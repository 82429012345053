import React, {useRef, useState} from 'react';

const IFrameWrapper = ({srcDoc, width = "", id = "", style = {}}) => {
  const ref = useRef();
  const [height, setHeight] = useState("0px");
  const onLoad = () => {
    setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
  };

  return (
    <iframe 
      ref={ref}
      onLoad={onLoad}
      id={id}
      srcDoc={srcDoc}
      width={width ? width : "100%"}
      height={height}
      scrolling="no"
      frameBorder="0"
    />
  );
};

export default IFrameWrapper;