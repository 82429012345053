import React, { useState } from 'react';
import  { Text, Button, Input } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, ActivityIndicator } from 'react-native';
import Spacer from '../components/Space';
import MeSearchAPI from '../api/MeSearch-API';
import { Platform } from 'react-native';

const ApproveBusinessClaimScreen = ({navigation, route}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMsg] = useState(null);
  const [pubUsername, setPubUsername] = useState("");
  const [ pgLoad, setPGLoad ] = useState(false);
  const pid = route.params?.pid ?? '';
  const rc = route.params?.rc ?? '';
  const body_id = route.params?.body_id ?? '';
  const rid = route.params?.rid ?? '';
  return (
    <SafeAreaView style={Dimensions.get('window').width > 500 ? styles.containerLarge : styles.containerSmall}>
     {!pgLoad ?
      <>
      <Spacer>
      <View style={{flex: 0.5, marginTop: 10, justifyContent: 'center', alignItems: 'center'}}>
        <Image style={styles.image} source={require('../../assets/logo-mesearch.png')}/>
      </View>
      </Spacer>
      <Input
        label={"Publication Name:"}
        value={pubUsername}
        onChangeText={(text) => {
            setPubUsername(text);
        }}
        autoCorrect={false}
        maxLength={800}
      />
      <Spacer/>
      {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
      {successMessage ? <Text style={styles.successMessage}>{successMessage}</Text> : null}
      <Spacer>
        <Button
          title={'Approve'}
          onPress={async () =>  {
            setSuccessMsg('');
            setErrorMessage('');
            if (pubUsername !== "") {
                try {
                setPGLoad(true);
                let payload = {pubOwnerId: pid, requestCode: rc, body_id: body_id, pubUsername: pubUsername, reqUserId: rid};
                await MeSearchAPI.post('/api/pub-owner/approve-business-claim', payload);
                setSuccessMsg('Successfully approved user!');
                } catch (err) {
                setErrorMessage(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`);
                }
                setPGLoad(false);
            } else {
                setErrorMessage('Must set the Pub Name');
            }
          }}
        />
      </Spacer>
      <Spacer>
      <Button
          title={'Deny'}
          buttonStyle={{backgroundColor: 'red'}}
          onPress={async () =>  {
            setSuccessMsg('');
            setErrorMessage('');
                try {
                  setPGLoad(true);
                  let payload = {pubOwnerId: pid, requestCode: rc, reqUserId: rid};
                  await MeSearchAPI.post('/api/pub-owner/deny-business-claim', payload);
                  setSuccessMsg('Successfully denied user!');
                } catch (err) {
                  setErrorMessage(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`);
                }
                setPGLoad(false);
          }}
        />
        </Spacer>
      </>
      : <ActivityIndicator size="large" style={{ marginTop: 150 }} color="#005157"/>}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
    containerSmall: {
      flex: 1
    },
    containerLarge: {
      width: Dimensions.get('window').width,
      paddingLeft: Dimensions.get('window').width > 500 ? ((Dimensions.get('window').width - 500)/2) : null,
      paddingRight: Dimensions.get('window').width > 500 ? ((Dimensions.get('window').width - 500)/2) : null
    },
    errorMessage: {
      fontSize: 18,
      color: 'red',
      marginTop: 15,
      marginLeft: 15
    },
    successMessage: {
      fontSize: 18,
      color: 'green',
      marginTop: 15,
      marginLeft: 15
    },
    image: {
      width: 200,
      height: 80,
      marginTop: 5,
    }
});

export default ApproveBusinessClaimScreen;