const months = [
  {label: "Jan", value: "0"}, {label: "Feb", value: "1"}, {label: "Mar", value: "2"}, {label: "Apr", value: "3"}, {label: "May", value: "4"},
  {label: "Jun", value: "5"}, {label: "Jul", value: "6"}, {label: "Aug", value: "7"}, {label: "Sep", value: "8"}, {label: "Oct", value: "9"},
  {label: "Nov", value: "10"}, {label: "Dec", value: "11"}];
const days = [
  {label: "01", value: "1"}, {label: "02", value: "2"}, {label: "03", value: "3"}, {label: "04", value: "4"}, {label: "05", value: "5"},
  {label: "06", value: "6"}, {label: "07", value: "7"}, {label: "08", value: "8"}, {label: "09", value: "9"}, {label: "10", value: "10"},
  {label: "11", value: "11"}, {label: "12", value: "12"}, {label: "13", value: "13"}, {label: "14", value: "14"}, {label: "15", value: "15"}, {label: "16", value: "16"},
  {label: "17", value: "17"}, {label: "18", value: "18"}, {label: "19", value: "19"}, {label: "20", value: "20"}, {label: "21", value: "21"}, {label: "22", value: "22"},
  {label: "23", value: "23"}, {label: "24", value: "24"}, {label: "25", value: "25"}, {label: "26", value: "26"}, {label: "27", value: "27"}, {label: "28", value: "28"}, 
  {label: "29", value: "29"}, {label: "30", value: "30"}, {label: "31", value: "31"}
];

const years = [
  {label: "1970", value: "1970"}, {label: "1971", value: "1971"}, {label: "1972", value: "1972"}, {label: "1973", value: "1973"}, {label: "1974", value: "1974"},
  {label: "1975", value: "1975"}, {label: "1976", value: "1976"}, {label: "1977", value: "1977"}, {label: "1978", value: "1978"}, {label: "1979", value: "1979"},
  {label: "1980", value: "1980"}, {label: "1981", value: "1981"}, {label: "1982", value: "1982"}, {label: "1983", value: "1983"}, {label: "1984", value: "1984"},
  {label: "1985", value: "1985"}, {label: "1986", value: "1986"}, {label: "1987", value: "1987"}, {label: "1988", value: "1988"}, {label: "1989", value: "1989"},
  {label: "1990", value: "1990"}, {label: "1991", value: "1991"}, {label: "1992", value: "1992"}, {label: "1993", value: "1993"}, {label: "1994", value: "1994"}, 
  {label: "1995", value: "1995"}, {label: "1996", value: "1996"}, {label: "1997", value: "1997"}, {label: "1998", value: "1998"},  {label: "1999", value: "1999"},
  {label: "2000", value: "2000"}, {label: "2001", value: "2001"}, {label: "2002", value: "2002"}, {label: "2003", value: "2003"}, {label: "2004", value: "2004"}, 
  {label: "2005", value: "2005"}, {label: "2006", value: "2006"}, {label: "2007", value: "2007"}, {label: "2008", value: "2008"}, {label: "2009", value: "2009"}, 
  {label: "2010", value: "2010"}, {label: "2011", value: "2011"}, {label: "2012", value: "2012"}, {label: "2013", value: "2013"}, {label: "2014", value: "2014"}, 
  {label: "2015", value: "2015"}, {label: "2016", value: "2016"}, {label: "2017", value: "2017"}, {label: "2018", value: "2018"}, {label: "2019", value: "2019"}, 
  {label: "2020", value: "2020"}, {label: "2021", value: "2021"}, {label: "2022", value: "2022"}, {label: "2023", value: "2023"}, {label: "2024", value: "2024"}, 
  {label: "2025", value: "2025"}, {label: "2026", value: "2026"}, {label: "2027", value: "2027"}, {label: "2028", value: "2028"}, {label: "2029", value: "2029"}, 
  {label: "2030", value: "2030"}, {label: "2031", value: "2031"}, {label: "2032", value: "2032"}
];

const hours = [
  {label: "1", value: "1"}, {label: "2", value: "2"}, {label: "3", value: "3"}, {label: "4", value: "4"}, {label: "5", value: "5"},
  {label: "6", value: "6"}, {label: "7", value: "7"}, {label: "8", value: "8"}, {label: "9", value: "9"}, {label: "10", value: "10"},
  {label: "11", value: "11"}, {label: "12", value: "12"}
];

/*const hours = [
  {label: "01", value: "1"}, {label: "02", value: "2"}, {label: "03", value: "3"}, {label: "04", value: "4"}, {label: "05", value: "5"},
  {label: "06", value: "6"}, {label: "07", value: "7"}, {label: "08", value: "8"}, {label: "09", value: "9"}, {label: "10", value: "10"},
  {label: "11", value: "11"}, {label: "12", value: "12"}, {label: "13", value: "13"}, {label: "14", value: "14"}, {label: "15", value: "15"},
  {label: "16", value: "16"}, {label: "17", value: "17"}, {label: "18", value: "18"}, {label: "19", value: "19"}, {label: "20", value: "20"}, 
  {label: "21", value: "21"}, {label: "22", value: "22"}, {label: "23", value: "23"}, {label: "24", value: "24"}
];*/

const minutes = [
  {label: "1", value: "1"}, {label: "2", value: "2"}, {label: "3", value: "3"}, {label: "4", value: "4"}, {label: "5", value: "5"},
  {label: "6", value: "6"}, {label: "7", value: "7"}, {label: "8", value: "8"}, {label: "9", value: "9"}, {label: "10", value: "10"},
  {label: "11", value: "11"}, {label: "12", value: "12"}, {label: "13", value: "13"}, {label: "14", value: "14"}, {label: "15", value: "15"}, {label: "16", value: "16"},
  {label: "17", value: "17"}, {label: "18", value: "18"}, {label: "19", value: "19"}, {label: "20", value: "20"}, {label: "21", value: "21"}, {label: "22", value: "22"},
  {label: "23", value: "23"}, {label: "24", value: "24"}, {label: "25", value: "25"}, {label: "26", value: "26"}, {label: "27", value: "27"}, {label: "28", value: "28"}, 
  {label: "29", value: "29"}, {label: "30", value: "30"}, {label: "31", value: "31"}, {label: "32", value: "32"}, {label: "33", value: "33"}, {label: "34", value: "34"}, 
  {label: "35", value: "35"}, {label: "36", value: "36"}, {label: "37", value: "37"}, {label: "38", value: "38"}, {label: "39", value: "39"}, {label: "40", value: "40"}, 
  {label: "41", value: "41"}, {label: "42", value: "42"}, {label: "43", value: "43"}, {label: "44", value: "44"}, {label: "45", value: "45"}, {label: "46", value: "46"}, 
  {label: "47", value: "47"}, {label: "48", value: "48"}, {label: "49", value: "49"}, {label: "50", value: "50"}, {label: "51", value: "51"}, {label: "52", value: "52"}, 
  {label: "53", value: "53"}, {label: "54", value: "54"}, {label: "55", value: "55"}, {label: "56", value: "56"}, {label: "57", value: "57"}, {label: "58", value: "58"}, 
  {label: "59", value: "59"}
];

const period = [
  {label: "AM", value: "AM"},
  {label: "PM", value: "PM"}
];

export { months, days, years, hours, minutes, period };