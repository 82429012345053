import ContentTypeEnum from '../../components/utils/contentTypes';

function getAllIndexes(arr, valArr) {
    let indexes = [], i;
    for(i = 0; i < arr.length; i++) {
        if (valArr.includes(arr[i].type) || (valArr.includes(ContentTypeEnum.ARTICLE) && !arr[i].type)) {
            indexes.push(i);
        }
    }
    return indexes;
}

export default getAllIndexes;