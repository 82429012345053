import React, { useState } from 'react';
import { View, StyleSheet, Dimensions, Image } from 'react-native';
import  { Text, Button, Input } from 'react-native-elements';
import NavLink from '../components/NavLink';
import Spacer from '../components/Space';
import MeSearchAPI from '../api/MeSearch-API';
import CopyrightFooter from '../components/CopyrightFooter';


const AddUserScreen = ({navigation, route}) => {
    const pubOwner = route.params?.pubOwner ?? '';
    const [errAd, setErrAd] = useState(null);
    const [successMsgAd, setSuccessMsgAd] = useState(null);
    const [ usernameAd, setUsernameAd ] = useState('');
    const [errAdBasic, setErrAdBasic] = useState(null);
    const [successMsgAdBasic, setSuccessMsgAdBasic] = useState(null);
    const [ usernameAdBasic, setUsernameAdBasic ] = useState('');

    return (
        <View style={styles.container}>
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <Text style={styles.headerStyle}>Add a Pro Admin:</Text>
            <Input 
                containerStyle={{width: 250}}
                label="Email"
                value={usernameAd}
                onChangeText={(newUsername) => setUsernameAd(newUsername)}
                autoCapitalize="none"
                autoCorrect={false}
                maxLength={80}
            />
            <View style={{padding: 10}}>
              {errAd ? <Text style={styles.errorMessage}>{errAd}</Text> : null}
              {successMsgAd ? <Text style={styles.successMessage}>{successMsgAd}</Text> : null}
            </View>
        </View>
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <Text style={styles.headerStyle}>Add a Basic Admin:</Text>
            <Input 
                containerStyle={{width: 250}}
                label="Email"
                value={usernameAdBasic}
                onChangeText={(newUsername) => setUsernameAdBasic(newUsername)}
                autoCapitalize="none"
                autoCorrect={false}
                maxLength={80}
            />
            <View style={{padding: 10}}>
              {errAdBasic ? <Text style={styles.errorMessage}>{errAdBasic}</Text> : null}
              {successMsgAdBasic ? <Text style={styles.successMessage}>{successMsgAdBasic}</Text> : null}
            </View>
        </View>
        <Spacer>
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <Button 
                title={"Submit"}
                style={{width: 100}}
                onPress={async () => {
                    setErrAd('');
                    setSuccessMsgAd('');
                    setErrAdBasic('');
                    setSuccessMsgAdBasic('');
                    let usrAd = usernameAd;
                    let usrAdBasic = usernameAdBasic;
                    setUsernameAd('');
                    setUsernameAdBasic('');
                    if (usrAd) {
                        try {
                            await MeSearchAPI.post(
                                '/api/user/change-access-lvl', 
                                { username: usrAd, pub: pubOwner, pubOwner, newLvl: 4});
                            setSuccessMsgAd('Succesfully added user as admin!');
                        } catch (err) {
                            setErrAd(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`);
                        }
                    }
                    if (usrAdBasic) {
                        try {
                            await MeSearchAPI.post(
                                '/api/user/change-access-lvl', 
                                { username: usrAdBasic, pub: pubOwner, pubOwner, newLvl: 3});
                            setSuccessMsgAdBasic('Succesfully added user as admin!');
                        } catch (err) {
                            setErrAdBasic(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`);
                        }
                    }
                }}
                />
            </View>
        </Spacer>
        <CopyrightFooter />
        </View>
    );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
    paddingRight: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
    marginBottom: 20
  },
  image: {
    width: null,
    height: null,
    marginTop: 5,
    marginBottom: 5,
    resizeMode: 'contain',
    flex: 1
  },
  errorMessage: {
    fontSize: 18,
    color: 'red',
    marginTop: 15,
    marginLeft: 15
  },
  successMessage: {
    fontSize: 18,
    color: 'green',
    marginTop: 15,
    marginLeft: 15
  },
  headerStyle: {
    fontSize: 22,
    fontWeight: '300',
    margin: 10
  },
  largeHeaderStyle: {
    fontSize: 26,
    fontWeight: '300',
    margin: 10
  }
});

export default AddUserScreen;