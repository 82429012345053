import React, {useState, useEffect, useContext} from 'react';
import { SafeAreaView, View, Text, Image, TouchableOpacity, FlatList, StyleSheet } from 'react-native';
import { HeaderBackButton } from '@react-navigation/stack';
import { Platform } from 'react-native';
import valErrorExist from '../../../screens/utils/FormValidation/utils/valErrorsExist';
import validateFormField from '../../../screens/utils/FormValidation/validateFormField';
import MenuFormItem from '../../core/MenuFormItem';
import HeaderButton from '../../core/HeaderButton';
import { isMobile } from 'react-device-detect';
import { Context as AuthContext } from '../../../context/AuthContext';
import { Context as PubContext } from '../../../context/PubContext';
import MeSearchAPI from '../../../api/MeSearch-API';
import { Dimensions } from 'react-native';

const UserSignupWrapper = ({ fieldsMeta, setFieldsMeta, validate, setEditingField, navigation, route, step, setStep }) => {
  const { state, signup, termsOfService, clearErrorMessage, tryFingerPrintSignin } = useContext(AuthContext);
  const PubCon = useContext(PubContext);
  const [fingerPrint, setFingerPrint] = useState(null);
  const upload = route.params?.upload ?? '';
  const mappedData = Object.entries(fieldsMeta);
  const lastStep = mappedData[mappedData.length - 1][1].step;
  const dataSet = mappedData.filter(item => {
    return item[1].step === step;
  });
  const dataSetObj = Object.fromEntries(dataSet);
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldsMetaInStep, setFieldsMetaInStep] = useState(dataSetObj);

  useEffect (() => {
    if (Platform.OS === 'web') {
      // Need this conditional import because importing this lib breaks native mobile
      require('clientjs');
      // Set up fingerprint
      const client = new ClientJS();
      setFingerPrint(String(client.getFingerprint()));
    } else {
      // Create an account with uuid as the username and password
      const uuidv4 = require('uuid/v4');
      setFingerPrint(String(uuidv4()));
    }
    
  }, []);

  const setTerms = async () => {
    try {
      const responsePromise = MeSearchAPI.get('/api/user');
      return await responsePromise.then(response => {
        if (response.data) {
          return response.data.termsAccepted ? true : false;
        }
        return false;
      });
    } catch (error) {
      console.log(error);
    }
  }

  const resetUrl = () => {
    if (Platform.OS === 'web') {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let pub = params.get('p');
      if (pub) {
        history.pushState(null, 'body', `/?p=${pub}`);
      } else {
        const pubPageMatches = `${PubCon.state.ref}`.match(/[^\/]+/g);
        let pubPage = (pubPageMatches && pubPageMatches.length >= 3) ? pubPageMatches[2] : '/';
        history.replaceState(null, 'body', `/${pubPage}` === '//' ? '/' : `/${pubPage}`);
      }
    }
  };

  const onNext = async () => {
    setErrorMessage('');
    Object.keys(fieldsMetaInStep).forEach(fieldsMetaKey => {
      validateFormField({ setter: setFieldsMetaInStep, original: fieldsMeta, key: fieldsMetaKey, validations: validate });
    });

    if (valErrorExist(fieldsMeta, step)) {
      setErrorMessage('There seems to be missing data or errors in the form. Please correct all errors before submitting.');
    } else if (fieldsMeta['password'].value !== fieldsMeta['confirmPassword'].value) {
      setErrorMessage('Passwords need to match, please try again.');
    } else {
      if (step === lastStep) {
        const termsAccepted = await setTerms();
        if (!termsAccepted) {
          navigation.navigate('TosPrivacy', {fieldsMeta});
        } else if (state.token) {
          signup({ username: fieldsMeta.emailAddress.value.trim(), password: fieldsMeta.password.value.trim(), displayName: fieldsMeta.displayName.value.trim(), guest: false, referrer: PubCon.state.ref });
        } else {
          signup({ username: fieldsMeta.emailAddress.value.trim(), password: fieldsMeta.password.value.trim(), displayName: fieldsMeta.displayName.value.trim(), guest: false, referrer: PubCon.state.ref, noToken: true });
        }
        resetUrl();
      } else {
        setStep(step + 1);
      }
    }
  };

  const onBack = () => {
    setErrorMessage('');
    if (step === 1) {
      navigation.goBack();
    } else {
      setStep(step - 1);
    }
  };

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: 'Create Personal Membership',
      headerTitleAlign:'center',
      headerRight: () => (
        <HeaderButton 
          title={ step === lastStep ? "Submit" : "Next" }
          onPress={() => onNext()}
        />
      ),
      headerLeft: () => (
        <HeaderBackButton
          onPress={() => onBack()}
        />
      )
    });
  }, [navigation, step]);

  return (
    <SafeAreaView style={{justifyContent: 'center', alignItems: 'center', padding: 20}}>
      <View style={{width: Math.min(Dimensions.get('window').width-20, 500), justifyContent: 'center'}}>
        { errorMessage ? (
          <Text style={styles.errorMessage}>{errorMessage}</Text>
        ) : null }
        <View style={{marginTop: -5, justifyContent: 'center', alignItems: 'center', paddingBottom: 20, paddingTop: 20}}>
          <Image style={{width: 140, height: 50, resizeMode: 'contain', }} source={require('../../../../assets/mesearch_logo_small.png')} />
        </View>
        <Text style={{fontWeight:'bold', fontSize: 20, marginBottom: 10, paddingLeft: 20}}>Let's Get Started</Text>
        <Text style={{fontSize: 16, paddingLeft: 20}}>Personal Membership Information</Text>
        
        <View>
          <FlatList 
            data={dataSet}
            keyExtractor={item => item[0]}
            renderItem={({item}) => {
              // can we perhaps render different MenuFormItem here based on the type from fieldsMeta?
              return (
                <MenuFormItem 
                  value={item[1].value}
                  itemName={item[1].name}
                  errorFlag={item[1].error ? true : false}
                  type={item[1].type}
                  onPress={() => {
                    setEditingField(`${item[0]}`);
                  }}
                />
              )
            }}
          />
        </View>
      
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  errorMessage: {
    fontSize: 14, 
    color: 'red', 
    paddingBottom: 10, 
    paddingLeft: 10
  },
  headerButtonText: {
    color: '#24627a',
    fontWeight: '700',
    fontSize: 16
  }
});

export default UserSignupWrapper;