import React, { useContext, useState } from 'react';
import { View, TouchableOpacity, Text, Dimensions, StyleSheet } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { Context as PubContext } from '../context/PubContext';
import { Platform } from 'react-native';


const TabBar = ({ state, descriptors, navigation }) => {
    const PubCon = useContext(PubContext);
    const isWhitelabel = (() => {
      if (PubCon.state.publisher && (PubCon.state.publisher.username === 'TribExtra-AP' || PubCon.state.publisher.username === 'Sponsored Sports 2')) {
        return true;
      } else {
        return false;
      }
    })();
    const styles = StyleSheet.create({
        outsideContainer: {
            flexDirection: 'row', 
            backgroundColor: (PubCon && PubCon.state && PubCon.state.publisher) ? "white"/*PubCon.state.publisher.tagBarColor*/ : 'white', 
            height: !isWhitelabel ? 80 : null, 
            justifyContent:"center", 
            alignItems:"center", 
            paddingBottom: Platform.OS !== 'ios' ? 5 : 10,
            width: Dimensions.get('window').width
        },
        insideContainer: {
            width: Math.min(Dimensions.get('window').width-30, 800), 
            flexDirection: 'row', 
            backgroundColor: (PubCon && PubCon.state && PubCon.state.publisher) ? "white"/*PubCon.state.publisher.tagBarColor*/ : "white", 
            height: !isWhitelabel ? 60 : null, 
            paddingTop: Platform.OS !== 'ios' ? 10 : 2, 
            borderRadius: 40
        },
        icon: {
            fontSize: 25, 
            marginHorizontal: 5, 
            marginTop: 2
        }
    });
  
    return (
        <View style={styles.outsideContainer}>
          <View style={styles.insideContainer}>
          {state.routes.map((route, index) => {
            const { options } = descriptors[route.key];
            const label =
              options.tabBarLabel !== undefined
                ? options.tabBarLabel
                : options.title !== undefined
                ? options.title
                : route.name;
    
            const isFocused = state.index === index;
    
            const onPress = () => {
              const event = navigation.emit({
                type: 'tabPress',
                target: route.key,
              });
              
              if (!isFocused && !event.defaultPrevented) {
                navigation.navigate(route.name);
              }
            };
  
            const fetchIcon = () => {
              if (label === "Home") {
                return "home";
              } else if (label === "Account") {
                return "user";
              } else if (label === "Search") {
                return "search";
              } else if (label === "Menu") {
                return "menu";
              } else if (label === "News") {
                return "list";
              }
            }
  
            const evaluateLabelEnabled = (labelName) => {
              if (labelName === 'Search') {
                if (!PubCon || !PubCon.state || !PubCon.state.publisher) {
                  return true;
                } else {
                  return false;
                }
              } else if (labelName === 'Menu') {
                return false;
              }
              return true;
            }
    
            return (
              <View key={label} style={{flex: 1, alignItems:"center"}}>
                {
                  !isWhitelabel ? (
                    <>
                    <TouchableOpacity
                      accessibilityRole="button"
                      accessibilityStates={isFocused ? ['selected'] : []}
                      accessibilityLabel={options.tabBarAccessibilityLabel}
                      testID={options.tabBarTestID}
                      onPress={() => {
                        if (evaluateLabelEnabled(label)) {
                          onPress();
                        }
                      }}
                      style={{ width: 65, alignItems: 'center' }}
                    >
                      <Feather 
                        name={`${fetchIcon()}`} 
                        style={[
                          styles.icon, 
                          {color: isFocused ? (PubCon && PubCon.state && PubCon.state.publisher) ? PubCon.state.publisher.tagBarColor/*'white'*/ : 'blue' : 'black'},
                          {opacity: evaluateLabelEnabled(label) ? 1 : 0.25}
                        ]}
                      />
                      <Text 
                        style={[ 
                          {color: isFocused ? (
                              PubCon && PubCon.state && PubCon.state.publisher
                            ) ? PubCon.state.publisher.tagBarColor/*'white'*/ 
                              : 'blue' 
                            : 'black'},
                          {opacity: evaluateLabelEnabled(label) ? 1 : 0.25}
                        ]}
                      >
                        {label}
                      </Text>
                      </TouchableOpacity>
                      </>
                    ) 
                    : 
                    null
                    // (
                    //   <>
                    //   <Feather 
                    //     name={`${fetchIcon()}`} 
                    //     style={[
                    //       styles.icon, 
                    //       {color: 'white'},
                    //     ]}
                    //   />
                    //   </> 
                    // )
                }
              
              </View>
            );
          })}
          </View>
        </View>
    );
}

export default TabBar;