import React, { useState } from 'react';
import { View, StyleSheet, Dimensions, Image } from 'react-native';
import  { Text, Button, Input } from 'react-native-elements';
import NavLink from '../components/NavLink';
import Spacer from '../components/Space';
import MeSearchAPI from '../api/MeSearch-API';
import CopyrightFooter from '../components/CopyrightFooter';


const PubHomeScreen = ({navigation, route}) => {
    const pub = route.params?.pub ?? '';
    const publisher = route.params?.publisher ?? '';
    const accountManage = route.params?.accountManage ?? '';
    const pubOwner = route.params?.pubOwner ?? '';
    const [errAd, setErrAd] = useState(null);
    const [successMsgAd, setSuccessMsgAd] = useState(null);
    const [errRem, setErrRem] = useState(null);
    const [successMsgRem, setSuccessMsgRem] = useState(null);
    const [ usernameAd, setUsernameAd ] = useState('');
    const [ usernameRem, setUsernameRem ] = useState('');

    return (
        <View style={styles.container}>
        <Spacer/>
        <View style={{flexDirection: 'row', justifyContent: 'center'}}>
          <Text style={styles.largeHeaderStyle}>{`${pub}`}</Text>
        </View>
        <Text style={styles.headerStyle}>Add an Admin:</Text>
        <Input 
            label="Username"
            value={usernameAd}
            onChangeText={(newUsername) => setUsernameAd(newUsername)}
            autoCapitalize="none"
            autoCorrect={false}
            maxLength={80}
        />
        <Spacer/>
        <Text style={styles.headerStyle}>Remove an Admin:</Text>
        <Input 
            label="Username"
            value={usernameRem}
            onChangeText={(newUsername) => setUsernameRem(newUsername)}
            autoCapitalize="none"
            autoCorrect={false}
            maxLength={80}
        />
        {errAd ? <Text style={styles.errorMessage}>{errAd}</Text> : null}
        {successMsgAd ? <Text style={styles.successMessage}>{successMsgAd}</Text> : null}
        {errRem ? <Text style={styles.errorMessage}>{errRem}</Text> : null}
        {successMsgRem ? <Text style={styles.successMessage}>{successMsgRem}</Text> : null}
        <Spacer>
            <Button 
            title={"Submit"}
            onPress={async () => {
                setErrAd('');
                setSuccessMsgAd('');
                setErrRem('');
                setSuccessMsgRem('');
                let usrAd = usernameAd;
                let usrRem = usernameRem;
                setUsernameAd('');
                setUsernameRem('');
                if (usrAd) {
                    try {
                        if (publisher) {
                            await MeSearchAPI.post(
                                '/api/user/change-access-lvl', 
                                { username: usrAd, pub: pub, newLvl: 4 });
                        } else {
                            await MeSearchAPI.post(
                                '/api/user/change-access-lvl', 
                                { username: usrAd, pub: pub, newLvl: 4 });
                        }
                        setSuccessMsgAd('Succesfully added user as admin!');
                    } catch (err) {
                        setErrAd(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`);
                    }
                }
                if (usrRem) {
                    try {
                        if (publisher) {
                            await MeSearchAPI.post(
                                '/api/user/change-access-lvl', 
                                { username: usrRem, pub: pub, newLvl: 1 });
                        } else {
                            await MeSearchAPI.post(
                                '/api/user/change-access-lvl', 
                                { username: usrRem, pub: pub, newLvl: 1 });
                        }
                        setSuccessMsgRem('Succesfully removed user\'s admin status!');
                    } catch (err) {
                        setErrRem(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`);
                    }
                }
            }}
            />
        </Spacer>
        <Spacer>
            <Button 
            title={"Back"}
            onPress={() => {
              if (!publisher) {
                navigation.navigate('Search')
              } else if (accountManage) {
                navigation.navigate('Drawer', {screen: 'Account'})
              } else {
                navigation.navigate('PublisherHome', {pubOwner})
              }

            }}
            />
        </Spacer>
        <View style={{width: 215}}>
        <NavLink
          text="Edit Publication"
          routeName="PubEdit"
          params={{pub: pub, publisher: publisher}}
        />
        </View>
        {/* <View style={{flexDirection: 'row', justifyContent: 'center'}}>
            <View style={{height: 150, width: 280, marginBottom: 5, padding: 10}}>
                <Image style={styles.image} source={require('../../assets/web_footer_copyright.png')}/>
            </View>
        </View> */}
        <CopyrightFooter />
        </View>
    );
};

// TODO: DEPRECATED
/*PubHomeScreen.navigationOptions = {
  header: null
};*/

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
    paddingRight: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
    marginBottom: 20
  },
  image: {
    width: null,
    height: null,
    marginTop: 5,
    marginBottom: 5,
    resizeMode: 'contain',
    flex: 1
  },
  errorMessage: {
    fontSize: 18,
    color: 'red',
    marginTop: 15,
    marginLeft: 15
  },
  successMessage: {
    fontSize: 18,
    color: 'green',
    marginTop: 15,
    marginLeft: 15
  },
  headerStyle: {
    fontSize: 22,
    fontWeight: '300',
    margin: 10
  },
  largeHeaderStyle: {
    fontSize: 26,
    fontWeight: '300',
    margin: 10
  }
});

export default PubHomeScreen;