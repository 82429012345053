import React from 'react';
import ResultsList from './GridRow';
import { Context as PubContext } from '../../../context/PubContext';
import { Dimensions } from 'react-native';
import { Platform } from 'react-native';
import WebView from 'react-native-webview';
import GridPage from '../../core/GridPage';
import SearchFooter from '../../SearchScreenFooter';
import PubImgHeader from '../../PubImgHeader';
import PubHeader from '../../PubHeader';
import DineLocalHeader from '../../DineLocalHeader';
import RestaurantHeader from '../../RestaurantHeader';
import MeTags from '../../utils/meTags';

class ResultsPage extends React.Component  {
  static contextType = PubContext;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <GridPage
        imgHeader={!this.props.claimed
          ? PubImgHeader 
          : RestaurantHeader
        }
        Header={(this.context.state.publisher.template && this.context.state.publisher.template.header && this.context.state.publisher.template.header.template1) 
          ? DineLocalHeader
          : PubHeader
        }
        headerProps={(this.context.state.publisher.template && this.context.state.publisher.template.header && this.context.state.publisher.template.header.template1)
          ? 
            {
              imageBackgroundUri: this.context.state.publisher.template.header.template1.imgBackgroundUri,
              imageHeaderUri: this.context.state.publisher.template.header.template1.imgHeaderUri,
              imageHeaderLink: this.context.state.publisher.template.header.template1.imgHeaderLink,
              subHeadingTxt: this.context.state.publisher.template.header.template1.subHeadingTxt,
              subHeaderLink: this.context.state.publisher.template.header.template1.subHeaderLink,
              headingText: this.context.state.publisher.template.header.template1.headingTxt,
              setCurSearchTxt: this.props.setCurSearchTxt,
              setErrorMessage: this.props.setErrorMessage,
              setSearchScreenPage: this.props.setSearchScreenPage,
              setNumFound: this.props.setNumFound,
              setFreeSearch: this.props.setFreeSearch,
              fetchRelevantUserContentTags: this.props.fetchRelevantUserContentTags,
              updateUserSearchHistory: this.props.updateUserSearchHistory,
              setUserSpecifiedTag: this.props.setUserSpecifiedTag,
              setUserSpecifiedResults: this.props.setUserSpecifiedResults,
              setAllTags: this.props.setAllTags,
              setResults: this.props.setResults,
              setResultsTags: this.props.setResultsTags,
              setNonGreedyResults: this.props.setNonGreedyResults,
              home: this.props.home,
              isAdmin: this.props.isAdmin,
              setHome: this.props.setHome,
              placeholder: this.context.state.publisher.template.header.template1.srchPlaceholder,
              searchDesc: this.context.state.publisher.template.header.template1.srchDesc,
              searchDescLink: this.context.state.publisher.template.header.template1.srchDescLink,
              searchBoxColor: this.context.state.publisher.template.header.template1.searchBoxColor,
              setSearchHash: this.props.setSearchHash,
              navigation: this.props.navigation,
              darkMode: this.props.darkMode,
              free: this.props.free,
              errorMessage: this.props.errorMessage,
              numFound: this.props.numFound
            }
          : 
            {
              navigation: this.props.navigation,
              route: this.props.route,
              darkMode: this.props.darkMode,
              setAllTags: this.props.setAllTags,
              setResults: this.props.setResults,
              setResultsTags: this.props.setResultsTags,
              setNonGreedyResults: this.props.setNonGreedyResults,
              setUserSpecifiedTag: this.props.setUserSpecifiedTag,
              setUserSpecifiedResults: this.props.setUserSpecifiedResults,
              setSearchScreenPage: this.props.setSearchScreenPage,
              setNumFound: this.props.setNumFound,
              setFreeSearch: this.props.setFreeSearch,
              setCurSearchTxt: this.props.setCurSearchTxt,
              fetchRelevantUserContentTags: this.props.fetchRelevantUserContentTags,
              updateUserSearchHistory: this.props.updateUserSearchHistory,
              setErrorMessage: this.props.setErrorMessage,
              claimed: this.props.claimed,
              isGuest: this.props.isGuest,
              isAdmin: this.props.isAdmin,
              navToLogin: this.props.navToLogin,
              termsAccepted: this.props.termsAccepted,
              free: this.props.free,
              errorMessage: this.props.errorMessage,
              home: this.props.home,
              setHome: this.props.setHome,
              numFound: this.props.numFound,
              businessName: this.props.businessInfo ? this.props.businessInfo.name : null,
              ugcPermitted: (this.context.state.publisher.ugcPermitted || (typeof this.context.state.publisher.ugcPermitted === 'undefined')),
              upload: this.props.upload
            }
        }
        scroll={(this.context.state.publisher.template && this.context.state.publisher.template.header) ? true : false}
        scrollOffset={(this.context.state.publisher.template && this.context.state.publisher.template.header) ? Dimensions.get('window').height-50 : 0}
        searchHash={this.props.searchHash}
        results={this.props.results}
        free={this.props.free}
        blacklistTags={this.context.state.publisher.blacklistTags}
        latestResults={this.props.latestResults}
        searchScreenPage={this.props.searchScreenPage}
        setSearchScreenPage={this.props.setSearchScreenPage}
        userSpecifiedResults={this.props.userSpecifiedResults}
        resultsTags={this.props.resultsTags}
        curPageTags={this.props.curPageTags}
        totalPageNum={this.props.totalPageNum}
        nonGreedyResults={this.props.nonGreedyResults}
        resultList={ResultsList}
        darkMode={this.props.darkMode}
        setNumFound={this.props.setNumFound}
        refresh={this.props.refresh}
        numFound={this.props.numFound}
        grid={this.props.grid}
        resultsV2={this.props.resultsV2}
        isLoadingV2={this.props.isLoadingV2}
        headlineResults={this.props.headlineResults}
        assets={this.context.state.publisher.assets}
        pgFocus={this.props.pgFocus}
        navigation={this.props.navigation}
        isAdmin={this.props.isAdmin}
        fetchMoreContentQueue={this.props.fetchMoreContentQueue}
        curSearchTxt={this.props.curSearchTxt}
        userSpecifiedTag={this.props.userSpecifiedTag}
        setUserSpecifiedTag={this.props.setUserSpecifiedTag}
        loadRegularResults={this.props.loadRegularResults}
        fetchMoreContent={this.props.fetchMoreContent}
        loading={this.props.loading}
        tagBarColor={this.context.state.publisher.tagBarColor}
        currentLoadTag={this.props.currentLoadTag}
        dfpAds={this.context.state.publisher.dfpAds}
        baseContentType={this.context.state.publisher.baseContentType}
        mandatoryTag={this.context.state.publisher.mandatoryTag}
        context={this.context}
        Footer={() => {
          if (this.context.state.publisher.username !== 'TribExtra-AP' || this.context.state.publisher.username !== 'Sponsored Sports 2') {
            return (
              <SearchFooter darkMode={this.props.darkMode} isGuest={this.props.isGuest} navToLogin={this.props.navToLogin} claimed={this.props.claimed || (this.context.state.publisher.template && this.context.state.publisher.template.header)}/>
            );
          } else {
            return null;
          }
        }}
        restaurantInfo={{
          name: this.props.businessInfo ? this.props.businessInfo.name : null,
          address: this.props.businessInfo ? this.props.businessInfo.address : null,
          hours: this.props.businessInfo ? this.props.businessInfo.hours : null,
          phoneNum: this.props.businessInfo ? this.props.businessInfo.phoneNum : null,
          website: this.props.businessInfo ? this.props.businessInfo.website : null,
          menuUrl: this.props.businessInfo ? this.props.businessInfo.menuUrl : null,
          reserveUrl: this.props.businessInfo ? this.props.businessInfo.reserveUrl : null,
          healthInfo: this.props.businessInfo ? this.props.businessInfo.healthInfo : null
        }}
      />
    );
  };
};

export default ResultsPage;
