import React, { useState, useContext } from 'react';
import  { Text, Button, Input } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, TextInput, ActivityIndicator } from 'react-native';
import Spacer from '../components/Space';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import MeSearchAPI from '../api/MeSearch-API';
import { Context as PubContext } from '../context/PubContext';

const TrustedContributorScreen = ({}) => {
  const [errorMessage, setErrorMsg] = useState(null);
  const [successMessage, setSuccessMsg] = useState(null);
  const [errorMessageName, setErrorMsgName] = useState(null);
  const [errorMessageEmail, setErrorMsgEmail] = useState(null);
  const [ qaFirstName, setQAFirstName ] = useState('');
  const [ qaLastName, setQALastName ] = useState('');
  const [ qaStreet, setQAStreet ] = useState('');
  const [ qaCity, setQACity ] = useState('');
  const [ qaState, setQAState ] = useState('');
  const [ qaZip, setQAZip ] = useState('');
  const [ qaCountry, setQACountry ] = useState('');
  const [ qaEmail, setQAEmail ] = useState('');
  const [ qaPhoneNum, setQAPhoneNum ] = useState('');
  const [ qaWebsite, setQAWebsite ] = useState('');
  const [ pgLoad, setPGLoad ] = useState(false);
  const  PubCon = useContext(PubContext);
  return (
    <SafeAreaView style={Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall}>
    {!pgLoad ?
    <>
      <Spacer>
        <View style={{flex: 1, marginTop: 5, justifyContent: 'center', alignItems: 'center'}}>
          <Image style={{marginLeft: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? -5 : -70, height: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 105 : 150, width: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 328 : 660}} source={{uri: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? PubCon.state.publisher.assets[1].link /*Top-Header-Mobile*/ : PubCon.state.publisher.assets[0].link /*Top-Header-Web*/}}/>
        </View>
      </Spacer>
      <View style={{flex: 1, marginTop: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
        <Text style={{fontSize: 30, fontWeight: '450', fontFamily: 'Avenir', color: '#0E6938'}}>Become A  </Text>
        <Image style={{height: 50, width: 155}} source={require('../../assets/trusted.png')}/>
      </View>
      <Spacer/>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>{errorMessageName ? <Text style={styles.errorAst}>{errorMessageName}</Text> : null}What is your name?</Text>
      <Spacer>
        <Input 
          label="First Name"
          value={qaFirstName}
          onChangeText={(text) => setQAFirstName(text)}
          autoCorrect={false}
          maxLength={80}
        />
      </Spacer>
      <Spacer>
        <Input 
          label="Last Name"
          value={qaLastName}
          onChangeText={(text) => setQALastName(text)}
          autoCorrect={false}
          maxLength={80}
        />
      </Spacer>
      <Text style={{marginBottom: 10, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>What is your address? (optional)</Text>
      <Spacer>
        <Input 
          label="Street Address"
          value={qaStreet}
          onChangeText={(text) => setQAStreet(text)}
          autoCorrect={false}
          maxLength={120}
        />
      </Spacer>
      <Spacer>
        <Input 
          label="City"
          value={qaCity}
          onChangeText={(text) => setQACity(text)}
          autoCorrect={false}
          maxLength={40}
        />
      </Spacer>
      <Spacer>
        <Input 
          label="State"
          value={qaState}
          onChangeText={(text) => setQAState(text)}
          autoCorrect={false}
          maxLength={40}
        />
      </Spacer>
      <Spacer>
        <Input 
          label="Zip Code"
          value={qaZip}
          onChangeText={(text) => setQAZip(text)}
          autoCorrect={false}
          maxLength={40}
        />
      </Spacer>
      <Spacer>
        <Input 
          label="Country"
          value={qaCountry}
          onChangeText={(text) => setQACountry(text)}
          autoCorrect={false}
          maxLength={40}
        />
      </Spacer>
      <Text style={{marginBottom: 10, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>{errorMessageEmail ? <Text style={styles.errorAst}>{errorMessageEmail}</Text> : null}What is your email?</Text>
      <Spacer>
        <Input 
          label="Email"
          value={qaEmail}
          onChangeText={(text) => setQAEmail(text)}
          autoCorrect={false}
          maxLength={80}
        />
      </Spacer>
      <Text style={{marginBottom: 10, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>What is your phone number? (optional)</Text>
      <Spacer>
        <Input 
          label="Phone Number"
          value={qaPhoneNum}
          onChangeText={(text) => setQAPhoneNum(text)}
          autoCorrect={false}
          maxLength={12}
        />
      </Spacer>
      <Text style={{marginBottom: 10, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>What is your website? (optional)</Text>
      <Spacer>
        <Input 
          label="Url"
          value={qaWebsite}
          onChangeText={(text) => setQAWebsite(text)}
          autoCorrect={false}
          maxLength={80}
        />
      </Spacer>
      {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
      {successMessage ? <Text style={styles.successMessage}>{successMessage}</Text> : null}
      <Spacer>
        <Button
          title={'Submit'}
          onPress={ async () =>  {
            let error = false;
            if (!qaFirstName || !qaLastName) {
              error = true;
              setErrorMsgName('*')
            }
            if (!qaEmail){
              error = true;
              setErrorMsgEmail('*')
            }
            if (error) {
              setErrorMsg('Must fill out all required(*) fields')
              return
            }
            setPGLoad(true)
            try {
              const response = await MeSearchAPI.get('/api/user');
              let details = response.data.details;
              let clkHssnSScreenTopBan = 0;
              let clkHssnRSScreenInStory = 0;
              if (details) {
                if (details.clickHssnSScreenTopBan) {
                  clkHssnSScreenTopBan = details.clickHssnSScreenTopBan;
                }
                if (details.clickHssnRSScreenInStory) {
                  clkHssnRSScreenInStory = details.clickHssnRSScreenInStory;
                }
              }
              await MeSearchAPI.post('/api/user/request-trusted',
                {
                  questions:
                  {
                    firstName: qaFirstName,
                    lastName: qaLastName,
                    streetAddress: qaStreet ? qaStreet : null,
                    city: qaCity ? qaCity : null,
                    state: qaState ? qaState : null,
                    zipCode: qaZip ? qaZip : null,
                    country: qaCountry ? qaCountry : null,
                    email: qaEmail,
                    phoneNum: qaPhoneNum ? qaPhoneNum: null,
                    website: qaWebsite ? qaWebsite: null,
                    clickHssnSScreenTopBan: clkHssnSScreenTopBan,  // Need this so ad click info isn't lost
                    clickHssnRSScreenInStory: clkHssnRSScreenInStory // Need this so ad click info isn't lost
                  },
                  toEmail: PubCon.state.publisher.tcEmail, //"trustedcontributor@crivellamedia.com"
                  referrer: PubCon.state.ref
                }
              );
              setSuccessMsg('Successfully sent your request!')
            } catch (err) {
              setErrorMsg(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`)
            }
            setPGLoad(false)
          }}
        />
      </Spacer>
      </>
    : <ActivityIndicator size="large" style={{ marginTop: 150 }} color="#005157"/> }
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
    containerSmall: {
      flex: 1
    },
    containerLarge: {
      width: Dimensions.get('window').width,
      paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
      paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null
    },
    errorMessage: {
      fontSize: 18,
      color: 'red',
      marginTop: 15,
      marginLeft: 15
    },
    successMessage: {
      fontSize: 18,
      color: 'green',
      marginTop: 15,
      marginLeft: 15
    },
    errorAst: {
      fontSize: 24,
      color: 'red',
    },
    image: {
      width: 200,
      height: 80,
      marginTop: 5,
    }
});

export default TrustedContributorScreen;