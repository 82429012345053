import React, { useRef, useState, useEffect } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  TouchableOpacity,
  TextInput,
  FlatList,
  Platform,
} from "react-native";
import { Feather } from "@expo/vector-icons";
import { isMobile, isTablet } from 'react-device-detect';

const AccountHomeCustomBtn = ({ list, onPress, activeBtnTitle, darkMode }) => {
  let flatListRef = useRef(0);
  const [currOffset, setCurrOffset] = useState(0);
  const [lastItem, setLastItem] = useState(0);

  const scroll = () => {
    flatListRef.current.scrollToOffset({
      offset: currOffset,
      animated: true,
    });
  };

  useEffect(() => {
    scroll();
  }, [currOffset]);

  return (
    <View style={styles.container}>
      {lastItem > 0 && Platform.OS === "web" ? (
        <TouchableOpacity
          onPress={() => {
            if (lastItem > 0) {
              setCurrOffset(currOffset - 240);
              setLastItem(lastItem - 1);
            }
          }}
        >
          <Feather name="chevron-left" size={24} color={darkMode ? "white" : "black"} />
        </TouchableOpacity>
      ) : null}
      <FlatList
        horizontal={true}
        ref={flatListRef}
        showsHorizontalScrollIndicator={false}
        data={list}
        extraData={activeBtnTitle}
        keyExtractor={(item) => item.title}
        renderItem={({ item }) => (
          <View
            style={[
              styles.btn,
              activeBtnTitle === item.title ? { opacity: 1.0 } : { opacity: 0.35 },
              item.title.toLowerCase() == "users"
                ? { backgroundColor: "#0984e3" }
                : (item.title.toLowerCase() == "pubs" || item.title.toLowerCase() == "restaurants")
                ? { backgroundColor: "#FFA500" }
                : item.title.toLowerCase() == "ads"
                ? { backgroundColor: "#8B0000" }
                : item.title.toLowerCase() == "offers"
                ? { backgroundColor: "#8000a3" }
                : item.title.toLowerCase() == "news"
                ? { backgroundColor: "#009420" }
                : item.title.toLowerCase() == "articles"
                ? { backgroundColor: "#016575" }
                : { backgroundColor: "#7f8c8d" },
            ]}
          >
            <TouchableOpacity onPress={() => onPress(item.title)}>
              <Text style={styles.btnText}>{item.title}</Text>
            </TouchableOpacity>
          </View>
        )}
      />
      {list.length > 3 &&
      (Platform.OS === "web" && (!isMobile && !isTablet)) &&
      lastItem < Math.ceil(list.length / 3) ? (
        <TouchableOpacity
          onPress={() => {
            if (lastItem < Math.ceil(list.length / 3)) {
              setCurrOffset(currOffset + 240);
              setLastItem(lastItem + 1);
            }
          }}
        >
          <Feather name="chevron-right" size={24} color={darkMode ? "white" : "black"} />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    paddingHorizontal: 5,
  },
  btn: {
    paddingVertical: 5,
    marginRight: Dimensions.get("window").width > 750 ? 40 : 25,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 15,
    width: Dimensions.get("window").width > 750 ? 200 : 100,
  },
  btnText: {
    color: "#fff",
    textAlign: "center",
  },
});

export default AccountHomeCustomBtn;
