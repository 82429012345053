import React, {useState, useEffect} from 'react';
import  { Button, Input, CheckBox } from 'react-native-elements';
import { StyleSheet, Text, Dimensions, Image, View, FlatList, ImageBackground} from 'react-native';
import * as ImgPicker from 'expo-image-picker';
import Constants from 'expo-constants';
// import {askAsync, MEDIA_LIBRARY} from 'expo-permissions';
import {requestPermissionsAsync} from 'expo-media-library';
import { Platform } from 'react-native';
import isVideo from '../screens/utils/isVideo';
import { WebView } from 'react-native-webview';
import isUri from './utils/isUri';
import { isMobile, isTablet } from 'react-device-detect';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import styles from './core/styles/GridDetailStyles';
import alertToConfirm from './utils/alertToConfirm';
import StockPhotoOverlay from './StockPhotoOverlay';
import { Feather } from '@expo/vector-icons';

const ImagePicker = ({
  myImages, 
  setMyImages, 
  setMyImage, 
  updateMyImages,
  darkMode, 
  myPhotoInfo, 
  setMyPhotoInfo, 
  myPhotoCredit,
  setMyPhotoCredit,
  myPhotoDesc,
  setMyPhotoDesc,
  myDisclaimer,
  setMyDisclaimer,
  setImgProcessing, 
  togglePhotoOverlay,
  visible,
}) => {

  const [image, setImage] = useState(null);
  const [photoInfo, setPhotoInfo] = useState([]);

  const getPermissionAsync = async () => {
    if (Constants.platform.ios) {
      const {status} = await requestPermissionsAsync();
      if (status !== 'granted') {
        alert('Sorry, we need camera roll permissions to make this work!');
      }
    }
  };

  const getCurrentDate = () => {
    const timeDate = new Date();
    const timestamp = timeDate.setDate(timeDate.getDate());
    return timestamp.toString()
  };

  const _pickImage = async () => {
    try {
      let result = await ImgPicker.launchImageLibraryAsync({
        mediaTypes: ImgPicker.MediaTypeOptions.Images,
        allowsEditing: false,
        allowsMultipleSelection: false,
        aspect: [4, 3],
        base64: true,
        // quality: 0.3
      });

      // TODO: after the image is uploaded things move way too slow on mobile
      // To the point where check boxes don't even work

      if (!result.cancelled) {
        setImgProcessing(true);
        let resultUri = result.uri;
        let downSampled = false;
        const downSizeAndCompressedImage = await manipulateAsync(
          result.uri,
          [],
          { compress: 0.5, format: SaveFormat.JPEG, base64: true }
        );
        result = downSizeAndCompressedImage;
        
        if (Platform.OS === 'web') {
          setImage(result.uri);
          resultUri = result.uri;
          downSampled = true;
        } else {
          setImage(result.uri);
          if (result.base64) {
            resultUri = `data:image/jpeg;base64,${result.base64}`;
            downSampled = true;
          }
        }

        if (Platform.OS !== 'web' && !downSampled) {
          let uriParts = result.uri.split('.');
          let ext = "";

          uriParts.some(uriPart => {
            if (uriPart.match(/(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|mp4|MP4)/)) {
              ext = uriPart;
              return true;
            }
            return false;
          });
          
          if (ext === "") {
            // TODO: some error logic
          }
          // TODO switch to data:video/mp4,
          resultUri = `data:image/jpeg;base64,${result.base64}`;
        }

        setImgProcessing(false);
        setMyImage(resultUri);
        updateMyImages(resultUri);
        setMyPhotoCredit(prevCredits => [...prevCredits]);
        setMyPhotoDesc(prevDesc => [...prevDesc]);

        let ds = myDisclaimer;
        ds.push(getCurrentDate());
        setMyDisclaimer(prevDisc => [...prevDisc]);
      }
    } catch (error) {
      console.log('An issue with picking images has occurred: ', error);
    }
  };

  const removeImage = (item, index) => {
    let newImageArray = myImages.filter(i => i !== item);
    let newPhotoCredit = myPhotoCredit.splice(index, 1);
    let newPhotoDesc = myPhotoDesc.splice(index, 1);
    let newPhotoDisclaimer = myDisclaimer.splice(index, 1);
    setMyPhotoCredit(newPhotoCredit);
    setMyPhotoDesc(newPhotoDesc);
    setMyDisclaimer(newPhotoDisclaimer);
    setMyImages(newImageArray);
  };

  useEffect(() => {
    getPermissionAsync();
  }, []);

  return (
    <View
      style={{flex: 1, alignItems: 'center', justifyContent: 'center', maxWidth:300}}
    >
      <View style={{flex: 1, flexDirection:'row', alignItems: 'center', justifyContent: 'center', width: '50%'}}>
        <Button
          title={
            <View style={{flexDirection:'row', alignItems: 'center', justifyContent: 'center'}}>
              <Feather name="upload" style={{fontSize: 24, color: 'white', }}/>
              <View style={{flexDirection:'column',paddingLeft:5, }}>
                <Text style={{fontSize: 14, fontFamily:'Arial', color: 'white', }}>Add</Text>
                <Text style={{fontSize: 14, fontFamily:'Arial', color: 'white', }}>Your Photo</Text>
              </View>
            </View> }
          titleStyle={{textAlignVertical:'center', alignItems:'center'}}
          style={{width: 120, paddingRight: 3}}
          onPress={async () => {
            alertToConfirm('web', "You agree that You are the owner or have the lawful right or license to the Content that You are about to upload to this site and that You are solely responsible for copyright or other intellectual property infringement that may occur as a result of this posting.", async () => {
              try {
                _pickImage()
              } catch (err) {
                console.log('cancelled');
              }
            })();
      
          }}
        />
        <Button
          title={
            <View style={{flexDirection:'row', alignItems: 'center', justifyContent: 'center'}}>
              <Feather name="image" style={{fontSize: 24, color: 'white', }}/>
              <View style={{flexDirection:'column',paddingLeft:5, }}>
                <Text style={{fontSize: 14, fontFamily:'Arial', color: 'white', }}>Select</Text>
                <Text style={{fontSize: 14, fontFamily:'Arial', color: 'white', }}>Stock Photo</Text>
              </View>
            </View> } 
          titleStyle={{fontSize: 12}}
          style={{width: 120, paddingLeft: 3}}
          onPress={togglePhotoOverlay}
        />
        <View>
        {/* <StockPhotoOverlay 
          toggleOverlay={togglePhotoOverlay}
          visible={visible}
          meTags={['Fantasy Sports', 'Football', 'Baseball']}
          updateMyImages={updateMyImages}
        /> */}
        </View>
      </View>
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 10,
          minHeight: 70,
          minWidth: '75%',
          //maxWidth: 300,
          paddingTop: 10,
          paddingRight: 10,
        }}
      >
        <FlatList 
          data={myImages}
          extraData={myDisclaimer}
          horizontal={true}
          style={{maxWidth:350}}
          keyExtractor={item => item}
          renderItem={({item, index}) => {
            return (
              <View
                style={{
                  paddingLeft: 10,
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {
                  isVideo(item) ? (
                    <View>
                      <View
                        style={{
                          flexDirection:'row', 
                          justifyContent:'flex-end', 
                          paddingRight:2, 
                          paddingTop:2, 
                          backgroundColor:'black'
                        }}
                      >
                        <Text
                          style={{
                            color:'white', 
                            height:20, 
                            backgroundColor:'#3385E5', 
                            paddingLeft:5, 
                            paddingRight:5,  
                            alignItems: 'center', 
                            justifyContent: 'center'
                          }}
                          onPress={() => {
                            removeImage(item);
                          }}
                        >
                          X
                        </Text>
                      </View>
                      <View>
                        {
                          Platform.OS === 'web' ? (
                            <iframe
                              width="250"
                              height={(isMobile) ? "250" : "180"}
                              src={`${item}`}
                              frameBorder="0"
                              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            />
                          ) : (
                            isUri(item) ? (
                              <WebView 
                                source={{uri: item}}
                                style={{width: 250, height: 180}}
                              />
                            ) : (
                              <Image 
                                source={{uri: 'https://cdn.mesearch.ai/assets/media/videoSymbolLightCard.png'}}
                                style={{width: 250, height: 180, backgroundColor: 'silver'}}
                              />
                            )
                          )
                        }
                      </View>
                    </View>
                  ) : (
                    <ImageBackground
                      style={styles.image}
                      imageStyle={{borderRadius: 4}}
                      source={{uri: `${item}`}}
                      // style={{width: 100, height: 100, marginBottom: 10, backgroundColor: 'silver'}}
                      onError={err => {
                        console.log(err);
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          paddingRight: 2,
                          paddingTop: 2,
                        }}
                      >
                        <Text 
                          style={{
                            color: 'white',
                            height: 20,
                            backgroundColor: '#3385E5',
                            paddingLeft: 5,
                            paddingRight: 5,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          onPress={() => {
                            removeImage(item, index);
                          }}
                        >
                          X
                        </Text>
                      </View>
                    </ImageBackground>
                  )
                }
                {
                  !(/mesearchstoryblocks/.test(item)) ? (
                    <>
                    <View>
                      <Input 

                          inputStyle={{fontSize:12, width:100, height:10, padding:0, color: darkMode ? 'white' : 'black'}}
                          inputContainerStyle={{height:20, width:100}}
                          placeholder='Credits'
                          value={myPhotoCredit[index]}
                          onChangeText={creditTxt => {
                            let newPhotoCredit = myPhotoCredit;
                            newPhotoCredit[index] = creditTxt;
                            setMyPhotoCredit(newPhotoCredit);
                          }}
                        />
                        <Input 

                          inputStyle={{fontSize:12, width:100, padding:0, color: darkMode ? 'white' : 'black'}}
                          inputContainerStyle={{height:20, width:100}}
                          value={myPhotoDesc[index]}
                          placeholder='Description'
                          onChangeText={descriptionTxt => {
                            let newPhotoDesc = myPhotoDesc;
                            newPhotoDesc[index] = descriptionTxt;
                            setMyPhotoDesc(newPhotoDesc);
                          }}
                        />
                    </View>

                    </>
                  ) : null
                }
              </View>
            );
          }}
        />
      </View>
    </View>
  );
};

export default ImagePicker;