import React, { useState, useContext, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, Image, TouchableOpacity, Linking, Dimensions, Share, SafeAreaView, ActivityIndicator } from 'react-native';
import MeSearchAPI from '../api/MeSearch-API';
import { Feather } from '@expo/vector-icons';
import removeSpecialCharacters from '../screens/utils/removeSpecialCharacters';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import { WebView } from 'react-native-webview';
import isVideo from '../screens/utils/isVideo';
import { Context as PubContext } from '../context/PubContext';
import AdUnit from './AdUnit';
import { Button } from 'react-native-elements';
import { FacebookShareButton,TwitterShareButton,EmailShareButton } from "react-share";
import ContentTypes from './utils/contentTypes';
import InlineHyperlink from './inlineHyperlink';
import AdTypesEnum from './utils/AdUnitTypes';
import FetchAdUnitStory from './utils/fetchAdUnitStory';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useResultsv2 from '../hooks/useResultsv2';
import GridDetail from './core/GridDetail';
import BettingLine from './BettingLine';
import useMicroService from '../hooks/useMicroService';
import RenderHTML from 'react-native-render-html';
import IFrameWrapper from './IFrameWrapper';

const httpPrefixToHttps = new RegExp("http:\/\/ttm-triblive-development.us-east-2.elasticbeanstalk.com", "g");
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

function isoDateToHumanReadable(isoDate) {
  const spaceRegex = new RegExp(" ", "g");
  const isoDateFixed = isoDate.trim().replace(spaceRegex, "-");
  let humanReadable = new Date(isoDateFixed);
  return `${days[humanReadable.getDay()]} ${months[humanReadable.getMonth()]} ${humanReadable.getDate()}, ${humanReadable.getFullYear()}`;
};

function minutesFromNow(isoDate) {
  const spaceRegex = new RegExp(" ", "g");
  const isoDateFixed = isoDate.trim().replace(spaceRegex, "-");
  let humanReadable = new Date(isoDateFixed);
  let nowTime = new Date(new Date().getTime());
  return Math.abs(Math.round((((humanReadable.getTime() - nowTime.getTime())/1000)/60)));
};

async function onShare(title, link) {
    let shareUrl = link;
    let shareMessage = shareUrl;
    try {
        const result = await Share.share({
        title:title,
        message:shareMessage,
        });

        /*this was some boilerplate for general sharing functionality
        We probably don't need all of it */
        if (result.action === Share.sharedAction) {
        if (result.activityType) {
            // shared with activity type of result.activityType
        } else {
            // shared
        }
        } else if (result.action === Share.dismissedAction) {
        // dismissed
        }
    } catch (error) {
        console.log(error.message);
    }
};

const getAccessLvl = (user, pub) => {
  let accessLvl = 1;
  if (user.accessLvl) {
    user.accessLvl.forEach(accessObj => {
      if (accessObj.account === pub) {
        accessLvl = Math.max(accessObj.perm, accessLvl);
      }
    });
  }
  return accessLvl;
};

async function updateSocialShare(platform, body_id, pub) {
    const timeStamp = new Date().getTime();
    let device = "";
    if ((Platform.OS === 'web' && !isMobile)) {
      if (isTablet) {
        device = "Tablet";
      } else {
        device = "Desktop";
      }
    } else {
      device = "Mobile";
    }
    const response = await MeSearchAPI.get('/api/user');
    let socialShares = response.data.history.contentShares;
    if (socialShares) {
      socialShares.push({platform: platform, timeStamp: timeStamp, body_id: `${body_id}`, pub: pub, device: device});
    } else {
      socialShares = [{platform: platform, timeStamp: timeStamp, body_id: `${body_id}`, pub: pub, device: device}];
    }
    await MeSearchAPI.patch(
      '/api/user', 
      { payload: { 
          history: {
            searches: response.data.history.searches,
            contentClicks: response.data.history.contentClicks,
            locations: response.data.history.locations,
            accounts: response.data.history.accounts,
            contentShareClicks: response.data.history.contentShareClicks,
            contentShares: socialShares,
            impressions: response.data.history.impressions
          }
        } 
    });
}
  
const ArticleDisplay = ({ darkMode, content, preview, navigation, claimed, refUrl }) => {
  const [curMedia, setCurMedia] = useState(content.body[0].media[0]);
  const [curMediaMeta, setCurMediaMeta] = useState(content.mediaMeta && content.mediaMeta.length > 0 ? content.mediaMeta[0]: undefined);
  const [placeholderFlag, setPlaceholderFlag] = useState(false);
  const [numPlaceholders, setNumPlaceholders] = useState(0);
  const [isBettingLine, setIsBettingLine] = useState(false);
  const [betmgmId, setBetmgmId] = useState('');
  const [team1name, setTeam1name] = useState('');
  const [team2name, setTeam2name] = useState('');
  const [overOrUnder1, setOverOrUnder1] = useState('');
  const [overOrUnder2, setOverOrUnder2] = useState('');
  const [spread1, setSpread1] = useState(0);
  const [spread2, setSpread2] = useState(0);
  const [odds1, setOdds1] = useState(0);
  const [odds2, setOdds2] = useState(0);
  const [ou1, setOu1] = useState(0);
  const [ou2, setOu2] = useState(0);
  const [moneyline1, setMoneyLine1] = useState(0);
  const [moneyline2, setMoneyLine2] = useState(0);
  const [bodyHash, setBodyHash] = useState({});
  const [htmlText, setHtmlText] = useState(content.body[0].html ? content.body[0].bodyContent : null);
  const PubCon = useContext(PubContext);
  const isWhitelabel = (() => {
    if (PubCon.state.publisher.username === 'TribExtra-AP' || PubCon.state.publisher.username === 'Sponsored Sports 2' || PubCon.state.publisher.username === 'BetMGM') {
      return true;
    } else {
      return false;
    }
  })();
  const [linkText, setLinkText] = useState(!isWhitelabel ? 'Click Here For Original Article' : '');
  const [curUrl, setCurUrl] = useState('');
  const [banner1, setBanner1] = useState({adUnit:"",slotID:""});
  const [banner2, setBanner2] = useState({adUnit:"",slotID:""});
  const [inStory1, setInStory1] = useState({adUnit:"",slotID:""});
  const [inStory2, setInStory2] = useState({adUnit:"",slotID:""});
  const [inStory3, setInStory3] = useState({adUnit:"",slotID:""});
  const [user, setUser] = useState({});
  const [getResultsv2, resultsv2, isLoadingv2, errorv2, getKiosk, resultKiosk, getMoreContentApi, moreContent] = useResultsv2();
  const [msError, getBettingLine, bettingLineResults] = useMicroService();
  const isPreview =  preview ? preview : false;
  const [resultSet, setResultSet] = useState([]);

  const getUser = async () => {
    const response = await MeSearchAPI.get('/api/user');
    if (response.data) {
      setUser(response.data);
    }
  };
  
  const getMoreContent = async () => {
    getMoreContentApi({
      page: 0, 
      // sources: [...PubCon.state.publisher.sources], 
      targeting: PubCon.state.publisher.username, 
      userId: user.username,
      tags: [...PubCon.state.publisher.pubTags],
    });
  }

  const styles = StyleSheet.create({
    image: {
      marginTop: 5,
      height: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 200 : 350,
      width: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 285 : 450,
      resizeMode: 'contain',
      marginBottom: 5,
      borderRadius: 4,
      margin: 5,
    },
    imageSmall: {
      marginTop: 5,
      height: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 50 : 50,
      width: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 80 : 80,
      //marginBottom: 5,
      borderRadius: 4,
      margin: 5,
    },
    textStyle: {
      marginTop: 15,
      marginLeft: 5,
      //marginBottom: 15,
      //fontFamily: 'bebas',
      //textTransform: 'uppercase',
      fontWeight: 'bold',
      textAlign: 'justify',
      fontSize: 24,
      color: darkMode ? 'white' : 'black',
    },
    authorStyle: {
      marginTop: 5,
      marginLeft: 5,
      //marginBottom: 15,
      //textTransform: 'uppercase',
      //fontWeight: 'bold',
      fontSize: 14,
      color: darkMode ? 'white' : '#A2A2A2',
    },
    contentStyle: {
      fontWeight: '400',
      marginTop: 5,
      // marginBottom: 5,
      fontSize: 17,
      padding:7,
      // textAlign: 'left',
      color: darkMode ? 'white' : 'black',
      // width: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 300 : 700,
    },
    dateStyle: {
      marginTop: 5,
      marginBottom: 5,
      marginLeft: 5,
      fontSize: 18,
      color: (minutesFromNow(content.body[0].createDate) < 60) ? 'red' : darkMode ? 'white' : '#A2A2A2'
    },
    scrollStyle: {
      marginTop: 15,
      marginBottom: 10,
      backgroundColor: darkMode ? '#3c3c3d' : 'white'
    },
    containerLarge: {
      //margin: 10,
      width: Dimensions.get('window').width,
      paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
      paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
      backgroundColor: darkMode ? '#3c3c3d' : 'white'
    },
    containerSmall: {
      paddingLeft: 5,
      paddingRight: 5,
      //margin: 10,
      //flex: 1,
      backgroundColor: darkMode ? '#3c3c3d' : 'white'
    },
    shareButton: {
      fontSize: 24,
      paddingTop: 5,
      paddingLeft: 20,
      color: darkMode ? 'white' : 'black'
    },
    horizontalRule: {
      marginTop: 5,
      marginBottom: 5,
      backgroundColor: '#A2A2A2',
      height: 2,
      //width: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? Dimensions.get('window').width-20 : 460,
      marginLeft: 5
    },
    imageCredit: {
      //textTransform: 'uppercase',
      fontSize: 10,
      paddingLeft: 5,
      color: darkMode ? 'white' : '#A2A2A2',
    },
    imageCaption: {
      marginTop: 10,
      //textTransform: 'uppercase',
      fontSize: 10,
      paddingLeft: 5,
      color: darkMode ? 'white' : '#A2A2A2',
    }
  });

  function getEmbedMedia(position) {
    let mediaMetaMatch;
    if (content.mediaMeta && content.mediaMeta.length > 0) {
      content.mediaMeta.some(mediaMetaObj => {
        if (mediaMetaObj.pos == position) {
          mediaMetaMatch = mediaMetaObj;
          return true;
        }
      });
    }
    return mediaMetaMatch;
  }
  
  function checkForHyperLinks(position) {
    let bodyLinkMatchs = [];
    if (content.bodyLinks && content.bodyLinks.length > 0) {
      content.bodyLinks.forEach(bodyLinkObj => {
        if (bodyLinkObj.pos == position) {
          bodyLinkMatchs.push(bodyLinkObj);
        }
      });
    }
    return bodyLinkMatchs;
  }

  function countPlaceholders(str) {
    return ((str || '').match(/\{PLACEHOLDER\}/g) || []).length;
  }

  async function createBodyHash() {
    const contentBody = content.body[0].bodyContent;
    const breakline = new RegExp('([ ]{4})', "g");
    const doubleSpace = new RegExp('([ ]{2})', "g");
    const placeholder = /\{PLACEHOLDER\}/g;

    // flag and count occurrences of {PLACEHOLDER}.
    const isPlaceholder = placeholder.test(contentBody);
    
    let bodySplit = [];
    if (isPlaceholder) {
      const countOfPlaceholders = countPlaceholders(contentBody);
      setNumPlaceholders(countOfPlaceholders);
      bodySplit = removeSpecialCharacters(contentBody).replace(breakline, "\n\n")
      .replace(doubleSpace, "")
      .replace(placeholder, "")
      .replace(/\n(\s+)/g, "\n\n")
      .replace(/,(?=[^\s\d])/g, ", ")
      .replace(/^\w+,\w+\s\d+,\d+\s\|\s\d+:\d+\s\w+\n/, "")
      .split("\n\n");
    } else {
      bodySplit = removeSpecialCharacters(contentBody).replace(breakline, "\n\n")
      .replace(doubleSpace, "")
      .replace(/\n(\s+)/g, "\n\n")
      .replace(/,(?=[^\s\d])/g, ", ")
      .replace(/^\w+,\w+\s\d+,\d+\s\|\s\d+:\d+\s\w+\n/, "")
      .split("\n\n");
    }

    // const bodySplit = removeSpecialCharacters(contentBody).replace(breakline, "\n\n")
    //   .replace(doubleSpace, "")
    //   .replace(placeholder, "")
    //   .replace(/\n(\s+)/g, "\n\n")
    //   .replace(/,(?=[^\s\d])/g, ", ")
    //   .replace(/^\w+,\w+\s\d+,\d+\s\|\s\d+:\d+\s\w+\n/, "")
    //   .split("\n\n");

    // let bodyHash = {};
    
    bodySplit.forEach((bodyObj, index) => {
      bodyHash[`${index}`] = bodyObj;
    });
    return bodyHash;
  }

  const createBodyObj = async () => {
    const bodyHash = await createBodyHash();
    setBodyHash(bodyHash);
  };

  // const bodyHash = createBodyHash();

  const isPointPark = ((src) => {
    return [
      'PointParkUniversity-UGC',
      'PPUGlobe-UGC',
      'PPUGlobe',
      'Point Park News Service-UGC',
      'Point Park News Service',
      'PointParkNewsService-UGC',
    ].includes(src);
  })(content.source)

  useEffect (() => {
    if (!isPreview) {
      if (PubCon.state.ref.includes('?p')) {
        const urlComps = PubCon.state.ref.split('?');
        let prefix = urlComps[0];
        //let urlSuffix = `content-page/?${urlComps[1]}&body_id=${content.body_id}`;
        let urlSuffix = `${urlComps[1].replace('p\=', '')}/${encodeURIComponent(content.body[0].title !== '' ? content.body[0].title.toLowerCase(): 'x')}/bid${content.body_id}`;
        setCurUrl(`${prefix}${urlSuffix}`);
      } else {
        setCurUrl(`${PubCon.state.ref}/${encodeURIComponent(content.body[0].title.toLowerCase())}/bid${content.body_id}`);
      }
      if (Platform.OS === 'web' && PubCon.state.publisher.dfpAds){
        setBanner1(...(PubCon.state.publisher.adSlots.filter(a => a.type === AdTypesEnum.BANNER1)))
        setBanner2(...(PubCon.state.publisher.adSlots.filter(a => a.type === AdTypesEnum.BANNER2)))
        setInStory1(...(PubCon.state.publisher.adSlots.filter(a => a.type === AdTypesEnum.INSTORY1)))
        setInStory2(...(PubCon.state.publisher.adSlots.filter(a => a.type === AdTypesEnum.INSTORY2)))
        setInStory3(...(PubCon.state.publisher.adSlots.filter(a => a.type === AdTypesEnum.INSTORY3)))
      }
      getBettingLine({meTags: content.meTags});
      if (msError) {
        console.log(msError);
      }
    }
    getUser();
    if (!htmlText) {
      createBodyObj();
    }
  }, []);

  // set state items with results from useMicroService hook
  useEffect(() => {
    if (!isPreview) {
      if (bettingLineResults && bettingLineResults.bettingLine !== false) {
        setIsBettingLine(true);
        const team1 = bettingLineResults['team1'];
        const team2 = bettingLineResults['team2'];
        let spread1 = team1?.FullTime?.Spread?.spreadNumber ?? '--';
        let spread2 = team2?.FullTime?.Spread?.spreadNumber ?? '--';
        let oou1 = team1?.FullTime?.Totals?.overOrUnder ?? '--';
        let oou2 = team2?.FullTime?.Totals?.overOrUnder ?? '--';
        let odds1 = team1?.FullTime?.Totals?.usOdds ?? '--';
        let odds2 = team2?.FullTime?.Totals?.usOdds ?? '--';
        let ou1 = team1?.FullTime?.Totals?.overUnderNumber ?? '--';
        let ou2 = team2?.FullTime?.Totals?.overUnderNumber ?? '--';
        setBetmgmId(bettingLineResults.BetMGM_ID);
        setOverOrUnder1(oou1);
        setOverOrUnder2(oou2);
        setTeam1name(team1.name);
        setTeam2name(team2.name);
        setSpread1(spread1);
        setSpread2(spread2);
        setOdds1(odds1);
        setOdds2(odds2);
        setOu1(ou1);
        setOu2(ou2);
        setMoneyLine1(team1['FullTime']['Money Line'].usOdds ?? '--');
        setMoneyLine2(team2['FullTime']['Money Line'].usOdds ?? '--');
      }
    }
  }, [bettingLineResults]);

  useEffect(() => {
    if (Object.values(user).length > 0) {
      getMoreContent();
    }
  }, [user]);

  useEffect(() => {
    if (moreContent.length > 0) {
      const result = moreContent.slice(0, 8);
      setResultSet(result);
    }
  }, [moreContent]);

  if (!content.body || content.body.length === 0) {
    return null;
  }

  return (
    <SafeAreaView
      style={!isPreview ? Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall : {padding:20}}
      showsVerticalScrollIndicator={false}
    >

    <View style={{padding: 10,}}>
    { (Platform.OS === 'web' && PubCon.state.publisher.dfpAds && banner1["adUnit"] != "") ? 
          (isMobile)?
          <AdUnit
              dfpNetworkID={PubCon.state.publisher.dfpNetworkID} 
              adPlatform={PubCon.state.publisher.adPlatform} 
              adUnit={banner1["adUnit"]}
              slotID={banner1["slotID"]}
              sizes={[[320, 50], [300, 50]]}
          ></AdUnit>
          :
          <AdUnit
              dfpNetworkID={PubCon.state.publisher.dfpNetworkID} 
              adPlatform={PubCon.state.publisher.adPlatform} 
              adUnit={banner1["adUnit"]}
              slotID={banner1["slotID"]}
              sizes={[[728, 90]]}
          ></AdUnit>
      : null}
      </View>
    {
      refUrl !== '' || PubCon.state.publisher.username !== 'TribExtra-AP' || PubCon.state.publisher.username !== 'Sponsored Sports 2' ? (
        <View style={{ marginTop: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 15 : 30}}>
          <Image style={{marginLeft: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? -5 : !preview ? -70 : 0, height: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 100 : 150, width: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 325 : 500}} source={{uri: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? PubCon.state.publisher.assets[1].link /*Top-Header-Mobile*/ : PubCon.state.publisher.assets[0].link /*Top-Header-Web*/}}/>
        </View>
      ) : null
    }
    <Text style={styles.textStyle}>{removeSpecialCharacters(content.body[0].title)}</Text>
    <View style={styles.horizontalRule} />
      <View style={{ flexDirection: "row",  }}>
        {(content.type !== ContentTypes.ADVERTISEMENT && content.type !== ContentTypes.ADVERTISEMENTLINKVIEW && content.type !== ContentTypes.ADVERTISEMENTFRAMEVIEW)
        ? <Text style={styles.dateStyle}>
          {`${
            minutesFromNow(content.body[0].createDate.split(",")[0]) < 60
              ? `${minutesFromNow(
                  content.body[0].createDate.split(",")[0]
                )} minutes ago`
              : isoDateToHumanReadable(content.body[0].createDate.split(",")[0])
          }`}
        </Text>
        : null}
        { Platform.OS === 'web' && !isPreview ?
        <>
          <TouchableOpacity
            onPress={() => {
              updateSocialShare("email", content.body_id, PubCon.state.publisher.username)
            }}
          >
            <EmailShareButton
              subject={content.body[0].title}
              body={"News story from MeSearch.ai:"}
              separator={" "}
              url={`${curUrl}`}
            >
              <Feather name="mail" style={styles.shareButton}/>
            </EmailShareButton>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              updateSocialShare("facebook", content.body_id, PubCon.state.publisher.username)
            }}
          >
            <FacebookShareButton
              url={`${curUrl}`}
              hastag="MeSearch"
            >
            <Feather name="facebook" style={styles.shareButton}/>
            </FacebookShareButton>
            </TouchableOpacity>
            <TouchableOpacity
            onPress={() => {
              updateSocialShare("twitter", content.body_id, PubCon.state.publisher.username)
            }}
          >
            <TwitterShareButton
              url={`${curUrl}`}
              via="MeSearch"
              title={content.body[0].title+'\n'}
              hashtags={["MeSearch"]}
            >
              <Feather name="twitter" style={styles.shareButton}/>
            </TwitterShareButton>
          </TouchableOpacity>
          {(user.accessLvl && getAccessLvl(user, PubCon.state.publisher.owner) >= 3)  ?
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('TagManager', {meTags: content.meTags, nativeTags: content.tags, body_id: content.body_id});
              }}
            >
    
              <Feather name="tag" style={[styles.shareButton, {marginTop: -3}]}/>
            </TouchableOpacity>
          : null}      
          {
          // (user.accessLvl && getAccessLvl(user, PubCon.state.publisher.owner) >= 3 && isWhitelabel)  
          (user.accessLvl && getAccessLvl(user, PubCon.state.publisher.owner) >= 1)
          ?
            <TouchableOpacity
            // onPress={() => window.open('https://mobile.knowledgekiosk.com/pubstats.htm')}
            onPress={() => navigation.navigate('analytics', {reportName: PubCon.state.publisher.username, body_id: content.body_id})}
            >
              {/* <Text>{content.body_id}</Text> */}
              <Feather name="bar-chart-2" style={[styles.shareButton, {marginTop: -3}]}/>
            </TouchableOpacity>
          : null}
        </>
        : 
        <>
          <TouchableOpacity
          onPress={()=> {
            onShare(content.body[0].title, `${curUrl}`)
          }} 
          >
            <Feather name="share-2" style={styles.shareButton}/>
          </TouchableOpacity>
          {(user.accessLvl && getAccessLvl(user, PubCon.state.publisher.owner) >= 3)  ?
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('TagManager', {meTags: content.meTags, nativeTags: content.tags, body_id: content.body_id});
              }}
            >
    
              <Feather name="tag" style={styles.shareButton}/>
            </TouchableOpacity>
          : null}
        </>
        }
      </View>
    <View style={styles.horizontalRule} />
    <View>
      {isVideo(curMediaMeta ? curMediaMeta.uri : curMedia)
      ? <View>
          {Platform.OS === 'web'
            ? <iframe 
              width={(isMobile) ? "250" : "450"}
              height="350" 
              src={`${curMediaMeta ? curMediaMeta.uri : curMedia}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
            />
            : <WebView source={{uri: curMediaMeta ? curMediaMeta.uri : curMedia}} style={{width: 285, height: 200}}/>}
        </View>
      : <Image style={styles.image} blurRadius={(content.claimed && content.claimed === "no") ? 10 : null} source={{ uri: curMediaMeta ? curMediaMeta.uri.replace(httpPrefixToHttps, "https:\/\/triblive.com") : curMedia.replace(httpPrefixToHttps, "https:\/\/triblive.com") }}/>}
      {curMediaMeta && (/\S/.test(curMediaMeta.credit)) ?
      <View style={{flexDirection: 'row', width: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 290 : 455}}>
        <View>
          {(curMediaMeta && curMediaMeta.credit) ? <Text style={styles.imageCredit}>{`${removeSpecialCharacters(curMediaMeta.credit)}`}</Text> : null}
        </View>
      </View>
      : null}
      {curMediaMeta && (/\S/.test(curMediaMeta.caption)) ?
      <View style={{ width: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 290 : 455}}>
        {(curMediaMeta && curMediaMeta.caption) ? <Text style={styles.imageCaption}>{`${removeSpecialCharacters(curMediaMeta.caption)}`}</Text> : null}
      </View>
      : null}
    </View>
    {((content.mediaMeta && content.mediaMeta.length > 1) || (content.body[0].media && content.body[0].media.length > 1))
    ? <FlatList
      style={{
        paddingTop: 5,
        height: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 75 : 75,
        width: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 285 : 450
      }}
      horizontal={true}
      data={content.mediaMeta && content.mediaMeta.length > 0 ? content.mediaMeta : content.body[0].media}
      keyExtractor={(photo) =>  content.mediaMeta && content.mediaMeta.length > 0 ? (photo._id ? photo._id : photo.uri) : photo}
      renderItem={({ item }) => {
        return (
          <TouchableOpacity
            onPress={() => {
              if (item.uri) {
                setCurMediaMeta(item)
              } else {
                setCurMedia(item)
              }
            }}
            style={{opacity: Platform.OS === 'web' ? (item === (curMediaMeta ? curMediaMeta : curMedia) ? 1 : .4) : null}}
          >
            <Image
              blurRadius={(content.claimed && content.claimed === "no") ? 10 : null}
              style={styles.imageSmall}
              source={!isVideo(item.uri ? item.uri : item) ? { uri: (item.uri ? item.uri.replace(httpPrefixToHttps, "https:\/\/triblive.com") : item.replace(httpPrefixToHttps, "https:\/\/triblive.com")) } : darkMode ?  require('../../assets/videoSymbolDark.png') : require('../../assets/videoSymbolLight.png')}
            />
          </TouchableOpacity>
        );
      }}
    /> : null}
    {/*this view is for the article author */}
    <View style={{flexDirection: 'row', height: 50,}}>
    {(content.body[0].authors[0] && content.body[0].authors[0].length > 0) && !content.claimed ? <Text style={styles.authorStyle}>{`By ${content.body[0].authors.toString()}   `}</Text> : null}
    {
      PubCon.state.publisher.username === 'AP' ? <Image style={{ marginTop: 3, height: 50, width: 65 }} source="https://cdn.mesearch.ai/assets/badgeImg/ap.jpg" /> : null
    }
    { content.userGen ? 
      !isPointPark ? (
        <Image style={{ marginTop: 3, height: content.userGen.accessLvl === 1 ? 22 : 20, width: content.userGen.accessLvl === 1 ? 85 : 65}} 
          source={content.userGen.accessLvl === 1 ? require('../../assets/community.png') : require('../../assets/trusted.png') }/>
      ) : null
      : null
    }
    </View>
    {
      numPlaceholders > 0 && isBettingLine !== false ? (
        <BettingLine 
          team1name={team1name}
          team2name={team2name}
          spread1={spread1}
          spread2={spread2}
          odds1={odds1}
          odds2={odds2}
          ou1={ou1}
          ou2={ou2}
          moneyline1={moneyline1}
          moneyline2={moneyline2}
          overOrUnder1={overOrUnder1}
          overOrUnder2={overOrUnder2}
          betmgmId={betmgmId}
          trackingCode={PubCon.state.publisher.trackingCode ?? null}
        />
      ) : null
    }
    {
      !htmlText ? (
        <FlatList
          data={Object.keys(bodyHash)}
          keyExtractor={(bodyHashKey) => bodyHashKey}
          renderItem={({ item, index }) => {
            const itemValue = bodyHash[item];
            return (
              <>
                { (Platform.OS === 'web' && PubCon.state.publisher.dfpAds ) ? 
                  <FetchAdUnitStory
                    index={index}
                    dfpNetworkID={PubCon.state.publisher.dfpNetworkID} 
                    adPlatform={PubCon.state.publisher.adPlatform} 
                    sizes={[[300,250]]}
                    inStory1={inStory1}
                    inStory2={inStory2}
                    inStory3={inStory3}
                  ></FetchAdUnitStory>
                : null}
                {getEmbedMedia(index) ?    
                  <View>
                    {isVideo(getEmbedMedia(index)) ?
                    <View>
                      {Platform.OS === 'web'
                        ? <iframe 
                          width={(isMobile) ? "250" : "450"}
                          height="350" 
                          src={`${getEmbedMedia(index)}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                          allowFullScreen
                        />
                        : <WebView source={{uri: getEmbedMedia(index).uri}} style={{width: 285, height: 200}}/>}
                    </View>
                    : <Image style={styles.image} source={{ uri: getEmbedMedia(index).uri.replace(httpPrefixToHttps, "https:\/\/triblive.com")}}/>}
                    {(/\S/.test(getEmbedMedia(index).credit)) ?
                    <View style={{flexDirection: 'row', width: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 290 : 455}}>
                      <View style={{alignItems: 'flex-start', flex: 1}}>
                        {(getEmbedMedia(index).credit) ? <Text style={styles.imageCredit}>{`${removeSpecialCharacters(getEmbedMedia(index).credit)}`}</Text> : null}
                      </View>
                    </View>
                    : null}
                    {(/\S/.test(getEmbedMedia(index).caption)) ?
                    <View style={{flex: 1, width: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 290 : 455}}>
                      {(getEmbedMedia(index).caption) ? <Text style={styles.imageCaption}>{`${removeSpecialCharacters(getEmbedMedia(index).caption)}`}</Text> : null}
                    </View>
                    : null}
                  </View>
                :null}
    
                {!checkForHyperLinks(index).length > 0
                ?
                  <Text style={styles.contentStyle}>{itemValue}</Text>
                :
                <View style={styles.contentStyle}>
                  <InlineHyperlink
                    darkMode={darkMode}
                    text={itemValue}
                    bodyLinks={checkForHyperLinks(index)}
                  />
                </View>
                }
    
              </>
            );
          }}
        />
      ) : (
        <>
        {Platform.OS === 'web' ? (
          // <iframe 
          //   srcDoc={htmlText}
          //   width={(isMobile) ? "250" : "450"}
          //   height="350"
          //   frameBorder="0"
          //   allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          // />
          <IFrameWrapper 
            srcDoc={htmlText}
            // width={(isMobile) ? "250" : "450"}
            id="html-frame"
          />
        ) : (
          <RenderHTML 
            source={htmlText}
          />
        )}
        </>
      )
    }
    {
      numPlaceholders > 1 && isBettingLine !== false ? (
        <BettingLine 
          team1name={team1name}
          team2name={team2name}
          spread1={spread1}
          spread2={spread2}
          odds1={odds1}
          odds2={odds2}
          ou1={ou1}
          ou2={ou2}
          moneyline1={moneyline1}
          moneyline2={moneyline2}
          overOrUnder1={overOrUnder1}
          overOrUnder2={overOrUnder2}
          betmgmId={betmgmId}
          trackingCode={PubCon.state.publisher.trackingCode ?? null}
        />
      ) : null
    }
    <View style={{ paddingLeft: 15, paddingBottom: 5, paddingTop: 5}}>
    { (Platform.OS === 'web' && PubCon.state.publisher.dfpAds ) && banner2["adUnit"] != ""? 
          (isMobile)?
          <AdUnit
              dfpNetworkID={PubCon.state.publisher.dfpNetworkID} 
              adPlatform={PubCon.state.publisher.adPlatform} 
              adUnit={banner2["adUnit"]}
              slotID={banner2["slotID"]}
              sizes={[[320, 50], [300, 50]]}
          ></AdUnit>
          :
          <AdUnit
              dfpNetworkID={PubCon.state.publisher.dfpNetworkID} 
              adPlatform={PubCon.state.publisher.adPlatform} 
              adUnit={banner2["adUnit"]}
              slotID={banner2["slotID"]}
              sizes={[[728, 90]]}
          ></AdUnit>
      : null}
    </View>

    <View style={{flexDirection: 'row', marginTop: 10, marginBottom: 10}}>
    {(content.claimed && content.claimed === "no") ?
      <Button
        title={'Claim'}
        style={{width: 115, padding: 15}}
        onPress={async () => {
          let isGuest = true;
          const response = await MeSearchAPI.get('/api/user');
          isGuest = response.data.guest;
          if (isGuest) {
            navigation.navigate('Signin/Signup', {screen: 'Signin'});
          } else {
            navigation.navigate('ClaimBusiness', {body_id: content.body_id, content: content});
          }
      }}
    />
    : null}
    {
      (!content.userGen && !content.claimed && !claimed) ? (
        <Text style={{ color: linkText !== 'An error occured' ? 'blue' : 'red', marginTop: 5, marginLeft: 5 }} onPress={ () => Platform.OS === 'web' ? window.open(content.body[0].link) : Linking.openURL(content.body[0].link) }>{`${linkText}`}</Text>
      ) : null
    }
      <View style={{alignItems: 'flex-end', flex: 1}}>
        <Text style={{ color:  darkMode ? 'white' : '#6a6b6a', fontSize: 20, paddingRight: 10, marginBottom: 5}}>{`${content.source}`}</Text>
      </View>
    </View>
    { isWhitelabel
    ?
    <View style={{flexDirection: 'row', marginTop: 10, marginBottom: 10}}> 
      {
        moreContent ? (
          <FlatList 
            data={resultSet}
            keyExtractor={item => item._id} 
            numColumns={(Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web' ? 1 : 4} 
            style={{ flex: 1 }} 
            contentContainerStyle={{ paddingVertical: 20 }}
            renderItem={({ item }) => (
              <TouchableOpacity style={{ flex: 1, marginHorizontal: 20, marginBottom: 20 }}
                onPress={() => navigation.push('ResultsShow', { content: item })}
              >
                <GridDetail 
                  title={item.body[0].title} 
                  // srcStampImg={} 
                  // typeStampImg={} 
                  defaultImage={PubCon.state.publisher.badgeSourceMap[item.source]} 
                  // byline={} 
                  // userGen={} 
                  media={item.body[0].media} 
                  type={item.type}
                  horizontal={true}
                />
              </TouchableOpacity>
            )}
          />
        ) : <ActivityIndicator size={"large"} style={{marginTop: 150}}/>
      }
    </View>
      :null
    }
  </SafeAreaView>
  );
};


export default ArticleDisplay;