import React, { useState, useEffect } from 'react';
import  { CheckBox } from 'react-native-elements';
import { View, Text, Image, ImageBackground, Dimensions, TouchableOpacity, Animated } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { Platform } from 'react-native';
import alertToConfirm from '../utils/alertToConfirm';
import { isMobile } from 'react-device-detect';


const SetTile = ({ title, numDocs, setSetChecks, setChecks, forumId, onPress, indx, set, portfolioId,
  setFocusCenterChecks, focusCenterChecks, setSets, accountId, removeSet, acctSets, setSuccessMsg, setErrorMsg, navigation, totalCoded, isLocked, endIndx }) => {
  const [animatedValue, setAnimatedValue] = useState(new Animated.Value(0));
  const [frontInterpolate, setFrontInterpolate] = useState(animatedValue.interpolate({
    inputRange: [0, 180],
    outputRange: ['0deg', '180deg']
  }));
  const [frontAnimatedStyle, setFrontAnimatedStyle] = useState({
    transform: [
      { rotateX: frontInterpolate }
    ]
  });

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      animatedValue.setValue(0);
    });
    return unsubscribe;
  }, []);
  
  return (
    <Animated.View 
      style={[
        {
          backgroundColor: indx % 2 === 0 ? '#F0F8FF' : '#bce0eb', 
          width: Math.min(Dimensions.get('window').width-26, 600), 
          backfaceVisibility: 'hidden',
          borderBottomLeftRadius: indx === endIndx ? 6 : null,
          borderBottomRightRadius: indx === endIndx ? 6 : null
        }, 
        frontAnimatedStyle
      ]}
    >
        <View style={{justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'row'}}>
            <CheckBox
                title=""
                onPress={() => {
                    let newSetChecks = JSON.parse(JSON.stringify(setChecks));
                    newSetChecks[portfolioId] = !setChecks[portfolioId] ? true : false;
                    setSetChecks(newSetChecks);
                    let newFocusChecks = {};
                    newFocusChecks[forumId] = !newFocusChecks[forumId] ? true : false;
                    setFocusCenterChecks(newFocusChecks);
                }}
                checkedColor={'#007bff'}
                checked={setChecks[portfolioId]}
            />
            <TouchableOpacity 
              onPress={() => {
                Animated.timing(animatedValue, {
                  toValue: 180,
                  duration: 800
                }).start();
                setTimeout(() => {
                  onPress(set);
                }, 500);
              }}
              style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 15}}
            >
                <Text style={{fontSize: 20, fontWeight: '400', paddingLeft: 10, color: 'blue'}}>{title}</Text>
            </TouchableOpacity>
        </View>
        <View>
            <View style={{flexDirection: 'row'}}>
                <Text style={{margin: 10}}>{`Total Docs: ${numDocs}`}</Text>
                <Text style={{margin: 10}}>{`Total Coded: ${totalCoded}/${numDocs}`}</Text>
                <Text style={{margin: 10}}>{isLocked ? `Locked` : 'Unlocked'}</Text>
                <Feather name={!isLocked ? 'unlock' : 'lock'} style={{ fontSize: 15, marginTop: 11, color: !isLocked ? 'green' : 'red', marginLeft: -3}}/>
                <View style={{flex: 1, alignItems: 'flex-end'}}>
                  <TouchableOpacity
                    onPress={Platform.OS === 'web' ?
                        async () => {
                            alertToConfirm('web', "Are you sure you want to remove this collection?", async () => {
                              const res = await removeSet(accountId, portfolioId, forumId);
                              if (res === "Success") {
                                setSets(acctSets.filter(d => d.Portfolioid != portfolioId));
                                setSuccessMsg("Successfully Removed");
                                setTimeout(() => {
                                  setSuccessMsg("");
                                }, 3000);
                              } else {
                                setErrorMsg("Failed To Remove");
                                setTimeout(() => {
                                  setErrorMsg("");
                                }, 3000);
                              }
                            })();
                        }
                        :
                        async () => {
                            alertToConfirm('mobile', "Are you sure you want to remove this collection?", async () => {
                                const res = await removeSet(accountId, portfolioId, forumId);
                                if (res === "Success") {
                                  setSets(acctSets.filter(d => d.Portfolioid != portfolioId));
                                  setSuccessMsg("Successfully Removed");
                                  setTimeout(() => {
                                    setSuccessMsg("");
                                  }, 3000);
                                } else {
                                  setErrorMsg("Failed To Remove");
                                  setTimeout(() => {
                                    setErrorMsg("");
                                  }, 3000);
                                }
                            })();
                        }
                    }
                  >
                    <Feather name="trash-2" style={{ fontSize: 25, paddingLeft: 10, paddingRight: 25}}/>
                  </TouchableOpacity>
                </View>
            </View>
        </View>
    </Animated.View>
  );
};

export default React.memo(SetTile);