import React, { useContext, useState, useEffect } from 'react';
import  { Text, Button, Input, CheckBox } from 'react-native-elements';
import {Picker, StyleSheet, Image, FlatList, Dimensions, View, ScrollView, SafeAreaView, ImageBackground, TouchableOpacity } from 'react-native';
import Logo from '../../../assets/Crivella-Ai-logo.png';
import Spacer from '../Space';
import { Feather } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { navigate } from '../../navigationRef';
import { Platform } from 'react-native';
import PDFReader from 'rn-pdf-reader-js';

    const kioskGrid = ({ navigation, activeAcct, Portfolioid, Forumid, accounts, accountGrpDetails, accountGrpSets, 
        portfolioItems, setAccountid, setForumid, 
        setPortfolioid, getPdfBase64, pdfBase64,
        setResults, viewResults,
        selectedAcctName, setAcctName,
        selectedAcctGrp, setAcctGrp,
        selectedSetName, setSetName,
        viewRS, setViewRS
        }) => {

    // Render use JSX syntax
    return (
        <>
        <View>   
        <View style={styles.titleStyle}>
            <View style={{ flexDirection:'row', justifyContent:'space-between' }}>
                <Text style={{fontSize: 18,  color: '#2867B2',}}>Accounts: </Text> 
                <Text style={styles.textDescStyle} >{((selectedAcctName).length > 25) ? 
                (((selectedAcctName).substring(0,25-3)) + '...') : 
                selectedAcctName }</Text>
                <View style={{ flexDirection:'row'}}>
                    <Feather name="refresh-cw" style={{fontSize: 20, color: 'white', width: 35}} onPress={() => {setViewRS('Accounts:'); setResults(!viewResults)}}/>
                </View>
            </View>                 
        </View>

        <FlatList
            data={accounts}
            horizontal={true}
            keyExtractor={(item) => item.AccountId }
            renderItem={(item) => {
                return (
                <View style={{ paddingRight:10}}>
                    <TouchableOpacity onPress={() => {setAccountid(item.item.AccountId); setAcctName(item.item.OwnersAccountTitle)}}>
                        <LinearGradient
                        colors={['#4c669f', '#3b5998', '#192f6a']} // blue
                        style={{ flex:1,  alignItems: 'center', justifyContent: 'center', width: 250, height: 180, borderRadius: 5,}}>
                        <Image
                        source={{uri: "https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/cdn1/AccountImage_"+ `${item.item.AccountId}` + ".png"}}
                        style={{flex:1, width: 250, maxHeight: 180}}
                        resizeMode="contain"
                        />   
                        </LinearGradient>
                    </TouchableOpacity>

                    <View style={{height: 70, width: 250, marginBottom:20, backgroundColor: '#000000', opacity: 0.9}}>
                    
                        <View style={{ height: 50, paddingTop: 1, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <Text numberOfLines={2} style={styles.name} >{item.item.OwnersAccountTitle}</Text>
                        </View>
                        <View style={{ flexDirection:'row', justifyContent:'space-between', height: 30, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <View style={{ flexDirection:'row', paddingLeft: 5,}}>
                            <Text numberOfLines={1} style={styles.textDescStyle}>{item.item.AccountId} / {item.item.KioskID}</Text>
                            </View>
                            <View style={{ flexDirection:'row'}}>
                            <Feather name="share-2" style={{fontSize: 20, color: 'white', width: 35}}/>
                            <Feather name="activity" style={{fontSize: 20, color: 'white', width: 35}}/>
                            <Feather name="settings" style={{fontSize: 20, color: 'white', width: 35}} onPress={() => {setAccountid(item.item.AccountId); setAcctName(item.item.OwnersAccountTitle)}}/>
                            </View>
                        </View>
                        
                    </View>
                </View>
                )
            }
            }
        />

        { selectedAcctName != '' 
        ? 
        <>
        <View style={styles.titleStyle}>
            <View style={{ flexDirection:'row', justifyContent:'space-between',}}>
                <Text style={{fontSize: 18, color: '#2867B2',}}>Focus Rooms: </Text>
                <Text style={styles.textDescStyle} >{((selectedAcctGrp).length > 25) ? 
                (((selectedAcctGrp).substring(0,25-3)) + '...') : 
                selectedAcctGrp }</Text>
                <View style={{ flexDirection:'row'}}>
                    <Feather name="refresh-cw" style={{fontSize: 20, color: 'white', width: 35}} onPress={() => { setViewRS('Focus Rooms:'); setResults(!viewResults)}}/>
                </View>
            </View>                 
        </View>              
        <FlatList
            data={accountGrpDetails}
            horizontal={true}
            keyExtractor={(item) => item.id }
            renderItem={(item) => {
                return (
                <View style={{ paddingRight:10}}> 
                    <TouchableOpacity onPress={() => {setForumid(item.item.Forumid); setAcctGrp(item.item.GroupName)}}>
                        <LinearGradient
                        colors={['#9f4c4c', '#983b3b', '#6a1919']} /// red
                        style={{  alignItems: 'center', borderRadius: 5, width: 250, height: 180, }}>
                        <Image
                        //source={{uri: `${item.item.uri}`}}
                        source={{uri: "https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/cdn1/GroupImage_" + `${item.item.Groupid}` +'_'+ `${item.item.AccountId}` + ".png"}}
                        defaultSource='https://cdn.mesearch.ai/assets/default/Crivella-Ai-logo_Blu.png'
                        style={{flex:1, width: 250, maxHeight: 180}}
                        resizeMode="contain"
                        />   
                        </LinearGradient>
                    </TouchableOpacity>

                    <View style={{height: 70, width: 250, marginBottom:20, backgroundColor: '#000000', opacity: 0.9}}>
                        <View style={{ height: 50, paddingTop: 1, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <Text numberOfLines={2} style={styles.name}  >{item.item.GroupName}</Text>
                        </View>
                        <View style={{ flexDirection:'row', justifyContent:'space-between', height: 30, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <View style={{ flexDirection:'row', paddingLeft: 5,}}>
                            <Text numberOfLines={1} style={styles.textDescStyle}>{item.item.Groupid}_{item.item.AccountId}</Text>
                            </View>
                            <View style={{ flexDirection:'row'}}>
                            <Feather name="share-2" style={{fontSize: 20, color: 'white', width: 35}}/>
                            <Feather name="activity" style={{fontSize: 20, color: 'white', width: 35}}/>
                            <Feather name="settings" style={{fontSize: 20, color: 'white', width: 35}} onPress={() => {setForumid(item.item.Forumid); setAcctGrp(item.item.GroupName)} }/>
                            </View>
                        </View>
                    </View>
                </View>
                )
            }
            }
        />
        </>
        : null
        }

        { selectedAcctGrp != '' 
        ? 
        <>
        <View style={styles.titleStyle}>
            <View style={{ flexDirection:'row', justifyContent:'space-between'}}>
                <Text style={{fontSize: 18, color: '#2867B2',}}>Sets:</Text>
                <Text style={styles.textDescStyle} >{((selectedSetName).length > 25) ? 
                (((selectedSetName).substring(0,25-3)) + '...') : 
                selectedSetName }</Text>
                <View style={{ flexDirection:'row'}}>
                    <Feather name="refresh-cw" style={{fontSize: 20, color: 'white', width: 35}} onPress={() => { setViewRS('Sets:'); setResults(!viewResults)}}/>
                </View>
            </View>                 
        </View>            
        <FlatList
            data={accountGrpSets}
            horizontal={true}
            keyExtractor={(item) => item.id }
            renderItem={(item) => {
                return (
                <View style={{ paddingRight:10}}> 
                    <TouchableOpacity onPress={() => {setPortfolioid(item.item.Portfolioid); setSetName(item.item.PortfolioName)}}>
                        <LinearGradient
                        colors={['#4c9f4c', '#47983b', '#196a25']} // Green
                        style={{  alignItems: 'center', borderRadius: 5, width: 250, height: 180, }}>
                                <View style={{ flexDirection:'column', alignSelf: 'flex-end', marginTop:10}}>
                                    <Feather name="edit" style={{fontSize: 20, color: 'white', width: 35}}/>
                                    <Feather name="share-2" style={{fontSize: 20, color: 'white', width: 35}}/>
                                    <Feather name="star" style={{fontSize: 20, color: 'white', width: 35}}/>
                                </View>  
                                <View style={{paddingLeft:5, width:250}}>
                                
                                </View>
                        </LinearGradient>  
                    </TouchableOpacity>

                    <View style={{height: 70, width: 250, marginBottom:20, backgroundColor: '#000000', opacity: 0.9}}>
                        <View style={{ height: 50, paddingTop: 1, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <Text numberOfLines={2} style={styles.name}  >{item.item.PortfolioName}</Text>
                        </View>
                        <View style={{ flexDirection:'row', justifyContent:'space-between', height: 30, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <View style={{ flexDirection:'row', paddingLeft: 5,}}>
                            <Text numberOfLines={1} style={styles.textDescStyle}></Text>
                            </View>
                            <View style={{ flexDirection:'row'}}>
                            <Feather name="share-2" style={{fontSize: 20, color: 'white', width: 35}}/>
                            <Feather name="activity" style={{fontSize: 20, color: 'white', width: 35}}/>
                            <Feather name="settings" style={{fontSize: 20, color: 'white', width: 35}} onPress={() => {setPortfolioid(item.item.Portfolioid); setSetName(item.item.PortfolioName) }}/>
                            </View>
                        </View>
                    </View>
                </View>
                )
            }
            }
        />
        </>
        : null
        }

        {selectedSetName != '' 
        ? 
        <>
        <View style={styles.titleStyle}>
            <View style={{ flexDirection:'row', justifyContent:'space-between'}}>
                <Text style={{fontSize: 18, color: '#2867B2',}}>Documents:</Text>
                <View style={{ flexDirection:'row'}}>
                    <Feather name="refresh-cw" style={{fontSize: 20, color: 'white', width: 35}} onPress={() => {setViewRS('Documents:'); setResults(!viewResults)}}/>
                </View>
            </View>                 
        </View>            
        <FlatList
            data={portfolioItems}
            horizontal={true}
            keyExtractor={(item) => item.ke_id }
            
            renderItem={(item) => {
                return (
                <View style={{ paddingRight:10}}> 
                    <TouchableOpacity onPress={() => navigation.navigate('PdfViewer', {Accountid: activeAcct, ke_id : item.item.ke_id, uri : item.item.pdf_link})}>
                        <LinearGradient
                        colors={['#534c9f', '#3e3b98', '#1c196a']} // Purple
                        style={{  alignItems: 'center', borderRadius: 5, width: 250, height: 380, }}>
                        {Platform.OS === 'web'
                                ?
                                <TouchableOpacity style={{alignItems: 'center', justifyContent: 'center',}} onPress={() => navigation.navigate('PdfViewer', {Accountid: activeAcct, ke_id : item.item.ke_id, uri : item.item.pdf_link})}>
                                {/* <iframe 
                                    width="100%" 
                                    height= "500"
                                    src={'https://mobile.knowledgekiosk.com/cm_pp.pdf'}
                                    //src={`${pdfBase64}`}
                                    scrolling="auto"
                                    frameBorder="1"
                                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen
                                />  */}
                                <Image
                                source={{uri: "https://cdn.mesearch.ai/assets/default/_sampleDoc.png"}}
                                style={{width: 220, height: 380}}
                                resizeMode="contain"
                                /> 
                                </TouchableOpacity>
                            :
                            <View style={{width: 250, height: 380,  }}>
                            <TouchableOpacity style={{alignItems: 'center', justifyContent: 'center',}}  onPress={() => navigation.navigate('PdfViewer', {Accountid: activeAcct, ke_id : item.item.ke_id, uri : item.item.pdf_link})}>
                            {/* <PDFReader
                                source={{
                                uri: 'https://mobile.knowledgekiosk.com/cm_pp.pdf',
                            }}
                            />  */}
                                <Image
                                source={{uri: "https://cdn.mesearch.ai/assets/default/_sampleDoc.png"}}
                                style={{width: '95%', height: '95%'}}
                                resizeMode="contain"
                                /> 
                            </TouchableOpacity>
                            </View>
                            
                        }

                        </LinearGradient>  
                    </TouchableOpacity>

                    <View style={{height: 70, width: 250, marginBottom:20, backgroundColor: '#000000', opacity: 0.9}}>
                        <View style={{ height: 50, paddingTop: 1, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <Text numberOfLines={2} style={styles.name}  >{item.item.title}</Text>
                        </View>
                        <View style={{ flexDirection:'row', justifyContent:'space-between', height: 30, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <View style={{ flexDirection:'row', paddingLeft: 5,}}>
                            <Text numberOfLines={1} style={styles.textDescStyle}>Ke_id: {item.item.ke_id}</Text>
                            </View>
                            <View style={{ flexDirection:'row'}}>
                            <Feather name="activity" style={{fontSize: 20, color: 'white', width: 35}}/>
                            <Feather name="settings" style={{fontSize: 20, color: 'white', width: 35}} onPress={() => navigation.navigate('PdfViewer', {Accountid: activeAcct, ke_id : item.item.ke_id, uri : item.item.pdf_link})}/>
                            </View>
                        </View>
                    </View>
                </View>
                )
            }
            }
        />
        </>
        : null
        }

        {/* <View>
            <HeatMap/>
        </View> */}
        </View>
        </>
    )
  };

  // Styles
const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    containerSmall: {
      flex: 1,
      justifyContent: 'center',
      paddingBottom: 50,
      //backgroundColor: darkMode ? '#3c3c3d' : 'white'
    },
    containerLarge: {
      width: Dimensions.get('window').width,
      //backgroundColor: darkMode ? '#3c3c3d' : 'white',
      paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
      paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
      justifyContent: 'center',
      paddingBottom: 150,
      marginTop:20,
      //opacity: viewModal ? 0.4 : 1.0
    },
    textDescStyle: {
      color: 'white',
      fontWeight: '300',
      fontSize: 14,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 10,
    },
    name: {
      width: 250,
      height: 42,
      fontWeight: 'bold',
      color: 'white',
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
    },
    titleStyle: {
      fontSize: 24,
      fontWeight: 'bold',
      color: 'white',
      paddingLeft: 5,
      paddingBottom:5,
      marginBottom:10,
      paddingTop:5,
      backgroundColor: 'black',
      overflow: 'hidden'
    },
    blank: {
      width: 250,
      height: 250,
      color:'white',
      justifyContent:'center', 
      alignItems:'center',
      elevation:5
    },
    pdf: {
      flex:1,
      width:Dimensions.get('window').width,
      height:Dimensions.get('window').height,
    }
  });

export default kioskGrid;