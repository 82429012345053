import React from 'react';
import { View, Text, Dimensions, StyleSheet, Image, TouchableOpacity, TextInput, FlatList} from 'react-native';
import { Feather } from '@expo/vector-icons';

const AccountHomeCustomBtn =({title, onPress})=>{
    return (
        <View style={styles.container}>
          <TouchableOpacity style={styles.btn} onPress={()=>onPress(title)}>
            <Text style={styles.btnText}>{title}</Text>
          </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
  container:{
    //flex:1,
    //flexDirection:'row',
    //justifyContent:'center',
    //alignItems:'center',
    marginTop:10,
    paddingHorizontal:5
  },
  btn:{
    backgroundColor:'#0984e3',
    paddingVertical:5,
    //paddingHorizontal:15,
    //marginHorizontal:10,
    marginRight:Dimensions.get('window').width > 750 ? 50 : 25,
    borderTopLeftRadius:15,
    borderBottomRightRadius:15,
    width:Dimensions.get('window').width > 750 ? 200 : 100
  },
  btnText:{
    color:'#fff',
    textAlign:'center'
  },
});

export default AccountHomeCustomBtn;