import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet, Image, Dimensions, View, FlatList } from 'react-native';
import { Platform } from 'react-native';
import PublicationsList from '../components/PublicationsList';
import { isMobile } from 'react-device-detect';
import usePubs from '../hooks/usePubs';
import uuidv4 from 'uuid/v4';
import updateGDataLayer from './utils/updateGDataLayer';
import { Context as PubContext } from '../context/PubContext';


const LandingPageScreen = ({navigation, route}) => {
    const landingPageInfo = route.params?.landingInfo ?? {};
    const gtmId = route.params?.gtmId ?? null;
    const [pubs, loading, error] = usePubs();
    const [rend, setRend] = useState("");
    const PubCon = useContext(PubContext);

    const filterPubs = (recentRow, tag) => {
      let sortedPublications = [];
      pubs.filter(pub => {
        return landingPageInfo.publications.some((publication) => {
          if (publication.pubName === pub) {
            sortedPublications.push(publication);
            return true;
          }
          return false;
        });
      });
      sortedPublications = sortedPublications.slice(0, 3);
      let sortedPubNames = sortedPublications.map(p => p.pubName);
      if (!recentRow)  {
        return landingPageInfo.publications.filter(pub => {
            return (pub.tag === tag && !sortedPubNames.includes(pub.pubName)) ? true : false;
          });
      }
      return sortedPublications;
    };

    useEffect(() => {
      setRend(uuidv4());
    }, [pubs]);

    useEffect(() => {
      if (Platform.OS === 'web' && !window.location.href.includes('content-page/') && !window.location.href.includes('/bid')) {
        updateGDataLayer('', gtmId, PubCon && PubCon.state.publisher ? PubCon.state.publisher.username : "");
      }
    }, []);


    return (
      <View style={styles.container}>
        <View style={{flexDirection: 'row', justifyContent: 'center'}}>
          <Image style={styles.imageHeader} source={{uri: landingPageInfo.headerImage}}/>
        </View>
        <View style={{flexDirection: 'row', justifyContent: 'center'}}>
          <Image style={styles.imagePoweredBy} source={{uri: 'https://cdn.mesearch.ai/assets/branding/landingpage/poweredby.png'}}/>
        </View>
        <FlatList
            style={{marginTop: 15}}
            data={landingPageInfo.tags}
            keyExtractor={(tag) => tag}
            renderItem={({ item, index }) => {
                if (index === 0) {
                    return (
                        <>
                            <PublicationsList
                                publications={filterPubs(true)}
                                tagBarColor={landingPageInfo.tagBarColor}
                                tagTitle={"Recent"}
                                navigation={navigation}
                            />
                            <PublicationsList
                                publications={filterPubs(false, item)}
                                tagBarColor={landingPageInfo.tagBarColor}
                                tagTitle={item}
                                navigation={navigation}
                            />
                        </>
                    )
                } else {
                    return (
                        <PublicationsList
                            publications={filterPubs(false, item)}
                            tagBarColor={landingPageInfo.tagBarColor}
                            tagTitle={item}
                            navigation={navigation}
                        />
                    )
                }
            }}
        />
      </View>
    );
};

const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
      paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
      marginBottom: 20,
      paddingTop: Platform.OS !== 'web' ? 50 : 0,
      backgroundColor: 'white'
    },
    imageHeader: {
      width: (Platform.OS === 'web' && !isMobile) ? 400 : 300,
      height: (Platform.OS === 'web' && !isMobile) ? 150 : 120,
      marginLeft: 10,
      marginRight: 10,
      marginTop: 10
    },
    imagePoweredBy: {
      width: (Platform.OS === 'web' && !isMobile) ? 120 : 120,
      height: (Platform.OS === 'web' && !isMobile) ? 22 : 22,
      marginLeft: 10,
      marginRight: 10,
      marginTop: 20
    },
  });

export default LandingPageScreen;