import React, { useEffect, useState, useContext } from 'react';
import { View, Text, StyleSheet, Dimensions, ScrollView, TouchableOpacity, ActivityIndicator, Image } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import  { Button } from 'react-native-elements';
import MeSearchAPI from '../api/MeSearch-API';
import MeSearchAPIPub from '../api/MeSearch-API-Pub';
import KioskAccountHomeWrapper from './wrappers/kiosk/KioskAccountHomeWrapper';
import UserHomeWrapper from './wrappers/MeSearch/UserHomeWrapper';
import { Ionicons } from "@expo/vector-icons";
import SystemCapabilities from './utils/SystemCapabilities';
import checkSystemCapability from './utils/checkSystemCapability';
import { Platform } from 'react-native';
import { Context as PubContext } from '../context/PubContext';
import useKioskResults from '../hooks/kiosk/useKioskResults';


const KioskAccountHome = ({navigation, route}) => {
    const [myAccLvl, setMyAccLvl] = useState(null);
    const [adminCapability, setAdminCapability] = useState(false);
    const [hasAccounts, setHasAccounts] = useState(false);
    const [horizontal, setHorizontal] = useState(true);
    const [darkMode, setDarkMode] = useState(false);
    const [account, setAccount] = useState(null);
    const { state, kAccountSignin } = useContext(PubContext);
    const [ accounts, accountGrpDetails,
        accountGrpSets, accountCodingFrm, 
        accountCodingData, loading, error, 
        getAccounts, getAccountGrpDetails, 
        getGroupSets, getCodingFrm, 
        getCodingData, setCodingData, putCodingData, 
        saveCodingSuccess, setSaveCodingSuccess,
        getPortfolioItems, getPdfBase64, portfolioItems, pdfBase64,
        accountCodingHistory, getCodingHistory,
        guid, setGuid,
        getSearch,searchResults, getAdminsApi, admins, adminsLoading, adminsError, setAdmins, setAccGrpDetails ] = useKioskResults();
    

    const getAccountInfo = async () => {
      await getAccounts();
    }

    useEffect(() => {
      getAccountInfo();
      const unsubscribe = navigation.addListener('focus', () => {
        getAccountInfo();
      });
      return unsubscribe;
    }, []);

    const updateAccounts = async () => {
      if (accounts && accounts.length > 0) {
        const kAccountToken = await AsyncStorage.getItem('kAccountToken');
        if (kAccountToken) {
          const acc = accounts.find(a => a.AccountId == kAccountToken);
          if (acc) {
            setAccount(acc);
          }
        } else {
          kAccountSignin({accId: accounts[0].AccountId});
          setAccount(accounts[0]);
        }
      }
    }

    useEffect(() => {
      updateAccounts();
    }, [accounts]);

    return (
        <ScrollView contentContainerStyle={[{alignItems: 'center', justifyContent: 'center', paddingTop: 50}, darkMode ? {backgroundColor: '#3c3c3d'} : null]}>
          <View>
              {account ?
              <KioskAccountHomeWrapper
                  navigation={navigation}
                  route={route}
                  name={account.OwnersAccountTitle}
                  type={"Litigation"}
                  account={account}
                  myAccLvl={myAccLvl}
                  imageUri={"https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/cdn1/AccountImage_"+ `${account.AccountId}` + ".png"}
                  adminCapability={adminCapability}
                  hasAccounts={true}
                  horizontal={horizontal}
                  setHorizontal={setHorizontal}
                  darkMode={false}
              />
              : <ActivityIndicator size="large" style={{ marginTop: 150 }} color="#005157"/> }
          </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
  errorMessage: {
    fontSize: 18,
    color: 'red',
    marginTop: 15
  },
  successMessage: {
    fontSize: 18,
    color: 'green',
    marginTop: 15
  }
});

export default KioskAccountHome;