import React, { useContext, useState, useEffect } from 'react';
import  { Text, Button, Input, CheckBox } from 'react-native-elements';
import {Picker, StyleSheet, Image, FlatList, Dimensions, View, ScrollView, SafeAreaView, ImageBackground, TouchableOpacity, ActivityIndicator } from 'react-native';
import Spacer from '../Space';
import { Feather } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { navigate } from '../../navigationRef';
import { HeaderBackButton } from '@react-navigation/stack';
import { Platform } from 'react-native';
import PDFReader from 'rn-pdf-reader-js'
import CodingPnl from './codingPanel'
import useKioskResults from '../../hooks/kiosk/useKioskResults';
import MeSearchAPIKiosk from '../../api/kiosk/MeSearch-API-Kiosk';
import { documentDirectory, writeAsStringAsync, EncodingType, cacheDirectory } from 'expo-file-system';
import { isAvailableAsync, shareAsync } from 'expo-sharing';
let Document = () => null;
let Page = () => null;
let pdfjs = {};

const PdfViewerScreen = ({ navigation, route }) => {
    
    const acctID = route.params?.Accountid ?? '';
    const ke_id = route.params?.ke_id ?? '';
    const portfolioId = route.params?.portfolioId?? '';
    const forumId = route.params?.forumId ?? '';
    const uri = route.params?.uri ?? '';

    const [viewCoding, setViewCoding] = useState(false);
    const [accountID, setAccountID] = useState(acctID);
    const [accounts, accountGrpDetails,
        accountGrpSets, accountCodingFrm, 
        accountCodingData, loading, error, 
        getAccounts, getAccountGrpDetails, 
        getGroupSets, getCodingFrm, 
        getCodingData, setCodingData, putCodingData, 
        saveCodingSuccess, setSaveCodingSuccess,
        getPortfolioItems, getPdfBase64, portfolioItems, pdfBase64,
        accountCodingHistory, getCodingHistory,
        guid, setGuid] = useKioskResults();
    const [fileLoading, setFileLoading] = useState(false);
    const [err, setErr] = useState("");
    const [success, setSuccess] = useState("");
    const [file, setFile] = useState("");
    const [canDownload, setCanDownload] = useState(false);
    const [downloadCallFinished, setDownloadCallFinished] = useState(false);
    const [guidId, setGuidId] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    if (Platform.OS === 'web') {
      const reactPdf = require('react-pdf');
      Document = reactPdf.Document;
      Page = reactPdf.Page;
      pdfjs = reactPdf.pdfjs;
      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }

    const b64toBlob = (b64Data, contentType) => {
        try {
            var byteCharacters = atob(b64Data)
            var byteArrays = []
            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                var slice = byteCharacters.slice(offset, offset + 512),
                    byteNumbers = new Array(slice.length)
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i)
                }
                var byteArray = new Uint8Array(byteNumbers)
                byteArrays.push(byteArray)
            }
            var blob = new Blob(byteArrays, { type: contentType })
            return blob
        } catch (err) {
          return "";
        }
    }

    const getPdf = async (ke_id) => {
      try {
        let res = await MeSearchAPIKiosk.get('/api/kiosk/account/forums/portfolio/pdf-link', {params: {ke_id: ke_id}});
        let pLink = res.data;
        getPdfBase64(pLink.pdf_link, ke_id);
      } catch (err) {
        console.log(err);
      }
    };

    useEffect(() => {
        if (uri === '') {
          getPdf(ke_id);
        } else {
          getPdfBase64(uri, ke_id);
        }
    }, [uri]);

    const checkCanDownload = async () => {
      try {
        const result = await MeSearchAPIKiosk.get('/api/kiosk/account/forums/portfolio/pdf/can-download', {params: {ke_id}});
        if (!result.data.IsDownloadProtected) {
          setCanDownload(true);
        }
      } catch (err) {
        console.log(err);
      }
      setDownloadCallFinished(true);
    };

    const recordView = async () => {
      try {
        await MeSearchAPIKiosk.post('/api/kiosk/account/user/record-doc-view-action', {ke_id: ke_id, params: {ke_id}});
      } catch (err) {
        console.log(err);
      }
    };

    const getGuid = async () => {
      const result = await MeSearchAPIKiosk.get('/api/kiosk/guid');
      setGuidId(result.data.guid);
    };

    useEffect(() => {
      const unsubscribe = navigation.addListener('focus', () => {
        setDownloadCallFinished(false);
        checkCanDownload();
        recordView();
        getGuid();
      });
      return unsubscribe;
    }, []);

    const downloadAndSharePdf = async () => {
      setFileLoading(true);
      try {
        const isShare = await isAvailableAsync();
        if (isShare) {
          const curMS = new Date().getTime();
          //const fileName = documentDirectory + `${ke_id}-${curMS}.pdf`;
          const fileName = cacheDirectory + `${ke_id}-${curMS}.pdf`;
          const base64Code = pdfBase64.split("data:application/pdf;base64,")[1];
          await writeAsStringAsync(fileName, base64Code, {
            encoding: EncodingType.Base64
          });
          /*let readableFilename = fileName;
          readableFilename.replace("file://");
          setSuccess("Successfully Downloaded To");
          setFile(readableFilename);*/
          await shareAsync(fileName);
          setSuccess("Successfully Downloaded");
        }
      } catch (err) {
        setErr(err)
        setTimeout(() => {
          setErr("");
        }, 3000);
      }
      setFileLoading(false);
    }

    return (
        <>
            {(pdfBase64 && !fileLoading) ?
            <>
                {err !== "" 
                  ? <View style={{height: 30, alignItems: 'center'}}><Text style={{ fontSize: 22, marginLeft: 10, fontWeight: '500', color: 'red'}}>{err}</Text></View>
                : null}
                {success !== "" 
                  ? <View style={{height: 30, alignItems: 'center'}}><Text style={{ fontSize: 22, marginLeft: 10, fontWeight: '500', color: 'green'}}>{success}</Text></View>
                : null}
                {file !== "" 
                  ? <View style={{alignItems: 'center'}}><Text style={{ fontSize: 14}} numberOfLines={6}>{file}</Text></View>
                : null}
                {/*Platform.OS !== 'web' &&*/ (err === "") ?
                    <View style={{flexDirection:'row', justifyContent:'flex-start', backgroundColor:'#2089dc', paddingTop:15, paddingLeft:15}}>
                        <Feather name="settings" style={{fontSize: 28, color: 'white', width: 35, paddingBottom: 10, marginLeft: 5, opacity: 0.4}} onPress={()=> {}}/>
                        <Feather name="save" style={{fontSize: 28, color: 'white', width: 35, paddingBottom: 10, marginLeft: 20, opacity: 0.4}} onPress={()=> {}}/>
                        <Feather name="plus-circle" style={{fontSize: 28, color: 'white', width: 35, paddingBottom: 10, marginLeft: 20, opacity: 0.4}} onPress={()=> {}}/>
                        <Feather name="bar-chart" style={{fontSize: 28, color: 'white', width: 35, paddingBottom: 10, marginLeft: 20, opacity: 0.4}} onPress={()=> {}}/>
                        {canDownload ?
                          <>
                            {Platform.OS === 'web' ?
                            <a download={`${ke_id}-${new Date().getTime()}`} href={`${pdfBase64}`} style={{textDecoration: "none"}}>
                              <Feather name="download" style={{fontSize: 28, color: 'white', width: 35, paddingBottom: 10, marginLeft: 20}}/>
                            </a>
                            :
                            <TouchableOpacity
                              onPress={()=> {
                                downloadAndSharePdf();
                              }}
                            >
                              <Feather name="download" style={{fontSize: 28, color: 'white', width: 35, paddingBottom: 10, marginLeft: 20}}/>
                            </TouchableOpacity>
                            }
                          </>
                        : null}
                    </View>
                : null}
                {Platform.OS === 'web'
                    ?
                    <View style={{flexDirection:'row'}}>
                        {guidId ?
                          <ScrollView 
                            style={{width: Dimensions.get('window').width, height: Dimensions.get('window').height-200, backgroundColor: "#707070"}}
                            contentContainerStyle={{justifyContent: 'center', alignItems: 'center'}}
                          >
                            <Document
                            file={`${(Platform.OS === 'web' && pdfBase64.includes('data:application/pdf;') && !canDownload && downloadCallFinished) 
                            ? ``
                            /*https://www.knowledgekiosk.com/faceplatedocnew/DocView.aspx?guid=${guidId}&portfolioID=${portfolioId}&forumID=${forumId}&keid=${ke_id}*/
                            : pdfBase64}`
                          }
                            onLoadSuccess={onDocumentLoadSuccess}
                          >
                            {numPages ?
                              <View style={{height: Dimensions.get('window').height-200}}>
                              <FlatList
                                horizontal={false}
                                data={Array.from(Array(pageNumber).keys()).map(k => k+1)}
                                keyExtractor={(i) =>  `${i}`}
                                onEndReachedThreshold={2}
                                onEndReached={() => {
                                  setPageNumber(Math.min(numPages, pageNumber+10));
                                }}
                                renderItem={({ item, index }) => {
                                  return (
                                    <View style={{paddingTop: 10, paddingBottom: (index === 0 || index === (numPages - 1)) ? 10 : 0}}>
                                      <Page pageNumber={item} width={Math.min(Dimensions.get('window').width-20, 800)}/>
                                    </View>
                                  );
                                }}
                              />
                              </View>
                            : null}
                          </Document>
                         </ScrollView>
                        : null}
                    </View>
                    :
                    <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                        <View style={{width:'100%', height: Dimensions.get('window').height}}>
                            <PDFReader
                              source={{
                                base64: `${pdfBase64}`,
                              }}
                            />
                        </View>
                    </View>
                }
            </>
            : <ActivityIndicator size="large" style={{marginTop: 20}} color="#005157"/>}
        </>
    );
};

PdfViewerScreen.navigationOptions = ({
    headerBackground: null,
    title: "Back",
    headerLeft: <HeaderBackButton title="Back" onPress={() => {
      navigate('KioskHome')
    }}/>,
    headerStyle: {
      height: 40
    }
});

export default PdfViewerScreen;