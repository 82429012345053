import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Dimensions, TextInput, TouchableOpacity, ActivityIndicator, ScrollView, FlatList, Image } from 'react-native';
import { Overlay } from 'react-native-elements';
import Modal from 'modal-react-native-web';
import { Picker } from '@react-native-picker/picker';
import MeSearchAPIInternal from '../api/MeSearch-API-Internal';
import { Feather } from '@expo/vector-icons';
import { Platform } from 'react-native';

const StockPhotoOverlay = ({meTags=[], visible, toggleOverlay, type=null, updateMyImages}) => {
  const [pickerSelected, setPickerSelected] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [meTagResults, setMeTagResults] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [viewDisplay, setviewDisplay] = useState('grid');
  const [apiProcessing, setApiProcessing] = useState(false);

  useEffect(() => {
    const timeoutAPI = setTimeout(() => {
      if (searchTerm) {
        setRefresh(true);
        getStockPhotosFromSearch(searchTerm);
      }
      // setRefresh(false);
    }, 1000);

    return () => clearTimeout(timeoutAPI);
  }, [searchTerm]);
  
  const getStockPhotosFromTags = async meTags => {
    setResults([]);
    setApiProcessing(true);
    let response = null;
    try {
      response = await MeSearchAPIInternal.get('/api/content/stock-images', {params: {
        meTags,
      }});
      // console.log('meTag query', response.data);
      setApiProcessing(false);
      setMeTagResults(response.data?.images);
    } catch (error) {
      setApiProcessing(false);
      console.log('error', error);
    }
  };

  useEffect(() => {
    if ((meTags && meTags.length > 0) && visible) {
      getStockPhotosFromTags(meTags);
    }
  }, [visible, meTags]);
  

  const getStockPhotosFromSearch = async queryString => {
    setResults([]);
    setApiProcessing(true);
    let response = null;
    try {
      response = await MeSearchAPIInternal.get('/api/content/stock-images-search', {params: {
        searchTerm: queryString,
      }});
      // console.log('searchTerm query', response.data);
      setApiProcessing(false);
      setResults(response.data?.images);
    } catch (error) {
      setApiProcessing(false);
      console.log('error', error);
    }
  };

  const addImage = url => {
    updateMyImages(url);
    setSearchTerm('')
    toggleOverlay();
  }

  const pickerSelectionHandler = value => {
    setPickerSelected(value);

    const results = meTagResults.filter(result => {
      const some = result.meTags.some(tagMeta => tagMeta.meTag === pickerSelected);
      if (some) {
        return result;
      };
    });

    setResults(results);
  };

  return (
    <Overlay 
      isVisible={visible} 
      onBackdropPress={toggleOverlay}
      overlayStyle={styles.container}
      ModalComponent={Modal}
    >
      <View style={styles.inputs}>
        <View style={{flexDirection:'row',marginLeft:5,}}>
          <Feather name="list" onPress={()=>{setviewDisplay('list'),getStockPhotosFromSearch(searchTerm);}} style={{ fontSize: 20, fontWeight:'bold',  color: viewDisplay=='list' ? '#1f89dc' : null, marginRight:5, marginTop:5}}/>
          <Feather name="grid" onPress={()=>{setviewDisplay('grid'),getStockPhotosFromSearch(searchTerm);}} style={{ fontSize: 20,  color: viewDisplay=='grid' ? '#1f89dc' : null, marginRight:5, marginTop:5 }}/>
        </View>

        <View style={{flexDirection:'row', marginLeft:5, marginRight:5, marginBottom:5, marginTop:5 }}>
          <Feather name="search" style={{ fontSize: 20,  color: 'black',  marginRight:5, marginTop:5}}/>
          <TextInput 
            onChangeText={text => {
              setSearchTerm(text);
            }}
            value={searchTerm}
            placeholder="Search"
            style={styles.textInput}
          />
          <Feather name="x" onPress={()=> {setSearchTerm(''), setResults([])}} style={{ fontSize: 20,  color: 'black', marginRight:5, marginBottom:5, marginTop:5, }}/>
        </View>
        
        {/* TODO FILTER SECTION needs more work */}
        {/* <View style={{flexDirection:'row', marginLeft:5, marginTop:5}}>
        <Feather name="filter" style={{ fontSize: 20,  color: 'black', marginRight:5, }}/>
          <Picker
            style={styles.picker}
            mode='dropdown'
            selectedValue={pickerSelected}
            onValueChange={(itemValue, itemIndex) => {
              // on value change, take new itemValue and filter results of the API to only show new selection for selectedValue
              pickerSelectionHandler(itemValue);
              //getStockPhotosFromSearch(pickerSelected)
            }}
          >
            <Picker.Item label={'Filter by tag'} value='' />
            {
              meTags.map(tag => (
                <Picker.Item label={tag} value={tag} />
              ))
            }
          </Picker>
        </View> */}
        {/* END FILTER SECTION */}
      </View>

      {/* <View style={{justifyContent: 'center', alignItems: 'center'}}>
        <Text style={{fontSize: 20, color: 'grey', justifyContent: 'center'}}>Enter a keyword, or select from a recommended list of meTags to find some stock images.</Text>
      </View> */}
      <View style={styles.list}>
        {
          visible ? (
            <ScrollView>
              {/* TODO ACTIVITY */}
              {!apiProcessing 
                ?
                <>
              {viewDisplay=='grid'
                    ?
            <FlatList 
              key={Dimensions.get('window').width > 1250 ?  5 :  3}
              keyExtractor={item => item._id}
              numColumns={Dimensions.get('window').width > 1250 ?  5 :  3}
              data={results}
              horizontal={false}
              refreshing={refresh}
              renderItem={({item, index}) => (
                <View>
                    <TouchableOpacity
                    style={{ margin: 5 }}
                    onPress={() => addImage(item.url)}
                  >
                    <Image 
                      style={styles.img}
                      source={{uri: item.url}}
                    />
                  </TouchableOpacity>
                    <View style={{width:120, justifyContent:'center', alignContent:'center'}}>
                      <Text style={{fontSize:12, marginLeft:10}}>
                        {
                          item.title.length >= 20
                            ? item.title.substring(0, 17) + "..."
                            : item.title
                        }
                      </Text>
                    </View>

                </View>
              )}
            />
            :
            <FlatList 
              key={1}
              keyExtractor={item => item._id}
              numColumns={1}
              data={results}
              refreshing={refresh}
              renderItem={({item, index}) => (
                <View style={{flexDirection:'row', alignItems:'flex-start', marginBottom:10}}>
                    <TouchableOpacity
                    style={{ margin: 5 }}
                    onPress={() => addImage(item.url)}
                  >
                    <Image 
                      style={styles.img}
                      source={{uri: item.url}}
                    />
                  </TouchableOpacity>
                  <View>
                    <Text style={{marginLeft:5, fontWeight:'bold', width:200}}>{item.title}</Text>
                    <Text style={{marginLeft:5, fontSize:12, marginTop:5, width:200}}>Keywords: {JSON.stringify(item.keywords)}</Text>
                  </View>
                </View>
              )}
            />
            }
            </>
            :
              <View style={{justifyContent: 'center', alignItems: 'center', height:400, }}>
                <ActivityIndicator size="large" style={{marginTop: 5}} />
                <Text>Searching...</Text>
              </View>

            }
            </ScrollView>
          ) : null
        }

        {/* TODO SORT BY */}
        {/* <View style={{flexDirection:'row', marginTop:10}}>
          <Text>Sort By: </Text>
            <Picker
                  style={styles.picker}
                  mode='dropdown'
            >
              <Picker.Item label='All' value='All'/>
              <Picker.Item label='Recently Added' value='Added'/>
              <Picker.Item label='Alphabetical' value='Alpha'/>
              <Picker.Item label='Creator' value='Creator'/>
            </Picker>
        </View> */}
        {/* END SORT BY */}
      </View>
    </Overlay>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 5,
    borderRadius: 7,
    width: Dimensions.get('window').width > 1250 ? 700: 450,
    height: 500,
    backgroundColor: 'rgb(255,255,255)',
    // justifyContent: 'center',
    alignItems: 'center',
  },
  inputs: {
    flexDirection: Dimensions.get('window').width > 1250 ? 'row' : 'column', 
    paddingHorizontal: 4,
    paddingVertical: 2,
    justifyContent: 'space-evenly',
    //alignItems: 'center',
    marginBottom: 10,
  },
  textInput: {
    // marginBottom: 5,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: 'grey',
    padding: 5,
    width: 380,
  },
  picker: {
    //padding: 5,
    marginTop:-5,
    borderColor: 'grey',
    borderRadius: 4,
    fontSize: 14
  },
  img: {
    height: 120,
    width: 120,
  },
  list: {
    // flex: 1,
    // justifyContent: 'center',
    height: Dimensions.get('window').width > 1250 ? 420 : 380,
    width: Dimensions.get('window').width > 1250 ? 650 : 400, // TODO LIST WIDTH
  },
});

export default StockPhotoOverlay;