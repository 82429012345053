import React, {useState, useContext, useEffect} from 'react';
import { SafeAreaView,  StyleSheet,  View,  FlatList, Dimensions, Image,  Text, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import { WebView } from 'react-native-webview';
import MeSearchApi from '../api/MeSearch-API';
import MeSearchAPIPub from '../api/MeSearch-API-Pub';
import { Rect, Text as TextSVG, Svg } from "react-native-svg";
import { Feather, FontAwesome, Foundation } from '@expo/vector-icons';
import { Context as PubContext } from '../context/PubContext';
import PieChartDash from '../components/core/PieChartDash';
import BarChartDash from '../components/core/BarChartDash';

//import React Native chart Kit for different kind of Chart
// import {
//   LineChart,
//   BarChart,
//   PieChart,
//   ProgressChart,
//   ContributionGraph,
//   StackedBarChart,
// } from 'react-native-chart-kit';

const DashboardScreen = ({navigation, route}) => {
  // TODO THIS PAGE SHOULD BE AS WRAPPER NOW
  // console.log('DashboardScreen')
  // console.log('pubName: '+route.params?.pubName)
  // console.log('body_id: '+route.params?.body_id)
  // console.log('title: '+route.params?.title)

  let pubName = route.params?.pubName ?? '';
  let body_id = route.params?.body_id ?? '';
  let contentTitle = route.params?.title ?? '';
  let reportName = route.params?.reportName ?? '';

  const { state } = useContext(PubContext);

  const [uniqClicks, setUniqClicks] = useState(0);

  const [refData] = useState([{id:1}]);
  const [rowData, setRowData] = useState([0,2]);
  const [colData, setColData] = useState([0,2]);
  const [solrData, setSolrData] = useState([0,2]);
  const [hdrData, setHdrData] = useState([0,2]);
  const [isLoading, setIsLoading] = useState(true);
  const [noDataFound, setNoDataFound] = useState(false);

  let rsData =''

  const [pieChartTypes, setPiechartTypes] = useState([{name: "Desktop", population: 700, color: "rgb(250,2,166)", legendFontColor: "#7F7F7F", legendFontSize: 15},{name: "Desktop", population: 700, color: "rgb(250,2,166)", legendFontColor: "#7F7F7F", legendFontSize: 15}]);
  let chartTypes=[];

  const [pieChartDevices, setPiechartDevices] = useState([{name: "Desktop", population: 700, color: "rgb(250,2,166)", legendFontColor: "#7F7F7F", legendFontSize: 15},{name: "Desktop", population: 700, color: "rgb(250,2,166)", legendFontColor: "#7F7F7F", legendFontSize: 15}]);
  let chartDevices=[];

  const [pieChartWidget, setPiechartWidget] = useState([{name: "Desktop", population: 700, color: "rgb(250,2,166)", legendFontColor: "#7F7F7F", legendFontSize: 15},{name: "Desktop", population: 700, color: "rgb(250,2,166)", legendFontColor: "#7F7F7F", legendFontSize: 15}]);
  let chartWidget=[];

  //////////////////////////////////////////////////////

  const [dayLabel, setDaylabel] = useState([]);
  const [dayData, setDayData] = useState([]);

  const [weekday, setWeekdayData] = useState([]);

  let WeekdayLabel=[];
  let WeekdayData=[];

  //////////////////////////////////////////////////////

  const [utcDateLabel, setUtcDatelabel] = useState([]);
  const [utcDateData, setUtcDateData] = useState([]);

  const [utcday, setUtcDayData] = useState([]);

  let UtcDateLabel=[];
  let UtcDateData=[];

  //////////////////////////////////////////////////////

  const [tagLabel, setTaglabel] = useState([]);
  const [tagData, setTagData] = useState([]);

  const [tagday, setTagDayData] = useState([]);

  let meTagLabel=[];
  let meTagData=[];

  //////////////////////////////////////////////////////

  const [hrsLabel, setHrlabel] = useState([]);
  const [hrsData, setHrData] = useState([0,2]);
  let hoursLabel=[];
  let hoursData=[];

  // const [tagLabel, setTaglabel] = useState([]);
  // const [tagData, setTagData] = useState([0,2]);
  // let tagsLabel=[];
  // let tagsData=[];

  let chartHr=[];
  let chartTags=[];
  let chartHeat=[];
  let hdr=[];
  let alpha=[];

  const getYMD = () => {
    // Get ISO date range for now
    const startDateTimeMS = new Date();
    const startDateMS = startDateTimeMS.setDate(startDateTimeMS.getDate() - 7);
    const startISODate = new Date(startDateMS);
    const startYMD = `${startISODate.getUTCFullYear()}-${`${startISODate.getUTCMonth()}`.length === 1 
      ? `0${startISODate.getUTCMonth()+1}` 
      : `${startISODate.getUTCMonth()+1}`}-${`${startISODate.getUTCDate()}`.length === 1 ? `0${startISODate.getUTCDate()}`: `${startISODate.getUTCDate()}`}`;
    return startYMD;
  }
 
  const [rptDate, setRptDate] = useState(getYMD()/*'2021-05-21'*/);
  const [rsCount, setRsCount] = useState(0);

  const getRandomColor = () =>
  {
      return 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';
  }

  const getStatsApi = async () => {
    try {
      setIsLoading(true);
      ///// TODO GET ACCOUNTID FROM PUBOWNERS
        const pubOwnerResponse = await MeSearchAPIPub.get('/api/pub-owner');
      /////

      const response = await MeSearchApi.get('/api/test/v2/solr-stats', {params: {period: '', accountid: pubOwnerResponse.data.accountId, pub: pubName.toLowerCase(), body_id: body_id, type: 'clicks' }});
      
      rsData = response.data
      
      let contentType = rsData.facet_counts.facet_fields.dctype
      let contentDevices = rsData.facet_counts.facet_fields.device
      let contentWidget = rsData.facet_counts.facet_fields.widgetName
      let contentTags = rsData.facet_counts.facet_fields.meTags
      let utcDate = rsData.facet_counts.facet_fields.utcdate;  //Now, using this array to list out the dates from oldest to current
      let weekday = rsData.facet_counts.facet_fields.weekday;  //Now, using this array to list out the dates from oldest to current

      setRsCount(rsData.stats.stats_fields.count.count); //total number of impressions
      setIsLoading(false);
      setUniqClicks(rsData.grouped.username.ngroups);
      
      if (rsData.stats.stats_fields.count.count==0){
        setNoDataFound(true);
      }

      ///////////////////////////////////////////////////////
      // LOAD TYPE ARRAY
      ///////////////////////////////////////////////////////

      let i=0; 
      let cnt=0; 
      let term='';

      contentType.forEach((key, el) => {
        if (i % 2 ==0){
          term = key;
        }
        else{
          cnt = key
          if (cnt!=0){
            let slice = getRandomColor();
            chartTypes.push({'name': term,  'population': cnt, 'color': slice, 'legendFontColor': '#7F7F7F', 'legendFontSize': 15});
          }

        }
        i++
      });
      
      setPiechartTypes(chartTypes);

      ///////////////////////////////////////////////////////
      // LOAD DEVICE ARRAY
      ///////////////////////////////////////////////////////

      i=0; 
      cnt=0; 
      term='';

      contentDevices.forEach((key, el) => {
        if (i % 2 ==0){
          term = key;
        }
        else{
          cnt = key
          if (cnt!=0){
            let slice = getRandomColor();
            chartDevices.push({'name': term,  'population': cnt, 'color': slice, 'legendFontColor': '#7F7F7F', 'legendFontSize': 15});
          }

        }
        i++
      });
      
      setPiechartDevices(chartDevices);

      ///////////////////////////////////////////////////////
      // LOAD WIDGET ARRAY
      ///////////////////////////////////////////////////////

      i=0; 
      cnt=0; 
      term='';

      contentWidget.forEach((key, el) => {
        if (i % 2 ==0){
          term = key;
        }
        else{
          cnt = key
          if (cnt!=0){
            let slice = getRandomColor();
            chartWidget.push({'name': term,  'population': cnt, 'color': slice, 'legendFontColor': '#7F7F7F', 'legendFontSize': 15});
          }

        }
        i++
      });
      
      setPiechartWidget(chartWidget);

      /////////////////////////////////////////////////////
      //LOAD UTC ARRAY
      /////////////////////////////////////////////////////

      i=0; cnt=0; term='';

      utcDate.forEach((key, el) => {
        if (i % 2 ==0) {
          term = key;
        }
        else {
          cnt = key;
          UtcDateLabel.push(term);
          UtcDateData.push(cnt);
        }
        i++
      });

      setUtcDatelabel(UtcDateLabel);
      setUtcDateData(UtcDateData);

      /////////////////////////////////////////////////////
      //LOAD WEEKDAY ARRAY
      /////////////////////////////////////////////////////

      i=0; cnt=0; term='';
      
      weekday.forEach((key, el) => {
        if (i % 2 ==0) {
          term = key;
        }
        else {
          cnt = key;
          WeekdayLabel.push(term);
          WeekdayData.push(cnt);
        }
        i++
      });

      setDaylabel(WeekdayLabel);
      setDayData(WeekdayData);

      /////////////////////////////////////////////////////
      //LOAD METAG ARRAY
      /////////////////////////////////////////////////////

      i=0; cnt=0; term='';
      
      contentTags.forEach((key, el) => {
        if (i % 2 ==0) {
          term = key;
        }
        else {
          cnt = key;
          meTagLabel.push({cnt, term});
         //meTagData.push(term, cnt);
        }
        i++
      });
      
      setTaglabel(meTagLabel);
      setTagData(meTagData);

      //////////////////////////////////////////////////////

     } catch (err) {
       console.log(err)
    }
  }

  useState(() => {  
    getStatsApi();
  }, []);

  let [tooltipPos1, setTooltipPos1] = useState({ x: 0, y: 0, visible: false, value: 0 })
  let [tooltipPos2, setTooltipPos2] = useState({ x: 0, y: 0, visible: false, value: 0 })

  //Colors array Required for BarChart component to work on Version 6.7
  const dataset = {
    labels: dayLabel,
    datasets: [
      {
        data: dayData,
        colors: [
          (opacity = 1) => `rgba(45, 166, 255, ${opacity})`,
          (opacity = 1) => `rgba(45, 166, 255, ${opacity})`,
          (opacity = 1) => `rgba(45, 166, 255, ${opacity})`,
          (opacity = 1) => `rgba(45, 166, 255, ${opacity})`,
          (opacity = 1) => `rgba(45, 166, 255, ${opacity})`,
          (opacity = 1) => `rgba(45, 166, 255, ${opacity})`,
          (opacity = 1) => `rgba(45, 166, 255, ${opacity})`,
          (opacity = 1) => `rgba(45, 166, 255, ${opacity})`,
        ]
      },
    ],
  }

  const datasetWeekDay = {
    labels: utcDateLabel,
    datasets: [
      {
        data: utcDateData,
        colors: [
          (opacity = 1) => `#ff00ff`,
          (opacity = 1) => `rgba(180, 0, 140, ${opacity})`,
          (opacity = 1) => `rgba(255, 0, 50, ${opacity})`,
          (opacity = 1) => `rgba(245, 110, 50, ${opacity})`,
          (opacity = 1) => `rgba(130, 230, 160, ${opacity})`,
          (opacity = 1) => `rgba(255, 0, 50, ${opacity})`,
          (opacity = 1) => `rgba(75, 110, 230, ${opacity})`,
          (opacity = 1) => `rgba(255, 0, 50, ${opacity})`,
        ]
      },
    ],
  }

  const datasetMeTags = {
    labels: tagLabel,
    datasets: [
      {
        data: tagData,
        colors: [
          (opacity = 1) => `#ff00ff`,
          (opacity = 1) => `rgba(180, 0, 140, ${opacity})`,
          (opacity = 1) => `rgba(255, 0, 50, ${opacity})`,
          (opacity = 1) => `rgba(245, 110, 50, ${opacity})`,
          (opacity = 1) => `rgba(130, 230, 160, ${opacity})`,
          (opacity = 1) => `rgba(255, 0, 50, ${opacity})`,
          (opacity = 1) => `rgba(75, 110, 230, ${opacity})`,
          (opacity = 1) => `rgba(255, 0, 50, ${opacity})`,
        ]
      },
    ],
  }

    return (
      <ScrollView contentContainerStyle={{justifyContent:'center', alignItems:'center'}}>
        
      {!isLoading 
      ?
      <>
      {!noDataFound
        ?
          <>
          <View>
            <View style={{marginTop:50, paddingBottom:10, 
              paddingLeft: Dimensions.get('window').width > 1250 ? 36 : 18,
              paddingRight: Dimensions.get('window').width > 1250 ? 36 : 18,
              flexDirection:'row', width:Dimensions.get('window').width, justifyContent:'space-between'}}>
        
            <Text style={{fontWeight:'bold', fontFamily: 'Arial'}}>TRAFFIC INSIGHTS: {rptDate} to present for {reportName}</Text>
            <Feather name="refresh-cw" style={{fontSize: 20, color: '#2da5ff', width: 35}} onPress={() => getStatsApi()}/>
            </View>

            <View style={{justifyContent:'left', width: Dimensions.get('window').width, paddingLeft: Dimensions.get('window').width > 1250 ? 36 : 18, alignContent:'left'}}>
              <Text style={{fontWeight:'bold', fontFamily: 'Arial'}}>TITLE: {contentTitle}</Text>
              <Text style={{fontWeight:'bold', fontFamily: 'Arial'}}>Result Total: {rsCount !== -1 ? `${rsCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : ""} </Text>
              <Text style={{fontWeight:'bold', fontFamily: 'Arial'}}>Unique Users: {uniqClicks}</Text>
            </View>

            <View 
            style={{padding:20}}
            showsVerticalScrollIndicator={false}
        >
          <View style={{
              width: Dimensions.get('window').width,
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent:'center',
              //paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : 18,
              //paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : 18,
            //   backgroundColor: 'grey'
            }}>

          <View style={{borderRadius: 4, borderColor:'silver', borderWidth:1, padding:10, marginBottom:10, marginRight: !isMobile ? 10: 0, width: isMobile ? Dimensions.get('window').width : 650}}>
            <PieChartDash
              pieData={pieChartTypes}
              title='Clicks by Content Type'
              subtitle={rptDate + ' to present'}
            />
          </View>

          <View style={{borderRadius: 4, borderColor:'silver', borderWidth:1, padding:10, marginBottom:10, marginRight: !isMobile ? 10: 0, width: isMobile ? Dimensions.get('window').width : 650}}>
            <PieChartDash
              pieData={pieChartDevices}
              title='Clicks by Content Device'
              subtitle={rptDate + ' to present'}
            />
          </View>

          <View style={{borderRadius: 4, borderColor:'silver', borderWidth:1, padding:10, marginBottom:10, marginRight: !isMobile ? 10: 0, width: isMobile ? Dimensions.get('window').width : 650}}>
            <BarChartDash
              barData={dataset}
              title='Clicks by Weekday'
              subtitle={rptDate + ' to present'}
            />
          </View>

          <View style={{borderRadius: 4, borderColor:'silver', borderWidth:1, padding:10, marginBottom:10, marginRight: !isMobile ? 10: 0, width: isMobile ? Dimensions.get('window').width : 650}}>
            <BarChartDash
                barData={datasetWeekDay}
                title='Clicks by Date'
                subtitle={rptDate + ' to present'}
              />
          </View>

          <View style={{borderRadius: 4, borderColor:'silver', borderWidth:1, padding:10, marginBottom:10, marginRight: !isMobile ? 10: 0, width: isMobile ? Dimensions.get('window').width : 650}}>
              <PieChartDash
                pieData={pieChartWidget}
                title='Accessed by Widget'
                subtitle={rptDate + ' to present'}
              />
          </View>

          <View style={{borderRadius: 4, borderColor:'silver', borderWidth:1, padding:10, marginBottom:10, marginRight: !isMobile ? 10: 0, width: isMobile ? Dimensions.get('window').width : 650}}>
            {/* //TODO JUST DO TOP 10 
                <BarChartDash
                barData={datasetMeTags}
                title='Trending Tags'
                subtitle={rptDate + ' to present'}
                meTagLabel.push(term, cnt);
              /> */}
          <Text style={{fontWeight:'bold', fontFamily: 'Arial'}}>Clicks by Tag</Text>
          <Text style={{color:'grey', fontFamily: 'Arial', paddingBottom:10}}>{rptDate} to present</Text>

          <FlatList
              data={tagLabel}
              horizontal={false}
              keyExtractor={(item, index) => index.toString()}
              columnWrapperStyle={{justifyContent: 'space-between'}}
              numColumns={2}
              renderItem={({item}) => (
                  <View style={{alignContent:'flex-start', width:250, flexDirection:'row'}}>
                    <Text>{item.term}</Text><Text style={{fontWeight:'bold', color: item.cnt>1000?'red':null }}>  ({item.cnt})</Text>
                  </View>
              )}
          /> 
          </View>

      </View>


          {/* PARENT TO SCROLL HORIZ */}

          {/* <FlatList
              data={hdrData}
              horizontal={true}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({item}) => (
                <View>
                  <Text>{item.lsname}({item.total})</Text>
                </View>
              )}
          />   */}

        {/* <iframe 
            width="100%" 
            height= "500"
            src={'https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/charts/nodechart/scalingnodesedges.htm'}
            //src={`${pdfBase64}`}
            scrolling="auto"
            frameBorder="1"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
        />  */}

        {/* {Platform.OS === 'web'
            ?
            <View style={{
              width: Dimensions.get('window').width,
              paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
              paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
            }}>
              <iframe 
              width="100%" 
              height= {Dimensions.get('window').height-50}
              //src={'https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/charts/nodechart/nodesedgesbyjson.htm'}
              src={'https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/charts/msstats.aspx'}
              //src={`${pdfBase64}`}
              scrolling="auto"
              frameBorder="1"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
              />
            </View>
            : 
            <>
                <ScrollView 
                    style={{padding:5}}
                    showsVerticalScrollIndicator={true}
                >
                <View style={{width:600, height: 1200 }}>
                    <WebView
                        //source={{uri: 'https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/charts/nodechart/nodesedgesbyjson.htm'}}
                        source={{uri: 'https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/charts/msstats.aspx'}}
                    /> 
                </View>
                </ScrollView>
            </>
            } */}

        </View>
          </View>
          </>
        :
            <View><Text>No data available at this time</Text></View>
        }
        </>
    : <View style={{flex:1}}><ActivityIndicator style={{marginTop:150}} color="#005157" size="large"/></View> 
    }

    </ScrollView>
    )
}

export default DashboardScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  cell10:{
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    width:30
  },
  cell50: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    width:50
  },
});