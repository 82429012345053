import React from 'react';
import { View, StyleSheet, ImageBackground, Text, Dimensions, Linking } from 'react-native';
import { Platform } from 'react-native';
import { isMobile } from 'react-device-detect';
import { LinearGradient } from 'expo-linear-gradient';

const genAddress = (addressObj) => {
  let address = "";
  if (addressObj.street) {
    address += `${addressObj.street},`;
  }
  if (addressObj.suiteNum) {
    address += ` ${addressObj.suiteNum},`;
  }
  if (addressObj.city) {
    address += ` ${addressObj.city},`;
  }
  if (addressObj.state) {
    address += ` ${addressObj.state},`;
  }
  if (addressObj.country) {
    address += ` ${addressObj.country},`;
  }
  if (addressObj.zipCode) {
    address += ` ${addressObj.zipCode}`;
  }
  address = address.trim();
  return address;
};

const genHours = (hoursStr) => {
  let hourComps = hoursStr.split('-');
  if (hourComps.length === 2) {
    try {
      let newHourStr = `${Number(hourComps[0]) > 12 ? Number(hourComps[0]) - 12: hourComps[0]}${Number(hourComps[0]) > 12 ? 'PM' : 'AM'}` +
      `-${Number(hourComps[1]) > 12 ? Number(hourComps[1]) - 12: hourComps[1]}${Number(hourComps[1]) > 12 ? 'PM' : 'AM'}`;
      return newHourStr;
    } catch (err) {
      return "";
    }
  } else {
    return "";
  }
};

const normalizeCasing = (title, comma=true) => {
  if (title) {
    let titlePrts = title.split(" ");
    for (let i = 0; i < titlePrts.length; i++) {
      if (titlePrts[i].length > 0) {
        titlePrts[i] = titlePrts[i][0].toUpperCase() + titlePrts[i].substr(1).toLowerCase();
      }
    }
    titlePrts = titlePrts.join(" ");
    return comma ? `${titlePrts},` : titlePrts;
  } else {
    return "";
  }
}

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

const RestaurantHeader = ({ fetchPubAsset, darkMode, restaurantInfo, tagBarColor }) => {
    const styles =  StyleSheet.create({
        imageHeader: {
            width: (Platform.OS === 'web' && !isMobile) ? 750 : 300,
            height: (Platform.OS === 'web' && !isMobile) ? 200 : 90,
            marginLeft: 10,
            marginRight: (Platform.OS === 'web' && !isMobile) ? 120 : 40,
        },
        txtStyle: {
            fontSize: 18,
            fontWeight: '800',
            color: darkMode ? 'white' : '#404040',
            padding: 5,
            paddingLeft: 10,
        },
        txtStyleField: {
            fontSize: 18,
            fontWeight: '600',
            color: darkMode ? 'white' : '#5c5c5c',
            paddingBottom: 5,
            paddingTop: 5
        },
        txtLinkStyle: {
            fontSize: 18,
            fontWeight: '600',
            color: 'blue',
            padding: 5
        },
        txtBtnStyle: {
            fontSize: 12,
            fontWeight: '600',
            color: 'white',
            padding: 5,
        },
        btnStyle: {
            flexDirection: 'row', 
            width: 70, 
            height: 40, 
            backgroundColor: tagBarColor,
            justifyContent: 'center', 
            alignItems: 'center',
            marginLeft: 10,
            marginTop: 5,
            borderRadius: 5,
        },
        btnContainer: {
            flex: 1, 
            flexDirection: 'row', 
            flexWrap: 'wrap', 
            justifyContent: (Platform.OS === 'web' && !isMobile) ? 'center': null, 
            alignItems: 'center', 
            paddingRight: (Platform.OS === 'web' && !isMobile) ? 60 : 10
        },
        mainContainer: {
            flex: 1, 
            marginBottom: (Platform.OS === 'web' && !isMobile) ? 10: null,
            marginLeft: (Platform.OS === 'web' && !isMobile) ? 18: null, 
            borderRadius: 5,
            marginLeft: (Platform.OS === 'web' && !isMobile) ? null : 10,
            marginRight: (Platform.OS === 'web' && !isMobile) ? null : 10
        }
    });

    return (
        <LinearGradient style={styles.mainContainer} colors={darkMode ? ['#595959', '#3c3c3d'] : ['#dedede', '#ffffff']}>
            <View style={{flexDirection: (Platform.OS === 'web' && !isMobile) ? 'row': 'column', justifyContent: 'center', alignItems: 'center'}}>
                <View style={styles.imageHeader}>
                <ImageBackground 
                  style={styles.imageHeader} 
                  imageStyle={{resizeMode: 'contain'}}
                  source={
                    (Platform.OS === 'web' && !isMobile) 
                      ? {uri: darkMode ? fetchPubAsset('TopHeader-Web-DarkMode') : fetchPubAsset('TopHeader-Web')} 
                      : {uri: darkMode ? fetchPubAsset('TopHeader-Mobile-DarkMode') : fetchPubAsset('TopHeader-Mobile')}
                  }
                  defaultSource={Platform.OS === 'web' 
                   ? (Platform.OS === 'web' && !isMobile) 
                     ? {uri: darkMode ? fetchPubAsset('TopHeader-Web-DarkMode') : fetchPubAsset('TopHeader-Web')} 
                     : {uri: darkMode ? fetchPubAsset('TopHeader-Mobile-DarkMode') : fetchPubAsset('TopHeader-Mobile')}
                   : null}
                >
                </ImageBackground>
                </View>
                <View style={{
                        width: Math.min((Dimensions.get('window').width-20), 800),
                        //paddingLeft: (Platform.OS === 'web' && !isMobile) ? 40 : null,
                        marginLeft: (Platform.OS === 'web' && !isMobile) ? -400 : null,
                        flexDirection: 'row', 
                        justifyContent: 'center', 
                        alignItems: 'center'
                      }}
                >
                    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                        <View style={{width: Math.min(460, Dimensions.get('window').width-10), alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                            {/*{restaurantInfo.name ? <Text style={styles.txtStyle}>{`Name: ${restaurantInfo.name}`}</Text> : null}*/}
                            {restaurantInfo.name ? 
                              <View style={{flexDirection: 'row'}}>
                                  {/*<Text style={styles.txtStyle}>Name:</Text>*/}
                                  <Text style={[styles.txtStyle, {paddingLeft: 8}]} numberOfLines={4}>{`${normalizeCasing(restaurantInfo.name, false)}`}</Text></View> 
                            : null}
                            {restaurantInfo.address ? 
                              <View style={{flexDirection: 'row'}}>
                                  <Text style={styles.txtStyle}>Location:</Text>
                                  <Text style={[styles.txtStyleField, {paddingLeft: 28}]} numberOfLines={4}>{`${genAddress(restaurantInfo.address)}`}</Text></View> 
                            : null}
                            {(restaurantInfo.hours && genHours(restaurantInfo.hours) !== "") ? 
                              <View style={{flexDirection: 'row'}}>
                                <Text style={styles.txtStyle}>Hours:</Text>
                                <Text style={[styles.txtStyleField, {paddingLeft: 52}]}>{`${genHours(restaurantInfo.hours)}`}</Text></View> 
                            : null}
                            {(restaurantInfo.phoneNum && formatPhoneNumber(restaurantInfo.phoneNum) !== "") ? 
                              <View style={{flexDirection: 'row'}}>
                                <Text style={styles.txtStyle}>Phone:</Text>
                                <Text style={[styles.txtStyleField, {paddingLeft: 48}]}>{`${formatPhoneNumber(restaurantInfo.phoneNum)}`}</Text></View> 
                            : null}
                            {restaurantInfo.healthInfo ? 
                              <View style={{flexDirection: 'row'}}>
                                <Text style={styles.txtStyle}>Health Info:</Text>
                                <Text 
                                  style={[styles.txtStyleField, {paddingLeft: 10, width: (Platform.OS === 'web' && !isMobile) ? 320 : 250}]}>
                                    {`${restaurantInfo.healthInfo}`}
                                </Text>
                              </View> 
                            : null}
                            <View style={styles.btnContainer}>
                                {restaurantInfo.website 
                                    ? <View style={styles.btnStyle}>
                                        <Text 
                                          style={styles.txtBtnStyle} 
                                          onPress={() => Platform.OS === 'web' ? window.open(restaurantInfo.website) : Linking.openURL(restaurantInfo.website)}>
                                              Website
                                        </Text>
                                    </View> 
                                    : null
                                }
                                {restaurantInfo.menuUrl
                                    ? <View style={styles.btnStyle}>
                                        <Text 
                                          style={styles.txtBtnStyle} 
                                          onPress={() => Platform.OS === 'web' ? window.open(restaurantInfo.menuUrl) : Linking.openURL(restaurantInfo.menuUrl)}>
                                            Menu
                                        </Text>
                                    </View> 
                                    : null
                                }
                                {restaurantInfo.reserveUrl
                                    ? <View style={styles.btnStyle}>
                                        <Text 
                                          style={styles.txtBtnStyle} 
                                          onPress={() => Platform.OS === 'web' ? window.open(restaurantInfo.reserveUrl) : Linking.openURL(restaurantInfo.reserveUrl)}>
                                            Reserve
                                        </Text>
                                    </View> 
                                    : null
                                }
                            </View>
                        </View>
                   
                    </View>
                </View>
            </View>
        </LinearGradient>
    );
};

export default RestaurantHeader;