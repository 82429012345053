import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import PubHomeScreen from '../../screens/PubHomeScreen';
import EditPublisherScreen from '../../screens/EditPublisherScreen';

const Stack = createStackNavigator();

const pubFlow = () => {
  return (
    <Stack.Navigator
      initialRouteName="PubHome"
      screenOptions={{ gestureEnabled: true }}
    >
      <Stack.Screen
        name="PubHome"
        component={PubHomeScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name="PubEdit"
        component={EditPublisherScreen}
        options={{headerShown: true}}
      />
    </Stack.Navigator>
  );
};

export default pubFlow;