import React, {useState, useEffect, useContext} from 'react';
import { View, Text, ScrollView, Dimensions, TextInput, StyleSheet, Picker, ActivityIndicator } from 'react-native';
import FormWrapper from '../components/wrappers/MeSearch/FormWrapper';
import FormFieldTypesEnum from './utils/FormValidation/formFieldTypesEnum';
import SubmitField from './utils/FormValidation/FormValidationSubmitField';
import ValidationTypesEnum from './utils/FormValidation/formValidateTypesEnum';
import BoolWrapper from '../components/wrappers/kiosk/BoolWrapper';
import KioskAPI from '../api/kiosk/MeSearch-API-Kiosk';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import HeaderButton from '../components/core/HeaderButton';
import BoolTermsEnum from './utils/FormValidation/utils/boolTermsEnum';
import MeSearchAPIKiosk from '../api/kiosk/MeSearch-API-Kiosk';
import useKioskResults from '../hooks/kiosk/useKioskResults';
import AsyncStorage from '@react-native-async-storage/async-storage';

const SearchHomeScreen = ({navigation, route}) => {

  const [step, setStep] = useState(1);
  const [multiValueIndex, setMultiValueIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [ 
    accounts,   
    accountGrpDetails,
    accountGrpSets,
    accountCodingFrm,
    accountCodingData,
    loading,
    error,
    getAccounts ] = useKioskResults();

  const getUsrName = async () => {
    let userId = "Search";
    try {
      const userResponse = await MeSearchAPIKiosk.get('/api/kiosk/user/id');
      userId = userResponse.data.userId;
    } catch (err) {
      console.log(err);
    }
    return userId;
  };

  const [fieldsMeta, setFieldsMeta] = useState({
    name: {
      name: "Search Name", 
      value: `Search-${new Date().toISOString()}`, 
      error: "", 
      required: true, 
      type: FormFieldTypesEnum.TEXTINPUT, 
      step: 1
    },
    description: {
      name: "Search Description", 
      value: "", 
      error: "", 
      required: false, 
      type: FormFieldTypesEnum.TEXTINPUT, 
      step: 1
    },
    periodicity: { 
      name: "Periodicity",
      value: {
        date: new Date(), time: new Date(), 
        interval: {"Once": true, "Daily": false, "Weekly": false}, 
        dailyInfo: {"On or Near": true, "Before": false, "After": false},
        weeklyInfo: {"Sun": false, "Mon": true, "Tues": false, "Wed": false, "Thur": false, "Fri": false, "Sat": false}
      }, 
      error: "", 
      required: false, 
      type: FormFieldTypesEnum.PERIODICITY, 
      step: 2
    },
    date: {
      name: "Date Range", 
      value: "No Date Range", 
      error: "", 
      required: false, 
      type: FormFieldTypesEnum.CALENDARRANGE, 
      step: 2
    },
    boolLine: { 
      name: "Search", 
      //value: [{ value: {terms: ["this and that and this and that", "\"this and blah blah\""]}, logic: BoolTermsEnum.AND, tags: "All Tags" }], 
      value: [{ value: {}, logic: BoolTermsEnum.AND, tags: "All Tags" }], 
      error: "", 
      required: true, 
      type: FormFieldTypesEnum.MULTIBOOL, 
      step: 2
    }
  });

  const editBoolValidation = {
    name: { validate: SubmitField(ValidationTypesEnum.ANY) },
    description: { validate: SubmitField(ValidationTypesEnum.ANY) },
    date: { validate: SubmitField(ValidationTypesEnum.ANY) },
    boolLine: { validate: SubmitField(ValidationTypesEnum.ANY) },
    periodicity: { validate: SubmitField(ValidationTypesEnum.ANY) }
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getAccounts();
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const genDefaultSearchName = async () => {
      if (accounts && accounts.length > 0) {
        let accName = "";
        const kAccountToken = await AsyncStorage.getItem('kAccountToken');
        if (kAccountToken) {
          const acc = accounts.find(a => a.AccountId == kAccountToken);
          if (acc) {
            accName = acc.OwnersAccountTitle;
          }
        } else {
          accName = accounts[0].OwnersAccountTitle;
        }
        let usrName = await getUsrName();
        let newFieldObj = fieldsMeta.name;
        newFieldObj.value = `${usrName}-${accName}-${new Date().toISOString()}`;
        let newFieldsMeta = JSON.parse(JSON.stringify(fieldsMeta));
        newFieldsMeta.name = newFieldObj;
        setFieldsMeta(newFieldsMeta);
      }
    }
    genDefaultSearchName();
  }, [accounts]);

  return (
      <ScrollView>
        <View style={{padding:20, 
          marginLeft: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 10 : '25%',
          marginRight: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 10 : '25%',
        }}
        >
          <View style={{justifyContent: 'center', alignItems: 'center'}}>
            { errorMessage ? (
              <Text style={styles.errorMessage}>{errorMessage}</Text>
            ) : null }
            <FormWrapper 
              Component={BoolWrapper}
              values={fieldsMeta}
              validation={editBoolValidation}
              navigation={navigation}
              route={route}
              setStep={setStep}
              step={step}
              index={multiValueIndex}
              setIndex={setMultiValueIndex}
              setMeta={setFieldsMeta}
              fMeta={fieldsMeta}
            />
          </View>
        </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  textArea: {
    height: 280,
    marginLeft:10,
    justifyContent: "flex-start",
    borderColor: 'silver', borderStyle:'dashed', borderWidth: 2
  },
  textAreaShort: {
    height: 100,
    marginLeft:10,
    justifyContent: "flex-start",
    borderColor: 'silver', borderStyle:'dashed', borderWidth: 2
  },
  errorMessage: {
    fontSize: 16, 
    color: 'red', 
    paddingBottom: 10, 
    paddingLeft: 10
  },
  headerButtonText: {
    color: '#24627a',
    fontWeight: '700',
    fontSize: 16
  }
})

export default SearchHomeScreen;