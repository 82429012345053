import DrawerItem from './DrawerItem';
import React from "react";
import { StyleSheet, View, FlatList } from "react-native";


const DrawerContent = ({navigation, tagBarColor, menuData, menuType, setMenuData, setMenuType, pubMenuMain, pubMenuCategory, isPubCatagories }) => {
    const styles = StyleSheet.create({
        container: {
          flex: 1,
          backgroundColor: tagBarColor,
          paddingTop: 70
        },
        menuRow: {
            flex: 1,
            borderBottomColor: 'gray',
            borderBottomWidth: menuType === 'Main' ? 1 : 0,
            padding: 5
        }
    });

    const genMenuMain = (menuItems, pubMenuItems, pubMenuCategory) => {
      let menuItemsCp = JSON.parse(JSON.stringify(menuItems));
      if (isPubCatagories) {
        menuItemsCp.push({ icon: "list", name: "Categories", content: pubMenuCategory, key: `${menuItemsCp.length}` });
      }
      if (!pubMenuItems || !(pubMenuItems.length > 0)) {
        //menuItemsCp.push({ icon: "x-square", name: "Close", key: `${menuItemsCp.length}` });
        return menuItemsCp;
      }
      let curKey = 4;
      pubMenuItems.forEach(pubMenuItem => {
        pubMenuItem["key"] = `${curKey}`;
        curKey += 1;
        menuItemsCp.push(pubMenuItem);
      });
      //menuItemsCp.push({ icon: "x-square", name: "Close", key: `${menuItemsCp.length+1}` });
      return menuItemsCp;
    };

    const genMenu = (menuType, menuData, pubMenuMain, pubMenuCategory) => {
      switch (menuType) {
        case 'Main':
          const menu = genMenuMain(menuData, pubMenuMain, pubMenuCategory);
          return menu;
        case 'Catagories':
          return menuData;
        default:
          return menuData;
      };
    };

    return (
        <View style={styles.container}>
            <FlatList
                data={genMenu(menuType, menuData, pubMenuMain, pubMenuCategory)}
                keyExtractor={(item) => menuType === 'Main' ? item.key : item.name}
                renderItem={({ item, index }) => (
                <View style= {styles.menuRow}>
                    <DrawerItem
                        navigation={navigation}
                        screenName={item.screenName}
                        icon={item.icon}
                        name={item.name}
                        key={item.key}
                        index={index}
                        uri={item.uri}
                        menuType={menuType}
                        setMenuData={setMenuData}
                        setMenuType={setMenuType}
                        data={item}
                        endIndex={menuData.length-1}
                    />
                </View>
                )}
            />
        </View>
    );
}

export default DrawerContent;