import React, { useState, useContext } from 'react';
import  { Text, Button } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView } from 'react-native';
import Spacer from '../components/Space';
import MeSearchAPI from '../api/MeSearch-API';
import { Context as PubContext } from '../context/PubContext';
import { useNavigation } from '@react-navigation/native';

const ApproveTrustedContributorScreen = ({/*navigation,*/ route}) => {
  const navigation = useNavigation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMsg] = useState(null);
  const  PubCon = useContext(PubContext);
  const email = route.params?.email ?? '';
  //const email = navigation.getParam('email');
  return (
    <SafeAreaView style={Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall}>
      <Spacer>
      <View style={{flex: 0.5, marginTop: 10, justifyContent: 'center', alignItems: 'center'}}>
        <Image style={styles.image} source={require('../../assets/logo-mesearch.png')}/>
      </View>
      </Spacer>
      {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
      {successMessage ? <Text style={styles.successMessage}>{successMessage}</Text> : null}
      <Spacer>
        <Button
          title={'Approve'}
          onPress={ async () =>  {
            try {
              await MeSearchAPI.post('/api/user/approve-trusted', { email, pub: PubCon.state.publisher.username, referrer: PubCon.state.ref, pubOwner: PubCon.state.publisher.owner });
              setSuccessMsg('Successfully approved user!')
            } catch (err) {
              setErrorMessage(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`)
            }
          }}
        />
      </Spacer>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
    containerSmall: {
      flex: 1
    },
    containerLarge: {
      width: Dimensions.get('window').width,
      paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
      paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null
    },
    errorMessage: {
      fontSize: 18,
      color: 'red',
      marginTop: 15,
      marginLeft: 15
    },
    successMessage: {
      fontSize: 18,
      color: 'green',
      marginTop: 15,
      marginLeft: 15
    },
    image: {
      width: 200,
      height: 80,
      marginTop: 5,
    }
});

export default ApproveTrustedContributorScreen;