import React from 'react';
import { Keyboard, TouchableWithoutFeedback } from 'react-native';
import { Platform } from 'react-native';

const DismissKeyboard = ({ children }) => (
  <>
  {
    Platform.OS !== 'web' ? (
    <TouchableWithoutFeedback
      onPress={() => Keyboard.dismiss()}
    >
      { children }
    </TouchableWithoutFeedback> ) : <>{ children }</>
  }
  </>
);

export default DismissKeyboard;