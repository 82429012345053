import React, {useEffect, useState} from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Linking } from 'react-native';
import { Platform } from 'react-native';

const InlineHyperlink = ({text, bodyLinks, darkMode}) => {

  const styles = StyleSheet.create({
    link: {
        color: 'blue'
    },
    contentStyle: {
        fontWeight: '400',
        // marginTop: 5,
        // marginBottom: 5,
        fontSize: 17,
        // padding: 7,
        color: darkMode ? 'white' : 'black',
        // textAlign: 'left'
    },
  });

  const genTextComps = () => {
    let supTxtComps = [];
    let displayTxtIndexMap = {};
    let prev;
    bodyLinks.forEach((bodyLink, index) => {
      let txtComps = [];
      txtComps = index === 0 ? text.split(`${bodyLink.displayTxt}`) : prev.split(`${bodyLink.displayTxt}`);
      // The following is to deal with multiple links with the same dispayTxt in the same paragragh
      if (txtComps.length > 2) {
        let firstTxt = txtComps[0];
        txtComps.shift();
        let suffixTxtComps = txtComps.join(`${bodyLink.displayTxt}`);
        txtComps = [firstTxt];
        txtComps.push(suffixTxtComps);
      }
      prev = txtComps.length === 2 ? txtComps[1] : txtComps[0];
      displayTxtIndexMap[bodyLink.displayTxt] = (supTxtComps.length + 1);
      supTxtComps.push(...txtComps);
    });
    return supTxtComps;
  };

  const txtComponents = genTextComps();
  let renderComps = (
    <View><Text style={styles.contentStyle}>{`${text}`}</Text></View>
  );

  switch (txtComponents.length) {
    case 0:
      renderComps = (
        <View><Text style={styles.contentStyle}>{`${text}`}</Text></View>
      );
      break;
    case 1:
      renderComps = (
        <View>
          <Text style={styles.contentStyle}>
            {`${txtComponents[0]}`}
            <Text
            style={styles.link}
            onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
            >
            {`${bodyLinks[0].displayTxt}`}
            </Text>
          </Text>
        </View>
      );
      break;
    case 2:
        renderComps = (
            <View>
              <Text style={styles.contentStyle}>
                {`${txtComponents[0]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
                >
                {`${bodyLinks[0].displayTxt}`}
                </Text>
                {`${txtComponents[1]}`}
              </Text>
            </View>
        );
        break;
    case 3:
        renderComps = (
            <View>
                <Text style={styles.contentStyle}>
                {`${txtComponents[0]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
                >
                {`${bodyLinks[0].displayTxt}`}
                </Text>
                {`${txtComponents[1]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[1].uri) : Linking.openURL(bodyLinks[1].uri)}
                >
                {`${bodyLinks[1].displayTxt}`}
                </Text>
                {`${txtComponents[2]}`}
                </Text>
            </View>
        );
        break;
    case 4:
        renderComps = (
            <View>
                <Text style={styles.contentStyle}>
                {`${txtComponents[0]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
                >
                {`${bodyLinks[0].displayTxt}`}
                </Text>
                {`${txtComponents[2]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[1].uri) : Linking.openURL(bodyLinks[1].uri)}
                >
                {`${bodyLinks[1].displayTxt}`}
                </Text>
                {`${txtComponents[3]}`}
                </Text>
            </View>
        );
        break;
    case 5:
        renderComps = (
          <View>
            <Text style={styles.contentStyle}>
            {`${txtComponents[0]}`}
            <Text
            style={styles.link}
            onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
            >
            {`${bodyLinks[0].displayTxt}`}
            </Text>
            {`${txtComponents[2]}`}
            <Text
            style={styles.link}
            onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[1].uri) : Linking.openURL(bodyLinks[1].uri)}
            >
            {`${bodyLinks[1].displayTxt}`}
            </Text>
            {`${txtComponents[3]}`}
            <Text
            style={styles.link}
            onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[2].uri) : Linking.openURL(bodyLinks[2].uri)}
            >
            {`${bodyLinks[2].displayTxt}`}
            </Text>
            </Text>
          </View>
        );
        break;
    case 6:
        renderComps = (
            <View>
                <Text style={styles.contentStyle}>
                {`${txtComponents[0]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
                >
                {`${bodyLinks[0].displayTxt}`}
                </Text>
                {`${txtComponents[2]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[1].uri) : Linking.openURL(bodyLinks[1].uri)}
                >
                {`${bodyLinks[1].displayTxt}`}
                </Text>
                {`${txtComponents[4]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[2].uri) : Linking.openURL(bodyLinks[2].uri)}
                >
                {`${bodyLinks[2].displayTxt}`}
                </Text>
                {`${txtComponents[5]}`}
                </Text>
            </View>
        );
        break;
    case 7:
        renderComps = (
            <View>
                <Text style={styles.contentStyle}>
                {`${txtComponents[0]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
                >
                {`${bodyLinks[0].displayTxt}`}
                </Text>
                {`${txtComponents[2]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[1].uri) : Linking.openURL(bodyLinks[1].uri)}
                >
                {`${bodyLinks[1].displayTxt}`}
                </Text>
                {`${txtComponents[4]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[2].uri) : Linking.openURL(bodyLinks[2].uri)}
                >
                {`${bodyLinks[2].displayTxt}`}
                </Text>
                {`${txtComponents[5]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[3].uri) : Linking.openURL(bodyLinks[3].uri)}
                >
                {`${bodyLinks[3].displayTxt}`}
                </Text>
                </Text>
            </View>
        );
        break;
    case 8:
        renderComps = (
            <View>
                <Text style={styles.contentStyle}>
                {`${txtComponents[0]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
                >
                {`${bodyLinks[0].displayTxt}`}
                </Text>
                {`${txtComponents[2]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[1].uri) : Linking.openURL(bodyLinks[1].uri)}
                >
                {`${bodyLinks[1].displayTxt}`}
                </Text>
                {`${txtComponents[4]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[2].uri) : Linking.openURL(bodyLinks[2].uri)}
                >
                {`${bodyLinks[2].displayTxt}`}
                </Text>
                {`${txtComponents[6]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[3].uri) : Linking.openURL(bodyLinks[3].uri)}
                >
                {`${bodyLinks[3].displayTxt}`}
                </Text>
                {`${txtComponents[7]}`}
                </Text>
            </View>
        );
        break;
    case 9:
        renderComps = (
            <View>
                <Text style={styles.contentStyle}>
                {`${txtComponents[0]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
                >
                {`${bodyLinks[0].displayTxt}`}
                </Text>
                {`${txtComponents[2]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[1].uri) : Linking.openURL(bodyLinks[1].uri)}
                >
                {`${bodyLinks[1].displayTxt}`}
                </Text>
                {`${txtComponents[4]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[2].uri) : Linking.openURL(bodyLinks[2].uri)}
                >
                {`${bodyLinks[2].displayTxt}`}
                </Text>
                {`${txtComponents[6]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[3].uri) : Linking.openURL(bodyLinks[3].uri)}
                >
                {`${bodyLinks[3].displayTxt}`}
                </Text>
                {`${txtComponents[7]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[4].uri) : Linking.openURL(bodyLinks[4].uri)}
                >
                {`${bodyLinks[4].displayTxt}`}
                </Text>
                </Text>
            </View>
        );
        break;
    case 10:
        renderComps = (
            <View>
                <Text style={styles.contentStyle}>
                {`${txtComponents[0]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
                >
                {`${bodyLinks[0].displayTxt}`}
                </Text>
                {`${txtComponents[2]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[1].uri) : Linking.openURL(bodyLinks[1].uri)}
                >
                {`${bodyLinks[1].displayTxt}`}
                </Text>
                {`${txtComponents[4]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[2].uri) : Linking.openURL(bodyLinks[2].uri)}
                >
                {`${bodyLinks[2].displayTxt}`}
                </Text>
                {`${txtComponents[6]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[3].uri) : Linking.openURL(bodyLinks[3].uri)}
                >
                {`${bodyLinks[3].displayTxt}`}
                </Text>
                {`${txtComponents[8]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[4].uri) : Linking.openURL(bodyLinks[4].uri)}
                >
                {`${bodyLinks[4].displayTxt}`}
                </Text>
                {`${txtComponents[9]}`}
                </Text>
            </View>
        );
        break;
    case 11:
        renderComps = (
            <View>
                <Text style={styles.contentStyle}>
                {`${txtComponents[0]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
                >
                {`${bodyLinks[0].displayTxt}`}
                </Text>
                {`${txtComponents[2]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[1].uri) : Linking.openURL(bodyLinks[1].uri)}
                >
                {`${bodyLinks[1].displayTxt}`}
                </Text>
                {`${txtComponents[4]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[2].uri) : Linking.openURL(bodyLinks[2].uri)}
                >
                {`${bodyLinks[2].displayTxt}`}
                </Text>
                {`${txtComponents[6]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[3].uri) : Linking.openURL(bodyLinks[3].uri)}
                >
                {`${bodyLinks[3].displayTxt}`}
                </Text>
                {`${txtComponents[8]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[4].uri) : Linking.openURL(bodyLinks[4].uri)}
                >
                {`${bodyLinks[4].displayTxt}`}
                </Text>
                {`${txtComponents[9]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[5].uri) : Linking.openURL(bodyLinks[5].uri)}
                >
                {`${bodyLinks[5].displayTxt}`}
                </Text>
                </Text>
            </View>
        );
        break;
    case 12:
        renderComps = (
            <View>
                <Text style={styles.contentStyle}>
                {`${txtComponents[0]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
                >
                {`${bodyLinks[0].displayTxt}`}
                </Text>
                {`${txtComponents[2]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[1].uri) : Linking.openURL(bodyLinks[1].uri)}
                >
                {`${bodyLinks[1].displayTxt}`}
                </Text>
                {`${txtComponents[4]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[2].uri) : Linking.openURL(bodyLinks[2].uri)}
                >
                {`${bodyLinks[2].displayTxt}`}
                </Text>
                {`${txtComponents[6]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[3].uri) : Linking.openURL(bodyLinks[3].uri)}
                >
                {`${bodyLinks[3].displayTxt}`}
                </Text>
                {`${txtComponents[8]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[4].uri) : Linking.openURL(bodyLinks[4].uri)}
                >
                {`${bodyLinks[4].displayTxt}`}
                </Text>
                {`${txtComponents[10]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[5].uri) : Linking.openURL(bodyLinks[5].uri)}
                >
                {`${bodyLinks[5].displayTxt}`}
                </Text>
                {`${txtComponents[11]}`}
                </Text>
            </View>
        );
        break;
    case 13:
        renderComps = (
            <View>
                <Text style={styles.contentStyle}>
                {`${txtComponents[0]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
                >
                {`${bodyLinks[0].displayTxt}`}
                </Text>
                {`${txtComponents[2]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[1].uri) : Linking.openURL(bodyLinks[1].uri)}
                >
                {`${bodyLinks[1].displayTxt}`}
                </Text>
                {`${txtComponents[4]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[2].uri) : Linking.openURL(bodyLinks[2].uri)}
                >
                {`${bodyLinks[2].displayTxt}`}
                </Text>
                {`${txtComponents[6]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[3].uri) : Linking.openURL(bodyLinks[3].uri)}
                >
                {`${bodyLinks[3].displayTxt}`}
                </Text>
                {`${txtComponents[8]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[4].uri) : Linking.openURL(bodyLinks[4].uri)}
                >
                {`${bodyLinks[4].displayTxt}`}
                </Text>
                {`${txtComponents[10]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[5].uri) : Linking.openURL(bodyLinks[5].uri)}
                >
                {`${bodyLinks[5].displayTxt}`}
                </Text>
                {`${txtComponents[11]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[6].uri) : Linking.openURL(bodyLinks[6].uri)}
                >
                {`${bodyLinks[6].displayTxt}`}
                </Text>
                </Text>
            </View>
        );
        break;
    case 14:
        renderComps = (
            <View>
                <Text style={styles.contentStyle}>
                {`${txtComponents[0]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
                >
                {`${bodyLinks[0].displayTxt}`}
                </Text>
                {`${txtComponents[2]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[1].uri) : Linking.openURL(bodyLinks[1].uri)}
                >
                {`${bodyLinks[1].displayTxt}`}
                </Text>
                {`${txtComponents[4]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[2].uri) : Linking.openURL(bodyLinks[2].uri)}
                >
                {`${bodyLinks[2].displayTxt}`}
                </Text>
                {`${txtComponents[6]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[3].uri) : Linking.openURL(bodyLinks[3].uri)}
                >
                {`${bodyLinks[3].displayTxt}`}
                </Text>
                {`${txtComponents[8]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[4].uri) : Linking.openURL(bodyLinks[4].uri)}
                >
                {`${bodyLinks[4].displayTxt}`}
                </Text>
                {`${txtComponents[10]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[5].uri) : Linking.openURL(bodyLinks[5].uri)}
                >
                {`${bodyLinks[5].displayTxt}`}
                </Text>
                {`${txtComponents[12]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[6].uri) : Linking.openURL(bodyLinks[6].uri)}
                >
                {`${bodyLinks[6].displayTxt}`}
                </Text>
                {`${txtComponents[13]}`}
                </Text>
            </View>
        );
        break;
    case 15:
        renderComps = (
            <View>
                <Text style={styles.contentStyle}>
                {`${txtComponents[0]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
                >
                {`${bodyLinks[0].displayTxt}`}
                </Text>
                {`${txtComponents[2]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[1].uri) : Linking.openURL(bodyLinks[1].uri)}
                >
                {`${bodyLinks[1].displayTxt}`}
                </Text>
                {`${txtComponents[4]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[2].uri) : Linking.openURL(bodyLinks[2].uri)}
                >
                {`${bodyLinks[2].displayTxt}`}
                </Text>
                {`${txtComponents[6]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[3].uri) : Linking.openURL(bodyLinks[3].uri)}
                >
                {`${bodyLinks[3].displayTxt}`}
                </Text>
                {`${txtComponents[8]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[4].uri) : Linking.openURL(bodyLinks[4].uri)}
                >
                {`${bodyLinks[4].displayTxt}`}
                </Text>
                {`${txtComponents[10]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[5].uri) : Linking.openURL(bodyLinks[5].uri)}
                >
                {`${bodyLinks[5].displayTxt}`}
                </Text>
                {`${txtComponents[12]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[6].uri) : Linking.openURL(bodyLinks[6].uri)}
                >
                {`${bodyLinks[6].displayTxt}`}
                </Text>
                {`${txtComponents[13]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[7].uri) : Linking.openURL(bodyLinks[7].uri)}
                >
                {`${bodyLinks[7].displayTxt}`}
                </Text>
                </Text>
            </View>
        );
        break;
    case 16:
        renderComps = (
            <View>
                <Text style={styles.contentStyle}>
                {`${txtComponents[0]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[0].uri) : Linking.openURL(bodyLinks[0].uri)}
                >
                {`${bodyLinks[0].displayTxt}`}
                </Text>
                {`${txtComponents[2]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[1].uri) : Linking.openURL(bodyLinks[1].uri)}
                >
                {`${bodyLinks[1].displayTxt}`}
                </Text>
                {`${txtComponents[4]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[2].uri) : Linking.openURL(bodyLinks[2].uri)}
                >
                {`${bodyLinks[2].displayTxt}`}
                </Text>
                {`${txtComponents[6]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[3].uri) : Linking.openURL(bodyLinks[3].uri)}
                >
                {`${bodyLinks[3].displayTxt}`}
                </Text>
                {`${txtComponents[8]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[4].uri) : Linking.openURL(bodyLinks[4].uri)}
                >
                {`${bodyLinks[4].displayTxt}`}
                </Text>
                {`${txtComponents[10]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[5].uri) : Linking.openURL(bodyLinks[5].uri)}
                >
                {`${bodyLinks[5].displayTxt}`}
                </Text>
                {`${txtComponents[12]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[6].uri) : Linking.openURL(bodyLinks[6].uri)}
                >
                {`${bodyLinks[6].displayTxt}`}
                </Text>
                {`${txtComponents[14]}`}
                <Text
                style={styles.link}
                onPress={() => Platform.OS === 'web' ? window.open(bodyLinks[7].uri) : Linking.openURL(bodyLinks[7].uri)}
                >
                {`${bodyLinks[7].displayTxt}`}
                </Text>
                {`${txtComponents[15]}`}
                </Text>
            </View>
        );
        break;
    default:
      renderComps = (
        <View><Text style={styles.contentStyle}>{`${text}`}</Text></View>
      );
      break;
  }

  return renderComps;
};

export default InlineHyperlink;