import React, { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import CalendarDropdown from './CalendarDropdown';


const DatePicker = ({onChange, date, mode}) => {

    return (
        <View style={{padding: 50}}>
          {Platform.OS === 'web' ?
            <CalendarDropdown
              onChange={onChange}
              date={date}
              mode={mode}
            />
          :
          <DateTimePicker
            testID="dateTimePicker"
            value={new Date(date)}
            mode={mode ? mode : 'date'}
            is24Hour={true}
            display="default"
            onChange={onChange}
          />
          }
      </View>
    );
};

export default DatePicker;