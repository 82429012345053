// import axios from 'axios';
import MeSearchAPIInternal from '../api/MeSearch-API-Internal';
import MeSearchAPI from '../api/MeSearch-API';
import { useState, useEffect } from 'react';

export default () => {
  const [results, setResults] = useState();
  const [error, setError] = useState();

  const getBettingLine = async ({ meTags }) => {
    try {
      const response = await MeSearchAPIInternal.post('/api/content/getBettingLine', {
        meTags: meTags,
      });
      setResults(response.data.response);
    } catch (error) {
      setError(error);
    }
  };
  
  return [
    error,
    getBettingLine,
    results,
  ];
};