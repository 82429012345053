const formFieldTypesEnum = {
    TEXTINPUT: "input",
    TEXTINPUTURL: "inputUrl",
    IMAGEPICKER: 'imagePicker',
    SELECT: 'select',
    RADIO: 'radio',
    CHECKBOX: 'checkbox',
    DATERANGE: 'dateRange',
    FILEUPLOAD: 'fileUpload',
    IMAGEUPLOAD: 'imageUpload',
    PASSWORD: 'passwordField',
    SOCIALMEDIA: 'socialMedia',
    MULTI: 'multiline',
    MULTIURL: 'multiurl',
    VERIFIED: 'verified',
    TOGGLE: 'toggle',
    MULTIBOOL: 'multiBool',
    CALENDARRANGE: 'CalendarRange',
    PERIODICITY: 'Periodicity'
  };
    
  export default formFieldTypesEnum;