import React, { useState, useEffect } from 'react';
import  { Text, Button, Input } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, ActivityIndicator } from 'react-native';
import Spacer from '../components/Space';
import MeSearchAPI from '../api/MeSearch-API';
import CopyrightFooter from '../components/CopyrightFooter';
import { Platform } from 'react-native';


const EditPublisherAccountScreen = ({navigation, route}) => {
  const pubOwner = route.params?.pub ?? '';
  const [errorMessage, setErrorMsg] = useState("");
  const [successMessage, setSuccessMsg] = useState("");
  const [sources, setSources] = useState("");
  const [sharedSources, setSharedSources] = useState("");
  const [secret, setSecret] = useState("");
  const [ pgLoad, setPGLoad ] = useState("");

  useEffect (() => {
    try {
        const responseProm = MeSearchAPI.get('/api/pub-owner', {params:{pubOwner}});
        responseProm.then(response => {
            if (response.data) {
              setSources(response.data.sources.toString());
              setSharedSources(response.data.sharedSources ? response.data.sharedSources.toString() : "");
            }
        });
    } catch (err) {
        setErrorMsg(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`);
    }
  }, []);

  return (
    <SafeAreaView style={Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall}>
    {!pgLoad ?
    <>
      <Spacer/>
      {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
      {successMessage ? <Text style={styles.successMessage}>{successMessage}</Text> : null}
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>Sources:</Text>
      <Spacer>
        <Input 
          label="Sources (Comma Separated)"
          value={sources}
          autoCapitalize="none"
          onChangeText={(text) => setSources(text)}
          autoCorrect={false}
          maxLength={100000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>Shared Sources:</Text>
      <Spacer>
        <Input 
          label="Shared Sources (Comma Separated)"
          value={sharedSources}
          autoCapitalize="none"
          onChangeText={(text) => setSharedSources(text)}
          autoCorrect={false}
          maxLength={100000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>key:</Text>
      <Spacer>
        <Input 
          label="key"
          value={secret}
          autoCapitalize="none"
          onChangeText={(text) => setSecret(text)}
          autoCorrect={false}
          maxLength={100000}
        />
      </Spacer>
      <Spacer>
        <Button
          title={'Submit'}
          onPress={ async () =>  {
            if (sources === "") {
              setErrorMsg('Sources shouldn\'t be empty');
              return;
            }
            setPGLoad(true)
            let trimSources = JSON.parse(JSON.stringify(sources));
            trimSources = trimSources.trim();
            let sourcesArr = trimSources.split(',');
            let trimShareSources = JSON.parse(JSON.stringify(sharedSources));
            trimShareSources = trimShareSources.trim();
            let sharedSourcesArr = trimShareSources.split(',');
            let payload = {sources: sourcesArr, sharedSources: sharedSourcesArr};
            try {
                await MeSearchAPI.patch(
                    '/api/pub-owner', 
                    { payload,
                      pubOwner,
                      secret
                });
                setSuccessMsg("Successfully Updated Publisher Account");
            } catch (err) {
              setErrorMsg(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`);
            }
            setPGLoad(false);
          }}
        />
      </Spacer>
      {/* <View style={{flexDirection: 'row', justifyContent: 'center'}}>
        <View style={{height: 150, width: 280, marginBottom: 5, padding: 10}}>
            <Image style={styles.image} source={require('../../assets/web_footer_copyright.png')}/>
        </View>
      </View> */}
      <CopyrightFooter />
      </>
    : <ActivityIndicator size="large" style={{ marginTop: 150 }} color="#005157"/> }
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
    containerSmall: {
      flex: 1
    },
    containerLarge: {
      width: Dimensions.get('window').width,
      paddingLeft: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
      paddingRight: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null
    },
    errorMessage: {
      fontSize: 18,
      color: 'red',
      marginTop: 15,
      marginLeft: 15
    },
    successMessage: {
      fontSize: 18,
      color: 'green',
      marginTop: 15,
      marginLeft: 15
    },
    image: {
      width: null,
      height: null,
      marginTop: 5,
      marginBottom: 5,
      resizeMode: 'contain',
      flex: 1
    },
});

export default EditPublisherAccountScreen;