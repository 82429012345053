import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  TouchableOpacity,
  TextInput,
  FlatList,
  ScrollView,
  Platform,
} from "react-native";

import MenuHomeItem from "../../core/MenuHomeItem";

const MenuHomeWrapper = ({ navigation, route, darkMode }) => {
  const onPressHandler = (btnTitle) => {
    //console.log(`${btnTitle} was pressed`);
  };

  //   useEffect(() => {
  //     navigation.setOptions({
  //       headerTitle: () => (
  //         <View
  //           style={Platform.OS == "ios" ? { alignItems: "flex-start" } : null}
  //         >
  //           <View style={Platform.OS == "ios" ? { alignItems: "center" } : null}>
  //             <Text>Settings</Text>
  //             <Text>535media LLC</Text>
  //           </View>
  //         </View>
  //       ),
  //     });
  //   }, []);

  return (
    <View>
      <ScrollView>
        <View
          style={{
            alignItems: "center",
            borderBottomColor: "#999",
            borderBottomWidth: 1,
            paddingBottom: 10,
          }}
        >
          <Image
            style={{
              width: 150,
              height: 100,
              resizeMode: "contain",
            }}
            //source={{ uri: "https://picsum.photos/150/100" }}
            source={require("../../../../assets/mesearch_logo_small.png")}
          />
        </View>
        <View>
          <MenuHomeItem
            darkMode={darkMode}
            title="Manage Account"
            subTitle="Control your account and behavior"
            iconFamily="MaterialCommunityIcons"
            iconName="account-outline"
            onpress={onPressHandler}
          />
          {/*
          <MenuHomeItem
            darkMode={darkMode}
            title="Content Sharing"
            subTitle="Control what you share and what you accept from others "
            iconFamily="MaterialCommunityIcons"
            iconName="share-variant"
            onpress={onPressHandler}
          />
          */}
          <MenuHomeItem
            darkMode={darkMode}
            title="Privacy"
            subTitle="Control your private information"
            iconFamily="MaterialCommunityIcons"
            iconName="lock-outline"
            onpress={onPressHandler}
          />
          <MenuHomeItem
            darkMode={darkMode}
            title="Security"
            subTitle="Manage the security of your account"
            iconFamily="MaterialCommunityIcons"
            iconName="security"
            onpress={onPressHandler}
          />
          <MenuHomeItem
            darkMode={darkMode}
            title="Payment Methods"
            subTitle="Manage how you make or receive payments"
            iconFamily="MaterialCommunityIcons"
            iconName="credit-card-outline"
            onpress={onPressHandler}
          />
          {/*}
          <MenuHomeItem
            darkMode={darkMode}
            title="Your Orders"
            subTitle="Manage your orders"
            iconFamily="MaterialIcons"
            iconName="list"
            onpress={onPressHandler}
          />
          */}
          {/*
          <MenuHomeItem
            darkMode={darkMode}
            title="Buy Again"
            subTitle="Purchase again items you have ordered"
            iconFamily="MaterialCommunityIcons"
            iconName="shopping-outline"
            onpress={onPressHandler}
          />
          */}
          {/*
          <MenuHomeItem
            darkMode={darkMode}
            title="Rewards"
            subTitle="Manage your rewards"
            iconFamily="FontAwesome5"
            iconName="award"
            onpress={onPressHandler}
          />
          */}
          {/*
          <MenuHomeItem
            darkMode={darkMode}
            title="Coupons"
            subTitle="Manage your coupons"
            iconFamily="MaterialCommunityIcons"
            iconName="scissors-cutting"
            onpress={onPressHandler}
          />
          */}
          <MenuHomeItem
            darkMode={darkMode}
            title="How MeSearch Works"
            subTitle="Preview how MeSearch works"
            iconFamily="MaterialCommunityIcons"
            iconName="information-outline"
            onpress={onPressHandler}
          />
          <MenuHomeItem
            darkMode={darkMode}
            title="Customer Service"
            subTitle="Get help"
            iconFamily="AntDesign"
            iconName="customerservice"
            onpress={onPressHandler}
          />
        </View>
      </ScrollView>
    </View>
  );
};

export default MenuHomeWrapper;
