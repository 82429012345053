import UserRoleGroupEnum from './UserRoleGroup';

let AccessLvlByGroupEnum = {};

AccessLvlByGroupEnum[`${UserRoleGroupEnum.OWNER}`] = 5;
AccessLvlByGroupEnum[`${UserRoleGroupEnum.ADMIN_PRO}`] = 4;
AccessLvlByGroupEnum[`${UserRoleGroupEnum.ADMIN_BASIC}`] = 3;
AccessLvlByGroupEnum[`${UserRoleGroupEnum.SUBSCRIBER_PRO}`] = 2;
AccessLvlByGroupEnum[`${UserRoleGroupEnum.SUBSCRIBER_BASIC}`] = 1;
AccessLvlByGroupEnum[`${UserRoleGroupEnum.GUEST}`] = 0;

export default AccessLvlByGroupEnum;