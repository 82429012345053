import React, { useState, useEffect } from 'react';
import { SafeAreaView, View, Text, TouchableOpacity, Dimensions } from 'react-native';
// import FormField from '../components/formField';
// import FormFieldTypesEnum from './utils/FormValidation/formFieldTypesEnum';
import { CheckBox } from 'react-native-elements';
import MeSearchAPI from '../api/MeSearch-API';

const SignupChoiceScreen = ({ navigation, route }) => {
  // const [selection, setSelection] = useState('personal');
  const [isGuest, setIsGuest] = useState(true);
  const [checkedPersonal, setCheckedPersonal] = useState(true);
  const [checkedBusiness, setCheckedBusiness] = useState(false);

  const handleRadio = () => {
    const businessOn = () => {
      setCheckedBusiness(true);
      setCheckedPersonal(false);
    }

    const personalOn = () => {
      setCheckedPersonal(true);
      setCheckedBusiness(false);
    }

    checkedPersonal ? businessOn() : personalOn();
  }

  const handleSignup = () => {
    if (checkedPersonal) {
      navigation.navigate('Signup');
    } else {
      navigation.navigate('BusinessSignup');
    }
  };

  useEffect (() => {
    const unsubscribe = navigation.addListener('focus', async () => {
      try {
        const response = await MeSearchAPI.get('/api/user');
        let guest = response.data.guest ? response.data.guest : false;
        setIsGuest(guest);
      } catch (err) {
        console.log(err);
      }
    });
    return unsubscribe;
  }, []);

  return (
    <SafeAreaView style={{justifyContent: 'center', alignItems: 'center'}}>
      <View style={{width: Math.min(Dimensions.get('window').width-20, 600)}}>
        <View>
          <Text style={{ padding: 10 }}>Select the type of account you would like to create:</Text>
        </View>
        <View>
          <CheckBox 
            title="Personal"
            checked={checkedPersonal}
            checkedIcon="dot-circle-o"
            uncheckedIcon="circle-o"
            onPress={() => handleRadio()}
          />
          {!isGuest ?
          <CheckBox 
            title="Business"
            checked={checkedBusiness}
            checkedIcon="dot-circle-o"
            uncheckedIcon="circle-o"
            onPress={() => handleRadio()}
          />
          : null}
        </View>
        <View
          style={{ padding: 10, alignItems: 'center' }}
        >
          <TouchableOpacity
            onPress={() => handleSignup()}
            style={{ backgroundColor: 'rgb(64, 154, 247)', width: 200, height: 'auto', alignItems: 'center', borderRadius: 3 }}
          >
            <Text style={{color: 'white', padding: 10, fontSize: 16}}>Sign Up</Text>
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default SignupChoiceScreen;