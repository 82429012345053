const predicateSearch = (data, searchTxt, schema) => {
  if (searchTxt === "" || searchTxt === "*") {
    return data;
  } else {
    return data.filter(dataObj => {
      return Object.keys(schema).some(key => {
        if (dataObj[`${key}`]) {
          return String(dataObj[`${key}`]).toLowerCase().includes(searchTxt.toLowerCase());
        }
        return false;
      });
    });
  }
};

export default predicateSearch;