import React, { useState, useEffect } from 'react';
import  { Text, Button, Input, CheckBox } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, ActivityIndicator, FlatList, ScrollView } from 'react-native';
import Spacer from '../components/Space';
import MeSearchAPIPub from '../api/MeSearch-API-Pub';
import CopyrightFooter from '../components/CopyrightFooter';


const CreateAdvertisementScreen = ({navigation, route}) => {
  const pubOwner = route.params?.pubOwner ?? '';

  return (
    <ScrollView contentContainerStyle={Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall}>
      
    </ScrollView>
  );
};

const styles = StyleSheet.create({
    containerSmall: {
    },
    containerLarge: {
        width: Dimensions.get('window').width,
        paddingLeft: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
        paddingRight: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null
    },
    errorMessage: {
        fontSize: 18,
        color: 'red',
        marginTop: 15,
        marginLeft: 15
    },
    successMessage: {
        fontSize: 18,
        color: 'green',
        marginTop: 15,
        marginLeft: 15
    }
});

export default CreateAdvertisementScreen;