const ContentTypesEnum = {
  ARTICLE: "Article",
  ADVERTISEMENT: "Ad",
  PRODUCT: "Prod",
  EVENT: "Evnt",
  SPONSORED: "Spon",
  ARTICLELINKVIEW: "Article-Link",
  ARTICLEFRAMEVIEW: "Article-Frame",
  ADVERTISEMENTLINKVIEW: "Ad-Link",
  ADVERTISEMENTFRAMEVIEW: "Ad-Frame",
  PRODUCTLINKVIEW: "Prod-Link",
  PRODUCTFRAMEVIEW: "Prod-Frame",
  EVENTLINKVIEW: "Evnt-Link",
  EVENTFRAMEVIEW: "Evnt-Frame",
  SPONSOREDLINKVIEW: "Spon-Link",
  SPONSOREDFRAMEVIEW: "Spon-Frame",
};

export default ContentTypesEnum;