import React, {useContext} from 'react';
import { View, TextInput, StyleSheet, Image } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import {Context as PubContext} from '../context/PubContext';

const SearchBar = ({ term, onTermChange, onTermSubmit }) => {
  const {state} = useContext(PubContext);
  let placeholder = 'MeSearch';
  if (state.publisher.username === 'TribExtra-AP' || state.publisher.username === 'Sponsored Sports 2') {
    placeholder = null;
  }
  
  return (
    <View style={styles.backgroundStyle}>
     <Feather name="search" style={styles.iconstyle} onPress={() => onTermSubmit(term)}/>
      <TextInput
        autoCapitalize="none"
        autoCorrect={false}
        style={styles.inputStyle}
        placeholder={((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? placeholder : placeholder}
        value={term}
        onChangeText={onTermChange}
        onSubmitEditing={onTermSubmit}
        multiline={false}
        maxLength={120}
      />
    </View>
  );
};

const styles = StyleSheet.create({
    backgroundStyle: {
        marginTop: 10,
        backgroundColor: '#F0EEEE',
        height: 50,
        borderRadius: 5,
        marginHorizontal: 5,
        flexDirection: 'row',
        marginBottom: 10,
        flex: 1,
    },
    inputStyle: {
        flex: 1,
        fontSize: 20,
    },
    iconstyle: {
        fontSize: 35,
        marginHorizontal: 5,
        marginTop: 5
    },
});

export default SearchBar;