import React from 'react';
import { SafeAreaView, ScrollView } from 'react-native';
import TosPrivacyWrapper from '../components/wrappers/MeSearch/TosPrivacyWrapper';

const TosPrivacyScreen = ({ navigation, route }) => {
  return (
    <SafeAreaView>
      <ScrollView>
        <TosPrivacyWrapper 
          navigation={navigation}
          route={route}
        />
        </ScrollView>
    </SafeAreaView>
  );
};

export default TosPrivacyScreen;