import React, { useContext } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import MenuHomeScreen from '../../screens/MenuHomeScreen';
import { Context as PubContext } from '../../context/PubContext';
import SimpleNewsFeedScreen from '../../screens/SimpleNewsFeedScreen';

const Stack = createStackNavigator();

const menuFlow = () => {
    const  PubCon = useContext(PubContext);
    return (
        <Stack.Navigator
          initialRouteName="MenuHome"
          screenOptions={{ gestureEnabled: true }}
        >
        {(PubCon && PubCon.state && PubCon.state.publisher) ?
        <Stack.Screen
            name="MenuHome"
            component={MenuHomeScreen}
            options={{title: "Mesearch", headerShown: false}}
        />
        :
        <Stack.Screen
          name="SimpleNewsFeed"
          component={SimpleNewsFeedScreen}
          options={{title: "Mesearch", headerShown: false}}
        />
        }
        </Stack.Navigator>
    );
};

export default menuFlow;