import React, { useEffect, useContext } from 'react';
import { Context as AuthContext } from '../../../context/AuthContext';
import { Context as PubContext } from '../../../context/PubContext';
import TosPrivacy from '../../core/TosPrivacy';

const TosPrivacyWrapper = ({ navigation, route }) => {
  const { state, signup, clearErrorMessage } = useContext(AuthContext);
  const PubCon = useContext(PubContext);
  const incomingFormData = route.params?.fieldsMeta ?? '';
  const {emailAddress, password, displayName} = incomingFormData;
  const bodyText = "Thanks for joining MeSearch! To start reading, selling and shopping, please agree to our Terms of Service, Terms of Use and Privacy Policy.";
  
  useEffect (() => {
    const unsubscribe = navigation.addListener('focus', () => {
      clearErrorMessage();
    });
    return unsubscribe;
  }, []);

  const tosConfirmedUserSignup = () => {
    if (state.token) {
      signup({ username: emailAddress.value.trim(), password: password.value.trim(), displayName: displayName.value.trim(), guest: false, referrer: PubCon.state.ref });
    } else {
      signup({ username: emailAddress.value.trim(), password: password.value.trim(), displayName: displayName.value.trim(), guest: false, referrer: PubCon.state.ref, noToken: true });
    }
  };

  return (
    <>
      <TosPrivacy 
        navigation={navigation}
        route={route}
        logoUrl={require('../../../../assets/mesearch_logo_small.png')}
        descriptionText={'Personal Membership Information'}
        userSignup={tosConfirmedUserSignup}
        stateErrorMessage={state.errorMessage}
        sectionBodyText={bodyText}
        termsOfServiceAndUse={'https://mesearch.us/wp-content/uploads/2020/02/Terms-of-Services.pdf'}
        privacyPolicy={'https://mesearch.us/wp-content/uploads/2020/02/2020-01-01_Crivella-Media-Tech_Collection-and-Use-of-Personal-Information_Policy-only.pdf'}
      />
    </>
  );
};

export default TosPrivacyWrapper;