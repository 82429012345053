import React, {useState, useEffect, useContext} from 'react';
import { SafeAreaView, View, Text, Image, TouchableOpacity, FlatList, StyleSheet, Dimensions } from 'react-native';
import { HeaderBackButton } from '@react-navigation/stack';
import { Platform } from 'react-native';
import valErrorExist from '../../../screens/utils/FormValidation/utils/valErrorsExist';
import validateFormField from '../../../screens/utils/FormValidation/validateFormField';
import FormFieldTypesEnum from '../../../screens/utils/FormValidation/formFieldTypesEnum';
import MenuFormItem from '../../core/MenuFormItem';
import HeaderButton from '../../core/HeaderButton';
import BoolTermsEnum from '../../../screens/utils/FormValidation/utils/boolTermsEnum';


const BoolWrapper = ({ fieldsMeta, setFieldsMeta, validate, setEditingField, navigation, route, step, setStep, setIndex }) => {
  const mappedData = Object.entries(fieldsMeta);
  const lastStep = mappedData[mappedData.length - 1][1].step;
  const dataSet = mappedData.filter(item => {
    return item[1].step === step;
  });
  const dataSetObj = Object.fromEntries(dataSet);
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldsMetaInStep, setFieldsMetaInStep] = useState(dataSetObj);
  const [refresh, setRefresh] = useState(false);

  const extractBoolTermObject = (boolObjArr, operator) => {
    return boolObjArr
      .filter(v => (v.logic === operator && (v.value.terms && v.value.terms.length > 0)))
      .map(v => {
        return {
          terms: (v.value.terms && v.value.terms.filter(t => t !== "").length > 0) ? v.value.terms : [],
          nearTerms: (v.value.nearTerms && v.value.nearTerms.filter(t => t !== "").length > 0) ? v.value.nearTerms : [],
          excludeTerms: (v.value.excludeTerms && v.value.excludeTerms.filter(t => t !== "").length > 0) ? v.value.excludeTerms : [],
          fields: v.tags
        };
      });
  }

  const onSubmit = () => {
    setErrorMessage('');
    let boolSearch = {name: fieldsMeta.name.value, description: fieldsMeta.description.value};
    let boolTerms = {};
    let andTerms = extractBoolTermObject(fieldsMeta.boolLine.value, BoolTermsEnum.AND);
    let orTerms = extractBoolTermObject(fieldsMeta.boolLine.value, BoolTermsEnum.OR);
    let notTerms = extractBoolTermObject(fieldsMeta.boolLine.value, BoolTermsEnum.NOT);
    if (andTerms.length === 0 && orTerms.length === 0 && notTerms.length === 0) {
      setErrorMessage('Must specify at least one search criteria');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      return;
    } else {
      if (andTerms.length > 0) {
        boolTerms['And'] = andTerms;
      }
      if (orTerms.length > 0) {
        boolTerms['Or'] = orTerms;
      }
      if (notTerms.length > 0) {
        boolTerms['Not'] = notTerms;
      }
    }
    boolSearch['boolTerms'] = boolTerms;
    if (fieldsMeta.date.value !== "No Date Range") {
      let date = {};
      const datePrts = fieldsMeta.date.value.split("->");
      if (datePrts.length > 1) {
        if (datePrts[0] !== "") {
          date['greater'] = datePrts[0];
        }
        if (datePrts[1] !== "") {
          date['less']  = datePrts[1];
        }
      }
      if (Object.keys(date).length > 0) {
        boolSearch['date'] = date;
      }
    }
    navigation.navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search', params: {advancedSearch: boolSearch, advancedSearchName: fieldsMeta.name.value}}}});
  };
  
  const onNext = () => {
    setErrorMessage('');
    Object.keys(fieldsMetaInStep).forEach(fieldsMetaKey => {
      validateFormField({ setter: setFieldsMetaInStep, original: fieldsMeta, key: fieldsMetaKey, validations: validate });
    });
    if (valErrorExist(fieldsMeta, step)) {
      setErrorMessage('There seems to be missing data or errors in the form. Please correct all errors before submitting.');
    } else {
      setStep(step + 1);
    }
  };

  const onBack = () => {
    setErrorMessage('');
    if (step === 1) {
      navigation.goBack();
    } else {
      setStep(step - 1);
    }
  };

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: 'Advanced Search',
      headerTitleAlign:'center',
      headerRight: () => {
        if (step === lastStep) {
          return (
            <HeaderButton 
              title="Search"
              onPress={() => onSubmit()}
            />
          );
        } else {
          return (
            <HeaderButton 
              title="Next"
              onPress={() => onNext()}
            />
          );
        }
      },
      headerLeft: () => {
        if (step === 1) {
          return (
            null
          );
        } else {
          return (
            <HeaderButton
              title="Back"
              onPress={() => onBack()}
            />
          );
        }
      }
    });
  }, [navigation, step, fieldsMeta]);

  return (
    <SafeAreaView style={{justifyContent: 'center', alignItems: 'center', padding: 10}}>
      <View style={{width: Math.min(Dimensions.get('window').width-20, 500), justifyContent: 'center'}}>
        { errorMessage ? (
          <Text style={styles.errorMessage}>{errorMessage}</Text>
        ) : null }
        <View style={{height: Math.min(Dimensions.get('window').height-100, 500), paddingVertical: 10}}>
          <FlatList 
            data={dataSet}
            keyExtractor={item => item[0]}
            extraData={refresh}
            renderItem={({item, index}) => {
              let render = null;
              if (item[1].type === FormFieldTypesEnum.MULTIBOOL) {
                render = (
                  <MenuFormItem
                    type={item[1].type}
                    itemName={item[1].name}
                    value={item[1]['value']}
                    fieldsMeta={fieldsMeta}
                    setFieldsMeta={setFieldsMeta}
                    fieldKey={item[0]}
                    setEditingField={setEditingField}
                    setIndex={setIndex}
                    setRefresh={setRefresh}
                    logic={true}
                  />
                );
              } else {
                render = (
                  <MenuFormItem 
                    value={item[1].value}
                    itemName={item[1].name}
                    errorFlag={item[1].error ? true : false}
                    type={item[1].type}
                    onPress={() => {
                      setEditingField(`${item[0]}`);
                    }}
                  />
                )
              }

              return (
                render
              );
            }}
          />
        </View>
      
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  errorMessage: {
    fontSize: 14, 
    color: 'red', 
    paddingBottom: 10, 
    paddingLeft: 10
  },
  headerButtonText: {
    color: '#24627a',
    fontWeight: '700',
    fontSize: 16
  }
});

export default BoolWrapper;