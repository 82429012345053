import removeSpecialCharacters from '../../screens/utils/removeSpecialCharacters';

export const httpsInsert = linkText => {
  const httpsRegex = new RegExp('https?:\/\/');

  const httpsExist = httpsRegex.test(linkText);

  if (!httpsExist) {
    return `http://${linkText.trim()}`;
  } else {
    return linkText.trim();
  }
};

const bodyLinkParser = bodyText => {
        
    const hlink = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|app|arpa|biz|com|coop|dev|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|tech|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi
    const newlineRegex = new RegExp('\n', "g");
    const parsetxt = bodyText.replace(newlineRegex, "    ");
    const breakline = new RegExp('[ ]{4}', "g");
    const doubleSpace = new RegExp('([ ]{2})', "g");
    const bodySplit = removeSpecialCharacters(parsetxt)
      .replace(breakline, "\n\n")
      .replace(doubleSpace, "")
      .replace(/\n(\s+)/g, "\n\n")
      .replace(/,(?=[^\s\d])/g, ", ")
      .replace(/^\w+,\w+\s\d+,\d+\s\|\s\d+:\d+\s\w+\n/, "")
      .split("\n\n");

    let bodyLinks = [];
    bodySplit.forEach((bsplit, index) => {
      const hArr = bsplit.match(hlink);
      if (hArr) {
        let bodyLinksCur = [];
        hArr.forEach(hyperL => {
            bodyLinksCur.push({uri: httpsInsert(hyperL), displayTxt: hyperL.trim(), pos: index});
        });
        if (bodyLinksCur.length > 0) {
            bodyLinks.push(...bodyLinksCur);
        }
      }
    });
    return bodyLinks;
}

export default bodyLinkParser;