import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import PubSigninScreen from '../../screens/PubSigninScreen';
import PubSignupScreen from '../../screens/PubSignupScreen';
import PublisherHomeScreen from '../../screens/PublisherHome';
import EditPublisherAccountScreen from '../../screens/EditPublisherAccountScreen';
import CreatePublisherScreen from '../../screens/CreatePublisherScreen';
import CreateLandingPageScreen from '../../screens/CreateLandingPageScreen';
import EditLandingPageScreen from '../../screens/EditLandingScreenPage';

const Stack = createStackNavigator();

const pubOwnerFlow = () => {
    return (
        <Stack.Navigator
        initialRouteName="PubSignin"
        screenOptions={{ 
            gestureEnabled: true,
            title: "Admin Panel"
        }}
        >
        <Stack.Screen
            name="PubSignin"
            component={PubSigninScreen}
            options={{headerShown: false}}
        />
        <Stack.Screen
            name="PubSignup"
            component={PubSignupScreen}
            options={{headerShown: false}}
        />
        <Stack.Screen
            name="PublisherHome"
            component={PublisherHomeScreen}
            options={{headerShown: false}}
        />
        <Stack.Screen
            name="CreatePublication"
            component={CreatePublisherScreen}
        />
        <Stack.Screen
            name="PublisherEdit"
            component={EditPublisherAccountScreen}
        />
        <Stack.Screen
            name="CreateLandingPage-DEPRECATED"
            component={CreateLandingPageScreen}
        />
        <Stack.Screen
            name="EditLandingPage"
            component={EditLandingPageScreen}
        />
        </Stack.Navigator>
    );
};

export default pubOwnerFlow;