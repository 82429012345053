import React, {useEffect, useState} from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Dimensions } from 'react-native';
import { Platform } from 'react-native';
import { Input } from 'react-native-elements';
import HeaderButton from './HeaderButton';
import validateFormField from '../../screens/utils/FormValidation/validateFormField';
import { isMobile } from 'react-device-detect';


// render the correct type of form detail based on received inputType and pass back the value into a wrapper's callback
const FormDetail = ({ 
  navigation, 
  inputType, 
  inputFieldStyling,
  label,
  labelProps,
  errorMessage,
  errorProps,
  viewContainerStyle, 
  placeholder,
  onChangeText,
  value,
  inputStyle, 
  containerStyle,
  inputContainerStyle, 
  labelStyle,
  errorStyle,
  editingField,
  setEditingField,
  fieldsMeta,
  setFieldsMeta,
  validate,
}) => {
  let render = null;
  
  const updateFieldsMeta = (key, value) => {
    let newFieldsMeta = JSON.parse(JSON.stringify(fieldsMeta));
    newFieldsMeta[key] = value;
    setFieldsMeta(newFieldsMeta);
  };

  const onSubmit = () => {
    // validate, and if good, then clear editingField (allowing user to go back to main form). Otherwise show error.
    validateFormField({ setter: setFieldsMeta, original: fieldsMeta, key: editingField, validations: validate });
    if (!fieldsMeta[editingField].error) {
      setEditingField('');
    }
  };
  
  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: 'Edit ' + fieldsMeta[editingField].name,
      headerTitleAlign:'center',
      headerRight: () => (
        <HeaderButton 
          title="Add"
          onPress={() => onSubmit()}
        />
      ),
      headerLeft: () => (
        <HeaderButton 
          title="Cancel"
          onPress={() => setEditingField('')}
        />
      ),
    });
  }, [navigation]);

  let ifFieldMeta = (
    <View style={{width: (Platform.OS === 'web' && !isMobile) ? 700 : '90%', paddingTop: 10}}>
      <Input 
        value={value}
        containerStyle={containerStyle ? containerStyle : {}}
        inputContainerStyle={styles.inputContainer}
        inputStyle={inputStyle ? inputStyle : {}}
        label={label}
        labelStyle={labelStyle ? labelStyle : {}}
        labelProps={labelProps}
        errorMessage={fieldsMeta ? fieldsMeta[editingField].error : errorMessage}
        errorStyle={errorStyle ? errorStyle : {}}
        errorProps={errorProps}
        placeholder={fieldsMeta[editingField].placeholder}
        onSubmitEditing={() => setEditingField('')}
        onChangeText={(text) => {
          let newFieldObj = fieldsMeta[`${editingField}`];
          newFieldObj.value = editingField.toLowerCase().includes("email") ? text.toLowerCase() : text;
          updateFieldsMeta(editingField, newFieldObj);
        }}
      />
    </View>
  );

  let regularInput = (
    <View style={{width: (Platform.OS === 'web' && !isMobile) ? 700 : '90%', paddingTop: 10}}>
      <Input 
        value={value}
        containerStyle={containerStyle ? containerStyle : {}}
        inputContainerStyle={inputContainerStyle ?? styles.inputContainer}
        inputStyle={inputStyle ? inputStyle : {}}
        label={label}
        labelStyle={labelStyle ? labelStyle : {}}
        labelProps={labelProps}
        errorMessage={errorMessage}
        errorStyle={errorStyle ? errorStyle : {}}
        errorProps={errorProps}
        placeholder={placeholder}
        onChangeText={onChangeText}
      />
    </View>
  );

  switch (inputType) {
    case "input":
      render = fieldsMeta ? ifFieldMeta : regularInput;
      break;

    case "imagePicker":
      // imagepicker logic
      break;

    case "select":
      // selection options
      break;

    case "radio":
      // radio options
      break;

    case "passwordField":
      // password field
      render = (
        <View style={{width: (Platform.OS === 'web' && !isMobile) ? 700 : '90%', paddingTop: 10}}>
          <Input 
            value={value}
            containerStyle={containerStyle ? containerStyle : {}}
            inputContainerStyle={inputContainerStyle ?? styles.inputContainer}
            inputStyle={inputStyle ? inputStyle : {}}
            label={label}
            labelStyle={labelStyle ? labelStyle : {}}
            labelProps={labelProps}
            errorMessage={fieldsMeta ? fieldsMeta[editingField].error : errorMessage}
            errorStyle={errorStyle ? errorStyle : {}}
            errorProps={errorProps}
            placeholder={fieldsMeta[editingField].placeholder}
            onSubmitEditing={() => setEditingField('')}
            onChangeText={(text) => {
              let newFieldObj = fieldsMeta[`${editingField}`];
              newFieldObj.value = editingField.toLowerCase().includes("email") ? text.toLowerCase() : text;
              updateFieldsMeta(editingField, newFieldObj);
            }}
            secureTextEntry={true}
          />
        </View>
      );
      break;

    case "phoneField":
      render = fieldsMeta ? ifFieldMeta : regularInput;
      break;
  
    default:
      render = null;
      break;
  }

  return render;
};

const styles = StyleSheet.create({
  inputContainer: {
    // width: Platform.OS === 'web' ? 700 : Dimensions.get("window") - 30,
  },
});

export default FormDetail;