import React, { useContext, useState } from 'react';
import { View, StyleSheet, Dimensions, Image, Text, ScrollView } from 'react-native';
import AuthFormWrapper from '../components/wrappers/MeSearch/AuthForm';

const SigninScreen = ({navigation, route}) => {
    return (
      <ScrollView>
        <AuthFormWrapper
          navigation={navigation}
          route={route}
        />
      </ScrollView>
    );
};

export default SigninScreen;