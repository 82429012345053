import React from 'react';
import { View, StyleSheet } from 'react-native';

const Spacer = ({ children, margin }) => {

  const styles = StyleSheet.create({
    spacer: {
      margin: margin ? margin : 15
    }
  });

  return <View style={styles.spacer}>{children}</View>
};

export default Spacer;