import React from 'react';
import { View, TouchableOpacity, StyleSheet, TextInput, Dimensions, Text } from 'react-native';
import { MaterialIcons, AntDesign } from '@expo/vector-icons';
import FormFieldTypesEnum from '../../screens/utils/FormValidation/formFieldTypesEnum';
import MultiLineFormItem from './MultiLineFormItem';

const MenuFormItem = ({navigation=null, value, itemName, navigateTo, errorFlag, onPress, type, logic, fieldKey, setEditingField, fieldsMeta, setFieldsMeta, setIndex, setRefresh}) => {

  let render = null;

  if (type === FormFieldTypesEnum.MULTI || type === FormFieldTypesEnum.MULTIURL || type === FormFieldTypesEnum.MULTIBOOL) {
    render = (
      <MultiLineFormItem 
        itemName={itemName}
        value={value}
        logic={logic}
        fieldsMeta={fieldsMeta}
        fieldKey={fieldKey}
        setEditingField={setEditingField}
        setFieldsMeta={setFieldsMeta}
        setIndex={setIndex}
        setRefresh={setRefresh}
      />
    )
  } else {
    const extractValue = (value) => {
      const entries = Object.entries(value).filter(([key, value]) => value);
      const result = entries.map(item => item[0]);
      return result[0];
    };

    render = (
      <TouchableOpacity
        onPress={() => onPress()}
      >
        <View style={styles.container}>
          <View>
            <View>
              <Text style={styles.headerText}>{itemName}</Text>
            </View>
            <View>
              <TextInput 
                style={{width: Math.min(Dimensions.get('window').width-100, 200)}}
                editable={false}
                value={type === FormFieldTypesEnum.RADIO ? 
                        extractValue(value) 
                        : (type === FormFieldTypesEnum.TOGGLE || type === FormFieldTypesEnum.CHECKBOX) ? 
                        `${Object.keys(value).filter(v => value[`${v}`]).toString()}`
                      : type === FormFieldTypesEnum.PERIODICITY ?
                        Object.keys(value.interval).find(i => value.interval[`${i}`])
                      : value
                      }
                secureTextEntry={type === FormFieldTypesEnum.PASSWORD ? true : false}
              />
            </View>
          </View>
          <View style={{justifyContent: 'center'}}>
            {
              errorFlag ? (
                <AntDesign name="exclamationcircleo" size={24} color="red" />
              ) : (
                <MaterialIcons name="keyboard-arrow-right" size={24} />
              )
            }
          </View>
        </View>
      </TouchableOpacity>
    );
  }

  return (
    <>
      { 
        navigation ? (
          <TouchableOpacity
            onPress={() => navigation.navigate(navigateTo)}
          >
            <View style={styles.container}>
              <View>
                <View>
                  <Text style={styles.headerText}>{itemName}</Text>
                </View>
                <View>
                  <TextInput 
                    style={{width: Math.min(Dimensions.get('window').width-100, 200)}}
                    editable={false}
                    value={value}
                  />
                </View>
              </View>
              <View style={{justifyContent: 'center'}}>
                {
                  errorFlag ? (
                    <AntDesign name="exclamationcircleo" size={24} color="red" />
                  ) : (
                    <MaterialIcons name="keyboard-arrow-right" size={24} />
                  )
                }
              </View>
            </View>
          </TouchableOpacity>
        ) : (
          render
        )
      }
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row', 
    margin: 10, 
    padding: 10, 
    justifyContent: 'space-between', 
    borderBottomWidth: 1, 
    borderBottomColor: 'rgb(224, 224, 224)'
  },
  errorMessage: {
    color: 'red', 
    margin: 5, 
  },
  headerText: {
    fontSize: 16, 
    fontWeight: 'bold'
  }
});

export default MenuFormItem;