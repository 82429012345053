import React, {useState, useEffect} from 'react';
import { View, TouchableOpacity, StyleSheet, TextInput, Text, FlatList, Platform } from 'react-native';
import { MaterialIcons, Feather } from '@expo/vector-icons';
import LogicalTypes from '../../screens/utils/logicalTypesEnum';
import { Dimensions } from 'react-native';
import BoolTermsEnum from '../../screens/utils/FormValidation/utils/boolTermsEnum';
import uuidv4 from 'uuid/v4';
import { isMobile } from 'react-device-detect';

const NewLine = ({ value, fieldsMeta, setFieldsMeta, onRemove, fieldKey, setEditingField, index, setIndex, logic, tags, firstLine=false }) => {
  const onPress = () => {
    setIndex(index);
    setEditingField(fieldKey);
  };

  return (
    <>
      {
        firstLine ? (
          <>
            <TouchableOpacity
              onPress={() => onPress()}
              style={{ width: '100%', height: 'auto' }}
            >
              <View style={styles.textArea}>
                {logic ?
                  <View>
                    <Text style={[styles.textField, {width: 80, backgroundColor: '#dedede', borderBottomColor: '#dedede', borderRadius: 5, textAlign: 'center'}]}>{logic}</Text>
                  </View>
                : null}
                  <View style={styles.fieldContainer}>
                    <View style={styles.field}>
                      {logic ?
                        <Text style={styles.textField}>{(value.terms && value.terms.length > 0) 
                          ? value.terms.toString().length > 20
                            ? value.terms.toString().substring(0, 18) +  "..."
                            : value.terms.toString()
                          : "Search Criteria"}
                        </Text>
                      : <TextInput value={value} editable={false} style={styles.textField} />}
                    </View>
                    {tags?
                      <View>
                        <Text style={[styles.textField, {width: 80, backgroundColor: '#dedede', borderBottomColor: '#dedede', borderRadius: 5, textAlign: 'center'}]}>{tags}</Text>
                      </View>
                    : null}
                    <View style={styles.rightIcon}>
                      <MaterialIcons name="keyboard-arrow-right" size={24} />
                    </View>
                  </View>
              </View>
            </TouchableOpacity>
          </>
        ) : (
          <>
            <View style={styles.textArea}>
              {logic ?
                <TouchableOpacity
                  onPress={() => onPress()}
                >
                  <Text style={[styles.textField, {width: 80, backgroundColor: '#dedede', borderBottomColor: '#dedede', borderRadius: 5, textAlign: 'center'}]}>{logic}</Text>
                </TouchableOpacity>
              : null}
              <TouchableOpacity
                onPress={() => onPress()}
              >
                <View style={styles.fieldContainer}>
                  <View style={styles.field}>
                  {logic ?
                    <Text style={styles.textField}>{(value.terms && value.terms.length > 0) 
                      ? value.terms.toString().length > 20
                      ? value.terms.toString().substring(0,  18) +  "..."
                        : value.terms.toString()
                      : "Search Criteria"}
                    </Text>
                  : <TextInput value={value} editable={false} style={styles.textField} />}
                  </View>
                  {tags?
                      <View>
                        <Text style={[styles.textField, {width: 80, backgroundColor: '#dedede', borderBottomColor: '#dedede', borderRadius: 5, textAlign: 'center'}]}>{tags}</Text>
                      </View>
                  : null}
                  <View style={styles.rightIcon}>
                    <MaterialIcons name="keyboard-arrow-right" size={24} />
                  </View>
                </View>
              </TouchableOpacity>
              <View style={styles.removeIcon}>
                <TouchableOpacity
                  onPress={() => onRemove(index)}
                  style={{justifyContent: 'center'}}
                >
                  <Feather name="x" size={22} />
                </TouchableOpacity>
              </View>
            </View>
          </>
        )
      }
    </>
  );
};

const MultiLineFormItem = ({ itemName=null, onPressFirstLine, value, setEditingField, fieldsMeta, setFieldsMeta, fieldKey, setIndex, logic }) => {
  // initial value should look like: [{value: "", logicOperator: ""}]. Subsequent additions should be similar shaped objects inserted into the array.
  // const [numOfExtraLines, setNumOfExtraLines] = useState([]);
  const [refresh, setRefresh] = useState(uuidv4());
  const onAdd = () => {
    if (logic) {
      setFieldsMeta(fieldsMeta, fieldsMeta[fieldKey].value.push({ value: {}, logic: BoolTermsEnum.AND, tags: 'All Tags' }));
    } else {
      setFieldsMeta(fieldsMeta, fieldsMeta[fieldKey].value.push({ value: '' }));
    }
    setRefresh(uuidv4());
  };

  const onRemove = (index) => {
    fieldsMeta[fieldKey].value.splice(index, 1);
    setFieldsMeta(fieldsMeta, fieldsMeta[fieldKey].value);
    setRefresh(uuidv4());
  };

  return (
    <>
      <View style={styles.container}>
        {
          itemName ? (
            <View>
              <Text style={styles.headerText}>{itemName}</Text>
            </View>
          ) : null
        }
        <View style={styles.addButton}>
          <TouchableOpacity
            onPress={() => onAdd()}
            style={{flexDirection: 'row', alignItems: 'center'}}
          >
            <MaterialIcons name="add-circle-outline" size={24} color={'rgb(104, 162, 208)'}/>
            <Text style={{color: 'rgb(104, 162, 208)', paddingLeft: 5}}>Add</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.extraLines}>
          <View style={styles.list}>
            <FlatList
              data={value}
              keyExtractor={(item, index) => `${index}`}
              extraData={refresh}
              renderItem={({item, index}) => {
                if (index === 0) {
                  return (
                    <NewLine 
                      value={item.value} 
                      setEditingField={setEditingField} 
                      index={index} 
                      setIndex={setIndex}
                      fieldKey={fieldKey}
                      firstLine={true} 
                      logic={item.logic}
                      tags={item.tags}
                    />
                  )
                } else {
                  return (
                    <NewLine 
                      value={item.value} 
                      setEditingField={setEditingField} 
                      index={index} 
                      setIndex={setIndex} 
                      fieldKey={fieldKey}
                      onRemove={onRemove}
                      logic={item.logic}
                      tags={item.tags}
                    />
                  )
                }
              }}
            />
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column', 
    margin: 10, 
    padding: 10,
    //borderBottomWidth: 1, 
    //borderBottomColor: 'rgb(224, 224, 224)',
  },
  list: {
    width: '100%'
  },
  extraLines: {
    flexDirection: 'row',
  },
  headerText: {
    fontSize: 16, 
    fontWeight: 'bold'
  },
  fieldContainer: {
    flexDirection: 'row',
    paddingBottom: 5,
    paddingTop: 2,
    width: '100%',
    alignItems: 'center',
  },
  textArea: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textField: {
    borderBottomWidth: 0.8,
    borderBottomColor: 'black',
    width: (Platform.OS === 'web' && !isMobile) ? Math.min(Dimensions.get('window').width-100, 280) : (Math.min(Dimensions.get('window').width/2.8)),
    padding: 4,
    fontSize: 16
  },
  addButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightIcon: {
    // justifyContent: 'flex-end'
    // alignItems: 'center'
  },
  removeIcon: {
    alignItems: 'center',
    paddingBottom: 3
  }
});

export default MultiLineFormItem;