import React, { useState, useEffect } from 'react';
import  { Button } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, Text, FlatList, ActivityIndicator, Platform, TouchableOpacity } from 'react-native';
import Spacer from '../components/Space';
import MeSearchAPI from '../api/MeSearch-API';
import useResultsv2 from '../hooks/useResultsv2';
import NewsFeed from '../components/wrappers/MeSearch/GridRow';
import SingleFeedResult from '../components/core/SingleFeedResult';
import { updateUsr } from '../screens/utils/resultClkUtils';

const SimpleNewsFeed = ({ navigation, route }) => {

  const [getResults, results, isLoading, error, getResultsKioskApi, resultsKiosk] = useResultsv2();
  const [page, setPage] = useState(0);

  useEffect (() => {
    getResultsKioskApi({currentFeed: resultsKiosk, page: page, account: "Spillwine Publishing"});
  }, []);


  const resultClick = async (item) => {
    updateUsr(item, {state: {publisher: {blacklistTags: ["a"], username: "Spillwine Publishing"}}});
    Platform.OS === 'web' ? window.open(item.body[0].link) : this.props.navigation.navigate('ResultsShow', { content: item, darkMode: false, frameView: true  });
  }
    
  return (
    <SafeAreaView style={{height: Dimensions.get('window').height-50, justifyContent: 'center', alignItems: 'center'}}>
      {(resultsKiosk && resultsKiosk.length > 0) ?
      <FlatList
        style={{width: Dimensions.get('window').width-10}}
        contentContainerStyle={{justifyContent: 'center', alignItems: 'center'}}
        horizontal={false}
        data={resultsKiosk}
        keyExtractor={(i) => `${i._id}`}
        onEndReachedThreshold={5}
        onEndReached={() => {
          setPage(page+1);
          getResultsKioskApi({currentFeed: resultsKiosk, page: page+1, account: "Spillwine Publishing"});
        }}
        renderItem={({ item, index }) => {
          return (
            <TouchableOpacity 
              style={{width: 350}}
              onPress={() => {
                resultClick(item);
              }}
            >
              <SingleFeedResult 
                result={item}
                horizontal={false}
                darkMode={false}
                index={index}
                defImage={false}
              />
            </TouchableOpacity>
          );
        }}
      />
      : <ActivityIndicator size="large" color={"#005157"}/>}
    </SafeAreaView>
  );

};

const styles = StyleSheet.create({});

export default SimpleNewsFeed;