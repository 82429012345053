import React, {useState} from 'react';
import { StyleSheet } from 'react-native';
import { Platform } from 'react-native';
import { View, Text, TouchableOpacity, Linking, Image } from 'react-native';
import { CheckBox } from 'react-native-elements';
import { isMobile } from 'react-device-detect';
import Spacer from '../Space';

const TosPrivacy = ({navigation, logoUrl, descriptionText, sectionBodyText, termsOfServiceAndUse, privacyPolicy, userSignup, stateErrorMessage}) => {
  const [isAccepted, setIsAccepted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onChecked = () => {
    isAccepted ? setIsAccepted(false) : setIsAccepted(true);
  };

  const submitUserSignup = () => {
    setErrorMessage('');
    if (!isAccepted) {
      setErrorMessage('You must accept the Terms of Service and Use, and our Privacy Policy to create an account.');
    } else {
      userSignup();
    }
  };

  return (
    <View style={{paddingHorizontal: ((Platform.OS === 'web' && !isMobile) ? '30%' : 20)}}>
      <View style={{justifyContent: 'center', alignItems: 'center', paddingBottom: 20, paddingTop: 20}}>
        <Image style={{width: 140, height: 50, resizeMode: 'contain', }} source={logoUrl} />
      </View>
      <Text style={{fontWeight:'bold', fontSize: 20, paddingBottom: 10}}>Let's Get Started</Text>
      <Text style={{fontSize: 20, paddingBottom: 25 }}>{descriptionText}</Text>
      
      <View style={styles.section}>
        <Text style={styles.sectionHeader}>Please review our terms</Text>
        <Text style={styles.sectionBodyText}>
          {sectionBodyText}
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={{fontSize: 14,fontWeight:"400", color: 'blue' }} onPress={ () => (Platform.OS === 'web' && !isMobile) ? window.open(termsOfServiceAndUse) : Linking.openURL(termsOfServiceAndUse) }>
          Terms of Service and Use
        </Text>
        <Text style={{fontSize: 14, fontWeight:"400", color: 'blue' }} onPress={ () => (Platform.OS === 'web' && !isMobile) ? window.open(privacyPolicy) : Linking.openURL(privacyPolicy) }>
          Privacy Policy
        </Text>
      </View>

      <View style={styles.checkboxSection}>
        <CheckBox 
          title="I accept the Terms of Service and Privacy Policy"
          onPress={() => onChecked()}
          checked={isAccepted ? true : false}
        />
      </View>

      <View style={styles.buttonGroup}>
        <TouchableOpacity
          onPress={() => submitUserSignup()}
          style={[styles.button, styles.acceptButton]}
        >
          <Text style={styles.buttonText}>Create Your Personal Account</Text>
        </TouchableOpacity>
        <Spacer margin={5}/>
        <TouchableOpacity
          onPress={() => navigation.navigate('Signup')}
          style={[styles.button, styles.declineButton]}
        >
          <Text style={styles.buttonText}>Decline</Text>
        </TouchableOpacity>
      </View>
      {stateErrorMessage ? <Text style={styles.error}>{(stateErrorMessage === 'Failure to update') ? 'Username already taken' : stateErrorMessage}</Text> : null}
      {errorMessage ? <Text style={styles.error}>{errorMessage}</Text> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  sectionHeader: {
    fontWeight: '700',
    fontSize: 16,
    paddingBottom: 20
  },
  section: {
    paddingBottom: 15,
  },
  checkboxSection: {
    width: 350,
    alignItems:'center',
    justifyContent: 'center',
    padding: (Platform.OS === 'web' && !isMobile) ?  null : 10,
    marginLeft: (Platform.OS === 'web' && !isMobile) ? -10 : null
  },
  buttonGroup: {
    alignItems: 'center',
  },
  button: {
    height: 40,
    minWidth: 320,
    width: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
  }, 
  acceptButton: {
    backgroundColor: 'rgb(0, 156, 248)'
  },
  declineButton: {
    backgroundColor: 'rgb(132, 29, 40)'
  },
  buttonText: {
    color: 'white'
  },
  error: {
    color: 'red'
  },
});

export default TosPrivacy;