class CallBackQueue {

  constructor(autoIterate=false, queue=[]) {
    this.queue = queue;
    this._version = 0;
    this.autoIterate = autoIterate;
    this.queueCount = queue.length;
    this.queueStarted = false;
    this.queueEnded = false;
  }

  startQueue() {
    if (!this.queueStarted && this.queueCount > 0) {
      this.queueStarted = true;
      this.queueEnded = false;
      this._executeCurrentElement();
    }
  }

  _checkVersionLock(version) {
    return this._version === version;
  }

  _incrementVersion() {
    this._version += 1;
  }

  _executeCurrentElement(paramsUpdate) {
    this.queue[0].processing = true;
    if (paramsUpdate) {
      paramsUpdate.forEach(param => {
        this.queue[0].params[param.indx] = param.value;
      });
    };
    this.queue[0].callback(...this.queue[0].params);
    this._incrementVersion();
    if (this.autoIterate) {
      this.queueNext();
    }
  }

  _updateQueueCount() {
    this.queueCount = this.queue.length;
  }

  addToQueue(callback, params, key) {
    // Check if key already exists
    if(!this.queue.some(element => element.key === key)){
      this.queue.push({ callback, processing: false, params, key});
      this._incrementVersion();
      this._updateQueueCount();
    }
  }

  removeLastQueueElement() {
    this.queue.splice(this.queue.length-1, 1);
    this._incrementVersion();
    this._updateQueueCount();
  }

  removeCurrentQueueElement() {
    this.queue.splice(0, 1);
    this._incrementVersion();
    this._updateQueueCount();
  };

  randomlyShuffleTheQueue() {
    // Fisher Yates Shuffle
    // https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
    let objsToShuffle = this.queue.length, tmp, remObj;
    // While objects remain that need shuffled
    while (objsToShuffle) {
  
      // Randomly select a remaining obj
      remObj = Math.floor(Math.random() * objsToShuffle--);
  
      // Swap with the current object
      tmp = this.queue[objsToShuffle];
      this.queue[objsToShuffle] = this.queue[remObj];
      this.queue[remObj] = tmp;
    }
    this._incrementVersion();
  }

  reverseQueue() {
    this.queue = this.queue.reverse();
  }

  async _executeCurrentElementAndAwait() {
    this._incrementVersion();
    this.queue[0].processing = true;
    if (paramsUpdate) {
      paramsUpdate.forEach(param => {
        this.queue[0].params[param.indx] = param.value;
      });
    };
    await this.queue[0].callback(this.queue[0].params);
    if (this.autoIterate) {
      this.queueNextAndAwait();
    }
  }

  getQueueCount() {
    return this.queueCount;
  }

  isQueueEnded() {
    return this.queueEnded;
  }

  queueNext(paramsUpdate) {
    if (this.queueCount > 1) {
      this.removeCurrentQueueElement();
      this._executeCurrentElement(paramsUpdate);
    } else {
      this.queueEnded = true;
    }
  }

  async queueNextAndAwait(paramsUpdate) {
    if (this.queueCount > 1) {
    this.removeCurrentQueueElement();
      this._executeCurrentElementAndAwait(paramsUpdate);
    } else {
      this.queueEnded = true;
    }
  }

}

export default CallBackQueue;