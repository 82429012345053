import React, { useContext, useState, useEffect } from 'react';
import  { Text, Button, Input } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView } from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import { Platform } from 'react-native';
import { Context as PubContext } from '../context/PubContext';
import MeSearchAPI from '../api/MeSearch-API';
import { yupResolver } from '@hookform/resolvers';
import { object, string } from 'yup';
import DismissKeyboard from '../components/DismissKeyboard';

const schema = object().shape({
  subject: string().required(),
  message: string().required(),
  email: string().email().required(),
})

const UserMessageScreen = ({ navigation }) => {
  const { state } = useContext(PubContext);
  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });
  const [bodyId, setBodyId] = useState('');
  const [userId, setUserId] = useState('');
  const [isGuest, setIsGuest] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  const updateUser = async (email) => {
    const data = {
      payload: {contactEmail: email}
    };

    await MeSearchAPI.patch('/api/user', data);
  };

  const onSubmit = async data => {
    const { subject, message, email } = data;

    if (email || !isGuest) {
      const postData = {
        userId,
        subject,
        message,
        body_id: bodyId,
        referrer: state.ref,
      };
  
      if (!userId) {
        setError(`There was an error submitting the form with userId: ${userId}`);
        return;
      }
  
      if (!bodyId) {
        setError(`There was an error submitting the form with bodyId: ${bodyId}`);
        return;
      }
  
      if (isGuest) {
        try {
          await updateUser(email);
        } catch (error) {
          setError(`${error}`);
          return;
        }
      }
  
      try {
        await MeSearchAPI.post('/api/user/send-user-message', postData);
        setSuccessMessage('Message sent!');
      } catch (error) {
        setError(`${error}`);
      }
    }
  }

  useEffect(() => {
    if (Platform.OS === 'web') {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      const urlBodyId = params.get('bId');
      const urlUserId = params.get('s');
      setBodyId(urlBodyId);
      setUserId(urlUserId);
    }

    const isUserGuest = async () => {
      try {
        const { data: userData } = await MeSearchAPI.get('/api/user');
        setIsGuest(userData.guest);
      } catch (error) {
        setError(`${error}`);
      }
    }

    isUserGuest();
  }, []);

  return (
    <SafeAreaView style={ Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall }>
      <View style={ styles.logo }>
        <Image style={ styles.image } source={ require('../../assets/logo-mesearch.png') }/>
      </View>
      <DismissKeyboard>
        <View style={ Platform.OS !== 'web' ? styles.mobileForm : styles.form }>
          {
            isGuest ? (
              <>
                <Controller
                  name="email"
                  control={ control }
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <Input
                      label="Your Email Address"
                      labelStyle={styles.labelStyle}
                      placeholder="e.g. michael.scott@dundermifflin.com"
                      value={ value }
                      onChangeText={value => onChange(value)}
                      containerStyle={ styles.inputContainer }
                      inputStyle={ styles.inputField }
                      errorMessage={ errors.email?.message }
                    />
                  )}
                />
              </>
            ) : null
          }
          <Controller 
            name="subject"
            control={ control }
            defaultValue=""
            render={({ onChange, value }) => (
              <Input
                label="Subject"
                labelStyle={styles.labelStyle}
                placeholder="What is this about?"
                value={ value }
                onChangeText={value => onChange(value)}
                containerStyle={ styles.inputContainer }
                inputStyle={ styles.inputField }
                errorMessage={ errors.subject?.message }
              />
            )}
          />
          <Controller 
            name="message"
            control={ control }
            defaultValue=""
            render={({ onChange, value }) => (
              <Input
                label="Message"
                labelStyle={styles.labelStyle}
                placeholder="What would you like to say?"
                value={ value }
                onChangeText={value => onChange(value)}
                containerStyle={ styles.inputContainer }
                inputStyle={ styles.inputField }
                multiline={ true }
                numberOfLines={ 7 }
                errorMessage={ errors.message?.message }
              />
            )}
          />
          <Button 
            containerStyle={ styles.buttonContainer } 
            buttonStyle={ styles.submitButton } 
            title="Send Message" 
            onPress={ handleSubmit(onSubmit) } 
          />
          {successMessage !== "" ? <Text style={styles.successMessage}>{successMessage}</Text> : null}
          {error ? <Text style={styles.errorMessage}>{error}</Text> : null}
        </View>
      </DismissKeyboard>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  successMessage: {
    fontSize: 18,
    color: 'green',
    marginTop: 15,
    marginLeft: 15
  },
  inputContainer: {
    marginBottom: 20,
  },
  labelStyle: {
    paddingLeft: 5,
    paddingBottom: 5,
  },
  inputField: {
    padding: 5,
  },
  logo: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    alignItems: 'center',
    width: '30%',
    marginVertical: 15
  },
  mobileForm: {
    alignItems: 'center',
    width: Dimensions.get('window').width,
  },
  errorMessage: {
    fontSize: 16,
    color: 'red',
    marginBottom: 15,
  },
  image: {
    width: 200,
    height: 80,
    margin: 10,
  },
  containerSmall: {
    flex: 1,
    justifyContent: 'center',
    marginBottom: 150,
  },
  containerLarge: {
    width: Dimensions.get('window').width,
    paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
    paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 150
  },
  submitButton: {
    backgroundColor: 'teal',
    padding: 10,
    alignItems: 'center'
  },
  buttonContainer: {
    minWidth: 30
  }
});

export default UserMessageScreen;