import React, { createRef } from 'react';
import { Button } from 'react-native-elements';
import { View, Text, StyleSheet, FlatList, ImageBackground, Dimensions } from 'react-native';
import AdUnit from '../AdUnit';
import { Platform } from 'react-native';
import { isMobile } from 'react-device-detect';
import getAllIndexes from '../../screens/utils/fetchIndexes';
import ContentTypesEnum from '../utils/contentTypes';
import ARTICLEROWSPERPAGE from '../../screens/utils/articlesPerPage';
import PageNav from '../PageNav';
import AdTypesEnum from '../utils/AdUnitTypes';
import TagFilter from './TagFilter';
import uuidv4 from 'uuid/v4';


class GridPage extends React.Component  {

  constructor(props) {
    super(props);
    this.sHash = "hash"
    this.state = {
      viewableRows: [], 
      offset: 0, 
      searchHash: 'hash', 
      banner1AdUnit: {adUnit:"", slotID:""}, 
      banner2AdUnit: {adUnit:"", slotID:""}, 
      banner3AdUnit: {adUnit:"", slotID:""}, 
      horizontal: true, 
      curTag: "",
    };
    this.resultHash =  {_v: 0};
    this.flatlist = createRef();
    this.tagHash = {};
    this.handleOnViewableItemsChanged = this.handleOnViewableItemsChanged.bind(this);
    this.styles = StyleSheet.create({
        imageHeader: {
            width: (Platform.OS === 'web' && !isMobile) ? 750 : 300,
            height: (Platform.OS === 'web' && !isMobile) ? 200 : 90,
            marginLeft: 10,
            marginRight: 10,
            marginRight: (Platform.OS === 'web' && !isMobile) ? 120 : 40
        },
    });
    this.VIEWABILITY_CONFIG = {
        minimumViewTime: 300,
        viewAreaCoveragePercentThreshold: 70,
        waitForInteraction: false,
    };
  };


  containsOtherTags = (content, tags, curTag) => {
      return tags.some((tag) => { 
          if (tag !== curTag && !this.tagHash[tag]) {
          if (content.meTags.includes(tag)) {
              return true;
          }
          }
          return false;
      });
  }

  setCurTag = (tag) => {
    this.setState({curTag: tag});
  }

  setHorizontal = (bool) => {
    this.setState({horizontal: bool});
  }

  getUniqueContent = (content) => {
    const uniqueContent = content
          .map(e => e['_id'])
  
        // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
  
      // eliminate the dead keys & store unique objects
      .filter(e => content[e]).map(e => content[e]);
  
      return uniqueContent;
  }

  shuffleToPositions = (indexes, content, types) => {
    if (indexes.length > 1) {
        let tmp = JSON.parse(JSON.stringify(content[2]));
        content[2] = content[indexes[0]];
        content[indexes[0]] = tmp;
        let tmpTwo = JSON.parse(JSON.stringify(content[3]));
        content[3] = content[indexes[1]];
        content[indexes[1]] = tmpTwo;
        if (indexes.length > 2) {
            const nIndexes = getAllIndexes(content, types);
            // Reverse iterate to not mess up the yet to be removed indexes
            nIndexes.slice(2, indexes.length-1).reverse().forEach(indx => {
              content.splice(indx, 1);
            });
        }
    } else if (indexes.length === 1) {
      let tmp = JSON.parse(JSON.stringify(content[2]));
      content[2] = content[indexes[0]];
      content[indexes[0]] = tmp;
    }
    return content;
  };

  filterResultsByTag = (Tag) => {
    let resultsToFilter = this.props.results;
    if (Tag === 'Offers') {
      return this.props.results.filter((resObj) => {
        return resObj.meTags.includes("Offers");
      });
    } else {
      resultsToFilter = this.props.results.filter((resObj) => {
        return !resObj.meTags.includes("Offers");
      });
    }
    let tagCount = 0;
    let nativePublisherCon = 0;
    let filteredResults = resultsToFilter.filter(result => {
        let containsTag = false;
        Object(result.meTags).forEach(tag => {
            if (Tag === tag && (this.props.free || !this.props.blacklistTags || !this.props.blacklistTags.includes(tag)) &&
            (this.props.free || tag !== this.props.mandatoryTag) && tag !== 'Headline'
            && (this.props.free || !this.props.latestResults.map(c => c.body_id === result.body_id).some((obj) => {return obj}) || this.props.searchScreenPage > 0) 
            && (!this.props.userSpecifiedResults.map(c => c.body_id === result.body_id).some((obj) => {return obj}) || this.props.searchScreenPage > 0)) {
                // Check if row has met its quota
                if (tagCount < Math.ceil(this.props.results.length/Math.min(this.props.resultsTags.length, 5)) || !this.containsOtherTags(result, this.props.curPageTags, Tag)) {
                    // Update hash
                    const hRes = this.saveResultHash(result, tag);
                    if (hRes) {
                      tagCount += 1;
                    }
                    containsTag = hRes;
                }
            }
        });
        if (!result.nonPubSrc && containsTag) {
            nativePublisherCon += 1;
        }
        return containsTag;
    });
    // Make sure positions 1 & 2 are native publisher content
    if (filteredResults.length >= 2 && nativePublisherCon >= 2) {
        let nativePubIndex = filteredResults.findIndex(c => c.nonPubSrc === false);
            if (nativePubIndex > 0) {
            // Temp deep copy
            let tmp = JSON.parse(JSON.stringify(filteredResults[0]));
            filteredResults[0] = filteredResults[nativePubIndex];
            filteredResults[nativePubIndex] = tmp;
            if (nativePublisherCon > 1) {
                let nativePubIndexTwo = filteredResults.findIndex(c => (c.nonPubSrc === false && c._id !== filteredResults[0]._id));
                if (nativePubIndexTwo > 1) {
                    let tmpTwo = JSON.parse(JSON.stringify(filteredResults[1]));
                    filteredResults[1] = filteredResults[nativePubIndexTwo];
                    filteredResults[nativePubIndexTwo] = tmpTwo;
                }
            }
        }
    }
    let nonBaseTypes = [];
    if (this.props.baseContentType === ContentTypesEnum.ARTICLE) {
      nonBaseTypes = [
        ContentTypesEnum.PRODUCT, 
        ContentTypesEnum.PRODUCTFRAMEVIEW, 
        ContentTypesEnum.PRODUCTLINKVIEW,
        ContentTypesEnum.EVENTFRAMEVIEW,
        ContentTypesEnum.EVENT,
        ContentTypesEnum.EVENTLINKVIEW,
        ContentTypesEnum.SPONSORED,
        ContentTypesEnum.SPONSOREDFRAMEVIEW,
        ContentTypesEnum.SPONSOREDLINKVIEW
      ];
    } else if (this.props.baseContentType === ContentTypesEnum.PRODUCT) {
        nonBaseTypes = [
            ContentTypesEnum.EVENTFRAMEVIEW,
            ContentTypesEnum.EVENT,
            ContentTypesEnum.EVENTLINKVIEW,
            ContentTypesEnum.SPONSORED,
            ContentTypesEnum.SPONSOREDFRAMEVIEW,
            ContentTypesEnum.SPONSOREDLINKVIEW,
            ContentTypesEnum.ARTICLE,
            ContentTypesEnum.ARTICLEFRAMEVIEW,
            ContentTypesEnum.ARTICLELINKVIEW
        ];
    }
    const nonBaseIndexes = getAllIndexes(filteredResults, nonBaseTypes);
    const adTypes = [ContentTypesEnum.ADVERTISEMENT, ContentTypesEnum.ADVERTISEMENTFRAMEVIEW, ContentTypesEnum.ADVERTISEMENTLINKVIEW];
    let adIndexes = getAllIndexes(filteredResults, adTypes);
    if (filteredResults.length >= 4) {
        if (nonBaseIndexes.length > 0) {
          filteredResults = this.shuffleToPositions(nonBaseIndexes, filteredResults, nonBaseTypes);
        }
        adIndexes = getAllIndexes(filteredResults, adTypes);
        if (adIndexes.length > 2) {
          // Need this line so ads that were assigned greedy for this row but can't show in slot 2 or 3 because they 
          // already have ads in them are allowed to show in other rows i.e. are taken out of greedy for this row
          const adIIndxsToRemoveFromHash = adIndexes.slice(2);
          adIIndxsToRemoveFromHash.forEach(adIndxToRem => {
            this.remResultHash(filteredResults[adIndxToRem], Tag);
          });
        }
        if (adIndexes.length > 0) {
          filteredResults = this.shuffleToPositions(adIndexes, filteredResults, adTypes);
        }
    }
    if ((filteredResults.length === 1 && (nonBaseIndexes.length > 0 || adIndexes.length > 0)) && !this.props.free) {
        this.remResultHash(filteredResults[0], Tag);
        filteredResults = [];
    }
    if (nativePublisherCon === 0 && filteredResults.length > 0) {
        filteredResults.forEach(fRes => {
          this.remResultHash(fRes, Tag);
        });
        filteredResults = [];
    }
    if (!this.tagHash[Tag]) {
        this.tagHash[Tag] = Tag;
    }
    return filteredResults;
  };

  filterResultsByTagNonGreedy = (Tag) => {
    return this.props.nonGreedyResults.filter(result => {
      let containsTag = false;
      Object(result.meTags).forEach(tag => {
          if (Tag === tag) {
          containsTag = true;
          }
      });
        return containsTag;
    });
  };

  remResultHash = (result, tag) => {
    // Deep copy
    const rHash = JSON.parse(JSON.stringify(this.resultHash));
    rHash._v += 1;
    if (rHash[result._id]) {
        delete rHash[result._id];
        if (this.resultHash._v >= rHash._v) {
        // If version mismatch then recursively retrieve the latest hashmap
        this.remResultHash(result, tag);
        }
        this.resultHash = rHash;
        return true;
    } else {
        return false;
    }
  }

  saveResultHash = (result, tag) => {
    // Deep copy
    const rHash = JSON.parse(JSON.stringify(this.resultHash));
    rHash._v += 1;
    if (!rHash[result._id] || rHash[result._id] === tag) {
        rHash[result._id] = tag;
        if (this.resultHash._v >= rHash._v) {
          // If version mismatch then recursively retrieve the latest hashmap
          this.saveResultHash(result, tag);
        }
        this.resultHash = rHash;
        return true;
    } else {
        return false;
    }
  };

  fetchPubAsset = (assetLocation) => {
      let image = null;
      this.props.assets.forEach(asset => {
          if (asset.location === assetLocation) {
          image = asset.link;
          }
      });
      return image;
  };


  handleOnViewableItemsChanged({viewableItems}) {
    const viewRows = viewableItems.map(v => {
        if (v.isViewable) {
          return v.item
        }
    });
    this.setState({viewableRows: viewRows});
  };

  UNSAFE_componentWillMount() {
    if (this.props.dfpAds) {
      let tempbanner1ad = this.props.context.state.publisher.adSlots.filter(a => a.type === AdTypesEnum.BANNER1);
      let tempbanner2ad = this.props.context.state.publisher.adSlots.filter(a => a.type === AdTypesEnum.BANNER2);
      let tempbanner3ad = this.props.context.state.publisher.adSlots.filter(a => a.type === AdTypesEnum.BANNER3);
      if (tempbanner1ad.length > 0){
        this.setState({banner1AdUnit: tempbanner1ad[0]});
      }
      if (tempbanner2ad.length > 0){
        this.setState({banner2AdUnit: tempbanner2ad[0]});
      }
      if (tempbanner3ad.length > 0){
        this.setState({banner3AdUnit: tempbanner3ad[0]});
      }
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (newProps.free === true) {
      this.setState({horizontal: true, curTag: ""});
    } else if (this.props.userSpecifiedTag && this.props.userSpecifiedTag !== "") {
      this.state.curTag = this.props.userSpecifiedTag
    }
    if (newProps.scroll == true && newProps.scrollOffset && (newProps.searchHash !== this.state.searchHash && newProps.searchHash !== this.sHash) && !this.props.loading && this.props.numFound !== 0) {
      this.sHash = newProps.searchHash;
      this.setState({searchHash: newProps.searchHash});
      setTimeout(() => {
        let curOffset = (Platform.OS === 'web' && !isMobile) ? Math.max((newProps.scrollOffset - this.state.offset), 0) : Math.max(((newProps.scrollOffset - 55) - this.state.offset), 0);
        this.flatlist.current.scrollToOffset({offset: Math.max(curOffset, 0), animated: true});
      }, 250);
    }
  }

  handleScroll(event) {
    this.setState({offset: event.nativeEvent.contentOffset.y});
  };
  
  render() {
    const ResultsList = this.props.resultList;
    const Footer = this.props.Footer;
    const ImgHeader = this.props.imgHeader;
    const Header = this.props.Header;
    return (
      <View
        style={{width: Dimensions.get('window').width, height: Dimensions.get('window').height, justifyContent: 'center', alignItems: 'center'}}
      >
      {
        this.props.numFound === 0 ? 
          <View style={{flex: 1, flexDirection: 'row', paddingTop: 100}}>
            {this.props.free ? <Text style={{color: this.props.darkMode ? 'white' : 'black', paddingLeft: 25, fontSize: 22, paddingTop: 5}}>{this.props.numFound === 0 ? `${this.props.numFound.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} results for \"${this.props.curSearchTxt}\"` : ""}</Text> : null}
            {this.props.errorMessage ? <Text style={{color: this.props.darkMode ? 'white' : 'red', backgroundColor: darkMode ? '#3c3c3d' : 'white', paddingLeft: 25, paddingTop: 5, fontSize: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web')  ? 14 : 18}}>{this.props.errorMessage}</Text> : null}
            <Button
              title="Go Back"
              style={{paddingLeft: 10}}
              onPress={() => {
                this.props.refresh();
              }}
            />
          </View>
        : null
      }
      {this.props.grid ?
      <>
      {this.state.horizontal ?
        <FlatList
            ref={this.flatlist}
            onScroll={(event) => this.handleScroll(event)}
            style={{backgroundColor: this.props.darkMode ? '#3c3c3d' : 'white', marginTop: (Platform.OS !== 'web') ? 15 : null}}
            data={this.props.resultsTags}
            keyExtractor={(result) => result}
            onViewableItemsChanged={this.handleOnViewableItemsChanged}
            viewabilityConfig={this.VIEWABILITY_CONFIG}
            renderItem={({ item, index }) => {
                return (
                    <View
                      style={{
                        width: Dimensions.get('window').width,
                        paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
                        paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
                      }}
                    >
                    {((typeof this.props.results !== 'undefined') && (item === this.props.resultsTags[0])) ?
                    <>
                    {this.props.numFound !== 0
                    ?
                    <View style={{flex: 1}}>
                        <>
                          {Header ? 
                            <Header
                              {...this.props.headerProps}
                            />
                          : null}
                          {ImgHeader ?
                          <ImgHeader
                            fetchPubAsset={this.fetchPubAsset}
                            darkMode={this.props.darkMode}
                            tagBarColor={this.props.tagBarColor}
                            restaurantInfo={this.props.restaurantInfo ? this.props.restaurantInfo : {}}
                          />
                          : null}
                        </>
                    {!this.props.free && this.props.searchScreenPage === 0
                    ?
                    <ResultsList
                        darkMode={this.props.darkMode}
                        results={this.props.headlineResults}
                        title={'Headlines'}
                        curLoadTag={'Headline'}
                        userSpecified={false}
                        tagBarColor={this.props.tagBarColor}
                        viewable={true}
                        isAdmin={this.props.isAdmin}
                        pgFocus={this.props.pgFocus}
                        navigation={this.props.navigation}
                        fetchMoreContentQueue={this.props.fetchMoreContentQueue}
                        horizontal={this.state.horizontal}
                        setHorizontal={this.setHorizontal}
                        setCurTag={this.setCurTag}
                        grid={this.props.grid}
                    />
                    : null}
                    </View>
                    :null}
                    <View style={{flexDirection: 'row', justifyContent: 'center', paddingBottom: 5}}>
                    { (Platform.OS === 'web' && this.props.dfpAds && this.state.banner1AdUnit["adUnit"] != "") ?
                        (isMobile)?
                        <AdUnit
                            dfpNetworkID={this.props.context.state.publisher.dfpNetworkID} 
                            adPlatform={this.props.context.state.publisher.adPlatform} 
                            adUnit={this.state.banner1AdUnit["adUnit"]}
                            slotID={this.state.banner1AdUnit["slotID"]}
                            sizes={[[320, 50], [300, 50]]}
                            cstmSctn="Homepage"
                        ></AdUnit>
                        :
                        <AdUnit
                            dfpNetworkID={this.props.context.state.publisher.dfpNetworkID} 
                            adPlatform={this.props.context.state.publisher.adPlatform} 
                            adUnit={this.state.banner1AdUnit["adUnit"]}
                            slotID={this.state.banner1AdUnit["slotID"]}
                            sizes={[[728, 90]]}
                            cstmSctn="homepage"
                        ></AdUnit>
                    : null}
                    </View>
                    </>
                    : null}
                    {((typeof this.props.results !== 'undefined') && (item === this.props.resultsTags[0]))
                    ?
                    // <View>
                    <>
                        <View style={{flexDirection: 'row'}}>
                          {
                            this.props.free ? 
                              <Text style={{color: this.props.darkMode ? 'white' : 'black', paddingLeft: 15, marginBottom: 10, fontSize: 22, paddingTop: 5}}>
                                {this.props.numFound !== -1 ? `${this.props.numFound.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} results for \"${this.props.curSearchTxt}\"` : ""}
                              </Text>
                            : null
                          }
                          {
                            this.props.free ? 
                              <Button title="Reset" buttonStyle={{backgroundColor: this.props.tagBarColor}} style={{paddingLeft: 10, marginBottom: 20, width: 80}} onPress={() => { this.props.refresh();}}/> 
                            : null
                          }
                        </View>
                        {
                          this.props.userSpecifiedTag !== ''
                        ? 
                        <ResultsList
                            darkMode={this.props.darkMode}
                            results={this.props.userSpecifiedResults}
                            title={this.props.userSpecifiedTag}
                            onReachedEnd={this.props.fetchMoreContent}
                            userSpecified={true}
                            searchTxt={this.props.curSearchTxt}
                            freesearchState={this.props.free}
                            isLoading={this.props.loading}
                            curLoadTag={this.props.userSpecifiedTag}
                            tagBarColor={this.props.tagBarColor}
                            viewable={true}
                            isAdmin={this.props.isAdmin}
                            pgFocus={this.props.pgFocus}
                            navigation={this.props.navigation}
                            fetchMoreContentQueue={this.props.fetchMoreContentQueue}
                            horizontal={this.state.horizontal}
                            setHorizontal={this.setHorizontal}
                            setCurTag={this.setCurTag}
                            grid={this.props.grid}
                        />
                        : null}
                        {!this.props.free && this.props.searchScreenPage === 0
                        ?
                        // Latest Row
                        <ResultsList
                           darkMode={this.props.darkMode}
                           results={this.props.latestResults}
                           title={'Latest'}
                           curLoadTag={'Latest'}
                           userSpecified={false}
                           tagBarColor={this.props.tagBarColor}
                           viewable={true}
                           isAdmin={this.props.isAdmin}
                           pgFocus={this.props.pgFocus}
                           navigation={this.props.navigation}
                           fetchMoreContentQueue={this.props.fetchMoreContentQueue}
                           horizontal={this.state.horizontal}
                           setHorizontal={this.setHorizontal}
                           setCurTag={this.setCurTag}
                           grid={this.props.grid}
                        />
                        : null}
                        {/* Pub.username tag Row, e.g. Sewickley */}
                        <ResultsList
                           darkMode={this.props.darkMode}
                           results={item === this.props.userSpecifiedTag ? [] : this.getUniqueContent(this.filterResultsByTag(item).concat(this.filterResultsByTagNonGreedy(item)))}
                           userSpecified={false}
                           title={item}
                           onReachedEnd={this.props.fetchMoreContent}
                           searchTxt={this.props.curSearchTxt}
                           freesearchState={this.props.free}
                           isLoading={this.props.loading}
                           curLoadTag={this.props.currentLoadTag}
                           tagBarColor={this.props.tagBarColor}
                           viewable={this.state.viewableRows.includes(item)}
                           isAdmin={this.props.isAdmin}
                           pgFocus={this.props.pgFocus}
                           navigation={this.props.navigation}
                           fetchMoreContentQueue={this.props.fetchMoreContentQueue}
                           horizontal={this.state.horizontal}
                           setHorizontal={this.setHorizontal}
                           setCurTag={this.setCurTag}
                           grid={this.props.grid}
                        />
                    {/* </View> */}
                    </>
                    : <>
                    {/* Row for all the remaining results by tag */}
                    <ResultsList
                        darkMode={this.props.darkMode}
                        results={item === this.props.userSpecifiedTag ? [] : this.getUniqueContent(this.filterResultsByTag(item).concat(this.filterResultsByTagNonGreedy(item)))}
                        userSpecified={false}
                        title={item}
                        onReachedEnd={this.props.fetchMoreContent}
                        searchTxt={this.props.curSearchTxt}
                        freesearchState={this.props.free}
                        isLoading={this.props.loading}
                        curLoadTag={this.props.currentLoadTag}
                        tagBarColor={this.props.tagBarColor}
                        viewable={this.state.viewableRows.includes(item)}
                        isAdmin={this.props.isAdmin}
                        pgFocus={this.props.pgFocus}
                        navigation={this.props.navigation}
                        fetchMoreContentQueue={this.props.fetchMoreContentQueue}
                        horizontal={this.state.horizontal}
                        setHorizontal={this.setHorizontal}
                        setCurTag={this.setCurTag}
                        grid={this.props.grid}
                      />
                        {index === this.props.resultsTags.length-1 
                        ? <View style={{flex: 1, marginTop: (Platform.OS === 'web' && isMobile) ? 50 : 5}}>
                            {this.props.numFound !== 0
                            ?<View style={{height: 40, width: null, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            {this.props.searchScreenPage > 0
                            ?<View style={{padding: 10}}>
                                <PageNav
                                setSearchScreenPage={this.props.setSearchScreenPage}
                                page={Math.max(0, (this.props.searchScreenPage-1))}
                                title={'Previous'}
                                darkMode={this.props.darkMode}
                                setUserSpecifiedTag={this.props.setUserSpecifiedTag}
                                />
                            </View>
                            : null}
                            {(this.props.searchScreenPage < (Math.ceil(this.props.totalPageNum/ARTICLEROWSPERPAGE)-1) && this.props.numFound !== 1)
                            ?<View style={{padding: 10}}>
                                <PageNav
                                setSearchScreenPage={this.props.setSearchScreenPage}
                                page={Math.min((Math.ceil(this.props.totalPageNum/ARTICLEROWSPERPAGE)-1), (this.props.searchScreenPage+1))}
                                title={'Next'}
                                darkMode={this.props.darkMode}
                                setUserSpecifiedTag={this.props.setUserSpecifiedTag}
                                />
                            </View>
                            :null}
                            </View>
                            :null}
                            <View style={{flexDirection: 'row', justifyContent: 'center', paddingTop: 15}}>
                              { (Platform.OS === 'web' && index === this.props.resultsTags.length-1 && this.props.dfpAds && this.state.banner3AdUnit["adUnit"] != "")  ? 
                                  (isMobile)?
                                  <AdUnit
                                      dfpNetworkID={this.props.context.state.publisher.dfpNetworkID} 
                                      adPlatform={this.props.context.state.publisher.adPlatform} 
                                      adUnit={this.state.banner3AdUnit["adUnit"]}
                                      slotID={this.state.banner3AdUnit["slotID"]}
                                      sizes={[[320, 50], [300, 50]]}
                                      cstmSctn="Homepage"
                                  ></AdUnit>
                                  :
                                  <AdUnit
                                      dfpNetworkID={this.props.context.state.publisher.dfpNetworkID} 
                                      adPlatform={this.props.context.state.publisher.adPlatform} 
                                      adUnit={this.state.banner3AdUnit["adUnit"]}
                                      slotID={this.state.banner3AdUnit["slotID"]}
                                      sizes={[[728, 90]]}
                                      cstmSctn="Homepage"
                                  ></AdUnit>
                                  : null }
                            </View>
                            <View style={{margin: 10, paddingBottom: 15}}>
                              <Footer/>
                            </View>
                          </View>
                        : null}
                    <View style={{flexDirection: 'row', justifyContent: 'center', paddingBottom: 5}}>
                    { (Platform.OS === 'web' && index === 1 && this.props.dfpAds && this.state.banner2AdUnit["adUnit"] != "")  ? 
                       (isMobile)?
                            <AdUnit
                                dfpNetworkID={this.props.context.state.publisher.dfpNetworkID} 
                                adPlatform={this.props.context.state.publisher.adPlatform} 
                                adUnit={this.state.banner2AdUnit["adUnit"]}
                                slotID={this.state.banner2AdUnit["slotID"]}
                                sizes={[[320, 50], [300, 50]]}
                                cstmSctn="Homepage"
                            ></AdUnit>
                            :
                            <AdUnit
                                dfpNetworkID={this.props.context.state.publisher.dfpNetworkID} 
                                adPlatform={this.props.context.state.publisher.adPlatform} 
                                adUnit={this.state.banner2AdUnit["adUnit"]}
                                slotID={this.state.banner2AdUnit["slotID"]}
                                sizes={[[728, 90]]}
                                cstmSctn="Homepage"
                            ></AdUnit>
                    : null }
                    </View>
                      </>}                      
                    </View>
                );
            }}        
        />
        : <View
              style={{height: Dimensions.get('window').height, 
              paddingTop: this.props.horizontal ? 50: null, 
              backgroundColor: this.props.darkMode ? '#3c3c3d' : 'white'
            }}
          >
            {this.state.curTag === 'Latest'
            ?
              <ResultsList
                darkMode={this.props.darkMode}
                results={this.props.latestResults}
                title={'Latest'}
                curLoadTag={'Latest'}
                userSpecified={false}
                tagBarColor={this.props.tagBarColor}
                viewable={true}
                isAdmin={this.props.isAdmin}
                pgFocus={this.props.pgFocus}
                navigation={this.props.navigation}
                fetchMoreContentQueue={this.props.fetchMoreContentQueue}
                horizontal={this.state.horizontal}
                setHorizontal={this.setHorizontal}
                setCurTag={this.setCurTag}
                grid={this.props.grid}
              />
            : <>{this.state.curTag !== this.props.userSpecifiedTag ?
              <ResultsList
                darkMode={this.props.darkMode}
                results={this.getUniqueContent(this.filterResultsByTag(this.state.curTag).concat(this.filterResultsByTagNonGreedy(this.state.curTag)))}
                userSpecified={this.props.userSpecifiedTag !== ''}
                title={this.state.curTag}
                onReachedEnd={this.props.fetchMoreContent}
                searchTxt={this.props.curSearchTxt}
                freesearchState={this.props.free}
                isLoading={this.props.loading}
                curLoadTag={this.props.currentLoadTag}
                tagBarColor={this.props.tagBarColor}
                viewable={true}
                isAdmin={this.props.isAdmin}
                pgFocus={this.props.pgFocus}
                navigation={this.props.navigation}
                fetchMoreContentQueue={this.props.fetchMoreContentQueue}
                horizontal={this.state.horizontal}
                setHorizontal={this.setHorizontal}
                setCurTag={this.setCurTag}
                grid={this.props.grid}
              />
              : <ResultsList
                    darkMode={this.props.darkMode}
                    results={this.props.userSpecifiedResults}
                    userSpecified={this.props.userSpecifiedTag !== ''}
                    title={this.state.curTag}
                    onReachedEnd={this.props.fetchMoreContent}
                    searchTxt={this.props.curSearchTxt}
                    freesearchState={this.props.free}
                    isLoading={this.props.loading}
                    curLoadTag={this.props.currentLoadTag}
                    tagBarColor={this.props.tagBarColor}
                    viewable={true}
                    isAdmin={this.props.isAdmin}
                    pgFocus={this.props.pgFocus}
                    navigation={this.props.navigation}
                    fetchMoreContentQueue={this.props.fetchMoreContentQueue}
                    horizontal={this.state.horizontal}
                    setHorizontal={this.setHorizontal}
                    setCurTag={this.setCurTag}
                    grid={this.props.grid}
                    />
                }</>
            }
          </View>
        }</>
        :
        <View
          style={{
            backgroundColor: this.props.darkMode ? '#3c3c3d' : 'white',
            height: Dimensions.get('window').height,
            width: Dimensions.get('window').width,
            paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
            paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
          }}
        >
            <View>
              {Header ? 
                <Header
                  {...this.props.headerProps}
                />
              : null}
            </View>
            {
              this.props.userSpecifiedTag ? (
                <TagFilter 
                  userTag={this.props.userSpecifiedTag}
                  onPress={() => this.props.loadRegularResults()}
                />
              ) : null
            }
            <ResultsList
              darkMode={this.props.darkMode}
              results={this.props.resultsV2}
              userSpecified={this.props.userSpecifiedTag !== ''}
              userSpecifiedTag={this.props.userSpecifiedTag}
              title={""}
              onReachedEnd={this.props.fetchMoreContent}
              searchTxt={this.props.curSearchTxt}
              freesearchState={this.props.free}
              isLoading={this.props.loading}
              curLoadTag={this.props.currentLoadTag}
              tagBarColor={this.props.tagBarColor}
              viewable={true}
              isAdmin={this.props.isAdmin}
              pgFocus={this.props.pgFocus}
              navigation={this.props.navigation}
              fetchMoreContentQueue={this.props.fetchMoreContentQueue}
              horizontal={false}
              setHorizontal={this.setHorizontal}
              setCurTag={this.setCurTag}
              isLoadingV2={this.props.isLoadingV2}
              imageHeader={this.props.imgHeader}
              restaurantInfo={this.props.restaurantInfo ? this.props.restaurantInfo : {}}
              assets={this.props.assets}
              grid={this.props.grid}
            />
          </View>
            
      } 
      </View>
    );
  };
};

export default GridPage;