import React from 'react';
import {View, TouchableOpacity, StyleSheet, Text} from 'react-native';

const HeaderButton = ({onPress, title}) => {
  return (
    <View style={styles.viewStyle}>
      <TouchableOpacity onPress={onPress}>
        <Text style={styles.headerButtonText}>{title}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  viewStyle: {
    marginHorizontal: 20,
  },
  headerButtonText: {
    color: '#24627a',
    fontWeight: '600',
    fontSize: 16
  },
});

export default HeaderButton;