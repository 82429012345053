import React, { useContext, useEffect, useState } from 'react';
import  { Button, Input, Icon } from 'react-native-elements';
import { View, StyleSheet, Image, Dimensions,  TouchableOpacity, ScrollView, SafeAreaView, Linking, Text } from 'react-native';
import { Platform } from 'react-native';
import Spacer from '../../Space';
import AuthForm from '../../core/AuthForm';
import { Context as AuthContext } from '../../../context/AuthContext';
import { Context as PubContext } from '../../../context/PubContext';
import { Feather } from '@expo/vector-icons';
import { DrawerActions } from '@react-navigation/native';
import { isMobile } from 'react-device-detect';

const AuthFormWrapper = ({navigation, route}) => {
  const authParams = route.params ?? null;
  let navToScreen = { parentNav: 'Welcome'};
  if (authParams && authParams.navToScreen) {
    navToScreen = authParams.navToScreen
  }
  const [fingerPrint, setFingerPrint] = useState(null);
  const { signin, state, clearErrorMessage, tryFingerPrintSignin } = useContext(AuthContext);
  const PubCon = useContext(PubContext);

  useEffect (() => {
    if (Platform.OS === 'web') {
      // Need this conditional import because importing this lib breaks native mobile
      require('clientjs');
      // Set up fingerprint
      const client = new ClientJS();
      setFingerPrint(String(client.getFingerprint()));
    } else {
      // Create an account with uuid as the username and password
      const uuidv4 = require('uuid/v4');
      setFingerPrint(String(uuidv4()));
    }
  }, []);
  
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // TODO: reset the passord and username to '' in the core comp
      clearErrorMessage();
    });
    return unsubscribe;
  }, []);

  const resetUrl = () => {
    if (Platform.OS === 'web') {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let pub = params.get('p');
      if (pub) {
        history.pushState(null, 'body', `/?p=${pub}`);
      } else {
        const pubPageMatches = `${PubCon.state.ref}`.match(/[^\/]+/g);
        let pubPage = (pubPageMatches && pubPageMatches.length >= 3) ? pubPageMatches[2] : '/';
        history.replaceState(null, 'body', `/${pubPage}` === '//' ? '/' : `/${pubPage}`);
      }
    }
  }

  const meSignin = ({username, password}) => {
    state.token ? signin({ username, password, fingerPrint, referrer: PubCon.state.ref }) : signin({ username, password, referrer: PubCon.state.ref });
  };
  
  return (
    <View style={{paddingTop: Platform.OS === 'ios' ? 40 : 10}}>

      <View style={Dimensions.get('window').width > 1250 ? styles.containerLarge : null}>
        <TouchableOpacity style={{padding: 10, width: 70}} onPress={() => navigation.dispatch(DrawerActions.openDrawer())}>
          <Feather name="menu" style={{
            fontSize: 35,        
          }}/>
        </TouchableOpacity>
      </View>

    <AuthForm
        copyWriteText={'Copyright © Mesearch Media Technologies Limited 2021'}
        privacyUrl={'https://mesearch.us/wp-content/uploads/2020/02/2020-01-01_Crivella-Media-Tech_Collection-and-Use-of-Personal-Information_Policy-only.pdf'}
        termsOfServiceUrl={'https://mesearch.us/wp-content/uploads/2020/02/Terms-of-Services.pdf'}
        signinCallback={meSignin}
        benefitText={'Enjoy the benefits of Mesearch membership.'}
        logoImagePath={require('../../../../assets/logo-mesearch.png')}
        navigation={navigation}
        onSignUp={() => {
            //TODO need logic for kiosk vs meSearch
            //NAVCONNECT to new signup screen currently disabled
            resetUrl();
            navigation.navigate('SignupChoice');
        }}
        onSubmitAsGuest={() => {
          resetUrl();
          if (state.token) {
            // If already signed in then nav to search screen
            clearErrorMessage();
            navigation.navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search'}}});
          } else {
            if (fingerPrint) {
              tryFingerPrintSignin({fingerPrint, referrer: PubCon.state.ref});
            }
          }
        }}
        errorMessage={state.errorMessage}
        clearErrorMessage={clearErrorMessage}
    />
    </View>
  );
};

const styles = StyleSheet.create({
  containerLarge: {
    paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
    paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
  },
})

export default AuthFormWrapper;