import React from 'react';
import { ScrollView, StyleSheet, Dimensions, Text} from 'react-native';

import MenuHomeWrapper from '../components/wrappers/MeSearch/MenuHomeWrapper';
const AccountSettingsScreen = ({navigation, route}) => {
  const darkMode = route.params?.darkMode ?? false;
  return (
      <ScrollView style={[Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall, darkMode ? {backgroundColor: '#3c3c3d'} : null]}>
      <MenuHomeWrapper navigation={navigation} route={route} darkMode={darkMode} />
      </ScrollView>
  );
};

const styles = StyleSheet.create({
  containerSmall: {
      paddingTop: 10,
      flex: 1,
      paddingLeft: 10,
      paddingRight: 10,
    },
    containerLarge: {
      paddingTop: 10,
      width: Dimensions.get('window').width,
      paddingLeft: Dimensions.get('window').width > 750 ? ((Dimensions.get('window').width - 750)/2) : null,
      paddingRight: Dimensions.get('window').width > 750 ? ((Dimensions.get('window').width - 750)/2) : null
    },
});
export default AccountSettingsScreen;