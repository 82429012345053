import React, { useState } from 'react';
import { View, SafeAreaView, Text, FlatList, StyleSheet, TouchableOpacity, TextInput, Dimensions } from 'react-native';
import TagLabel from '../components/core/TagLabel';
import { Feather } from '@expo/vector-icons';
import meTagsAll from '../components/utils/meTags';
import HeaderButton from '../components/core/HeaderButton';

const AddTagsScreen = ({ navigation, route }) => {

  const meTags = route.params?.meTags ?? [];
  const nativeTags = route.params?.nativeTags ?? [];
  const body_id = route.params?.body_id ?? null;
  const remTags = route.params?.remTags ?? [];
  const remMeTags = route.params?.remMeTags ?? [];
  const addTags = route.params?.addTags ?? [];
  const addMeTags = route.params?.addMeTags ?? [];
  const isNative = route.params?.isNative ?? false;

  const [searchTxt, setSearchTxt] = useState("");
  const [nativeTxt, setNativeTxt] = useState("");
  const [addedTags, setAddedTags] = useState([]);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: 'Add Tags',
      headerTitleAlign:'center',
      headerRight: () => (
        <HeaderButton 
          title="Add"
          onPress={() => {
            navigation.navigate(
                'TagManager', 
                {
                    meTags: meTags, 
                    nativeTags: nativeTags, 
                    body_id: body_id, 
                    remNativeTags: remTags,
                    remMeTags: remMeTags,
                    addNativeTags: (nativeTxt && nativeTxt !== "") ? [...addTags, nativeTxt] : addTags,
                    addMeTags: [...addMeTags, ...addedTags]
                }
            )
          }}
        />
      ),
      headerLeft: () => (
        <HeaderButton 
          title="Cancel"
          onPress={() => {
            navigation.navigate(
                'TagManager', 
                {
                    meTags: meTags, 
                    nativeTags: nativeTags, 
                    body_id: body_id, 
                    remNativeTags: remTags,
                    remMeTags: remMeTags,
                    addNativeTags: addTags,
                    addMeTags: addMeTags
                }
            )
          }}
        />
      ),
    });
  }, [navigation, addedTags, nativeTxt]);

  return (
    <SafeAreaView style={styles.main}>
      {!isNative ?
      <View style={{width: Math.min(Dimensions.get('window').width-20, 500), paddingTop: 5}}>
        <View style={styles.header}>
            <Text style={styles.heading}>Add Tags:</Text>
            <View style={{flexDirection: 'row'}}>
                <View style={{ borderColor: 'gray', borderWidth: 2, marginTop: 10, backgroundColor: '#F0EEEE', height: 50, borderRadius: 5, flexDirection: 'row', marginBottom: 10, flex: 1}}>
                    <TouchableOpacity 
                      onPress={() => {

                      }}
                    >
                        <Feather name="search" style={{ fontSize: 35, marginHorizontal: 5, marginTop: 5}}/>
                    </TouchableOpacity>
                    <TextInput
                        autoCapitalize="none"
                        autoCorrect={false}
                        style={{ flex: 1, fontSize: 20 }}
                        placeholder={"Search"}
                        value={searchTxt}
                        onChangeText={(txt) => setSearchTxt(txt)}
                        onFocus={(evnt) => setSearchTxt(evnt.nativeEvent.text)}
                        onSubmitEditing={() => {}}
                        onEndEditing={() => {}}
                        multiline={false}
                        maxLength={120}
                    />
                </View>
            </View>
        </View>
        <View style={{height: Dimensions.get('window').height-150}}>
            <FlatList 
                data={meTagsAll.filter(m => searchTxt !== "" ? m.toLowerCase().includes(searchTxt.toLowerCase()) : true)}
                keyExtractor={item => item}
                renderItem={({ item }) => {
                return (
                    <View style={{paddingTop: 2, paddingBottom: 2}}>
                        <TagLabel 
                            tagText={item} 
                            type="add"
                            highlight={addMeTags.includes(item) || addedTags.includes(item)}
                            onPress={() => {
                                if (!addedTags.includes(item) && !addMeTags.includes(item)) {
                                const newTags = [...addedTags, item];
                                setAddedTags(newTags);
                                } else {
                                    const newTags = addedTags.filter(a => a !== item);
                                    setAddedTags(newTags);
                                }
                            }} 
                        />
                    </View>
                )
                }}
            />
        </View>
      </View>
    :
    <View style={{ borderColor: 'gray', borderWidth: 2, marginTop: 10, backgroundColor: '#F0EEEE', height: 50, borderRadius: 5, flexDirection: 'row', marginBottom: 10, width: 250}}>
        <TextInput
            autoCapitalize="none"
            autoCorrect={false}
            style={{ fontSize: 20, flex: 1 }}
            placeholder={"Enter a tag"}
            value={nativeTxt}
            onChangeText={(txt) => setNativeTxt(txt)}
            onFocus={(evnt) => setNativeTxt(evnt.nativeEvent.text)}
            onSubmitEditing={() => {}}
            onEndEditing={() => {}}
            multiline={false}
            maxLength={120}
        />
    </View>
    }
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  main: {
    height: Dimensions.get('window').height-120,
    alignItems: 'center'
  },
  header: {},
  heading: {},
});

export default AddTagsScreen;