import React, { useContext, useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions, Image, TouchableOpacity, ScrollView, Text } from 'react-native';
import  { Button, Icon } from 'react-native-elements';
import { Context as AuthContext } from '../context/AuthContext';
import { Context as PubContext } from '../context/PubContext';
import AuthForm  from '../components/AuthForm';
import { Platform } from 'react-native';
import { DrawerActions } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons';
import CopyrightFooter from '../components/CopyrightFooter';

const SignupScreen = ({ navigation, route }) => {
    
    const { state, signup, termsOfService, clearErrorMessage, tryFingerPrintSignin } = useContext(AuthContext);
    const PubCon = useContext(PubContext);

    const [fingerPrint, setFingerPrint] = useState(null);
    const upload = route.params?.upload ?? '';
    const termsAccepted = route.params?.termsAccepted ?? '';
    useEffect (() => {
      if (Platform.OS === 'web') {
        // Need this conditional import because importing this lib breaks native mobile
        require('clientjs');
        // Set up fingerprint
        const client = new ClientJS();
        setFingerPrint(String(client.getFingerprint()));
      } else {
        // Create an account with uuid as the username and password
        const uuidv4 = require('uuid/v4');
        setFingerPrint(String(uuidv4()));
      }
      
    }, []);

    const resetUrl = () => {
      if (Platform.OS === 'web') {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let pub = params.get('p');
        if (pub) {
          history.pushState(null, 'body', `/?p=${pub}`);
        } else {
          const pubPageMatches = `${PubCon.state.ref}`.match(/[^\/]+/g);
          let pubPage = (pubPageMatches && pubPageMatches.length >= 3) ? pubPageMatches[2] : '/';
          history.replaceState(null, 'body', `/${pubPage}` === '//' ? '/' : `/${pubPage}`);
        }
      }
    }

    useEffect (() => {
      const unsubscribe = navigation.addListener('focus', () => {
        clearErrorMessage();
      });
      return unsubscribe;
    }, []);
    
    return (
        <ScrollView contentContainerStyle={Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall}>
          <TouchableOpacity style={{paddingLeft: 10, paddingTop: 10}} onPress={() => navigation.dispatch(DrawerActions.openDrawer())}>
            <Feather name="menu" style={{
              fontSize: 35
            }}/>
          </TouchableOpacity>
          <AuthForm
            upload={upload}
            formType='signUp'
            headerText="Sign Up For MeSearch"
            errorMessage={state.errorMessage}
            clearErrorMessage={clearErrorMessage}
            submitButtonText="Sign Up"
            onSubmit={({ username, password }) => {
              if (!termsAccepted) {
                termsOfService({ username, password, guest: false })
              } else if (state.token) {
                signup({ username: username, password: password, guest: false, referrer: PubCon.state.ref })
              } else {
                signup({ username: username, password: password, guest: false, noToken: true, referrer: PubCon.state.ref })
              }
              resetUrl();
            }}
            onSubmitAsGuest={() => {
              if (state.token) {
                // If already signed in then nav to search screen
                clearErrorMessage();
                navigation.navigate('Search');
              } else {
                if (fingerPrint) {
                  tryFingerPrintSignin({fingerPrint, referrer: PubCon.state.ref});
                }
              }
              resetUrl();
            }}
            meSign={false}
            navigation={navigation}
          />
          <CopyrightFooter />
        </ScrollView>
    );
};

const styles = StyleSheet.create({
  containerSmall: {
    flex: 1,
    justifyContent: 'center',
    marginBottom: 5
  },
  containerLarge: {
    width: Dimensions.get('window').width,
    paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
    paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
    justifyContent: 'center',
    marginBottom: 20
  },
  image: {
    width: null,
    height: null,
    resizeMode: 'contain',
    flex: 1
  },
  button: {
    marginHorizontal: 10,
    marginVertical: 2,
  },
  buttonSolid: {
    backgroundColor: '#10335F',
  },
});

export default SignupScreen;