import React, { useState, useContext } from 'react';
import { View, Text, TouchableOpacity, Dimensions, SafeAreaView, StyleSheet, Image } from 'react-native';
import SearchBar from './SearchBar';
import Header from './core/whitelabel/Header';
import { DrawerActions } from '@react-navigation/native';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import Upload from './Upload';
import { Feather } from '@expo/vector-icons';
import MeTags from './utils/meTags';
import {Context as PubContext} from '../context/PubContext';

const PubHeader = ({ 
    navigation,
    route, 
    darkMode,
    setAllTags, 
    setResults,  
    setResultsTags,
    setNonGreedyResults,
    setUserSpecifiedTag,
    setUserSpecifiedResults,
    setSearchScreenPage,
    setNumFound,
    setFreeSearch,
    setCurSearchTxt,
    fetchRelevantUserContentTags,
    updateUserSearchHistory,
    setErrorMessage,
    errorMessage,
    claimed,
    isGuest,
    isAdmin,
    navToLogin,
    termsAccepted,
    free,
    home,
    setHome,
    numFound,
    businessName,
    ugcPermitted,
    upload
}) => {
    const {state} = useContext(PubContext);
    const [term, setTerm] = useState('');
    const refUrl = route.params?.refUrl ?? '';

    return (
        <SafeAreaView>
            {
              state.publisher.username === 'TribExtra-AP' || state.publisher.username === 'Sponsored Sports 2' || refUrl !== '' ? (
                <Header 
                  pubName={state.publisher.whitelabel?.displayName}
                  logo={state.publisher.whitelabel?.logo}
                  menuLinks={state.publisher.whitelabel?.headerLinks}
                  primaryColor={state.publisher.whitelabel?.primaryColor}
                  textColor={state.publisher.whitelabel?.textColor}
                />
              ) : null
            }
            <View 
                style={{flexDirection: 'row', backgroundColor: darkMode ? '#3c3c3d' : 'white',
                    width: Math.min(Dimensions.get('window').width-15, 1250),
                    paddingTop: (Platform.OS === 'android') ? 20 : null,
                }}
            >  
                {
                    state.publisher.username !== 'TribExtra-AP' || state.publisher.username !== 'Sponsored Sports 2' ? (
                        <TouchableOpacity 
                            style={{marginTop: 18, paddingLeft: (Platform.OS === 'web' && !isMobile) ? null : 10, paddingRight: 0}} 
                            onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
                        >
                            <Feather 
                            name="menu" 
                            style={{
                                fontSize: 35,
                                color: darkMode ? 'white' : 'black',
                            }}
                            />
                        </TouchableOpacity>
                    ) : null
                }
                <Image source={state.publisher.defaultImage} style={{ width: 100, marginHorizontal: 2, resizeMode: 'contain' }} />
                <SearchBar
                    term={term}
                    onTermChange={setTerm}
                    onTermSubmit={() => {
                        let txt = term
                        if (txt !== "") {
                        if (home) {
                            setHome(false);
                        }
                        setAllTags([]);
                        setResults([]);
                        setResultsTags([]);
                        setNonGreedyResults([]);
                        let Tag = '';
                        MeTags.map(t => {
                            if (t.toLowerCase() === txt.toLowerCase()) {
                            Tag = t;
                            }
                        });
                        if (Tag !== '') {
                            setUserSpecifiedTag(Tag);
                            setUserSpecifiedResults([]);
                        } else {
                            setUserSpecifiedTag('');
                            setUserSpecifiedResults([]);
                        }
                        setSearchScreenPage(0);
                        setNumFound(-1);
                        setFreeSearch(true);
                        setCurSearchTxt(term);
                        fetchRelevantUserContentTags(txt, true, true, true);
                        updateUserSearchHistory(txt);
                        setTerm('');
                        setErrorMessage('');
                        } else {
                        setCurSearchTxt(term);
                        setTerm('');
                        setErrorMessage('');
                        }
                    }}
                    style={{borderWidth: 5}}
                />
                {((!claimed || isAdmin) && ugcPermitted && upload && termsAccepted) ?
                    state.publisher.username !== 'TribExtra-AP' || state.publisher.username !== 'Sponsored Sports 2' ? (
                        <Upload
                            guest={isGuest}
                            darkMode={darkMode}
                            navToLogin={navToLogin}
                            termsAccepted={termsAccepted}
                            setErrorMsg={setErrorMessage}
                            navigation={navigation}
                            claimed={claimed}
                            businessName={businessName}
                        />
                    ) : null
                : null}
            </View>
        </SafeAreaView>
    );
};

const styles =  StyleSheet.create({
    
});

export default PubHeader;