import React, { useState, useEffect } from 'react';
import  { Button, Input, CheckBox } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, ActivityIndicator, FlatList, ScrollView, Text, TextInput } from 'react-native';
import Spacer from '../components/Space';
import MeSearchAPIPub from '../api/MeSearch-API-Pub';
import MeSearchAPI from '../api/MeSearch-API';
import CopyrightFooter from '../components/CopyrightFooter';
import { Feather } from "@expo/vector-icons";
import predicateSearch from './utils/predicateSearch';
import ValidationTypesEnum from './utils/FormValidation/formValidateTypesEnum';
import SubmitField from './utils/FormValidation/FormValidationSubmitField';
import validateFormField from './utils/FormValidation/validateFormField';
import valErrorExist from './utils/FormValidation/utils/valErrorsExist';
import FormFieldTypesEnum from './utils/FormValidation/formFieldTypesEnum';
import MenuFormItem from '../components/core/MenuFormItem';
import FormField from '../components/formField';
import { Platform } from 'react-native';
import { isMobile } from 'react-device-detect';


const CreateLandingPageScreen = ({navigation, route, darkMode}) => {
  const pubOwner = route.params?.pubOwner ?? '';
  const landDomain = route.params?.landDomain ?? null;
  const [errorMessage, setErrorMsg] = useState("");
  const [errorMessageBtm, setErrorMsgBtm] = useState("");
  const [successMessage, setSuccessMsg] = useState("");
  const [pgLoad, setPGLoad ] = useState(true);
  const [publications, setPublications ] = useState([]);
  const [curLandingPages, setCurLandingPages ] = useState(null);
  const [domains, setDomains] = useState("");
  const [landingPublications, setLandingPublications] = useState([]);
  const [landingPubObjs, setLandingPubObjs] = useState({});
  const [tags, setTags] = useState("");
  const [searchTxt, setSearchTxt] = useState("");

  const [fieldsMeta, setFieldsMeta] = useState({
    //domains: { name: "Urls to direct to this page (baseurl/?p=<enter your name>)", value: [{ value: '' }], error: "", required: false, type: FormFieldTypesEnum.MULTIURL, trim: true },
    headerImage: { name: "Header Image Url (paste url or upload an image)", value: "", error: "", required: true, type: FormFieldTypesEnum.IMAGEUPLOAD, trim: true },
    //tags: { name: "Tags (This creates the rows of the page)", value: [{ value: '' }], error: "", required: false, type: FormFieldTypesEnum.MULTI },
    tagBarColor: { name: "Theme Color (Hex Value)", value: "", error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT, trim: true },
  });

  const [fieldsMetaValidate, setFieldsMetaValidate] = useState({
    domains: { validate: SubmitField(ValidationTypesEnum.URL) },
    headerImage: {validate: SubmitField(ValidationTypesEnum.URL)},
    tags: { validate: SubmitField(ValidationTypesEnum.ANY) },
    tagBarColor: { validate: SubmitField(ValidationTypesEnum.ANY) },
    img: {validate: SubmitField(ValidationTypesEnum.URL)},
  });


  const updateFieldsMeta = (key, value) => {
    let newFieldsMeta = JSON.parse(JSON.stringify(fieldsMeta));
    newFieldsMeta[key] = value;
    setFieldsMeta(newFieldsMeta);
  }

  const fetchPublications = async () => {
    setPGLoad(true);
      try {
        let publications = await MeSearchAPIPub.get(
          '/api/pub-owner/publications', {params: {pubOwner}});
        setPublications(publications.data);
        setPGLoad(false);
      } catch (err) {
        setErrorMsg(`${err.response ? err.response.data.error : 'An error occured please try again later'}`);
        setPGLoad(false);
      }
  };

  const fetchLandingPages = async () => {
    setPGLoad(true);
    try {
        const responseProm = MeSearchAPIPub.get('/api/pub-owner', {params:{pubOwner}});
        responseProm.then(response => {
            if (response.data) {
              setCurLandingPages(response.data.landingPage ? response.data.landingPage : []);
            }
        });
    } catch (err) {
        setErrorMsg(`${err.response ? err.response.data.error : 'An error occured please try again later'}`);
    }
  };

  const fetchLandingPagesAndPreFill = async () => {
      setPGLoad(true);
      let landingPageArr = [];
      let newLandingPubObjs = {};
      let newLandingPublications = [];
      try {
        const response = await MeSearchAPIPub.get('/api/pub-owner', {params:{pubOwner}});
        landingPageArr = response.data.landingPage;
        setCurLandingPages(response.data.landingPage);
        setPGLoad(false);
      } catch (err) {
        setErrorMsg(`${err.response ? err.response.data.error : 'An error occured please try again later'}`);
        setPGLoad(false);
        return;
      }
      let landPG;
      landingPageArr.forEach(landingPg => {
        if (landingPg.domains.includes(landDomain)) {
          landPG = landingPg;
        }
      });
      landPG.publications.forEach(publication => {
          newLandingPublications.push(publication.pubName);
          newLandingPubObjs[`${publication.pubName}`] = publication;
      });
      setDomains(landPG.domains.toString());
      //setHeaderImage(landPG.headerImage);
      //setTagBarColor(landPG.tagBarColor);
      let newFieldsMeta = JSON.parse(JSON.stringify(fieldsMeta));
      newFieldsMeta['headerImage'].value = landPG.headerImage;
      newFieldsMeta['tagBarColor'].value = landPG.tagBarColor;
      setFieldsMeta(newFieldsMeta);
      setTags(landPG.tags.toString());
      setLandingPublications(newLandingPublications);
      setLandingPubObjs(newLandingPubObjs);
  };

  useEffect (() => {
    fetchLandingPages();
    fetchPublications();
    if (landDomain) {
      fetchLandingPagesAndPreFill();
    }
  }, []);

  return (
    <ScrollView contentContainerStyle={Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall}>
    {!pgLoad ?
    <>
      <Spacer/>
      {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
      {successMessage ? <Text style={styles.successMessage}>{successMessage}</Text> : null}
      <Spacer>
            <View>
                <View style={{flexDirection: 'row'}}>
                  <Text style={styles.headerStyle}>Domains:</Text>
                  {(errorMessageBtm === 'Missing required fields' && domains === "") ?
                    <Text style={{color: 'red', paddingLeft: 5, fontWeight: '800', fontSize: 22, marginTop: 15}}>*</Text>
                  : null}
                </View>
                <Input 
                    label="URIs (Comma Separated)"
                    value={domains}
                    onChangeText={(newDomains) => setDomains(newDomains)}
                    autoCapitalize="none"
                    autoCorrect={false}
                    maxLength={80}
                />
                {/*<FormField
                    fieldKey="domains"
                    fieldsMeta={fieldsMeta}
                    updateFieldsMeta={updateFieldsMeta}
                    type={fieldsMeta['domains'].type}
                />*
                {fieldsMeta['domains'].error !== "" ? <Text style={styles.errorMessage}>{fieldsMeta['domains'].error}</Text> : null}
                */}
            </View>
        </Spacer>
        <View style={{flexDirection: 'row', marginLeft: (Platform.OS === 'web' && !isMobile) ? 20 : 5}}>
            <Text style={styles.headerStyle}>Add Publications:</Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 1,
                borderColor: darkMode ? "white" : "#666",
                width: 120,
                height: 40
              }}
            >
              <Feather name="search" size={24} color={darkMode ? "white" : "#666"} />
              <TextInput
                style={{
                  height: 40,
                  width: 70
                }}
                placeholder="search"
                value={searchTxt}
                onChangeText={(txt) => {
                  setSearchTxt(txt);
                }}
              />
              <Feather name="chevron-right" size={24} color={darkMode ? "white" : "#666"} />
              {((errorMessageBtm === 'Missing required fields' && Object.keys(landingPubObjs).length === 0) || errorMessageBtm === 'Missing required fields for one of your publications') ?
                    <Text style={{color: 'red', paddingLeft: 5, fontWeight: '800', fontSize: 22, marginTop: 15}}>*</Text>
              : null}
            </View>
        </View>
        <FlatList
          style={{height: 240, marginLeft: (Platform.OS === 'web' && !isMobile) ? 20 : 5}}
          data={predicateSearch(publications, searchTxt, {username: 1, domains: 1, businessName: 1})}
          keyExtractor={(pub) =>  pub._id}
          renderItem={({ item }) => {
          return (
            <>
                <CheckBox
                    checkedIcon='dot-circle-o'
                    uncheckedIcon='circle-o'
                    title={`${item.username}`}
                    checkedColor='green'
                    checked={landingPublications.includes(item.username)}
                    onPress={() => {
                        let newLandingPublications = JSON.parse(JSON.stringify(landingPublications));
                        if (landingPublications.includes(item.username)) {
                            const index = newLandingPublications.indexOf(item.username);
                            if (index > -1) {
                              newLandingPublications.splice(index, 1);
                            }
                            setLandingPublications(newLandingPublications);
                        } else {
                          newLandingPublications.push(item.username);
                          if (!landingPubObjs[`${item.username}`]) {
                            // Deep copy
                            let newLandingPubObjs = JSON.parse(JSON.stringify(landingPubObjs));
                            newLandingPubObjs[`${item.username}`] = {pubName: `${item.username}`};
                            setLandingPubObjs(newLandingPubObjs);
                          }
                          setLandingPublications(newLandingPublications);
                        }
                    }}
                />
                {landingPublications.includes(item.username) ? 
                <View style={{paddingLeft: 10}}>
                  <Input 
                    label="Display Name"
                    value={landingPubObjs[`${item.username}`]["displayName"] ? landingPubObjs[`${item.username}`]["displayName"] : ""}
                    onChangeText={(newDisplayName) => {
                      // Deep copy
                      let newLandingPubObjs = JSON.parse(JSON.stringify(landingPubObjs));
                      newLandingPubObjs[`${item.username}`]["displayName"] = newDisplayName;
                      newLandingPubObjs[`${item.username}`]["uri"] = landingPubObjs[`${item.username}`]["uri"] ? landingPubObjs[`${item.username}`]["uri"] : (item.domains && item.domains.length > 0) ? item.domains[0] : "";
                      newLandingPubObjs[`${item.username}`]["img"] = landingPubObjs[`${item.username}`]["img"] ? landingPubObjs[`${item.username}`]["img"] : (item.defaultImage && item.defaultImage !== "") ? item.defaultImage : ""
                      setLandingPubObjs(newLandingPubObjs);
                    }}
                    autoCapitalize="none"
                    autoCorrect={false}
                    maxLength={200}
                  />
                  <Spacer/>
                  <Input 
                    label="Tag (Pick one of the tags in the landing page)"
                    value={landingPubObjs[`${item.username}`]["tag"] ? landingPubObjs[`${item.username}`]["tag"] : ""}
                    onChangeText={(newTag) => {
                      // Deep copy
                      let newLandingPubObjs = JSON.parse(JSON.stringify(landingPubObjs));
                      newLandingPubObjs[`${item.username}`]["tag"] = newTag;
                      setLandingPubObjs(newLandingPubObjs);
                    }}
                    autoCapitalize="none"
                    autoCorrect={false}
                    maxLength={200}
                  />
                  <Spacer/>
                  <Input 
                    label="Description"
                    value={landingPubObjs[`${item.username}`]["description"] ? landingPubObjs[`${item.username}`]["description"] : ""}
                    onChangeText={(newDescription) => {
                      // Deep copy
                      let newLandingPubObjs = JSON.parse(JSON.stringify(landingPubObjs));
                      newLandingPubObjs[`${item.username}`]["description"] = newDescription;
                      setLandingPubObjs(newLandingPubObjs);
                    }}
                    autoCapitalize="none"
                    autoCorrect={false}
                    maxLength={2000}
                  />
                  <Spacer/>
                  {/*
                  <Input 
                    label="Image"
                    value={landingPubObjs[`${item.username}`]["img"] ? landingPubObjs[`${item.username}`]["img"] : (item.defaultImage && item.defaultImage !== "") ? item.defaultImage : ""}
                    onChangeText={(newImage) => {
                      // Deep copy
                      let newLandingPubObjs = JSON.parse(JSON.stringify(landingPubObjs));
                      newLandingPubObjs[`${item.username}`]["img"] = newImage;
                      setLandingPubObjs(newLandingPubObjs);
                    }}
                    autoCapitalize="none"
                    autoCorrect={false}
                    maxLength={500}
                  />
                  */}
                  <View>
                  <FormField
                    fieldKey="img"
                    fieldsMeta={{img: { name: "Grid Image (Url)", value: landingPubObjs[`${item.username}`]["img"] ? landingPubObjs[`${item.username}`]["img"] : "", error: "", required: false, type: FormFieldTypesEnum.IMAGEUPLOAD, trim: true }}}
                    setFieldsMeta={(newObj) => {
                      let newLandingPubObjs = JSON.parse(JSON.stringify(landingPubObjs));
                      newLandingPubObjs[`${item.username}`]["img"] = newObj.img.value;
                      setLandingPubObjs(newLandingPubObjs);
                    }}
                    type={FormFieldTypesEnum.IMAGEUPLOAD}
                    navOptions={false}
                    label={true}
                    validate={fieldsMetaValidate}
                  />
                  {fieldsMeta['headerImage'].error !== "" ? <Text style={styles.errorMessage}>{fieldsMeta['headerImage'].error}</Text> : null}
            </View>
                  <Spacer/>
                  <Input 
                    label="URL (clicking this tile opens this url)"
                    value={landingPubObjs[`${item.username}`]["uri"] ? landingPubObjs[`${item.username}`]["uri"] : (item.domains && item.domains.length > 0) ? item.domains[0] : ""}
                    onChangeText={(newUri) => {
                      // Deep copy
                      let newLandingPubObjs = JSON.parse(JSON.stringify(landingPubObjs));
                      newLandingPubObjs[`${item.username}`]["uri"] = newUri;
                      setLandingPubObjs(newLandingPubObjs);
                    }}
                    autoCapitalize="none"
                    autoCorrect={false}
                    maxLength={200}
                  />
                  <Spacer/>
                </View>
                : null}
            </>
          );
        }}
        />
        <Spacer>
            <View>
                <View style={{flexDirection: 'row'}}>
                  <Text style={styles.headerStyle}>Tags (This creates the rows of the page):</Text>
                  {(errorMessageBtm === 'Missing required fields' && tags === "") ?
                    <Text style={{color: 'red', paddingLeft: 5, fontWeight: '800', fontSize: 22, marginTop: 15}}>*</Text>
                  : null}
                </View>
                <Input 
                    label="Tags (Comma Separated)"
                    value={tags}
                    onChangeText={(newTags) => setTags(newTags)}
                    autoCapitalize="none"
                    autoCorrect={false}
                    maxLength={1000}
                />
                {/*<FormField
                    fieldKey="tags"
                    fieldsMeta={fieldsMeta}
                    updateFieldsMeta={updateFieldsMeta}
                    type={fieldsMeta['tags'].type}
                />
                {fieldsMeta['tags'].error !== "" ? <Text style={styles.errorMessage}>{fieldsMeta['tags'].error}</Text> : null}
                */}
            </View>
        </Spacer>
        <Spacer>
            <View>
                <FormField
                    fieldKey="tagBarColor"
                    fieldsMeta={fieldsMeta}
                    setFieldsMeta={setFieldsMeta}
                    type={fieldsMeta['tagBarColor'].type}
                    navOptions={false}
                    label={true}
                    validate={fieldsMetaValidate}
                />
                {fieldsMeta['tagBarColor'].error !== "" ? <Text style={styles.errorMessage}>{fieldsMeta['tagBarColor'].error}</Text> : null}
            </View>
        </Spacer>
        <Spacer>
            <View>
                <FormField
                    fieldKey="headerImage"
                    fieldsMeta={fieldsMeta}
                    setFieldsMeta={setFieldsMeta}
                    type={fieldsMeta['headerImage'].type}
                    navOptions={false}
                    label={true}
                    validate={fieldsMetaValidate}
                />
                {fieldsMeta['headerImage'].error !== "" ? <Text style={styles.errorMessage}>{fieldsMeta['headerImage'].error}</Text> : null}
            </View>
        </Spacer>
        {errorMessageBtm ? <Text style={styles.errorMessage}>{errorMessageBtm}</Text> : null}
        <Spacer>
            <View style={{justifyContent: 'center', alignItems: 'center'}}>
                <Button 
                    title={"Submit"}
                    style={{width: Math.min(Dimensions.get('window').width-60, 200)}}
                    onPress={async () => {
                        setErrorMsg('');
                        setSuccessMsg('');
                        setErrorMsgBtm('');
                        if (!curLandingPages) {
                            setErrorMsgBtm('Service is down please try again later');
                            return;  
                        }
                        if (tags === "" || domains === "" || (landingPubObjs.length === 0 || landingPublications.length === 0)) {
                          setErrorMsgBtm('Missing required fields');
                          return;
                        }
                        if (!curLandingPages) {
                          setErrorMsgBtm('Service is down please try again later');
                          return;  
                        }
                        Object.keys(fieldsMeta).forEach(fieldMetaKey => {
                        validateFormField({setter: setFieldsMeta, original: fieldsMeta, key: fieldMetaKey, validations: fieldsMetaValidate});
                        });
                        if (valErrorExist(fieldsMeta)) {
                            setErrorMsg('Errors In The Form. Please Correct All Errors Before Submitting.');
                            return;
                        }
                        let payload = {};
                        let newLandingPages = JSON.parse(JSON.stringify(curLandingPages));
                        const domainsArr = domains.split(',').map(x => x.toLocaleLowerCase());
                        const tagsArr = tags.split(',');
                        let landingPubs = [];
                        let publicationErr = false;
                        landingPublications.forEach(landingPub => {
                        if (landingPubObjs[`${landingPub}`]) {
                            if (!landingPubObjs[`${landingPub}`].displayName || !landingPubObjs[`${landingPub}`].tag || 
                            !landingPubObjs[`${landingPub}`].description || !landingPubObjs[`${landingPub}`].img || !landingPubObjs[`${landingPub}`].uri) {
                              setErrorMsgBtm('Missing required fields for one of your publications');
                              publicationErr = true;
                            }
                            landingPubs.push(landingPubObjs[`${landingPub}`]);
                        }
                        });
                        if (publicationErr) {
                          return;
                        }
                        payload["tags"] = tagsArr;
                        payload["tagBarColor"] = fieldsMeta['tagBarColor'].value;
                        payload["headerImage"] = fieldsMeta['headerImage'].value;
                        payload["domains"] = domainsArr;
                        payload["publications"] = landingPubs;
                        if (landDomain) {
                            let index = newLandingPages.findIndex(x => x.domains.includes(landDomain));
                            if (index > -1) {
                              newLandingPages.splice(index, 1);
                            }
                        }
                        newLandingPages.push(payload);
                        setPGLoad(true)
                        try {
                            await MeSearchAPI.patch(
                                '/api/pub-owner', 
                                { payload: {landingPage: newLandingPages},
                                pubOwner
                            });
                            setSuccessMsg(landDomain ? "Successfully Pushed Landing Page Edits" : "Successfully Added New Landing Page");
                        } catch (err) {
                        setErrorMsg(`${err.response ? err.response.data.error : 'An error occured please try again later'}`);
                        }
                        setPGLoad(false);
                    }}
                />
            </View>
        </Spacer>
      <CopyrightFooter />
    </>
    : <ActivityIndicator size="large" style={{ marginTop: 150 }} color="#005157"/> }
    </ScrollView>
  );
};

const styles = StyleSheet.create({
    containerSmall: {
      //flex: 1
    },
    containerLarge: {
      width: Dimensions.get('window').width,
      paddingLeft: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
      paddingRight: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null
    },
    errorMessage: {
      fontSize: 18,
      color: 'red',
      marginTop: 15,
      marginLeft: 15
    },
    successMessage: {
      fontSize: 18,
      color: 'green',
      marginTop: 15,
      marginLeft: 15
    },
    image: {
      width: null,
      height: null,
      marginTop: 5,
      marginBottom: 5,
      resizeMode: 'contain',
      flex: 1
    },
    headerStyle: {
    fontSize: 22,
    fontWeight: '300',
    margin: 10
    },
});

export default CreateLandingPageScreen;