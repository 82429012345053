import React, { useContext, useState, useEffect } from 'react';
import  { Text, Button, Input, CheckBox, Icon } from 'react-native-elements';
import {Picker, TextInput, StyleSheet, Image, FlatList, Dimensions, View, Switch, ScrollView, SafeAreaView, ImageBackground, TouchableOpacity } from 'react-native';
import Spacer from '../Space';
import { Feather, MaterialIcons, FontAwesome } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { navigate } from '../../navigationRef';
import { Platform } from 'react-native';
import Swipeable from 'react-native-gesture-handler/Swipeable';

    const profileInfo = ({ navigation, Accountid, setShowSearch, ShowSearch, activeAcct, Portfolioid, Forumid, viewRS, setViewRS
        }) => {
        
            const [isEnabled, setIsEnabled] = useState(false);
            const toggleSwitch = () => setIsEnabled(previousState => !previousState);

            const LeftItem = () => {
                return (
                  <View style={[styles.leftItem, {paddingTop:30, alignItems:'center'}]}>
                    <View style={{flexDirection:'row', justifyContent:'center', paddingBottom:10}}>
                        <Text style={{color:'white',fontWeight:'bold', fontSize:20,}}>Post Classified</Text>
                    </View>
                    <ScrollView>
                    <View style={{flexDirection:'row', alignItems:'center', paddingBottom:10}}>
                        <MaterialIcons name="cloud-upload" size={40} color="white" />
                        <Text style={{color:'white',}}>    Upload Media (.jpg, .mp4)</Text>
                    </View>
                    
                    <View style={{ justifyContent: 'center', alignItems: 'center',}}>
                    <View>
                            <Input 
                            label="Headline: (Required)"
                            labelStyle={{color:'white',fontSize:20, marginBottom:0}}
                            value=""
                            placeholder="Title"
                            autoCapitalize="none"
                            containerStyle={{ width:300,}}
                            inputStyle={{color:'white'}}
                            inputContainerStyle={{color:'silver'}}
                            //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                            autoCorrect={false}
                            maxLength={600}
                            rightIcon={
                                <Icon
                                name='edit'
                                type='MaterialIcons'
                                size={24}
                                color='white'
                                />
                            }
                            />
                            <Input 
                            label="Author: (Required)"
                            labelStyle={{color:'white',fontSize:20, marginBottom:0}}
                            value=""
                            placeholder="Title"
                            autoCapitalize="none"
                            containerStyle={{ width:300,}}
                            inputStyle={{color:'white'}}
                            inputContainerStyle={{color:'silver'}}
                            //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                            autoCorrect={false}
                            maxLength={600}
                            rightIcon={
                                <Icon
                                name='edit'
                                type='MaterialIcons'
                                size={24}
                                color='white'
                                />
                            }
                            />
                            <Input 
                            label="Story: (Required)"
                            labelStyle={{color:'white',fontSize:20, marginBottom:0}}
                            value=""
                            placeholder="Your Story"
                            autoCapitalize="none"
                            containerStyle={{ width:300,}}
                            inputStyle={{color:'white'}}
                            inputContainerStyle={{color:'silver'}}
                            //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                            autoCorrect={false}
                            maxLength={600}
                            rightIcon={
                                <Icon
                                name='edit'
                                type='MaterialIcons'
                                size={24}
                                color='white'
                                />
                            }
                            />
                        </View>
                    </View>
                    <View style={{flexDirection:'row', alignItems:'center', paddingBottom:10}}>
                        <MaterialIcons name="cloud-upload" size={40} color="white" />
                        <Text style={{color:'white',}}>    Upload Document or type above</Text>
                    </View>
                    <Text>Suggested Categories:</Text>
                        <View style={{flexDirection:'column'}}>
                            <CheckBox
                                containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                                checkedColor='white' size={20} textStyle={{color: 'white'}} title='Events'
                                onPress={() => {
                                    alert('Yes');
                                }}
                                checked={true}
                            />
                            <CheckBox
                                containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                                checkedColor='white' size={20} textStyle={{color: 'white'}} title='Gateway'
                                onPress={() => {
                                    alert('Yes');
                                }}
                                checked={true}
                            />
                            <CheckBox
                                containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                                checkedColor='white' size={20} textStyle={{color: 'white'}} title='Food & Drink'
                                onPress={() => {
                                    alert('Yes');
                                }}
                                checked={true}
                            />
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-evenly'}}>
                        <Button  
                            buttonStyle={{height:50, marginTop:10, }}
                            title='Preview' />    
                        <Button  
                            buttonStyle={{height:50, marginTop:10, backgroundColor:'green' }}
                            title='Publish' />
                        </View>
                    </ScrollView>
                  </View>
                );
              };
          
              const RightItem = () => {
                return (
                    <View style={[styles.rightItem, {paddingTop:30, alignItems:'center'}]}>
                    <View style={{flexDirection:'row', justifyContent:'center', paddingBottom:10}}>
                        <Text style={{color:'black',fontWeight:'bold', fontSize:20,}}>Post Ad</Text>
                    </View>
                    <ScrollView>
                    <View style={{flexDirection:'row', alignItems:'center', paddingBottom:10}}>
                        <MaterialIcons name="cloud-upload" size={40} color="black" />
                        <Text>    Upload Media (.jpg, .mp4)</Text>
                    </View>
                    
                    <View style={{ justifyContent: 'center', alignItems: 'center',}}>
                    <View>
                            <Input 
                            label="Headline: (Required)"
                            labelStyle={{color:'black',fontSize:20, marginBottom:0}}
                            value=""
                            placeholder="Title"
                            autoCapitalize="none"
                            containerStyle={{ width:300,}}
                            inputStyle={{color:'black'}}
                            inputContainerStyle={{color:'silver'}}
                            //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                            autoCorrect={false}
                            maxLength={600}
                            rightIcon={
                                <Icon
                                name='edit'
                                type='MaterialIcons'
                                size={24}
                                color='white'
                                />
                            }
                            />
                            <Input 
                            label="Author: (Required)"
                            labelStyle={{color:'black',fontSize:20, marginBottom:0}}
                            value=""
                            placeholder="Title"
                            autoCapitalize="none"
                            containerStyle={{ width:300,}}
                            inputStyle={{color:'black'}}
                            inputContainerStyle={{color:'silver'}}
                            //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                            autoCorrect={false}
                            maxLength={600}
                            rightIcon={
                                <Icon
                                name='edit'
                                type='MaterialIcons'
                                size={24}
                                color='white'
                                />
                            }
                            />
                            <Input 
                            label="Story: (Required)"
                            labelStyle={{color:'black',fontSize:20, marginBottom:0}}
                            value=""
                            placeholder="Your Story"
                            autoCapitalize="none"
                            containerStyle={{ width:300,}}
                            inputStyle={{color:'black'}}
                            inputContainerStyle={{color:'silver'}}
                            //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                            autoCorrect={false}
                            maxLength={600}
                            rightIcon={
                                <Icon
                                name='edit'
                                type='MaterialIcons'
                                size={24}
                                color='white'
                                />
                            }
                            />
                        </View>
                    </View>
                    <View style={{flexDirection:'row', alignItems:'center', paddingBottom:10}}>
                        <MaterialIcons name="cloud-upload" size={40} color="black" />
                        <Text>    Upload Document or type above</Text>
                    </View>
                    <Text>Suggested Categories:</Text>
                        <View style={{flexDirection:'column'}}>
                            <CheckBox
                                containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                                checkedColor='black' size={20} textStyle={{color: 'black'}} title='Events'
                                onPress={() => {
                                    alert('Yes');
                                }}
                                checked={true}
                            />
                            <CheckBox
                                containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                                checkedColor='black' size={20} textStyle={{color: 'black'}} title='Gateway'
                                onPress={() => {
                                    alert('Yes');
                                }}
                                checked={true}
                            />
                            <CheckBox
                                containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                                checkedColor='black' size={20} textStyle={{color: 'black'}} title='Food & Drink'
                                onPress={() => {
                                    alert('Yes');
                                }}
                                checked={true}
                            />
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-evenly'}}>
                        <Button  
                            buttonStyle={{height:50, marginTop:10, }}
                            title='Preview' />    
                        <Button  
                            buttonStyle={{height:50, marginTop:10, backgroundColor:'green' }}
                            title='Publish' />
                        </View>
                    </ScrollView>
                  </View>
                );
              };

    // Render use JSX syntax
    return (
        <View style={{backgroundColor:'#eeeee0', height:Dimensions.get('window').height}}>
            <Swipeable
            onSwipeableLeftOpen={() => console.log('Swiped Left Sets')}
            onSwipeableRightOpen={() => console.log('Swiped right Sets')}
            renderLeftActions={(progress, dragx) => <LeftItem />}
            renderRightActions={(progress, dragx) => <RightItem />}
            >
                <Spacer/>
                <View style={{flexDirection:'row', justifyContent:'center', paddingBottom:10}}>
                    <Text style={{color:'black',fontWeight:'bold', fontSize:20,}}>Post Article</Text>
                </View>

                <ScrollView>
                <View style={{flexDirection:'row', alignItems:'center', paddingBottom:10}}>
                    <MaterialIcons name="cloud-upload" size={40} color="black" />
                    <Text>    Upload Media (.jpg, .mp4)</Text>
                </View>
                
                <View style={{ justifyContent: 'center', alignItems: 'center',}}>
                <View>
                        <Input 
                        label="Headline: (Required)"
                        labelStyle={{color:'black',fontSize:20, marginBottom:0}}
                        value="Family photo"
                        placeholder="Title"
                        autoCapitalize="none"
                        containerStyle={{ width:300,}}
                        inputStyle={{color:'black'}}
                        inputContainerStyle={{color:'silver'}}
                        //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                        autoCorrect={false}
                        maxLength={600}
                        rightIcon={
                            <Icon
                            name='edit'
                            type='MaterialIcons'
                            size={24}
                            color='white'
                            />
                        }
                        />
                        <Input 
                        label="Author: (Required)"
                        labelStyle={{color:'black',fontSize:20, marginBottom:0}}
                        value="K-Andre"
                        placeholder="Title"
                        autoCapitalize="none"
                        containerStyle={{ width:300,}}
                        inputStyle={{color:'black'}}
                        inputContainerStyle={{color:'silver'}}
                        //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                        autoCorrect={false}
                        maxLength={600}
                        rightIcon={
                            <Icon
                            name='edit'
                            type='MaterialIcons'
                            size={24}
                            color='white'
                            />
                        }
                        />
                        <Input 
                        label="Story: (Required)"
                        labelStyle={{color:'black',fontSize:20, marginBottom:0}}
                        value=""
                        placeholder="Your Story"
                        autoCapitalize="none"
                        containerStyle={{ width:300,}}
                        inputStyle={{color:'black'}}
                        inputContainerStyle={{color:'silver'}}
                        //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                        autoCorrect={false}
                        maxLength={600}
                        rightIcon={
                            <Icon
                            name='edit'
                            type='MaterialIcons'
                            size={24}
                            color='white'
                            />
                        }
                        />
                    </View>
                </View>
                <View style={{flexDirection:'row', alignItems:'center', paddingBottom:10}}>
                    <MaterialIcons name="cloud-upload" size={40} color="black" />
                    <Text>    Upload Document or type above</Text>
                </View>
                <Text>Suggested Categories:</Text>
                    <View style={{flexDirection:'column'}}>
                        <CheckBox
                            containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                            checkedColor='black' size={20} textStyle={{color: 'black'}} title='Events'
                            onPress={() => {
                                alert('Yes');
                            }}
                            checked={true}
                        />
                        <CheckBox
                            containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                            checkedColor='black' size={20} textStyle={{color: 'black'}} title='Gateway'
                            onPress={() => {
                                alert('Yes');
                            }}
                            checked={true}
                        />
                        <CheckBox
                            containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                            checkedColor='black' size={20} textStyle={{color: 'black'}} title='Food & Drink'
                            onPress={() => {
                                alert('Yes');
                            }}
                            checked={true}
                        />
                    </View>
                    <View style={{flexDirection:'row', justifyContent:'space-evenly'}}>
                    <Button  
                        buttonStyle={{height:50, marginTop:10, }}
                        title='Preview' />    
                    <Button  
                        buttonStyle={{height:50, marginTop:10, backgroundColor:'green' }}
                        title='Publish' />
                    </View>
                </ScrollView>
            </Swipeable>
        </View>
    )
  };

  // Styles
const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    containerSmall: {
      flex: 1,
      justifyContent: 'center',
      paddingBottom: 50,
    },
    containerLarge: {
      width: Dimensions.get('window').width,
      paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
      paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
      justifyContent: 'center',
      paddingBottom: 150,
      marginTop:20,
    },
        leftItem: {
        flex:1,
        backgroundColor: '#3385E5',
        },
    leftItemText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#fff',
        },
    rightItem: {
        flex: 1,
        backgroundColor: 'red',
        },
    deleteButtonStyle: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        backgroundColor: 'black',
        },

  });

  export default profileInfo;