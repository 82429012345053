import React, {useState, useEffect} from 'react';
import {View, SafeAreaView, Dimensions} from 'react-native';
import FormDetail from '../../core/FormDetail';
import FormField from '../../formField';

const FormWrapper = ({ Component, values, validation, navigation, route, step, setStep, index, setIndex, methods, userExists, account, create }) => {
  const [editingField, setEditingField] = useState('');
  const [fieldsMeta, setFieldsMeta] = useState(values);
  // const [fieldsValidation, setFieldsValidation] = useState(validation);


  return (
    <SafeAreaView style={{justifyContent: 'center', alignItems: 'center'}}>
      <View style={{width: Math.min(Dimensions.get('window').width-20, 500)}}>
        {
          editingField ? (
            <View style={{paddingTop: 10}}>
              <FormField 
                fieldKey={editingField}
                fieldsMeta={fieldsMeta}
                setFieldsMeta={setFieldsMeta}
                setEditingField={setEditingField}
                validate={validation}
                type={fieldsMeta[editingField].type}
                navigation={navigation}
                index={index}
                methods={methods}
                userExists={userExists}
              />
            </View>
          ) : (
            <Component 
              fieldsMeta={fieldsMeta}
              setFieldsMeta={setFieldsMeta}
              setEditingField={setEditingField}
              setIndex={setIndex}
              validate={validation}
              navigation={navigation}
              route={route}
              step={step}
              setStep={setStep}
              create={create}
              userExists={userExists}
              account={account}
            />
          )
        }
      </View>
    </SafeAreaView>
  );
};

export default FormWrapper;

// <View style={{width: (Platform.OS === 'web' && !isMobile) ? 700 : '90%', paddingTop: 10}}>}
// {/* <FormDetail
  //   editingField={editingField}
  //   setEditingField={setEditingField}
  //   validate={validation}
  //   inputType={fieldsMeta[editingField].type}
  //   value={fieldsMeta[editingField].value}
  //   fieldsMeta={fieldsMeta}
  //   setFieldsMeta={setFieldsMeta}
  //   navigation={navigation}
  // /> */}
// {/*</View>*/}