import React, { useContext, useState } from 'react';
import { Text, View, Image, StyleSheet, TouchableOpacity, Dimensions, ImageBackground } from 'react-native';
import ContentTypeEnum from '../utils/contentTypes';
import removeSpecialCharacters from '../../screens/utils/removeSpecialCharacters';
import { Context as PubContext } from '../../context/PubContext';
import isVideo from '../../screens/utils/isVideo';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ContentTypesEnum from '../utils/contentTypes';

dayjs.extend(relativeTime);

const httpPrefixToHttps = new RegExp("http:\/\/ttm-triblive-development.us-east-2.elasticbeanstalk.com", "g");

const determineStampImage = (type) => {
  if (type === ContentTypeEnum.ADVERTISEMENT || type === ContentTypeEnum.ADVERTISEMENTFRAMEVIEW || type === ContentTypeEnum.ADVERTISEMENTLINKVIEW) {
    return require('../../../assets/ad_content.png');
  }
  if (type === ContentTypeEnum.PRODUCT || type === ContentTypeEnum.PRODUCTFRAMEVIEW || type === ContentTypeEnum.PRODUCTLINKVIEW) {
    return require('../../../assets/product_content.png');
  }
  if (type === ContentTypeEnum.EVENT || type === ContentTypeEnum.EVENTFRAMEVIEW || type === ContentTypeEnum.EVENTLINKVIEW) {
    return require('../../../assets/events_content.png');
  }
  if (type === ContentTypeEnum.SPONSORED || type === ContentTypeEnum.SPONSOREDFRAMEVIEW || type === ContentTypeEnum.SPONSOREDLINKVIEW) {
    return require('../../../assets/sponsored_content.png');
  }
};

const determineTitlePrefix = (item, PubCon, small) => {
    let displayTitle = ""
    if (PubCon.state && PubCon.state.publisher) {
      if ((PubCon.state.publisher.sources.includes(item.source) && (PubCon.state.publisher.owner !== 'MeSearchAI' || item.source.includes('-UGC'))) || item.meTags.includes("Offers")) {
        displayTitle = removeSpecialCharacters(item.body[0].title);
      } else {
        displayTitle = `\"${removeSpecialCharacters(item.body[0].title)}\" ${item.source} Reports`;
      }
    } else {
      displayTitle = removeSpecialCharacters(item.body[0].title);
    }
    return !small 
      ? displayTitle.length > 80 ? `${displayTitle.substring(0, 80)}...`: displayTitle
      : displayTitle.length > 45 ? `${displayTitle.substring(0, 45)}...`: displayTitle;
}

const determineTimeMarker = (contentDate) => {
  let time = "";
  try {
    time = dayjs(contentDate).fromNow();
  } catch (error) {
    console.log('error displaying time', error)
  }
  return time;
};

const LargerResult = ({ title, tags=null, image, source, type, PubCon, result, determineThumbnail, defaultImage, darkMode }) => {
  const [err, setErr] = useState(false);
  return (
    <View style={styles.largeContainer}>
      <View style={styles.photoSection}>
        <ImageBackground 
          source={{ uri: !err ? determineThumbnail(result.body[0].media.length > 0 ? result.body[0].media : PubCon.state.publisher.badgeSourceMap[result.source]) : defaultImage }}
          style={styles.image}
          imageStyle={styles.image}
          onError={() => {
            setErr(true);
          }}
        >
          {type !== null && type !== "Article"?
          <View style={styles.typeBadgeContainer}>
            <Image
                source={determineStampImage(type)}
                style={styles.typeImage}
            />
          </View>
          : null}
        </ImageBackground>
      </View>
      <View style={styles.lgSectionA}>
          <Text style={darkMode ? [styles.title, styles.darkModeText] : styles.title}>{determineTitlePrefix(result, PubCon, false)}</Text>
      </View>
      <View style={styles.lgSectionB}>
        <View style={styles.leftSide}>
          {
            tags ? <Text style={darkMode ? [styles.tags, styles.darkModeText] : styles.tags}>{tags.slice(0,2).join(', ')}</Text> : null
          }
          {
            type === ContentTypesEnum.ARTICLE || type === ContentTypeEnum.ARTICLEFRAMEVIEW || type === ContentTypeEnum.ARTICLELINKVIEW || type === null ? <Text style={darkMode ? [styles.time, styles.darkModeText] : styles.time}>{determineTimeMarker(result.contentDate, type)}</Text> : null
          }
        </View>
        <Text style={darkMode ? [styles.source, styles.darkModeText] : styles.source}>{source}</Text>
      </View>
    </View>
  );
};

const SingleFeedResult = ({ result, index, darkMode, defImage=true }) => {
  // title, tags=null, image, source, link, index
  const PubCon = useContext(PubContext);
  const title = result.body[0].title;
  const meTags = result.meTags;
  const imageUri = result.mediaMeta[0].uri;
  const source = result.source;
  const link = result.body[0].link;
  const type = result.type;
  const [err, setErr] = useState(false);
  const defaultImage = defImage ? PubCon.state.publisher.badgeSourceMap[result.source] : "";
  const determineThumbnail = (media, defaultImage) => {
    if (isVideo(media[0])) {
      let thumbnailImage = "";
      media.some((mediaObj) => {
        if (!isVideo(mediaObj)) {
          thumbnailImage = mediaObj;
          return true;
        }
      });
      if (thumbnailImage !== "") {
        return thumbnailImage.replace(httpPrefixToHttps, "https:\/\/triblive.com") === "https://triblive.com/wp-content/themes/TribLIVE/assets/visuals/images/icons/head/TribLIVELogo.jpg" ? defaultImage : thumbnailImage.replace(httpPrefixToHttps, "https:\/\/triblive.com");
      }
      return defaultImage;
    } else {
      return media[0].replace(httpPrefixToHttps, "https:\/\/triblive.com") === "https://triblive.com/wp-content/themes/TribLIVE/assets/visuals/images/icons/head/TribLIVELogo.jpg" ? defaultImage : media[0].replace(httpPrefixToHttps, "https:\/\/triblive.com");
    }
  }

  const regular = (
    <View style={styles.container}>
      <View style={styles.sectionA}>
        <View style={styles.titleAndTags}>
          <Text numberOfLines={2} style={darkMode ? [styles.title, styles.darkModeText] : styles.title}>{determineTitlePrefix(result, PubCon, true)}</Text>
        </View>
        <View style={styles.imageContainer}>
        <ImageBackground 
          source={{ uri: !err ? determineThumbnail(result.body[0].media.length > 0 ? result.body[0].media : PubCon.state.publisher.badgeSourceMap[result.source]) : defaultImage }}
          imageStyle={styles.image}
          style={styles.image}
          onError={() => {
            setErr(true);
          }}
        >
          {type !== null && type !== "Article" ?
          <View style={styles.typeBadgeContainerSmall}>
            <Image
                source={determineStampImage(result.type)}
                style={styles.typeImageSmall}
            />
          </View>
          : null}
        </ImageBackground>
        </View>
      </View>
      <View style={styles.sectionB}>
        <View style={styles.leftSide}>
          {
            meTags ? <Text style={darkMode ? [styles.tags, styles.darkModeText] : styles.tags}>{meTags.slice(0,2).join(', ')}</Text> : null
          }
          {
            type === ContentTypesEnum.ARTICLE || type === ContentTypeEnum.ARTICLEFRAMEVIEW || type === ContentTypeEnum.ARTICLELINKVIEW || type === null ? <Text style={darkMode ? [styles.time, styles.darkModeText] : styles.time}>{determineTimeMarker(result.contentDate, type)}</Text> : null
          }
        </View>
        <Text style={darkMode ? [styles.source, styles.darkModeText] : styles.source}>{source}</Text>
      </View>
    </View>
  );

  const large = (
    <LargerResult title={title} tags={meTags} image={imageUri} source={source} type={type} PubCon={PubCon} result={result} determineThumbnail={determineThumbnail} defaultImage={defaultImage} darkMode={darkMode} />
  );

  return (
    <View style={styles.parentContainer}>
      <View
        style={styles.clickable}
      >
        {
          index % 3 === 0 ? large : regular
        }
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  clickable: {
    alignItems: 'center',
    width: 350,
    marginBottom: 7,
  },
  parentContainer: {
    alignItems: 'center',
  },
  container: {
    flexDirection: 'column',
    width: 350,
    height: 100,
    marginVertical: 5,
    padding: 5,
    paddingBottom: 10,
    borderBottomColor: 'rgba(17, 78, 232, 0.6)',
    borderBottomWidth: 5,
    borderBottomStartRadius: 10,
    borderBottomEndRadius: 10,
  },
  largeContainer: {
    flexDirection: 'column',
    width: 350,
    height: 300,
    marginVertical: 5,
    padding: 5,
    //paddingBottom: 10,
    borderBottomColor: 'rgba(17, 78, 232, 0.6)',
    borderBottomWidth: 5,
    borderBottomStartRadius: 10,
    borderBottomEndRadius: 10,
    justifyContent: 'space-around'
  },
  lgSectionA: {
    flexDirection: 'row',
  },
  lgSectionB: {
    flexDirection: 'row',
    height: 20,
    width: 340,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sectionA: {
    margin: 5,
    flexDirection: 'row',
    //justifyContent: 'flex-start',
    //height: 120,
  },
  sectionB: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleAndTags: {
    flexDirection: 'column',
    width: '70%',
    marginLeft: -5
  },
  imageContainer: {
    width: 100,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5
  },
  photoSection: {
    // height: '90%',
    height: 200,
    width: 340,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    fontWeight: '400',
    paddingBottom: 5,
  },
  tags: {
    fontSize: 10,
    textTransform: 'uppercase',
    fontWeight: '200'
  },
  image: {
    resizeMode: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: 5,
  },
  metadata: {
    //alignItems: 'flex-end',
  },
  source: {
    fontSize: 12,
    fontWeight: '200',
    letterSpacing: 0.4,
  },
  typeImage: {
    height: 25,
    width: 85,
    opacity: 0.8,
    position: 'absolute',
    top: 0,
    right: 1,
    marginLeft: 2
  },
  typeImageSmall: {
    height: 18,
    width: 55,
    opacity: 0.8,
    position: 'absolute',
    top: 0,
    right: 1,
    marginLeft: 4
  },
  typeBadgeContainer: {
    height: 30, 
    width: 90, 
    position: 'absolute', 
    top: 0, 
    right: 1,
    backgroundColor: '#000000', 
    opacity: 0.9
  },
  typeBadgeContainerSmall: {
    height: 22,
    width: 58,
    position: 'absolute', 
    top: 0, 
    right: 1,
    backgroundColor: '#000000', 
    opacity: 0.9
  },
  time: {
    fontSize: 10,
    fontWeight: '200',
    paddingLeft: 5
  },
  leftSide: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  darkModeText: {
    color: 'rgb(255, 255, 255)',
  },
});

export default React.memo(SingleFeedResult);