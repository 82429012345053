import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
//import SigninScreen from '../../screens/SigninScreen';
import SignupScreen from '../../screens/SignupScreen';
import SigninScreen from '../../screens/SigninScreen-NEW';
import UserSignupScreen from '../../screens/UserSignupScreen';
import WelcomeScreen from '../../screens/WelcomeScreen';
import PassportScreen from '../../screens/PassportScreen';
import TosPrivacyScreen from '../../screens/TosPrivacyScreen';
import BusinessSignupScreen from '../../screens/BusinessSignupScreen';
import SignupChoiceScreen from '../../screens/SignupChoiceScreen';

const Stack = createStackNavigator();

const authFlow = () => {
  return (
    <Stack.Navigator
      initialRouteName="Signin"
      screenOptions={{ 
        gestureEnabled: true,
        title: "MeSearch"
      }}
    >
      <Stack.Screen
        name="Signin"
        component={SigninScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen 
        //name="MemberSignup"
        name="Signup"
        component={UserSignupScreen}
      />
      <Stack.Screen 
        name="BusinessSignup"
        component={BusinessSignupScreen}
      />
      <Stack.Screen 
        name="SignupChoice"
        component={SignupChoiceScreen}
      />
      {/*<Stack.Screen
        name="Signup"
        component={SignupScreen}
        options={{headerShown: false}}
      />*/}
      <Stack.Screen
        name="Welcome"
        component={WelcomeScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen 
        name="Passport"
        component={PassportScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen 
        name="TosPrivacy"
        component={TosPrivacyScreen}
      />
    </Stack.Navigator>
  );
};

export default authFlow;