import React, { useState } from 'react';
import { View, Text, StyleSheet, FlatList, Button, TouchableOpacity } from 'react-native';

const Page = ({ setSearchScreenPage, page, title, darkMode, setUserSpecifiedTag }) => {
  return (
    <TouchableOpacity onPress={() => {
      setSearchScreenPage(page)
      setUserSpecifiedTag('');
    }}>
      <Text style={{color: darkMode ? 'white' : 'blue', fontSize: 22}}>{`${title}`}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
    container: {
      marginLeft: 15,
    }
});

export default Page;