import React from 'react';
import { SafeAreaView, Text, StyleSheet, Dimensions, View } from 'react-native';
import { isMobile } from 'react-device-detect';
import { Platform } from 'react-native';
import { Button } from 'react-native-elements';

const Welcome = ({ navigation, route }) => {
  return (
    <SafeAreaView style={(Platform.OS === 'web' && !isMobile) ? styles.containerLarge : styles.containerSmall}>
      <Text style={styles.header}>Welcome</Text>
      <View style={{marginVertical: 10}}>
        <Text style={styles.text}>Help us provide a better service for you.</Text>
        <Text style={styles.promptText}>Do you need a new or additional business account?</Text>
      </View>
      <View style={{justifyContent: 'space-between'}}>
        {/* NAVCONNECT add business signup screen navigation below */}
        <Button 
          title="Create a Business Account"
          type="outline"
          onPress={() => {navigation.navigate('Signup')}}
          buttonStyle={styles.buttonOutline}
          titleStyle={styles.buttonTitleStyleOutline}
        />
        <Button 
          title="Next"
          onPress={() => {navigation.navigate('Passport')}}
          buttonStyle={Platform.OS === 'web' ? styles.button : styles.buttonMobile}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  containerSmall: {
    flex: 1,
    minHeight: 500,
    height: Dimensions.get('window').height,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 10
  },
  containerLarge: {
    width: Dimensions.get('window').width,
    paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
    paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginVertical: 20
  },
  buttonTitleStyleOutline: {
    'color': 'black',
  },
  buttonOutline: {
    borderColor: 'black',
    width: 300,
    marginVertical: 5,
  },
  button: {
    width: 300,
    marginBottom: 5
  },
  buttonMobile: {
    width: 300,
    marginBottom: 5,
  },
  header: {
    fontWeight: "bold",
    fontSize: 40,
  },
  text: {
    fontSize: 16,
    paddingBottom: 20,
  },
  promptText: {
    fontSize: 16,
    fontWeight: "300"
  }
});


export default Welcome;