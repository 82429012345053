import React from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  TouchableOpacity,
  TextInput,
  FlatList,
  ScrollView,
  Platform,
} from "react-native";
import {
  Feather,
  MaterialCommunityIcons,
  Ionicons,
  AntDesign,
  MaterialIcons,
  FontAwesome5,
} from "@expo/vector-icons";

const MenuHomeItem = ({ title, subTitle, iconName, iconFamily, onpress, darkMode }) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.item}
        onPress={() => {
          onpress(title);
        }}
      >
        <View style={styles.innerLeft}>
          {iconFamily === "MaterialCommunityIcons" ? (
            <MaterialCommunityIcons name={iconName} size={30} color={darkMode ? "white" : "black"} />
          ) : iconFamily === "Feather" ? (
            <Feather name={iconName} size={30} color={darkMode ? "white" : "black"}  />
          ) : iconFamily === "MaterialIcons" ? (
            <MaterialIcons name={iconName} size={30} color={darkMode ? "white" : "black"}  />
          ) : iconFamily === "FontAwesome5" ? (
            <FontAwesome5 name={iconName} size={30} color={darkMode ? "white" : "black"}  />
          ) : iconFamily === "AntDesign" ? (
            <AntDesign name={iconName} size={30} color={darkMode ? "white" : "black"}  />
          ) : null}
          <View style={styles.textCtn}>
            <Text style={[styles.title, darkMode ? {color: 'white'}: null]}>{title}</Text>
            <Text style={[styles.subTitle, darkMode ? {color: 'white'}: null]}>{subTitle}</Text>
          </View>
        </View>
        <Feather
          name="chevron-right"
          size={30}
          color={darkMode ? "white" : "#666"}
          style={styles.arrow}
        />
      </TouchableOpacity>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    // borderTopColor: "#999",
    // borderTopWidth: 1,
    borderBottomColor: "#999",
    borderBottomWidth: 1,
  },
  item: {
    paddingVertical: 15,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  innerLeft: {
    flexDirection: "row",
    alignItems: "center",
  },
  textCtn: {
    marginLeft: 15,
  },
  title: {
    fontSize: 15,
    lineHeight: 20,
    fontWeight: "bold",
    color: "#666",
  },
  subTitle: {
    fontSize: 10,
    lineHeight: 10,
    color: "#666",
  },
  arrow: {},
});
export default MenuHomeItem;
