import { useEffect, useState, useContext } from 'react';
import MeSearchAPI from '../api/MeSearch-API';
import ContentTypesEnum from '../components/utils/contentTypes';

export default () => {
  const [results, setResults] = useState([]);
  const [resultsTags, setResultsTags] = useState([]);
  const [nonGreedyResults, setNonGreedyResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentLoadTag, setLoadingTag] = useState('');
  const [headlineResults, setHeadlineResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [numFound, setNumFound] = useState(-1);
  const [userSpecifiedResults, setUserSpecifiedResults] = useState([]);
  const [latestResults, setLatestResults] = useState([]);
  const [fetchNonPub, setFetchNonPub] = useState(true);
  const [fetchNonPubDone, setFetchNonPubDone] = useState(false);
  const [fetchNonArt, setFetchNonArt] = useState(true);
  const [fetchNonArtDone, setFetchNonArtDone] = useState(false);
  const [fetchAds, setFetchAds] = useState(true);

  function getUniqueContent(content) {
 
    const uniqueContent = content
      .map(e => e['_id'])
  
        // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
  
      // eliminate the dead keys & store unique objects
      .filter(e => content[e]).map(e => content[e]);
  
      return uniqueContent;
  }

  function balanceContent(contentObjsOne, contentObjsTwo, ratio) {
    let uniqueContentOne = getUniqueContent(contentObjsOne);
    let uniqueContentTwo = getUniqueContent(contentObjsTwo);
    const totalCount = uniqueContentOne.length;
    const conLimitCount = Math.min((totalCount * ratio), contentObjsTwo.length);
    if (uniqueContentTwo.length > conLimitCount) {
      uniqueContentTwo = uniqueContentTwo.slice(0, conLimitCount);
    }
    uniqueContentOne.push(...uniqueContentTwo);
    uniqueContentOne.sort((a,b) => (a.contentDate < b.contentDate) ? 1 : ((b.contentDate < a.contentDate) ? -1 : 0));
    return uniqueContentOne;
  }

  function filterIntoTags(contentObjs, tags) {
    let tagMatchCount;
    let tagMap = {};
    let conCount = 0;
    let conNotMatched = [];
    let conMatched = [];
    let conMatchedIds = [];
    tags.forEach(tag => {
      tagMatchCount = 0;
      contentObjs.filter(contentObj => {
        return contentObj.meTags.some(contentTag => {
          if (tag === contentTag) {
            if (tagMatchCount <= 1) {
              if (!tagMap[tag]) {
                tagMap[tag] = [contentObj];
              } else {
                tagMap[tag].push(contentObj);
              }
              conMatchedIds.push(contentObj._id);
              conMatched.push(contentObj);
              conCount += 1;
              tagMatchCount += 1;
              return true;
            }
          }
          return false;
        }) ? false : true;
      });
    });
    conNotMatched = contentObjs.filter(content => {
      return !conMatchedIds.includes(content._id);
    });
    return {tagMap, conCount, conNotMatched, conMatched};
  }

  function fyShuffle(array) {
    let objsToShuffle = array.length, tmp, remObj;
    let newArray = JSON.parse(JSON.stringify(array));
    // While objects remain that need shuffled
    while (objsToShuffle) {
  
      // Randomly select a remaining obj
      remObj = Math.floor(Math.random() * objsToShuffle--);
  
      // Swap with the current object
      tmp = newArray[objsToShuffle];
      newArray[objsToShuffle] = newArray[remObj];
      newArray[remObj] = tmp;
    }
    return newArray;
  }

  function assignToTagsAndBalanceContent(contentObjsOne, contentObjsTwo, ratio, tags, ad) {
    let uniqueContentOne = getUniqueContent(contentObjsOne);
    let uniqueContentTwo = getUniqueContent(contentObjsTwo);
    let randTagsTaken = [];
    let {tagMap, conCount, conNotMatched, conMatched} = filterIntoTags(uniqueContentTwo, tags);
    const totalCount = uniqueContentOne.length;
    const contentLimit = Math.min((totalCount * ratio), contentObjsTwo.length);
    let tagKeys = Object.keys(tagMap);
    let tagsToAssign = tags;
    if (tagKeys.length > 0) {
      tagsToAssign = tags.filter(t => !tagKeys.includes(t));
    }
    let count = 0;
    if (conCount < contentLimit) {
      let tagIndex = 0;
      // shuffle the arrays randomly so products don't become stale
      conMatched = fyShuffle(conMatched);
      conNotMatched = fyShuffle(conNotMatched);
      conNotMatched.forEach(conObj => {
        if (count < contentLimit) {
          if (tagIndex >= tagsToAssign.length) {
            tagIndex = 0;
          }
          if (ad) {
            // Randomly select a tag
            let tagRand = Math.abs(Math.floor(Math.random() * tagsToAssign.length-1)+1);
            if (randTagsTaken.includes(tagRand)) {
              tagRand = Math.abs(Math.floor(Math.random() * tagsToAssign.length-1)+1);
            }
            randTagsTaken.push(tagRand);
            conObj.meTags.push(tagsToAssign[tagRand]);
          } else {
            conObj.meTags.push(tagsToAssign[tagIndex]);
          }
          conMatched.push(conObj);
          tagIndex += 1;
          count += 1;
        }
      });
    } else if (conCount > contentLimit) {
      conMatched = conMatched.slice(0, contentLimit);
    }
    uniqueContentOne.push(...conMatched);
    //uniqueContentOne.sort((a,b) => (a.contentDate < b.contentDate) ? 1 : ((b.contentDate < a.contentDate) ? -1 : 0));
    return uniqueContentOne;
  }

  const getContentApi = async (
    searchTags,
    curSearchTag=null,
    page=0,
    headline=false,
    curResults=null,
    userSpecified=false,
    tagless=false,
    mandTag, 
    greedy=true, 
    curNonGreedyResults=null, 
    pub, 
    nonPub=false, 
    limit, 
    startDate,
    types
    ) => {
    if (curResults) {
      setLoading(true);
      setLoadingTag(curSearchTag);
    }
    try {
      const response = !nonPub
          ? await MeSearchAPI.get(
            '/api/content', 
            {params: {
              tags: curSearchTag ? curSearchTag : searchTags,
              page: page,
              tagless: tagless,
              mandatoryTag: mandTag,
              pub: pub,
              types: types
            }
          })
        : await MeSearchAPI.get(
            '/api/content', 
            {params: {
              tags: curSearchTag ? [curSearchTag] : searchTags,
              page: page,
              limit: limit,
              startDate: startDate,
              nonPubSrcs: true,
              pub: pub,
              types: types
            }
          });
      let resObjs = [];
      response.data.forEach(obj => {
        obj.nonPubSrc = nonPub ? true : false;
        resObjs.push(obj);
      });
      let uniqueResObjs = [];
      if (!headline && !tagless) {
        if (curResults) {
          if (nonPub) {
            // Balance content
            uniqueResObjs = balanceContent(curResults, resObjs, 0.5);
          } else {
            uniqueResObjs = getUniqueContent(curResults.concat(resObjs));
          }
        } else {
          uniqueResObjs = resObjs;
        }
        if (!userSpecified) {
          if (greedy) {
            setResults(uniqueResObjs);
            setResultsTags(searchTags);
          } else {
            let uniqueRes = [];
            resObjs.forEach(resObj => {
              let found = false;
              if (curResults) {
                curResults.forEach(curResObj => {
                  if (curResObj._id === resObj._id) {
                    found = true;
                  }
                });
              }
              if (!found) {
                uniqueRes.push(resObj);
              }
            });
            if (curNonGreedyResults) {
              if (nonPub) {
                // Balance content
                setNonGreedyResults(balanceContent(curNonGreedyResults, uniqueRes, 0.5));
              } else {
                setNonGreedyResults(getUniqueContent(curNonGreedyResults.concat(uniqueRes)));
              }
            }
          }
        } else {
          setUserSpecifiedResults(uniqueResObjs);
        }
      } else if (headline) {
        if (nonPub && curResults) {
          // Balance content
          setHeadlineResults(balanceContent(curResults, resObjs));
        } else {
          setHeadlineResults(resObjs);
        }
      } else {
        if (nonPub && curResults) {
          // Balance content
          setLatestResults(balanceContent(curResults, resObjs));
        } else {
          setLatestResults(resObjs);
        }
      }
    } catch (err) {
        setErrorMessage(`${err.response ? err.response.data.error : 'Something went wrong trying to retrieve content'}`);
    };
    if (curResults) {
      setLoading(false);
      setLoadingTag(curSearchTag);
    }
  };

  const getContentFreeSearchApi = async (searchTags, curSearchTag=null, page=0, searchText, curResults=null, userSpecified=false, mandTag, greedy=true, curNonGreedyResults=null, pub) => {
    if (curResults) {
      setLoading(true);
      setLoadingTag(curSearchTag);
    }
    try {
      const response = await MeSearchAPI.get(
        '/api/content/free-search', 
        {params: {
          tags: curSearchTag ? [curSearchTag] : searchTags,
          searchText: searchText,
          page: page,
          pub: pub
          //mandatoryTag: mandTag
        }
      });
      let resObjs = [];
      response.data.content.forEach(obj => {
        // free search results are all treated as native
        obj.nonPubSrc = false;
        resObjs.push(obj);
      });
      setNumFound(response.data.numFound);
      let uniqueResObjs = [];
      if (curResults) {
        uniqueResObjs = getUniqueContent(curResults.concat(resObjs));
      } else {
        uniqueResObjs = resObjs;
      }
      if (!userSpecified) {
        if (greedy) {
          setResults(uniqueResObjs);
          setResultsTags(searchTags);
        } else {
          let uniqueRes = []
          resObjs.forEach(resObj => {
            let found = false;
            if (curResults) {
              curResults.forEach(curResObj => {
                if (curResObj._id === resObj._id) {
                  found = true;
                }
              });
            }
            if (!found) {
              uniqueRes.push(resObj);
            }
          });
          if (curNonGreedyResults) {
            setNonGreedyResults( curNonGreedyResults ? getUniqueContent(curNonGreedyResults.concat(uniqueRes)) : uniqueRes);
          }
        }
      } else {
        setUserSpecifiedResults(uniqueResObjs);
      }
    } catch (err) {
        setErrorMessage(`${err.response ? err.response.data.error : 'Something went wrong trying to retrieve content'}`);
    };
    if (curResults) {
      setLoading(false);
      setLoadingTag(curSearchTag);
    }
  };

  const getNonBaseContentApi = async (startDate, pub, curResults, tags, limit, types, target) => {
    const response = !target
    ? await MeSearchAPI.get(
      '/api/content', 
      {params: {
        startDate: startDate,
        nonBaseSrcs: true,
        pub: pub,
        tags: tags,
        limit: limit,
        types: types
      }
    })
    : await MeSearchAPI.get(
      '/api/content', 
      {params: {
        startDate: startDate,
        adSources: true,
        pub: pub,
        tags: tags,
        limit: limit,
        types: types,
        targeting: target
      }
    });
    let resObjs = [];
    response.data.forEach(obj => {
      if (types && types.includes(ContentTypesEnum.ADVERTISEMENT)) {
        obj.nonPubSrc = true;
      } else {
        obj.nonPubSrc = true;
        obj.nonBaseSrc = true;
      }
      resObjs.push(obj);
    });
    let balancedContent = [];
    if (types && types.includes(ContentTypesEnum.ADVERTISEMENT)) {
      balancedContent = assignToTagsAndBalanceContent(curResults, resObjs, 0.15, tags, true);
    } else {
      balancedContent = assignToTagsAndBalanceContent(curResults, resObjs, 0.15, tags);
    }
    setResults(balancedContent);
    setResultsTags(tags);
  };

  return [
    getContentApi, 
    results, 
    headlineResults, 
    errorMessage, 
    getContentFreeSearchApi, 
    setErrorMessage, 
    setResults, 
    loading, 
    currentLoadTag, 
    numFound, 
    setNumFound,
    userSpecifiedResults,
    setUserSpecifiedResults,
    latestResults,
    nonGreedyResults,
    setNonGreedyResults,
    getNonBaseContentApi,
    setFetchNonPub,
    setFetchNonArt,
    fetchNonPub,
    fetchNonArt,
    setFetchNonPubDone,
    fetchNonPubDone,
    setFetchNonArtDone,
    fetchNonArtDone,
    setFetchAds,
    fetchAds,
    resultsTags,
    setResultsTags
  ];
};