import React, { useState, useEffect } from 'react';
import  { Button } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, Text } from 'react-native';
import Spacer from '../components/Space';
import MeSearchAPI from '../api/MeSearch-API';

const PublishArticle = ({ navigation, route }) => {
    const body_id = route.params?.body_id ?? '';
    const activate = route.params?.activate ?? false;
    const [ successMsg, setSuccessMsg ] = useState('');
    const [ errorMsg, setErrorMsg ] = useState('');

    useEffect (() => {
        const unsubscribe = navigation.addListener('focus', () => {
          setErrorMsg('');
          setSuccessMsg('');
        });
        return unsubscribe;
    }, []);
    
    return (
        <SafeAreaView style={{justifyContent: 'center', alignItems: 'center'}}>
            <View style={styles.container}>
                <Spacer>
                    <View style={{flex: 0.5, marginTop: 60, justifyContent: 'center', alignItems: 'center'}}>
                    <Image style={styles.image} source={require('../../assets/logo-mesearch.png')}/>
                    </View>
                </Spacer>
                <Spacer>
                    <Button 
                        title={activate ? 'UnPublish' : 'Publish'}
                        onPress={async () => {
                            setErrorMsg('');
                            setSuccessMsg('');
                            try {
                                await MeSearchAPI.post('/api/content/ad/edit-campaign', {body_id, suspend: activate});
                                setSuccessMsg('Successfully Published');
                            } catch (err) {
                                console.log(err)
                                setErrorMsg('An Error Occured');
                            }
                        }}
                    />
                </Spacer>
                <Spacer>
                    {errorMsg !== '' ? <Text style={styles.errorMessage}>{errorMsg}</Text> : null}
                    {successMsg !== '' ? <Text style={styles.success}>{successMsg}</Text> : null}
                </Spacer>
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    errorMessage: {
      fontSize: 16,
      color: 'red',
      marginTop: 15,
      marginLeft: 15
    },
    image: {
      width: 200,
      height: 80,
      marginTop: 5,
    },
    container: {
      width: Math.min(Dimensions.get('window').width-20, 650),
      justifyContent: 'center',
      alignItems: 'center'
    },
    success: {
      fontSize: 18,
      color: 'green',
      marginTop: 15,
      marginLeft: 15
    },
});

export default PublishArticle;