import { useEffect, useState, useContext } from 'react';
import MeSearchAPIPub from '../api/MeSearch-API-Pub';
import MeSearchAPI from '../api/MeSearch-API';

export default () => {
  const [admins, setAdmins] = useState([]);
  const [adminsLoading, setAdminsLoading] = useState(false);
  const [adminsError, setAdminsError] = useState(null);
  const [pubOwner, setPubOwner] = useState(null);
  const [ads, setAds] = useState([]);
  const [adsLoading, setAdsLoading] = useState(false);
  const [articles, setArticles] = useState([]);
  const [unPubArticles, setUnPubArticles] = useState([]);
  const [articlesLoading, setArticlesLoading] = useState(false);
  const [nonPubArticlesLoading, setNonPubArticlesLoading] = useState(false);

  function getUniqueContent(content) {
    const uniqueContent = content
      .map(e => e['_id'])
        // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => content[e]).map(e => content[e]);
      return uniqueContent;
  }

  const getAdminsApi = async () => {
    setAdmins([]);
    setAdminsLoading(true);
    try {
      let response = await MeSearchAPIPub.get('/api/pub-owner/admins', {params: {pubOwner}});
      const adminsObj = response.data;
      setAdmins(adminsObj ? adminsObj : []);
      setAdminsLoading(false);
    } catch (err) {
      setAdminsError(err);
      setAdminsLoading(false);
    }
  };

  const getAdsApi = async () => {
    setAds([]);
    setAdsLoading(true);
    try {
      let response = await MeSearchAPI.get('/api/content/ads', {params: {pubOwner}});
      const adsObj = response.data;
      setAds(adsObj ? adsObj : []);
      setAdsLoading(false);
    } catch (err) {
      setAdsLoading(false);
    }
  };

  const getArticles = async (page, currentFeed, pubOwnerUsername, unPublished=false) => {
    if (!articlesLoading && !nonPubArticlesLoading) {
      if (unPublished) {
        if (page == 0) {
          setUnPubArticles([]);
        }
        setNonPubArticlesLoading(true);
      } else {
        if (page == 0) {
          setArticles([]);
        }
        setArticlesLoading(true);
      }
      try {
        let params = {params: {contentAge: 3, page: page, tagless: true, account: pubOwnerUsername}};
        if (unPublished) {
          params.params['unPublished'] = true;
        }
        let response = await MeSearchAPI.get('/api/dev/content/v2', params);
        let articlesObj = response.data;
        articlesObj = articlesObj ? articlesObj : [];
        let newFeed = [...currentFeed];
        if (articlesObj && articlesObj.length > 0) {
          newFeed.push(...articlesObj);
        }
        if (unPublished) {
          setUnPubArticles(getUniqueContent(newFeed));
        } else {
          setArticles(getUniqueContent(newFeed));
        }
        if (unPublished) {
          setNonPubArticlesLoading(false);
        } else {
          setArticlesLoading(false);
        }
      } catch (err) {
        if (unPublished) {
          setNonPubArticlesLoading(false);
        } else {
          setArticlesLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (pubOwner) {
      getAdminsApi();
    }
  }, [pubOwner]);

  return [
    admins,
    adminsLoading,
    adminsError,
    setPubOwner,
    setAdmins,
    getAdsApi,
    ads,
    adsLoading,
    getAdminsApi,
    articles, 
    getArticles, 
    articlesLoading,
    unPubArticles,
    nonPubArticlesLoading
  ];

};