import React from 'react';
import { Feather } from '@expo/vector-icons';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

const TagFilter = ({ userTag, onPress }) => {
  return (
    <View style={styles.container}>
      <View style={styles.tags}>
        <TouchableOpacity
          style={styles.button}
          onPress={() => onPress()}
        >
          <Text style={styles.text}>{userTag}</Text>
          <Feather name="x" size={20} color="white" />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  tags: {
    minWidth: 130,
    minHeight: 30,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: 'rgb(234,147,62)',
    backgroundColor: 'rgb(234,147,62)',
    alignContent: 'center',
    justifyContent: 'center',
  },
  button: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    color: 'white',
    fontSize: 16,
    // paddingRight: 5
  }
});

export default TagFilter;