import React, { useState, useEffect, useContext } from 'react';
import { SafeAreaView, Dimensions, StyleSheet, View, Image, Text } from 'react-native';
import { Context as PubContext } from '../../../context/PubContext';
import PassportForm from '../../core/PassportForm';
import MesearchAPI from '../../../api/MeSearch-API';
import { Platform } from 'react-native';

const PassportFormWrapper = ({ navigation, route }) => {
  // make a call to User on API, pass in relevant data to PassportForm.
  const darkMode = route.params?.darkMode ?? false;
  const currentAccount = route.params?.acc ?? null;
  const [username, setUsername] = useState('');
  const [accessLevel, setAccessLevel] = useState([]);
  const [apiError, setApiError] = useState(null);
  const [removedAccess, setRemovedAccess] = useState(false);
  const [profile, setProfile] = useState(null);
  const { state, signin, signout, clearErrorMessage  } = useContext(PubContext);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      clearErrorMessage();
    });
    return unsubscribe;
  }, []);

  const deleteUserAccessLevel = async username => {
    // remove a user's access level to given pubOwner. {req.body: username}
    try {
      const deleteResponse = await MesearchAPI.delete('/api/user/accessLvl/account', { data: { username } });
      if (deleteResponse) {
        setRemovedAccess(true);
      }
      return deleteResponse;
    } catch (error) {
      setApiError(error);
    }
  };

  // TODO: Refactor the below to hit the signout method and then the signin method in PubContext
  const goToPubOwnerProfile = async pubOwnerUser => {
    await signout();
    await signin({username: pubOwnerUser});
    // TODO: DEPRECATED
    /*try {
      const profileResponse = await MesearchAPI.get('/api/pub-owner', { params: { pubOwner: pubOwnerUser } });
      setProfile(profileResponse);
      return profileResponse;
    } catch (error) {
      setApiError(error);
    }*/
  };

  useEffect(() => {
    // API call here on the user to retrieve list of accessible profiles
    const fetchUserData = async () => {
      try {
        const userResponse = await MesearchAPI.get('/api/user');
        const { data: userData } = userResponse;
        const { username, accessLvl } = userData;
        setUsername(username);

        const bodyData = { accessLvl };
        const accessLevelResponse = await MesearchAPI.post('/api/accounts-from-accessLvl', bodyData);
        const { data: accessLevelData } = accessLevelResponse;
        const { pubOwners: finalAccessLevel } = accessLevelData;
        setAccessLevel(finalAccessLevel);
      } catch (error) {
        setApiError(error);
      }
    };
    
    fetchUserData();

    return () => {
      setRemovedAccess(false);
      setApiError(null);
    };
  }, [removedAccess]);

  return (
    <SafeAreaView style={[Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall, darkMode ? {backgroundColor: '#3c3c3d'} : null]}>
      {
        apiError ? (
          <Text>{JSON.stringify(apiError.message)}</Text>
        ) : null
      }
      {state.errorMessage ? <Text style={styles.errorMessage}>{state.errorMessage}</Text> : null}
      <PassportForm
        darkMode={darkMode}
        signout={signout}
        removeAccessCallback={deleteUserAccessLevel}
        username={username}
        navigation={navigation}
        apiError={apiError}
        accessLevel={accessLevel}
        goToPubOwnerProfile={goToPubOwnerProfile}
        profile={profile}
        myMembershipEnabled={true}
        mainImage={{uri: 'https://cdn.mesearch.ai/UI/Icons/network@3x.png'}}
        logoUrl={require('../../../../assets/mesearch_logo_small.png')}
        currentAccount={currentAccount}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  containerSmall: {
    marginTop: Platform.OS === 'ios' ? 200 : 20,
    margin: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerLarge: {
    paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
    paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
    justifyContent: 'center',
    marginTop:20,
    alignItems: 'center'
  },
  errorMessage: {
    fontSize: 16,
    color: 'red',
    paddingTop: 15,
    paddingBottom: 10
  },
});

export default PassportFormWrapper;