import React, { useContext } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import drawer from '../navigators/drawerNavigator';
import accountFlow from './stackNavigators/accountFlow';
import searchFlow from './stackNavigators/searchFlow';
import menuFlow from './stackNavigators/menuFlow';
import TabBar from '../components/TabBar';
import { Context as PubContext } from '../context/PubContext';

const Tab = createBottomTabNavigator();

const tabNavigator = () => {
  const  PubCon = useContext(PubContext);
  return (
    <Tab.Navigator
      initialRouteName={'Home'}
      tabBar={props => <TabBar {...props}/>}
    >
      <Tab.Screen
        name="Home"
        component={drawer}
      />
      <Tab.Screen
        name="Account"
        component={accountFlow}
      />
      <Tab.Screen
        name="Search"
        component={searchFlow}
      />
      <Tab.Screen
        name={`${(PubCon && PubCon.state && PubCon.state.publisher) ? 'Menu' : 'News'}`}
        component={menuFlow}
      />
    </Tab.Navigator>
  );
};

export default tabNavigator;