import React, { useState, useEffect } from 'react';
import { View, Text, Image, ImageBackground, Dimensions, TouchableOpacity, Animated } from 'react-native';
import  { CheckBox } from 'react-native-elements';
import { Feather } from '@expo/vector-icons';
import { Platform } from 'react-native';
import alertToConfirm from '../utils/alertToConfirm';
// import { color } from 'react-native-reanimated';
import { isMobile } from 'react-device-detect';

const DocTile = ({ title, ke_id, onPress, indx, path, accountId, setDocChecks, docChecks, setSuccessMsg, setErrorMsg, 
  removeDoc, forumId, portfolioId, setDocs, allDocs, navigation, IsIssueCoded, alerts, importance, description, endIndx, pageCount }) => {

  const [animatedValue, setAnimatedValue] = useState(new Animated.Value(0));
  const [frontInterpolate, setFrontInterpolate] = useState(animatedValue.interpolate({
    inputRange: [0, 180],
    outputRange: ['0deg', '180deg']
  }));
  const [frontAnimatedStyle, setFrontAnimatedStyle] = useState({
    transform: [
      { rotateX: frontInterpolate }
    ]
  });

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      animatedValue.setValue(0);
    });
    return unsubscribe;
  }, []);

  return (
    <Animated.View 
      style={[
        {
          backgroundColor: indx % 2 === 0 ? '#F0F8FF' : '#bce0eb', 
          width: Math.min(Dimensions.get('window').width-26, 600), 
          backfaceVisibility :'hidden',
          borderWidth: (importance && importance !== "") ? 2 : null,
          borderColor: (importance && importance !== "") ? 'red' : null,
          borderStyle: (importance && importance !== "") ? 'dashed' : null,
          borderBottomLeftRadius: indx === endIndx ? 5 : null,
          borderBottomRightRadius: indx === endIndx ? 5 : null,
        }, 
        frontAnimatedStyle
      ]}
    >
        <View style={{justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'row'}}>
            <CheckBox
                title=""
                onPress={() => {
                    let newDocChecks = JSON.parse(JSON.stringify(docChecks));
                    newDocChecks[ke_id] = !docChecks[ke_id] ? true : false;
                    setDocChecks(newDocChecks);
                }}
                checkedColor={'#007bff'}
                checked={docChecks[ke_id]}
            />
            <Image
              style={{
                height: 120,
                width: 80,
                resizeMode: 'contain',
                borderRadius: 15
              }}
              blurRadius={0.7}
              source={{uri: `https://cdn.mesearch.ai/assets/stockPhotos/thumb/${title.replace(/[0-9]/g, "X").substring(0, 1).toLowerCase()}.jpg`}}
            />
            {/*<Feather name="file-text" style={{ fontSize: 85, paddingLeft: 10, paddingRight: 5, color: (importance && importance !== "" ? '#c70000' : null)}}/>*/}
            <TouchableOpacity 
              onPress={() => {
                Animated.timing(animatedValue, {
                  toValue: 180,
                  duration: 800
                }).start();
                setTimeout(() => {
                  onPress(ke_id, path, accountId)
                }, 500);
              }}
              style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 15}}
            >
              <View>
                <Text style={{fontSize: 20, fontWeight: '400', paddingLeft: 10, color: 'blue'}}>{title}</Text>
                <Text style={{fontSize: 16, fontWeight: '400', paddingLeft: 10, paddingTop: 10}}>{description.length >= ((Platform.OS === 'web' && !isMobile) ? 30 : 15) 
                      ? description.substring(0, ((Platform.OS === 'web' && !isMobile) ? 30 : 15)) + "..."
                      : description}</Text>
                <Text style={{fontSize: 16, fontWeight: '400', paddingLeft: 10, paddingTop: 10}}>{`${pageCount} ${pageCount === 1 ? 'Page' : 'Pages'}`}</Text>
                </View>
            </TouchableOpacity>
        </View>
        <View>
            <View style={{flexDirection: 'row'}}>
                {importance && importance !== "" ? 
                  <View style={{flexDirection: 'row'}}>
                    <Text style={{marginLeft: 10, marginTop: 10}}>{'Relevance: '}</Text> 
                    <Text style={{color: 'red', marginTop: 10, marginRight: 5}}>{`${importance}`}</Text> 
                  </View>
                : null}
                {alerts && alerts === "True" ? <Text style={{margin: 10}}>{`Alerts:`}</Text> : null}
                {alerts && alerts === "True" ? <Feather name="alert-triangle" style={{ fontSize: 15, marginTop: 11, paddingRight: 5, color: '#b8b500', marginLeft: -3}}/> : null}
                {IsIssueCoded && IsIssueCoded  === "True" ? <Text style={{margin: 10}}>{`Coded:`}</Text> : null}
                {IsIssueCoded && IsIssueCoded === "True" ? <Feather name="tag" style={{ fontSize: 15, marginTop: 11, paddingRight: 5, color: '#00911f', marginLeft: -2}}/> : null}
                <View style={{flex: 1, alignItems: 'flex-end'}}>
                  {(forumId && portfolioId) ?
                  <TouchableOpacity
                    onPress={Platform.OS === 'web' ?
                        async () => {
                            alertToConfirm('web', "Are you sure you want to remove this document from the collection?", async () => {
                              const res = await removeDoc(ke_id, portfolioId, forumId);
                              if (res === "Success") {
                                setDocs(allDocs.filter(d => d.ke_id !== ke_id));
                                setSuccessMsg("Successfully Removed");
                                setTimeout(() => {
                                  setSuccessMsg("");
                                }, 3000);
                              } else {
                                setErrorMsg("Failed To Remove");
                                setTimeout(() => {
                                  setErrorMsg("");
                                }, 3000);
                              }
                            })();
                        }
                        :
                        async () => {
                            alertToConfirm('mobile', "Are you sure you want to remove this document from the collection?", async () => {
                                const res = await removeDoc(ke_id, portfolioId, forumId);
                                if (res === "Success") {
                                  setDocs(allDocs.filter(d => d.ke_id !== ke_id));
                                  setSuccessMsg("Successfully Removed");
                                  setTimeout(() => {
                                    setSuccessMsg("");
                                  }, 3000);
                                } else {
                                  setErrorMsg("Failed To Remove");
                                  setTimeout(() => {
                                    setErrorMsg("");
                                  }, 3000);
                                }
                            })();
                        }
                    }
                  >
                    <Feather name="trash-2" style={{ fontSize: 25, paddingLeft: 10, paddingRight: 25}}/>
                  </TouchableOpacity>
                  : null}
                </View>
            </View>
        </View>
    </Animated.View>
  );
};

export default React.memo(DocTile);