import React, { useState, useEffect, useContext } from 'react';
import { SafeAreaView, Dimensions, StyleSheet, View, Image, Text } from 'react-native';
import { Context as PubContext } from '../../../context/PubContext';
import PassportForm from '../../core/PassportForm';
import { Platform } from 'react-native';
import useKioskResults from '../../../hooks/kiosk/useKioskResults';
import MesearchAPIKiosk from '../../../api/kiosk/MeSearch-API-Kiosk';


const KioskPassportFormWrapper = ({ navigation, route }) => {
  // make a call to User on API, pass in relevant data to PassportForm.
  const darkMode = route.params?.darkMode ?? false;
  const currentAccount = route.params?.acc ?? null;
  const [username, setUsername] = useState('');
  const [accessLevel, setAccessLevel] = useState([]);
  const [apiError, setApiError] = useState(null);
  const [removedAccess, setRemovedAccess] = useState(false);
  const [profile, setProfile] = useState(null);
  const { state, kAccountSignin, kAccountSignout, clearErrorMessage  } = useContext(PubContext);
  const [ accounts, accountGrpDetails,
    accountGrpSets, accountCodingFrm, 
    accountCodingData, loading, error, 
    getAccounts, getAccountGrpDetails, 
    getGroupSets, getCodingFrm, 
    getCodingData, setCodingData, putCodingData, 
    saveCodingSuccess, setSaveCodingSuccess,
    getPortfolioItems, getPdfBase64, portfolioItems, pdfBase64,
    accountCodingHistory, getCodingHistory,
    guid, setGuid,
    getSearch,searchResults, getAdminsApi, admins, adminsLoading, adminsError, setAccountAdmins, setAccGrpDetails ] = useKioskResults();

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      clearErrorMessage();
    });
    return unsubscribe;
  }, []);

  const deleteUserAccessLevel = async (accId) => {
    console.log("Delete Access To Account");
  };

  const goToPubOwnerProfile = async accName => {
    await kAccountSignout();
    const acc = accounts.find(a => a.OwnersAccountTitle === accName);
    if (acc) {
      await kAccountSignin({accId: acc.AccountId});
    }

  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await MesearchAPIKiosk.get('/api/kiosk/user');
        setUsername(userResponse.data.email);
        await getAccounts();
      } catch (error) {
        setApiError(error);
      }
    };
    
    fetchUserData();

    return () => {
      setRemovedAccess(false);
      setApiError(null);
    };
  }, [removedAccess]);

  const updateAccounts = async () => {
    if (accounts && accounts.length > 0) {
      const accList = accounts.map(a => a.OwnersAccountTitle);
      setAccessLevel(accList);
    }
  }

    useEffect(() => {
      updateAccounts();
    }, [accounts]);



  return (
    <SafeAreaView style={[Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall, darkMode ? {backgroundColor: '#3c3c3d'} : null]}>
      {
        apiError ? (
          <Text>{JSON.stringify(apiError.message)}</Text>
        ) : null
      }
      {state.errorMessage ? <Text style={styles.errorMessage}>{state.errorMessage}</Text> : null}
      <PassportForm
        darkMode={darkMode}
        signout={kAccountSignout}
        removeAccessCallback={deleteUserAccessLevel}
        username={username}
        navigation={navigation}
        apiError={apiError}
        accessLevel={accessLevel}
        goToPubOwnerProfile={goToPubOwnerProfile}
        profile={profile}
        myMembershipEnabled={false}
        mainImage={{uri: 'https://cdn.mesearch.ai/UI/Icons/network@3x.png'}}
        logoUrl={require('../../../../assets/mesearch_logo_small.png')}
        currentAccount={currentAccount}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  containerSmall: {
    marginTop: Platform.OS === 'ios' ? 200 : 20,
    margin: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerLarge: {
    paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
    paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
    justifyContent: 'center',
    marginTop:20,
    alignItems: 'center'
  },
  errorMessage: {
    fontSize: 16,
    color: 'red',
    paddingTop: 15,
    paddingBottom: 10
  },
});

export default KioskPassportFormWrapper;