import React, { useContext, useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions, Image } from 'react-native';
import  { Text, Button, Input } from 'react-native-elements';
import NavLink from '../components/NavLink';
import { Context as PubContext } from '../context/PubContext';
import Spacer from '../components/Space';
import MeSearchAPI from '../api/MeSearch-API';
import CopyrightFooter from '../components/CopyrightFooter';

const PubSigninScreen = ({navigation}) => {
    const { state, clearErrorMessage, signin, errorMsg  } = useContext(PubContext);
    const [err, setErr] = useState(null);
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [user, setUser] = useState(null);
    const [pubSign, setPubSign] = useState(false);

    const getAccessLvl = (user, pub) => {
      let accessLvl = 1;
      if (user.accessLvl) {
        user.accessLvl.forEach(accessObj => {
          if (accessObj.account === pub) {
            accessLvl = Math.max(accessObj.perm, accessLvl);
          }
        });
      }
      return accessLvl;
    }
    useEffect(() => {
      const usrResponseProm = MeSearchAPI.get('/api/user');
      usrResponseProm.then(usrRes => {
        setUser(usrRes.data);
      });
    }, []);

    return (
        <View style={styles.container}>
        <Input 
            label="Publisher Username"
            value={username}
            onChangeText={(newUsername) => setUsername(newUsername)}
            autoCapitalize="none"
            autoCorrect={false}
            maxLength={80}
        />
        <Spacer/>
        {pubSign ?
            <Input
                secureTextEntry={true}
                label="Publisher Password"
                value={password}
                onChangeText={(newPassword) => setPassword(newPassword)}
                autoCapitalize="none"
                autoCorrect={false}
                onSubmitEditing={() => {
                    setUsername('')
                    setPassword('')
                    signin({ username, password })
                }}
                maxLength={60}
            />
        : null}
        {err ? <Text style={styles.errorMessage}>{err}</Text> : null}
        {state.errorMsg ? <Text style={styles.errorMessage}>{state.errorMsg}</Text> : null}
        <Spacer>
            <Button 
            title={"Sign In With User Account"}
            onPress={() => {
                if (!username) {
                    setErr("Must provide a username");
                } else {
                    const accLvl = getAccessLvl(user, username);
                    if (accLvl >= 4) {
                        navigation.navigate('PublisherHome', {pubOwner: username});
                    } else {
                        setErr("You do not have Admin access to this publisher account");
                    }
                }
            }}
            />
        </Spacer>
        <Text style={{paddingLeft: 10, fontSize: 18, fontWeight: '500'}}>Or</Text>
        <Spacer>
            <Button 
            title={"Sign In With Publisher Account"}
            onPress={() => {
                if (!pubSign) {
                    setPubSign(true);
                } else {
                    setUsername('');
                    setPassword('');
                    signin({ username, password });
                }
            }}
            />
        </Spacer>
        {/*<Spacer>
            <Button 
            title={"Back"}
            onPress={() => navigate('Search')}
            />
        </Spacer>*/}
        <View style={{width: 280}}>
            <NavLink
            text="Need to create a Publisher Account? Sign up instead!"
            routeName="PubSignup"
            />
        </View>
        {/* <View style={{flexDirection: 'row', justifyContent: 'center'}}>
            <View style={{height: 150, width: 280, marginBottom: 5, padding: 10}}>
                <Image style={styles.image} source={require('../../assets/web_footer_copyright.png')}/>
            </View>
        </View> */}
        <CopyrightFooter />
        </View>
    );
};

// TODO: DEPRECATED
/*PubSigninScreen.navigationOptions = {
  header: null
};*/

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
    paddingRight: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
    marginBottom: 30
  },
  image: {
    width: null,
    height: null,
    marginTop: 5,
    marginBottom: 5,
    resizeMode: 'contain',
    flex: 1
  },
  errorMessage: {
    fontSize: 18,
    color: 'red',
    marginTop: 15,
    marginLeft: 15
  },
  createPubStyle: {
    fontSize: 18,
    fontWeight: '400',
    paddingLeft: 10,
    paddingBottom: 5,
    color: 'blue'

  }
});

export default PubSigninScreen;