import React, { useState, useEffect } from 'react';
import { View, Text, SafeAreaView, FlatList, TouchableOpacity, StyleSheet, Dimensions, ScrollView, ActivityIndicator } from 'react-native';
import { Feather } from '@expo/vector-icons';
import TagLabel from '../components/core/TagLabel';
import HeaderButton from '../components/core/HeaderButton';
import MeSearchAPI from '../api/MeSearch-API';


const TagManagerScreen = ({ navigation, route }) => {

  const meTags = route.params?.meTags ?? [];
  const nativeTags = route.params?.nativeTags ?? [];
  const body_id = route.params?.body_id ?? null;
  const addMeTags = route.params?.addMeTags ?? [];
  const addNativeTags = route.params?.addNativeTags ?? [];
  const remMeTags = route.params?.remMeTags ?? [];
  const remNativeTags = route.params?.remNativeTags ?? [];
  const [isLoading, setIsLoading] = useState(false);
  const [remMeTagsPayload, setRemMeTagsPayload] = useState(remMeTags);
  const [remNativeTagsPayload, setRemNativeTagsPayload] = useState(remNativeTags);
  const [addMeTagsPayload, setAddMeTagsPayload] = useState(addMeTags.filter(a => !meTags.includes(a)));
  const [addNativeTagsPayload, setAddNativeTagsPayload] = useState(addNativeTags.filter(a => !nativeTags.includes(a)));
  const [err, setErr] = useState('');

  useEffect(() => {
    if (remMeTags.length > 0) {
      setRemMeTagsPayload(remMeTags);
    }
    if (remNativeTags.length > 0) {
      setRemNativeTagsPayload(remNativeTags);
    }
    if (addMeTags.length > 0) {
      setAddMeTagsPayload(addMeTags.filter(a => !meTags.includes(a)));
      addMeTags.forEach(m => {
        if (remMeTagsPayload.includes(m)) {
          let newRemMeTagsPayload = [...remMeTagsPayload];
          const i = newRemMeTagsPayload.indexOf(m);
          if (i > -1) {
            newRemMeTagsPayload.splice(i, 1);
            setRemMeTagsPayload(newRemMeTagsPayload);
          }
        }
      });
    }
    if (addNativeTags.length > 0) {
      setAddNativeTagsPayload(addNativeTags.filter(a => !nativeTags.includes(a)));
      addNativeTags.forEach(m => {
        if (remNativeTagsPayload.includes(m)) {
          let newRemNativeTagsPayload = [...remNativeTagsPayload];
          const i = newRemNativeTagsPayload.indexOf(m);
          if (i > -1) {
            newRemNativeTagsPayload.splice(i, 1);
            setRemNativeTagsPayload(newRemNativeTagsPayload);
          }
        }
      });
    }
  }, [addMeTags, addNativeTags, remMeTags, remNativeTags]);


  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: 'Tag Manager',
      headerTitleAlign:'center',
      headerRight: () => (
        <HeaderButton 
          title="Save"
          onPress={async() => {
            setIsLoading(true);
            try {
                await MeSearchAPI.put(
                    '/api/content/tags/edit', 
                    {
                      body_id: body_id,
                      addTags: addNativeTagsPayload,
                      addMeTags: addMeTagsPayload,
                      remTags: remNativeTagsPayload,
                      remMeTags: remMeTagsPayload
                    }
                )
                navigation.goBack(null);
            } catch (err) {
              setErr("Saving Tags Failed");
              setTimeout(() => {
                setErr("");
              }, 3000);
              console.log(err);
            }
            setIsLoading(false);
          }}
        />
      ),
      headerLeft: () => (
        <HeaderButton 
          title="Back"
          onPress={() => {
            navigation.goBack(null);
          }}
        />
      ),
    });
  }, [navigation, addMeTagsPayload, addNativeTagsPayload, remMeTagsPayload, remNativeTagsPayload, isLoading, setErr]);

  return (
    <ScrollView contentContainerStyle={styles.main}>
    {!isLoading ?
      <>
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
            {err != "" ? <Text style={{color: 'red', fontWeight: '500', fontSize: 18, marginTop: 5, marginBottom: 5}}>{err}</Text> : null}
            <View style={{flexDirection: 'row'}}>
            <Text style={styles.heading}>MeTags:</Text>
            <TouchableOpacity
                onPress={() => navigation.navigate(
                'AddTags', 
                {
                    meTags: meTags, 
                    nativeTags: nativeTags, 
                    body_id: body_id, 
                    remTags: remNativeTagsPayload,
                    remMeTags: remMeTagsPayload,
                    addTags: addNativeTagsPayload,
                    addMeTags: addMeTagsPayload
                })}
                style={styles.button}
            >
                <Feather name="plus" size={14} style={{marginTop: 2}} color="blue"/>
                <Text style={styles.buttonText}>Add MeTags</Text>
            </TouchableOpacity>
            </View>
            <FlatList 
            data={[...meTags, ...addMeTagsPayload].filter(t => !remMeTagsPayload.includes(t))}
            keyExtractor={item => item}
            renderItem={({item}) => {
                return (
                <View style={{paddingTop: 2, paddingBottom: 2}}>
                    <TagLabel 
                    tagText={item} 
                    type="remove" 
                    onPress={() => {
                        if ((meTags.length + addMeTagsPayload.length) > remMeTagsPayload.length+1) {
                            if (meTags.includes(item)) {
                                if (!remMeTagsPayload.includes(item)) {
                                    let newRemMeTagsPayload = [...remMeTagsPayload];
                                    newRemMeTagsPayload.push(item)
                                    setRemMeTagsPayload(newRemMeTagsPayload);
                                }
                            }
                            if (addMeTagsPayload.includes(item)) {
                                let newAddMeTagsPayload = [...addMeTagsPayload];
                                const i = newAddMeTagsPayload.indexOf(item);
                                if (i > -1) {
                                    newAddMeTagsPayload.splice(i, 1);
                                    setAddMeTagsPayload(newAddMeTagsPayload);
                                }
                            }
                        } else {
                            setErr("Must Be At Least One MeTag");
                            setTimeout(() => {
                              setErr("");
                            }, 3000);
                        }
                    }} 
                    />
                </View>
                )
            }}
            />
        </View>
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
            <View style={{flexDirection: 'row', alignItems: ''}}>
            <Text style={styles.heading}>Native Tags:</Text>
            <TouchableOpacity
                onPress={() => navigation.navigate(
                'AddTags', 
                {
                    meTags: meTags, 
                    nativeTags: nativeTags, 
                    body_id: body_id, 
                    remTags: remNativeTagsPayload,
                    remMeTags: remMeTagsPayload,
                    addTags: addNativeTagsPayload,
                    addMeTags: addMeTagsPayload,
                    isNative: true
                })}
                style={styles.button}
            >
                <Feather name="plus" size={14} style={{marginTop: 2}} color="blue"/>
                <Text style={styles.buttonText}>Add Native Tags</Text>
            </TouchableOpacity>
            </View>
            <FlatList 
            data={[...nativeTags, ...addNativeTagsPayload].filter(t => !remNativeTagsPayload.includes(t))}
            keyExtractor={item => item}
            renderItem={({item}) => {
                return (
                    <View style={{paddingTop: 2, paddingBottom: 2}}>
                    <TagLabel 
                        tagText={item} 
                        type="remove" 
                        onPress={() => {
                            if (nativeTags.includes(item)) {
                                if (!remNativeTagsPayload.includes(item)) {
                                    let newRemNativeTagsPayload = [...remNativeTagsPayload];
                                    newRemNativeTagsPayload.push(item)
                                    setRemNativeTagsPayload(newRemNativeTagsPayload);
                                }
                            }
                            if (addNativeTagsPayload.includes(item)) {
                                let newAddNativeTagsPayload = [...addNativeTagsPayload];
                                const i = newAddNativeTagsPayload.indexOf(item);
                                if (i > -1) {
                                    newAddNativeTagsPayload.splice(i, 1);
                                    setAddNativeTagsPayload(newAddNativeTagsPayload);
                                }
                            }
                        }} 
                    />
                    </View>
                )
            }}
            />
        </View>
      </>
    : <ActivityIndicator size="large" style={{ marginTop: 5 }} color="#005157"/>}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  main: {
    height: Dimensions.get('window').height-120
  },
  heading: {
    fontWeight: '500',
    paddingBottom: 10
  },
  button: {
    flexDirection: 'row',
    marginLeft: 20
  },
  buttonText: {
    color: "blue"
  },
});

export default TagManagerScreen;