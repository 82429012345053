const FormValidateTypesEnum = {
  ENUMERATOR: "EnumeratorValidate",
  RADIO: "RadioValidate",
  EMAIL: 'EmailValidate',
  URL: 'UrlValidate',
  PHONE: 'PhoneNumberValidate',
  ARRAYELEMENT: 'ArrayElementValidate',
  ARRAY: 'SetArrayValidate',
  NUMBER: 'NumberValidate',
  DATERANGE: 'DateRangeValidate',
  ANY: 'Any'
};
  
export default FormValidateTypesEnum;