import React, { useState, useEffect } from 'react';
import  { Text, Button, Input, CheckBox } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, ActivityIndicator, FlatList } from 'react-native';
import Spacer from '../components/Space';
import MeSearchAPI from '../api/MeSearch-API';
import CopyrightFooter from '../components/CopyrightFooter';
import { Platform } from 'react-native';


const EditLandingPageScreen = ({navigation, route}) => {
  const pubOwner = route.params?.pubOwner ?? '';
  const landDomain = route.params?.landDomain ?? '';
  const [errorMessage, setErrorMsg] = useState("");
  const [errorMessageBtm, setErrorMsgBtm] = useState("");
  const [successMessage, setSuccessMsg] = useState("");
  const [pgLoad, setPGLoad ] = useState(true);
  const [publications, setPublications ] = useState([]);
  const [curLandingPages, setCurLandingPages ] = useState(null);
  const [domains, setDomains] = useState("");
  const [landingPublications, setLandingPublications] = useState([]);
  const [landingPubObjs, setLandingPubObjs] = useState({});
  const [tags, setTags] = useState("");
  const [tagBarColor, setTagBarColor] = useState("");
  const [headerImage, setHeaderImage] = useState("");


  const fetchLandingPages = async () => {
    try {
      let publications = await MeSearchAPI.get(
        '/api/pub-owner/publications', {params: {pubOwner}});
      setPublications(publications.data);
    } catch (err) {
      setErrorMsg(`${err.response ? err.response.data.error : 'An error occured please try again later'}`);
      setPGLoad(false);
      return;
    }
    let landingPageArr = [];
    let newLandingPubObjs = {};
    let newLandingPublications = [];
    try {
      const response = await MeSearchAPI.get('/api/pub-owner', {params:{pubOwner}});
      landingPageArr = response.data.landingPage;
      setCurLandingPages(response.data.landingPage);
    } catch (err) {
      setErrorMsg(`${err.response ? err.response.data.error : 'An error occured please try again later'}`);
      return;
    }
    let landPG;
    landingPageArr.forEach(landingPg => {
      if (landingPg.domains.includes(landDomain)) {
        landPG = landingPg;
      }
    });
    landPG.publications.forEach(publication => {
        newLandingPublications.push(publication.pubName);
        newLandingPubObjs[`${publication.pubName}`] = publication;
    });
    setDomains(landPG.domains.toString());
    setHeaderImage(landPG.headerImage);
    setTagBarColor(landPG.tagBarColor);
    setTags(landPG.tags.toString());
    setLandingPublications(newLandingPublications);
    setLandingPubObjs(newLandingPubObjs);
    setPGLoad(false);
  };

  useEffect (() => {
    fetchLandingPages();
  }, []);

  return (
    <SafeAreaView style={Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall}>
    {!pgLoad ?
    <>
      <Spacer/>
      {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
      {successMessage ? <Text style={styles.successMessage}>{successMessage}</Text> : null}
      <Spacer/>
      <Text style={styles.headerStyle}>Domains:</Text>
        <Input 
            label="URIs (Comma Separated)"
            value={domains}
            onChangeText={(newDomains) => setDomains(newDomains)}
            autoCapitalize="none"
            autoCorrect={false}
            maxLength={80}
        />
        <Spacer/>
        <Text style={styles.headerStyle}>Add Publications:</Text>
        <FlatList
          style={{height: 240}}
          data={publications}
          keyExtractor={(pub) =>  pub._id}
          renderItem={({ item }) => {
          return (
            <>
                <CheckBox
                    checkedIcon='dot-circle-o'
                    uncheckedIcon='circle-o'
                    title={`${item.username}`}
                    checkedColor='green'
                    checked={landingPublications.includes(item.username)}
                    onPress={() => {
                        let newLandingPublications = JSON.parse(JSON.stringify(landingPublications));
                        if (landingPublications.includes(item.username)) {
                            const index = newLandingPublications.indexOf(item.username);
                            if (index > -1) {
                              newLandingPublications.splice(index, 1);
                            }
                            setLandingPublications(newLandingPublications);
                        } else {
                          newLandingPublications.push(item.username);
                          if (!landingPubObjs[`${item.username}`]) {
                            // Deep copy
                            let newLandingPubObjs = JSON.parse(JSON.stringify(landingPubObjs));
                            newLandingPubObjs[`${item.username}`] = {pubName: `${item.username}`};
                            setLandingPubObjs(newLandingPubObjs);
                          }
                          setLandingPublications(newLandingPublications);
                        }
                    }}
                />
                {landingPublications.includes(item.username) ? 
                <View style={{paddingLeft: 10}}>
                  <Input 
                    label="Display Name"
                    value={landingPubObjs[`${item.username}`]["displayName"] ? landingPubObjs[`${item.username}`]["displayName"] : ""}
                    onChangeText={(newDisplayName) => {
                      // Deep copy
                      let newLandingPubObjs = JSON.parse(JSON.stringify(landingPubObjs));
                      newLandingPubObjs[`${item.username}`]["displayName"] = newDisplayName;
                      setLandingPubObjs(newLandingPubObjs);
                    }}
                    autoCapitalize="none"
                    autoCorrect={false}
                    maxLength={200}
                  />
                  <Spacer/>
                  <Input 
                    label="Tag (Pick one of the tags in the landing page)"
                    value={landingPubObjs[`${item.username}`]["tag"] ? landingPubObjs[`${item.username}`]["tag"] : ""}
                    onChangeText={(newTag) => {
                      // Deep copy
                      let newLandingPubObjs = JSON.parse(JSON.stringify(landingPubObjs));
                      newLandingPubObjs[`${item.username}`]["tag"] = newTag;
                      setLandingPubObjs(newLandingPubObjs);
                    }}
                    autoCapitalize="none"
                    autoCorrect={false}
                    maxLength={200}
                  />
                  <Spacer/>
                  <Input 
                    label="Description"
                    value={landingPubObjs[`${item.username}`]["description"] ? landingPubObjs[`${item.username}`]["description"] : ""}
                    onChangeText={(newDescription) => {
                      // Deep copy
                      let newLandingPubObjs = JSON.parse(JSON.stringify(landingPubObjs));
                      newLandingPubObjs[`${item.username}`]["description"] = newDescription;
                      setLandingPubObjs(newLandingPubObjs);
                    }}
                    autoCapitalize="none"
                    autoCorrect={false}
                    maxLength={2000}
                  />
                  <Spacer/>
                  <Input 
                    label="Image"
                    value={landingPubObjs[`${item.username}`]["img"] ? landingPubObjs[`${item.username}`]["img"] : ""}
                    onChangeText={(newImage) => {
                      // Deep copy
                      let newLandingPubObjs = JSON.parse(JSON.stringify(landingPubObjs));
                      newLandingPubObjs[`${item.username}`]["img"] = newImage;
                      setLandingPubObjs(newLandingPubObjs);
                    }}
                    autoCapitalize="none"
                    autoCorrect={false}
                    maxLength={500}
                  />
                  <Spacer/>
                  <Input 
                    label="URI"
                    value={landingPubObjs[`${item.username}`]["uri"] ? landingPubObjs[`${item.username}`]["uri"] : ""}
                    onChangeText={(newUri) => {
                      // Deep copy
                      let newLandingPubObjs = JSON.parse(JSON.stringify(landingPubObjs));
                      newLandingPubObjs[`${item.username}`]["uri"] = newUri;
                      setLandingPubObjs(newLandingPubObjs);
                    }}
                    autoCapitalize="none"
                    autoCorrect={false}
                    maxLength={200}
                  />
                  <Spacer/>
                </View>
                : null}
            </>
          );
        }}
        />
        <Spacer/>
        <Text style={styles.headerStyle}>Tags (This creates the rows of the page):</Text>
        <Input 
            label="Tags (Comma Separated)"
            value={tags}
            onChangeText={(newTags) => setTags(newTags)}
            autoCapitalize="none"
            autoCorrect={false}
            maxLength={1000}
        />
        <Spacer/>
        <Text style={styles.headerStyle}>Tag Bar Color:</Text>
        <Input 
            label="Hex Value"
            value={tagBarColor}
            onChangeText={(newTagBarColor) => setTagBarColor(newTagBarColor)}
            autoCapitalize="none"
            autoCorrect={false}
            maxLength={80}
        />
        <Spacer/>
        <Text style={styles.headerStyle}>Header Image:</Text>
        <Input 
            label="URI"
            value={headerImage}
            onChangeText={(newHeaderImage) => setHeaderImage(newHeaderImage)}
            autoCapitalize="none"
            autoCorrect={false}
            maxLength={500}
        />
        <Spacer/>
        {errorMessageBtm ? <Text style={styles.errorMessage}>{errorMessageBtm}</Text> : null}
        <Spacer>
            <Button 
            title={"Submit"}
            onPress={async () => {
                setErrorMsg('');
                setSuccessMsg('');
                if (tags === "" || tagBarColor === "" || headerImage === "" || domains === "" || (landingPubObjs.length === 0 || landingPublications.length === 0)) {
                  setErrorMsgBtm('Missing required fields');
                  return;
                }
                if (!curLandingPages) {
                  setErrorMsgBtm('Service is down please try again later');
                  return;  
                }
                let payload = {};
                let newLandingPages = JSON.parse(JSON.stringify(curLandingPages));
                const domainsArr = domains.split(',').map(x => x.toLocaleLowerCase());
                const tagsArr = tags.split(',');
                let landingPubs = [];
                let publicationErr = false;
                landingPublications.forEach(landingPub => {
                  if (landingPubObjs[`${landingPub}`]) {
                    if (!landingPubObjs[`${landingPub}`].displayName || !landingPubObjs[`${landingPub}`].tag || 
                       !landingPubObjs[`${landingPub}`].description || !landingPubObjs[`${landingPub}`].img || !landingPubObjs[`${landingPub}`].uri) {
                       setErrorMsgBtm('Missing required fields for one of your publications');
                       publicationErr = true;
                    }
                    landingPubs.push(landingPubObjs[`${landingPub}`]);
                  }
                });
                if (publicationErr) {
                  return;
                }
                payload["tags"] = tagsArr;
                payload["tagBarColor"] = tagBarColor;
                payload["headerImage"] = headerImage;
                payload["domains"] = domainsArr;
                payload["publications"] = landingPubs;
                let index = newLandingPages.findIndex(x => x.domains.includes(landDomain));
                if (index > -1) {
                  newLandingPages.splice(index, 1);
                }
                newLandingPages.push(payload);
                setPGLoad(true)
                try {
                    await MeSearchAPI.patch(
                        '/api/pub-owner', 
                        { payload: {landingPage: newLandingPages},
                          pubOwner
                    });
                    setSuccessMsg("Successfully Pushed Landing Page Edits");
                } catch (err) {
                  setErrorMsg(`${err.response ? err.response.data.error : 'An error occured please try again later'}`);
                }
                setPGLoad(false);
            }}
            />
        </Spacer>
      {/* <View style={{flexDirection: 'row', justifyContent: 'center'}}>
        <View style={{height: 150, width: 280, marginBottom: 5, padding: 10}}>
            <Image style={styles.image} source={require('../../assets/web_footer_copyright.png')}/>
        </View>
      </View> */}
      <CopyrightFooter />
    </>
    : <ActivityIndicator size="large" style={{ marginTop: 150 }} color="#005157"/> }
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
    containerSmall: {
      flex: 1
    },
    containerLarge: {
      width: Dimensions.get('window').width,
      paddingLeft: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
      paddingRight: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null
    },
    errorMessage: {
      fontSize: 18,
      color: 'red',
      marginTop: 15,
      marginLeft: 15
    },
    successMessage: {
      fontSize: 18,
      color: 'green',
      marginTop: 15,
      marginLeft: 15
    },
    image: {
      width: null,
      height: null,
      marginTop: 5,
      marginBottom: 5,
      resizeMode: 'contain',
      flex: 1
    },
    headerStyle: {
    fontSize: 22,
    fontWeight: '300',
    margin: 10
    },
});

export default EditLandingPageScreen;