import React, { useState, useEffect } from 'react';
import { View, Image, Dimensions, FlatList, TouchableOpacity, Platform } from 'react-native';
import { Text, Button, CheckBox  } from 'react-native-elements';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import alertToConfirm from '../utils/alertToConfirm';

const PassportForm = ({ removeAccessCallback, goToPubOwnerProfile, accessLevel, navigation, logoUrl, listOfProfiles, mainImage, apiError, username, currentAccount, signout, darkMode, myMembershipEnabled }) => {
  const [selectedValue, setSelectedValue] = useState(currentAccount);

  useEffect(() => {
    return () => {
      setSelectedValue(null);
    }
  }, [selectedValue]);
  

  const onSelected = item => {
    // hit GET /api/pub-owner with req.query: {pubOwner: username}
    setSelectedValue(item);
    goToPubOwnerProfile(item);
  };

  const removeAccount = username => {
    removeAccessCallback(username);
  };

  const renderRadioButton = ( item ) => {

    return (
      <View style={{flexDirection:'row', flexWrap: "wrap", alignItems:'center'}}>
        <CheckBox
          checkedIcon='dot-circle-o'
          uncheckedIcon='circle-o'
          containerStyle={{ width: 250 }}
          title={item}
          textStyle={{fontSize: 13, fontWeight: "600"}}
          onPress={() => { onSelected(item) }}
          checkedColor='#007bff'
          checked={selectedValue === item ? true : false}
        />
        { 
          Platform.OS === 'web' ? (
            <TouchableOpacity 
              onPress={alertToConfirm('web', `Are you sure you want to remove the following account: ${item}?`, () => removeAccount(item))}
              style={styles.removeButton}
            >
              <Text style={[styles.removeText, darkMode ? {color: 'white'} : null]}>Remove</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity 
              onPress={alertToConfirm('mobile', `Are you sure you want to remove the following account: ${item}?`, () => removeAccount(item))}
              style={styles.removeButton}
            >
              <Text style={[styles.removeText, darkMode ? {color: 'white'} : null]}>Remove</Text>
            </TouchableOpacity>
          )
        }
      </View>
    );
  };

  return (
    <>
      <View style={{alignItems: 'center'}}>
        <Image style={{ height: 70, width: 150, marginTop: 5, marginBottom: 30}} resizeMode='contain' source={logoUrl}/>
      </View>
      <View style={{alignItems: 'center'}}>
        <Image style={{height: 50, width: 50, marginBottom: 30}} resizeMode='contain' source={mainImage}/>
      </View>
      <View style={{alignItems: 'center', marginBottom: 10}}>
        <Text style={[{fontSize: 18}, darkMode ? {color: 'white'} : null]}>Hello, {username}</Text>
      </View>
      <Text style={[{fontSize: 16, paddingLeft: 5, padding: 5, marginHorizontal: 5}, darkMode ? {color: 'white'} : null]}>Select account to view</Text>
      {/* NAVCONNECT to profile screen */}
      {myMembershipEnabled ?
      <View style={styles.buttonContainer}>
        <Button 
          title="My Membership"
          type="outline"
          icon={<MaterialCommunityIcons name="face-profile" size={20} color={darkMode ? "white" : "black"} />}
          onPress={async () => {
            await signout();
            navigation.navigate('Drawer', {screen: 'Account', params: {screen: 'AccountHome', params: {myMembership: true}}})
          }}
          titleStyle={{color: darkMode ? 'white' : 'black', paddingLeft: 10, fontSize: 16}}
          buttonStyle={{borderColor: darkMode ? 'white' : 'grey', borderWidth: 2, marginHorizontal: 5}}
        />
      </View>
      : null}
      <View style={styles.flatListContainer}>
        <FlatList 
          data={accessLevel}
          keyExtractor={item => item}
          renderItem={({item}) => renderRadioButton(item)}
        />
      </View>
    </>
  );
};

const styles = {
  image: {
    width: 70,
    height: 70,
    resizeMode: 'contain',
    marginTop: 8,
  },
  removeButton: {
    width: 60,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center'
  },
  removeText: {
    color: 'blue',
  },
  flatListContainer: {
    // height: 'auto',
    // maxHeight: '40%',
    // width: Dimensions.get('screen').width - 20,
  },
  buttonContainer: {
    maxWidth: 180,
    margin: 5
  }
};

export default PassportForm;