const BusinessTypesEnum = {
  NEWSPUBLISHER: "News Publisher",
  ADVERTISER: "Advertiser",
  RESTAURANT: "Restaurant",
  RETAILER: "Retailer",
  BAR: "Bar",
  BAKERY: "Bakery",
  Museum: "Museum",
  CAFE: "Cafe",
  LODGING: "Lodging",
  OTHER: "Other",
};
  
export default BusinessTypesEnum;