import React from 'react';
import { View, Dimensions } from 'react-native';
import { Platform } from 'react-native';
import { WebView } from 'react-native-webview';
  
const NativeArticleDisplay = ({ link }) => {

  if (!link) {
    return null;
  }

  return (
    <>
    {Platform.OS === 'web'
        ?<iframe 
        height={Dimensions.get('window').height}
        width={Dimensions.get('window').width}
        src={`${link}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
        : <View style={{overflow: true, height: Dimensions.get('window').height, width: Dimensions.get('window').width}}><WebView allowsInlineMediaPlayback={true} source={{uri: link}}/></View>
    }
    </>
  );
};


export default NativeArticleDisplay;