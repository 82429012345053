import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import KioskHomeScreen from '../../components/kiosk/kioskMainRN';
import PdfView from '../../components/kiosk/PdfViewer';

const Stack = createStackNavigator();

const kioskFlow = () => {
    return (
        <Stack.Navigator
        initialRouteName="KioskHome"
        screenOptions={{ gestureEnabled: true }}
        >
        <Stack.Screen
            name="KioskHome"
            component={KioskHomeScreen}
            options={{title: "Mesearch", headerShown: false}}
        />
        <Stack.Screen
            name="PdfViewer"
            component={PdfView}
            options={{title: "Viewer", headerTitle: 'back'}}
        />
        </Stack.Navigator>
    );
};

export default kioskFlow;