import React, { useRef, useState, useEffect } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  TouchableOpacity,
  TextInput,
  FlatList,
  Platform,
} from "react-native";
import { Feather, MaterialCommunityIcons, Ionicons } from "@expo/vector-icons";
import MeSearchAPI from '../../api/MeSearch-API';
import { isMobile, isTablet } from "react-device-detect";
import alertToConfirm from '../utils/alertToConfirm';
import KioskAPI from '../../api/kiosk/MeSearch-API-Kiosk';

const AccountHomeFocusCenters = ({ focusCenters, title, myAccLvl, adminCapability, setFocusCenters, horizontal, darkMode, setSuccessMsg, setErrMsg, navigation }) => {
  let flatListRef = useRef(0);
  const [currOffset, setCurrOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [imgErrors, setImgErrors] = useState({});
  const scroll = () => {
    flatListRef.current.scrollToOffset({
      offset: currOffset,
      animated: true,
    });
  };

  const DeleteFocusCenter = async (Groupid, AccountId) =>{

    await KioskAPI.delete('/api/kiosk/account/DeleteFocusCenter', 
    { params: {
      GroupID : Groupid, 
      accountId : AccountId,
    }
    });

  }

  useEffect(() => {
    scroll();
  }, [currOffset]);

  return (
    <View style={styles.container}>
      {focusCenters.length > 0 ? <Text style={[styles.title, darkMode ? {color: 'white'} : null]}>{title}</Text> : null}
      <View style={styles.inner}>
        {count > 0 && Platform.OS === "web" ? (
          <TouchableOpacity
            style={styles.arrow}
            onPress={() => {
              if (count > 0) {
                setCurrOffset(currOffset - 250);
                setCount(count - 1);
              }
            }}
          >
            <Feather name="chevron-left" size={24} color="Black" />
          </TouchableOpacity>
        ) : null}
        <FlatList
          horizontal={horizontal}
          ref={flatListRef}
          showsHorizontalScrollIndicator={false}
          data={focusCenters}
          keyExtractor={(item) => `${item.Groupid}`}
          renderItem={({ item }) => {
            return (
              <View
                style={[
                  styles.profile,
                  item.tagBarColor
                    ? { backgroundColor: `${item.tagBarColor}` }
                    : { backgroundColor: "#cccccc" },
                    { width: 250 },
                ]}
              >
                <View style={styles.upper}>
                  <Image 
                    style={styles.img} 
                    source={{ uri: (imgErrors[`${item.Groupid}`] && Platform.OS !== 'web') ? `https://cdn.mesearch.ai/assets/stockPhotos/alpha/${item.GroupName.substring(0, 1).toLowerCase()}.jpg` : "https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/cdn1/groupImage_" + `${item.Groupid}` +'_'+ `${item.AccountId}` + ".png" }} 
                    defaultSource={Platform.OS === 'web' ? {uri: `https://cdn.mesearch.ai/assets/stockPhotos/alpha/${item.GroupName.substring(0, 1).toLowerCase()}.jpg`} : null}
                    onError={() => {
                      if (Platform.OS !== 'web') {
                        let newImgErrors = JSON.parse(JSON.stringify(imgErrors));
                        newImgErrors[`${item.Groupid}`] = true;
                        setImgErrors(newImgErrors);
                      }
                    }} 
                  />
                  <View style={styles.userInfo}>
                    <Text style={styles.userName}>
                        {
                          item.GroupName.length >= 20
                            ? item.GroupName.substring(0, 17) + "..."
                            : item.GroupName
                        }
                    </Text>
                  </View>
                </View>
                <View>
                    <View style={styles.lower}>
                        <View style={styles.contentWrapper}>
                            <View style={styles.content}>
                            <Text style={styles.text}>{`Active Users: ${item.ActiveUsersCount}`}</Text>
                            <Text style={[styles.text, {color: item.VisibilityRestriction === "Open" ? 'green' : 'red'}]}>{`Status: ${item.VisibilityRestriction}`}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                      {(/*adminCapability && myAccLvl >= 4*/1==1) ?
                          <View style={{flexDirection:'row'}}>
                              <TouchableOpacity 
                                  style={styles.btn}
                                  onPress={async () => {
                                    // TODO: Navigate to focus center edit screen
                                    navigation.navigate('ManageFocusCenter', {groupId: item.Groupid, forumId: item.Forumid, AccountId: item.AccountId, groupName: item.GroupName});
                                  }}
                              >
                                  <Text style={styles.btnText}>Edit</Text>
                              </TouchableOpacity>
                              <TouchableOpacity 
                                  style={styles.btn}
                                  onPress={async () => {
                                    alertToConfirm('web', "Are you sure you want to remove this focus center?", async () => {
                                      setSuccessMsg("");
                                      setErrMsg("");
                                      try {
                                        await DeleteFocusCenter(item.Groupid, item.AccountId);
                                        setSuccessMsg("Successfully deleted");
                                        setTimeout(() => {
                                          setSuccessMsg("");
                                        }, 3000);
                                        setFocusCenters(focusCenters.filter(p => p.Forumid !== item.Forumid));
                                      } catch (err) {
                                        setErrMsg("Failed to delete");
                                        setTimeout(() => {
                                          setErrMsg("");
                                        }, 3000);
                                      }
                                    })();
                                  }}
                              >
                                  <Text style={styles.btnText}>Remove</Text>
                              </TouchableOpacity>
                            </View>
                      : null}
                    </View>

                  </View>
              </View>
            );
          }}
        />
        {focusCenters.length > 3 &&
        (Platform.OS === "web" && (!isMobile && !isTablet)) &&
        count <= Math.ceil(focusCenters.length / 3) &&
        horizontal
        ? (
          <TouchableOpacity
            style={styles.arrow}
            onPress={() => {
              if (count <= Math.ceil(focusCenters.length / 3)) {
                setCurrOffset(currOffset + 250);
                setCount(count + 1);
              }
            }}
          >
            <Feather name="chevron-right" size={24} color="Black" />
          </TouchableOpacity>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    //flex:1,
    //backgroundColor:'#d63031',
    //marginHorizontal:10,
    // justifyContent:'center',
    // alignItems:'center',
    //paddingVertical:5,
  },
  inner: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    paddingHorizontal: 5,
    width: Math.min(Dimensions.get('window').width-26, 800)
    // borderColor:'red',
    // borderWidth:1,
  },
  title: {
    paddingVertical: 5,
    marginHorizontal: 5,
  },
  profile: {
    marginHorizontal: 5,
    marginVertical: 5,
    height: (Platform.OS === 'web' && !isMobile) ? 180 : 200,
  },
  upper: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderBottomColor: "black",
    borderBottomWidth: 1,
  },
  img: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
  },
  userInfo: {
    paddingLeft: 5,
  },
  userName: {
    color: "black",
    fontWeight: "600",
    fontSize: 15,
  },
  userTitle: {
    color: "black",
    fontSize: 10,
  },
  lower: {
    flexDirection: "row",
    alignItems: "center",
  },
  contentWrapper: {
    paddingHorizontal: 5,
    paddingVertical: 10,
  },
  content: {
    paddingBottom: 10,
  },
  text: {
    color: "black",
    fontSize: 10,
  },
  btn: {
    borderColor: "black",
    borderWidth: 1,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 5,
    width: 85
  },
  btnText: {
    color: "black",
    textAlign: "center",
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  arrow: {
    backgroundColor: "#666",
    height: "95%",
    justifyContent: "center",
  },
});

export default AccountHomeFocusCenters;
