import {launchImageLibraryAsync, MediaTypeOptions, requestMediaLibraryPermissionsAsync } from 'expo-image-picker';
import { Platform } from 'react-native';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';

const pickImage = async () => {
    const request = await requestMediaLibraryPermissionsAsync();
    if (request.granted) {
        try {
          let result = await launchImageLibraryAsync({
            mediaTypes: MediaTypeOptions.Images,
            allowsEditing: false,
            allowsMultipleSelection : false,
            aspect: [4, 3],
            base64: true,
          });
          if (!result.cancelled) {
            let resultUri = result.uri;
            let downSampled = false;
            const downSizeAndCompressedImage = await manipulateAsync(
              result.uri,
              [],
              { compress: 0.5, format: SaveFormat.JPEG, base64: true }
            );
            result = downSizeAndCompressedImage;
            if (Platform.OS === 'web') {
              resultUri = result.uri;
              downSampled = true;
            } else {
              if (result.base64) {
                resultUri = `data:image/jpeg;base64,${result.base64}`;
                downSampled = true;
              }
            }
            
            if (Platform.OS !== 'web' && !downSampled) {
              let uriParts = result.uri.split('.');
              let ext = "";

              uriParts.some(uriPart => {
                if (uriPart.match(/(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|mp4|MP4)/)) {
                ext = uriPart;
                return true;
                }
                return false;
              });
              if (ext === "") {
                // TODO:
                // Some error logic
              }
            // TODO switch to data:video/mp4, 
              resultUri = `data:image/${ext};base64,${result.base64}`;
            }
            return resultUri;
          } else {
            return "";
          }
        } catch (err) {
          console.log(err);
          return "";
        }
    } else {
        return "";
    }
};

export default pickImage;