import { useState, useEffect } from 'react';
import MeSearchAPIInternal from '../api/MeSearch-API-Internal';
import MeSearchAPI from '../api/MeSearch-API';

export default () => {
  const [results, setResults] = useState([]);
  const [resultsKiosk, setResultsKiosk] = useState([]);
  const [moreContent, setMoreContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState([]);

  function getUniqueContent(content) {
    const uniqueContent = content
      .map(e => e['_id'])
        // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => content[e]).map(e => content[e]);
      return uniqueContent;
  }

  const getResultsApi = async ({currentFeed, page, sources, targeting, userId, tags=[]}) => {
    const curFeed = currentFeed ? currentFeed : [];
    const hchParams = {params: {Ad: {ratio: 0.15, sources: ["MeSearch-Ads"]}, Prod: {ratio: 0.15, sources: ["EverybodyShops"]}, Evnt: {ratio: 0.15, sources: ["Evvnt"]}}};
    let params = {params: {contentAge: 3, page: page, relevant: true, sources: sources, targeting: targeting, userId, hch: hchParams, nonPubSources: true}};
    setIsLoading(true);
    try {
      if (tags.length > 0) {
        params.params['tags'] = tags;
      }
      let response = await MeSearchAPIInternal.get('/api/dev/content/v2', params);
      const resObj = response.data;
      let newFeed = [...curFeed];
      if (resObj && resObj.length > 0) {
        newFeed.push(...resObj);
      }
      setResults(getUniqueContent(newFeed));
    } catch (err) {
      setError(err);
    }
    setIsLoading(false);
  };

  const getMoreContentApi = async ({page, sources, targeting, userId, tags=[]}) => {
    let params = {
      params: {
        contentAge: 3, 
        page: page, 
        relevant: true, 
        sources: sources, 
        targeting: targeting, 
        userId, 
        nonPubSources: true
      }
    };
    setIsLoading(true);
    try {
      if (tags.length > 0) {
        params.params['tags'] = tags;
      }
      let response = await MeSearchAPIInternal.get('/api/dev/content/v2', params);
      const resObj = response.data;
      let newFeed = [];
      if (resObj && resObj.length > 0) {
        newFeed.push(...resObj);
      }
      setMoreContent(getUniqueContent(newFeed));
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const getResultsKioskApi = async ({currentFeed, page, account, sources, tags=[]}) => {
    const curFeed = currentFeed ? currentFeed : [];
    //const hchParams = {params: {Ad: {ratio: 0.15, sources: ["Kiosk-Ads"]}, Prod: {ratio: 0.15, sources: ["Kiosk-Products"]}}};
    //let params = {params: {contentAge: 7, page: page, relevant: true, sources: sources, targeting: targeting, userId, hch: hchParams, nonPubSources: true}};
    let params = {params: {contentAge: 3, page: page, relevant: true, nonPubSources: true, account}};
    setIsLoading(true);
    try {
      if (tags.length > 0) {
        params.params['tags'] = tags;
      }
      let response = await MeSearchAPI.get('/api/dev/content/v2', params);
      const resObj = response.data;
      let newFeed = [...curFeed];
      if (resObj && resObj.length > 0) {
        newFeed.push(...resObj);
      }
      setResultsKiosk(getUniqueContent(newFeed));
    } catch (err) {
      setError(err);
    }
    setIsLoading(false);
  };
 
  return [
    getResultsApi,
    results,
    isLoading,
    error,
    getResultsKioskApi,
    resultsKiosk,
    getMoreContentApi,
    moreContent,
  ];

};