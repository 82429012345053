import React, { useState, useEffect, useContext } from 'react';
import { View, Text, StyleSheet, FlatList, Dimensions, ActivityIndicator, TextInput, Image, TouchableOpacity } from 'react-native';
import { Button } from 'react-native-elements';
import FocusCenterTile from '../kiosk/FocusCenterTile';
import SetTile from '../kiosk/SetTile';
import useKioskResults from '../../hooks/kiosk/useKioskResults';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Feather } from '@expo/vector-icons';
import { Platform } from 'react-native';
import { isMobile } from 'react-device-detect';
import DocTile from './DocTile';
import {Picker} from '@react-native-picker/picker';
import boolSearch from './boolSearch';
import { Context as PubContext } from '../../context/PubContext';


const KioskHome = ({ navigation, route }) => {
  const [ accounts, accountGrpDetails,
    accountGrpSets, accountCodingFrm, 
    accountCodingData, loading, error, 
    getAccounts, getAccountGrpDetails, 
    getGroupSets, getCodingFrm, 
    getCodingData, setCodingData, putCodingData, 
    saveCodingSuccess, setSaveCodingSuccess,
    getPortfolioItems, getPdfBase64, portfolioItems, pdfBase64,
    accountCodingHistory, getCodingHistory,
    guid, setGuid,
    getSearch,searchResults, getAdminsApi, admins, adminsLoading, 
    adminsError, setAccountAdmins, setAccGrpDetails, setPorfolioItems, 
    numFound, addDocsToSet, setAcctSets, accountSets, getAccountSets, 
    setAcctForumIds, removeDocFromSet, createSetFromSearchResults, removeSet, setAcctGrpSets, accountForumIds ] = useKioskResults();
  const [focusCenterChecks, setFocusCenterChecks] = useState({});
  const [setChecks, setSetChecks] = useState({});
  const [account, setAccount] = useState(null);
  const [search, setSearch] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [curFocusCenter, setCurFocusCenter] = useState(null);
  const [curSet, setCurSet] = useState(null);
  const [err, setErr] = useState(false);
  const [docPage, setDocPage] = useState(0);
  const [calledDuringMomentum, setCalledDuringMomentum] = useState(true);
  const [previousLoad, setPreviousLoad] = useState(0);
  const [initCall, setInitCall] = useState(true);
  const [advancedInitCall, setAdvancedInitCall] = useState(false);
  const [searchSet, setSearchSet] = useState(null);
  const [searchFocusCenter, setSearchFocusCenter] = useState(null);
  const [docChecks, setDocChecks] = useState({});
  const [selectedSet, setSelectedSet] = useState("");
  const [selectedFocusCenter, setSelectedFocusCenter] = useState("");
  const [successNotification, setSuccessNotification] = useState("");
  const [failureNotification, setFailureNotification] = useState("");
  const { kAccountSignin } = useContext(PubContext);
  let advancedSearch = route.params?.advancedSearch ?? {};
  let advancedSearchName = route.params?.advancedSearchName ?? "";

  const getAccountInfo = async () => {
    await getAccounts();
  }

  useEffect(() => {
    getAccountInfo();
    const unsubscribe = navigation.addListener('focus', () => {
      getAccountInfo();
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (Object.keys(advancedSearch).length > 0 
      && (accountSets && accountSets.length > 0) 
      && (accountForumIds && accountForumIds.length > 0) 
      && !loading 
      && (accountGrpDetails && accountGrpDetails.length > 0)
      && advancedInitCall) {
      // Below is commented out since it causes the add collection to not render after an advanced search
      //setSelectedFocusCenter("");
      setAdvancedInitCall(false);
      setSearchTxt("");
      setSearch(true);
      setDocPage(0);
      setPreviousLoad(0);
      setSearchFocusCenter(curFocusCenter);
      setSearchSet(curSet);
      setInitCall(true);
      setPorfolioItems([]);
      setDocChecks({});
      setSelectedSet("");
      getSearch(
        "", 
        account.AccountId, 
        curFocusCenter 
        ? [...Object.keys(focusCenterChecks).filter(f => focusCenterChecks[`${f}`]), curFocusCenter.Forumid] 
        : Object.keys(focusCenterChecks).filter(f => focusCenterChecks[`${f}`]), 
        curSet ? [...Object.keys(setChecks).filter(s => setChecks[`${s}`]), curSet.Portfolioid] : Object.keys(setChecks).filter(s => setChecks[`${s}`]), 
        [], 
        0, 
        advancedSearch
      );
      setCurFocusCenter(null);
      setCurSet(null);
    }
  }, [advancedSearch, accountSets, accountForumIds, accountGrpDetails, advancedInitCall]);

  useEffect(() => {
    if (Object.keys(advancedSearch).length > 0) {
      setAdvancedInitCall(true);
    }
  }, [advancedSearch]);

  const updateAccounts = async () => {
    if (accounts && accounts.length > 0) {
      const kAccountToken = await AsyncStorage.getItem('kAccountToken');
      if (kAccountToken) {
        const acc = accounts.find(a => a.AccountId == kAccountToken);
        if (acc) {
          setAccount(acc);
        }
      } else {
        kAccountSignin({accId: accounts[0].AccountId});
        setAccount(accounts[0]);
      }
    }
  }

  useEffect(() => {
    updateAccounts();
  }, [accounts]);

  const updateAccountGrps = async () => {
    if (account) {
      await getAccountGrpDetails(account.AccountId);
    }
  }

  useEffect(() => {
    updateAccountGrps();
    setAcctForumIds([]);
  }, [account]);

  useEffect(() => {
    if (accountGrpDetails && accountGrpDetails.length > 0 && account) {
      getAccountSets(account.AccountId, accountGrpDetails.map(a => `${a.Forumid}`));
    }
  }, [accountGrpDetails])

  useEffect(() => {
    if (curFocusCenter) {
      getGroupSets(curFocusCenter.Forumid);
    }
  }, [curFocusCenter]);

  useEffect(() => {
    if ((portfolioItems.length > previousLoad) || initCall) {
      if (curSet) {
        setInitCall(false);
        setPreviousLoad(portfolioItems.length);
        getPortfolioItems(curFocusCenter.Forumid, account.AccountId, curSet.Portfolioid, docPage, portfolioItems);
      } else if (search) { 
        setInitCall(false);
        setPreviousLoad(portfolioItems.length);
        getSearch(
          searchTxt.replace(/\“/g, '\"').replace(/\„/g, '\"').replace(/\”/g, '\"'), 
          account.AccountId, 
          searchFocusCenter
          ? [...Object.keys(focusCenterChecks).filter(f => focusCenterChecks[`${f}`]), searchFocusCenter.Forumid] 
          : Object.keys(focusCenterChecks).filter(f => focusCenterChecks[`${f}`]), 
          searchSet ? [...Object.keys(setChecks).filter(s => setChecks[`${s}`]), searchSet.Portfolioid] : Object.keys(setChecks).filter(s => setChecks[`${s}`]), 
          portfolioItems, 
          docPage,
          advancedSearch
        );
      }
    }
  }, [curSet, docPage]);

  useEffect(() => {
    if (!selectedSet || selectedSet === "") {
      if (curFocusCenter || searchFocusCenter) {
        if (accountGrpSets.length > 0) { 
          setSelectedSet(`${accountGrpSets[0].Portfolioid},${accountGrpSets[0].forumid}`);
        }
      } else {
        if (accountSets.length > 0) { 
          setSelectedSet(`${accountSets[0].Portfolioid},${accountSets[0].forumid}`);
        }
      }
    }
  }, [docChecks]);

  useEffect(() => {
    if (!selectedFocusCenter || selectedFocusCenter === "") {
      if (accountGrpDetails.length > 0) {
        setSelectedFocusCenter(accountGrpDetails[0].Forumid);
      }
    }
  }, [search]);

  const submitSearch = () => {
    if (searchTxt && !loading) {
      // Below is commented out since it causes the add collection to not render after a search
      //setSelectedFocusCenter("");
      setSearch(true);
      setDocPage(0);
      setPreviousLoad(0);
      setSearchFocusCenter(curFocusCenter);
      setSearchSet(curSet);
      setInitCall(true);
      setPorfolioItems([]);
      setDocChecks({});
      setSelectedSet("");
      advancedSearch = {};
      getSearch(
        searchTxt.replace(/\“/g, '\"').replace(/\„/g, '\"').replace(/\”/g, '\"'), 
        account.AccountId, 
        curFocusCenter 
        ? [...Object.keys(focusCenterChecks).filter(f => focusCenterChecks[`${f}`]), curFocusCenter.Forumid] 
        : Object.keys(focusCenterChecks).filter(f => focusCenterChecks[`${f}`]), 
        curSet ? [...Object.keys(setChecks).filter(s => setChecks[`${s}`]), curSet.Portfolioid] : Object.keys(setChecks).filter(s => setChecks[`${s}`]), 
        [], 
        0
      );
      setCurFocusCenter(null);
      setCurSet(null);
    }
  }

  const reset = () => {
    setDocPage(0);
    setPreviousLoad(0);
    setCurFocusCenter(null);
    setCurSet(null);
    setInitCall(true);
    setPorfolioItems([]);
    setSearch(false);
    setSearchTxt("");
    setSetChecks({});
    setFocusCenterChecks({});
    setSearchFocusCenter(null);
    setSearchSet(null);
    setDocChecks({});
    setSelectedSet("");
    setSelectedFocusCenter("");
    setSuccessNotification("");
    setFailureNotification("");
    advancedSearch = {};
  };

  return (
    <View style={styles.container}>
        {((account && accountGrpDetails.length > 0) || (search && (portfolioItems.length > 0 || numFound === 0))) ?
         <View style={{height: Dimensions.get('window').height-20, width: Math.min(Dimensions.get('window').width-26, 600)}}>
           <View style={{flexDirection: 'row'}}>
            <View>
            <Image 
                style={{ height: 60, width: 60, justifyContent: "center", alignItems: "center", borderRadius: 30, marginTop: (Platform.OS === 'web' && !isMobile) ? 5 : 30, marginLeft: 50 }} 
                source={{ 
                  uri: (err && Platform.OS !== 'web') 
                    ? "https://cdn.mesearch.ai/assets/stockPhotos/60.jpg" 
                    : "https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/cdn1/AccountImage_"+ `${account.AccountId}` + ".png" 
                }} 
                defaultSource={Platform.OS === 'web' ? {uri: "https://cdn.mesearch.ai/assets/stockPhotos/60.jpg"} : null}
                onError={() => {
                  if (Platform.OS !== 'web') {
                    setErr(true);
                  }
                }} 
              />
            </View>
            <Text style={{
              fontWeight: '600', 
              paddingTop: (Platform.OS === 'web' && !isMobile) ? 25 : 50, 
              paddingLeft: 10, fontSize: 16}}>{account.OwnersAccountTitle.length >= 30 
                ? account.OwnersAccountTitle.substring(0, 30) + "..." 
                : account.OwnersAccountTitle}
            </Text>
            </View>
           <View style={{flexDirection: 'row'}}>
            <View style={{ borderColor: 'gray', borderWidth: 2, marginTop: 10, backgroundColor: '#F0EEEE', height: 50, borderRadius: 5, flexDirection: 'row', marginBottom: 10, flex: 1}}>
              <TouchableOpacity onPress={submitSearch}>
                <Feather name="search" style={{ fontSize: 35, marginHorizontal: 5, marginTop: 5}}/>
              </TouchableOpacity>
              <TextInput
                autoCapitalize="none"
                autoCorrect={false}
                style={{ flex: 1, fontSize: 20 }}
                placeholder={"Search"}
                value={searchTxt}
                onChangeText={(txt) => setSearchTxt(txt)}
                onFocus={(evnt) => setSearchTxt(evnt.nativeEvent.text)}
                onSubmitEditing={submitSearch}
                onEndEditing={submitSearch}
                multiline={false}
                maxLength={120}
              />
            </View>
            {/*<View style={{marginTop: (Platform.OS === 'web' && !isMobile) ? 6 : 8}}>
              <TouchableOpacity
                onPress={reset}
              >
                <Feather name="refresh-cw" style={{ fontSize: 35, paddingLeft: 10, paddingRight: 5}}/>
                <Text style={{fontSize: 14, fontWeight: '400', paddingBottom: 10, paddingLeft: 10}}>Reset</Text>
              </TouchableOpacity>
            </View>*/}
          </View>
          {search && (searchTxt || advancedSearch) && !loading ?
          <View style={{flexDirection: 'row'}}>
            <Text style={{fontWeight: '600', paddingTop: 5, paddingLeft: 10, fontSize: 16, paddingBottom: 10}}>{`${numFound} ${numFound !== 1 ? 'Results' : 'Result'} Found `}</Text>
            <View style={{marginTop: (Platform.OS === 'web' && !isMobile) ? 6 : 8}}>
              <TouchableOpacity
                onPress={reset}
              >
                <Feather name="x-circle" style={{ fontSize: 25, paddingLeft: 5, paddingRight: 5, marginTop: -6}}/>
              </TouchableOpacity>
            </View>
          </View>
          : null}
          {(search && !loading && selectedFocusCenter) ?
            <View style={{height: (Platform.OS === 'ios' && !searchFocusCenter) ? 160 : ((Platform.OS === 'web' && !isMobile) || searchFocusCenter) ? 40 : 80, flexDirection: ((Platform.OS === 'web' && !isMobile) || Platform.OS === 'ios') ? 'row': 'column'}}>
              <TouchableOpacity
                onPress={async () => {
                  const searchISODate = new Date().toISOString();
                  const fId = searchFocusCenter ? searchFocusCenter.Forumid : selectedFocusCenter ? selectedFocusCenter : "";
                  if (fId === "") {
                    setFailureNotification("Must be searching within a focus center to create a collection");
                  }
                  const res = await createSetFromSearchResults((searchTxt && searchTxt !== "") 
                    ? `${searchTxt}-${searchISODate}` 
                      : advancedSearchName, `${searchISODate}`, fId, account.AccountId, Object.keys(advancedSearch).length > 0 
                      ? advancedSearch
                    : null
                  );
                  setDocChecks({});
                  if (res === "Success") {
                    setSuccessNotification("Successfully Created");
                    setTimeout(() => {
                      setSuccessNotification("");
                    }, 3000);
                  } else {
                    setFailureNotification("Failed To Create");
                    setTimeout(() => {
                      setFailureNotification("");
                    }, 3000);
                  }
                }}
                style={{flexDirection: 'row'}}
              >
                <Feather name="plus-circle" style={{ fontSize: 25, paddingLeft: 10, paddingRight: 5, paddingTop: 7, color: 'blue' }}/>
                <Text style={{ fontWeight: '600', fontSize: 16, marginTop: 12, paddingRight: 5 }}>Create Collection Using Results</Text>
              </TouchableOpacity>
              {!searchFocusCenter ?
                <View style={{marginLeft: Platform.OS === 'ios' ? -Dimensions.get('window').width/1.6 : null, marginTop: Platform.OS === 'ios' ? -8 : null}}>
                  <Picker
                    selectedValue={`${selectedFocusCenter}`}
                    style={{height: Platform.OS === 'ios' ? 60 : 40, width: Platform.OS === 'ios' ? 280 : Math.min(Dimensions.get('window').width/1.6, 300)}}
                    onValueChange={(itemValue, itemIndex) => {
                      setSelectedFocusCenter(itemValue)
                    }}
                  >
                  {accountGrpDetails.map(s => {
                    return (
                      <Picker.Item
                        label={`${s.GroupName.length >= ((Platform.OS === 'web' && !isMobile) ? 30 : 15)
                        ? s.GroupName.substring(0, ((Platform.OS === 'web' && !isMobile) ? 30 : 15)) + "..."
                        : s.GroupName}`}
                        value={`${s.Forumid}`}
                        key={`${s.Forumid}`}
                      />
                    )
                  })}
                  </Picker>
                </View> 
              : null}
          </View>
        : null}
        {((curSet || curFocusCenter || search) && !loading) 
          ? <View style={{width: Math.min(Dimensions.get('window').width-26, 600), backgroundColor: '#d4d4d4', borderTopLeftRadius: 5, borderTopRightRadius: 5,
              height: (Object.keys(docChecks).filter(d => docChecks[`${d}`]).length > 0 && Platform.OS === 'ios')  ? 160 : 40, flexDirection: 'row'}}
            >
              {!search ?
              <TouchableOpacity
                onPress={() => {
                  setDocChecks({});
                  setSelectedSet("");
                  setSelectedFocusCenter("");
                  if (curSet) {
                    setInitCall(true);
                    setPorfolioItems([]);
                    setCurSet(null);
                  } else {
                    setCurFocusCenter(null);
                  }
                }}
                style={{flexDirection: 'row', width: 100}}
              >
                <Feather name="arrow-left" style={{ fontSize: 25, paddingLeft: 10, paddingRight: 5, paddingTop: 10 }}/>
                <Text style={{fontWeight: '600', paddingLeft: 10, fontSize: 16, marginTop: 13}}>Back</Text>
              </TouchableOpacity>
              : null}
              {Object.keys(docChecks).filter(d => docChecks[`${d}`]).length > 0 
              ? <View style={{flexDirection: 'row', justifyContent: 'flex-end', flex: 1}}>
                  <View style={{marginRight: Platform.OS === 'ios' ? -Dimensions.get('window').width/5 : null, marginTop: (Platform.OS === 'ios') ? -30 : Platform.OS === 'android' ? -10 : null}}>
                    <Picker
                      selectedValue={`${selectedSet}`}
                      style={{height: 60, width: Platform.OS === 'ios' ? 280 : Platform.OS === 'android' ? 200 : Math.min(Dimensions.get('window').width/3, 300)}}
                      onValueChange={(itemValue, itemIndex) => {
                        setSelectedSet(itemValue)
                      }}
                    >
                      {(curFocusCenter || searchFocusCenter) ?
                        accountGrpSets.map(s => {
                          return (
                            <Picker.Item
                              label={`${s.PortfolioName.length >= ((Platform.OS === 'web' && !isMobile) ? 30 : 15)
                              ? s.PortfolioName.substring(0, ((Platform.OS === 'web' && !isMobile) ? 30 : 15)) + "..." + `${s.TotalDocs ? ` docs: ${s.TotalDocs}` : " docs: 0"}`
                              : s.PortfolioName + `${s.TotalDocs ? ` docs: ${s.TotalDocs}` : " docs: 0"}`}`}
                              value={`${s.Portfolioid},${s.forumid}`}
                              key={`${s.Portfolioid}`}
                            />
                          )
                        })
                      : 
                        accountSets.map(s => {
                          return (
                            <Picker.Item
                              label={`${s.PortfolioName.length >= ((Platform.OS === 'web' && !isMobile) ? 30 : 15)
                              ? s.PortfolioName.substring(0, ((Platform.OS === 'web' && !isMobile) ? 30 : 15)) + "..." + `${s.TotalDocs ? ` docs: ${s.TotalDocs}` : " docs: 0"}`
                              : s.PortfolioName + `${s.TotalDocs ? ` docs: ${s.TotalDocs}` : " docs: 0"}`}`}
                              value={`${s.Portfolioid},${s.forumid}`}
                              key={`${s.Portfolioid}`}
                            />
                          )
                        })
                      }
                    </Picker>
                  </View>
                  {!loading ?
                    <View style={{height: 40}}>
                      <TouchableOpacity
                        onPress={async () => {
                          try {
                            const res = await addDocsToSet(Object.keys(docChecks).filter(d => docChecks[`${d}`]), `${selectedSet.split(",")[0]}`, `${selectedSet.split(",")[1]}`);
                            setDocChecks({});
                            if (res === "Success") {
                              setSuccessNotification("Successfully Added");
                              setTimeout(() => {
                                setSuccessNotification("");
                              }, 3000);
                            } else {
                              setFailureNotification("Failed To Add");
                              setTimeout(() => {
                                setFailureNotification("");
                              }, 3000);
                            }
                          } catch (err) {
                            setFailureNotification("Failed To Add");
                            setTimeout(() => {
                              setFailureNotification("");
                            }, 3000);
                          }
                        }}
                        style={{flexDirection: 'row'}}
                      >
                        <Feather name="plus-circle" style={{ fontSize: 25, paddingLeft: 10, paddingRight: 5, paddingTop: 7, color: 'blue' }}/>
                        <Text style={{ fontWeight: '600', fontSize: 16, marginTop: 12, paddingRight: 5 }}>Add to Set</Text>
                      </TouchableOpacity>
                    </View>
                  : null}
                </View>
              : null}
            </View>
          : null}
          <FlatList
            style={{paddingBottom: 80, marginBottom: (Platform.OS === 'web' && !isMobile) ? null : 80}}
            data={search ? portfolioItems : !curFocusCenter || !accountGrpSets.length > 0 ? accountGrpDetails : portfolioItems.length > 0  ? portfolioItems : accountGrpSets}
            keyExtractor={(item) => search 
              ? `${item.ke_id}` 
              : !curFocusCenter || !accountGrpSets.length > 0 
                ? `${item.Groupid}` : portfolioItems.length > 0  ? `${item.ke_id}` 
                : `${item.Portfolioid}`}
            onEndReachedThreshold={5}
            extraData={search ? portfolioItems : !curFocusCenter || !accountGrpSets.length > 0 ? accountGrpDetails : portfolioItems.length > 0  ? portfolioItems : accountGrpSets}
            onMomentumScrollBegin={() => setCalledDuringMomentum(false)}
            ListHeaderComponent={() => {
              return (
                <>
                {loading ?
                  <ActivityIndicator size="large" style={{marginBottom: 50}} color="#005157"/>
                : null}
                {successNotification !== "" 
                ? <View style={{backgroundColor: '#76b587', opacity: 0.8}}><Text style={{ fontSize: 18, marginLeft: 10}}>{successNotification}</Text></View>
                : null}
                {failureNotification !== "" 
                ? <View style={{backgroundColor: '#a83232', opacity: 0.8}}><Text style={{ fontSize: 18, marginLeft: 10}}>{failureNotification}</Text></View>
                : null}
                </>
              );
            }}
            onEndReached={() => {
              if (!calledDuringMomentum) {
                setDocPage(search ? docPage+1 : !curFocusCenter || !accountGrpSets.length > 0 ? 0 : portfolioItems.length > 0  ? docPage+1 : 0);
                setCalledDuringMomentum(true);
              }
            }}
            renderItem={({item, index}) => {
              return (
                <>
                {((!curFocusCenter || !accountGrpSets.length > 0) && !search) ?
                  <FocusCenterTile
                    indx={index}
                    img={"https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/cdn1/groupImage_" + `${item.Groupid}` +'_'+ `${item.AccountId}` + ".png"}
                    defaultImg={"https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/cdn1/defaultgroup.png"}
                    title={item.GroupName.length >= ((Platform.OS === 'web' && !isMobile) ? 30 : 20) 
                      ? item.GroupName.substring(0, ((Platform.OS === 'web' && !isMobile) ? 30 : 20)) + "..." 
                      : item.GroupName
                    }
                    numMembers={"..."}
                    setFocusCenterChecks={setFocusCenterChecks}
                    focusCenterChecks={focusCenterChecks}
                    groupId={item.Groupid}
                    forumId={item.Forumid}
                    accountId={item.AccountId}
                    visibilityRestriction={item.VisibilityRestriction}
                    activeUsersCount={item.ActiveUsersCount}
                    onPress={(fCenter) => setCurFocusCenter(fCenter)}
                    fCenter={item}
                    endIndx={accountGrpDetails.length-1}
                    navigation={navigation}
                  />
                : <>{portfolioItems.length > 0 ?
                  <DocTile
                    title={item.title.length >= ((Platform.OS === 'web' && !isMobile) ? 30 : 15) 
                      ? item.title.substring(0, ((Platform.OS === 'web' && !isMobile) ? 30 : 15)) + "..." 
                      : item.title
                    }
                    ke_id={item.ke_id}
                    onPress={(ke_id, uri, accountId) => navigation.navigate('PdfViewer', { ke_id: ke_id, accID: accountId, uri: uri, forumId: item.forumId, portfolioId: item.portfolioId })}
                    indx={index}
                    accountId={account.AccountId}
                    path={item.pdf_link}
                    docChecks={docChecks}
                    setDocChecks={setDocChecks}
                    removeDoc={removeDocFromSet}
                    setSuccessMsg={setSuccessNotification}
                    setErrorMsg={setFailureNotification}
                    forumId={(Array.isArray(item.forumId) && searchFocusCenter) ? item.forumId.find(f => f == searchFocusCenter.Forumid) : !Array.isArray(item.forumId) ? item.forumId : null}
                    portfolioId={(Array.isArray(item.portfolioId) && searchSet) ? item.portfolioId.find(p => p.toLowerCase() == searchSet.Portfolioid.toLowerCase()) : !Array.isArray(item.portfolioId) ? item.portfolioId : null}
                    setDocs={setPorfolioItems}
                    allDocs={portfolioItems}
                    navigation={navigation}
                    IsIssueCoded={item.IsIssueCoded}
                    alerts={item.alerts}
                    importance={item.importance}
                    description={item.description}
                    endIndx={portfolioItems.length-1}
                    pageCount={item.pageCount}
                  />
                 : <>{accountGrpSets.length > 0 ?
                    <SetTile
                      title={item.PortfolioName.length >= ((Platform.OS === 'web' && !isMobile) ? 30 : 20) 
                        ? item.PortfolioName.substring(0, ((Platform.OS === 'web' && !isMobile) ? 30 : 20)) + "..." 
                        : item.PortfolioName
                      } 
                      numDocs={item.TotalDocs}
                      setSetChecks={setSetChecks} 
                      setChecks={setChecks} 
                      forumId={item.forumid}
                      portfolioId={item.Portfolioid}
                      onPress={(set) => setCurSet(set)} 
                      indx={index}
                      set={item}
                      setFocusCenterChecks={setFocusCenterChecks}
                      focusCenterChecks={focusCenterChecks}
                      removeSet={removeSet}
                      setSets={setAcctGrpSets}
                      accountId={account.AccountId}
                      acctSets={accountGrpSets}
                      setSuccessMsg={setSuccessNotification}
                      setErrorMsg={setFailureNotification}
                      navigation={navigation}
                      isLocked={item.IsLocked}
                      totalCoded={item.TotalCoded}
                      endIndx={accountGrpSets.length-1}
                    />
                   : null}</>
                }</>
                
                }
                </>
              );
            }}
          />
        </View>
        : <ActivityIndicator size="large" style={{marginBottom: 50}} color="#005157"/>}
    </View>
  );
};

const styles = StyleSheet.create({
    container: {
      paddingTop: 20,
      justifyContent: 'center', 
      alignItems: 'center',
      height: Dimensions.get('window').height-10,
    }
});

export default KioskHome;