import React, { useContext } from "react";
import { createStackNavigator, HeaderBackButton } from "@react-navigation/stack";
import AccountHomeScreen from "../../screens/AccountHomeScreen";
import AccountSettingsScreen from "../../screens/AccountSettingsScreen";
import PassportScreen from "../../screens/PassportScreen";
import AccountUserScreen from "../../screens/AccountUserScreen";
import CreateLandingPageScreen from '../../screens/CreateLandingPageScreen2';
import CreateAdvertisementScreen from '../../screens/CreateAdvertisementScreen';
import ManageCampaignScreen from '../../screens/ManageCampaign';
import UploadContentScreen from '../../screens/UploadContentScreen';
import AddUserScreen from '../../screens/AddUserTempScreen';
import ManageFocusCenterScreen from '../../screens/ManageFocusCenterScreen';
import ManageSet from '../../screens/ManageSet';
import determineUrl from '../../screens/utils/determineUrl';
import TagManager from 'react-gtm-module';
import { Platform } from 'react-native';
import { Context as PubContext } from '../../context/PubContext';
import ResultsShowScreen from '../../screens/ResultsShowScreen';
import AddTagScreen from '../../screens/AddTagScreen';
import TagManagerScreen from '../../screens/TagManagerScreen';
import PublishArticleScreen from '../../screens/PublishArticle';

const Stack = createStackNavigator();

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const accountFlow = () => {
  const  PubCon = useContext(PubContext);
  return (
    <Stack.Navigator
      initialRouteName="AccountHome"
      screenOptions={{ gestureEnabled: true }}
    >
      <Stack.Screen
        name="AccountHome"
        component={AccountHomeScreen}
        options={{ title: "Mesearch", headerShown: false }}
      />
      <Stack.Screen
        name="Passport"
        component={PassportScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="AccountSettings"
        component={AccountSettingsScreen}
        options={{ title: "Mesearch", headerShown: true }}
      />
      <Stack.Screen
        name="AccountUser"
        component={AccountUserScreen}
      />
      <Stack.Screen
        name="CreateLandingPage"
        component={CreateLandingPageScreen}
        options={{ title: "Mesearch", headerShown: true }}
      />
      <Stack.Screen
        name="CreateAdvertisement"
        component={CreateAdvertisementScreen}
        options={{ title: "Mesearch", headerShown: true }}
      />
      <Stack.Screen
        name="ManageCampaign"
        component={ManageCampaignScreen}
        options={{ title: "Mesearch", headerShown: true }}
      />
      <Stack.Screen
        name="UploadContent"
        component={UploadContentScreen}
        options={{headerTitle: "Account"}}
      />
      <Stack.Screen
        name="AddUser"
        component={AddUserScreen}
        options={{ title: "Mesearch", headerShown: true }}
      />
      <Stack.Screen
        name="ManageFocusCenter"
        component={ManageFocusCenterScreen}
        options={{ title: "AccountHome", headerShown: true }}
      />
      <Stack.Screen
        name="ManageSet"
        component={ManageSet}
        options={{ title: "AccountHome", headerShown: true }}
      />
      <Stack.Screen
        name="ResultsShow"
        component={ResultsShowScreen}
        options={({navigation}) => ({
            gestureEnabled: true,
            headerLeftContainerStyle: {
                height: 50
            },
            //headerStyle: {height: 50},
            //title: Platform.OS === 'web' ? "Back" : "",
            headerTitle: Platform.OS === 'web' ? "Back" : "",
            headerLeft: () => {return (<HeaderBackButton onPress={() => {
                if (Platform.OS === 'web') {
                    let search = window.location.search;
                    let href = window.location.href;
                    const referrer = determineUrl(search, href);
                    let params = new URLSearchParams(search);
                    let pub = params.get('p');
                    if (pub) {
                      history.pushState(null, 'body', `/?p=${pub}`);
                    } else {
                      const pubPageMatches = referrer.match(/[^\/]+/g);
                      let pubPage = (pubPageMatches && pubPageMatches.length >= 3) ? pubPageMatches[2] : '/';
                      history.replaceState(null, 'body', `/${pubPage}` === '//' ? '/' : `/${pubPage}`);
                    }
                    let date = new Date();
                    const tagManagerArgs = {
                    gtmId: PubCon.state.publisher.gtmId, //'GTM-59ZPF2B'
                        dataLayer: {
                            FullSection: 'home',
                            CanonicalUrl: `${referrer}`,
                            Headline: 'Home',
                            Author: '',
                            Catagory: 'Home',
                            SubCategory: 'Home',
                            Description: '',
                            Published: '',
                            Published_short: '',
                            Updated: '',
                            StoryType: '',
                            Day_of_week: `${days[date.getDay()]}`,
                            Month: `${months[date.getMonth()]}`,
                            Day: `${date.getDay()}`,
                            Hour: `${date.getHours()}`,
                            Year: `${date.getFullYear()}`, 
                            Server: '', 
                            Domain: `${referrer}`,
                            Testing: 'Yes', 
                            User: '', 
                            Logged: '',
                            BodyId: '',
                            Publication: `${PubCon.state.publisher.username}`
                        }
                    }
                    if (PubCon.state.publisher.gtmId) {
                      TagManager.initialize(tagManagerArgs);
                    }
                }
                navigation.goBack(null);
                }} style={{paddingTop: Platform.OS === 'web' ? 14 : 0}}/>)}
        })}
    />
    <Stack.Screen
      name="TagManager"
      component={TagManagerScreen}
      options={{title: "TagManager", headerTitle: 'back'}}
    />
    <Stack.Screen
      name="AddTags"
      component={AddTagScreen}
      options={{title: "AddTags", headerTitle: 'back'}}
    />
    <Stack.Screen
      name="PublishArticle"
      component={PublishArticleScreen}
      options={{ title: "Mesearch", headerShown: true }}
    />
    </Stack.Navigator>
  );
};

export default accountFlow;
