import React, { useState } from 'react';
import { View, Text, Image, ImageBackground, Dimensions, StyleSheet } from 'react-native';


const GridDetailRestaurant = ({ result, horizontal, darkMode, tagBarColor }) => {

  const normalizeCasing = (title, comma=true) => {
    if (title) {
      let titlePrts = title.split(" ");
      for (let i = 0; i < titlePrts.length; i++) {
        if (titlePrts[i].length > 0) {
          titlePrts[i] = titlePrts[i][0].toUpperCase() + titlePrts[i].substr(1).toLowerCase();
        }
      }
      titlePrts = titlePrts.join(" ");
      return comma ? `${titlePrts},` : titlePrts;
    } else {
      return "";
    }
  }

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
  }

  const styles =  StyleSheet.create({
    txtStyleField: {
        fontSize: 22,
        fontWeight: '600',
        color: darkMode ? 'white' : '#5c5c5c',
        paddingBottom: 5,
        paddingTop: 5,
        paddingLeft: 10
    },
    txtBtnStyle: {
        fontSize: 12,
        fontWeight: '600',
        color: 'white',
        padding: 5,
    },
    btnStyle: {
        flexDirection: 'row', 
        width: 70, 
        height: 40, 
        backgroundColor: tagBarColor,
        justifyContent: 'center', 
        alignItems: 'center',
        marginLeft: 10,
        marginTop: 5,
        borderRadius: 5,
    },
  });

  return (
    <ImageBackground
      source={{uri: result.body[0].media[0]}}
      style={{
        marginLeft: horizontal? 15 : null,
        marginTop: horizontal? null : 15,
        width: horizontal ? (result.claimed !== "no" && result.premium) ? Math.min(Dimensions.get('window').width-30, 500) : 250 : Math.min(Dimensions.get('window').width-30, 500),
        height: horizontal ? 250 : Math.min(Dimensions.get('window').width-30, 500),
      }}
      imageStyle={{borderRadius: 5}}
      blurRadius={result.claimed !== "no" ? null : 8}
    >
      <View style={{flex: 1, backgroundColor: result.claimed !== "no" ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0.5)', borderRadius: 5}}>
        <View style={{paddingTop: 20, paddingLeft: 10, paddingRight: 15, height: 120}}>
          <Text style={[styles.txtStyleField, {color: 'white'}]} numberOfLines={3}>{result.businessName ? normalizeCasing(result.businessName, false) : normalizeCasing(result.body[0].title, false)}</Text>
        </View>
        <View style={{padding: 10}}>
          {result.location 
            ? <Text numberOfLines={2} style={{paddingTop: 20, color: 'white', paddingLeft: 10, paddingRight: 10, fontWeight: '600'}}>
              {`${normalizeCasing(result.location.street)} ${normalizeCasing(result.location.city)} ${normalizeCasing(result.location.state)} ${normalizeCasing(result.location.postalCode)}`.replace(/(^,)|(,$)/g, "")}
              </Text> 
            : null}
          {result.phoneNum ? <Text numberOfLines={1} style={{paddingTop: 5, color: 'white', paddingLeft: 10, paddingRight: 10, fontWeight: '600'}}>{formatPhoneNumber(result.phoneNum)}</Text> : null}
        </View>
      </View>
    </ImageBackground>
  );
};

export default GridDetailRestaurant;