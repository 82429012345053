import React, { useEffect, useState } from 'react';
import { TouchableOpacity, Text } from 'react-native';
import { Button } from 'react-native-elements';
import { Platform } from 'react-native';
import { getDocumentAsync } from 'expo-document-picker';
import { readAsStringAsync } from 'expo-file-system';
import base64ToBlob from './utils/base64toBlob';
import MeSearchAPI from '../api/MeSearch-API';
import { Feather } from '@expo/vector-icons';

const FileParserInput = ({ getFileContent }) => {

  const handleUpload = async () => {
    const { uri } = await getDocumentAsync({ type: 'text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    const regex = /officedocument/g;
    const found = uri.match(regex);
    if (found) {
      const docx = uri;
      try {
        const postResponse = await MeSearchAPI.post('/api/content/docx-base64-to-text', { docx });
        const { text } = postResponse.data;
        getFileContent(text, null);
      } catch (error) {
        getFileContent('', error);
      }
    } else {
      if (Platform.OS === 'web') {
        const base64Text = uri.split(',')[1];
        let blob = base64ToBlob(base64Text, 'text/plain');
        try {
          const resultingText = await blob.text();
          getFileContent(resultingText, '');
        } catch (error) {
          getFileContent('', error);
        }
      } else {
        try {
          const resultText = await readAsStringAsync(uri);
          getFileContent(resultText, null);
        } catch (error) {
          getFileContent('', error);
        }
      }
    }
  };

  return (
    <>
      <TouchableOpacity
        onPress={() => handleUpload()}
        style={{
          flexDirection: 'row',
          backgroundColor: 'rgb(31,137,220)',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 7,
          margin: 2,
          borderRadius: 5,
        }}
      >
        <Feather name="upload" size={14} color="white" onPress={handleUpload} style={{paddingRight:5}} />
        <Text style={{color: 'white', fontSize: 14}}>Upload File</Text>
      </TouchableOpacity>
    </>
  );
};

export default FileParserInput;