import React, {useState, useContext, useEffect} from 'react';
import { SafeAreaView,  StyleSheet,  View,  FlatList, Dimensions, Image,  Text, TextInput, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import MeSearchApi from '../api/MeSearch-API';
import { Rect, Text as TextSVG, Svg } from "react-native-svg";
import { Feather, FontAwesome, Foundation } from '@expo/vector-icons';
import { Context as PubContext } from '../context/PubContext';
import { isMobile, isTablet } from 'react-device-detect';

//import React Native chart Kit for different kind of Chart
import {
  LineChart,
  BarChart,
  PieChart,
  ProgressChart,
  ContributionGraph,
  StackedBarChart,
} from 'react-native-chart-kit';

const PubTagStatScreen = ({navigation, route}) => {
  
  const pubName = route.params?.pubName ?? '';
  const { state } = useContext(PubContext);

  const [refData] = useState([{id:1}]);
  const [rowData, setRowData] = useState([0,2]);
  const [colData, setColData] = useState([0,2]);
  const [solrData, setSolrData] = useState([0,2]);
  const [hdrData, setHdrData] = useState([0,2]);
  const [isLoading, setIsLoading] = useState(false);

  let rsData =''
  const [pieChart, setPiechart] = useState([{name: "Desktop", population: 700, color: "rgb(250,2,166)", legendFontColor: "#7F7F7F", legendFontSize: 15},{name: "Desktop", population: 700, color: "rgb(250,2,166)", legendFontColor: "#7F7F7F", legendFontSize: 15}]);
  let chartDevices=[];

  const [dayLabel, setDaylabel] = useState([]);
  const [dayData, setDayData] = useState([0,2]);
  let WeekdayLabel=[];
  let WeekdayData=[];

  const [hrsLabel, setHrlabel] = useState([]);
  const [hrsData, setHrData] = useState([0,2]);
  let hoursLabel=[];
  let hoursData=[];

  const [tagTxt, setTagTxt] = useState("");
  const [tagLabel, setTaglabel] = useState([]);
  const [tagData, setTagData] = useState([0,2]);
  let tagsLabel=[];
  let tagsData=[];

  const [heatData, setHeatData] = useState([]);
  let hdr=[];
  let curBadge = "";

  const getYMD = () => {
    // Get ISO date range for now
    const startDateTimeMS = new Date();
    const startDateMS = startDateTimeMS.setDate(startDateTimeMS.getDate() - 7);
    const startISODate = new Date(startDateMS);
    const startYMD = `${startISODate.getUTCFullYear()}-${`${startISODate.getUTCMonth()}`.length === 1 
      ? `0${startISODate.getUTCMonth()+1}` 
      : `${startISODate.getUTCMonth()+1}`}-${`${startISODate.getUTCDate()}`.length === 1 ? `0${startISODate.getUTCDate()}`: `${startISODate.getUTCDate()}`}`;
    return startYMD;
  }
 
  const [rptDate, setRptDate] = useState(getYMD());
  const [rsCount, setRsCount] = useState(0);

  const getRandomColor = () =>
  {
      return 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';
  }

  const getStatsApi = async (txt) => {
    try {
      setIsLoading(true);
      const response = await MeSearchApi.get('/api/test/solr-tagsbytime', {params: {tags: txt, pub: state.publisher.username }});
      rsData = response.data
 
      let tag = rsData.facet_counts.facet_fields.tags
      let heat = rsData.facet_counts.facet_pivot['displaydate,tags']
      
      setRsCount(rsData.response.numFound);
      setIsLoading(false);
      ///////////////////////////////////////////////////////
      // LOAD DEVICE ARRAY
      ///////////////////////////////////////////////////////

      let i=0; 
      let cnt=0; 
      let term='';

      ///////////////////////////////////////////////////////
      // LOAD TAG ARRAY
      ///////////////////////////////////////////////////////

      i=0; cnt=0; term='';
      tag.forEach((key, el) => {
        if (i % 2 ==0){
          term = key;
        }
        else{
          cnt=key
          tagsLabel.push({term, cnt});
          tagsData.push([cnt]);
        }
        i++
      });

      setTaglabel(tagsLabel);
      setTagData(tagsData);

      let pkid=0;
      heat.forEach((key, el) => {
        if (key!=null){
          //alpha.push(key.value)
          key.pivot.forEach((ky, e) => {
            hdr.push({pk:pkid, date:key.value, tagname:ky.value, tagcount:ky.count})
            pkid++
          });
        }
      });
      hdr.sort((a,b) => new Date(b.date) - new Date(a.date));
      setHeatData(hdr);
      
      ///////////////////////////////////////////////////////
      // LOAD HEAT ARRAYS
      ///////////////////////////////////////////////////////

      // i=0; cnt=0; term='';
      // debugger
      // heat.forEach((key, el) => {
      //   if (key!=null){
      //     // alpha.push(key.value)
      //     // key.pivot.forEach((ky, e) => {
      //     //   hdr.push(ky.value)
      //     // });
      //   }
      // });

      //hdr.sort();
      //alpha.sort();

      //let dhdrs = [...new Set(hdr)]; // distinct array

    } catch (err) {
      console.log(err)
    }
  }

  ///////////////////////////////////////////////////

  let [tooltipPos1, setTooltipPos1] = useState({ x: 0, y: 0, visible: false, value: 0 })
  let [tooltipPos2, setTooltipPos2] = useState({ x: 0, y: 0, visible: false, value: 0 })

    return (
      <ScrollView contentContainerStyle={{justifyContent:'center', alignItems:'center'}}>
      {!isLoading ?
      <>
       <View style={{ borderColor: 'gray', borderWidth: 2, marginTop: 10, backgroundColor: '#F0EEEE', height: 50, borderRadius: 5, flexDirection: 'row', marginBottom: 10, width: 250}}>
            <TextInput
                autoCapitalize="none"
                autoCorrect={false}
                style={{ fontSize: 20 }}
                placeholder={"Enter a tag"}
                value={tagTxt}
                onChangeText={(txt) => setTagTxt(txt)}
                onFocus={(evnt) => setTagTxt(evnt.nativeEvent.text)}
                onSubmitEditing={() => {
                  if (!isLoading) {
                    getStatsApi(tagTxt);
                  }
                }}
                onEndEditing={() => {
                  if (!isLoading) {
                    getStatsApi(tagTxt);
                  }
                }}
                multiline={false}
                maxLength={120}
            />
        </View>
      {heatData.length > 0 ?
      <>
      <View style={{marginTop:50, paddingBottom:10, 
        paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : 18,
        paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : 18,
        flexDirection:'row', width:Dimensions.get('window').width, justifyContent:'space-between'}}>
  
       <Text style={{fontWeight:'bold'}}>TAGGING INSIGHTS: {rptDate} to present for {pubName} publication</Text> 
       <Feather name="refresh-cw" style={{fontSize: 20, color: '#2da5ff', width: 35}} onPress={() => getStatsApi(tagTxt)}/> 
      </View>
      <View style={{paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : 18}}>
      <Text style={{fontWeight:'bold'}}>Result Total: {rsCount !== -1 ? `${rsCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : ""} </Text>
      </View>

    <View 
        style={{padding:20}}
        showsVerticalScrollIndicator={false}
    >
      <View style={{
          width: Dimensions.get('window').width,
          paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : 18,
          paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : 18,
        }}>


    {/*<View style={{borderRadius: 4, borderColor:'silver', borderWidth:1, marginTop:10, padding:5}}>
        <Text style={{fontWeight:'bold'}} >Tags by Date</Text>
        <Text style={{color:'grey'}}>{rptDate} to present</Text>  

    <LineChart
      data={{
        labels: [],
        datasets: [
          {
            data: tagData, //dayData
            color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
            strokeWidth: 2,
          },
        ],
      }}
      width={Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width)/2) : Dimensions.get('window').width-50}
      height={220}
      chartConfig={{
        backgroundColor: '#1cc910',
        backgroundGradientFrom: '#eff3ff',
        backgroundGradientTo: '#efefef',
        fillShadowGradient:'skyblue',
        fillShadowGradientOpacity:1,
        decimalPlaces: 0,
        color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
        style: {
          borderRadius: 16,
        },
      }}
      style={{
        marginVertical: 8,
        borderRadius: 16,
      }}

      decorator={() => {
        return tooltipPos1.visible ? <View>
            {<Svg>
                <Rect x={tooltipPos1.x - 15} 
                    y={tooltipPos1.y + 10} 
                    width="40" 
                    height="30"
                    fill="black" />
                    <TextSVG
                        x={tooltipPos1.x + 5}
                        y={tooltipPos1.y + 30}
                        fill="white"
                        fontSize="16"
                        fontWeight="bold"
                        textAnchor="middle">
                        {tooltipPos1.value}
                    </TextSVG>
            </Svg>}
        </View> : null
    }}

    onDataPointClick={(data) => {

        let isSamePoint = (tooltipPos1.x === data.x 
                            && tooltipPos1.y === data.y)

        isSamePoint ? setTooltipPos1((previousState) => {
            return { 
                      ...previousState,
                      value: data.value,
                      visible: !previousState.visible
                   }
        })
            : 
        setTooltipPos1({ x: data.x, value: data.value, y: data.y, visible: true });

    }}
  />
 </View>*/}
    <View>
    <Text style={{fontWeight:'bold', paddingTop:10, paddingBottom:10}}>TAG USAGE:</Text> 
    <FlatList
      style={{
        paddingTop: 5,
        width: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 285 : 450
      }}
      horizontal={false}
      data={tagLabel}
      keyExtractor={item => item.term}
      numColumns={2}
      renderItem={({ item }) => {
        return (
              <Text style={{width:200}}>{`${item.term} (${item.cnt})`}</Text>
        );
      }}
    />
    <Text style={{fontWeight:'bold', paddingTop:10, paddingBottom:10}}>TIMELINE:</Text> 
    <FlatList
      style={{
        width: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 285 : 450
      }}
      horizontal={false}
      data={heatData}
      keyExtractor={item => item.pk}
      renderItem={({ item }) => {
        let dateBadge = ''

        if (item.date!=curBadge){
          curBadge=item.date
          dateBadge=item.date
        }

        return (
          <View>
            {dateBadge!=''
            ?
            <Text style={{color:'white', fontWeight:'bold', backgroundColor:'#3498db', width:100, textAlign:'center'}}>{`${dateBadge}`}</Text>
            :null
            }
            
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Feather name="more-vertical" style={{fontSize: 25, paddingLeft: 8, color:'silver'}}/>
              <Text style={{paddingLeft:40}}>{`${item.tagname} (${item.tagcount})`}</Text>
            </View>
          </View>
        );
      }}
    />

    </View>
      </View>

    </View>
    </>
    : null}
    </>
    : <View style={{flex:1}}><ActivityIndicator style={{marginTop:150}} color="#005157" size="large"/></View> }
    </ScrollView>
    )
}

export default PubTagStatScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  cell10:{
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    width:30
  },
  cell50: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    width:50
  },

});