import React, { useContext } from 'react';
import { View, StyleSheet, ImageBackground } from 'react-native';
import { Platform } from 'react-native';
import { isMobile } from 'react-device-detect';
import {Context as PubContext} from '../context/PubContext'; 

const PubImgHeader = ({ fetchPubAsset, darkMode }) => {
    const {state} = useContext(PubContext);

    return (
        <>
            {
              state.publisher.username !== 'TribExtra-AP' || state.publisher.username !== 'Sponsored Sports 2' ? (
                <View style={{flex: 1, marginBottom: 10}}>
                    <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                        <View style={styles.imageHeader}>
                        <ImageBackground 
                        style={styles.imageHeader} 
                        //imageStyle={{resizeMode: 'contain'}}
                        source={(Platform.OS === 'web' && !isMobile) ? {uri: darkMode ? fetchPubAsset('TopHeader-Web-DarkMode') : fetchPubAsset('TopHeader-Web')} : {uri: darkMode ? fetchPubAsset('TopHeader-Mobile-DarkMode') : fetchPubAsset('TopHeader-Mobile')}}
                        defaultSource={Platform.OS === 'web' 
                        ? (Platform.OS === 'web' && !isMobile) 
                            ? {uri: darkMode ? fetchPubAsset('TopHeader-Web-DarkMode') : fetchPubAsset('TopHeader-Web')} 
                            : {uri: darkMode ? fetchPubAsset('TopHeader-Mobile-DarkMode') : fetchPubAsset('TopHeader-Mobile')}
                        : null}
                        >
                        </ImageBackground>
                        </View>
                    </View>
                </View>
              ) : null
            }
        </>
    );
};

const styles =  StyleSheet.create({
    imageHeader: {
        width: (Platform.OS === 'web' && !isMobile) ? 750 : 300,
        height: (Platform.OS === 'web' && !isMobile) ? 200 : 90,
        marginLeft: 10,
        marginRight: (Platform.OS === 'web' && !isMobile) ? 120 : 40,
    },
});

export default PubImgHeader; 