import React from 'react';
import { View, TextInput, StyleSheet, Image, TouchableOpacity, Text, Linking, Dimensions } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';

const DineLocalSearchBar = ({ term, onTermChange, onTermSubmit, placeholder, searchDesc, searchDescLink, searchBoxColor }) => {

  const styles = StyleSheet.create({
    backgroundStyle: {
      marginTop: 10,
      backgroundColor: 'white',
      height: 40,
      width: 260,
      borderRadius: 5,
      marginHorizontal: 5,
      flexDirection: 'row',
      marginBottom: 10,
    },
    inputStyle: {
      flex: 1,
      fontSize: 20,
      paddingLeft: 10,
      width: (Platform.OS === 'web' && isMobile) ? 220 : 260
    },
    iconstyle: {
      fontSize: 36,
      //backgroundColor: "#B32226",
      borderRadius: 5
    },
    searchDescription: {
      fontSize: (Dimensions.get('window').height < 600) ? 14 : 18,
      color: 'white',
      //padding: 5,
      //flexDirection: 'row',
      //paddingTop: 20
    }
  });

  return (
    <>
        <Text
          style={styles.searchDescription}
        >
          {searchDesc}
          {searchDescLink ?
          <TouchableOpacity
            onPress={() => {
              Platform.OS === 'web' 
                ? window.open(searchDescLink.uri) 
                : Linking.openURL(searchDescLink.uri);
            }}
          >
            <Text style={[styles.searchDescription, {fontWeight: Platform.OS !== 'web' ? '800' : '750', marginBottom: Platform.OS !== 'web' ? -3 : null, color: '#00a2ff'}]}>
              {"  " + searchDescLink.txt}
            </Text>
          </TouchableOpacity>
          : null}
        </Text>
        <View style={styles.backgroundStyle}>
        <TouchableOpacity
          onPress={onTermSubmit}
          style={{flexDirection: 'row'}}
        >
        <View style={{backgroundColor: searchBoxColor, borderRadius: 5}}>
        <Feather name="search" style={styles.iconstyle}/>
        </View>
        <View style={{backgroundColor: searchBoxColor, width: 5, height: 40, marginLeft: -4}}/>
        </TouchableOpacity>
        <TextInput
            autoCapitalize="none"
            autoCorrect={false}
            style={styles.inputStyle}
            placeholder={placeholder}
            value={term}
            onChangeText={onTermChange}
            onSubmitEditing={onTermSubmit}
            multiline={false}
            maxLength={120}
        />
        </View>
        <View style={{flexDirection: 'row', marginRight: 122}}>
          <Text style={{color: 'white', paddingRight: 5, fontSize: 12, marginTop: 5}}>Powered by</Text>
          <Image 
            style={{height: 28, width: 48}} 
            source={{uri: "https://cdn.mesearch.ai/assets/mesearch/header-mobile-dm.png"}} 
            defaultSource={Platform.OS === 'web' ? {uri: "https://cdn.mesearch.ai/assets/mesearch/header-mobile-dm.png"} : null}
          />
        </View>
    </>
  );
};

export default DineLocalSearchBar;