import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

const instance = axios.create({
  //baseURL: 'http://192.168.1.60'
  //baseURL: 'http://192.168.1.121:8080/'
  //baseURL: 'https://mesearch.ai:8443/',
  //baseURL: 'http://192.168.0.43:8080/'
  //baseURL: 'http://10.152.104.222:8080/' //staging
  //baseURL: 'http://10.142.22.53:8080/'
  //baseURL: 'http://192.168.1.41:8080/'
  //baseURL: 'http://10.142.22.5:8080/'
  // baseURL: 'http://192.168.42.103:8080/', //HZ
  //baseURL: 'http://192.168.1.155:8080/' //AK
  //baseURL: 'http://192.168.86.36:8080/' //AKC
  //baseURL: 'http://172.17.104.5:8080/' // WA 
  baseURL: 'https://mesearch.ai/',
  //rejectUnauthorized: false
});

instance.interceptors.request.use(
  async (config) => {
    const token = await AsyncStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, 
  (err) => {
    return Promise.reject(err);
  }
);

// singleton instance
export default instance;