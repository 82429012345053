import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  TouchableOpacity,
  TextInput,
  FlatList,
  ScrollView,
  ActivityIndicator,
  ImageBackground
} from "react-native";
import { Platform } from 'react-native';
import { Feather, MaterialCommunityIcons, Ionicons } from "@expo/vector-icons";
import AccountHomeUserProfile from "../../core/AccountHomeUserProfile";
import AccountHomeFocusCenters from "../../core/AccountHomeFocusCenters";
import AccountHomeBtnList from "../../core/AccountHomeBtnList";
import AccountHomeCustomBtn from "../../core/AccountHomeCustomBtn";
import useAccount from '../../../hooks/useAccount';
import MeSearchAPI from '../../../api/MeSearch-API';
import MeSearchAPIKiosk from '../../../api/kiosk/MeSearch-API-Kiosk';
import { isMobile, isTablet } from 'react-device-detect';
import { Context as PubContext } from '../../../context/PubContext';
import { Context as AuthContext } from '../../../context/AuthContext';
import predicateSearch from '../../../screens/utils/predicateSearch';
import imagePick from '../../utils/imagePick';
import BusinessTypesEnum from '../../../screens/utils/businessTypesEnum';
import useManagedContent from '../../../hooks/useManagedContent';
import alertToConfirm from '../../utils/alertToConfirm';
import useKioskResults from '../../../hooks/kiosk/useKioskResults';


const AccountHomeWrapper = ({ navigation, route, name, type, account, myAccLvl, imageUri, adminCapability, hasAccounts, horizontal, setHorizontal, darkMode, setAdmins }) => {
  const [imgErr, setImgErr] = useState(false);
  const { state } = useContext(PubContext);
  const { kSignout } = useContext(AuthContext);
  const [activeBtnTitle, setActiveBtnTitle] = useState("");
  const [lPgSuccessMsg, setLPgSuccessMsg] = useState("");
  const [pubErrorMsg, setPubErrorMsg] = useState("");
  const [pubSuccessMsg, setPubSuccessMsg] = useState("");
  const [usrErrorMsg, setUsrErrorMsg] = useState("");
  const [usrSuccessMsg, setUsrSuccessMsg] = useState("");
  const [lPgErrorMsg, setLPgErrorMsg] = useState("");
  const [searchTxt, setSearchTxt] = useState("");
  const [base64Img, setbase64Img] = useState(null);
  const [imgProcessing, setImgProcessing] = useState(false);
  const [tmpBtnList, setTmpBtnList] = useState([{title: "Users"}]);
  const [ accounts, accountGrpDetails,
    accountGrpSets, accountCodingFrm, 
    accountCodingData, loading, error, 
    getAccounts, getAccountGrpDetails, 
    getGroupSets, getCodingFrm, 
    getCodingData, setCodingData, putCodingData, 
    saveCodingSuccess, setSaveCodingSuccess,
    getPortfolioItems, getPdfBase64, portfolioItems, pdfBase64,
    accountCodingHistory, getCodingHistory,
    guid, setGuid,
    getSearch,searchResults, getAdminsApi, admins, adminsLoading, adminsError, setAccountAdmins, setAccGrpDetails ] = useKioskResults();

    // TODO: Re-Implement this functionality with kiosk hook functions
  /*useEffect(() => {
    if (pubOwner) {
      setPubOwner(pubOwner.username);
    }
  }, [pubOwner]);

  useEffect(() => {
    if (refreshAdmins) {
      getAdminsApi();
    }
  }, [refreshAdmins]);*/

  // TODO: instead call the fopcus room hook function
  /*
  const fetchFocusRooms = async () => {
    
  };*/

  useEffect(() => {
    setSearchTxt("");
    setLPgErrorMsg("");
    setLPgSuccessMsg("");
    setPubSuccessMsg("");
    setPubErrorMsg("");
    setUsrSuccessMsg("");
    setUsrErrorMsg("");
    if (activeBtnTitle === 'Focus Centers') {
      getAccountGrpDetails(account.AccountId);
    } else if (activeBtnTitle === 'Users') {
      getAdminsApi(account.AccountId);
    } 
  }, [activeBtnTitle]);

  useEffect(() => {
    if (account) {
        setImgErr(false);
        setAccGrpDetails([]);
        setAccountAdmins([]);
        getAccountGrpDetails(account.AccountId);
        getAdminsApi(account.AccountId);
    }
  }, [account]);  

  useEffect(() => {
    const newTmpBtnList = [
        {
          title: "Users",
        },
        {
          title: "Focus Centers",
        },
    ];
    setTmpBtnList(newTmpBtnList);
  }, []);

  const btnClickHandler = (btnTitle) => {
    setActiveBtnTitle(btnTitle);
  };

  useEffect(() => {
    setActiveBtnTitle(tmpBtnList[0].title);
  }, []);

  const setNewImage = async () => {
    setImgProcessing(true);
    // TODO: HACK 
    // Expo web doesn't resolve the following promise on cancel
    if (Platform.OS === 'web') {
      setTimeout(() => {
        setImgProcessing(false);
      }, [8000]);
    }
    let resultUri = await imagePick();
    if (resultUri !== "") {
      try {
        await MeSearchAPI.post('/api/kiosk/accounts/upload-display-pic-base64', { image: resultUri });
        setbase64Img(resultUri);
        setImgProcessing(false);
      } catch (err) {
        console.log(err);
        setImgProcessing(false);
      }
    } else {
      setImgProcessing(false);
    }
  }

  /*const getAccLvl = (cRole) => {
    switch (cRole) {
      case "Owner":
        return 5;
      case "Admin":
        return 4;
      case "Supervisor":
        return 4;
      case "Guest":
        return 3
      default: 
        return 3;
    }
  }*/

  return (
    <View>
      <View>
        <View style={{ alignItems: "center" }}>
          <View>
            <View style={{alignItems: "center", justifyContent: "center"}}>
              {!imgProcessing ?
                <>
                  <Image
                    style={{
                      height: (Platform.OS === 'web' && !isMobile) ? 250 : 124,
                      width: (Platform.OS === 'web' && !isMobile) ? 250 : 124,
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 20
                    }}
                    source={{ 
                      uri: imgErr 
                      ? "https://cdn.mesearch.ai/assets/stockPhotos/60.jpg"
                      : base64Img 
                      ? base64Img 
                      : imageUri 
                        ? imageUri
                        : "https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/cdn1/AccountImage_"+ `${account.AccountId}` + ".png" 
                          ? "https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/cdn1/AccountImage_"+ `${account.AccountId}` + ".png" 
                          : "https://cdn.mesearch.ai/assets/stockPhotos/60.jpg" 
                    }}
                    onError={() => setImgErr(true)}
                  />
                  {(adminCapability && myAccLvl >= 0) ?
                  <TouchableOpacity
                    style={{
                      backgroundColor: "rgba(0,0,0,0.6)",
                      position: "absolute",
                      zIndex: 1,
                      width: (Platform.OS === 'web' && !isMobile) ? 250 : 124,
                      height: "30%",
                      bottom: 0,
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottomLeftRadius: 20,
                      borderBottomRightRadius: 20,
                    }}
                    onPress={setNewImage}
                  >
                    <Text style={{ color: "#fff" }}>Edit</Text>
                  </TouchableOpacity>
                  : null}
                </>
              : 
                <View style={{alignItems: 'center', justifyContent: 'center'}}>
                  <ActivityIndicator size="large" color={darkMode ? 'white' : Platform.OS ==='web' ? "#005157" : Platform.OS === 'android' ? '#005157' : 'default'}/>
                  <Text style={{fontSize: 14, color: 'grey'}}>Processing image...</Text>
                </View> 
              }
            </View>
            <View style={{ alignItems: "center" }}>
              <Text style={[{ fontWeight: "bold" }, darkMode ? {color: 'white'} : null]}>{name}</Text>
              <Text style={darkMode ? {color: 'white'} : null}>{type}</Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
            <View style={{ alignItems: "center", marginHorizontal: 15 }}>
              <Text style={[{ fontWeight: "bold" }, darkMode ? {color: 'white'} : null]}>{admins.length}</Text>
              <Text style={[darkMode ? {color: 'white'} : null]}>{`${admins.length === 1 ? 'User' : 'Users'}`}</Text>
            </View>
            <View style={{ alignItems: "center", marginHorizontal: 15 }}>
              <Text style={[{ fontWeight: "bold" }, darkMode ? {color: 'white'} : null]}>{accountGrpDetails.length}</Text>
              <Text style={[darkMode ? {color: 'white'} : null]}>{`${accountGrpDetails.length === 1 ? 'Focus Center' : 'Focus Centers'}`}</Text>
            </View>
        </View>

        <View
          style={{ alignItems: "center", marginTop: 10, paddingHorizontal: 13 }}
        >
          <View style={{ flexDirection: "row", paddingVertical: 10 }}>
            <TouchableOpacity
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginHorizontal: 10,
                paddingHorizontal: 5,
                paddingVertical: 5,
                borderColor: darkMode ? "white" : "#666",
                borderWidth: 1,
                borderRadius: 5
              }}
              onPress={() => setHorizontal(!horizontal)}
            >
              <Feather name={horizontal ? "list" : "grid"} size={24} color={darkMode ? "white" : "black"} />
              <Text style={[{ paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>{horizontal ? "List" : "Grid"}</Text>
            </TouchableOpacity>
            {hasAccounts ?
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginHorizontal: 10,
                    paddingHorizontal: 5,
                    borderColor: darkMode ? "white" : "#666",
                    borderWidth: 1,
                    borderRadius: 5
                  }}
                  onPress={() => {
                    navigation.navigate('Drawer', {screen: 'Account', params: {screen: 'Passport', params: {darkMode}}});
                  }}
                >
                  <Ionicons name="git-network-outline" size={24} color={darkMode ? "white" : "black"} />
                  <View style={{ alignItems: "center", justifyContent: "center" }}>
                    <Text style={[{ lineHeight: 12, paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>My</Text>
                    <Text style={[{ lineHeight: 12, paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>
                      Network
                    </Text>
                  </View>
                </TouchableOpacity>
            : null}
            <TouchableOpacity
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginHorizontal: 10,
                paddingHorizontal: 5,
                borderColor: darkMode ? "white" : "#666",
                borderWidth: 1,
                borderRadius: 5
              }}
              onPress={() => {
                  if (Platform.OS === 'web') {
                    alertToConfirm('web', "Are you sure you want to logout of your personal membership?", async () => {
                      await kSignout();
                    })();
                  } else {
                    alertToConfirm('mobile', "Are you sure you want to logout of your personal membership?", async () => {
                      await kSignout();
                    })();
                  }
                }
              }
            >
              <View>
                <Feather name="log-out" size={24} color={darkMode ? "white" : "black"} />
              </View>
              <View style={{ alignItems: "center", justifyContent: "center" }}>
                <Text style={[{ lineHeight: 12, paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>Log</Text>
                <Text style={[{ lineHeight: 12, paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>
                  Out
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>

        <View style={{justifyContent: 'center', alignItems: 'center'}}>
          <AccountHomeBtnList
            darkMode={darkMode}
            list={tmpBtnList}
            onPress={btnClickHandler}
            activeBtnTitle={activeBtnTitle}
          />
        </View>

        <View style={{ paddingHorizontal: 5, marginTop: 10, width: Math.min(Dimensions.get('window').width-26, 800) }}>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            {(activeBtnTitle === 'Users' /*&& (adminCapability && myAccLvl >= 4)*/) ?
              <TouchableOpacity
            onPress={() => {}}
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <Feather name="plus-circle" size={24} color={"#0984e3"} />
                <Text style={{ paddingLeft: 5 }}>User</Text>
              </TouchableOpacity>
            : null}
            {(activeBtnTitle === 'Focus Centers' /*&& (adminCapability && myAccLvl >= 4)*/) ?
              <TouchableOpacity
              onPress={() => navigation.navigate('ManageFocusCenter', {AccountId: account.AccountId, create:true})}
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <Feather name="plus-circle" size={24} color={"#0984e3"} />
                <Text style={{ paddingLeft: 5 }}>Focus Center</Text>
              </TouchableOpacity>
            : null}
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 1,
                borderColor: darkMode ? "white" : "#666",
                width: 225,
                height: 30
              }}
            >
              <Feather name="search" size={24} color={darkMode ? "white" : "#666"} />
              <TextInput
                style={{
                  flex: 1,
                }}
                placeholder="search"
                value={searchTxt}
                onChangeText={(txt) => {
                  setSearchTxt(txt);
                }}
              />
              <Feather name="chevron-right" size={24} color={darkMode ? "white" : "#666"} />
            </View>
          </View>
        </View>
        <View>
          {activeBtnTitle && activeBtnTitle === "Users" ? (
            <View style={{width: Math.min(Dimensions.get('window').width-26, 800)}}>
              {usrSuccessMsg !== "" ? <Text style={{ fontSize: 16, color: 'green', paddingTop: 10, marginLeft: 12, paddingBottom: 10}}>{usrSuccessMsg}</Text> : null}
              {usrErrorMsg !== "" ? <Text style={{ fontSize: 16, color: 'red', paddingTop: 10, marginLeft: 12, paddingBottom: 10}}>{usrErrorMsg}</Text> : null}
              <AccountHomeUserProfile
                setErrorMsg={setUsrErrorMsg}
                setSuccessMsg={setUsrSuccessMsg}
                darkMode={darkMode}
                horizontal={horizontal}
                admins={admins}
                setAdmins={setAdmins}
                myAccLvl={myAccLvl}
                adminCapability={adminCapability}
                profiles={predicateSearch(admins.filter(a => a.CommonsUserType === 'Pro'), searchTxt, {UserID: 1, Firstname: 1, Lastname: 1, CompanyName: 1, Email: 1}).map(ad => { 
                  return {
                    Firstname: ad.Firstname,
                    Lastname: ad.Lastname,
                    displayName: ad.UserID,
                    displayPicUri: `https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/cdn1/${ad.Firstname}${ad.Lastname}${ad.UID}.png`,
                    username: ad.UserID,
                    accessLvl: ad.CommonsUserType === "Pro" ? 4 : 3,
                    IsRestrictedCapabilies: ad.IsRestrictedCapabilies,
                    IsRestrictedDisclosure: ad.IsRestrictedDisclosure
                  }
                })}
                title="Administrators - Pro"
                kiosk={true}
                removeFunc={() => {}}
              />
              <AccountHomeUserProfile
                setErrorMsg={setUsrErrorMsg}
                setSuccessMsg={setUsrSuccessMsg}
                darkMode={darkMode}
                horizontal={horizontal}
                admins={admins}
                setAdmins={setAdmins}
                myAccLvl={myAccLvl}
                adminCapability={adminCapability}
                profiles={predicateSearch(admins.filter(a => a.CommonsUserType === 'Basic'), searchTxt, {UserID: 1, Firstname: 1, Lastname: 1, CompanyName: 1, Email: 1}).map(ad => { 
                  return {
                    Firstname: ad.Firstname,
                    Lastname: ad.Lastname,
                    displayName: ad.UserID,
                    displayPicUri: `https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/cdn1/${ad.Firstname}${ad.Lastname}${ad.UID}.png`,
                    username: ad.UserID,
                    accessLvl: ad.CommonsUserType === "Pro" ? 4 : 3,
                    IsRestrictedCapabilies: ad.IsRestrictedCapabilies,
                    IsRestrictedDisclosure: ad.IsRestrictedDisclosure
                  }
                })}
                title="Administrators - Basic"
                kiosk={true}
                removeFunc={() => {}}
              />
            </View>
          ) : <>{activeBtnTitle && activeBtnTitle === "Focus Centers" ? (
            <View>
                {pubSuccessMsg !== "" ? <Text style={{ fontSize: 16, color: 'green', paddingTop: 10, marginLeft: 12, paddingBottom: 10}}>{pubSuccessMsg}</Text> : null}
                {pubErrorMsg !== "" ? <Text style={{ fontSize: 16, color: 'red', paddingTop: 10, marginLeft: 12, paddingBottom: 10}}>{pubErrorMsg}</Text> : null}
                <AccountHomeFocusCenters
                    darkMode={darkMode}
                    horizontal={horizontal}
                    focusCenters={predicateSearch(accountGrpDetails, searchTxt, {kioskid: 1, Groupid: 1, GroupName: 1, AccountId: 1, ForumId: 1,})}
                    setFocusCenters={setAccGrpDetails}
                    setErrMsg={setPubErrorMsg}
                    setSuccessMsg={setPubSuccessMsg}
                    title={"Focus Centers"}
                    myAccLvl={myAccLvl}
                    adminCapability={adminCapability}
                    navigation={navigation}
                />
            </View>)
            : null}
            </>}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  badgeImage: {
      width: 75,
      height: 20,
      marginTop: 2,
      marginLeft: 5,
      paddingLeft: 5,
      backgroundColor: 'black'
  },
  container: {
      marginLeft: 15,
      
      justifyContent:'center', 
      flexDirection:'column',
  },
  image: {
      width: 250,
      height: 180,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      marginBottom: -2,
      },

  textDescStyle: {
      color: 'white',
      fontWeight: '300',
      fontSize: 14,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 10
  },
  name: {
      width: 250,
      height: 42,
      fontWeight: 'bold',
      color: 'white',
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
  },
});

export default AccountHomeWrapper;