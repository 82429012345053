import createDataContext from './createDataContext';
import MeSearchApi from '../api/MeSearch-API';
import MeSearchApiPub from '../api/MeSearch-API-Pub';
import { navigate, navigateAndResetStack } from '../navigationRef';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import ContentTypeEnum from '../components/utils/contentTypes';
import determineReferrer from '../screens/utils/determineReferrer';

const publisherReducer = (state, action) => {
  switch (action.type) {
    case 'add_error':
      return { ...state, errorMsg: action.payload };
    case 'clear_error_message':
      return { ...state, errorMsg: '' };
    case 'signin':
      return { ...state, errorMsg: '', token: action.payload, username: '', password: '' };
    case 'add_pub':
      return { ...state, publisher: action.payload };
    case 'add_ref':
      return { ...state, ref: action.payload };
    case 'confirmEmail':
      return {...state, isConfirmed: action.payload};
    case 'signout':
      return {...state, token: null, errorMsg: '', username: '', password: ''};
    default:
      return state;
  }
};

const addPublisher = dispatch => {
  return async ({ referrer }) => {
    try {
        const response = await MeSearchApi.get('/api/pub/with-referrer',  
        {  params: {
            referrer: referrer
          }
        });
        let pubObj = response.data;
        pubObj.baseContentType = pubObj.baseContentType ? pubObj.baseContentType : ContentTypeEnum.ARTICLE;
        dispatch({ type: 'add_pub', payload: pubObj });
        //navigate('Search');
    } catch (err) {
        dispatch({ type: 'add_error', payload: `${err.response ? err.response.data.error : 'Something went wrong trying to retrieve publisher'}` });
    }
  }
}

const addRef = dispatch => {
  return async ({ referrer }) => {
    dispatch({ type: 'add_ref', payload: referrer });
  }
}

const signin = (dispatch) => {
  return async ({ username }) => {
    try {
      const usernameTrimmed = String(username).trim();
      const response = await MeSearchApi.post('/api/pubOwnerSignin', { username: usernameTrimmed });
      await AsyncStorage.setItem('pubtoken', response.data.token);
      dispatch({ type: 'signin', payload: response.data.token });
      const isEmailConfirmed = response.data.isConfirmed;
      navigate('AccountHome', {isConfirmed: isEmailConfirmed, myMembership: false});
    } catch (err) {
      dispatch({
        type: 'add_error',
        payload: `${err.response ? err.response.data.error : err}`
      });
    }
  };
};

const kAccountSignin = (dispatch) => {
  return async ({ accId }) => {
    try {
      await AsyncStorage.setItem('kAccountToken', `${accId}`);
      dispatch({ type: 'kAccountSignin', payload: `${accId}` });
      navigate('AccountHome');
    } catch (err) {
      dispatch({
        type: 'add_error',
        payload: `${err.response ? err.response.data.error : err}`
      });
    }
  };
};

const kAccountSignout = (dispatch) => {
  return async () => {
    await AsyncStorage.removeItem('kAccounttoken');
    dispatch({ type: 'signout' });
  };
};

const signup = (dispatch) => {
  return async ({ payload, usrPayload, navigation }) => {
    try {
      const usernameTrimmed = String(payload.username).trim();
      payload["username"] = usernameTrimmed;
      const response = await MeSearchApi.post('/api/pubOwnerSignup', {payload});
      await AsyncStorage.setItem('pubtoken', response.data.token);
      dispatch({ type: 'signin', payload: response.data.token });
      const referrer = determineReferrer();
      const res = await MeSearchApi.post('/api/user/sendEmailConfirmation', {usrPayload, account: payload.username, referrer});
      let confirmed = false;
      if (res.data && res.data.notification) {
        confirmed = res.data.notification === "Email already confirmed" ? true : false;
      }
      navigation.navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search'}}});
      //navigate('Drawer', {screen: 'Account', params: {screen: 'AccountHome', params: {isConfirmed: confirmed, myMembership: false}}});
    } catch (err) {
      dispatch({
        type: 'add_error',
        payload: `${err.response ? err.response.data.error : err}`
      });
    }
  };
};

const tryLandingPage = (dispatch) => {
  return async ({referrer}) => {
    try {
      const response = await MeSearchApi.get('/api/pub-owner/landing-page/with-referrer',  
        {params: {
          referrer: referrer
        }
      });
      if (response.data && response.data.landingPage) {
        navigate('LandingPage', {landingInfo: response.data.landingPage, gtmId: response.data.gtmId});
        return true;
      }
    } catch (err) {
      dispatch({
        type: 'add_error',
        payload: `${err.response ? err.response.data.error : err}`
      });
    }
    return false;
  };
};


const tryLocalAccountSignin = dispatch => async () => {
  try { 
    // Fetch current pub token
    const pubToken = await AsyncStorage.getItem('pubtoken');
    // Fetch current user token
    const token = await AsyncStorage.getItem('token');
    let valid = false;
    if (token) {
      const validObj = await MeSearchApi.post('/api/verify', { token: token, referrer: determineReferrer() });
      const userRes = await MeSearchApi.get('/api/user');
      if (validObj.data.username && !validObj.data.guest) {
        if (pubToken) {
          const validObj = await MeSearchApi.post('/api/pubOwner/verify', { token: pubToken });
          const isEmailConfirmed = (validObj.data.isConfirmed || typeof validObj.data.isConfirmed === 'undefined') ? true : false;
          if (validObj.data.username) {
            let curAccLvl = 1;
            if (userRes.data) {
              if (userRes.data.accessLvl) {
                userRes.data.accessLvl.some(accessObj => {
                  if (accessObj.account === validObj.data.username) {
                    curAccLvl = accessObj.perm;
                    return true;
                  }
                  return false;
                });
              }
            }
            if (curAccLvl >= 3) {
              valid = true;
              console.log(validObj.data.username)
              dispatch({ type: 'signin', payload: pubToken });
            }
          }
          dispatch({ type: 'confirmEmail', payload: isEmailConfirmed });
        }
      }
    }
    if (!valid) {
      await AsyncStorage.removeItem('pubtoken');
      dispatch({ type: 'signout' });
    }
  } catch (err) {
    dispatch({
      type: 'add_error',
      payload: `${err.response ? err.response.data.error : err}`
    });
  }
};

const signout = (dispatch) => {
  return async () => {
    await AsyncStorage.removeItem('pubtoken');
    dispatch({ type: 'signout' });
    // Need to have this navigate to the my network screen
    //navigate('PubSignin');
  };
};

const clearErrorMessage = dispatch => () => {
  dispatch({ type: 'clear_error_message' });
};
  

export const { Context, Provider } = createDataContext(
  publisherReducer,
  { addPublisher, clearErrorMessage, addRef, signin, signout, signup, tryLandingPage, tryLocalAccountSignin, kAccountSignin, kAccountSignout },
  { publisher: null, /* TODO: Will need to switch to 'MeSearch' */ errorMsg: '', ref: null, username: '', password: '', token: null, isConfirmed: false }
);