import React, { useContext, useState } from 'react';
import  { Button, Input } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, Text, Linking } from 'react-native';
import Spacer from '../components/Space';
import { Context as AuthContext } from '../context/AuthContext';
import { isMobile, isTablet } from 'react-device-detect';
import { Platform } from 'react-native';
import { Context as PubContext } from '../context/PubContext';

const ResetPasswordScreen = ({ navigation, route }) => {
    const changeCode = route.params?.changeCode ?? '';
    const priv = route.params?.priv ?? false;
    //const changeCode = navigation.getParam('changeCode');
    const [ newPassword, setNewPassword ] = useState('');
    const [ newPasswordConfirm, setNewPasswordConfirm ] = useState('');
    const { state, clearErrorMessage, resetPassword } = useContext(AuthContext);
    const  PubCon = useContext(PubContext);

    const resetUrl = () => {
      if (Platform.OS === 'web') {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let pub = params.get('p');
        if (pub) {
          history.pushState(null, 'body', `/?p=${pub}`);
        } else {
          const pubPageMatches = `${PubCon.state.ref}`.match(/[^\/]+/g);
          let pubPage = (pubPageMatches && pubPageMatches.length >= 3) ? pubPageMatches[2] : '/';
          history.replaceState(null, 'body', `/${pubPage}` === '//' ? '/' : `/${pubPage}`);
        }
      }
    }

    return (
        <SafeAreaView style={{justifyContent: 'center', alignItems: 'center', flex: 1}}>
        <View style={{width: Math.min(Dimensions.get('window').width-80, 500), justifyContent: 'center', alignItems: 'center'}}>
          <Spacer>
          <View style={{flex: 0.5, marginTop: 60, justifyContent: 'center', alignItems: 'center'}}>
            <Image style={styles.image} source={require('../../assets/logo-mesearch.png')}/>
          </View>
          
          </Spacer>
            <Input 
              secureTextEntry={true}
              label="New Password"
              value={newPassword}
              onChangeText={(newPass) => setNewPassword(newPass)}
              autoCapitalize="none"
              autoCorrect={false}
            />
          <Spacer/>
            <Input
              secureTextEntry={true}
              label="Repeat New Password"
              value={newPasswordConfirm}
              onChangeText={(newPassConfirm) => setNewPasswordConfirm(newPassConfirm)}
              autoCapitalize="none"
              autoCorrect={false}
              onSubmitEditing={() => resetPassword({newPassword, newPasswordConfirm, changeCode, resetUrl: resetUrl})}
            />
          {state.errorMessage ? <Text style={styles.errorMessage}>{state.errorMessage}</Text> : null}
          <Spacer>
            <Button 
              style={{width: 180}}
              title={'Reset'}
              onPress={() => resetPassword({newPassword, newPasswordConfirm, changeCode, resetUrl: resetUrl})}
            />
          </Spacer>
          {priv ?
          <View style={styles.section}>
            <Text>By clicking 'Reset' you are agreeing to our privacy policy and terms of service</Text>
            <Text style={{fontSize: 14,fontWeight:"400", color: 'blue' }} onPress={ () => (Platform.OS === 'web' && !isMobile) ? window.open('https://mesearch.us/wp-content/uploads/2020/02/Terms-of-Services.pdf') : Linking.openURL('https://mesearch.us/wp-content/uploads/2020/02/Terms-of-Services.pdf') }>
            Terms of Service and Use
            </Text>
            <Text style={{fontSize: 14, fontWeight:"400", color: 'blue' }} onPress={ () => (Platform.OS === 'web' && !isMobile) ? window.open('https://mesearch.us/wp-content/uploads/2020/02/2020-01-01_Crivella-Media-Tech_Collection-and-Use-of-Personal-Information_Policy-only.pdf') : Linking.openURL('https://mesearch.us/wp-content/uploads/2020/02/2020-01-01_Crivella-Media-Tech_Collection-and-Use-of-Personal-Information_Policy-only.pdf') }>
            Privacy Policy
            </Text>
        </View>
        : null}
        </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    errorMessage: {
      fontSize: 16,
      color: 'red',
      marginTop: 15,
      marginLeft: 15
    },
    image: {
      width: 200,
      height: 80,
      marginTop: 5,
    },
    section: {
      paddingBottom: 15,
      width: Math.min(Dimensions.get('window').width-80, 300)
    },
});

export default ResetPasswordScreen;