import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Linking, Image } from 'react-native';
import { Platform } from 'react-native';

const Header = ({logo=null, pubName, menuLinks=[], primaryColor='', textColor=''}) => {
  return (
    <>
      {
        Platform.OS === 'web' ? (
          <View style={[styles.container]}>
            <View style={styles.heading}>
              {
                logo ? (
                  <Image 
                    source={logo}
                    style={styles.logo}
                    resizeMode='center'
                  />
                ) : (
                  <Text style={styles.headingText}>{pubName}</Text>
                )
              }
            </View>
            <View style={[styles.links, {backgroundColor: primaryColor}]}>
              {
                menuLinks.map(link => (
                  <View>
                    <TouchableOpacity onPress={() => {
                      Linking.openURL(link.url)
                    }}>
                      <Text style={[styles.text, {color: textColor}]}>{link.label}</Text>
                    </TouchableOpacity>
                  </View>
                ))
              }
            </View>
          </View>
        ) : null
      }
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 15,
    height: 200,
    width: '100%',
    backgroundColor: 'white'
  },
  heading: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 100
  },
  links: {
    flexDirection: 'row',
    // justifyContent: 'flex-end',
    paddingHorizontal: 10,
    paddingVertical: 15,
    backgroundColor: 'rgb(103,103,103)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headingText: {
    fontSize: 26,
    textTransform: 'capitalize',
    fontFamily: 'serif',
  },
  text: {
    color: 'rgb(255,255,255)',
    textTransform: 'uppercase',
    paddingHorizontal: 20,
  },
  logo: {
    width: 400,
    height: '100%',
  },
});

export default Header;