// NEED this import only for native mobile to run
// import globals from '../globals';
import React from 'react';
import { Provider as AuthProvider } from './context/AuthContext';
import { Provider as LocationProvider } from './context/LocationContext';
import { Provider as PublisherProvider } from './context/PubContext';
import { Platform } from 'react-native';
import MeSearchAPI from './api/MeSearch-API';
import appJson from '../app.json';
import MainNavigator from './navigators/mainNavigator';
import { GestureHandlerRootView } from 'react-native-gesture-handler';


export default () => {
  if (Platform.OS === 'web') {
    // Disable back button
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    
    // Check if app is on latest version
    const currentVersion = appJson.expo.version;
    const responseProm = MeSearchAPI.get('/version');
 
    //if (!location.href == 'http://localhost:19006/'){
      // Cache busting for web app
      responseProm.then(response => {
        if (response) {
          if (currentVersion !== response.data.version) {
            // Clear service worker cache
            if (caches) {
              caches.keys().then(function(names) {
                for (let name of names) caches.delete(name);
              });
            }
          // Clear browser cache and hard reload
          window.location.reload(true);
          }
        }
      });
    // }
 
  }

  return (
    <>
      {Platform.OS === 'web' ?
      <>
        <meta httpEquiv='cache-control' content='no-cache'/>
        <meta httpEquiv='expires' content='0'/>
        <meta httpEquiv='pragma' content='no-cache'/>
      </>
      : null}
      <GestureHandlerRootView style={{ flex: 1 }}>
        <PublisherProvider>
          <LocationProvider>
            <AuthProvider>
              <MainNavigator/>
            </AuthProvider>
          </LocationProvider>
        </PublisherProvider>
      </GestureHandlerRootView>
    </>
  );
};