import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
import { Feather } from '@expo/vector-icons';


const TagLabel = ({ type, tagText, onPress, highlight }) => {

const styles = StyleSheet.create({
    button: {
      borderColor: highlight ? 'green' : 'rgb(108, 137, 234)',
      borderWidth: highlight ? 4 : 2,
      borderRadius: 7,
      backgroundColor: 'white',
      width: Math.min(Dimensions.get('window').width-30, 300),
      paddingTop: 2,
      paddingBottom: 2
    },
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 7,
    },
    labelText: {
      fontSize: 12,
      paddingRight: 5,
      color: 'rgb(108, 137, 234)',
    },
  });

  return (
    <>
      {
        type === "remove" ? (
          <TouchableOpacity
            onPress={onPress}
            style={styles.button}
          >
            <View style={styles.container}>
              <Text style={styles.labelText}>{tagText}</Text>
              <Feather name="x" size={14} color='rgb(108, 137, 234)' />
            </View>
          </TouchableOpacity>
        ) : null
      }
      {
        type === "add" ? (
          <TouchableOpacity
            onPress={onPress}
            style={styles.button}
          >
            <View style={styles.container}>
              <Text style={styles.labelText}>{tagText}</Text>
              <Feather name="plus" size={14} color='rgb(108, 137, 234)' />
            </View>
          </TouchableOpacity>
        ) : null
      }
    </>
  );
};

export default TagLabel;