
function removeSpecialChar(str) {
  // Declare regex
  const apostrophe = new RegExp('\&\#039\;', "g");
  const leftQuoteDouble =  new RegExp("\â\€\œ", "g");
  const rightQuoteDouble =  new RegExp("\â\€", "g");
  const leftQuoteSingle =  new RegExp("\â\€\˜", "g");
  const rightQuoteSingle =  new RegExp("\â\€\™", "g");
  const enDash =  new RegExp("\â\€\”", "g");
  const emDash =  new RegExp("\â\€\“", "g");
  const hyphen =  new RegExp("\â\€\¢", "g");
  const ellipsis =  new RegExp("\â\€\¦", "g");
  const ampersand = new RegExp("\&amp\;", "g");
  const tradeSymbol = new RegExp("\&trade", "g");
  const bulletSymbol = new RegExp("\”\¢", "g");
  const doubleDash = new RegExp("\”\”", "g");
  const apostropheTwo = new RegExp('\&\#8217\;', "g");
  const apostropheThree = new RegExp('\&\#8217', "g");
  const doubleQuote = new RegExp('\&rdquo\;', "g");
  const doubleDashTwo = new RegExp('\&\#8212\;', "g");
  const doubleQuoteTwo = new RegExp('\&\#8220\;', "g");
  const doubleQuoteThree =  new RegExp("\&ldquo\;", "g");
  const apostropheFour = new RegExp('\&rsquo\;', "g");
  const doubleQuoteFour = new RegExp('\&quot\;', "g");
  const laughEmojiDouble = new RegExp('\ð\Ÿ\˜\‚\ð\Ÿ\˜', "g");
  const laughEmoji = new RegExp('\ð\Ÿ\˜', "g");
  const doubleQuoteFive = new RegExp('\\\?\&mdash\;', "g");
  const doubleQuoteSix = new RegExp('\&mdash\;', "g");
  const apostropheFive = new RegExp('\&\#39\;', "g");
  const arrow = new RegExp('\â\¬\‡\ï\¸\\\?', "g");
  const apostropheSix = new RegExp('\”\™', "g");
  const dotsAboveO = new RegExp('\&\#246\;', "g");
  const dotsAboveU = new RegExp('\&\#252\;', "g");
  const ellipsisTwo = new RegExp('\&\#8230\;', "g");
  const ellipsisThree = new RegExp('\¦', "g");
  const doubleQuoteSeven = new RegExp('\&\#8221\;', "g");
  const apostropheSeven= new RegExp('\&\#8216\;', "g");
  const oneHalfOne = new RegExp('\&\#189\;', "g");
  const oneHalfTwo = new RegExp('\ \½', "g");
  const eWithDotsAboveOne = new RegExp('\&\#235\;', "g");
  const spaceOne = new RegExp('\&\#0010\;', "g");
  const apostropheEight = new RegExp('\&lsquo\;', "g");
  const bulletSymbolTwo = new RegExp('\&\#8226\;', "g");
  const iWithDotsAbove = new RegExp('\&\#239\;', "g");
  const eWithHashAboveToRight = new RegExp('\&\#233\;', "g");
  const eWithHashAboveToLeft = new RegExp('\&\#232\;', "g");
  const doubleDashThree = new RegExp('\&\#8211\;', "g");
  const iWithHashAboveToRight = new RegExp('\&\#237\;', "g");
  const rightQuoteDoubleTwo = new RegExp("\â\€\?", "g");
  const spaceTwo = new RegExp('\&nbsp\;', "g");
  const removeOne = new RegExp('\Â', "g");
  const apostropheNine = new RegExp('\"\~', "g");
  const apostropheTen = new RegExp('\”\˜', "g");
  
  // Clean up string
  const cleanStr = str
  .replace(apostrophe, "\'")
  .replace(leftQuoteDouble, "\“")
  .replace(rightQuoteDouble, "\”")
  .replace(leftQuoteSingle, "\‘")
  .replace(rightQuoteSingle, "\’")
  .replace(enDash, "\–")
  .replace(emDash, "\—")
  .replace(hyphen, "\-")
  .replace(ellipsis, "\…")
  .replace(ampersand, "\&")
  .replace(tradeSymbol, "\™")
  .replace(bulletSymbol, "\•")
  .replace(doubleDash, "\—")
  .replace(apostropheTwo, "\'")
  .replace(apostropheThree, "\'")
  .replace(doubleQuote, "\"")
  .replace(doubleDashTwo, "\—")
  .replace(doubleQuoteTwo, "\"")
  .replace(laughEmojiDouble, "")
  .replace(doubleQuoteThree, "\"")
  .replace(apostropheFour, "\'")
  .replace(doubleQuoteFour, "\"")
  .replace(laughEmoji, "")
  .replace(doubleQuoteFive, "\"")
  .replace(doubleQuoteSix, "\"")
  .replace(apostropheFive, "\'")
  .replace(arrow, "")
  .replace(apostropheSix, "\'")
  .replace(ellipsisTwo, "\…")
  .replace(dotsAboveO, "\ö")
  .replace(ellipsisThree, "\…")
  .replace(doubleQuoteSeven, "\"")
  .replace(apostropheSeven, "\'")
  .replace(oneHalfOne, "\½")
  .replace(oneHalfTwo, "\½")
  .replace(eWithDotsAboveOne, "\ë")
  .replace(spaceOne, " ")
  .replace(apostropheEight, "\'")
  .replace(bulletSymbolTwo, "\•")
  .replace(iWithDotsAbove, "\ï")
  .replace(eWithHashAboveToRight, "\é")
  .replace(eWithHashAboveToLeft, "\è")
  .replace(doubleDashThree, "\–")
  .replace(iWithHashAboveToRight, "\í")
  .replace(dotsAboveU, "\ü")
  .replace(spaceTwo, " ")
  .replace(removeOne, "")
  .replace(apostropheNine, "\'")
  .replace(apostropheTen, "\'")
  .replace(rightQuoteDoubleTwo, "\”");

  return cleanStr;
}

export default removeSpecialChar;