import React, { Component } from 'react';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import { Context as PubContext } from '../context/PubContext';


/*
  Implementation of readt-dfp to add react Google Ad Manager supported Ads
  Needed to use HTMLView to turn divs into Views for react native
  Current Trib Ads dfpNetworkID 207845991
  Two ad Units created for us:
  [728 x 90] /21907320071/mesearch_728x90
 [300 x 250] /21907193119/mesearch_300x250
  Should be able to downscale for mobile (?)
*/

// TODO: Unhard code this entire Component

const pubTargMap = {
  "FoxChapelHerald": ["foxchapelherald","foxchapel.triblive.com"],
  "HamptonJournal": ["hampton.triblive.com"],
  "NorthJournal": ["northjournal.triblive.com"],
  "PennHillsProgress": ["pennhills.triblive.com"],
  "PineCreekJournal": ["pinecreekjournal.com","pinecreek.triblive.com"],
  "PlumAdvanceLeader": ["plum.triblive.com"],
  "ShalerJournal": ["shaler.triblive.com"],
  "SewickleyHerald": ["sewickleyherald.com","sewickley.triblive.com"]
};

class AdUnit extends Component {
  static contextType = PubContext;

  constructor(props) {
    super(props);
    this.dfpNetworkID = props.dfpNetworkID
    this.adPlatform = props.adPlatform
    this.adLocation = props.adLocation
    this.adUnit = props.adUnit
    this.slotID = props.slotID
    this.sizes = props.sizes
    this.targetingAruguments = props.targetingArguments
    this.cstmSctn = props.cstmSctn
    this.state = {rend: ""};
  }

  render() {
    return (
      <DFPSlotsProvider 
        dfpNetworkId={this.dfpNetworkID}    
        collapseEmptyDivs
        targetingArguments={{
          site: pubTargMap[this.context.state.publisher.username.replace(/\s/g, '')] 
            ? [this.context.state.publisher.username.replace(/\s/g, ''), ...pubTargMap[this.context.state.publisher.username.replace(/\s/g, '')]] 
            : this.context.state.publisher.username === 'TimesExpress.com' 
              ? this.context.state.publisher.username.replace(/\s/g, '').toLowerCase() 
              : this.context.state.publisher.username.replace(/\s/g, ''),
          platform:this.adPlatform,
          cstmSctn:this.cstmSctn
      }}>
        <AdSlot 
          sizes={this.sizes}
          adUnit={this.adUnit}
          slotId={this.slotID}
        />
      </DFPSlotsProvider>
    )
  }
}

export default AdUnit;