import React from 'react';
import { View, Dimensions, Image, Linking } from 'react-native';
import {Button, Text}  from 'react-native-elements';
import Spacer from './Space';
import { isMobile, isTablet } from 'react-device-detect';
import { Platform } from 'react-native';
import MeSearchAPI from '../api/MeSearch-API';

const TermsOfServiceFooter = ({darkMode, setTermsAccepted, setErrorMsg}) => {
    /*K-Andre Helped*/
    const styles = {
        container:{
            backgroundColor: darkMode ? '#3c3c3d' : 'white',
            //flex: 1,
            height: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 300 : 310,
            //(Dimensions.get('window').height * 0.4) < 335 ? 335 : (Dimensions.get('window').height * 0.4),
            width: Dimensions.get('window').width,
            paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
            paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
            borderRadius: 4,
            borderWidth: 4,
            borderColor: '#d6d7da',
            position: 'absolute',
            bottom: 0
        },
        privacyTitle: {
            color: darkMode ? 'white' : 'black',
            fontWeight: 'bold',
            paddingLeft: 5,
            paddingBottom:7,
            marginLeft: 15,
            marginBottom: 5,
            borderRadius: 4,
            fontSize: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 20 : 28,
        },
        privacyText: {
            color: darkMode ? 'white' : 'black',
            fontSize: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 14 : 20,
            paddingLeft: 5,
            paddingBottom: 5,
            marginLeft: 15,
            marginBottom: 5,
            borderRadius: 4,
            fontWeight: '100' //'lighter'
        },
        mesearchLogo: {
            height: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 50 : 80, 
            width: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 100 : 180,
            marginLeft: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 15 : 20,
        },
    }
    return (
        <View style={styles.container}>
            <Image style={styles.mesearchLogo} source={require('../../assets/logo-mesearch.png')}/>
            <Text style={styles.privacyTitle}>Privacy Terms</Text>
            <Text style={styles.privacyText}>We use some data collected from you in order to improve your experience on MeSearch.</Text>
            <Text style={styles.privacyText}>To find out more, please read our{' '}
                <Text style={{fontSize: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 14 : 20, fontWeight:"400", color: darkMode ? 'white' : 'blue' }} onPress={ () => Platform.OS === 'web' ? window.open('https://mesearch.us/wp-content/uploads/2020/02/2020-01-01_Crivella-Media-Tech_Collection-and-Use-of-Personal-Information_Policy-only.pdf') : Linking.openURL('https://mesearch.us/wp-content/uploads/2020/02/2020-01-01_Crivella-Media-Tech_Collection-and-Use-of-Personal-Information_Policy-only.pdf') }>
                Privacy Policy
                </Text>
                <Text> and our </Text>
                <Text style={{fontSize: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 14 : 20,fontWeight:"400", color: darkMode ? 'white' : 'blue' }} onPress={ () => Platform.OS === 'web' ? window.open('https://mesearch.us/wp-content/uploads/2020/02/Terms-of-Services.pdf') : Linking.openURL('https://mesearch.us/wp-content/uploads/2020/02/Terms-of-Services.pdf') }>
                    Terms of Service and Use.
                </Text> 
            </Text>
            <Text style={styles.privacyText}>
                To accept these Terms and Conditions please click "I Agree" below.
            </Text>
            <Spacer>
                <Button
                title="I Agree"
                onPress={() => {
                    setErrorMsg('');
                    setTermsAccepted(true);
                    MeSearchAPI.patch(
                        '/api/user', 
                        { payload: { 
                            termsAccepted: true
                        } 
                    });
                }}
                />         
            </Spacer>
        </View>
    );
}

export default TermsOfServiceFooter;