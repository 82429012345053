const MeTags = ["Community - Bloomfield-Garfield",
"Community - Downtown Pittsburgh",
"Community - East End",
"Community - Edgewood-Swissvale",
"Community - Fox Chapel",
"Community - Glenwood Area",
"Community-Hampton",
"Community-Highland Park",
"Community-Homestead",
"Community - Hill District",
"Community - Lawrenceville",
"Community-Mckeesport",
"Community-Monroeville",
"Community-Murrysville",
"Community-Northside",
"Community-Penn-Trafford",
"Community-Penn Hills",
"Community-Pine Creek",
"Community-Airport Area",
"Community - Shadyside-Squirrel Hill",
"Community - Shaler",
"Community-Carnegie Area",
"Community-South Hills",
"Community-Sewickley Area",
"Community-Wilkinsburg",
"Community-West End",
"Community-Woodland Hills",
"Community-North Journal",
"Community-Norwin Star",
"Community-South Side",
"Community - Oakland",
"South Hills Record",
"Coraopolis",
"Inven Global",
"EverybodyCraves",
"EverybodyGardens",
"EverybodyAdventures",
"Oakland Area News",
"Pittsburgh Airport Area News",
"North Side Area News",
"South Side Area News",
"West End Area News",
"Downtown Pittsburgh News",
"Hill District News",
"Lawrenceville Area News",
"Bloomfield-Garfield Area News",
"Highland Park News",
"Shadyside-Squirrel Hill",
"East End Area News",
"Glenwood Area News",
"Homestead Area News",
"Edgewood-Swissvale Area News",
"Wilkinsburg Area News",
"Woodland Hills Area News",
"Fox Chapel Herald",
"Hampton Journal",
"North Journal",
"Pine Creek Journal",
"Shaler Journal",
"Penn Hills Progress",
"Plum Advance Leader",
"Sewickley Herald",
"MeSearch-News",
"Top Stories",
"Faribault - North",
"Faribault - West",
"Faribault - East",
"Faribault - South",
"Steele County",
"Rice County",
"Le Sueur County",
"Dakota County",
"Oakmont Advance Leader",
"Perryopolis Area News",
"Dine-In",
"Take-Out",
"Outdoor Seating",
"Delivery",
"American",
"Asian",
"Breakfast",
"Burgers",
"Chinese",
"Desserts",
"Fast Food",
"Healthy",
"Italian",
"Mexican",
"Mediterranean",
"Cuban",
"French",
"Sandwiches",
"Pizza",
"Seafood",
"Soup",
"Steak",
"Vegan",
"Menu",
"Offers",
"Coupon",
"Restaurant News",
"Bethel Park Journal",
"Featured",
"Team",
"Board",
"Products",
"Media",
"Rockland",
"Rockland Communities",
"Rockland Tourism",
"Rochester",
"Allegheny",
"Art",
"Baseball",
"Basketball",
"Books",
"Business",
"Butler",
"Carnegie",
"College",
"Coronavirus",
"Election",
"Environment",
"Events",
"Fashion",
"Fitness",
"Food and Drink",
"Football",
"Fox Chapel",
"Garden",
"Hampton",
"Health",
"Hockey",
"MLB",
"Monroeville",
"Movies",
"Murrysville",
"Museums",
"Music",
"NBA",
"NCAA",
"NFL",
"NHL",
"Norwin",
"Outdoors",
"Penguins",
"Penn Hills",
"Penn State",
"Penn-Trafford",
"Pennsylvania",
"Pirates",
"Pitt",
"Pittsburgh",
"Plum",
"Politics",
"Science",
"Sewickley",
"Shaler",
"Soccer",
"South Hills",
"Steelers",
"TV",
"Technology",
"Theater",
"Video Games",
"WVU",
"Westmoreland",
"World",
"Carnegie Signal Item",
"Murrysville Star",
"Norwin Star",
"Penn-Trafford Star",
"Monroeville Times Express",
"Northside",
"South Side",
"West End",
"Downtown Pittsburgh",
"Hill District",
"Lawrenceville",
"Bloomfield",
"Garfield",
"Highland Park",
"Oakland",
"Shadyside",
"East End",
"Glenwood",
"Homestead",
"Edgewood",
"Wilkinsburg",
"Woodland Hills",
"Mon Valley",
"Beaver County",
"Kittaning",
"Washington County",
"Armstrong County",
"Fulton County",
"Alpharetta",
"Roswell",
"Sandy Springs",
"American Flag",
"Education",
"Fundraiser-Charity",
"Holiday",
"Kids",
"Entertainment",
"Automotive",
"Religion",
"Current Flag News",
"Home",
"Military",
"Relationships",
"Golf",
"Perryopolis",
"Perryopolis High School Sports",
"Norwin High School Sports",
"Carnegie High School Sports",
"South Hills High School Sports",
"Perryopolis Obituaries",
"Norwin Obituaries",
"Carnegie Obituaries",
"South Hills Obituaries",
"Bethel Park",
"Trib Total Media Employees",
"Non-Trib Employees",
"Advertising",
"Agriculture",
"Airport Area",
"Alcohol",
"Architecture",
"Aviation",
"Banking",
"Computers",
"Concerts",
"Construction",
"Consumer Electronics",
"Country Music",
"Crime",
"Dance",
"Design",
"Dogs",
"Economics",
"Emergency Services",
"High School Sports",
"Jazz Music",
"Lifestyle",
"Mckeesport",
"Mobile Phones",
"Motorcycle",
"Nature",
"North Hills",
"Photography",
"Pop Music",
"Shoes",
"Small Business",
"Sports",
"Squirrel Hill",
"Swimming",
"Swissvale",
"Amusement Parks",
"USA News",
"Weather",
"Wrestling",
"Edible Gardens",
"Gardening How To",
"Gardening News",
"Garden Recipe",
"Indoor Garden",
"Ornamental Garden",
"Plant Problems",
"Special Gardens",
"Animals",
"Insects",
"Surfside Condo Florida",
"Public Safety",
"Schools",
"Minnesota",
"Minneapolis-Saint Paul",
"Track and Field",
"Tennis",
"Olympic Games",
"Summer Olympic Games",
"Winter Olympic Games",
"Minnesota Twins",
"Juneteenth",
"President Donald Trump",
"President Joe Biden",
"Space Tourism",
"Governor Tim Walz",
"Travel",
"Electric Vehicle",
"Voting Rights"];
  
export default MeTags;