import { Platform } from 'react-native';

const determineBodyId = (search, href) => {
  if (Platform.OS === 'web') {
    let base = href;
    let params = new URLSearchParams(search);
    const pub = params.get('p');
    const paramKeys = Object(params).keys();
    let paramCount = 0;
    for (let pKey of paramKeys) {
      paramCount += 1;
    }
    if (paramCount > 1) {
      if (pub) {
        base = href.toLowerCase().split('&')[0].replace('content-page/', '').replace('publisher/', '').replace('publisher', '')
      } else {
        base = href.toLowerCase().split('?')[0].replace('content-page/', '').replace('publisher/', '').replace('publisher', '')
      }
    } else if (paramCount === 1) {
      if (pub) {
        base = href.toLowerCase().replace('content-page/', '').replace('publisher/', '').replace('publisher', '')
      } else {
        base = href.toLowerCase().split('?')[0].replace('content-page/', '').replace('publisher/', '').replace('publisher', '')
      }
    } else {
      base = href.toLowerCase().replace('content-page/', '').replace('publisher/', '').replace('publisher', '')
    }
    let refPrts = base.match(/\/bid(.*)/);
    const checkMulti = (refPrts && refPrts.length > 0) ? refPrts[0].replace('/', '').replace('bid', '').match(/\/bid(.*)/g) : [];
    if (checkMulti && checkMulti.length > 0) {
      refPrts = checkMulti;
    }
    const bodyId = (refPrts && refPrts.length > 0) ? `${refPrts[0]}`.replace('/', '').replace('bid', '') : "";
    return bodyId;
  } else {
    return "";
  }
};

export default determineBodyId;