import React, {useState, useEffect} from 'react';
import {Text, View, StyleSheet, Image, TouchableOpacity, Linking} from 'react-native';
import { Platform } from 'react-native';

const Stats = ({teamName, spread, odds, overunder, moneyline, overOrUnder, containerStyle={}}) => {
  return (
    <View style={[styles.statsContainer, containerStyle]}>
      <Text style={styles.statsTitle}>{teamName ? teamName : 'Unavailable Data'}</Text>
      <View style={styles.statsRow}>
        <View style={styles.stat}>
          <Text style={styles.statFirstTxt}>{spread}</Text>
          <Text style={styles.statSecondTxt}>{odds}</Text>
        </View>
        <View style={styles.stat}>
          <Text style={styles.statFirstTxt}>{`${overOrUnder === 'Over' ? 'O' : 'U'} ${overunder}`}</Text>
          <Text style={styles.statSecondTxt}>{odds}</Text>
        </View>
        <View style={styles.stat}>
          <Text style={styles.stat3Txt}>{moneyline}</Text>
        </View>
      </View>
    </View>
  );
};

const BettingLine = ({
  team1name, 
  team2name,
  spread1,
  spread2,
  odds1,
  odds2,
  ou1,
  ou2,
  moneyline1,
  moneyline2,
  overOrUnder1,
  overOrUnder2,
  betmgmId,
  trackingCode,
}) => {
  const [date, setDate] = useState('');
  let getDate = () => {
    // get date in format of Sunday / 4:25 PM
  };
  
  return (
    <View style={styles.container}>
      <Text style={styles.sponsoredText}>- Sponsored Betting Line -</Text>
      <View style={styles.mainBox}>
        <View style={styles.heading}>
          <Image 
            style={styles.logo} 
            source={{uri: 'https://cdn.mesearch.ai/assets/betMGM/web-drkmod.png'}}
          />
          <Text style={styles.date}>{date}</Text>
          <TouchableOpacity 
            onPress={() => {
              const link = trackingCode ? `https://sports.pa.betmgm.com/en/sports/events/${trackingCode}` : `https://sports.pa.betmgm.com/en/sports/events/]`
              Platform.OS === 'web' 
                ? window.open(link) 
                : Linking.openURL(link);
            }}
          >
            <Text style={styles.wagersText}>ALL WAGERS {`>`}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.statsBox}>
          <Stats 
            teamName={team1name}
            spread={spread1}
            odds={odds1}
            overunder={ou1}
            moneyline={moneyline1}
            overOrUnder={overOrUnder1}
            // containerStyle={{}}
          />
          <Text style={styles.atSign}>@</Text>
          <Stats 
            teamName={team2name}
            spread={spread2}
            odds={odds2}
            overunder={ou2}
            moneyline={moneyline2}
            overOrUnder={overOrUnder2}
            // containerStyle={{}}
          />
        </View>
        <View style={styles.footer}>
          <TouchableOpacity
            style={styles.footerBtns}
            onPress={() => {
              const link = `https://sports.pa.betmgm.com/en/sports/events/${betmgmId}`;
              Platform.OS === 'web' 
                ? window.open(link) 
                : Linking.openURL(link);
            }}
          >
            <Text style={styles.footerText}>View Matchup</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.footerBtns}
            onPress={() => {
              const link = `https://sports.pa.betmgm.com/en/sports/events/${betmgmId}${trackingCode}`;
              Platform.OS === 'web' 
                ? window.open(link) 
                : Linking.openURL(link);
            }}
          >
            <Text style={styles.footerText}>Place Bet</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    margin: 10,
    // width: 450,
  },
  sponsoredText: {
    fontSize: 10,
    padding: 10,
  },
  mainBox: {
    width: 600,
    alignItems: 'center',
  },
  heading: {
    flexDirection: 'row',
    backgroundColor: 'black',
    width: 600,
    height: 40,
    alignItems: 'center',
    paddingHorizontal: 10,
    justifyContent: 'space-between',
  },
  logo: {
    height: 40,
    width: 100,
  },
  date: {},
  wagersText: {
    color: 'white',
    alignContent: 'flex-end',
    fontWeight: '600',
  },
  statsBox: {
    flexDirection: 'row',
    width: 600,
    backgroundColor: 'rgb(243,243,243)',
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  atSign: {
    fontSize: 30,
    fontStyle: 'italic',
    color: 'gray',
    marginHorizontal: 40,
  },
  footer: {
    width: 600,
    height: 40,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    backgroundColor: 'rgb(176,161,110)',
    alignItems: 'center',
  },
  footerBtns: {
    backgroundColor: 'rgb(188,175,130)',
    height: 40,
    width: 160,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 3,
  },
  footerText: {
    color: 'rgb(243,241,235)',
    fontSize: 16,
    fontWeight: '500',
    paddingHorizontal: 20,
  },
  statsContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  statsTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '500',
    paddingBottom: 8,
  },
  statsRow: {
    flexDirection: 'row',

  },
  stat: {
    width: 60,
    height: 40,
    backgroundColor: 'rgb(197,197,197)',
    padding: 2,
    marginRight: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  statFirstTxt: {
    flexDirection: 'row',
    fontWeight: 'bold',
  },
  statSecondTxt: {
    flexDirection: 'row',
  },
  stat3Txt: {
  },
});

export default BettingLine;
