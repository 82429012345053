import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Dimensions, SafeAreaView, StyleSheet, ImageBackground, Image, Linking } from 'react-native';
import SearchBar from './SearchBar';
import { DrawerActions } from '@react-navigation/native';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import Upload from './Upload';
import { Feather } from '@expo/vector-icons';
import MeTags from './utils/meTags';
import DineLocalSearchBar  from './DineLocalSearchBar';
import uuidv4 from 'uuid/v4';


const DineLocalHeader = ({ 
    imageBackgroundUri,
    imageHeaderUri,
    imageHeaderLink,
    headingText,
    subHeadingTxt,
    subHeaderLink,
    setCurSearchTxt,
    setErrorMessage,
    setSearchScreenPage,
    setNumFound,
    setFreeSearch,
    fetchRelevantUserContentTags,
    updateUserSearchHistory,
    setUserSpecifiedTag,
    setUserSpecifiedResults,
    setAllTags,
    setResults,
    setResultsTags,
    setNonGreedyResults,
    home,
    setHome,
    placeholder,
    searchDesc,
    searchDescLink,
    setSearchHash,
    navigation,
    searchBoxColor,
    darkMode,
    free,
    errorMessage,
    numFound
 }) => {

    const [term, setTerm] = useState('');

    return (
        <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingBottom: 10}}>
        <ImageBackground
            source={{uri: imageBackgroundUri}}
            defaultSource={Platform.OS === 'web' ? {uri: imageBackgroundUri} : null}
            style={styles.imageBackground}
            imageStyle={{opacity: 0.8}}
            resizeMethod="scale"
            resizeMode="cover"
        >
            <View style={{flex: 1, paddingTop: (Platform.OS === 'web') ? 5 : (Platform.OS === 'ios') ? (Dimensions.get('window').height < 600) ? 5 : 60 : 5, paddingLeft: (Platform.OS === 'web' && isMobile) ? 5 : null}}>
                <View style={{flexDirection: 'row', justifyContent: 'flex-start'}}>
                    <TouchableOpacity 
                        style={{marginTop: 18, paddingLeft: 10, paddingRight: 0, width: 50, height: 50}} 
                        onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
                    >
                        <Feather 
                            name="menu" 
                            style={{
                            fontSize: 35,
                            color: 'white'
                            }}
                        />
                    </TouchableOpacity>
                </View>
                <View style={{paddingTop: (Platform.OS === 'web' && !isMobile) ? Dimensions.get('window').height > 800 ? Dimensions.get('window').height/6 : 35 : 0, justifyContent: 'center', alignItems: 'center'}}>
                    <View>
                        <Image
                            source={{uri: imageHeaderUri}}
                            defaultSource={Platform.OS === 'web' ? {uri: imageHeaderUri} : null}
                            style={styles.imageLogo}
                        />
                        {imageHeaderLink ?
                        <TouchableOpacity
                            onPress={() => {
                                Platform.OS === 'web' 
                                  ? window.open(imageHeaderLink.uri) 
                                  : Linking.openURL(imageHeaderLink.uri);
                            }}
                        >
                            <Text style={{color: '#00a2ff', fontWeight: "800", fontSize: 16, marginTop: -50, marginBottom: 20, paddingTop: 50, marginLeft: (Platform.OS === 'web' && !isMobile) ? 95 : 110}}>{imageHeaderLink.txt}</Text>
                        </TouchableOpacity>
                        : null}
                    </View>
                    <View style={{width: Math.min(Dimensions.get('window').width-20, 600), justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 35}}>
                        <Text 
                            style={{color: 'white', fontWeight: "800", fontSize: (Dimensions.get('window').height < 600) ? 18 : 28, textAlign: 'center'}} 
                            numberOfLines={3}
                        >
                            {headingText}
                        </Text>
                        <View style={{flexDirection: 'row'}}>
                            {subHeadingTxt ?
                                <Text 
                                    style={{color: 'white', fontWeight: "400", fontSize: (Dimensions.get('window').height < 600) ? 16 : 22, paddingTop: 14, fontSize: 18}} 
                                    numberOfLines={8}
                                >
                                    {subHeadingTxt}
                                </Text>
                            : null}
                            {subHeaderLink ?
                                <TouchableOpacity
                                    onPress={() => {
                                        Platform.OS === 'web' 
                                        ? window.open(subHeaderLink.uri) 
                                        : Linking.openURL(subHeaderLink.uri);
                                    }}
                                >
                                    <Text style={{color: '#00a2ff', fontWeight: "800", fontSize: 18, paddingLeft: 10, paddingTop: 15}}>{subHeaderLink.txt}</Text>
                                </TouchableOpacity>
                            : null}
                        </View>
                    </View>
                    <DineLocalSearchBar
                        searchBoxColor={searchBoxColor}
                        term={term}
                        onTermChange={(txt) => {
                        setTerm(txt)
                        }}
                        onTermSubmit={() => {
                            let txt = term
                            if (txt !== "") {
                                if (home) {
                                setHome(false);
                                }
                                setAllTags([]);
                                setResults([]);
                                setResultsTags([]);
                                setNonGreedyResults([]);
                                let Tag = '';
                                MeTags.map(t => {
                                    if (t.toLowerCase() === txt.toLowerCase() || t.toLowerCase().includes(txt.toLowerCase().split(" ").length > 0 ? txt.toLowerCase().split(" ")[0] : txt.toLowerCase())) {
                                        Tag = t;
                                    }
                                });
                                if (Tag !== '') {
                                setUserSpecifiedTag(Tag);
                                setUserSpecifiedResults([]);
                                } else {
                                setUserSpecifiedTag('');
                                setUserSpecifiedResults([]);
                                }
                                setSearchScreenPage(0);
                                setNumFound(-1);
                                setFreeSearch(true);
                                setCurSearchTxt(term);
                                fetchRelevantUserContentTags(txt, true, true, true);
                                updateUserSearchHistory(txt);
                                setTerm('');
                                setErrorMessage('');
                                setSearchHash(`${uuidv4()}`);
                            } else {
                              setCurSearchTxt(term);
                              setTerm('');
                              setErrorMessage('');
                            }
                        }}
                        placeholder={placeholder}
                        searchDesc={searchDesc}
                        searchDescLink={searchDescLink}
                    />
             
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: (Platform.OS === 'web' && !isMobile) ? 15 : 5, right: 0, left: 0}}>
                    <View>
                        <Text style={{color: 'white', fontSize: (Dimensions.get('window').height < 600)  ? 16 : 22, fontWeight: "300"}}>Browse All</Text>
                        <Feather name="chevron-down" style={styles.iconstyle}/>
                    </View>
                </View>
            </View>
        </ImageBackground>
        </View>
    );
};

const styles =  StyleSheet.create({
    imageBackground: {
        width: Dimensions.get('window').width, 
        height: Dimensions.get('window').height-50,
        //justifyContent: 'center',
        //alignItems: 'center',
        //flexDirection: 'row',
    },
    imageLogo: {
        width:  (Platform.OS === 'web' && !isMobile) ? 300 : 300,
        height:  (Platform.OS === 'web' && !isMobile) ?  120 : 90,
        marginRight: (Platform.OS === 'web' && !isMobile) ? 60 : 40,
        //marginRight: (Platform.OS === 'web' && !isMobile) ? 120 : 40
    },
    iconstyle: {
        paddingLeft: 25,
        color: 'white',
        fontSize: (Dimensions.get('window').height < 600) ? 32 : 48
    }
});

export default DineLocalHeader;