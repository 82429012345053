import React, { useEffect, useState } from 'react';
import  { Text, Button, Input, Icon } from 'react-native-elements';
import { View, StyleSheet, Image, Dimensions, TouchableOpacity, SafeAreaView, Linking } from 'react-native';
import { Platform } from 'react-native';
import { isMobile } from 'react-device-detect';

import { useForm, Controller } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers';
// import { onChange } from 'react-native-reanimated';

const schema = object().shape({
    email: string().email().required(), 
    password: string().required()
});

const AuthForm = ({ 
    copyWriteText,
    privacyUrl,
    termsOfServiceUrl,
    signinCallback,
    benefitText,
    logoImagePath,
    onSignUp,
    onSubmitAsGuest,
    navigation,
    errorMessage,
    clearErrorMessage
}) => {

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async data => {
    const { password, email } = data;
    signinCallback({ username:email, password })
  }

  const setDefault = () => {
    if (Platform.OS === 'web') {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let usrEmail = params.get('email');
        if (usrEmail) {
          return usrEmail
        } else {
          return "";
        }
    } else {
      return "";
    }
  };

  return (

    <SafeAreaView style={(Platform.OS === 'web' && !isMobile) ? styles.containerLarge : styles.containerSmall}>

      <View style={{ justifyContent: 'center', alignItems: 'center', paddingBottom:10}}>
        <Image style={{width: (Platform.OS === 'web' && !isMobile) ? 320 : 200, height: (Platform.OS === 'web' && !isMobile) ? 180 : 130, resizeMode: 'contain', }} source={logoImagePath}/>
      </View>

      <View style={{flex:1, alignItems: 'center', }}>
        
          <View style={{alignItems: 'center',}}>

          <Text style={{color:'black', fontSize:18, fontWeight:'bold', left: (Platform.OS === 'web' && !isMobile) ? null : -135, }}>Log In</Text>

          <Controller
            name="email"
            control={ control }
            defaultValue={setDefault}
            render={({ onChange, value }) => (
                <Input 
                    label=""
                    value={value}
                    containerStyle={{
                        width: (Platform.OS === 'web' && !isMobile) ? 600 : Dimensions.get('window').width - 50,}}
                    onChangeText={(value) => onChange(value)}
                    placeholder="Email Address"
                    errorMessage={ errors.email?.message }
                    rightIcon={
                    <Icon
                        name='at-sign'
                        type='feather'
                        size={24}
                        color='silver'
                    />
                    }
                />
            )}
          />

            <Controller
            name="password"
            control={ control }
            defaultValue=""
            render={({ onChange, value }) => (
                <Input
                secureTextEntry={true}
                label=""
                placeholder="Password"
                value={value}
                containerStyle={{
                    width: (Platform.OS === 'web' && !isMobile) ? 600 : Dimensions.get('window').width - 50,
                }}
                onChangeText={(value) => {clearErrorMessage(); onChange(value)}}
                errorMessage={ errors.password?.message }
                //onSubmitEditing={() => signinCallback({ username, password })}
                rightIcon={
                  <Icon
                    name='eye-off'
                    type='feather'
                    size={24}
                    color='silver'
                  />
                }
              />
            )}
          />
          {errorMessage ? <View><Text style={styles.errorMessage}>{errorMessage}</Text></View> : null}

          <Text style={{color:'#009BFF', fontWeight:'bold', marginBottom:10, left: (Platform.OS === 'web' && !isMobile) ? null : -100, marginLeft: 20}} onPress={() => navigation.navigate('ForgotPassword')}>Forgot password?</Text>
          
          </View>
          
          <View style={{justifyContent:'center', alignItems:'center'}}>
            <Button 
                containerStyle={{width: (Platform.OS === 'web' && !isMobile) ? 585 : Dimensions.get('window').width - 75, marginBottom:10}}
                buttonStyle={{backgroundColor:'#009BFF'}}
                title="Log In"
                onPress={handleSubmit(onSubmit)
            }
            />
            <Text style={{left:0, marginBottom:10, fontSize:11}}>{benefitText}</Text>
            <Button 
                containerStyle={{width:(Platform.OS === 'web' && !isMobile) ? 585 : Dimensions.get('window').width - 75, marginBottom:10}}
                buttonStyle={{ borderColor:'#009BFF', borderWidth:1, backgroundColor:'white'}}
                titleStyle={{color:'#009BFF',}}
                title="Create an Account"
                onPress={() =>  onSignUp()}
            />
            <Button 
                containerStyle={{width:(Platform.OS === 'web' && !isMobile) ? 585 : Dimensions.get('window').width - 75,}}
                buttonStyle={{ borderColor:'#009BFF', borderWidth:1, backgroundColor:'white'}}
                titleStyle={{color:'#009BFF',}}
                title="Continue as a Guest"
                onPress={() =>  onSubmitAsGuest()}
            />
          </View>        

      <View style={{width:(Platform.OS === 'web' && !isMobile) ? 600 : Dimensions.get('window').width - 75, paddingTop:10, alignItems:'center',}}>
          <Text>By signing up or logging in you agree to our </Text>
          <View style={{flexDirection:'row'}}>
            <TouchableOpacity 
                onPress={()=> {
                    if (Platform.OS === 'web') {
                        let nUrl = privacyUrl
                        window.open(`${nUrl}`)
                    } else {
                        Linking.openURL(privacyUrl)
                    }
                }}
            ><Text style={{color:'#3385E5'}}>Privacy Policy</Text> 
            </TouchableOpacity>
            <Text> and </Text>
            <TouchableOpacity 
                onPress={()=> {
                    if (Platform.OS === 'web') {
                        let nUrl = termsOfServiceUrl
                        window.open(`${nUrl}`)
                    } else {
                        Linking.openURL(termsOfServiceUrl)
                    }
                }}
            ><Text style={{color:'#3385E5'}}>Terms of Service</Text>
            </TouchableOpacity>
          </View>
      </View>
          
      <View style={{height: 70, width:(Platform.OS === 'web' && !isMobile) ? 600 : Dimensions.get('window').width - 75, marginBottom: 1, paddingTop:10, alignItems:'center'}}>
          <Text style={{color:'silver', fontSize:11}}>{copyWriteText}</Text>
      </View>

      </View>

    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
    containerSmall: {
      flex: 1,
      minHeight: 500,
      justifyContent: 'center',
      marginBottom: 5,
      alignItems: 'center'
    },
    containerLarge: {
      width: Dimensions.get('window').width,
      paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
      paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
      justifyContent: 'center',
      // marginBottom: 20
    },
    image: {
      width: null,
      height: null,
      resizeMode: 'contain',
      flex: 1
    },
    errorMessage: {
        fontSize: 16,
        color: 'red',
        left:0
      },
    roundBttn:{
      backgroundColor: '#3385E5',
      justifyContent: 'center',
      alignContent: 'center',
      borderWidth: 3,
      borderColor: '#3385E5',
      borderRadius: 30,
      marginRight:5
      
    },
    
  });

export default AuthForm;