import React, { useContext, useState, useEffect } from 'react';
import { Text, View } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { months, days, years, hours, minutes, period } from './utils/dateTimeConstants';


export const CalendarDropdown = ({onChange, date, mode}) => {
  return (
    <View style={{flexDirection:'row'}}>
      <View>
      <Text style={{fontWeight:'bold', paddingBottom:10}}>{mode === 'date' ? 'Month' : 'Hour'}</Text>
      <Picker
        selectedValue={`${mode === 'date' ? new Date(date).getMonth() : new Date(date).getHours() > 12 ? new Date(date).getHours()-12 : new Date(date).getHours()}`}
        onValueChange={mth => {
          let nDate = new Date(date);
          mode === 'date' 
            ? onChange({}, new Date(nDate.setMonth(mth)))
            : onChange({}, new Date(nDate.setHours(mth)));
        }}
        placeholder="Select"
        style={{ width: 100, height:30, borderColor:'grey', borderWidth:0 }}
        mode="dropdown"
      >
        {mode === 'date' ?
          months.map((i) => {
            return (
              <Picker.Item label={`${i.label}`} value={`${i.value}`} key={`${i.label}`} />
            )
          })
        :
          hours.map((i) => {
            return (
              <Picker.Item label={`${i.label}`} value={`${i.value}`} key={`${i.label}`} />
            )
          })
        }
      </Picker>
      </View>
      <View>
        <Text style={{fontWeight:'bold', paddingBottom:10}}>{mode === 'date' ? 'Day' : 'Minute'}</Text>
        <Picker
          selectedValue={`${mode === 'date' ? new Date(date).getDate() : new Date(date).getMinutes()}`}
          onValueChange={dd => {
            let nDate = new Date(date);
            mode === 'date'
              ? onChange({}, new Date(nDate.setDate(dd)))
              : onChange({}, new Date(nDate.setMinutes(dd)));
          }}
          placeholder="Select"
          //containerStyle={{width: 100, height:30, borderColor:'grey', borderWidth:3}}
          style={{ width: 100, height:30, marginLeft:10, borderColor:'grey', borderWidth:0  }}
          mode="dropdown"
        >
          {mode === 'date' ?
            days.map((i) => {
              return (
                <Picker.Item label={`${i.label}`} value={`${i.value}`} key={`${i.label}`} />
              )
            })
          :
            minutes.map((i) => {
              return (
                <Picker.Item label={`${i.label}`} value={`${i.value}`} key={`${i.label}`} />
              )
            })
          }
        </Picker>
      </View>
      <View>
        <Text style={{fontWeight:'bold', paddingBottom:10}}>{mode === 'date' ? 'Year' : 'Period'}</Text>
        <Picker
          selectedValue={`${mode === 'date' ? new Date(date).getFullYear() : new Date(date).getHours() >= 12 ? "PM" : "AM"}`}
          onValueChange={yy => {
            let nDate = new Date(date);
            mode === 'date'
              ? onChange({}, new Date(nDate.setFullYear(yy)))
              : onChange({}, new Date(nDate.setHours(nDate.getHours() + ((yy === "PM") ? 12 : -12))))
          }}
          placeholder="Select"
          style={{ width: 120, height:30, marginLeft:10, borderColor:'grey', borderWidth:0 }}
          mode="dropdown"
        >
          {mode === 'date' ?
            years.map((i) => {
              return (
                <Picker.Item label={`${i.label}`} value={`${i.value}`} key={`${i.label}`} />
              )
            })
          :
            period.map((i) => {
              return (
                <Picker.Item label={`${i.label}`} value={`${i.value}`} key={`${i.label}`} />
              )
            })
          }
        </Picker>     
      </View>
    </View>
  );
}

export default CalendarDropdown;