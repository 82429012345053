import React, { useState, useEffect } from 'react';
import  { CheckBox } from 'react-native-elements';
import { View, Text, Image, ImageBackground, Dimensions, TouchableOpacity, Animated } from 'react-native';
import { Platform } from 'react-native';
import { Feather } from '@expo/vector-icons';


const FocusCenterTile = ({ img, defaultImg, title, numMembers, setFocusCenterChecks, focusCenterChecks, groupId, accountId, onPress, indx, fCenter, forumId, activeUsersCount, visibilityRestriction, endIndx, navigation }) => {
  
  const [err, setErr] = useState(false);
  const [animatedValue, setAnimatedValue] = useState(new Animated.Value(0));
  const [frontInterpolate, setFrontInterpolate] = useState(animatedValue.interpolate({
    inputRange: [0, 180],
    outputRange: ['0deg', '180deg']
  }));
  const [frontAnimatedStyle, setFrontAnimatedStyle] = useState({
    transform: [
      { rotateX: frontInterpolate }
    ]
  });

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      animatedValue.setValue(0);
    });
    return unsubscribe;
  }, []);


  return (
    <Animated.View 
      style={[
        {
          backgroundColor: indx % 2 === 0 ? '#F0F8FF' : '#bce0eb', 
          width: Math.min(Dimensions.get('window').width-26, 600), 
          backfaceVisibility: 'hidden', 
          borderTopLeftRadius: indx === 0 ? 6 : null,
          borderTopRightRadius: indx === 0 ? 6 : null,
          borderBottomLeftRadius: indx === endIndx ? 6 : null,
          borderBottomRightRadius: indx === endIndx ? 6 : null
        }, 
        frontAnimatedStyle
      ]}
    >
        <View style={{justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'row'}}>
            <CheckBox
                containerStyle={{marginTop: 20}}
                title=""
                onPress={() => {
                    let newFocusChecks = JSON.parse(JSON.stringify(focusCenterChecks));
                    newFocusChecks[forumId] = !newFocusChecks[forumId] ? true : false;
                    setFocusCenterChecks(newFocusChecks);
                }}
                checkedColor={'#007bff'}
                checked={focusCenterChecks[forumId]}
            />
            <TouchableOpacity 
              onPress={() => {
                Animated.timing(animatedValue, {
                  toValue: 180,
                  duration: 800
                }).start();
                setTimeout(() => {
                  onPress(fCenter);
                }, 500);
              }}
              style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 10}}
            >
                {img ?
                <Image 
                    style={{ height: 60, width: 60, justifyContent: "center", alignItems: "center", borderRadius: 30 }} 
                    source={{ uri: (err && Platform.OS !== 'web') ? `https://cdn.mesearch.ai/assets/stockPhotos/alpha/${title.replace(/[0-9]/g, "X").substring(0, 1).toLowerCase()}.jpg` : img }}
                    defaultSource={Platform.OS === 'web' ? {uri: `https://cdn.mesearch.ai/assets/stockPhotos/alpha/${title.replace(/[0-9]/g, "X").substring(0, 1).toLowerCase()}.jpg`} : null}
                    onError={() => {
                        if (Platform.OS !== 'web') {
                            setErr(true);
                        }
                    }} 
                />
                : null}
                <Text style={{fontSize: 20, fontWeight: '400', paddingLeft: 10, color: 'blue'}}>{title}</Text>
            </TouchableOpacity>
        </View>
        <View>
            <View style={{flexDirection: 'row'}}>
                {/*<Text style={{margin: 10}}>{`Members: ${numMembers}`}</Text>*/}
                <Text style={{margin: 10}}>{`Active Users: ${activeUsersCount}`}</Text>
                <Text style={{margin: 10}}>{`Status: ${visibilityRestriction}`}</Text>
                <Feather name={visibilityRestriction === "Open" ? 'unlock' : 'lock'} style={{ fontSize: 15, marginTop: 11, color: visibilityRestriction === "Open" ? 'green' : 'red', marginLeft: -3}}/>
            </View>
        </View>
    </Animated.View>
  );
};

export default React.memo(FocusCenterTile);