import React from 'react';
import { Text, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Feather } from '@expo/vector-icons';

const Upload = ({ darkMode, guest, navToLogin, pub, termsAccepted, setErrorMsg, navigation, claimed, businessName }) => {
  return (
    <View style={{flexDirection:'row'}}>
      <View>
        <TouchableOpacity onPress={() => {
          if (termsAccepted) {
            if (guest) {
              navToLogin(true, true)
            } else {
              navigation.navigate('Drawer', {screen: 'Account', params: {screen: 'UploadContent', params: { darkMode, isGuest: guest, claimed, displayForm: true }}});
            }
          } else {
            setErrorMsg("You must accept the terms of service and use at the bottom of the page to upload content.")
          }
        }}>
          <Feather name="plus-circle" style={{fontSize: 35, paddingLeft: !claimed ? 8 : 15, paddingRight: 5, marginTop: 8, color: darkMode ? 'white' : 'black'}}/>
          <Text style={{fontSize: 10, color: darkMode ? 'white' : 'black', paddingLeft: claimed ? 20 : null}}>{!claimed ? "Post Story" : "News"}</Text>
        </TouchableOpacity>
      </View>
      {claimed ?
      <View>
        <TouchableOpacity onPress={() => {
          if (termsAccepted) {
            if (guest) {
              navToLogin(true, true)
            } else {
              // TODO: Make uploadType an enum
              navigation.navigate('Drawer', {screen: 'Account', params: {screen: 'UploadContent', params: { darkMode, isGuest: guest, claimed, uploadType: 'Offer', businessName, displayForm: true }}});
            }
          } else {
            setErrorMsg("You must accept the terms of service and use at the bottom of the page to upload content.")
          }
        }}>
          <Feather name="plus-circle" style={{fontSize: 35, paddingLeft: !claimed ? 8 : 15, paddingRight: 5, marginTop: 8, color: darkMode ? 'white' : 'black'}}/>
          <Text style={{fontSize: 10, color: darkMode ? 'white' : 'black', paddingLeft: claimed ? 20 : null}}>Offer</Text>
        </TouchableOpacity>
      </View>
      : null}
    </View>
  );
};

const styles = StyleSheet.create({
    container: {
      marginLeft: 15,
    }
});

export default Upload;