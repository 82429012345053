import React, {useState, useContext, useEffect} from 'react';
import { SafeAreaView,  StyleSheet,  View,  FlatList, Dimensions, Image,  Text, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import MeSearchApi from '../api/MeSearch-API';
import { Rect, Text as TextSVG, Svg } from "react-native-svg";
import { Feather, FontAwesome, Foundation } from '@expo/vector-icons';
import { Context as PubContext } from '../context/PubContext';

//import React Native chart Kit for different kind of Chart
import {
  LineChart,
  BarChart,
  PieChart,
  ProgressChart,
  ContributionGraph,
  StackedBarChart,
} from 'react-native-chart-kit';

const PubChartsScreen = ({navigation, route}) => {
  
  const pubName = route.params?.pubName ?? 'NorwinStar';
  const { state } = useContext(PubContext);

  const [refData] = useState([{id:1}]);
  const [rowData, setRowData] = useState([0,2]);
  const [colData, setColData] = useState([0,2]);
  const [solrData, setSolrData] = useState([0,2]);
  const [hdrData, setHdrData] = useState([0,2]);
  const [isLoading, setIsLoading] = useState(true);

  let rsData =''
  const [pieChart, setPiechart] = useState([{name: "Desktop", population: 700, color: "rgb(250,2,166)", legendFontColor: "#7F7F7F", legendFontSize: 15},{name: "Desktop", population: 700, color: "rgb(250,2,166)", legendFontColor: "#7F7F7F", legendFontSize: 15}]);
  let chartDevices=[];

  const [dayLabel, setDaylabel] = useState([]);
  const [dayData, setDayData] = useState([0,2]);
  let WeekdayLabel=[];
  let WeekdayData=[];

  const [hrsLabel, setHrlabel] = useState([]);
  const [hrsData, setHrData] = useState([0,2]);
  let hoursLabel=[];
  let hoursData=[];

  const [tagLabel, setTaglabel] = useState([]);
  const [tagData, setTagData] = useState([0,2]);
  let tagsLabel=[];
  let tagsData=[];

  let chartHr=[];
  let chartTags=[];
  let chartHeat=[];
  let hdr=[];
  let alpha=[];

  const getYMD = () => {
    // Get ISO date range for now
    const startDateTimeMS = new Date();
    const startDateMS = startDateTimeMS.setDate(startDateTimeMS.getDate() - 7);
    const startISODate = new Date(startDateMS);
    const startYMD = `${startISODate.getUTCFullYear()}-${`${startISODate.getUTCMonth()}`.length === 1 
      ? `0${startISODate.getUTCMonth()+1}` 
      : `${startISODate.getUTCMonth()+1}`}-${`${startISODate.getUTCDate()}`.length === 1 ? `0${startISODate.getUTCDate()}`: `${startISODate.getUTCDate()}`}`;
    return startYMD;
  }
 
  const [rptDate, setRptDate] = useState(getYMD()/*'2021-05-21'*/);
  const [rsCount, setRsCount] = useState(0);

  const getRandomColor = () =>
  {
      return 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';
  }

  const getStatsApi = async() => {
    try {
      setIsLoading(true);
      const response = await MeSearchApi.get('/api/test/solr-stats', {params: {period: rptDate, pub: pubName, pubOwner: state.publisher.owner }});
      rsData = response.data
      //debugger
      let devices = rsData.facet_counts.facet_fields.clickdevice
      let weekday = rsData.facet_counts.facet_fields.clkdayofweek
      let hr = rsData.facet_counts.facet_fields.clkhr
      let tag = rsData.facet_counts.facet_fields.contentTags
      let heat = rsData.facet_counts.facet_pivot['clickpub,clkday']
      setRsCount(rsData.response.numFound);
      setIsLoading(false);
      ///////////////////////////////////////////////////////
      // LOAD DEVICE ARRAY
      ///////////////////////////////////////////////////////

      let i=0; 
      let cnt=0; 
      let term='';

      devices.forEach((key, el) => {
        if (i % 2 ==0){
          term = key;
        }
        else{
          cnt=key
          if (cnt!=0){
            let slice = getRandomColor();
            chartDevices.push({'name': term,  'population': cnt, 'color': slice, 'legendFontColor': '#7F7F7F', 'legendFontSize': 15});
          }

        }
        i++
      });

      setPiechart(chartDevices);

      ///////////////////////////////////////////////////////
      // LOAD WEEKDAY ARRAY
      ///////////////////////////////////////////////////////

      i=0; cnt=0; term='';

      weekday.forEach((key, el) => {
        if (i % 2 ==0){
          
          if (key == 1)
          term = "Sun"
          if (key == 2)
          term = "Mon"
          if (key == 3)
          term = "Tue"
          if (key == 4)
          term = "Wed"
          if (key == 5)
          term = "Thr"
          if (key == 6)
          term = "Fri"
          if (key == 7)
          term = "Sat"  
        }
        else{
          cnt=key
          WeekdayLabel.push(term);
          WeekdayData.push(cnt);
        }
        i++
      });
      
      setDaylabel(WeekdayLabel);
      setDayData(WeekdayData);

      ///////////////////////////////////////////////////////
      // LOAD HOUR ARRAY
      ///////////////////////////////////////////////////////

      i=0; cnt=0; term='';

      hr.forEach((key, el) => {
        if (i % 2 ==0){
          term = key-12;
        
          if (term==0)
          term=12

          if (term<0)
            term=term*-1 + 'am'
          else
            term=term + 'pm'

        }
        else{
          cnt=key
          hoursLabel.push(term);
          hoursData.push(cnt);
        }
        i++
      });

      setHrlabel(hoursLabel);
      setHrData(hoursData);

      ///////////////////////////////////////////////////////
      // LOAD TAG ARRAY
      ///////////////////////////////////////////////////////

      i=0; cnt=0; term='';

      tag.forEach((key, el) => {
        if (i % 2 ==0){
          term = key;
        }
        else{
          cnt=key
          tagsLabel.push(term);
          tagsData.push([cnt]);
        }
        i++
      });

      setTaglabel(tagsLabel);
      setTagData(tagsData);

      ///////////////////////////////////////////////////////
      // LOAD HEAT ARRAYS
      ///////////////////////////////////////////////////////

      i=0; cnt=0; term='';

      heat.forEach((key, el) => {
        if (key!=null){
          alpha.push(key.value)
          key.pivot.forEach((ky, e) => {
            hdr.push(ky.value)
          });
        }
      });

      hdr.sort();
      alpha.sort();

      let dhdrs = [...new Set(hdr)]; // distinct array

    } catch (err) {
      console.log(err)
    }
  }

  ///////////////////////////////////////////////////

  useState(() => {  

    getStatsApi();

  }, []);

  let [tooltipPos1, setTooltipPos1] = useState({ x: 0, y: 0, visible: false, value: 0 })
  let [tooltipPos2, setTooltipPos2] = useState({ x: 0, y: 0, visible: false, value: 0 })

    return (
      <ScrollView contentContainerStyle={{justifyContent:'center', alignItems:'center'}}>
      {!isLoading ?
      <>
      <View style={{marginTop:50, paddingBottom:10, 
        paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : 18,
        paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : 18,
        flexDirection:'row', width:Dimensions.get('window').width, justifyContent:'space-between'}}>
  
       <Text style={{fontWeight:'bold'}}>TRAFFIC INSIGHTS: {rptDate} to present for {pubName} publication</Text> 
       <Text style={{color:'#0984e3'}} onPress={() => window.open('https://mobile.knowledgekiosk.com/pubstats.htm')}>Detailed Analytics</Text>
       <Feather name="refresh-cw" style={{fontSize: 20, color: '#2da5ff', width: 35}} onPress={() => getStatsApi()}/> 
      </View>
      <View style={{paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : 18}}>
      <Text style={{fontWeight:'bold'}}>Result Total: {rsCount !== -1 ? `${rsCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : ""} </Text>
      </View>

    <View 
        style={{padding:20}}
        showsVerticalScrollIndicator={false}
    >
      <View style={{
          width: Dimensions.get('window').width,
          paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : 18,
          paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : 18,
        }}>

      <View style={{borderRadius: 4, borderColor:'silver', borderWidth:1, padding:5}}>
        <Text style={{fontWeight:'bold'}} >Visits by Device</Text>
        <Text style={{color:'grey'}}>{rptDate} to present</Text>    
        <PieChart
        data={pieChart}
        width={Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width)/2) : Dimensions.get('window').width-50}
        height={220}
        chartConfig={{
          backgroundColor: '#1cc910',
          backgroundGradientFrom: '#eff3ff',
          backgroundGradientTo: '#efefef',
          decimalPlaces: 2,
          color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
          style: {
            borderRadius: 16,
          },
        }}
        style={{
          marginVertical: 8,
          borderRadius: 16,
        }}
        accessor="population"
        backgroundColor="transparent"
        paddingLeft="15"
        absolute //for the absolute number remove if you want percentage
      />
    </View>

    <View style={{borderRadius: 4, borderColor:'silver', borderWidth:1, marginTop:10, padding:5}}>
      <Text style={{fontWeight:'bold'}} >Visits by Weekday</Text>
      <Text style={{color:'grey'}}>{rptDate} to present</Text>  

    <LineChart
      data={{
        labels: dayLabel,
        datasets: [
          {
            data: dayData, //dayData
            color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
            strokeWidth: 2,
          },
        ],
      }}
      width={Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width)/2) : Dimensions.get('window').width-50}
      height={220}
      chartConfig={{
        backgroundColor: '#1cc910',
        backgroundGradientFrom: '#eff3ff',
        backgroundGradientTo: '#efefef',
        fillShadowGradient:'skyblue',
        fillShadowGradientOpacity:1,
        decimalPlaces: 0,
        color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
        style: {
          borderRadius: 16,
        },
      }}
      style={{
        marginVertical: 8,
        borderRadius: 16,
      }}

      decorator={() => {
        return tooltipPos1.visible ? <View>
            {<Svg>
                <Rect x={tooltipPos1.x - 15} 
                    y={tooltipPos1.y + 10} 
                    width="40" 
                    height="30"
                    fill="black" />
                    <TextSVG
                        x={tooltipPos1.x + 5}
                        y={tooltipPos1.y + 30}
                        fill="white"
                        fontSize="16"
                        fontWeight="bold"
                        textAnchor="middle">
                        {tooltipPos1.value}
                    </TextSVG>
            </Svg>}
        </View> : null
    }}

    onDataPointClick={(data) => {

        let isSamePoint = (tooltipPos1.x === data.x 
                            && tooltipPos1.y === data.y)

        isSamePoint ? setTooltipPos1((previousState) => {
            return { 
                      ...previousState,
                      value: data.value,
                      visible: !previousState.visible
                   }
        })
            : 
        setTooltipPos1({ x: data.x, value: data.value, y: data.y, visible: true });

    }}
    />
 </View>

      <View style={{borderRadius: 4, borderColor:'silver', borderWidth:1, marginTop:10, padding:5}}>
        
        <Text style={{fontWeight:'bold'}} >Visits by Time of Day</Text>
        <Text style={{color:'grey'}}>{rptDate} to present</Text>  

        <LineChart
          data={{
            labels: hrsLabel,
            color: "#CD7F32",
            barColors: ['skyblue', ],
            datasets: [
              {
                data: hrsData,
              },
            ],
          }}
          width={Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width)/2) : Dimensions.get('window').width-50} // from react-native
          height={220}
          //yAxisLabel={'Rs'}
          chartConfig={{
            backgroundColor: '#1cc910',
            backgroundGradientFrom: '#eff3ff',
            backgroundGradientTo: '#efefef',
            fillShadowGradient:'skyblue',
            fillShadowGradientOpacity:1,
            decimalPlaces: 0, // optional, defaults to 2dp
            color: (opacity = 255) => `rgba(0, 0, 0, ${opacity})`,
            style: {
              borderRadius: 16,
            },
          }}
          bezier
          style={{
            marginVertical: 8,
            borderRadius: 16,
          }}   

          decorator={() => {
            return tooltipPos2.visible ? <View>
                {<Svg>
                    <Rect x={tooltipPos2.x - 15} 
                        y={tooltipPos2.y + 10} 
                        width="40" 
                        height="30"
                        fill="black" />
                        <TextSVG
                            x={tooltipPos2.x + 5}
                            y={tooltipPos2.y + 30}
                            fill="white"
                            fontSize="16"
                            fontWeight="bold"
                            textAnchor="middle">
                            {tooltipPos2.value}
                        </TextSVG>
                </Svg>}
            </View> : null
        }}
    
        onDataPointClick={(data) => {
    
            let isSamePoint = (tooltipPos2.x === data.x 
                                && tooltipPos2.y === data.y)
    
            isSamePoint ? setTooltipPos2((previousState) => {
                return { 
                          ...previousState,
                          value: data.value,
                          visible: !previousState.visible
                       }
            })
                : 
            setTooltipPos2({ x: data.x, value: data.value, y: data.y, visible: true });
    
        }} 
        />
      </View>

      <View style={{borderRadius: 4, borderColor:'silver', borderWidth:1, marginTop:10, padding:5}}>
        <Text style={{fontWeight:'bold'}} >Visits by Tag</Text>
        <Text style={{color:'grey'}}>{rptDate} to present</Text>  

        
      </View>

      </View>


      {/* PARENT TO SCROLL HORIZ */}

      {/* <FlatList
          data={hdrData}
          horizontal={true}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({item}) => (
            <View>
              <Text>{item.lsname}({item.total})</Text>
            </View>
          )}
      />   */}

    {/* <iframe 
        width="100%" 
        height= "500"
        src={'https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/charts/nodechart/scalingnodesedges.htm'}
        //src={`${pdfBase64}`}
        scrolling="auto"
        frameBorder="1"
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen
    />  */}

    {/* {Platform.OS === 'web'
        ?
        <View style={{
          width: Dimensions.get('window').width,
          paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
          paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
        }}>
          <iframe 
          width="100%" 
          height= {Dimensions.get('window').height-50}
          //src={'https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/charts/nodechart/nodesedgesbyjson.htm'}
          src={'https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/charts/msstats.aspx'}
          //src={`${pdfBase64}`}
          scrolling="auto"
          frameBorder="1"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
          />
        </View>
        : 
        <>
            <ScrollView 
                style={{padding:5}}
                showsVerticalScrollIndicator={true}
            >
            <View style={{width:600, height: 1200 }}>
                <WebView
                    //source={{uri: 'https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/charts/nodechart/nodesedgesbyjson.htm'}}
                    source={{uri: 'https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/charts/msstats.aspx'}}
                /> 
            </View>
            </ScrollView>
        </>
        } */}

    </View>
        </>
    : <View style={{flex:1}}><ActivityIndicator style={{marginTop:150}} color="#005157" size="large"/></View> }
    </ScrollView>
    )
}

export default PubChartsScreen;

/*
    https://solr.aseedge.com:8983/solr/baseline_flqcommonssolrd/select?q=((metadatatext:(%22cancer%22)%20OR%20qhtml:(%22cancer%22)))%20OR%20_query_:%22{!join%20from=ke_id%20to=ke_id%20fromIndex=baseline_flqcommonssolrt}{!surround%20maxBasicQueries=250000}text:(cancer)%22&wt=json&omitHeader=true&rows=0&indent=true&fl=ke_id,prodbeg,lsname,title,orderdate,preview,types,iscoded,pages,forumid,matterid,isdup,coding_relevance,coding_importance,coding_priorityalerts,coding_monitoredsearches&fq=accountid:4&fq=accountid:4&facet=true&facet.limit=100000&stats=true&stats.field={!tag=t1%20max=true}coding_viewed&stats.field={!tag=t1%20max=true}coding_downloads&stats.field={!tag=t1%20max=true}coding_superhotdocument&stats.field={!tag=t1%20max=true}coding_hotdocument&facet.pivot={!stats=t1}lsname,msg_yr&facet.sort=index&fq=lsname:(%22Ashland,%20Betsy%22)&fq=msg_yr:(1990 1963 1966)
    
    -- first load years data[facet_counts],[facet_pivot],[lsname,msg_yr], function(key, element){}
    ** sort().reverse(), distinct() years 
    */

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  cell10:{
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    width:30
  },
  cell50: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    width:50
  },
});