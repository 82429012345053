import React, { useContext, useState } from 'react';
import  { Button, Input } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, Text, ActivityIndicator, Platform } from 'react-native';
import Spacer from '../components/Space';
import { navigate } from '../navigationRef';
import { Context as AuthContext } from '../context/AuthContext';
import { Context as PubContext } from '../context/PubContext';

const ForgotPasswordScreen = ({ navigation, route }) => {
    const usrEmail = route.params?.usrEmail ?? '';
    //const usrEmail = navigation.getParam('email');
    const [ email, setEmail ] = useState(usrEmail ? usrEmail : '');
    const [isLoading, setIsLoading] = useState(false);
    const { state, clearErrorMessage, forgotPassword } = useContext(AuthContext);
    const  PubCon = useContext(PubContext);
    return (
      <SafeAreaView style={{justifyContent: 'center', alignItems: 'center', flex: 1}}>
        <View style={{width: Math.min(Dimensions.get('window').width-80, 500), justifyContent: 'center', alignItems: 'center'}}>
          <Spacer>
          <View style={{flex: 0.5, justifyContent: 'center', alignItems: 'center'}}>
            <Image style={styles.image} source={require('../../assets/logo-mesearch.png')}/>
          </View>
          
          </Spacer>
            <Input
              label="Email"
              value={email}
              onChangeText={(newEmail) => setEmail(newEmail)}
              autoCapitalize="none"
              autoCorrect={false}
            />
          {state.errorMessage ? <Text style={styles.errorMessage}>{state.errorMessage}</Text> : null}
          {!isLoading ?
          <Spacer>
            <Button 
              style={{width: 180}}
              title={'Send Reset Link'}
              onPress={async () => {
                setIsLoading(true);
                await forgotPassword({email, referrer: PubCon.state.ref});
                setIsLoading(false);
              }}
            />
          </Spacer>
          :  <ActivityIndicator size="large" style={{marginTop: 15}} color="#005157"/>}
          <Spacer>
            <Button 
              style={{width: 180}}
              title={'Back'}
              onPress={() => navigate('Signin')}
            />
          </Spacer>
        </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    errorMessage: {
      fontSize: 16,
      color: 'red',
      marginTop: 15,
      marginLeft: 15
    },
    image: {
      width: 200,
      height: 80,
      marginTop: 5,
    }
});

export default ForgotPasswordScreen;