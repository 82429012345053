import React, {useState, useEffect, useContext} from 'react';
import { SafeAreaView, View, Text, Image, TouchableOpacity, FlatList, StyleSheet, Dimensions } from 'react-native';
import { HeaderBackButton } from '@react-navigation/stack';
import { Platform } from 'react-native';
import uuidv4 from 'uuid/v4';
import valErrorExist from '../../../screens/utils/FormValidation/utils/valErrorsExist';
import validateFormField from '../../../screens/utils/FormValidation/validateFormField';
import FormFieldTypesEnum from '../../../screens/utils/FormValidation/formFieldTypesEnum';
import UserTypesEnum from '../../../screens/utils/userTypesEnum';
import MenuFormItem from '../../core/MenuFormItem';
import HeaderButton from '../../core/HeaderButton';
import { Context as PubContext } from '../../../context/PubContext';
import { isMobile } from 'react-device-detect';
import StepProgress from '../../StepProgress';
import AccessLvlByGroupEnum from '../../utils/AccessLvlByGroup';
import UserRoleGroupEnum from '../../utils/UserRoleGroup';
import checkCapabilityGroup from '../../utils/checkCapabilityGroup';
import MeSearchAPI from '../../../api/MeSearch-API';

const AccountUserWrapper = ({ fieldsMeta, setFieldsMeta, validate, setEditingField, navigation, route, step, setStep, setIndex, userExists, account }) => {
  const initData = Object.entries(fieldsMeta);
  // const lastStep = mappedData[mappedData.length - 1][1].step;
  const dataSet = initData.filter(item => {
    return item[1].step === step;
  });
  const dataSetObj = Object.fromEntries(dataSet);
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldsMetaInStep, setFieldsMetaInStep] = useState(dataSetObj);
  const [refresh, setRefresh] = useState(uuidv4());

  const proUserRoles = {
    [UserRoleGroupEnum.ADMIN_PRO]: false,
    [UserRoleGroupEnum.ADMIN_BASIC]: false,
  };

  const basicUserRoles = {
    [UserRoleGroupEnum.SUBSCRIBER_PRO]: false,
    [UserRoleGroupEnum.SUBSCRIBER_BASIC]: false,
  };

  const guestUserRoles = {
    [UserRoleGroupEnum.GUEST]: false,
  };

  const generateRoleMeta = usrType => {
    let metaObject = null;
    switch (usrType) {
      case UserTypesEnum.PRO:
        metaObject = {
          name: "Role",
          value: proUserRoles,
          error: "",
          required: true,
          type: FormFieldTypesEnum.RADIO,
          step: 3
        };
        break;
      case UserTypesEnum.BASIC:
        metaObject = {
          name: "Role",
          value: basicUserRoles,
          error: "",
          required: true,
          type: FormFieldTypesEnum.RADIO,
          step: 3
        };
        break;
      case UserTypesEnum.GUEST:
        metaObject = {
          name: "Role",
          value: guestUserRoles,
          error: "",
          required: true,
          type: FormFieldTypesEnum.RADIO,
          step: 3
        };
        break;
      default:
        metaObject = null;
        break;
    }
    return metaObject;
  };

  const generateSysCapability = selectedRole => {
    let setOfCapabilities = checkCapabilityGroup(AccessLvlByGroupEnum[selectedRole]);

    let sysCapabilities = setOfCapabilities.reduce((accumulator, capability) => {
      accumulator[`${capability}`] = true;
      return accumulator;
    }, {});

    const metaObject = {
      name: "System Capabilities",
      value: sysCapabilities,
      error: "",
      required: true,
      type: FormFieldTypesEnum.TOGGLE,
      step: 4
    };

    fieldsMeta['sysCapabilities'] = metaObject;
    setFieldsMeta(fieldsMeta);
    setRefresh(uuidv4());
  };

  useEffect(() => {
    if (fieldsMeta['usrType'].value[UserTypesEnum.PRO] === true && step === 2) {
      const metadata = generateRoleMeta(UserTypesEnum.PRO);
      fieldsMeta['role'] = metadata
      setFieldsMeta(fieldsMeta);
      setRefresh(uuidv4());
    } 
  
    if (fieldsMeta['usrType'].value[UserTypesEnum.BASIC] === true && step === 2) {
      const metadata = generateRoleMeta(UserTypesEnum.BASIC);
      fieldsMeta['role'] = metadata;
      setFieldsMeta(fieldsMeta);
      setRefresh(uuidv4());
    }

    if (fieldsMeta['usrType'].value[UserTypesEnum.GUEST] === true && step === 2) {
      const metadata = generateRoleMeta(UserTypesEnum.GUEST);
      fieldsMeta['role'] = metadata
      setFieldsMeta(fieldsMeta);
      setRefresh(uuidv4());
    }

    if (fieldsMeta['role'] && step === 3) {
      const validRole = Object.entries(fieldsMeta['role']).some(meta => meta[1]);
      if (validRole) {
        const selectedRole = Object.keys(fieldsMeta['role'].value).filter(key => {
          return fieldsMeta['role'].value[`${key}`];
        })[0];
        
        generateSysCapability(selectedRole);
      }
      setRefresh(uuidv4());
    }
  }, [fieldsMeta, step])


  const onSubmit = async () => {
    setErrorMessage("");
    const role = Object.keys(fieldsMeta['role'].value).filter(r => fieldsMeta['role'].value[r]);
    if (role.length === 0) {
      setErrorMessage('Something went wrong.');
      return;
    }
    if (userExists) {
      try {
        await MeSearchAPI.post('/api/user/change-user-permisions', {
          username: fieldsMeta['email'].value,
          newLvl: AccessLvlByGroupEnum[role[0]],
          pubOwner: account,
          sysCapabilities: Object.keys(fieldsMeta['sysCapabilities'].value).map(s => {
            return {key: s, value: fieldsMeta['sysCapabilities'].value[`${s}`], account}
          }),
        });
        navigation.navigate('AccountHome', {refreshAdmins: true});
      } catch (error) {
        console.log('error on submitting userExists', error);
        setErrorMessage("Adding user failed.");
      }
    } else {
      try {
        await MeSearchAPI.post('/api/user/sendEmailConfirmation', {
          email: fieldsMeta['email'].value,
          newLvl: AccessLvlByGroupEnum[role[0]],
          pubOwner: account,
          sysCapabilities: Object.keys(fieldsMeta['sysCapabilities'].value).map(s => {
            return {key: s, value: fieldsMeta['sysCapabilities'].value[`${s}`], account}
          }),
        });
        navigation.navigate('AccountHome', {refreshAdmins: true});
      } catch (error) {
        console.log('error on submitting !userExists', error);
        setErrorMessage("Adding user failed.");
      }
    }
  };
  
  const onNext = () => {
    setErrorMessage('');
    Object.keys(fieldsMetaInStep).forEach(fieldsMetaKey => {
      validateFormField({ setter: setFieldsMetaInStep, original: fieldsMeta, key: fieldsMetaKey, validations: validate });
    });

    if (valErrorExist(fieldsMeta, step)) {
      setErrorMessage('There seems to be missing data or errors in the form. Please correct all errors before submitting.');
    } else {
      setStep(step + 1);
    }
  };

  const onBack = () => {
    setErrorMessage('');
    if (step === 1) {
      navigation.goBack();
    } else {
      setStep(step - 1);
    }
  };

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: 'Add Account User',
      headerTitleAlign:'center',
      headerRight: () => {
        const mappedData = Object.entries(fieldsMeta).sort((a, b) => a.step - b.step);
        const lastStep = mappedData[mappedData.length - 1][1].step;
        if (step === lastStep && Object.values(fieldsMeta['usrType'].value).filter(a => a).length > 0) {
          return (
            <HeaderButton 
              title="Create User"
              onPress={() => onSubmit()}
            />
          );
        } else {
          return (
            <HeaderButton 
              title="Next"
              onPress={() => onNext()}
            />
          );
        }
      },
      headerLeft: () => (
        <HeaderBackButton
          onPress={() => onBack()}
        />
      )
    });
  }, [navigation, step, fieldsMeta, userExists]);

  return (
    <SafeAreaView style={{justifyContent: 'center', alignItems: 'center', padding: 10}}>
      <View style={{width: Math.min(Dimensions.get('window').width-20, 500), justifyContent: 'center'}}>
        { errorMessage ? (
          <Text style={styles.errorMessage}>{errorMessage}</Text>
        ) : null }

        {/* <View style={{marginTop: -5, justifyContent: 'center', alignItems: 'center', paddingTop: 10}}>
          <StepProgress 
            step={step}
            numberOfSteps={4}
          />
        </View> */}
        
        <View style={{height: Dimensions.get('window').height - 300, paddingVertical: 10}}>
          <FlatList 
            data={dataSet}
            keyExtractor={item => item[0]}
            extraData={refresh}
            renderItem={({item, index}) => {
              let render = null;

              if (item[1].type === FormFieldTypesEnum.MULTI || item[1].type === FormFieldTypesEnum.MULTIURL) {
                render = (
                  <MenuFormItem
                    type={item[1].type}
                    itemName={item[1].name}
                    value={item[1]['value']}
                    fieldsMeta={fieldsMeta}
                    setFieldsMeta={setFieldsMeta}
                    fieldKey={item[0]}
                    setEditingField={setEditingField}
                    setIndex={setIndex}
                    setRefresh={setRefresh}
                    logic={false}
                  />
                );
              } else {
                render = (
                  <MenuFormItem 
                    value={item[1].value}
                    itemName={item[1].name}
                    errorFlag={item[1].error ? true : false}
                    type={item[1].type}
                    onPress={() => {
                      setEditingField(`${item[0]}`);
                    }}
                  />
                )
              }

              return (
                render
              );
            }}
          />
        </View>
      
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  errorMessage: {
    fontSize: 14, 
    color: 'red', 
    paddingBottom: 10, 
    paddingLeft: 10
  },
  headerButtonText: {
    color: '#24627a',
    fontWeight: '700',
    fontSize: 16
  }
});

export default AccountUserWrapper;