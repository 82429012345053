import React, { useState } from 'react';
import { View, Text, Image, ImageBackground, Dimensions, TouchableOpacity } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import isVideo from '../../screens/utils/isVideo';
import ContentTypeEnum from '../utils/contentTypes';
import styles from './styles/GridDetailStyles';


const GridDetail = ({ title, srcStampImg, typeStampImg, defaultImage, byline, userGen, media, type, horizontal, isPointPark }) => {

  const [err, setErr] = useState(false);
  const httpPrefixToHttps = new RegExp("http:\/\/ttm-triblive-development.us-east-2.elasticbeanstalk.com", "g");
  const [liked, setLiked] = useState(false);

  const determineThumbnail = () => {
    if (isVideo(media[0])) {
      let thumbnailImage = "";
      media.some((mediaObj) => {
        if (!isVideo(mediaObj)) {
          thumbnailImage = mediaObj;
          return true;
        }
      });
      if (thumbnailImage !== "") {
        return thumbnailImage.replace(httpPrefixToHttps, "https:\/\/triblive.com") === "https://triblive.com/wp-content/themes/TribLIVE/assets/visuals/images/icons/head/TribLIVELogo.jpg" ? defaultImage : thumbnailImage.replace(httpPrefixToHttps, "https:\/\/triblive.com");
      }
      return defaultImage;
    } else {
      return media[0].replace(httpPrefixToHttps, "https:\/\/triblive.com") === "https://triblive.com/wp-content/themes/TribLIVE/assets/visuals/images/icons/head/TribLIVELogo.jpg" ? defaultImage : media[0].replace(httpPrefixToHttps, "https:\/\/triblive.com");
    }
  }
  return (
    <View style={[styles.container, {paddingBottom: horizontal ? 0 : 15, paddingRight: horizontal ? 0 : 15}]}>
      <ImageBackground 
        style={[styles.image, {width: horizontal ? 300 : Math.min(Dimensions.get('window').width-30, 500), height: horizontal ? 300: Math.min(Dimensions.get('window').height-90, 300), justifyContent: 'flex-end'}]}
        imageStyle={{ borderRadius: 10 }}
        source={{ uri: !err ? determineThumbnail() : defaultImage }}
        onError={() => {
          setErr(true);
        }}
      >
        <View style={styles.actions}>
          {
            liked ? (
              <TouchableOpacity
                onPress={() => setLiked(!liked)}
              >
                <AntDesign name="like1" size={24} style={{paddingBottom: 5, color: 'rgba(49,82,211,1)'}} />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                onPress={() => setLiked(!liked)}
              >
                <AntDesign name="like2" size={24} style={{paddingBottom: 5}} />
              </TouchableOpacity>
            )
          }
          <AntDesign name="sharealt" size={24} />
        </View>
        <Image
          style={styles.srcStampImage}
          source={{uri: srcStampImg}}
        />
        {userGen && (!type || type === ContentTypeEnum.ARTICLE ||
          type === ContentTypeEnum.ARTICLEFRAMEVIEW || type === ContentTypeEnum.ARTICLELINKVIEW)
        ? !isPointPark ? 
          (<View style={styles.userGenBadgeContainer}>
            <Image
              style={userGen.accessLvl === 1 ? styles.stampImageCom : styles.stampImageTrus}
              source={ userGen.accessLvl === 1 ? require('../../../assets/community.png') : require('../../../assets/trusted.png')}
            />
          </View>) 
          : null
        : null}
        {typeStampImg
        ?
        <View style={styles.typeBadgeContainer}>
          <Image
            style={styles.nonArtStampImage}
            source={typeStampImg}
          />
        </View>
        : null}
          <View style={[styles.titleContainer, {
            paddingTop: (title.length > 15 ? 5 : 20),
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            borderRadius: 10,
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(9.6px)',
            WebkitBackdropFilter: 'blur(9.6px)',
            borderWidth: 1,
            borderColor: 'rgba(255, 255, 255, 0.34)',
            margin: 10,
            // justifyContent: 'center',
          }]}>
            <Text numberOfLines={3} style={[styles.name, {width: horizontal ? 300: Math.min(Dimensions.get('window').width-30, 500)}]}>{title}</Text>
            <View style={[styles.detailSubContainer, {width: horizontal ? 300 : Math.min(Dimensions.get('window').width-30, 500)}]}>
              {/* <View style={styles.detailSubSubContainer}> */}
                <Text style={styles.byLine}>{byline}</Text>
                {/* <View style={{}}>
                  <AntDesign name="like2" size={24} />
                  <AntDesign name="sharealt" size={24} />
                </View> */}
              {/* </View> */}
            </View>
          </View>
      </ImageBackground>
      <View style={[styles.detailContainer, {width: horizontal ? 300 : Math.min(Dimensions.get('window').width-30, 500), height: '100%'}]}>
        {/* {
          Platform.OS === 'web' ? (
            <View style={[styles.titleContainer, {paddingTop: (title.length > 15 ? 0 : 20),
              backgroundColor: 'rgba(255, 255, 255, 0.29)',
              borderRadius: 16,
              boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
              backdropFilter: 'blur(9.6px)',
              WebkitBackdropFilter: 'blur(9.6px)',
              borderWidth: 1,
              borderColor: 'rgba(255, 255, 255, 0.34)',
            }]}>
              <Text numberOfLines={2} style={[styles.name, {width: horizontal ? 250: Math.min(Dimensions.get('window').width-30, 500)}]}>{title}</Text>
            </View>
          ) : (
            <View style={[styles.titleContainer, {paddingTop: (title.length > 15 ? 0 : 20)}]}>
              <Text numberOfLines={2} style={[styles.name, {width: horizontal ? 250: Math.min(Dimensions.get('window').width-30, 500)}]}>{title}</Text>
            </View>
          )
        } */}
        {/* <View style={[styles.detailSubContainer, {width: horizontal ? 300 : Math.min(Dimensions.get('window').width-30, 500)}]}>
          <View style={styles.detailSubSubContainer}>
            <Text style={styles.byLine}>{byline}</Text>
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
              <AntDesign name="like2" size={24} />
              <AntDesign name="sharealt" size={24} />
            </View>
          </View>
        </View> */}
      </View>
    </View>
  );
};

export default React.memo(GridDetail);