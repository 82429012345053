import React, { useState, useContext, useEffect } from 'react';
import { View, ScrollView, Dimensions, ActivityIndicator } from 'react-native';
import MeSearchPassportForm from '../components/wrappers/MeSearch/PassportFormWrapper.js';
import KioskPassportForm from '../components/wrappers/kiosk/KioskPassportFormWrapper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Context as PubContext } from '../context/PubContext';
import { Platform } from 'react-native';

const PassportScreen = ({ navigation, route }) => {
  const darkMode = route.params?.darkMode ?? false;
  const [kiosk, setKiosk] = useState(false);
  const [fetchComplete, setFetchComplete] = useState(false);
  const { state } = useContext(PubContext);

  const checkToken = async () => {
    const ktoken = await AsyncStorage.getItem('ktoken');
    if (ktoken) {
      if (!state || !state.publisher || !state.publisher.username) {
        setKiosk(true);
      }
    }
    setFetchComplete(true);
  };
  
  useEffect(() => {
    checkToken();
  }, []);

  return (
    <ScrollView contentContainerStyle={[{flex: 1}, darkMode ? {backgroundColor: '#3c3c3d'} : null]}>
    {fetchComplete 
    ? <>{kiosk 
        ? <KioskPassportForm navigation={navigation} route={route}/>
        : <MeSearchPassportForm navigation={navigation} route={route}/>
      }</>
    : <View style={{height: Dimensions.get('window').height}}>
        <ActivityIndicator size={"large"} style={{marginTop: 150}} color="#005157"/>
      </View>
    }
    </ScrollView>
  );
};

export default PassportScreen;