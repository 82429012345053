// This function is not passed into the onPress event of a button, but a reference to what is returned from this function.
// This function should return a function to be executed by the appropriate platform.

import { Alert } from "react-native";

// e.g. const webAlert = window.alert(), where window.alert() is returned from this util function because it received 'web' as a parameter.

const defaultCancel = () => {
  console.log('Cancelled Removal');
};

const alertToConfirm = (platform, title, onConfirmPress, onCancelPress=defaultCancel, message=null) => {
  if (platform === 'web') {
    return () => {
      if (window.confirm(title)) {
        onConfirmPress();
      }
    };
  } else {
    return () => {
      Alert.alert(title, message, [
        {
          text: 'Cancel',
          // onPress: () => onCancelPress(),
          style: 'cancel'
        },
        {
          text: 'Confirm',
          onPress: () => onConfirmPress()
        }
      ], { cancelable: false })
    };
  }
};

export default alertToConfirm;