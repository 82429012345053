import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  TouchableOpacity,
  TextInput,
  FlatList,
  ScrollView,
  ActivityIndicator,
  ImageBackground
} from "react-native";
import adBadge from '../../../assets/ad_content.png' ;
import { isMobile, isTablet } from 'react-device-detect';
import isVideo from '../../screens/utils/isVideo';
import { WebView } from 'react-native-webview';
import { Platform } from 'react-native';
import { Context as PubContext } from '../../context/PubContext';
import { Feather, MaterialCommunityIcons, Ionicons } from "@expo/vector-icons";

const ContentRow = ({navigation, route, data, isLoading, horizontal, onPress, onReachedEnd, setPage, page, adminCapability, myAccLvl}) => {
    const { state } = useContext(PubContext);
    const [scrollMomentum, setScrollMomentum] = useState(false);
    return (
        <FlatList
            data={data}
            horizontal={horizontal}
            showsHorizontalScrollIndicator={(Platform.OS === 'web' && (!isMobile && !isTablet)) ? true : false}
            onScroll={() => {
                setScrollMomentum(true);
            }}
            ListHeaderComponent={
                () => {
                return (
                    <View style={{justifyContent: 'center', alignItems: 'center'}}>
                    {isLoading && page > 0
                    ? <ActivityIndicator size="large" color="#005157"/>
                    : null}
                    </View>
                );
                }
            }
            ListFooterComponent={
                () => {
                return (
                    <View style={{justifyContent: 'center', alignItems: 'center', marginTop: 80}}>
                    {isLoading
                    ? <ActivityIndicator size="large" color="#005157"/>
                    : null}
                    </View>
                );
                }
            }
            keyExtractor={(item) => item._id}
            onEndReachedThreshold={1}
            onEndReached={() => {
                if (onReachedEnd && !isLoading && scrollMomentum) {
                    setPage(page+1);
                    onReachedEnd(page+1);
                    setScrollMomentum(false);
                }
            }}
            renderItem={(item) => {
                return (
                    <TouchableOpacity style={styles.container} onPress={()=> {onPress(item.item)}}>
                        { isVideo(item.item.body[0].media[0])
                        ?
                        <>
                            <View>
                            {Platform.OS === 'web'
                                ?<iframe 
                                width="250" 
                                height="180"
                                src={`${item.item.body[0].media[0]}`}
                                frameBorder="0"
                                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen
                                />
                                : <WebView source={{uri: item.item.body[0].media.length > 0 ? item.item.body[0].media[0] : 'https://cdn.mesearch.ai/assets/media/sample_Clouds.png'}} style={{width: 250, height: 180}}/>}
                            </View>
                        </>
                        :
                            <ImageBackground 
                                style={styles.image}
                                imageStyle={{ borderRadius: 4 }}
                                source={{uri: item.item.body[0].media.length > 0 ? item.item.body[0].media[0] : 'https://cdn.mesearch.ai/assets/media/sample_Clouds.png'}}
                                onError={(err) => {
                                    console.log(err)
                            }}
                            >
                            {(item.type && item.type === "Ad") ?
                            <View style={{backgroundColor: 'black', width: 100, height: 30, padding:3, borderRadius: 4 }}>
                                <Image
                                style={styles.badgeImage}
                                imageStyle={{ borderRadius: 4 }}
                                source={adBadge}
                                />             
                            </View>
                            : null}
                            </ImageBackground>
                        }
                        
                        <View style={{height: 70, width: 250, marginBottom:20, backgroundColor: '#000000', opacity: 0.9}}>
                            <View style={{ height: 50, paddingTop: 1, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                                <Text numberOfLines={2} style={styles.name} >{item.item.body[0].title}</Text>
                            </View>
                            <View 
                            style={{ 
                                flexDirection:'row', 
                                justifyContent:'space-between', 
                                height: 30, 
                                width: 250, 
                                marginTop: -4, 
                                backgroundColor: '#000000', 
                                opacity: 0.9, 
                                borderBottomLeftRadius: 4, 
                                borderBottomRightRadius: 4
                                }}
                            >
                                <View style={{ flexDirection:'row', flex:1, justifyContent:'space-between',}}>
                                    <View style={{ flexDirection:'row', paddingLeft: 5,}}>>
                                        <Image
                                        style={{height: 20, width: 35, paddingLeft: 15, paddingBottom: 5}}
                                        imageStyle={{ borderRadius: 4 }}
                                        source= {{uri: state.publisher.badgeSourceMap[item.item.source]}}
                                        onError={(err) => {
                                            console.log(err)
                                        }}
                                        />
                                        <Text numberOfLines={1} style={styles.textDescStyle} >By {item.item.body[0].authors[0]}</Text>
                                    </View>

                                    {(adminCapability && myAccLvl >= 4 && item.item.type && item.item.type === "Ad")
                                    ?
                                        <TouchableOpacity
                                        style={{
                                            borderColor: "silver",
                                            borderWidth: 1,
                                            paddingHorizontal: 5,
                                            marginBottom:5,
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginHorizontal: 5,
                                            width: 35}}
                                            onPress={() => navigation.navigate('analytics', {reportName: item.item.source, contentTitle: item.item.body[0].title, body_id: item.item.body_id,})}
                                        >
                                            <Feather name="bar-chart-2" size={24} color="silver" />
                                        </TouchableOpacity>
                                    : null}

                                </View>
                            </View>
                        </View>
                    </TouchableOpacity>
                    )
                }
                }
            />
    )
};


const styles = StyleSheet.create({
    badgeImage: {
        width: 75,
        height: 20,
        marginTop: 2,
        marginLeft: 5,
        paddingLeft: 5,
        backgroundColor: 'black'
    },
    container: {
        marginLeft: 15,
        
        justifyContent:'center', 
        flexDirection:'column',
    },
    image: {
        width: 250,
        height: 180,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        marginBottom: -2,
        },
  
    textDescStyle: {
        color: 'white',
        fontWeight: '300',
        fontSize: 14,
        paddingBottom: 5,
        paddingLeft: 5,
        paddingRight: 10,
        maxWidth:150
    },
    name: {
        width: 250,
        height: 42,
        fontWeight: 'bold',
        color: 'white',
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
    },
  });

export default ContentRow;