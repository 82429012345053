import React, { useState, useEffect, useContext } from 'react';
import  { Button, Input, CheckBox } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, ActivityIndicator, FlatList, Text, ScrollView } from 'react-native';
import Spacer from '../components/Space';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import MeSearchAPI from '../api/MeSearch-API';
import BusinessTypesEnum from './utils/businessTypesEnum';
import ValidationTypesEnum from './utils/FormValidation/formValidateTypesEnum';
import SubmitField from './utils/FormValidation/FormValidationSubmitField';
import validateFormField from './utils/FormValidation/validateFormField';
import valErrorExist from './utils/FormValidation/utils/valErrorsExist';
import FormFieldTypesEnum from './utils/FormValidation/formFieldTypesEnum';
import FormField from '../components/formField';
import { Context as PubContext } from '../context/PubContext';



const ClaimBusinessScreen = ({navigation, route}) => {
  const body_id = route.params?.body_id ?? '';
  const content = route.params?.content ?? {};
  const addBusiness = route.params?.addBusiness ?? false;
  const [successMessage, setSuccessMsg] = useState(null);
  const [errorMessage, setErrorMsg] = useState(null);
  const [admin, setAdmin] = useState(false);
  const  PubCon = useContext(PubContext);
  const [ pubTags, setPubTags ] = useState({
    "Oakmont Advance Leader": false, "Penn Hills Progress": false, "Penn-Trafford Star": false, "Plum Advance Leader": false, "North Journal": false, "Pine Creek Journal": false,
    "Bloomfield-Garfield Area News": false, "Downtown Pittsburgh News": false, "East End Area News": false, "Glenwood Area News": false, "Highland Park News": false,
    "Hill District News": false, "Homestead Area News": false, "Lawrenceville Area News": false, "North Side Area News": false, "Oakland Area News": false, "Shadyside-Squirrel Hill": false,
    "South Side Area News": false, "West End Area News": false, "Carnegie Signal Item": false, "Perryopolis Area News": false, "South Hills Record": false, "Edgewood-Swissvale Area News": false,
    "Fox Chapel Herald": false, "Monroeville Times Express": false, "Norwin Star": false, "Shaler Journal": false, "Hampton Journal": false, "Pittsburgh Airport Area News": false, "Sewickley Herald": false,
    "Wilkinsburg Area News": false, "Woodland Hills Area News": false
  });
  const [ optPubTags, setOptPubTags ] = useState({
    "Dine-In": false, "Take-Out": false, "Outdoor Seating": false, "Delivery": false, "American": false, "Asian": false, "Breakfast": false, "Burgers": false, "Chinese": false, "Desserts": false,
    "Fast Food": false, "Healthy": false, "Italian": false, "Mexican": false, "Mediterranean": false, "Cuban": false, "French": false, "Sandwiches": false, "Pizza": false, "Seafood": false,
    "Soup": false, "Steak": false, "Vegan": false
  });

  const genBusinessTypesOptions = () => {
    const businessTypeOptions = {};
    Object.values(BusinessTypesEnum).forEach(val => {
      businessTypeOptions[`${val}`] = false;
    });
    return businessTypeOptions;
  };

  const constructHoursObject = (hours) => {
    if (hours) {
        let hourComps = hours.split("-");
        if (hourComps.length !== 2) {
          return {open: '', openAM: true, openPM: false, close: '', closeAM: false, closePM: true};
        }
        return {open: `${hourComps[0]}`, openAM: true, openPM: false, close: `${hourComps[1]}`, closeAM: false, closePM: true};
    } else {
        return {open: '', openAM: true, openPM: false, close: '', closeAM: false, closePM: true};
    }
  };

  const constructHoursString = (hoursObj) => {
    let openStr = hoursObj.openAM ? `${hoursObj.open}` : `${Number(hoursObj.open) + 12}`;
    let closeStr = hoursObj.closeAM ? `${hoursObj.close}` : `${Number(hoursObj.close) + 12}`;
    return `${openStr}-${closeStr}`;
  };

  const [salesRep, setSalesRep] = useState(false);

  const [fieldsMeta, setFieldsMeta] = useState({
    businessName: {name: "Business Name", value: `${content.businessName ? content.businessName : ""}`, error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT},
    //businessType: {name: `Business Type (enter one: ${Object.values(BusinessTypesEnum).toString()})`, value: "Restaurant", error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT},
    businessType: {name: "Business Type", value: genBusinessTypesOptions(), error: "", required: true, type: FormFieldTypesEnum.RADIO, horizontal: true},
    businessStreet: {name: "Business Street", value: `${(content.location && content.location.street) ? content.location.street : ""}`, error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT},
    businessCity: {name: "Business City", value: `${(content.location && content.location.city) ? content.location.city : ""}`, error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT},
    businessState: {name: "Business State", value: `${(content.location && content.location.state) ? content.location.state : ""}`, error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT},
    businessZip: {name: "Business Zip", value: `${(content.location && content.location.postalCode) ? content.location.postalCode : ""}`, error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT, trim: true},
    businessCountry: {name: "Business Country", value: `${(content.location && content.location.country) ? content.location.country : ""}`, error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT},
    businessPhoneNum: {name: "Business Phone Number", value: `${content.phoneNum ? content.phoneNum : ""}`, error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT},
    authRepName: {name: "Business Authorized Representative Name", value: "", error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT},
    authRepEmail: {name: "Business Authorized Representative Email", value: "", error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT, trim: true},
    authRepPhoneNum: {name: "Business Authorized Representative Phone Number", value: "", error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT},
    authRepStreet: {name: "Business Authorized Representative Street", value:  `${(content.location && content.location.street) ? content.location.street : ""}`, error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT},
    authRepCity: {name: "Business Authorized Representative City", value: `${(content.location && content.location.city) ? content.location.city : ""}`, error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT},
    authRepState: {name: "Business Authorized Representative State", value: `${(content.location && content.location.state) ? content.location.state : ""}`, error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT},
    authRepZip: {name: "Business Authorized Representative Zip", value: `${(content.location && content.location.postalCode) ? content.location.postalCode : ""}`, error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT, trim: true},
    authRepCountry: {name: "Business Authorized Representative Country", value: `${(content.location && content.location.country) ? content.location.country : ""}`, error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT},
    businessTilePicUrl: {name: "Image on the Directory (paste url or upload an image, example: a picture of a signiture dish)", value: "", error: "", required: false, type: FormFieldTypesEnum.IMAGEUPLOAD, trim: true, /*size: {height: 250, width: 250}*/},
    hours: {name: "Business Hours", value: constructHoursObject(), error: "", required: false, type: FormFieldTypesEnum.DATERANGE, trim: true},
    //hours: {name: "Business Hours (in military time i.e 0-24, example: 8-17 would be 8am to 5pm)", value: `${content.hours ? content.hours : "8-17"}`, error: "", required: false, type: FormFieldTypesEnum.TEXTINPUT, trim: true},
    businessWebsite: {name: "Business Website", value: `${content.website ? content.website : ""}`, error: "", required: false, type: FormFieldTypesEnum.TEXTINPUTURL, trim: true},
    businessLogoUri: {name: "Business Logo Url (paste url or upload an image)", value: "", error: "", required: false, type: FormFieldTypesEnum.IMAGEUPLOAD, trim: true, /*size: {height: 90, width: 300}*/},
    businessFacebook: {name: "Business Facebook Url", value: `${content.fbUrl ? content.fbUrl : ""}`, error: "", required: false, type: FormFieldTypesEnum.TEXTINPUTURL, trim: true},
    businessTwitter: {name: "Business Twitter Url", value: `${content.twtUrl ? content.twtUrl : ""}`, error: "", required: false, type: FormFieldTypesEnum.TEXTINPUTURL, trim: true},
    businessInstagram: {name: "Business Instagram Url", value: `${content.instaUrl ? content.instaUrl : ""}`, error: "", required: false, type: FormFieldTypesEnum.TEXTINPUTURL, trim: true},
    healthInfo: {name: "Health Info (i.e masks required, etc...)", value: `${content.healthInfo ? content.healthInfo : ""}`, error: "", required: false, type: FormFieldTypesEnum.TEXTINPUT},
    menuUrl: {name: "Menu Url (paste url or upload a pdf file)", value: `${content.menuUrl ? content.menuUrl : ""}`, error: "", required: false, type: FormFieldTypesEnum.FILEUPLOAD, trim: true},
    reserveUrl: {name: "Reservation Service Url", value: `${content.reserveUrl ? content.reserveUrl : ""}`, error: "", required: false, type: FormFieldTypesEnum.TEXTINPUTURL},
  });

  const [fieldsMetaValidate, setFieldsMetaValidate] = useState({
    businessName: {validate: SubmitField(ValidationTypesEnum.ANY)},
    businessType: {validate: SubmitField(ValidationTypesEnum.RADIO)},
    businessStreet: {validate: SubmitField(ValidationTypesEnum.ANY)},
    businessCity: {validate: SubmitField(ValidationTypesEnum.ANY)},
    businessState: {validate: SubmitField(ValidationTypesEnum.ANY)},
    businessZip: {validate: SubmitField(ValidationTypesEnum.NUMBER)},
    businessCountry: {validate: SubmitField(ValidationTypesEnum.ANY)},
    businessWebsite: {validate: SubmitField(ValidationTypesEnum.URL)},
    businessPhoneNum: {validate: SubmitField(ValidationTypesEnum.PHONE)},
    businessLogoUri: {validate: SubmitField(ValidationTypesEnum.URL)},
    businessFacebook: {validate: SubmitField(ValidationTypesEnum.URL)},
    businessTwitter: {validate: SubmitField(ValidationTypesEnum.URL)},
    businessInstagram: {validate: SubmitField(ValidationTypesEnum.URL)},
    businessTilePicUrl: {validate: SubmitField(ValidationTypesEnum.URL)},
    hours: {validate: SubmitField(ValidationTypesEnum.DATERANGE)},
    healthInfo: {validate: SubmitField(ValidationTypesEnum.ANY)},
    menuUrl: {validate: SubmitField(ValidationTypesEnum.URL)},
    reserveUrl: {validate: SubmitField(ValidationTypesEnum.URL)},
    authRepName: {validate: SubmitField(ValidationTypesEnum.ANY)},
    authRepEmail: {validate: SubmitField(ValidationTypesEnum.EMAIL)},
    authRepPhoneNum: {validate: SubmitField(ValidationTypesEnum.PHONE)},
    authRepStreet: {validate: SubmitField(ValidationTypesEnum.ANY)},
    authRepCity: {validate: SubmitField(ValidationTypesEnum.ANY)},
    authRepState: {validate: SubmitField(ValidationTypesEnum.ANY)},
    authRepZip: {validate: SubmitField(ValidationTypesEnum.NUMBER)},
    authRepCountry: {validate: SubmitField(ValidationTypesEnum.ANY)}
  });

  const [ pgLoad, setPGLoad ] = useState(false);

  const updateFieldsMeta = (key, value) => {
    let newFieldsMeta = JSON.parse(JSON.stringify(fieldsMeta));
    newFieldsMeta[key] = value;
    setFieldsMeta(newFieldsMeta);
  }

  const getAccessLvl = (user, pub) => {
    let accessLvl = 1;
    if (user.accessLvl) {
      user.accessLvl.forEach(accessObj => {
        if (accessObj.account === pub) {
          accessLvl = Math.max(accessObj.perm, accessLvl);
        }
      });
    }
    return accessLvl;
  };

  useEffect(() => {
    async function getAccvLvl() {
        const usrResponse = await MeSearchAPI.get('/api/user');
        const curAccessLvl = getAccessLvl(usrResponse.data, PubCon.state.publisher.username);
        if (curAccessLvl >= 3) {
        setAdmin(true);
        setSalesRep(true);
        }
    }
    getAccvLvl();
  }, []);

  return (
    <ScrollView style={Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall}>
    {!pgLoad ?
    <View>
      <FlatList
        data={Object.keys(fieldsMeta)}
        keyExtractor={(result) => result}
        ListHeaderComponent={() => {
            return (
                <View>
                <Spacer>
                    <View style={{flex: 1, marginTop: 5, justifyContent: 'center', alignItems: 'center', marginLeft: -100}}>
                    <Image style={{
                      height: 180, 
                      width: 500 }} 
                      source={{uri: 'https://cdn.mesearch.ai/assets/dine-local/dine-local-website-header.png' }}
                    />
                    </View>
                </Spacer>
                <View style={{flex: 1, marginTop: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                    <Text style={{fontSize: 30, fontWeight: '500', fontFamily: 'Avenir', color: '#7a7a7a'}}>Claim Your Business</Text>
                </View>
                <Spacer/>
                <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                  {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
                  {successMessage ? <Text style={styles.successMessage}>{successMessage}</Text> : null}
                </View>
                </View>
            );
        }}
        renderItem={({item}) => {
          return (
            <Spacer>
                <View>
                    <FormField
                      fieldKey={item}
                      fieldsMeta={fieldsMeta}
                      setFieldsMeta={setFieldsMeta}
                      type={fieldsMeta[`${item}`].type}
                      navOptions={false}
                      label={true}
                      validate={fieldsMetaValidate}
                      horizontal={fieldsMeta[`${item}`].horizontal ? fieldsMeta[`${item}`].horizontal : false}
                    />
                    {fieldsMeta[`${item}`].error !== "" ? <Text style={styles.errorMessage}>{fieldsMeta[`${item}`].error}</Text> : null}
                </View>
            </Spacer>
          );
        }}
      />
      <Spacer/>
        <Text
          style={{fontSize: 20, fontWeight: '700', color: '#707070', paddingLeft: 13}}
        >
            Which of the following communities does your business serve? Select all that apply. (Don’t see your community? Select the communities closest to your service area.)
        </Text>
        <Spacer/>
        <View style={{paddingLeft: 8}}>
            <FlatList
                data={Object.keys(pubTags)}
                horizontal={false}
                style={{ marginLeft:10 }}
                numColumns={2}
                keyExtractor={(item) => item}
                renderItem={({item, index}) => {
                    return (
                        <CheckBox
                            containerStyle={{flex:1, marginLeft:-10, backgroundColor: null, borderWidth: 0}}
                            title={item}
                            onPress={() => {
                                let newPubTags = JSON.parse(JSON.stringify(pubTags));
                                newPubTags[item] = !newPubTags[item];
                                setPubTags(newPubTags);
                            }}
                            checkedColor={'#007bff'}
                            checked={pubTags[item]}
                        />
                    )
                }}
            />
        </View>
        <Spacer/>
        <Text
          style={{fontSize: 20, fontWeight: '700', color: '#707070', paddingLeft: 15, paddingRight: 13}}
        >
            Which of the following categories describe your business? Select all that apply.
        </Text>
        <Spacer/>
        <View style={{paddingLeft: 8}}>
            <FlatList
                data={Object.keys(optPubTags)}
                horizontal={false}
                style={{ marginLeft:10 }}
                numColumns={2}
                keyExtractor={(item) => item }
                renderItem={({item, index}) => {
                    return (
                        <CheckBox
                            containerStyle={{flex:1, marginLeft:-10, backgroundColor: null, borderWidth: 0}}
                            title={item}
                            onPress={() => {
                                let newOptPubTags = JSON.parse(JSON.stringify(optPubTags));
                                newOptPubTags[item] = !newOptPubTags[item];
                                setOptPubTags(newOptPubTags);
                            }}
                            checkedColor={'#007bff'}
                            checked={optPubTags[item]}
                        />
                    )
                }}
            />
        </View>
      <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
        {successMessage ? <Text style={styles.successMessage}>{successMessage}</Text> : null}
      </View>
      <Spacer>
        {admin ?
            <View style={{paddingLeft: 15, paddingRight: 15}}>
                <Spacer/>
                <Text
                  style={{fontSize: 20, fontWeight: '700', color: '#707070'}}
                >
                  Are you submiting this claim as a sales representative?
                </Text>
                <CheckBox
                    containerStyle={{flex:1, marginLeft:-10, backgroundColor: null, borderWidth: 0}}
                    title={'Yes'}
                    onPress={() => {
                        setSalesRep(!salesRep);
                    }}
                    checkedColor={'#007bff'}
                    checked={salesRep}
                />
                <Spacer/>
            </View>
        : null}
        <View style={{alignItems: 'center', justifyContent: 'center', flexDirection: 'row', paddingBottom: 20}}>
            <Button
              title={!successMessage ? 'Cancel' : 'Go Back'}
              style={{width: 115, paddingRight: 10}}
              onPress={() => {
                // Navigate back to last screen
                navigation.goBack();
              }}
            />
            {!successMessage ?
            <Button
            title={'Submit'}
            style={{width: 100}}
            onPress={ async () =>  {
                setErrorMsg(null);
                setSuccessMsg(null);
                Object.keys(fieldsMeta).forEach(fieldMetaKey => {
                  validateFormField({setter: setFieldsMeta, original: fieldsMeta, key: fieldMetaKey, validations: fieldsMetaValidate});
                });
                if (valErrorExist(fieldsMeta)) {
                    setErrorMsg('Errors In The Form. Please Correct All Errors Before Submitting.');
                } else {
                    /*let pubTagsArr = [];
                    if (pubTags) {
                      Object.keys(pubTags).forEach(pTag => {
                        if (pubTags[`${pTag}`]) {
                            pubTagsArr.push(pTag);
                        }
                      });
                    }*/
                    let pubTagsArr = ["Restaurant News"];
                    let optPubTagsArr = [];
                    if (pubTags) {
                        Object.keys(pubTags).forEach(pTag => {
                          if (pubTags[`${pTag}`]) {
                              optPubTagsArr.push(pTag);
                          }
                        });
                    }
                    if (optPubTags) {
                        Object.keys(optPubTags).forEach(pTag => {
                            if (optPubTags[`${pTag}`]) {
                                optPubTagsArr.push(pTag);
                            }
                        });
                    }
                    // Construct payload
                    let payload = {};
                    Object.keys(fieldsMeta).forEach(fieldMetaKey => {
                      if (fieldsMeta[`${fieldMetaKey}`].type === FormFieldTypesEnum.RADIO) {
                        let value = "";
                        Object.keys(fieldsMeta[`${fieldMetaKey}`].value).forEach(val => {
                            if (fieldsMeta[`${fieldMetaKey}`].value[`${val}`]) {
                                value = val;
                            }
                          
                        });
                        payload[`${fieldMetaKey}`] = value.trim();
                      } else if (fieldsMeta[`${fieldMetaKey}`].type === FormFieldTypesEnum.DATERANGE) {
                        const dateStr = constructHoursString(fieldsMeta[`${fieldMetaKey}`].value)
                        payload[`${fieldMetaKey}`] = dateStr;
                      } else {
                        payload[`${fieldMetaKey}`] = fieldsMeta[`${fieldMetaKey}`].value.trim();
                        if (fieldsMeta[`${fieldMetaKey}`].name === "Business Logo Url (paste url or upload an image)") {
                            payload[`${fieldMetaKey}`] = (fieldsMeta[`${fieldMetaKey}`].value !== "")
                                ? fieldsMeta[`${fieldMetaKey}`].value.trim()
                                : "https://cdn.mesearch.ai/assets/dine-local/dine-local-website-header.png";
                        }
                      }
                    });
                    payload.body_id = body_id;
                    payload.ugcPubTags = pubTagsArr;
                    payload.ugcOptPubTags = optPubTagsArr;
                    payload.salesRep = salesRep;
                    setPGLoad(true);
                    try {
                      if (!addBusiness) {
                        await MeSearchAPI.post('/api/pub-owner/claimBusiness', payload);
                      } else {
                        await MeSearchAPI.post('/api/pub-owner/addBusiness', payload);
                      }
                      setSuccessMsg('Successfully sent your request!');
                      //navigation.goBack();
                    } catch (err) {
                      setErrorMsg(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`);
                    }
                    setPGLoad(false);
                }
            }}
            />
            : null}
        </View>
      </Spacer>
      </View>
    : <ActivityIndicator size="large" style={{ marginTop: 150 }} color="#005157"/>}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
    containerSmall: {
      paddingTop: 15,
      flex: 1,
      paddingLeft: 10,
      paddingRight: 10,
    },
    containerLarge: {
      paddingTop: 15,
      width: Dimensions.get('window').width,
      paddingLeft: Dimensions.get('window').width > 750 ? ((Dimensions.get('window').width - 750)/2) : null,
      paddingRight: Dimensions.get('window').width > 750 ? ((Dimensions.get('window').width - 750)/2) : null
    },
    errorMessage: {
      fontSize: 18, 
      color: 'red', 
      paddingBottom: 10, 
      paddingLeft: 10
    },
    successMessage: {
      fontSize: 18,
      color: 'green',
      marginTop: 15,
      marginLeft: 15
    },
    errorAst: {
      fontSize: 24,
      color: 'red',
    },
    image: {
      width: 200,
      height: 80,
      marginTop: 5,
    }
});

export default ClaimBusinessScreen;