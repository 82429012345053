import React, { useState, useContext, useEffect } from 'react';
import  { Button, Input } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, Text } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Context as PubContext } from '../context/PubContext';
import Spacer from './Space';

const AuthForm = ({ headerText, errorMessage, onSubmit, submitButtonText, onSubmitAsGuest, meSign, upload, navigation, formType, clearErrorMessage }) => {
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');
  const [ localErrorMessage, setLocalErrorMessage ] = useState('');
  const  PubCon = useContext(PubContext);

  const resetUrl = () => {
    if (Platform.OS === 'web') {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let pub = params.get('p');
      if (pub) {
        history.pushState(null, 'body', `/?p=${pub}`);
      } else {
        const pubPageMatches = `${PubCon.state.ref}`.match(/[^\/]+/g);
        let pubPage = (pubPageMatches && pubPageMatches.length >= 3) ? pubPageMatches[2] : '/';
        history.replaceState(null, 'body', `/${pubPage}` === '//' ? '/' : `/${pubPage}`);
      }
    }
  }  

  useEffect (() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setUsername('');
      setPassword('');
      setConfirmPassword('');
      setLocalErrorMessage('');
      if (Platform.OS === 'web') {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let usrEmail = params.get('email');
        if (usrEmail) {
          setUsername(usrEmail);
        }
      }
    });
    return unsubscribe;
  }, []);

  const onSubmitSignup = (username, password) => {
    // check passwords for match, if good, then pass username and password to onSubmit callback.

    if (password === confirmPassword) {
      onSubmit({username, password});
    } else {
      setLocalErrorMessage("Passwords do not match.");
    }
    // else: send back an errorMessage via callback.
  };

  return (
    <SafeAreaView style={{justifyContent: 'center'}}>
      <View style={{marginTop: -5, justifyContent: 'center', alignItems: 'center', paddingBottom: 20, paddingTop: 20}}>
          <Image style={{marginLeft: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? -5 : -70, height: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 105 : 150, width: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 328 : 660}} source={{uri: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? PubCon.state.publisher.assets[1].link /*Top-Header-Mobile*/ : PubCon.state.publisher.assets[0].link /*Top-Header-Web*/}}/>
      </View>
      <View style={{marginHorizontal: (Platform.OS === 'web' ? '20%' : 20)}}>
        <Text style={styles.headerText}>{headerText}</Text>
        <Input 
          label="Email"
          value={username}
          onChangeText={(newUsername) => setUsername(newUsername)}
          autoCapitalize="none"
          autoCorrect={false}
          maxLength={80}
          // inputStyle={styles.inputStyling}
        />
        <Input
          secureTextEntry={true}
          label="Password"
          value={password}
          onChangeText={(newPassword) => setPassword(newPassword)}
          autoCapitalize="none"
          autoCorrect={false}
          onSubmitEditing={() => {
            resetUrl();
            onSubmit({ username, password });
          }}
          maxLength={60}
          // inputStyle={styles.inputStyling}
        />
        { formType === 'signUp' ? (<Input 
          secureTextEntry={true}
          label="Confirm Password"
          value={confirmPassword}
          onChangeText={(passwordConfirmation) => setConfirmPassword(passwordConfirmation)}
          autoCapitalize="none"
          autoCorrect={false}
          maxLength={60}
        />) : null }
        {
          formType !== 'signUp' ? (
            <Text 
              style={{fontSize: 14, color: 'blue', paddingLeft: 15, marginBottom: 10 }}
              onPress={() => {
                clearErrorMessage();
                navigation.navigate('ForgotPassword');
              }}
            >
              Forgot Password?
            </Text>
          ) : null
        }
        {upload ? <Text style={styles.errorMessage}>Sign up for an account to upload content</Text> : null}
        {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
        {localErrorMessage ? <Text style={styles.errorMessage}>{localErrorMessage}</Text> : null}
        {/* <Button 
          title={submitButtonText}
          onPress={() => onSubmit({ username, password })}
          buttonStyle={[styles.button, styles.buttonSolid]}
        /> */}
        {
          formType === 'signUp' ? (
            <Button 
              title={submitButtonText}
              onPress={() => onSubmitSignup(username, password)}
              buttonStyle={[styles.button, styles.buttonSolid]}
            />
          ) : (
            <Button 
              title={submitButtonText}
              onPress={() => onSubmit({ username, password })}
              buttonStyle={[styles.button, styles.buttonSolid]}
            />
          )
        }
        {
          formType === 'signUp' ? (
            <Button 
              title="Sign In Instead"
              type="outline"
              // titleStyle={styles.buttonText}
              buttonStyle={[styles.button, styles.buttonOutline]}
              onPress={() => navigation.navigate('Signin')}
            />
            ) : (
            <Button 
              title="Create an Account"
              type="outline"
              // titleStyle={styles.buttonText}
              buttonStyle={[styles.button, styles.buttonOutline]}
              onPress={() => navigation.navigate('Signup')}
            />
          )
        }
      </View>

    {/* { update the following button to be a link that upon pressing does an onPress thing.} */}
    {!meSign && !upload
    ? <Button 
      type="clear"
      title="Continue As Guest"
      // titleStyle={styles.buttonText}
      onPress={() => {
        resetUrl();
        onSubmitAsGuest();
      }}
      /> : null}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  headerText: {
    fontSize: 24, 
    fontWeight: 'bold',
    color: '#10335F',
    marginHorizontal: 10,
    marginTop: 10,
    marginBottom: 20,
    textAlign: 'center',
  },
  errorMessage: {
    fontSize: 16,
    color: 'red',
    marginTop: 15,
    marginLeft: 15
  },
  image: {
    width: 200,
    height: 80,
    marginBottom: 20,
  },
  button: {
    marginHorizontal: 10,
    marginVertical: 2,
  },
  buttonSolid: {
    backgroundColor: '#10335F',
  },
  buttonOutline: {
    borderColor: '#10335F',
  },
  buttonText: {
    color: '#10335F',
    fontSize: 14
  },
  inputStyling: {
    paddingHorizontal: 5,
  }
});

export default AuthForm;