import React, { useContext, useState, useEffect } from 'react';
import  { Button, Input } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, Text, Linking } from 'react-native';
import Spacer from '../components/Space';
import { Context as AuthContext } from '../context/AuthContext';
import { Context as PubContext } from '../context/PubContext';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';

const ConfirmEmailScreen = ({ navigation, route }) => {
    const confirmCode = route.params?.confirmCode ?? '';
    const changeCode = route.params?.changeCode ?? '';
    const account = route.params?.account ?? '';
    const email = route.params?.email ?? '';
    const [ successMsg, setSuccessMsg ] = useState('');
    const [ canConfirm, setCanConfirm ] = useState('');
    const [ reset, setReset ] = useState(false);
    const [ newPassword, setNewPassword ] = useState('');
    const [ newPasswordConfirm, setNewPasswordConfirm ] = useState('');
    const { state, clearErrorMessage, resetPassword, confirmEmail } = useContext(AuthContext);
    const  PubCon = useContext(PubContext);

    useEffect(() => {
      const unsubscribe = navigation.addListener('focus', () => {
        clearErrorMessage();
        if (changeCode === '') {
            setCanConfirm(true);
        }
      });
      return unsubscribe;
    }, []);

    const resetUrl = () => {
        if (Platform.OS === 'web') {
          let search = window.location.search;
          let params = new URLSearchParams(search);
          let pub = params.get('p');
          if (pub) {
            history.pushState(null, 'body', `/?p=${pub}`);
          } else {
            const pubPageMatches = `${PubCon.state.ref}`.match(/[^\/]+/g);
            let pubPage = (pubPageMatches && pubPageMatches.length >= 3) ? pubPageMatches[2] : '/';
            history.replaceState(null, 'body', `/${pubPage}` === '//' ? '/' : `/${pubPage}`);
          }
        }
    }

    return (
        <SafeAreaView style={{justifyContent: 'center', alignItems: 'center'}}>
            <View style={styles.container}>
                <Spacer>
                    <View style={{flex: 0.5, marginTop: 60, justifyContent: 'center', alignItems: 'center'}}>
                    <Image style={styles.image} source={require('../../assets/logo-mesearch.png')}/>
                    </View>
                </Spacer>
                {(changeCode !== '' && !reset) ?
                <>
                    <Input 
                        secureTextEntry={true}
                        label="New Password"
                        value={newPassword}
                        onChangeText={(newPass) => setNewPassword(newPass)}
                        autoCapitalize="none"
                        autoCorrect={false}
                    />
                    <Spacer/>
                    <Input
                        secureTextEntry={true}
                        label="Repeat New Password"
                        value={newPasswordConfirm}
                        onChangeText={(newPassConfirm) => setNewPasswordConfirm(newPassConfirm)}
                        autoCapitalize="none"
                        autoCorrect={false}
                        onSubmitEditing={() => {
                            if (resetPassword({newPassword, newPasswordConfirm, changeCode, nav: false})) {
                                setCanConfirm(true);
                                setReset(true);
                            }
                        }}
                    />
                    {state.errorMessage ? <Text style={styles.errorMessage}>{state.errorMessage}</Text> : null}
                    <Spacer>
                    <Button 
                        title={'Reset'}
                        onPress={() => {
                            if (resetPassword({newPassword, newPasswordConfirm, changeCode, nav: false})) {
                                setCanConfirm(true);
                                setReset(true);
                            }
                        }}
                    />
                    </Spacer>
                </>
                : null}
                {canConfirm ?
                    <View>
                        <Spacer>
                            <Button 
                            title={'Confirm Email'}
                            onPress={() => {
                                const success = confirmEmail({email, confirmCode, account});
                                if (success) {
                                    setSuccessMsg('Successfully confirmed email');
                                    setCanConfirm(false);
                                    resetUrl();
                                    navigation.navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search'}}});
                                }
                            }}
                            />
                        </Spacer>
                        <View style={styles.section}>
                            <Text>By clicking 'Confirm Email' you are agreeing to our privacy policy and terms of service</Text>
                            <Text style={{fontSize: 14,fontWeight:"400", color: 'blue' }} onPress={ () => (Platform.OS === 'web' && !isMobile) ? window.open('https://mesearch.us/wp-content/uploads/2020/02/Terms-of-Services.pdf') : Linking.openURL('https://mesearch.us/wp-content/uploads/2020/02/Terms-of-Services.pdf') }>
                            Terms of Service and Use
                            </Text>
                            <Text style={{fontSize: 14, fontWeight:"400", color: 'blue' }} onPress={ () => (Platform.OS === 'web' && !isMobile) ? window.open('https://mesearch.us/wp-content/uploads/2020/02/2020-01-01_Crivella-Media-Tech_Collection-and-Use-of-Personal-Information_Policy-only.pdf') : Linking.openURL('https://mesearch.us/wp-content/uploads/2020/02/2020-01-01_Crivella-Media-Tech_Collection-and-Use-of-Personal-Information_Policy-only.pdf') }>
                            Privacy Policy
                            </Text>
                        </View>
                    </View>
                : null}
                <Spacer>
                    {state.errorMessage !== '' ? <Text style={styles.errorMessage}>{state.errorMessage}</Text> : null}
                    {successMsg !== '' ? <Text style={styles.success}>{successMsg}</Text> : null}
                </Spacer>
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    errorMessage: {
      fontSize: 16,
      color: 'red',
      marginTop: 15,
      marginLeft: 15
    },
    image: {
      width: 200,
      height: 80,
      marginTop: 5,
    },
    container: {
      width: Math.min(Dimensions.get('window').width-20, 650),
      justifyContent: 'center',
      alignItems: 'center'
    },
    success: {
      fontSize: 18,
      color: 'green',
      marginTop: 15,
      marginLeft: 15
    },
    section: {
      paddingBottom: 15,
      width: Math.min(Dimensions.get('window').width-80, 300)
    },
});

export default ConfirmEmailScreen;