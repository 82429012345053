import React, {useState, useEffect, useContext} from 'react';
import { SafeAreaView, View, Text, Image, TouchableOpacity, FlatList, StyleSheet, Dimensions } from 'react-native';
import { HeaderBackButton } from '@react-navigation/stack';
import { Platform } from 'react-native';
import valErrorExist from '../../../screens/utils/FormValidation/utils/valErrorsExist';
import validateFormField from '../../../screens/utils/FormValidation/validateFormField';
import FormFieldTypesEnum from '../../../screens/utils/FormValidation/formFieldTypesEnum';
import BusinessTypesEnum from '../../../screens/utils/businessTypesEnum';
import MenuFormItem from '../../core/MenuFormItem';
import HeaderButton from '../../core/HeaderButton';
import { Context as PubContext } from '../../../context/PubContext';
import { isMobile } from 'react-device-detect';
import uuidv4 from 'uuid/v4';


const BusinessSignupWrapper = ({ fieldsMeta, setFieldsMeta, validate, setEditingField, navigation, route, step, setStep, setIndex }) => {
  const mappedData = Object.entries(fieldsMeta);
  const lastStep = mappedData[mappedData.length - 1][1].step;
  const dataSet = mappedData.filter(item => {
    return item[1].step === step;
  });
  const dataSetObj = Object.fromEntries(dataSet);
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldsMetaInStep, setFieldsMetaInStep] = useState(dataSetObj);
  const [refresh, setRefresh] = useState(false);
  const { state, signup, clearErrorMessage } = useContext(PubContext);

  useEffect(() => {
    if (fieldsMeta['businessType'].value['News Publisher'] === true && step === 2) {
      if (!fieldsMeta['sources']) {
        setFieldsMeta(fieldsMeta, fieldsMeta['sources'] = { name: "Content Websites (websites to scrape for your content)", value: [{ value: '' }], error: "", required: false, type: FormFieldTypesEnum.MULTIURL, step: 3, trim: true })
        setRefresh(uuidv4());
      }
    }
    if (fieldsMeta['businessType'].value['News Publisher'] !== true && fieldsMeta['sources']) {
      delete fieldsMeta.sources;
      setFieldsMeta(fieldsMeta)
      setRefresh(uuidv4());
    }

  }, [fieldsMeta]);

  const onSubmit = () => {
    const payload = {
      username: fieldsMeta['businessName'].value,
      displayName: fieldsMeta['businessName'].value,
      type: Object.keys(fieldsMeta['businessType'].value).filter(key => {
        return fieldsMeta['businessType'].value[`${key}`];
      })[0],
      mailAddr: {
        street: fieldsMeta['businessStreet'].value,
        city: fieldsMeta['businessCity'].value,
        state: fieldsMeta['businessState'].value,
        zipCode: fieldsMeta['businessZip'].value,
        country: fieldsMeta['businessCountry'].value,
      },
      website: fieldsMeta['businessWebsite'].value,
      phoneNum: fieldsMeta['businessPhone'].value,
      logoUrl: fieldsMeta['businessLogo'].value,
      displayPicUri: fieldsMeta['businessProfilePic'].value,
      pendingSources: fieldsMeta['sources'] ? fieldsMeta['sources'].value.map(v => v.value) : [],
    };

    const usrPayload = {
      firstName: fieldsMeta['firstName'].value,
      lastName: fieldsMeta['lastName'].value,
      email: fieldsMeta['email'].value,
      phone: fieldsMeta['phone'].value,
    };
    signup({ payload, usrPayload, navigation });
  };
  
  const onNext = () => {
    setErrorMessage('');
    Object.keys(fieldsMetaInStep).forEach(fieldsMetaKey => {
      validateFormField({ setter: setFieldsMetaInStep, original: fieldsMeta, key: fieldsMetaKey, validations: validate });
    });

    if (valErrorExist(fieldsMeta, step)) {
      setErrorMessage('There seems to be missing data or errors in the form. Please correct all errors before submitting.');
    } else {
      setStep(step + 1);
    }
  };

  const onBack = () => {
    setErrorMessage('');
    if (step === 1) {
      navigation.goBack();
    } else {
      setStep(step - 1);
    }
  };

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: 'Create Business Account',
      headerTitleAlign:'center',
      headerRight: () => {
        if (step === lastStep) {
          return (
            <HeaderButton 
              title="Create"
              onPress={() => onSubmit()}
            />
          );
        } else {
          return (
            <HeaderButton 
              title="Next"
              onPress={() => onNext()}
            />
          );
        }
      },
      headerLeft: () => (
        <HeaderBackButton
          onPress={() => onBack()}
        />
      )
    });
  }, [navigation, step]);

  return (
    <SafeAreaView style={{justifyContent: 'center', alignItems: 'center', padding: 10}}>
      <View style={{width: Math.min(Dimensions.get('window').width-20, 500), justifyContent: 'center'}}>
        { errorMessage ? (
          <Text style={styles.errorMessage}>{errorMessage}</Text>
        ) : null }
        <View style={{marginTop: -5, justifyContent: 'center', alignItems: 'center', paddingBottom: 20, paddingTop: 20}}>
          <Image style={{width: 140, height: 50, resizeMode: 'contain', }} source={require('../../../../assets/mesearch_logo_small.png')} />
        </View>
        {
          step === 1 ? (
            <Text style={{fontWeight:'bold', fontSize: 18, marginBottom: 10, paddingLeft: 20}}>Authorized Business Representative</Text>
          ) : null
        }
        {
          step === 2 ? (
            <Text style={{fontWeight:'bold', fontSize: 18, marginBottom: 10, paddingLeft: 20}}>Business Information</Text>
          ) : null
        }
        
        <View style={{height: Math.min(Dimensions.get('window').height-100, 500), paddingVertical: 10}}>
          <FlatList 
            data={dataSet}
            keyExtractor={item => item[0]}
            extraData={refresh}
            renderItem={({item, index}) => {
              let render = null;

              if (item[1].type === FormFieldTypesEnum.MULTI || item[1].type === FormFieldTypesEnum.MULTIURL) {
                render = (
                  <MenuFormItem
                    type={item[1].type}
                    itemName={item[1].name}
                    value={item[1]['value']}
                    fieldsMeta={fieldsMeta}
                    setFieldsMeta={setFieldsMeta}
                    fieldKey={item[0]}
                    setEditingField={setEditingField}
                    setIndex={setIndex}
                    setRefresh={setRefresh}
                    logic={false}
                  />
                );
              } else {
                render = (
                  <MenuFormItem 
                    value={item[1].value}
                    itemName={item[1].name}
                    errorFlag={item[1].error ? true : false}
                    type={item[1].type}
                    onPress={() => {
                      setEditingField(`${item[0]}`);
                    }}
                  />
                )
              }

              return (
                render
              );
            }}
          />
        </View>
      
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  errorMessage: {
    fontSize: 14, 
    color: 'red', 
    paddingBottom: 10, 
    paddingLeft: 10
  },
  headerButtonText: {
    color: '#24627a',
    fontWeight: '700',
    fontSize: 16
  }
});

export default BusinessSignupWrapper;