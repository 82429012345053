import React, { useState } from 'react';
import { View, Text, StyleSheet, Image, ImageBackground } from 'react-native';
import { isMobile } from 'react-device-detect';

const PublicationDetail = ({ publication }) => {

  const styles = StyleSheet.create({
    container: {
      marginLeft: 15,
    },
    image: {
      width: 250,
      height: 180,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      marginBottom: -2,
    },
    name: {
      width: 250,
      height: 42,
      fontWeight: 'bold',
      color: 'white',
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
    },
    textDescStyle: {
      color: 'white',
      fontWeight: '300',
      fontSize: 14,
      paddingBottom: 20,
      paddingLeft: 5,
      paddingRight: 10
    },
  });

  return (
    <View style={styles.container}>
      <ImageBackground 
        style={styles.image}
        imageStyle={{ borderRadius: 4 }}
        source={{ uri: publication.img }}
        onError={(err) => {
          console.log(err)
        }}
      >
      </ImageBackground>
      <View style={{height: 70, width: 250, backgroundColor: '#000000', opacity: 0.9}}>
        <View style={{ height: 50, paddingTop: 10, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
          <Text numberOfLines={2} style={styles.name}>{publication.displayName}</Text>
        </View>
        <View style={{ height: 30, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
          <Text numberOfLines={1} style={styles.textDescStyle}>{publication.description}</Text>
        </View>
      </View>
    </View>
  );
};

export default PublicationDetail;