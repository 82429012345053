import createDataContext from './createDataContext';

const locationReducer = (state, action) => {
    switch (action.type) {
        case 'add_current_location':
            return { ...state, currentLocation: action.payload };
        case 'add_current_address':
            return { ...state, currentAddress: action.payload };
        case 'start_recording':
            return { ...state, recording: true };
        case 'stop_recording':
            return { ...state, recording: false };
        case 'add_location':
            return {...state, locations: [...state.locations, action.payload]};
        case 'reset':
            return {...state, name: '', locations: [] };
        default:
            return state;
    }
};

const startRecording = dispatch => () => {
    dispatch({ type: 'start_recording' });
};
const stopRecording = dispatch => () => {
    dispatch({ type: 'stop_recording' });
};
const addLocation = dispatch => (location, recording) => {
    dispatch({ type: 'add_current_location', payload: location});
    if (recording) {
        dispatch({ type: 'add_location', payload: location});
    }
};

const addAddress = dispatch => (address) => {
    dispatch({ type: 'add_current_address', payload: address });
}

const reset = dispatch => () => {
  dispatch({ type: 'reset' });
};

export const { Context, Provider } = createDataContext(
  locationReducer,
  { startRecording, stopRecording, addLocation, addAddress, reset },
  { name: '', recording: false, locations: [], currentLocation: null, currentAddress: null}
);