import React, {useState} from 'react';
import FormWrapper from '../components/wrappers/MeSearch/FormWrapper';
import AccountUserWrapper from '../components/wrappers/MeSearch/AccountUserWrapper';
import FormFieldTypesEnum from './utils/FormValidation/formFieldTypesEnum';
import UserTypesEnum from './utils/userTypesEnum';
import SubmitField from './utils/FormValidation/FormValidationSubmitField';
import ValidationTypesEnum from './utils/FormValidation/formValidateTypesEnum';
import MeSearchAPI from '../api/MeSearch-API';

const AccountUserScreen = ({ navigation, route }) => {
  const account = route.params?.pubOwner ?? '';
  const [errorResponse, setErrorResponse] = useState('');
  const [userExists, setUserExists] = useState(true);
  const genUsrTypesOptions = () => {
    const usrTypeOptions = {};
    Object.values(UserTypesEnum).forEach(val => {
      usrTypeOptions[`${val}`] = false;
    });
    return usrTypeOptions;
  };

  const accountUserForm = {
    email: { name: "Add New User's Email Address", value: "", error: "", required: true, placeholder: "name@example.com", type: FormFieldTypesEnum.VERIFIED, step: 1 },
    usrType: { name: "User Type", value: genUsrTypesOptions(), error: "", required: true, placeholder: "Select a User Type", type: FormFieldTypesEnum.RADIO, step: 2 },
  };

  const accountUserValidation = {
    email: { validate: SubmitField(ValidationTypesEnum.EMAIL) },
    usrType: { validate: SubmitField(ValidationTypesEnum.RADIO) },
    role: { validate: SubmitField(ValidationTypesEnum.RADIO) },
    sysCapabilities: { validate: SubmitField(ValidationTypesEnum.ANY) }
  };

  const methods = {
    userLookup: async userEmail => {
      return await MeSearchAPI.get('/api/user/is-account-user', {params: {account, username: userEmail}});
    },
    doesUserExist: response => setUserExists(response),
    errorResponse: error => setErrorResponse(error),
  };

  const [step, setStep] = useState(1);

  return (
    <FormWrapper 
      Component={AccountUserWrapper}
      values={accountUserForm}
      validation={accountUserValidation}
      navigation={navigation}
      route={route}
      setStep={setStep}
      step={step}
      methods={methods}
      userExists={userExists}
      account={account}
    />
  );
};
    
export default AccountUserScreen;