import React, { useContext, useState, useEffect } from 'react';
import  { Button } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, Text } from 'react-native';
import Spacer from '../components/Space';
import { Context as AuthContext } from '../context/AuthContext';
import MeSearchAPI from '../api/MeSearch-API';


const ConfirmEmailHoldingScreen = ({ navigation }) => {

    const [errorMessage, setErrorMessage] = useState('');
    const { state, clearErrorMessage, signoutAndSigninToGuest, changeEmailConfirm } = useContext(AuthContext);

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
          clearErrorMessage();
        });
        return unsubscribe;
      }, []);

    return (
        <SafeAreaView style={{justifyContent: 'center', alignItems: 'center', marginTop: 100}}>
            <View style={{marginTop: 60, justifyContent: 'center', alignItems: 'center', width: Math.min(Dimensions.get('window').width-20, 650)}}>
                <Image style={styles.image} source={require('../../assets/logo-mesearch.png')}/>
                <Spacer/>
                <Text style={styles.txt}>Please confirm your email address by clicking the link you got in your email</Text>
                <Spacer>
                  <Button 
                    title={'I Have Confirmed My Email'}
                    onPress={async () => {
                        const response = await MeSearchAPI.get('/api/user');
                        if (response.data && (response.data.isConfirmed || (typeof response.data.isConfirmed === 'undefined'))) {
                            navigation.navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search'}}});
                        } else {
                            setErrorMessage('Email not confirmed yet')
                        }
                    }}
                  />
                </Spacer>
                    <Button 
                      title={'Signout'}
                      onPress={() => signoutAndSigninToGuest({navToLogin: false}) }
                    />
                <Spacer/>
                {state.errorMessage ? <Text style={styles.errorMessage}>{state.errorMessage}</Text> : null}
                {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
  errorMessage: {
    fontSize: 16,
    color: 'red',
    marginTop: 15,
    marginLeft: 15
  },
  image: {
    width: 200,
    height: 80,
    marginTop: 5,
  },
  txt: {
    fontSize: 18,
    fontWeight: '500',
    color: '#4d4d4d',
    textAlign: 'justify',
  },
});

export default ConfirmEmailHoldingScreen;