import AsyncStorage from '@react-native-async-storage/async-storage';
import createDataContext from './createDataContext';
import MeSearchApi from '../api/MeSearch-API';
import MeSearchAPIKiosk from '../api/kiosk/MeSearch-API-Kiosk';
import { navigate } from '../navigationRef';
import { Platform } from 'react-native';
import uuidv4 from 'uuid/v4';
import determineReferrer from '../screens/utils/determineReferrer';

const authReducer = (state, action) => {
  switch (action.type) {
    case 'add_error':
      return { ...state, errorMessage: action.payload };
    case 'add_kerror':
      return {...state, kerrorMessage: action.payload };
    case 'ksignin':
      return {...state, errorMessage: '', ktoken: action.payload, kUsername: '', kPassword: '' };
    case 'signin':
      return { ...state, errorMessage: '', token: action.payload, username: '', password: '', guest: false };
    case 'termsOfService':
      return { ...state, errorMessage: '', username: action.payload.username, password: action.payload.password, guest: action.payload.guest };
    case 'clear_error_message':
      return { ...state, errorMessage: '' };
    case 'clear_kerror_message':
      return { ...state, kerrorMessage: '' };
    case 'signout':
      return {...state, token: null, errorMessage: '', username: '', password: '', guest: false };
    case 'ksignout':
      return {...state, token: null, kerrorMessage: '', kUsername: '', kPassword: ''};
    case 'confirmEmail':
      return {...state, isConfirmed: action.payload};
    case 'toggleGuest':
      return {...state, guest: action.payload};
    default:
      return state;
  }
};

const tryLocalSignin = dispatch => async ({ referrer, nav }) => {
  // Fetch current token
  const token = await AsyncStorage.getItem('token');

  // Check the validity of the current token
  let valid = false;
  let isEmailConfirmed = false;
  if (token) {
    const validObj = await MeSearchApi.post('/api/verify', { token: token, referrer: referrer });
    isEmailConfirmed = validObj.data.guest ? true : (validObj.data.isConfirmed || typeof validObj.data.isConfirmed === 'undefined') ? true : false;
    if (validObj.data.username) {
      console.log(validObj.data.username)
      valid = true;
    }
  }
  dispatch({ type: 'confirmEmail', payload: isEmailConfirmed });
  if (valid) {
    dispatch({ type: 'signin', payload: token });
    if (Platform.OS === 'web') {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      const emailConfirm = params.get('emailConfirm');
      if (emailConfirm) {
        if (nav) {
          navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search'}}});
        }
        return false;
      }
    }
    if (!isEmailConfirmed) {
      navigate('ConfirmEmailHolding');
      return true;
    }
    if (nav) {
      navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search'}}});
      return false;
    }
  } else {
    // Remove pub token if there is one
    await AsyncStorage.removeItem('pubtoken');
    let fingerPrint = "";
    if (Platform.OS === 'web') {
      // Need this conditional import because importing this lib breaks native mobile
      require('clientjs');
      // Set up fingerprint
      const client = new ClientJS();
      fingerPrint = String(client.getFingerprint());
    } else {
      // Create an account with uuid as the username and password
      const uuidv4 = require('uuid/v4');
      fingerPrint = String(uuidv4());
    }
    try {
      const response = await MeSearchApi.post('/api/signin', { username: fingerPrint, password: fingerPrint, referrer: referrer });
      await AsyncStorage.setItem('token', response.data.token);
      dispatch({ type: 'signin', payload: response.data.token });
      if (nav) {
        navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search'}}});
        return false;
      }
    } catch (err) {
      try {
        const response = await MeSearchApi.post('/api/signup', { username: fingerPrint, password: fingerPrint, guest: true, referrer: referrer });
        await AsyncStorage.setItem('token', response.data.token);
        dispatch({ type: 'signin', payload: response.data.token });
        if (nav) {
          navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search'}}});
          return false;
        }
      } catch (err) {
        dispatch({ type: 'add_error', payload: `${err.response ? err.response.data.error : 'Something went wrong with signup'}` });
      }
    }
  }
};

const clearErrorMessage = dispatch => () => {
  dispatch({ type: 'clear_error_message' })
};

// TODO: DEPRECATE
const termsOfService = (dispatch) => {
  return async ({ username, password, guest }) => {
    // Validate username is a correctly formatted email address
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!emailRegex.test(String(username).toLowerCase().trim())) {
      dispatch({ type: 'add_error', payload: 'email not a valid email address' });
      return;
    }
    if (!password || !password.length > 0) {
      dispatch({ type: 'add_error', payload: 'password cannot be empty' });
      return;
    }
    try {
      const usernameTrimmed = String(username).trim();
      const passwordTrimmed = String(password).trim();
      dispatch({ type: 'termsOfService', payload: { username: usernameTrimmed, password: passwordTrimmed, guest } });
      navigate('Signin/Signup', {screen: 'TermsOfService'});
    } catch (err) {
        dispatch({ type: 'add_error', payload: `${err.response ? err.response.data.error : 'Something went wrong with signup'}` });
    }
  };
};

const resetPassword = (dispatch) => {
  return async ({newPassword, newPasswordConfirm, changeCode, nav=true, resetUrl}) => {
    if (newPassword === newPasswordConfirm) {
      try {
        await MeSearchApi.post('/api/user/reset-password', { newPassword, changeCode });
        if (nav) {
          resetUrl();
          navigate('Drawer', {screen: 'Home', params: {screen: 'Signin/Signup'}});
        } else {
          return true;
        }
      } catch (err) {
        dispatch({ type: 'add_error', payload: `${err.response ? err.response.data.error : 'Something went wrong'}` });
        return false;
      }
    } else {
      dispatch({ type: 'add_error', payload: 'Passwords do not match' });
      return false;
    }
  };
};

const confirmEmail = (dispatch) => {
  return async ({email, confirmCode, account}) => {
    try {
      await MeSearchApi.post('/api/user/confirmEmail', { email, confirmCode, account });
      dispatch({ type: 'confirmEmail', payload: true });
      //navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search'}}});
      return true;
    } catch (err) {
      dispatch({ type: 'add_error', payload: `${err.response ? err.response.data.error : 'Something went wrong'}` });
      return false;
    }
  };
};

const sendEmailConfirmation = (dispatch) => {
  return async ({email, account}) => {
    try {
      const referrer = determineReferrer();
      await MeSearchApi.post('/api/user/sendEmailConfirmation', { usrPayload: {email}, account, referrer });
      navigate('ConfirmEmailHolding');
    } catch (err) {
      dispatch({ type: 'add_error', payload: `${err.response ? err.response.data.error : 'Something went wrong'}` });
    }
  };
};

const changeEmailConfirm = (dispatch) => {
  return async ({email, account, newEmail}) => {
    try {
      await MeSearchApi.post('/api/user/changeConfirmEmail', { email, account, newEmail});
      return true;
    } catch (err) {
      dispatch({ type: 'add_error', payload: `${err.response ? err.response.data.error : 'Something went wrong'}` });
      return false;
    }
  };
};

const forgotPassword = (dispatch) => {
  return async ({email, referrer}) => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!emailRegex.test(String(email).toLowerCase().trim())) {
      dispatch({ type: 'add_error', payload: 'email not a valid email address' });
      return;
    }
    try {
      await MeSearchApi.post('/api/user/forgot-password', { email, referrer });
      navigate('Drawer', {screen: 'Home', params: {screen: 'Signin/Signup'}});
    } catch (err) {
      dispatch({ type: 'add_error', payload: `${err.response ? err.response.data.error : 'Something went wrong'}` });
    }
  };
};

const signup = (dispatch) => {
  return async ({ username, password, guest, noToken, referrer, displayName=username }) => {
    // Validate username is a correctly formatted email address
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!emailRegex.test(String(username).toLowerCase().trim())) {
      dispatch({ type: 'add_error', payload: 'email not a valid email address' });
      return;
    }
    if (!password || !password.length > 0) {
      dispatch({ type: 'add_error', payload: 'password cannot be empty' });
      return;
    }
    let confirmed = false;
    try {
      const usernameTrimmed = String(username).trim();
      const passwordTrimmed = String(password).trim();
      if (noToken) {
        const response = await MeSearchApi.post('/api/signup', { username: usernameTrimmed, password: passwordTrimmed, displayName, guest: guest, referrer: referrer });
        await AsyncStorage.setItem('token', response.data.token);
        dispatch({ type: 'signin', payload: response.data.token });
        const res = await MeSearchApi.post('/api/user/sendEmailConfirmation', {usrPayload: {email: usernameTrimmed}, referrer});
        if (res.data && res.data.notification) {
          confirmed = res.data.notification === "Email already confirmed" ? true : false;
        }
      } else {
        // Fetch current token
        const token = await AsyncStorage.getItem('token');
        // Check if current token is associated with a guest account
        let isGuest = false;
        if (token) {
          const validObj = await MeSearchApi.post('/api/verify', { token: token });
          if (validObj.data.guest) {
            isGuest = true;
          }
        }
        if (isGuest) {
          const switchRes = await MeSearchApi.post('/api/user/switch-guest', { newUsername: usernameTrimmed, newPassword: passwordTrimmed, displayName });
          await AsyncStorage.setItem('token', switchRes.data.token);
          dispatch({ type: 'signin', payload: switchRes.data.token });
          const res = await MeSearchApi.post('/api/user/sendEmailConfirmation', {usrPayload: {email: usernameTrimmed}, referrer});
          if (res.data && res.data.notification) {
            confirmed = res.data.notification === "Email already confirmed" ? true : false;
          }
        } else {
          const response = await MeSearchApi.post('/api/signup', { username: usernameTrimmed, password: passwordTrimmed, displayName, guest: guest, referrer: referrer });
          await AsyncStorage.setItem('token', response.data.token);
          dispatch({ type: 'signin', payload: response.data.token });
          const res = await MeSearchApi.post('/api/user/sendEmailConfirmation', {usrPayload: {email: usernameTrimmed}, referrer});
          if (res.data && res.data.notification) {
            confirmed = res.data.notification === "Email already confirmed" ? true : false;
          }
        }
      }
      await MeSearchApi.patch(
        '/api/user', 
        { payload: { 
          termsAccepted: true
        } 
      });
      
      dispatch({ type: 'toggleGuest', payload: false });

      if (confirmed) {
        dispatch({ type: 'confirmEmail', payload: true });
      } else {
        dispatch({ type: 'confirmEmail', payload: false });
        navigate('ConfirmEmailHolding');
      }
      //navigate('Home', {screen: 'Search', params: {refreshUserInfo: uuidv4()}});
    } catch (err) {
      dispatch({ type: 'add_error', payload: `${err.response ? err.response.data.error : 'Something went wrong with signup'}` });
    }
  };
};

const signin = (dispatch) => {
  return async ({ username, password, fingerPrint, referrer, navToScreen }) => {
    try {
      const usernameTrimmed = String(username).trim();
      const passwordTrimmed = String(password).trim();
      const response = await MeSearchApi.post('/api/signin', { username: usernameTrimmed, password: passwordTrimmed, referrer: referrer });
      await AsyncStorage.setItem('token', response.data.token);
      dispatch({ type: 'signin', payload: response.data.token });
      const isEmailConfirmed = response.data.guest ? true : (response.data.isConfirmed || typeof response.data.isConfirmed === 'undefined') ? true : false;
      dispatch({ type: 'confirmEmail', payload: isEmailConfirmed });
      if (fingerPrint) {
        // Merge guest account into user account
        await MeSearchApi.post('/api/user/merge-guest', { fingerPrint: fingerPrint, referrer: referrer });
      }
      if (!isEmailConfirmed) {
        navigate('ConfirmEmailHolding');
        return;
      }
      if (navToScreen) {
        if (navToScreen.screen) {
          let screenObj = {};
          screenObj.screen = navToScreen.screen;
          screenObj.params = navToScreen.params ? navToScreen.params : {};
          navigate(navToScreen.parentNav, screenObj);
          return;
        } else {
          navigate(navToScreen.parentNav);
          return;
        }
      } else {
        navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search', params: {refreshUserInfo: uuidv4()}}}});
        return;
      }
    } catch (err) {
        dispatch({
          type: 'add_error',
          payload: `${err.response ? err.response.data.error : 'Something went wrong with sign in'}`
        });
    }
  };
};

const signout = (dispatch) => {
  return async ({ clrToken, termsAccepted }) => {
    // Remove pub token if there is one
    await AsyncStorage.removeItem('pubtoken');
    if (clrToken) {
      await AsyncStorage.removeItem('token');
      dispatch({ type: 'signout' });
      navigate('Drawer', {screen: 'Home', params: {screen: 'Signin/Signup', params: {screen: 'Signin', params: { termsAccepted }}}});
    } else {
      AsyncStorage.getItem('token').then((token) => {
        if (token) {
          navigate('Drawer', {screen: 'Home', params: {screen: 'Signin/Signup', params: {screen: 'Signin', params: { termsAccepted }}}});
        }
      });
    }
  };
};

const signoutAndSigninToGuest = (dispatch) => {
  return async ({navToLogin}) => {
    try {
      await AsyncStorage.removeItem('token');
      dispatch({ type: 'signout' });
      // Remove pub token if there is one
      await AsyncStorage.removeItem('pubtoken');
      let fingerPrint = "";
      if (Platform.OS === 'web') {
        // Need this conditional import because importing this lib breaks native mobile
        require('clientjs');
        // Set up fingerprint
        const client = new ClientJS();
        fingerPrint = String(client.getFingerprint());
      } else {
        // Create an account with uuid as the username and password
        const uuidv4 = require('uuid/v4');
        fingerPrint = String(uuidv4());
      }
      const referrer = determineReferrer();
      try {
        const response = await MeSearchApi.post('/api/signin', { username: fingerPrint, password: fingerPrint, referrer: referrer });
        await AsyncStorage.setItem('token', response.data.token);
        dispatch({ type: 'signin', payload: response.data.token });
        if (!navToLogin) {
          navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search', params: {refreshUserInfo: uuidv4()}}}});
        } else {
          navigate('Drawer', {screen: 'Home', params: {screen: 'Signin/Signup'}});
        }
      } catch (err) {
        try {
          const response = await MeSearchApi.post('/api/signup', { username: fingerPrint, password: fingerPrint, guest: true, referrer: referrer });
          await AsyncStorage.setItem('token', response.data.token);
          dispatch({ type: 'signin', payload: response.data.token });
          if (!navToLogin) {
            navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search', params: {refreshUserInfo: uuidv4()}}}});
          } else {
            navigate('Drawer', {screen: 'Home', params: {screen: 'Signin/Signup'}});
          }
        } catch (err) {
          dispatch({
            type: 'add_error',
            payload: `${err.response ? err.response.data.error : 'Something went wrong'}`
          });
        }
      }
    } catch (err) {
      dispatch({type: 'add_error', payload: 'Something went wrong'});
    }
  };
};

const tryFingerPrintSignin = (dispatch) => {
  return async ({ fingerPrint, referrer }) => {
    try {
      const response = await MeSearchApi.post('/api/signin', { username: fingerPrint, password: fingerPrint, referrer: referrer });
      await AsyncStorage.setItem('token', response.data.token);
      dispatch({ type: 'signin', payload: response.data.token });
      navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search', params: {refreshUserInfo: uuidv4()}}}});
    } catch (err) {
      try {
        const response = await MeSearchApi.post('/api/signup', { username: fingerPrint, password: fingerPrint, guest: true, referrer: referrer });
        await AsyncStorage.setItem('token', response.data.token);
        dispatch({ type: 'signin', payload: response.data.token });
        navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search', params: {refreshUserInfo: uuidv4()}}}});
      } catch (err) {
        dispatch({
          type: 'add_error',
          payload: `${err.response ? err.response.data.error : 'Something went wrong'}`
        });
      }
    }
  };
};

const kSignin = (dispatch) => {
  return async ({ username, password }) => {
    try {
      const usernameTrimmed = String(username).trim();
      const passwordTrimmed = String(password).trim();
      const response = await MeSearchAPIKiosk.post('/api/kiosk/signin', { username: usernameTrimmed, password: passwordTrimmed });
      await AsyncStorage.setItem('ktoken', response.data.token);
      dispatch({ type: 'ksignin', payload: response.data.token });
      //navigate('Drawer', {screen: 'Account', params: {screen: 'AccountHome'}});
      navigate('Drawer', {screen: 'Home', params: {screen: 'Home'}});
      //navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search', params: {refreshUserInfo: uuidv4()}}}});
      //navigate('kioskFlow');
    } catch (err) {
        dispatch({
          type: 'add_kerror',
          payload: `${err.response ? err.response.data.error : 'Something went wrong with sign in'}`
        });
    }
  };
};


const tryKSignin = (dispatch) => {
  return async () => {
    try {
      // Fetch current token
      const ktoken = await AsyncStorage.getItem('ktoken');
      if (ktoken) {
        // TODO: 
        // Need to hit an API endpoint to detemrine if the current token is still valid or not
        let response = await MeSearchAPIKiosk.post('/api/kiosk/validate-token');
        if (response.data && response.data.valid) {
          dispatch({ type: 'ksignin', payload: ktoken });
          //navigate('Drawer', {screen: 'Account', params: {screen: 'AccountHome'}});
          navigate('Drawer', {screen: 'Home', params: {screen: 'Home'}});
          //navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search', params: {refreshUserInfo: uuidv4()}}}});
          //navigate('kioskFlow');
        } else {
          await AsyncStorage.removeItem('ktoken');
          dispatch({ type: 'ksignout' });
          navigate('kioskLoginFlow');
        }
      } else {
        navigate('kioskLoginFlow');
      }
    } catch (err) {
      dispatch({
        type: 'add_kerror',
        payload: `${'Something went wrong'}`
      });
    }
  };
};

const kSignout = (dispatch) => {
  return async () => { 
    const ktoken = await AsyncStorage.getItem('ktoken');
    if (ktoken) {
      await AsyncStorage.removeItem('ktoken');
    }
    dispatch({ type: 'ksignout' });
    navigate('kioskLoginFlow');
  }
}

const clearKErrorMessage = dispatch => () => {
  dispatch({ type: 'clear_kerror_message' });
};

export const { Provider, Context } = createDataContext(
  authReducer,
  { signin, signout, signup, clearErrorMessage, tryLocalSignin, 
    tryFingerPrintSignin, termsOfService, resetPassword, forgotPassword, kSignin, tryKSignin, clearKErrorMessage, kSignout, confirmEmail,
    signoutAndSigninToGuest, sendEmailConfirmation, changeEmailConfirm
  },
  { token: null, errorMessage: '', username: '', password: '', guest: false, ktoken: null, kUsername: '', kPassword: '', kerrorMessage: '', isConfirmed: false }
);