import MeSearchAPI from '../../api/MeSearch-API';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';

function updateUserContentTags(userContentTags, contentTags, percRead, PubCon) {
  contentTags.forEach((contentTag) => {
    if (contentTag !== "Headline" && (!PubCon.state.publisher.blacklistTags || !PubCon.state.publisher.blacklistTags.includes(contentTag)) && contentTag !== "General") {
      let tagExists = false;
      userContentTags.forEach((userContentTag, usrIndex) => {
        if (contentTag === userContentTag.conTag) {
          tagExists = true;
          userContentTags[usrIndex] = { conTag: userContentTag.conTag, value: userContentTag.value + 1 /*(percRead > 0 ? percRead : 1)*/ };
        }
      });
      if (!tagExists) {
        userContentTags.push({ conTag: contentTag, value: 1 /*(percRead > 0 ? percRead : 1)*/ });
      }
    }
  });
  return userContentTags;
};

function updateUserHistoryContentClicks(userContentClicks, contentClick) {
  userContentClicks.push(contentClick);
  return userContentClicks;
};

function updateUserDecay(userDecay, conTags, PubCon) {
  const timeStamp = new Date().getTime();
  conTags.forEach((conTag) => {
    if (conTag !== "Headline" && (!PubCon.state.publisher.blacklistTags || !PubCon.state.publisher.blacklistTags.includes(conTag)) && conTag !== "General") {
      let tagExists = false;
      userDecay.forEach((decayConTag, usrDecayIndex) => {
        if (conTag === decayConTag.conTag) {
          tagExists = true;
          userDecay[usrDecayIndex] = { conTag: decayConTag.conTag, timestamp: timeStamp };
        }
      });
      if (!tagExists) {
        userDecay.push({ conTag: conTag, timestamp: timeStamp  });
      }
    }
  });
  return userDecay;
};

async function updateUserHistory(contentTags,
                                 currentLocation,
                                 homeLocation,
                                 profileTags, 
                                 pubs,
                                 contentClicks, 
                                 searches, 
                                 locations, 
                                 accounts, 
                                 contentShareClicks,
                                 contentShares,
                                 impressions,
                                 decay) {
    await MeSearchAPI.patch(
        '/api/user', 
        { payload: { 
            params: {
                contentTags: contentTags,
                currentLocation: currentLocation,
                homeLocation: homeLocation,
                profileTags: profileTags,
                pubs: pubs
            },
            history: {
                contentClicks: contentClicks,
                searches: searches,
                locations: locations,
                accounts: accounts,
                contentShareClicks: contentShareClicks,
                contentShares: contentShares,
                impressions: impressions
            },
            decay: decay
        } 
    });
};

const updateUsr = async (content, PubCon) => {
    const response = await MeSearchAPI.get('/api/user');
    const timeStamp = new Date().getTime();
    // Update user content tags
    let userContentTags = response.data.params.contentTags;
    let contentTags = content.meTags;
    // update user details if this article was reached from social sharing (deprecated)
    let clickedFromSocial = response.data.history.contentShareClicks;
    let device = "";
    if ((Platform.OS === 'web' && !isMobile)) {
      if (isTablet) {
        device = "Tablet";
      } else {
        device = "Desktop";
      }
    } else {
      device = "Mobile";
    }
    const updatedUserContentTags = updateUserContentTags(userContentTags, contentTags, 0, PubCon);
    const contentClickObj = { body_id: content.body_id, timestamp: timeStamp, pub: PubCon.state.publisher.username, device: device };
    const updatedUserContentClicks = updateUserHistoryContentClicks(response.data.history.contentClicks, contentClickObj);
    let usrDecay = response.data.decay ? response.data.decay : [];
    const updatedUserDecay = updateUserDecay(usrDecay, contentTags, PubCon);
    await updateUserHistory(updatedUserContentTags,
      response.data.params.currentLocation,
      response.data.params.homeLocation,
      response.data.params.profileTags,
      response.data.params.pubs,
      updatedUserContentClicks,
      response.data.history.searches,
      response.data.history.locations,
      response.data.history.accounts,
      clickedFromSocial,
      response.data.history.contentShares,
      response.data.history.impressions,
      updatedUserDecay
    );
};

export { updateUserContentTags, updateUserHistoryContentClicks, updateUserDecay, updateUserHistory, updateUsr };



