import React, {useState} from 'react';
import { ScrollView } from 'react-native';
import FormWrapper from '../components/wrappers/MeSearch/FormWrapper';
import UserSignupWrapper from '../components/wrappers/MeSearch/UserSignupWrapper';
import FormFieldTypesEnum from './utils/FormValidation/formFieldTypesEnum';
import SubmitField from './utils/FormValidation/FormValidationSubmitField';
import ValidationTypesEnum from './utils/FormValidation/formValidateTypesEnum';
import MeSearchAPI from '../api/MeSearch-API';

const UserSignupScreen = ({ navigation, route }) => {
  // since this is a multi-step form, include all form fields in config below.
  const userSignupForm = {
    emailAddress: { name: "Email Address", value: "", error: "", required: true, placeholder: "name@example.com", type: FormFieldTypesEnum.VERIFIED, step: 1},
    displayName: { name: "Display Name", value: "", error: "", required: true, placeholder: "3 - 20 character username", type: FormFieldTypesEnum.TEXTINPUT, step: 1},
    password: { name: "Password", value: "", error: "", required: true, placeholder: "Enter a password", type: FormFieldTypesEnum.PASSWORD, step: 2 },
    confirmPassword: { name: "Confirm Password", value: "", error: "", required: true, placeholder: "Confirm your password", type: FormFieldTypesEnum.PASSWORD, step: 2 },
    // mobileNumber: { name: "Mobile Number", value: "", error: "", required: true, placeholder: "Enter your mobile number", type: FormFieldTypesEnum.PHONE, step: 3},
  };
  const userSignupValidation = {
    emailAddress: { validate: SubmitField(ValidationTypesEnum.EMAIL) },
    displayName: { validate: SubmitField(ValidationTypesEnum.ANY) },
    password: { validate: SubmitField(ValidationTypesEnum.ANY) },
    confirmPassword: { validate: SubmitField(ValidationTypesEnum.ANY) },
    // mobileNumber: { validate: SubmitField(ValidationTypesEnum.PHONE) },
  };
  const [step, setStep] = useState(1);

  const methods = {
    existingUserLookup: async email => {
      return await MeSearchAPI.get('/api/user/exists', {params: {username: email}});
    },
  };

  return (
    <ScrollView>
      <FormWrapper 
        Component={UserSignupWrapper}
        values={userSignupForm}
        validation={userSignupValidation}
        navigation={navigation}
        route={route}
        setStep={setStep}
        step={step}
        methods={methods}
      />
    </ScrollView>
  );
};
    
export default UserSignupScreen;