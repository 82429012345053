import TagManager from 'react-gtm-module';
import determineUrl from './determineUrl';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const updateGDataLayer = (catagory, gtmId, pub="") => {
    let search = window.location.search;
    let href = window.location.href;
    const referrer = determineUrl(search, href);
    let date = new Date();
    const tagManagerArgs = {
        gtmId: gtmId, //'GTM-59ZPF2B'
        dataLayer: {
            FullSection: 'home',
            CanonicalUrl: `${referrer}`,
            Headline: 'Home',
            Author: '',
            Catagory: `${catagory}`,
            SubCategory: `${catagory}`,
            Description: '',
            Published: '',
            Published_short: '',
            Updated: '',
            StoryType: '',
            Day_of_week: `${days[date.getDay()]}`,
            Month: `${months[date.getMonth()]}`,
            Day: `${date.getDay()}`,
            Hour: `${date.getHours()}`,
            Year: `${date.getFullYear()}`, 
            Server: '', 
            Domain: `${referrer}`,
            Testing: 'Yes', 
            User: '', 
            Logged: '',
            BodyId: '',
            Publication: `${pub}`
        }
    }
    if (gtmId) {
      TagManager.initialize(tagManagerArgs);
    }
}

export default updateGDataLayer;