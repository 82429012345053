import React, { useContext, useState, useEffect } from 'react';
import  { Text, Button, Input, CheckBox, Icon } from 'react-native-elements';
import {Picker, TextInput, StyleSheet, Image, FlatList, Dimensions, View, Switch, ScrollView, SafeAreaView, ImageBackground, TouchableOpacity } from 'react-native';
import Spacer from '../Space';
import { Feather, MaterialIcons, FontAwesome } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { navigate } from '../../navigationRef';
import { Platform } from 'react-native';

    const profileInfo = ({ navigation, Accountid, setShowSearch, ShowSearch, activeAcct, Portfolioid, Forumid, viewRS, setViewRS
        }) => {
        
            const [isEnabled, setIsEnabled] = useState(false);
            const toggleSwitch = () => setIsEnabled(previousState => !previousState);

    // Render use JSX syntax
    return (

        <View style={{backgroundColor:'#eeeee0', height:Dimensions.get('window').height-55}}>
        
        <View style={{ justifyContent: 'center', alignItems: 'center', paddingBottom:20}}>
            <Spacer/>
            <View style={{borderColor:'black', borderRadius:50, borderWidth:3, width:70, height:70, justifyContent: 'center', alignItems: 'center'}}>
            <FontAwesome name="user-plus" size={30} color="black"  />
            </View>
        </View>
        <View style={{ justifyContent: 'center', alignItems: 'center',}}>
          <View>
            <Text style={{color:'black', paddingLeft:10}}>Name:</Text>
                <Input 
                label=""
                labelStyle={{color:'black',fontSize:20, marginBottom:0}}
                value="Arthur Crivella"
                placeholder="Name"
                autoCapitalize="none"
                containerStyle={{ width:300, height:50}}
                inputStyle={{color:'black'}}
                inputContainerStyle={{color:'black'}}
                //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                autoCorrect={false}
                maxLength={600}
                rightIcon={
                    <Icon
                      name='edit'
                      type='MaterialIcons'
                      size={24}
                      color='white'
                    />
                  }
                />
                <Text style={{color:'black', paddingLeft:10}}>Username:</Text>
                <Input 
                label=""
                labelStyle={{color:'black',fontSize:20, marginBottom:0}}
                value="K-Andre"
                placeholder="Title"
                autoCapitalize="none"
                containerStyle={{ width:300, height:50}}
                inputStyle={{color:'black'}}
                inputContainerStyle={{color:'black'}}
                //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                autoCorrect={false}
                maxLength={600}
                rightIcon={
                    <Icon
                      name='edit'
                      type='MaterialIcons'
                      size={24}
                      color='black'
                    />
                  }
                />
                <Text style={{color:'black', paddingLeft:10}}>Publisher:</Text>
                <Input 
                label=""
                labelStyle={{color:'black',fontSize:20, marginBottom:0}}
                value="Mesearch"
                placeholder="PubDate"
                autoCapitalize="none"
                containerStyle={{ width:300, height:50}}
                inputStyle={{color:'black'}}
                inputContainerStyle={{color:'black'}}
                //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                autoCorrect={false}
                maxLength={600}
                rightIcon={
                    <Icon
                      name='edit'
                      type='MaterialIcons'
                      size={24}
                      color='black'
                    />
                  }
                />
                <View style={{flexDirection:'column'}}>
                <Text style={{color:'black', paddingLeft:10}}>Bios:</Text>
                <Input 
                label=""
                labelStyle={{color:'black',fontSize:20, marginBottom:0}}
                value=""
                placeholder="Click to add"
                autoCapitalize="none"
                containerStyle={{ width:300, height:50}}
                inputStyle={{color:'black'}}
                inputContainerStyle={{color:'black'}}
                //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                autoCorrect={false}
                maxLength={600}
                rightIcon={
                    <Icon
                      name='edit'
                      type='MaterialIcons'
                      size={24}
                      color='black'
                    />
                  }
                />
        <Spacer/>
          <View>
            <View style={{justifyContent: 'center', alignItems: 'center', paddingBottom:10}}>
              <Text style={{fontWeight:'bold', fontSize:20}}>Settings and Privacy</Text>
            </View>
            
            <View style={{flexDirection:'row', justifyContent:'space-around'}}>
              <View style={{flexDirection:'row', alignItems:'center'}}>
                <FontAwesome name="user" size={30} color="black"  />
                <Text style={{paddingLeft:10}}>Manage account</Text>
              </View>
              <Feather name="arrow-right" size={30} color="black"  />
            </View>
            <View style={{flexDirection:'row', justifyContent:'space-around'}}>
              <View style={{flexDirection:'row', alignItems:'center'}}>
                <FontAwesome name="lock" size={30} color="black"  />
                <Text style={{paddingLeft:10}}>Privacy</Text>
              </View>
              <Feather name="arrow-right" size={30} color="black"  />
            </View>
          </View>
        </View>
                </View>
            </View>
        </View>


    )
  };

  // Styles
const styles = StyleSheet.create({

    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    containerSmall: {
      flex: 1,
      justifyContent: 'center',
      paddingBottom: 50,
    },
    containerLarge: {
      width: Dimensions.get('window').width,
      paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
      paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
      justifyContent: 'center',
      paddingBottom: 150,
      marginTop:20,
    },
   
  });

  export default profileInfo;