import React, { useState, useEffect, useContext } from 'react';
import { View, SafeAreaView, Text, StyleSheet, FlatList, Switch, Dimensions, Linking, TouchableOpacity } from 'react-native';
import { Button } from 'react-native-elements';
import {Context as AuthContext } from '../context/AuthContext';
import { DrawerActions } from '@react-navigation/native';
import MeSearchAPI from '../api/MeSearch-API';
import { fixConTagRunon } from '../screens/utils/fixTagRunon';
import { Platform } from 'react-native';
import { isMobile } from 'react-device-detect';
import { Context as PubContext } from '../context/PubContext';
import { Feather } from '@expo/vector-icons';

//import appJson from '../app.json';
// Check if app is on latest version
//const currentVersion = appJson.expo.version;

const SettingsScreen = ({ navigation }) => {
  const [darkMode, setDarkMode] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isGuest, setIsGuest] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const  PubCon = useContext(PubContext);
  const defaultTitle = [{_id: '123456789', conTag: "Tag", value: 1, isEnabled: true}];
  const [userTags, setUserTags] = useState(defaultTitle);
  const [userTagsRender, setUserTagsRender] = useState(defaultTitle);
  const [addressErr, setAddressErr] = useState(null);
  const { signout, signoutAndSigninToGuest } = useContext(AuthContext);

  const fetchRelevantUserContentTags = async () => {
    const response = await MeSearchAPI.get('/api/user');
    let usrConTags = response.data.params.contentTags;

    // Remove unwanted tags
    if (PubCon.state.publisher.blacklistTags) {
      PubCon.state.publisher.blacklistTags.forEach(bTag => {
        let bIndex = usrConTags.findIndex(u => u.conTag == bTag);
        if (bIndex > -1) {
          usrConTags.splice(bIndex, 1);
        }
      });
    }
    let mandIndex = usrConTags.findIndex(u => u.conTag == PubCon.state.publisher.mandatoryTag);
    if (mandIndex > -1) {
      usrConTags.splice(mandIndex, 1);
    }
    usrConTags = fixConTagRunon(usrConTags);
    const usrConTagsSorted = (usrConTags.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))).reverse();
    setUserTags(usrConTagsSorted);
  };

  const styles = StyleSheet.create({
    titleStyle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'white',
        paddingLeft: 5,
        marginLeft: 10,
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: PubCon.state.publisher.tagBarColor,
        borderRadius: 4,
        overflow: 'hidden',
        width: Dimensions.get('window').width
        //width: 300
    },
    headerStyle: {
      fontSize: 22,
      fontWeight: 'bold',
      color: darkMode ? 'white' : 'black',
      margin: 10
    },
    betterMargins: {
      marginHorizontal: (Platform.OS === 'web' && !isMobile) ? '20%' : '0%',
    }
  });

  useEffect (() => {
    setUserTagsRender(userTags.slice(0, Math.min(userTags.length, 25)));
  }, [userTags]);

  const changeTagEnabled = async (item, enabled) => {
    let usrEnabledTags = [];
    userTags.forEach(conTag => {
        let newConTag = Object();
        newConTag._id = conTag._id;
        newConTag.conTag = conTag.conTag;
        newConTag.value = conTag.value;
        newConTag.isEnabled = conTag.isEnabled;
        if (typeof newConTag.isEnabled == 'undefined') {
            newConTag.isEnabled = true;
        }
        if (conTag.conTag === item.conTag) {
            newConTag.isEnabled = enabled;
        }
        usrEnabledTags.push(newConTag);
    });
    const response = await MeSearchAPI.get('/api/user');
    await MeSearchAPI.patch(
        '/api/user', 
        { payload: { 
            params: {
              contentTags: usrEnabledTags,
              pubs: response.data.params.pubs
            }
          } 
      });
      setUserTags(usrEnabledTags);
  };

  useEffect (() => {
    const unsubscribe = navigation.addListener('focus', () => {
      fetchRelevantUserContentTags();
      const responseProm = MeSearchAPI.get('/api/user');
      responseProm.then(response => {
        let curPub = PubCon.state.publisher.username;
        setDarkMode(response.data.darkMode)
        setIsGuest(response.data.guest);
        if (response.data.guest && !response.data.termsAccepted) {
          setTermsAccepted(false);
        } else {
          setTermsAccepted(true);
        }
        if (response.data.accessLvl && response.data.accessLvl.length > 0) {
          response.data.accessLvl.forEach(accessLvlObj => {
            if (accessLvlObj.account === curPub && accessLvlObj.perm >= 4) {
              setIsAdmin(true);
            }
          });
        }
      });
    });
    return unsubscribe;
  }, []);

  return (
    <SafeAreaView
      style={{backgroundColor: darkMode ? '#3c3c3d' : 'white',
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
        paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
        paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
      }}
    >
      <TouchableOpacity style={{marginTop: 18, paddingLeft: 10, paddingRight: 0}} onPress={() => navigation.dispatch(DrawerActions.openDrawer())}>
        <Feather name="menu" style={{
          fontSize: 35,
          color: darkMode ? 'white' : 'black'
        }}/>
      </TouchableOpacity>
      <View style={styles.betterMargins}>
        <View>
          <Text style={styles.headerStyle}>User Settings:</Text>
          <FlatList
            style={{height: 300}}
            data={userTagsRender}
            extraData={refresh}
            keyExtractor={(userTag) => userTag._id}
            renderItem={({ item }) => {
                return (
                <View>
                  <View style={{flexDirection: 'row', paddingLeft: 10, paddingTop: 10, backgroundColor: darkMode ? '#3c3c3d' : 'white'}}>
                      <Switch value={(typeof item.isEnabled !== 'undefined' && !item.isEnabled) ? false : true} trackColor={{true: '#0682d4', false: '#838584'}} style={{marginTop: 10}} onValueChange={(value) => {
                        if (!value) {
                          changeTagEnabled(item, false);
                        } else {
                          changeTagEnabled(item, true);
                        }
                      }}/>
                      <Text style={styles.titleStyle}>{item.conTag}</Text>
                  </View>
                </View>
                );
            }}
          />
      </View>
      <View>
        <Text style={styles.headerStyle}>Application Settings:</Text>
        <View style={{flexDirection: 'row', backgroundColor: darkMode ? '#3c3c3d' : 'white', paddingTop: 10, paddingLeft: 10}}>
          <Switch value={darkMode} trackColor={{true: '#0682d4', false: '#838584'}} style={{paddingLeft: 5}} onValueChange={(value) => {
            if (!value) {
              setDarkMode(false);
              setRefresh(false);
              MeSearchAPI.patch(
                '/api/user', 
                { payload: { 
                    darkMode: false
                  } 
              });
            } else {
              setDarkMode(true);
              setRefresh(true);
              MeSearchAPI.patch(
                '/api/user', 
                { payload: { 
                    darkMode: true
                  } 
              });
            }
          }}/>
          <Text style={{ color: darkMode ? 'white' : 'black', fontSize: 18, fontWeight: 'bold', paddingLeft: 15}}>Dark Mode</Text>
        </View>
      </View>
      {/*isAdmin ?
      <View style={{paddingTop: 15, paddingBottom: 20}}>
          <Text style={styles.headerStyle}>Admin Settings:</Text>
          <Text 
            style={{fontSize: 14, color: darkMode ? 'white' : 'blue', paddingLeft: 10, width: 260 }} 
            onPress={ () =>  navigation.navigate('Publisher', { screen: 'PubHome', params: {pub: PubCon.state.publisher.username} })}>
            Publication Tools
          </Text>
      </View>
      : null*/}
      <View style={{flexDirection: 'row', backgroundColor: darkMode ? '#3c3c3d' : 'white', paddingBottom: 15}}>
        <Text style={{fontSize: 14, marginLeft: 10, paddingTop: 20, color: darkMode ? 'white' : 'black'}}>v2.40.310</Text>
      </View>
      <View style={{flexDirection: 'row', paddingLeft: 10, paddingBottom: 20}}>
        <Text 
          style={{fontSize: 14, color: darkMode ? 'white' : 'blue' }} 
          onPress={ () => Platform.OS === 'web' ? window.open('https://mesearch.us/wp-content/uploads/2020/02/2020-01-01_Crivella-Media-Tech_Collection-and-Use-of-Personal-Information_Policy-only.pdf') : Linking.openURL('https://mesearch.us/wp-content/uploads/2020/02/2020-01-01_Crivella-Media-Tech_Collection-and-Use-of-Personal-Information_Policy-only.pdf') }>
          Privacy Policy
        </Text>
        <Text> | </Text>
        <Text 
          style={{fontSize: 14, color: darkMode ? 'white' : 'blue' }} 
          onPress={ () => Platform.OS === 'web' ? window.open('https://mesearch.us/wp-content/uploads/2020/02/Terms-of-Services.pdf') : Linking.openURL('https://mesearch.us/wp-content/uploads/2020/02/Terms-of-Services.pdf') }>
          Terms of Service and Use
        </Text>
      </View>
      {/*<Button style={{marginTop: 10, paddingLeft: 10, paddingRight: 10}}
        title={ isGuest ? "Sign Up" : "Sign Out" }
        onPress={isGuest ? () => signout({clrToken: false, termsAccepted}) : () => signoutAndSigninToGuest()}
      />*/}
    </View>
  </SafeAreaView>
  );
};

export default SettingsScreen;