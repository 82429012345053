import { Platform } from 'react-native';
// TODO:
// Finish this function and call it in ResolveAuthScreen and ResultsShowScreen
// Use the return url to fill out the needed data layer fields pertaining to url in
// ResultsShowScreen

const determineUrl = (search, href) => {
  if (Platform.OS === 'web') {
    let referrer = href;
    let params = new URLSearchParams(search);
    const pub = params.get('p');
    const paramKeys = Object(params).keys();
    let paramCount = 0;
    for (let pKey of paramKeys) {
      paramCount += 1;
    }
    if (paramCount > 1) {
      if (pub) {
        referrer = href.toLowerCase().split('&')[0].replace('content-page/', '').replace('publisher/', '').replace('publisher', '').replace(/bid(.*)/, '');
      } else {
        referrer = href.toLowerCase().split('?')[0].replace('content-page/', '').replace('publisher/', '').replace('publisher', '').replace(/bid(.*)/, '');
      }
    } else if (paramCount === 1) {
      if (pub) {
        referrer = href.toLowerCase().replace('content-page/', '').replace('publisher/', '').replace('publisher', '').replace(/bid(.*)/, '');
      } else {
        referrer = href.toLowerCase().split('?')[0].replace('content-page/', '').replace('publisher/', '').replace('publisher', '').replace(/bid(.*)/, '');
      }
    } else {
      referrer = href.toLowerCase().replace('content-page/', '').replace('publisher/', '').replace('publisher', '').replace(/bid(.*)/, '');
    }
    const refPrts = referrer.split('/').filter(s => s !== "");
    referrer = refPrts.length >= 3 ? `${refPrts[0]}//${refPrts[1]}/${refPrts[2]}/` : referrer;
    return referrer;
  } else {
    return "http://localhost:19006/mesearch";
  }
};

export default determineUrl;