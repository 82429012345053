const checkSystemCapability = (user, account, capability) => {
  if (user.systemCapabilities) {
    const systemCapabilityDenied = user.systemCapabilities.some(c => {
      if (c.key === capability && c.value === false && c.account === account) {
        return true;
      }
      return false;
    });
    return !systemCapabilityDenied;
  } else {
    return true;
  }
};

export default checkSystemCapability;