const valErrorExist = (fieldsMeta, step) => {
  return Object.keys(fieldsMeta).some(fieldsMetaKey => {
    if (step) {
      return (fieldsMeta[`${fieldsMetaKey}`].error !== "" && fieldsMeta[`${fieldsMetaKey}`].step === step);
    } else {
      return fieldsMeta[`${fieldsMetaKey}`].error !== "";
    }
  });
}

export default valErrorExist;