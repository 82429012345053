import React, { useContext, useState } from "react";
import { Context as PubContext } from '../../context/PubContext';
import { menuDataMain } from './menuData';
import DrawerContent from './DrawerContent';


const DrawerMenu = ({navigation}) => {

  const [menuData, setMenuData] = useState(menuDataMain);
  const [menuType, setMenuType] = useState('Main');
  const { state } = useContext(PubContext);

  return (
      <DrawerContent
        navigation={navigation}
        tagBarColor={state.publisher.tagBarColor}
        pubMenuMain={state.publisher.menuMain}
        pubMenuCategory={state.publisher.menuCategories}
        isPubCatagories={(state.publisher.menuCategories && state.publisher.menuCategories.length > 0)}
        menuData={menuData}
        menuType={menuType}
        setMenuData={setMenuData}
        setMenuType={setMenuType}
      />
  );

};

export default DrawerMenu;
