import React, { useContext, useState, useEffect } from 'react';
import  { Text, Button, Input, CheckBox, Icon } from 'react-native-elements';
import {Picker, TextInput, StyleSheet, Image, FlatList, Dimensions, View, Switch, ScrollView, SafeAreaView, ImageBackground, TouchableOpacity } from 'react-native';
import Spacer from '../Space';
import { Feather, MaterialIcons, FontAwesome } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { navigate } from '../../navigationRef';
import { Platform } from 'react-native';

const tileDetails = ({ navigation, Accountid, setShowSearch, ShowSearch, activeAcct, Portfolioid, Forumid, viewRS, setViewRS
        }) => {
        
            const [isEnabled, setIsEnabled] = useState(false);
            const toggleSwitch = () => setIsEnabled(previousState => !previousState);

    // Render use JSX syntax
    return (
        <View style={{height:Dimensions.get('window').height-100}}>
        <ScrollView>
            <Spacer/>
            <View style={{ justifyContent: 'center', alignItems: 'center',}}>
          <View>
                <Input 
                label="Title:"
                labelStyle={{color:'white',fontSize:20, marginBottom:0}}
                value="Family photo"
                placeholder="Title"
                autoCapitalize="none"
                containerStyle={{ width:300}}
                inputStyle={{color:'white'}}
                inputContainerStyle={{color:'white'}}
                //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                autoCorrect={false}
                maxLength={600}
                rightIcon={
                    <Icon
                      name='edit'
                      type='MaterialIcons'
                      size={24}
                      color='white'
                    />
                  }
                />
                                <Input 
                label="Author:"
                labelStyle={{color:'white',fontSize:20, marginBottom:0}}
                value="K-Andre"
                placeholder="Title"
                autoCapitalize="none"
                containerStyle={{ width:300}}
                inputStyle={{color:'white'}}
                inputContainerStyle={{color:'white'}}
                //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                autoCorrect={false}
                maxLength={600}
                rightIcon={
                    <Icon
                      name='edit'
                      type='MaterialIcons'
                      size={24}
                      color='white'
                    />
                  }
                />
                <Input 
                label="Publication Date:"
                labelStyle={{color:'white',fontSize:20, marginBottom:0}}
                value="11/19/2020"
                placeholder="PubDate"
                autoCapitalize="none"
                containerStyle={{ width:300}}
                inputStyle={{color:'white'}}
                inputContainerStyle={{color:'white'}}
                //onChangeText={(text) => setAssetTopHeaderWebDarkMode(text.trim())}
                autoCorrect={false}
                maxLength={600}
                rightIcon={
                    <Icon
                      name='edit'
                      type='MaterialIcons'
                      size={24}
                      color='white'
                    />
                  }
                />
            </View>
            <View style={{flexDirection:'row'}}>
                <CheckBox
                    containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                    checkedColor='white' size={20} textStyle={{color: 'white'}} title='PII'
                    onPress={() => {
                        alert('Yes');
                    }}
                    checked={true}
                />
                <CheckBox
                    containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                    checkedColor='white' size={20} textStyle={{color: 'white'}} title='PHI'
                    onPress={() => {
                        alert('Yes');
                    }}
                    checked={true}
                />
                <CheckBox
                    containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                    checkedColor='white' size={20} textStyle={{color: 'white'}} title='Manditory'
                    onPress={() => {
                        alert('Yes');
                    }}
                    checked={true}
                />
            </View>

            {/* <Text style={{color:'white', fontSize:24, fontWeight:'bold'}}>Date Input</Text>
            <View>
                  <Calendar/>
            </View>
            <Spacer/> 
          <Text style={{color:'white', fontSize:24, fontWeight:'bold'}}>Yes/No Question</Text>
          <Text style={{color:'white'}}>Please answer the questions below</Text>
          <Spacer/>
          <View>
            <View style={{flexDirection:'column', justifyContent:'space-between', paddingBottom:10, borderBottomColor: 'silver', borderBottomWidth: 1,}}>
                <Text style={{color:'white'}}>Was the idea interesting</Text>
                <View style={{flexDirection:'row', paddingLeft:10}}>
                    <Text style={{color:'white', paddingRight:5}}>Yes</Text>
                    <Switch
                        trackColor={{ false: "#767577", true: "#81b0ff" }}
                        thumbColor={isEnabled ? "#f5dd4b" : "#f4f3f4"}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={!isEnabled}
                        value={isEnabled}
                    />
                    <Text style={{color:'white', paddingRight:10}}>No</Text>
                    <MaterialIcons name="edit" size={24} color="white" />
                </View>
            </View>
            <View style={{flexDirection:'column', justifyContent:'space-between', paddingBottom:10, borderBottomColor: 'silver', borderBottomWidth: 1,}}>
                <Text style={{color:'white'}}>Where the keys under the books?</Text>
                <View style={{flexDirection:'row',}}>
                <CheckBox
                containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                checkedColor='white'
                size={20}
                textStyle={{color: 'white'}}
                title='Yes'
                onPress={() => {
                    alert('Yes');
                }}
                checked={true}
                />
                <CheckBox
                containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                checkedColor='white'
                size={20}
                textStyle={{color: 'white'}}
                title='No'
                onPress={() => {
                    alert('No');
                }}
                checked={false}
                />
                <MaterialIcons name="edit" size={24} color="white" />
                </View>
            </View>
            <View style={{flexDirection:'column', justifyContent:'space-between', paddingBottom:10, borderBottomColor: 'silver', borderBottomWidth: 1,}}>
                <Text style={{color:'white'}}>Are shoes worn with socks?</Text>
                <View style={{flexDirection:'row',}}>
                <CheckBox
                containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                checkedColor='white'
                size={20}
                textStyle={{color: 'white'}}
                title='Yes'
                onPress={() => {
                    alert('Yes');
                }}
                checked={true}
                />
                <CheckBox
                containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                checkedColor='white'
                size={20}
                textStyle={{color: 'white'}}
                title='No'
                onPress={() => {
                    alert('No');
                }}
                checked={false}
                />
                <MaterialIcons name="edit" size={24} color="white" />
                </View>
            </View>
            <View style={{flexDirection:'column', justifyContent:'space-between', paddingBottom:10, borderBottomColor: 'silver', borderBottomWidth: 1,}}>
                <Text style={{color:'white'}}>Are dogs and cats animals that live in the jungle?</Text>
                <View style={{flexDirection:'row',}}>
                <CheckBox
                containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                checkedColor='white'
                size={20}
                textStyle={{color: 'white'}}
                title='Yes'
                onPress={() => {
                    alert('Yes');
                }}
                checked={true}
                />
                <CheckBox
                containerStyle={{backgroundColor:'#ffffff00', borderColor:'#ffffff00', padding:0, margin:0}}
                checkedColor='white'
                size={20}
                textStyle={{color: 'white'}}
                title='No'
                onPress={() => {
                    alert('No');
                }}
                checked={false}
                />
                <MaterialIcons name="edit" size={24} color="white" />
                </View>
            </View>
          </View>
         */}
        </View>
        </ScrollView>
        </View>
    )
  };

  // Styles
const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    containerSmall: {
      flex: 1,
      justifyContent: 'center',
      paddingBottom: 50,
    },
    containerLarge: {
      width: Dimensions.get('window').width,
      paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
      paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
      justifyContent: 'center',
      paddingBottom: 150,
      marginTop:20,
    },
   
  });

export default tileDetails;