import React, { useContext, useState, useEffect } from 'react';
import  { Button, Input } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, TextInput, FlatList, ScrollView, SafeAreaView, ImageBackground, TouchableOpacity, ActivityIndicator, Linking, Text } from 'react-native';
import Spacer from '../components/Space';
import { Context as AuthContext } from '../context/AuthContext';
import MeSearchAPI from '../api/MeSearch-API';
import truBadge from '../../assets/trusted.png' ;
import comBadge from '../../assets/community.png' ;
import { Platform } from 'react-native';
import isVideo from '../screens/utils/isVideo';
import { WebView } from 'react-native-webview';
import UploadForm from '../components/uploadForm';
import ContentTypeEnum from '../components/utils/contentTypes';
import useManagedContent from '../hooks/useManagedContent';
import uuidv4 from 'uuid/v4';
import { Context as PubContext } from '../context/PubContext';
import bodyLinkParser from '../components/utils/bodyLinkParser';

//expo install expo-file-system
//expo install expo-document-picker

const UploadContentScreen = ({ navigation, route }) => {
    const darkMode = route.params?.darkMode ?? false;
    const isGuest = route.params?.isGuest ?? true;
    const claimed = route.params?.claimed ?? false;
    const businessName = route.params?.businessName ?? ' ';
    const uploadType = route.params?.uploadType ?? 'Article';
    const curContent = route.params.curContent ?? null;
    const displayForm = route.params.displayForm ?? false;
    const pubConfig = route.params.pubConfig ?? {};
    const [myHeadline, setMyHeadline] = useState('');
    const [myByline, setMyByline] = useState('');
    const [myContactInfo, setMyContactInfo] = useState('');
    const [myStory, setMyStory] = useState('');
    const [body_ID, setBody_ID] = useState(uuidv4());
    const [viewStory, setViewStory] = useState(false);
    const [init, setInit] = useState(true);
    const [viewModal, setViewModal] = useState(false);    
    const [myImage, setMyImage] = useState(null);
    const [myImages, setMyImages] = useState([]);
    const [myPhotoInfo, setMyPhotoInfo] = useState({});
    const [myPhotoDesc, setMyPhotoDesc] = useState([]);
    const [myPhotoCredit, setMyPhotoCredit] = useState([]);
    const [myDisclaimer, setMyDisclaimer] = useState([]);
    const [cardHeadline, setCardHeadline] = useState('Headline'); 
    const [cardAuthor, setCardAuthor] = useState('By-line (Author)');
    const [contentType, setContentType] = useState(ContentTypeEnum.ARTICLE);
    const [content, adContent, loading, adLoading, error, postMediaApi, setError, getContentApi] = useManagedContent();
    const [rend, setRend] = useState("");
    const [productPrice, setProductPrice] = useState(0.00);
    const {state} = useContext(PubContext);
    const authCon = useContext(AuthContext);
    const [canEdit, setCanEdit] = useState(false);
    const [contentObj, setContentObj] = useState({});
    const [pubStatus, setPubStatus] = useState(true);
    const [optPubTags, setOptPubTags] = useState({});
    const [optPubTagsArr, setOptPubTagsArr] = useState(state.publisher.ugcOptPubTags);
    const [chkBoxes, setChkBoxes] = useState({News: true, Editorial: false, Opinion: false});
    const [cbLayouts, setCbLayouts] = useState({Card: true, Full: false, Pencil: false});
    const [ugcSource, setUgcSource] = useState(state.publisher.ugcSource);
    const [ugcPubTags, setUgcPubTags] = useState(state.publisher.ugcPubTags);
    const [publisherUsername, setPublisherUsername] = useState(state.publisher.username);
    const [tagBarColor, setTagBarColor] = useState(state.publisher.tagBarColor);
    const [badgeSourceMap, setBadgeSourceMap] = useState(state.publisher.badgeSourceMap);
    const [curRef, setCurRef] = useState(state.ref);
    const [nEmail, setNEmail] = useState(state.publisher.nEmails);
    const [defaultImage, setDefaultImage] = useState(state.publisher.defaultImage);

    const styles = StyleSheet.create({
        input:{
            backgroundColor:'blue',
            borderColor:'silver',
            borderWidth:2,
            alignItems:'center',
            padding:10,
            width:'95%',
            height:120,
            marginTop:5,
            marginLeft:5
        },

        blank: {
            width: 250,
            height: 250,
            backgroundColor:'#e5e9ed',
            justifyContent:'center', 
            alignItems:'center',
            marginBottom: 20,
            elevation:5
          },

        isflexRowView:{flexDirection:'row', width:'100%', justifyContent:'space-between', padding:20},
        isflexWrapView:{ width:'100%', justifyContent:'space-between', padding:20},
        isflexRowItem:{flex:1, width:'50%', justifyContent:'center', alignItems:'center'},
        isflexWrapItem:{flex:1, justifyContent:'center', alignItems:'center'},
    
        dropZone: {
            height: 50,
            backgroundColor:'white',
            justifyContent:'center', 
            alignItems:'center',
            marginBottom: 20,
            elevation:5
          },

        badgeImage: {
            width: 75,
            height: 20,
            marginTop: 2,
            marginLeft: 5,
            paddingLeft: 5,
            backgroundColor: 'black'
          },

        textArea: {
            height: 340,
            minWidth:320,
            width: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width)/2): 320,
            justifyContent: "flex-start",
            borderColor: 'silver', borderStyle:'dashed', borderWidth: 2
          },

        titleStyle: {
            fontSize: 24,
            fontWeight: 'bold',
            paddingLeft: 5,
            paddingTop:5,
            paddingBottom: 10,
            overflow: 'hidden'
          },
          addArticleStyle: {
            fontSize: 14,
            fontWeight: 'bold',
            color: 'white',
            padding: 5,
            backgroundColor: '#10335F',
            borderRadius: 5,
            overflow: 'hidden'
          },
        containerSmall: {
          flex: 1,
          justifyContent: 'center',
          paddingBottom: 150,
          backgroundColor: darkMode ? '#3c3c3d' : 'white'
        },

        mobileSmall: {
            flex: 1,
            justifyContent: 'center',
            marginBottom: 150,
            paddingLeft:'5%',
            paddingRight:'5%',
          },

        containerLarge: {
          width: Dimensions.get('window').width,
          backgroundColor: darkMode ? '#3c3c3d' : 'white',
          paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
          paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
          justifyContent: 'center',
          paddingBottom: 150,
          opacity: viewModal ? 0.4 : 1.0
        },

        container: {
            marginLeft: 15,
            
            justifyContent:'center', 
            flexDirection:'column',
          },

        image: {
            width: 250,
            height: 180,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            marginBottom: -2,
          },

          name: {
            width: 250,
            height: 42,
            fontWeight: 'bold',
            color: 'white',
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 5,
          },

          textDescStyle: {
            color: 'white',
            fontWeight: '300',
            fontSize: 14,
            paddingBottom: 5,
            paddingLeft: 5,
            paddingRight: 10
          },
    });

    const clrUpload = (nav=true) => {
      setMyHeadline('');
      setCardHeadline('Headline');
      setCardAuthor('By-line (Author)');
      setMyByline('');
      setMyStory('');
      setMyContactInfo('');
      setMyImages([]);
      setMyPhotoInfo({});
      setViewStory(!viewStory);
      setCanEdit(false);
      setContentType('Article');
      let initUgcOptPubTags = {};
      state.publisher.ugcOptPubTags.forEach(optTag => {
        initUgcOptPubTags[optTag] = false;
      });
      setOptPubTags(initUgcOptPubTags);
      setOptPubTagsArr(state.publisher.ugcOptPubTags);
      setUgcPubTags(state.publisher.ugcPubTags);
      setUgcSource(state.publisher.ugcSource);
      setPublisherUsername(state.publisher.username);
      setTagBarColor(state.publisher.tagBarColor);
      setBadgeSourceMap(state.publisher.badgeSourceMap);
      setCurRef(state.ref);
      setNEmail(state.publisher.nEmail);
      setDefaultImage(state.publisher.defaultImage);
      if (nav) {
        navigation.navigate('AccountHome');
      }
  }

  const preSetPubConfig = (pubConfiguration) => {
    if (uploadType === "Offer") {
      setOptPubTags({});
      setOptPubTagsArr([]);
      setUgcPubTags(["Offers"]);
    } else {
      let initUgcOptPubTags = {};
      pubConfiguration.ugcOptPubTags.forEach(optTag => {
        initUgcOptPubTags[optTag] = false;
      });
      setOptPubTags(initUgcOptPubTags);
      setOptPubTagsArr(pubConfiguration.ugcOptPubTags);
      setUgcPubTags(pubConfiguration.ugcPubTags);
    }
    setUgcSource(pubConfiguration.ugcSource);
    setPublisherUsername(pubConfiguration.username);
    setTagBarColor(pubConfiguration.tagBarColor);
    setBadgeSourceMap(pubConfiguration.badgeSourceMap);
    setCurRef(pubConfiguration.domains[0]);
    setNEmail(pubConfiguration.nEmail);
    setDefaultImage(pubConfiguration.defaultImage);
  };

    const editContent = (conObj) => {
      setContentObj(conObj);
      setCanEdit(true);
    };

    useEffect (() => {
      setRend(uuidv4());
    }, [content]);

    useEffect(() => {
      if (curContent) {
        editContent(curContent);
        setViewStory(!viewStory);
        setContentType(curContent.type ? curContent.type : ContentTypeEnum.ARTICLE);
      }
    }, [curContent]);

    useEffect(() => {
      if (displayForm) {
        setViewStory(true); 
        setContentType(ContentTypeEnum.ARTICLE);
      }
    }), [displayForm];


    useEffect(() => {
      if (Object.keys(pubConfig).length > 0) {
        preSetPubConfig(pubConfig);
      }
    }, [pubConfig]);

    /*useEffect(() => {
      if (!init) {
        if (!viewStory) {
          if (claimed) {
            getContentApi(null, state.publisher.ugcSource, claimed, uploadType);
          } else {
            getContentApi();
          }
        }
      } else {
        setInit(false);
      }
    }, [viewStory]);*/

    /*useEffect(() => {
      if (claimed) {
        getContentApi(null, state.publisher.ugcSource, claimed, uploadType);
      } else {
        getContentApi();
      }
    }, []);*/

    const updateMyImages = (newImage) => {
        let myNewImages = JSON.parse(JSON.stringify(myImages));
        if (!myNewImages.includes(newImage)){
            myNewImages.push(newImage);
        }
        setMyImages(myNewImages)
    };

    const ifEnabledRun = (callback, ...args) => {   
        return !viewModal ? callback(...args) : null;
    };

    const toggleViewModal = (isEnabled) => {  
        setViewModal(!isEnabled);
    };

    const toggleViewStory = (isEnabled) => {  
        setViewStory(!isEnabled);
    };

    const getContentAccessLvl = (content) => {
      return (content.userGen && content.userGen.accessLvl) ? content.userGen.accessLvl : 1;
    }

    const getAccessLvl = (user, pub) => {
      let accessLvl = 1;
      if (user.accessLvl) {
        user.accessLvl.forEach(accessObj => {
          if (accessObj.account === pub) {
            accessLvl = Math.max(accessObj.perm, accessLvl);
          }
        });
      }
      return accessLvl;
    };

    async function asyncForEach(array, callback) {
      
      for (let index = 0; index < array.length; index++) {
        
        await callback(array[index], index, array);
      }
    }

    const removePub = async () => {
      
      const patchResponse = await MeSearchAPI.patch('/api/content',
        {
          payload: 2,
          body_id: body_ID,
          type: "status",
          referrer: curRef,
          pubOwner: state.publisher.owner
        }
      );

      //setViewStory(!viewStory)
    }

    const publish = async (setPublishing) => {
      setPublishing(true);
      const userRes = await MeSearchAPI.get('/api/user');
      const userObj = userRes.data;
      let mediaUris = myImages.length > 0 ? [] : [defaultImage];
      let myNewImages = [];
      const base64Regex = new RegExp("base64,");

      for (let i = 0; i < myImages.length; i++) {
        if (base64Regex.test(myImages[i])) {
          const myImgArr = [myImages[i]];
          let newUriArr = await postMediaApi(myImgArr);
          if (newUriArr.length > 0) {
            mediaUris.push(newUriArr[0]);
            myNewImages.push(myImages[i]);
          }
        } else {
          mediaUris.push(myImages[i]);
          myNewImages.push(myImages[i]);
        }
      }
      let mediaMeta = [];
      
      if (mediaUris.length === 0) {
        mediaMeta.push({uri: defaultImage, credit: '', caption: '', disclaimerTimestamp: ''});
        mediaUris = [defaultImage];
      } else {
        myNewImages.forEach((img, index) => {
          const desc = myPhotoDesc[index] ?? '';
          const credit = myPhotoCredit[index] ?? '';
          const disclaimerTimestamp = myDisclaimer[index] ?? '';

          mediaMeta.push({uri: mediaUris[index], credit: credit, caption: desc, disclaimerTimestamp });
        });
      }

      const source = ugcSource;
      let ugcTags = Object.keys(optPubTags).filter(optT => optPubTags[`${optT}`]);
      ugcTags.push(...ugcPubTags);
      const accessLvl = getAccessLvl(userObj, publisherUsername);
      const userID = userObj._id;
      const timeDate = new Date();
      const timestamp = timeDate.setDate(timeDate.getDate());
      const crtDate = new Date(timestamp).toISOString();
      const desc = myStory.substring(0, 120);
      let link = body_ID;

      if (curRef.includes('?p')) {
        const urlComps = curRef.split('?');
        let prefix = urlComps[0];
        //let urlSuffix = `content-page/?${urlComps[1]}&body_id=${body_ID}`;
        let urlSuffix = `${urlComps[1].replace('p\=', '')}/${encodeURIComponent(myHeadline !== '' ? myHeadline.toLowerCase(): 'x')}/bid${body_ID}`;
        link = `${prefix}${urlSuffix}`;
      } else {
        link = `${curRef}${encodeURIComponent(myHeadline !== '' ? myHeadline.toLowerCase(): 'x')}/bid${body_ID}`;
      }

      const newlineRegex = new RegExp('\n', 'g');
      const bodyStory = myStory.replace(newlineRegex, "    ");
      const bodyLinks = bodyLinkParser(bodyStory);
      const percentRegex = new RegExp('\%', 'g');
      
      const bodyDat = [{
          link: link,
          description: desc.replace(percentRegex, '%25'),
          bodyContent: bodyStory.replace(percentRegex, '%25'),
          media: mediaUris,
          title: myHeadline.replace(percentRegex, '%25'),
          createDate: crtDate,
          authors:[myByline],
          html: true,
      }];

      const usrGen = {
          accessLvl: accessLvl,
          username: userObj.username,
          created: timestamp,
          lastModified: timestamp, 
          userId: userID
      }

      const payload = [{
          mediaMeta: mediaMeta,
          source_native_id:0,
          type: contentType, //ContentTypeEnum.ARTICLE,
          source: source,
          body: bodyDat,
          bodyLinks: bodyLinks,
          body_id: body_ID,
          tags: ugcTags,
          price: productPrice
      }];

      if (!claimed) {
        payload[0]['userGen'] =  usrGen;
      }

      if (state.publisher.shareTo && state.publisher.shareTo.length > 0) {
        let sharingArr = [];
        state.publisher.shareTo.forEach(shareObj => {
          if (shareObj.tags.some(t => { return ugcTags.includes(t)})) {
            sharingArr.push(shareObj.pub);
          }
        });
        if (sharingArr.length > 0) {
          payload[0]['sharing'] = sharingArr;
        }
      }

      const putResponse = await MeSearchAPI.put('/api/content',
        {
          payload,
          modifyRand: true,
          referrer: curRef,
          pubOwner: state.publisher.owner
        }
      );
      
      const nEmails = nEmail.split(";");

      // Send Email
      const emailRes = await MeSearchAPI.post('/api/analytics/send-alert-admin',
        {
          email: "info@crivellatech.com",
          nEmails: nEmails,
          subject: `User Generated content submitted to ${publisherUsername}`,
          paragraph1: `Please be advised that the below article has been posted as User Generated Content to ${publisherUsername} By ${userObj.username}`,
          paragraph2: `Please review post with the following link. ${link}`, 
          paragraph3: `You can edit or remove this article on the system using the link below:`,
          link: `https://mobile.knowledgekiosk.com/msUploadAdmin.aspx?body_id=${body_ID}&tkn=${authCon.state.token}&pub=${encodeURIComponent(publisherUsername)}&ref=${curRef}`,
          articleTitle: myHeadline
        }
      );
      
      let strEmailMsg = 'RESPONSE: ' + putResponse.data + '     PAYLOAD: ' + JSON.stringify(payload)

      //TODO email results to me with payload submission 
      const emailRes2 = await MeSearchAPI.post('/api/analytics/send-alert',
      {

        email: "wackman@crivellatech.com",
        message: strEmailMsg, 
        subject: `UGC log executed for ${publisherUsername}`
      }
      )
      setPublishing(false);
      clrUpload();
    }

    const isPPNS = (src) => {
      return [
        'PointParkUniversity-UGC',
        'PPUGlobe-UGC',
        'PPUGlobe',
        'Point Park News Service-UGC',
        'Point Park News Service',
        'PointParkNewsService-UGC',
      ].includes(src);
    };

    let isPointPark = isPPNS(ugcSource);

    return (

        <ScrollView style={{paddingLeft:10, paddingRight:10, height: Dimensions.get('window').height - 40, backgroundColor: darkMode ? '#3c3c3d' : 'white'}}>
        <SafeAreaView style={Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall}>
            { viewStory
            ?
                <UploadForm
                    viewStory={viewStory}
                    setViewStory={setViewStory}
                    viewModal={viewModal}
                    setViewModal={setViewModal}
                    myStory={myStory}
                    setMyStory={setMyStory}
                    myByline={myByline}
                    setMyByline={setMyByline}
                    myHeadline={myHeadline}
                    setMyHeadline={setMyHeadline}
                    myContactInfo={myContactInfo}
                    setMyContactInfo={setMyContactInfo}
                    myImage={myImage}
                    setMyImage={setMyImage}
                    myImages={myImages}
                    setMyImages={setMyImages}
                    updateMyImages={updateMyImages}
                    cardHeadline={cardHeadline}
                    setCardHeadline={setCardHeadline}
                    cardAuthor={cardAuthor}
                    setCardAuthor={setCardAuthor}
                    contentType={contentType}
                    setContentType={setContentType}
                    publish={publish}
                    removePub={removePub}
                    pubStatus={pubStatus}
                    setPubStatus={setPubStatus}
                    myPhotoInfo={myPhotoInfo}
                    setMyPhotoInfo={setMyPhotoInfo}
                    myPhotoCredit={myPhotoCredit}
                    setMyPhotoCredit={setMyPhotoCredit}
                    myPhotoDesc={myPhotoDesc}
                    setMyPhotoDesc={setMyPhotoDesc}
                    myDisclaimer={myDisclaimer}
                    setMyDisclaimer={setMyDisclaimer}
                    canEdit={canEdit}
                    setCanEdit={setCanEdit}
                    contentObj={contentObj}
                    optPubTags={optPubTags}
                    setOptPubTags={setOptPubTags}
                    chkBoxes={chkBoxes}
                    setChkBoxes={setChkBoxes}
                    cbLayouts={cbLayouts}
                    setCbLayouts={setCbLayouts}
                    productPrice={productPrice}
                    setProductPrice={setProductPrice}
                    getContentAccessLvl={getContentAccessLvl}
                    body_ID={body_ID}
                    setBody_ID={setBody_ID}
                    darkMode={darkMode}
                    isGuest={isGuest}
                    ugcSource={ugcSource}
                    setUgcSource={setUgcSource}
                    ugcPubTags={ugcPubTags}
                    setUgcPubTags={setUgcPubTags}
                    publisherUsername={publisherUsername}
                    setPublisherUsername={setPublisherUsername}
                    setTagBarColor={setTagBarColor}
                    tagBarColor={tagBarColor}
                    setBadgeSourceMap={setBadgeSourceMap}
                    badgeSourceMap={badgeSourceMap}
                    setCurRef={setCurRef}
                    curRef={curRef}
                    setNEmail={setNEmail}
                    nEmail={nEmail}
                    setDefaultImage={setDefaultImage}
                    defaultImage={defaultImage}
                    setOptPubTagsArr={setOptPubTagsArr}
                    optPubTagsArr={optPubTagsArr}
                    navigation={navigation}
                    claimed={claimed}
                    uploadType={uploadType}
                    businessName={businessName}
                    pubConfig={Object.keys(pubConfig).length > 0 ? pubConfig : state.publisher}
                    isPointPark={isPointPark}
                />
            :
                <>

                <Spacer/>
                {/*<Text style={[styles.titleStyle, {color: darkMode ? 'white' : 'black'}]}>{!claimed ? "Articles" : "Content"}</Text>*/}
                <View style={{flexDirection: 'row', backgroundColor: darkMode ? '#636363' : '#F3F3F3'}}>
                    <View style={{padding: 5, marginTop: 3}}>
                      <Text style={styles.addArticleStyle} onPress={()=>{setViewStory(!viewStory), setContentType(ContentTypeEnum.ARTICLE)}}>{!claimed ? "New article +" : "New " + uploadType.toLowerCase() + " +"}</Text>
                    </View>
                    <View style={{ flexDirection:'row', justifyContent:'center', paddingTop: 10, paddingLeft: 10}}>
                    {
                      state.publisher.owner === 'TribTotalMedia' ? (
                        !claimed ?
                          <TouchableOpacity 
                            style={{flexDirection: 'row'}}
                            onPress={()=> {
                                if (!isGuest) {
                                    if (Platform.OS === 'web') {
                                        let nUrl = window.location.href.includes('?') ? `${window.location.href}&tcr=true` : `${window.location.href}?tcr=true`
                                        window.open(`${nUrl}`)
                                    } else {
                                      Linking.openURL('https://mesearch.ai/?tcr=true')
                                    }
                                }
                            }}
                          >
                            <Text style={{fontSize: 14, fontWeight: 'bold', color: 'black', marginTop: 3}}>Become A  </Text>
                            <Image style={{height: 25, width: 75, marginBottom: 5}} source={require('../../assets/trusted.png')}/>
                          </TouchableOpacity>
                          : null
                      ) : null
                    }
                    </View>
                    <Text style={{flex: 1, backgroundColor: darkMode ? '#636363' : '#F3F3F3', height: 38}}/>
                </View>
                <Spacer/>
                  ${`here`}
                <FlatList
                    data={content}
                    horizontal={true}
                    ListHeaderComponent={
                      () => {
                        return (
                          <View style={{justifyContent: 'center', alignItems: 'center'}}>
                            {loading 
                            ? <ActivityIndicator color={darkMode ? 'white' : Platform.OS ==='web' ? "#005157" : Platform.OS === 'android' ? '#005157' : 'default'} size="large"/>
                            : null}
                          </View>
                        );
                      }
                    }
                    keyExtractor={(item) => item._id }
                    renderItem={(item) => {
                        return (
                            <TouchableOpacity style={styles.container} onPress={()=>{editContent(item.item),  setViewStory(!viewStory), setContentType(ContentTypeEnum.ARTICLE)}}>

                                { isVideo(item.item.body[0].media[0])
                                ?
                                <>
                                    <View>
                                    {Platform.OS === 'web'
                                        ?<iframe 
                                        width="250" 
                                        height="180"
                                        src={`${item.item.body[0].media[0]}`}
                                        frameBorder="0"
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
                                        allowFullScreen
                                        />
                                        : <WebView source={{uri: item.item.body[0].media.length > 0 ? item.item.body[0].media[0] : 'https://cdn.mesearch.ai/assets/media/sample_Clouds.png'}} style={{width: 250, height: 180}}/>}
                                    </View>
                                </>
                                :
                                    <ImageBackground 
                                      style={styles.image}
                                      imageStyle={{ borderRadius: 4 }}
                                      source={{uri: item.item.body[0].media.length > 0 ? item.item.body[0].media[0] : 'https://cdn.mesearch.ai/assets/media/sample_Clouds.png'}}
                                      onError={(err) => {
                                          console.log(err)
                                    }}
                                    >
                                    {!claimed ?
                                    <View style={{backgroundColor: 'black', width: 100, height: 30, padding:3 }}>
                                      <Image
                                      style={styles.badgeImage}
                                      imageStyle={{ borderRadius: 4 }}
                                      source={
                                        getContentAccessLvl(item.item) > 1 ? 
                                        truBadge : isPointPark ? 
                                          '' : comBadge
                                      }
                                      />             
                                    </View>
                                    : null}
                                    </ImageBackground>
                                }

                                <View style={{height: 70, width: 250, marginBottom:20, backgroundColor: '#000000', opacity: 0.9}}>
                                    <View style={{ height: 50, paddingTop: 1, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                                        <Text numberOfLines={2} style={styles.name} >{item.item.body[0].title}</Text>
                                    </View>
                                    <View style={{ flexDirection:'row', justifyContent:'space-between', height: 30, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                                        <View style={{ flexDirection:'row', paddingLeft: 5,}}>
                                          <Image
                                          style={{height: 20, width: 35, paddingLeft: 15, paddingBottom: 5}}
                                          imageStyle={{ borderRadius: 4 }}
                                          source= {{uri: badgeSourceMap[item.item.source]}}
                                          onError={(err) => {
                                              console.log(err)
                                          }}
                                          />
                                          <Text numberOfLines={1} style={styles.textDescStyle} >By {item.item.body[0].authors[0]}</Text>
                                        </View>
                                        {getContentAccessLvl(item.item) > 1 ? <Text style={{color:'white', paddingRight:5, fontSize:10, display:'none' }}>Trusted{"\n"}Contributor</Text> : <Text style={{color:'white', paddingRight:5, fontSize:10, display:'none'}}>Community{"\n"}Contributor</Text> }
                                    </View>
                                </View>
                            </TouchableOpacity>
                        )
                    }
                    }
                />

{/*
                <Spacer/>

                <View>
                    <Text style={styles.titleStyle}>Advertisements</Text>
                </View>

                <Spacer>

                <View style={{marginLeft:-15}}>

                <FlatList
                    data={adContent}
                    horizontal={true}
                    ListHeaderComponent = { () => { 
                        return (
                            <View style={{justifyContent:'center', alignItems:'center'}}>
                            <View style={styles.blank}>
                                    <Text style={{fontSize:60, fontWeight:'bold', color:'#007bff', justifyContent: 'center', }} 
                                    onPress={()=>{setViewStory(!viewStory), setContentType(ContentTypeEnum.ADVERTISEMENT)}} >+</Text>
                                    <Text style={{fontSize:20, fontWeight:'bold', color:'#007bff' }}>Advertisement</Text>
                            </View>  
                            </View>
                        )}}  
                    ListFooterComponent = {() => {
                            return (
                                <>
                                  {adLoading
                                  ? <View style={{justifyContent:'center', alignItems:'center', width: 250, height: 250,}}>
                                        <ActivityIndicator size="large" style={{padding: 20}}/>
                                    </View>
                                  : null}
                                </>
                            )
                    }}    
                    keyExtractor={(item) => item._id }
                    renderItem={(item) => {
                        return (
                            <TouchableOpacity style={styles.container} onPress={()=>{editContent(item.item),  setViewStory(!viewStory), setContentType(ContentTypeEnum.ADVERTISEMENT)}}>
                                <ImageBackground 
                                style={styles.image}
                                imageStyle={{ borderRadius: 4 }}
                                source={{uri: item.item.body[0].media.length > 0 ? item.item.body[0].media[0] : 'https://cdn.mesearch.ai/assets/media/sample_Clouds.png'}}
                                onError={(err) => {
                                    console.log(err)
                                }}
                                >
                                  <View style={{ flexDirection:'row', justifyContent:'space-between'}}>
                                    <View style={{backgroundColor: 'black', width: 100, height: 30, justifyContent:'center', alignItems:'center'}}>
                                      <Text style={{color:'orange', fontWeight:'bold'}}>Advertisment</Text>
                                    </View>

                                    <Text style={{color:'white', fontWeight:'bold', height: 30, paddingTop:5, paddingRight:15}} >Buy Now</Text>

                                  </View>

                                </ImageBackground>

                                <View style={{height: 70, width: 250, marginBottom:20, backgroundColor: '#000000', opacity: 0.9}}>
                                    <View style={{ height: 50, paddingTop: 1, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                                        <Text numberOfLines={2} style={styles.name} >{item.item.body[0].title}</Text>
                                    </View>
                                    <View style={{ flexDirection:'row', justifyContent:'space-between', height: 30, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                                        <Text style={{color:'white', fontWeight:'bold', paddingLeft:5}}>Price: {item.item.price}</Text>
                                        
                                    </View>
                                </View>
                            </TouchableOpacity>
                        )
                    }
                    }
                />

                </View>

                </Spacer>
*/}

                </>
            }

        </SafeAreaView>
        </ScrollView>
    );

    
};

export default UploadContentScreen;