import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
      // paddingLeft: 15,
      boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.2)',
      marginVertical: 10,
      borderRadius: 10,
      marginHorizontal: 5,
    },
    image: {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      marginBottom: -2,
    },
    stampImageCom: {
      width: 80,
      height: 22,
      marginTop: 4,
      marginLeft: 1,
    },
    stampImageTrus: {
      width: 80,
      height: 25,
      marginTop: 2,
      marginLeft: 1,
    },
    nonArtStampImage: {
      width: 85,
      height: 25,
      marginTop: 2,
      marginLeft: 1,
    },
    imageHeadline: {
      width: 500,
      height: 280,
      borderRadius: 4,
      marginBottom: 5,
    },
    name: {
      height: 55,
      fontWeight: 'bold',
      color: 'black',
      paddingLeft: 5,
      paddingRight: 5,
      // paddingTop: 5,
    },
    userGenBadgeContainer: {
      height: 30,
      width: 90, 
      position: 'absolute', 
      top: 0, 
      backgroundColor: '#000000', 
      opacity: 0.9
    },
    actions: {
      position: 'absolute',
      top: 5,
      right: 5,
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      borderRadius: 10,
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      backdropFilter: 'blur(9.6px)',
      WebkitBackdropFilter: 'blur(9.6px)',
      borderWidth: 1,
      borderColor: 'rgba(255, 255, 255, 0.2)',
      padding: 2,
    },
    typeBadgeContainer: {
      height: 30, 
      width: 90, 
      position: 'absolute', 
      top: 0, 
      backgroundColor: '#000000', 
      opacity: 0.9
    },
    detailContainer: {
      // height: 70,
      // backgroundColor: 'rgba(207,207,207,0.64)', 
      opacity: 0.9,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    detailSubContainer: {
      height: 20,
      // marginTop: -4, 
      // backgroundColor: 'rgba(37,32,32,0.64)',
      // paddingVertical: 5,
      // flexDirection: 'row', 
      opacity: 0.9,
      // borderBottomLeftRadius: 10,
      // borderBottomRightRadius: 10,
      justifyContent: 'center',
      alignItems: 'center',
      width: 278,
    },
    detailSubSubContainer: {
      flexDirection: 'row', 
      flex: 1, 
      paddingLeft: 5,
      justifyContent: 'space-between',
    },
    titleContainer: {
      height: 80,
      // marginTop: -4,
      // backgroundColor: 'rgba(207,207,207,0.64)', 
      opacity: 0.9,
      justifyContent: 'space-between',
      // justifyContent: 'flex-end',
      // alignContent: 'flex-end',
    },
    nameHeadline: {
      width: 500,
      fontWeight: 'bold',
      fontSize: 18,
      color: 'white',
      paddingLeft: 5
    },
    textDescStyle: {
      color: 'black',
      fontWeight: '300',
      fontSize: 14,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: 10
    },
    srcStampImage: {
      height: 20,
      width: 35,
      paddingLeft: 5,
      paddingTop: 5
    },
    byLine: {
      fontSize: 10, 
      color: 'black', 
      paddingLeft: 5,
      alignSelf: 'flex-start',
      width: 278,
      // paddingTop: 2,
    }
});

export default styles;