import React, { useRef, useState, useEffect } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  TouchableOpacity,
  TextInput,
  FlatList,
  Platform,
} from "react-native";
import { Feather, MaterialCommunityIcons, Ionicons } from "@expo/vector-icons";
import MeSearchAPI from '../../api/MeSearch-API';
import { isMobile, isTablet } from "react-device-detect";
import alertToConfirm from '../utils/alertToConfirm';

const AccountHomeLandingPages = ({ landingPages, title, myAccLvl, pubOwner, adminCapability, setLandingPages, horizontal, darkMode, navigation, setLPgSuccessMsg, setLPgErrorMsg }) => {
  let flatListRef = useRef(0);
  const [currOffset, setCurrOffset] = useState(0);
  const [count, setCount] = useState(0);

  const scroll = () => {
    flatListRef.current.scrollToOffset({
      offset: currOffset,
      animated: true,
    });
  };

  useEffect(() => {
    scroll();
  }, [currOffset]);

  return (
    <View style={styles.container}>
      {landingPages.length > 0 ? <Text style={[styles.title, darkMode ? {color: 'white'} : null]}>{title}</Text> : null}
      <View style={styles.inner}>
        {count > 0 && Platform.OS === "web" ? (
          <TouchableOpacity
            style={styles.arrow}
            onPress={() => {
              if (count > 0) {
                setCurrOffset(currOffset - 250);
                setCount(count - 1);
              }
            }}
          >
            <Feather name="chevron-left" size={24} color="#fff" />
          </TouchableOpacity>
        ) : null}
        <FlatList
          horizontal={horizontal}
          ref={flatListRef}
          showsHorizontalScrollIndicator={false}
          data={landingPages}
          keyExtractor={(item) => item._id}
          renderItem={({ item }) => {
            return (
              <View
                style={[
                  styles.profile,
                  {backgroundColor: '#d9d9d9'},
                  { width: 250 },
                ]}
              >
                <View style={styles.upper}>
                  <Image style={styles.img} source={{ uri: item.headerImage ? item.headerImage : "https://cdn.mesearch.ai/assets/stockPhotos/60.jpg" }} />
                </View>
                <View>
                    <View style={styles.lower}>
                        <View style={styles.contentWrapper}>
                            <View style={styles.content}>
                            <Text style={styles.text}>{`publications: ${item.publications ? `${item.publications.length}` : '0'}`}</Text>
                            </View>
                        </View>
                    </View>
                    {(adminCapability && myAccLvl >= 4) ?
                        <View style={{flexDirection: 'row'}}>
                            <TouchableOpacity 
                                style={styles.btn}
                                onPress={() => navigation.navigate('CreateLandingPage', {pubOwner, landDomain: (item.domains && item.domains.length > 0) ? item.domains[0] : null})}
                            >
                                <Text style={styles.btnText}>Edit</Text>
                            </TouchableOpacity>
                            {Platform.OS === 'web' ?
                              <TouchableOpacity 
                                style={styles.btn}
                                onPress={async () => {
                                  alertToConfirm('web', "Are you sure you want to remove this landing page?", async () => {
                                    setLPgErrorMsg("");
                                    setLPgSuccessMsg("");
                                    try {
                                      await MeSearchAPI.delete('/api/pub-owner/landing-page', { params: {domain: (item.domains && item.domains.length > 0) ? item.domains[0] : "" }});
                                      setLandingPages(landingPages.filter(l => !l.domains.includes(item.domains[0])));
                                      setLPgSuccessMsg("Successfully deleted landing page");
                                    } catch (err) {
                                      setLPgErrorMsg("Error deleting landing page");
                                    }
                                  })();
                                }}
                              >
                                  <Text style={styles.btnText}>Remove</Text>
                              </TouchableOpacity>
                            :
                              <TouchableOpacity 
                                style={styles.btn}
                                onPress={async () => {
                                  alertToConfirm('mobile', "Are you sure you want to remove this landing page?", async () => {
                                    setLPgErrorMsg("");
                                    setLPgSuccessMsg("");
                                    try {
                                      await MeSearchAPI.delete('/api/pub-owner/landing-page', { domain: (item.domains && item.domains.length > 0) ? item.domains[0] : "" });
                                      setLandingPages(landingPages.filter(l => !l.domains.includes(item.domains[0])));
                                      setLPgSuccessMsg("Successfully deleted landing page");
                                    } catch (err) {
                                      setLPgErrorMsg("Error deleting landing page");
                                    }
                                  })();
                                }}
                              >
                                <Text style={styles.btnText}>Remove</Text>
                              </TouchableOpacity>
                            }
                        </View>
                    : null}
                    </View>
              </View>
            );
          }}
        />
        {landingPages.length > 3 &&
        (Platform.OS === "web" && (!isMobile && !isTablet)) &&
        count <= Math.ceil(landingPages.length / 3) &&
        horizontal
        ? (
          <TouchableOpacity
            style={styles.arrow}
            onPress={() => {
              if (count <= Math.ceil(landingPages.length / 3)) {
                setCurrOffset(currOffset + 250);
                setCount(count + 1);
              }
            }}
          >
            <Feather name="chevron-right" size={24} color="#fff" />
          </TouchableOpacity>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    //flex:1,
    //backgroundColor:'#d63031',
    //marginHorizontal:10,
    // justifyContent:'center',
    // alignItems:'center',
    //paddingVertical:5,
  },
  inner: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    paddingHorizontal: 5,
    // borderColor:'red',
    // borderWidth:1,
  },
  title: {
    paddingVertical: 5,
    marginHorizontal: 5,
  },
  profile: {
    marginHorizontal: 5,
    marginVertical: 5,
    height: (Platform.OS === 'web' && !isMobile) ? 180 : 200
  },
  upper: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderBottomColor: "#fff",
    borderBottomWidth: 1,
  },
  img: {
    height: 80,
    width: 100,
    resizeMode: 'contain',
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
  },
  userInfo: {
    paddingLeft: 5,
  },
  userName: {
    color: "black",
    fontWeight: "600",
    fontSize: 15,
  },
  userTitle: {
    color: "black",
    fontSize: 10,
  },
  lower: {
    flexDirection: "row",
    alignItems: "center",
  },
  contentWrapper: {
    paddingHorizontal: 5,
    paddingVertical: 10,
  },
  content: {
    paddingBottom: 10,
  },
  text: {
    color: "black",
    fontSize: 10,
  },
  btn: {
    borderColor: "black",
    borderWidth: 1,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 10,
    width: 85
  },
  btnText: {
    color: "black",
    textAlign: "center",
    paddingHorizontal: 10,
    paddingVertical: 5,
    width: 80
  },
  arrow: {
    backgroundColor: "#666",
    height: "95%",
    justifyContent: "center",
  },
});

export default AccountHomeLandingPages;
