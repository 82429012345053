import React from 'react';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import { Feather } from '@expo/vector-icons';
import { Dimensions, View, Text, StyleSheet, TouchableOpacity } from 'react-native';
//import React Native chart Kit for different kind of Chart
import { PieChart } from 'react-native-chart-kit';

  const PieChartDash = ({ pieData, title, subtitle }) => {
    return (
      <View>
        <Text style={{fontWeight:'bold', fontFamily: 'Arial'}}>{title}</Text>
        <Text style={{color:'grey', fontFamily: 'Arial'}}>{subtitle}</Text>
        <PieChart
        data={pieData}
        // width={Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width)/2) : Dimensions.get('window').width-80}
        width={((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? Dimensions.get('window').width - 50 : 650}
        height={250}
        chartConfig={{
          backgroundColor: '#1cc910',
          backgroundGradientFrom: '#eff3ff',
          backgroundGradientTo: '#efefef',
          decimalPlaces: 2,
          color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
          style: {
            borderRadius: 16,
          },
          propsForLabels: {
            fontFamily: 'Arial',
            fontSize: 12
          }
        }}
        style={{
          marginVertical: 8,
          borderRadius: 16,
          padding: 10,
        }}
        accessor="population"
        backgroundColor={"transparent"}
        paddingLeft="10"
        absolute //for the absolute number remove if you want percentage
      />
      </View>
    );
  };
   
  export default PieChartDash;