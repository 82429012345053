import React, { useState, useEffect, useContext, useCallback } from 'react';
import { View, Text, TouchableOpacity, Dimensions, ActivityIndicator, SafeAreaView, Linking } from 'react-native';
import SearchBar from './SearchBar';
import Header from '../components/core/whitelabel/Header';
import useResults from '../hooks/useResults';
import MeSearchAPI from '../api/MeSearch-API';
import { DrawerActions } from '@react-navigation/native';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import ARTICLEROWSPERPAGE from '../screens/utils/articlesPerPage';
import distinctMerge from '../screens/utils/distinctMerge';
import Upload from './Upload';
import uuidv4 from 'uuid/v4';
import useLocation from '../hooks/useLocation';
import { Context as LocationContext } from '../context/LocationContext';
import { reverseGeocodeAsync, setGoogleApiKey} from 'expo-location';
import { fixTagRunon } from '../screens/utils/fixTagRunon';
import { Feather } from '@expo/vector-icons';
import TermsOfServiceFooter from './TermsOfServiceFooter';
import { Context as PubContext } from '../context/PubContext';
import ResultsPage from './wrappers/MeSearch/GridPage';
import MeTags from './utils/meTags';
import updateGDataLayer from '../screens/utils/updateGDataLayer';
import ContentTypesEnum from './utils/contentTypes';
import { DFPManager } from 'react-dfp';
import AdTypesEnum from './utils/AdUnitTypes';
import CallBackQueue from '../screens/utils/CallBackQueue';
import SystemCapabilities from './utils/SystemCapabilities';
import checkSystemCapability from './utils/checkSystemCapability';
import determineBodyId from '../screens/utils/determineBodyId';
import useResultsv2 from '../hooks/useResultsv2';
import WebView from 'react-native-webview';


const MeSearchHome = ({navigation, route}) => {

  const [fetchMoreContentQueue, setfetchMoreContentQueue] = useState(new CallBackQueue());
  const [darkMode, setDarkMode] = useState(false);
  const [searchHash, setSearchHash] = useState("hash");
  const [businessInfo, setBusinessInfo] = useState({})
  const [isGuest, setIsGuest] = useState(false);
  const [curSearchTxt, setCurSearchTxt] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [free, setFreeSearch] = useState(false);
  const [searchScreenPage, setSearchScreenPage] = useState(0);
  const [initVal, setInitVal] = useState(false); // Only used so the useEffect for searchScreenPage doesn't fire on initialize
  const [initValFocus, setInitValFocus] = useState(false); // Only used so the listener for focus doesn't fire on initialize
  const [initValFocusEffect, setInitValFocusEffect] = useState(false); // Only used so the useEffect for focus doesn't fire on initialize
  const [allTags, setAllTags] = useState([]);
  const [curMergedTags, setCurMergedTags] = useState([]);
  const [focus, setFocus] = useState(uuidv4());
  const [home, setHome] = useState(true);
  const [user, setUser] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(true);
  const usrSpecTag = route.params?.usrSpecTag ?? '';
  let shouldRefresh = route.params?.refresh ?? '';
  let shouldRefreshUserInfo = route.params?.refreshUserInfo ?? '';
  let searchHashUpdate = route.params?.searchHashUpdate ?? '';
  const [userSpecifiedTag, setUserSpecifiedTag] = useState(usrSpecTag ? usrSpecTag : '');
  const { state, addLocation, addAddress } = useContext(LocationContext);
  const PubCon = useContext(PubContext);
  const isResultsV2 = PubCon.state.publisher.username === "MeSearchAI";//route.params?.isResultsV2 ?? true;
  const callback = useCallback((location) => {
    addLocation(location, state.recording);
  }, [state.recording]);
  const [err] = useLocation(state.recording, callback);
  const [totalPageNum, setTotalPageNum] = useState(0);
  const [curPageTags, setCurPageTags] = useState([]);
  const [renderPage, setRenderPage] = useState(false);
  const [pubUpload, setPubUpload] = useState(false);
  const [initRend, setInitRend] = useState(true);
  const [focusCall, setFocusCall] = useState(uuidv4());

  const [
    searchContentApi,
    results, 
    headlineResults, 
    errorMessage, 
    searchContentFreeSearchApi, 
    setErrorMessage, 
    setResults, 
    loading, 
    currentLoadTag, 
    numFound, 
    setNumFound,
    userSpecifiedResults,
    setUserSpecifiedResults,
    latestResults,
    nonGreedyResults,
    setNonGreedyResults,
    getNonBaseContentApi,
    setFetchNonPub,
    setFetchNonArt,
    fetchNonPub,
    fetchNonArt,
    setFetchNonPubDone,
    fetchNonPubDone,
    setFetchNonArtDone,
    fetchNonArtDone,
    setFetchAds,
    fetchAds,
    resultsTags, 
    setResultsTags
  ] = useResults();
  const [getResultsv2, resultsv2, isLoadingv2, errorv2] = useResultsv2();
  const { stopRecording, startRecording } = useContext(LocationContext);



  const fetchUserAddress = async (state) => {

    if (state.currentLocation !== null) {
      try {
        //await requestPermissionsAsync();
        if (Platform.OS === 'web') {
          setGoogleApiKey("AIzaSyDjfwURL6qGha9MK6MQOPD2eHZpTUZAC24");
        }
        let add = await reverseGeocodeAsync({
          latitude: state.currentLocation.coords.latitude,
          longitude: state.currentLocation.coords.longitude
        });
        if (add) {
          addAddress(add);
          const usrResponse = await MeSearchAPI.get('/api/user');
          let userLocations = usrResponse.data.history.locations;
          const timeStamp = new Date().getTime();
          userLocations.push({ 
            region: add[0].region,
            city: add[0].city,
            street: add[0].street,
            postalCode: add[0].postalCode,
            lat: state.currentLocation.coords.latitude,
            long: state.currentLocation.coords.longitude,
            timestamp: timeStamp
          });
          await MeSearchAPI.patch(
            '/api/user', 
            { payload: { 
                history: {
                  locations: userLocations,
                  searches: usrResponse.data.history.searches,
                  contentClicks: usrResponse.data.history.contentClicks,
                  accounts: usrResponse.data.history.accounts,
                  contentShareClicks: usrResponse.data.history.contentShareClicks,
                  contentShares: usrResponse.data.history.contentShares,
                  impressions: usrResponse.data.history.impressions
                }
              } 
          });
          stopRecording();
        }
      } catch (e) {
        stopRecording();
        }
    }
  };

  const fetchRelevantUserContentTags = async (searchText='', freeSearch=false, searchTags=false, searchFirstFetch=false) => {
    // TODO: Move all API calls into useResults hook
    let usrName = null;
    let mergedTags = allTags;
    let pageTags = [];
    const publisherTags = PubCon.state.publisher.pubTags;
    const mandatoryTag = PubCon.state.publisher.mandatoryTag;
    if (!initVal) {
      fetchHeadlineContent(mandatoryTag);
      fetchBreakingContent(mandatoryTag);
      setInitVal(true);
    }
    if (!searchTags) {
      let contentAgeTags = allTags;
      let disabledTags = [];
      // Fetch user tags
      const usrTagsResponse = await MeSearchAPI.get('/api/user');
      const usrConTags = usrTagsResponse.data.params.contentTags;
      let darkmode = usrTagsResponse.data.darkMode;
      let guest = usrTagsResponse.data.guest;
      let termsAccepted = usrTagsResponse.data.termsAccepted;
      let accLvl = usrTagsResponse.data.accessLvl;
      usrName = usrTagsResponse.data.username;
      setUser(usrName);
      let curPubAccLvl = 1;
      let curOwnAccLvl = 1;
      if (accLvl) {
        accLvl.forEach(accObj => {
          if (accObj.account === PubCon.state.publisher.username) {
            curPubAccLvl = accObj.perm;
          }
          if (accObj.account === PubCon.state.publisher.owner) {
            curOwnAccLvl = accObj.perm;
          }
        });
      }
      if (curPubAccLvl >= 4 || curOwnAccLvl >= 4) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
      setIsGuest(guest);
      if (typeof darkmode === 'undefined') {
        darkmode = false;
      }
      if (!termsAccepted && guest) {
        setTermsAccepted(false);
      }
      setDarkMode(darkmode);
      if (shouldRefreshUserInfo !== '') {
        if (typeof termsAccepted === 'undefined') {
          if (!guest) {
            setTermsAccepted(true);
          } else {
            setTermsAccepted(false);
          }
        } else {
          if (!guest) {
            setTermsAccepted(true);
          } else {
            setTermsAccepted(termsAccepted);
          }
        }
      }
      const usrConTagsSorted = (usrConTags.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))).reverse();
      let usrTagsSorted = [];
      usrConTagsSorted.forEach(contentTag => {
        if ((typeof contentTag.isEnabled === 'undefined') || contentTag.isEnabled) {
          usrTagsSorted.push(contentTag.conTag);
        } else {
          disabledTags.push(contentTag.conTag);
        }
      });
      let cachedTags = allTags;
      if (allTags.length === 0) {
        // Get ISO date range for contageAgeRange or past two weeks
        const startDateTimeMS = new Date();
        const startDateMS = startDateTimeMS.setDate(startDateTimeMS.getDate() - (PubCon.state.publisher.contentAgeRange 
          ? (PubCon.state.publisher.baseContentType === ContentTypesEnum.EVENT
            ? 0: PubCon.state.publisher.contentAgeRange) 
          : 14));
        const startISODate = new Date(startDateMS).toISOString();
        const endDateTimeMS = new Date();
        const endDateMS = endDateTimeMS.setDate(endDateTimeMS.getDate() + (PubCon.state.publisher.baseContentType &&
          PubCon.state.publisher.baseContentType === ContentTypesEnum.EVENT ?  PubCon.state.publisher.contentAgeRange : 0));
        const endISODate = new Date(endDateMS).toISOString();
        let types = null;
        if (PubCon.state.publisher.baseContentType === ContentTypesEnum.PRODUCT) {
          types = [ContentTypesEnum.PRODUCT, ContentTypesEnum.PRODUCTLINKVIEW, ContentTypesEnum.PRODUCTFRAMEVIEW];
        } else if (PubCon.state.publisher.baseContentType === ContentTypesEnum.EVENT) {
          types = [ContentTypesEnum.EVENT, ContentTypesEnum.EVENTLINKVIEW, ContentTypesEnum.EVENTFRAMEVIEW];
        }
        const contentAgeTagsResponse = await MeSearchAPI.get(
          '/api/content/tags',
          {params: {
            startDate: startISODate,
            endDate: endISODate,
            pub: PubCon.state.publisher.username,
            types: types
            //mandatoryTag: mandatoryTag ? mandatoryTag : undefined
          }
        });
        contentAgeTags = contentAgeTagsResponse.data.tags;
        // TODO: HACK to fix tag,tag,tag,tag from rendering
        // Remove mandatory & Headline tags from list because they will be redundant
        let mandIndex = contentAgeTags.indexOf(mandatoryTag);
        if (mandIndex > -1) {
          contentAgeTags.splice(mandIndex, 1);
        }
        let headIndex = contentAgeTags.indexOf("Headline");
        if (headIndex > -1) {
          contentAgeTags.splice(headIndex, 1);
        }
        if (PubCon.state.publisher.blacklistTags) {
          PubCon.state.publisher.blacklistTags.forEach(bTag => {
            let bIndex = contentAgeTags.indexOf(bTag);
            if (bIndex > -1) {
              contentAgeTags.splice(bIndex, 1);
            }
          });
        }
        /*
        // Only use the first 30 tags
        if (contentAgeTags.length > 30) {
          contentAgeTags.splice(30, contentAgeTags.length-30)
        }*/
        cachedTags = contentAgeTags;
        setAllTags(fixTagRunon(contentAgeTags));
        //setTotalPages(generatePagesArray(mergedTags.length));
      }
      const cachedTagsWithoutDisabled = cachedTags.filter(ageTag => {
        let isEnabled = true;
        disabledTags.forEach(disabledTag => {
          if (ageTag === disabledTag) {
            isEnabled = false;
          }
        });
        return isEnabled;
      });
      mergedTags = distinctMerge(publisherTags, usrTagsSorted, cachedTagsWithoutDisabled);
      // Remove mandatory & Headline tags from list because they will be redundant
      let mandIndex = mergedTags.indexOf(mandatoryTag);
      if (mandIndex > -1) {
        mergedTags.splice(mandIndex, 1);
      }
      let headIndex = mergedTags.indexOf("Headline");
      if (headIndex > -1) {
        mergedTags.splice(headIndex, 1);
      }
      // Remove blacklist tags
      if (PubCon.state.publisher.blacklistTags) {
        PubCon.state.publisher.blacklistTags.forEach(bTag => {
          let bIndex = contentAgeTags.indexOf(bTag);
          if (bIndex > -1) {
            contentAgeTags.splice(bIndex, 1);
          }
        });
      }
    } else {
      if (searchFirstFetch) {
        const curSearchBodyIdsResponse = await MeSearchAPI.get(
          '/api/content/free-search/bodyIds',
          {params: {
            searchText: searchText,
            pub: PubCon.state.publisher.username
          }
        });
        const bodyIds = curSearchBodyIdsResponse.data.bodyIds;
        const numFound = curSearchBodyIdsResponse.data.numFound;
        if (Number(numFound) > 0) {
          const searchTagsResponse = await MeSearchAPI.post(
            '/api/content/tags',
            {
              bodyIds: bodyIds,
              pub: PubCon.state.publisher.username
          });
          const searchTags = searchTagsResponse.data.tags;
          // TODO: HACK to fix tag,tag,tag,tag from rendering
          setAllTags([...new Set(fixTagRunon(searchTags))]);
          mergedTags = searchTags;
        } else {
          mergedTags = [];
          setResults([]);
          setResultsTags([]);
          setNumFound(0);
        }
      }
    }
    // TODO: HACK to fix tag,tag,tag,tag from rendering
    mergedTags = fixTagRunon(mergedTags);
    setCurMergedTags(mergedTags);
    //setAllTags(mergedTags);
    if (mergedTags.length > 0) {
      setTotalPageNum(mergedTags.length);
      pageTags = generateTagsForPage(searchFirstFetch ? 0 : searchScreenPage, mergedTags);
      setCurPageTags(pageTags);
      if (freeSearch) {
        searchContentFreeSearchApi(pageTags, null, 0, searchText, null, false, undefined, true, null, PubCon.state.publisher.username);
      } else {
        let types = null;
        if (PubCon.state.publisher.baseContentType === ContentTypesEnum.PRODUCT) {
          types = [ContentTypesEnum.PRODUCT, ContentTypesEnum.PRODUCTLINKVIEW, ContentTypesEnum.PRODUCTFRAMEVIEW];
        } else if (PubCon.state.publisher.baseContentType === ContentTypesEnum.EVENT) {
          types = [ContentTypesEnum.EVENT, ContentTypesEnum.EVENTLINKVIEW, ContentTypesEnum.EVENTFRAMEVIEW];
        }
        isResultsV2
          ? getResultsv2({currentFeed: resultsv2, page: 0, 
              sources: [...PubCon.state.publisher.sources, ...PubCon.state.publisher.nonPubSources], 
              targeting: PubCon.state.publisher.username, userId: usrName
            })
          : searchContentApi(pageTags, null, 0, '', null, false, false, undefined, true, null, PubCon.state.publisher.username, false, null, null, types);
      }
    }
  };

  const genPageTagsFromCurMergedTags = async () => {
    genContentAgeTags();
    if (curMergedTags.length > 0) {
      let pageTags = generateTagsForPage(searchScreenPage, curMergedTags);
      setCurPageTags(pageTags);
      let types = null;
      if (PubCon.state.publisher.baseContentType === ContentTypesEnum.PRODUCT) {
        types = [ContentTypesEnum.PRODUCT, ContentTypesEnum.PRODUCTLINKVIEW, ContentTypesEnum.PRODUCTFRAMEVIEW];
      } else if (PubCon.state.publisher.baseContentType === ContentTypesEnum.EVENT) {
        types = [ContentTypesEnum.EVENT, ContentTypesEnum.EVENTLINKVIEW, ContentTypesEnum.EVENTFRAMEVIEW];
      }
      isResultsV2
        ? getResultsv2({currentFeed: resultsv2, page: 0, 
          sources: [...PubCon.state.publisher.sources, ...PubCon.state.publisher.nonPubSources], 
          targeting: PubCon.state.publisher.username, userId: user
        })
        : searchContentApi(pageTags, null, 0, '', null, false, false, undefined, true, null, PubCon.state.publisher.username, false, null, null, types);
    }
  }

  const genContentAgeTags = async () => {

    const mandatoryTag = PubCon.state.publisher.mandatoryTag;
     // Get ISO date range for contageAgeRange or past two weeks
    const startDateTimeMS = new Date();
    const startDateMS = startDateTimeMS.setDate(startDateTimeMS.getDate() - (PubCon.state.publisher.contentAgeRange 
      ? (PubCon.state.publisher.baseContentType === ContentTypesEnum.EVENT
        ? 0: PubCon.state.publisher.contentAgeRange) 
      : 14));
    const startISODate = new Date(startDateMS).toISOString();
    const endDateTimeMS = new Date();
    const endDateMS = endDateTimeMS.setDate(endDateTimeMS.getDate() + (PubCon.state.publisher.baseContentType &&
      PubCon.state.publisher.baseContentType === ContentTypesEnum.EVENT ?  PubCon.state.publisher.contentAgeRange : 0));
    const endISODate = new Date(endDateMS).toISOString();
    const contentAgeTagsResponse = await MeSearchAPI.get(
      '/api/content/tags',
      {params: {
        startDate: startISODate,
        endDate: endISODate,
        pub: PubCon.state.publisher.username,
        types: (PubCon.state.publisher.baseContentType === ContentTypesEnum.PRODUCT)
        ? [ContentTypesEnum.PRODUCT, ContentTypesEnum.PRODUCTLINKVIEW, ContentTypesEnum.PRODUCTFRAMEVIEW]
        : null
        //mandatoryTag: mandatoryTag ? mandatoryTag : undefined
      }
    });
    let contentAgeTags = contentAgeTagsResponse.data.tags;
    // TODO: HACK to fix tag,tag,tag,tag from rendering
    // Remove mandatory & Headline tags from list because they will be redundant
    let mandIndex = contentAgeTags.indexOf(mandatoryTag);
    if (mandIndex > -1) {
      contentAgeTags.splice(mandIndex, 1);
    }
    let headIndex = contentAgeTags.indexOf("Headline");
    if (headIndex > -1) {
      contentAgeTags.splice(headIndex, 1);
    }
    // Remove blacklist tags
    if (PubCon.state.publisher.blacklistTags) {
      PubCon.state.publisher.blacklistTags.forEach(bTag => {
        let bIndex = contentAgeTags.indexOf(bTag);
        if (bIndex > -1) {
          contentAgeTags.splice(bIndex, 1);
        }
      });
    }
    setAllTags(fixTagRunon(contentAgeTags));
  }

  const fetchMoreContent = async (tag, page, searchText='', freeSearch=false, userSpecified=false, nonGreedyRes=nonGreedyResults, res=results) => {
    if (freeSearch) {
      if (userSpecified) {
        searchContentFreeSearchApi(curPageTags, tag, page, searchText, userSpecifiedResults, userSpecified, undefined, true, null, PubCon.state.publisher.username);
      } else {
        searchContentFreeSearchApi(curPageTags, tag, page, searchText, res, false, undefined, false, nonGreedyRes, PubCon.state.publisher.username);
      }
    } else {
      let types = null;
      if (PubCon.state.publisher.baseContentType === ContentTypesEnum.PRODUCT) {
        types = [ContentTypesEnum.PRODUCT, ContentTypesEnum.PRODUCTLINKVIEW, ContentTypesEnum.PRODUCTFRAMEVIEW];
      } else if (PubCon.state.publisher.baseContentType === ContentTypesEnum.EVENT) {
        types = [ContentTypesEnum.EVENT, ContentTypesEnum.EVENTLINKVIEW, ContentTypesEnum.EVENTFRAMEVIEW];
      }
      if (userSpecified) {
        isResultsV2
          ? getResultsv2({currentFeed: resultsv2, page: page, 
            sources: [...PubCon.state.publisher.sources, ...PubCon.state.publisher.nonPubSources], 
            targeting: PubCon.state.publisher.username, userId: user, tags: [tag]
          })
          : searchContentApi(curPageTags, tag, page, false, userSpecifiedResults, userSpecified, false, undefined, true, null, PubCon.state.publisher.username, false, null, null, types);
      } else {
        isResultsV2
          ? getResultsv2({currentFeed: resultsv2, page: page, 
            sources: [...PubCon.state.publisher.sources, ...PubCon.state.publisher.nonPubSources], 
            targeting: PubCon.state.publisher.username, userId: user
          })
          : searchContentApi(curPageTags, tag, page, false, res, false, false, undefined, false, nonGreedyRes, PubCon.state.publisher.username, false, null, null, types);
      }
    }
  };

  const fetchHeadlineContent = async (mandatoryTag) => {
    let types = null;
    if (PubCon.state.publisher.baseContentType === ContentTypesEnum.PRODUCT) {
      types = [ContentTypesEnum.PRODUCT, ContentTypesEnum.PRODUCTLINKVIEW, ContentTypesEnum.PRODUCTFRAMEVIEW];
    } else if (PubCon.state.publisher.baseContentType === ContentTypesEnum.EVENT) {
      types = [ContentTypesEnum.EVENT, ContentTypesEnum.EVENTLINKVIEW, ContentTypesEnum.EVENTFRAMEVIEW];
    }
    //searchContentApi("Headline", null, 0, true, null, false, false, undefined, true, null, PubCon.state.publisher.username, false, null, null, types);
  };

  const fetchBreakingContent = async (mandatoryTag) => {
    let types = null;
    if (PubCon.state.publisher.baseContentType === ContentTypesEnum.PRODUCT) {
      types = [ContentTypesEnum.PRODUCT, ContentTypesEnum.PRODUCTLINKVIEW, ContentTypesEnum.PRODUCTFRAMEVIEW];
    } else if (PubCon.state.publisher.baseContentType === ContentTypesEnum.EVENT) {
      types = [ContentTypesEnum.EVENT, ContentTypesEnum.EVENTLINKVIEW, ContentTypesEnum.EVENTFRAMEVIEW];
    }
    isResultsV2
      ? null
      : searchContentApi("Latest", null, 0, false, null, false, true, mandatoryTag, true, null, PubCon.state.publisher.username, false, null, null, types);
  };

  const generateTagsForPage = (page, tags) => {
    const beginTagIndex = page * ARTICLEROWSPERPAGE;
    const endTagIndex = Math.min((beginTagIndex + ARTICLEROWSPERPAGE), tags.length);
    return tags.slice(beginTagIndex, endTagIndex);
  };

  const updateUserSearchHistory = async (searchText) => {
    const timeStamp = new Date().getTime();
    const response = await MeSearchAPI.get('/api/user');
    let usrSearches = response.data.history.searches;
    let device = "";
    if ((Platform.OS === 'web' && !isMobile)) {
      if (isTablet) {
        device = "Tablet";
      } else {
        device = "Desktop";
      }
    } else {
      device = "Mobile";
    }
    const newSearch = {searchTxt: searchText, timestamp: timeStamp, device: device, pub: PubCon.state.publisher.username};
    usrSearches.push(newSearch);
    await MeSearchAPI.patch(
      '/api/user', 
      { payload: { 
          history: {
            searches: usrSearches,
            contentClicks: response.data.history.contentClicks,
            locations: response.data.history.locations,
            accounts: response.data.history.accounts,
            contentShareClicks: response.data.history.contentShareClicks,
            contentShares: response.data.history.contentShares,
            impressions: response.data.history.impressions
          }
        } 
    });
  }

  const loadResults = (clearPrevResults=false, clearUserSpec=true, fetchNonBase=false) => {
    // TODO: Currently clicking home causes the non publisher content and the non article
    // Content to disapear
    let fetchedNonPubandArtContent = false;
    if (searchScreenPage === 0 && !free) {
      fetchHeadlineContent(PubCon.state.publisher.mandatoryTag);
      fetchBreakingContent(PubCon.state.publisher.mandatoryTag);
      fetchRelevantUserContentTags('', false);
      if (!fetchedNonPubandArtContent) {
        setFetchNonPub(true);
        setFetchNonPubDone(false);
        setFetchNonArtDone(false);
        setFetchNonArt(true);
        setFetchAds(true);
        fetchedNonPubandArtContent = true;
      }
    }
    if (searchScreenPage > 0 && home) {
      setHome(false);
      if (!fetchedNonPubandArtContent) {
        setFetchNonPub(true);
        setFetchNonPubDone(false);
        setFetchNonArtDone(false);
        setFetchNonArt(true);
        setFetchAds(true);
        fetchedNonPubandArtContent = true;
      }
    }
    if (clearPrevResults) {
      if (clearUserSpec) {
        setUserSpecifiedTag('');
        setUserSpecifiedResults([]);
      }
      setResults([]);
      setResultsTags([]);
      if (!fetchedNonPubandArtContent) {
        setFetchNonPub(true);
        setFetchNonPubDone(false);
        setFetchNonArtDone(false);
        setFetchNonArt(true);
        setFetchAds(true);
        fetchedNonPubandArtContent = true;
      }
    }
    if (fetchNonBase) {
      setFetchNonPub(true);
      setFetchNonPubDone(false);
      setFetchNonArtDone(false);
      setFetchNonArt(true);
      setFetchAds(true);
      fetchedNonPubandArtContent = true;
    }
    setErrorMessage('');
    if (free && !home) {
      fetchRelevantUserContentTags(curSearchTxt, true, true);
    } else {
      setNumFound(-1);
      setFreeSearch(false);
      setCurSearchTxt('');
      // TODO: Cleanup, this is only to keep rows from dynamically moving off the page
      genPageTagsFromCurMergedTags();
    }
  };
  const fetchNonPublisherContent = async () => {
    // Get ISO date range for the past week
    const startDateTimeMS = new Date();
    const startDateMS = startDateTimeMS.setDate(startDateTimeMS.getDate() - (PubCon.state.publisher.contentAgeRange 
      ? (PubCon.state.publisher.baseContentType === ContentTypesEnum.EVENT
        ? 0: PubCon.state.publisher.contentAgeRange) 
      : 14));
    const startISODate = new Date(startDateMS).toISOString();
    let types = null;
    if (PubCon.state.publisher.baseContentType === ContentTypesEnum.PRODUCT) {
      types = [ContentTypesEnum.PRODUCT, ContentTypesEnum.PRODUCTLINKVIEW, ContentTypesEnum.PRODUCTFRAMEVIEW];
    } else if (PubCon.state.publisher.baseContentType === ContentTypesEnum.EVENT) {
      types = [ContentTypesEnum.EVENT, ContentTypesEnum.EVENTLINKVIEW, ContentTypesEnum.EVENTFRAMEVIEW];
    }
    isResultsV2
      ? null
      : await searchContentApi(curPageTags, null, 0, false, results, false, false, undefined, true, null, PubCon.state.publisher.username, true, 30, startISODate, types);
    setFetchNonPubDone(true);
  }

  const fetchNonBaseContent = async () => {
    // Get ISO date range for now
    const startDateTimeMS = new Date();
    const startDateMS = startDateTimeMS.setDate(startDateTimeMS.getDate() - (PubCon.state.publisher.contentAgeRange 
      ? (PubCon.state.publisher.baseContentType === ContentTypesEnum.EVENT
        ? 0: PubCon.state.publisher.contentAgeRange) 
      : 14));
    const startISODate = new Date(startDateMS).toISOString();
    let types = null;
    if (PubCon.state.publisher.baseContentType === ContentTypesEnum.PRODUCT) {
      types = [ContentTypesEnum.PRODUCT, ContentTypesEnum.PRODUCTLINKVIEW, ContentTypesEnum.PRODUCTFRAMEVIEW];
    } else if (PubCon.state.publisher.baseContentType === ContentTypesEnum.EVENT) {
      types = [ContentTypesEnum.EVENT, ContentTypesEnum.EVENTLINKVIEW, ContentTypesEnum.EVENTFRAMEVIEW];
    }
    await getNonBaseContentApi(startISODate, PubCon.state.publisher.username, results, curPageTags, 30, types);
    setFetchNonArtDone(true);
  }

  const fetchAdContent = async () => {
    // Get ISO date range for now
    const startDateTimeMS = new Date();
    const startDateMS = startDateTimeMS.setDate(startDateTimeMS.getDate() - (PubCon.state.publisher.contentAgeRange 
      ? (PubCon.state.publisher.baseContentType === ContentTypesEnum.EVENT
        ? 0: PubCon.state.publisher.contentAgeRange) 
      : 14));
    const startISODate = new Date(startDateMS).toISOString();
    const types = [ContentTypesEnum.ADVERTISEMENT, ContentTypesEnum.ADVERTISEMENTFRAMEVIEW, ContentTypesEnum.ADVERTISEMENTLINKVIEW];
    const target = PubCon.state.publisher.username;
    await getNonBaseContentApi(startISODate, PubCon.state.publisher.username, results, curPageTags, 6, types, target);
  }

  const refreshDFPAds = () => {
     if (Platform.OS === 'web' && PubCon.state.publisher.dfpAds) {
      const banner1 = PubCon.state.publisher.adSlots.filter(a => a.type === AdTypesEnum.BANNER1);
      const banner2 = PubCon.state.publisher.adSlots.filter(a => a.type === AdTypesEnum.BANNER2);
      const banner3 = PubCon.state.publisher.adSlots.filter(a => a.type === AdTypesEnum.BANNER3);
      const grid1 = PubCon.state.publisher.adSlots.filter(a => a.type === AdTypesEnum.GRID);
      if (banner1.length > 0) {
        DFPManager.refresh(banner1[0]["slotID"]);
      }
      if (banner2.length > 0) {
        DFPManager.refresh(banner2[0]["slotID"]);
      }
      if (banner3.length > 0) {
        DFPManager.refresh(banner3[0]["slotID"]);
      }
      if (grid1.length > 0) {
        DFPManager.refresh(grid1[0]["slotID"]);
      }
    }
  }

  // Fetch pubOwner
  useEffect (() => {
    const apiProm = MeSearchAPI.get('/api/pub-owner/buisness-info', {params: {pubOwnerId: PubCon.state.publisher.claimedOwner}})
    apiProm
    .catch((err) => {
      console.log(err);
    })
    .then((res) => {
      if (res) {
        const businessObj = res.data;
        setBusinessInfo({
          name: businessObj.businessName,
          address: businessObj.mailAddr,
          hours: businessObj.hours,
          phoneNum: businessObj.phoneNum,
          website: businessObj.website,
          menuUrl: businessObj.menuUrl,
          reserveUrl: businessObj.reserveUrl,
          healthInfo: businessObj.healthInfo,
        });
        /*
        setBusinessInfo({
          name: "Test Name",
          address: {street: "test street", city: "test city", state: "test state", country: "test country", zipCode: "test zip code"},
          hours: "9-17",
          website: "https://google.com/?p=1",
          menuUrl: "https://google.com/?p=2",
          reserveUrl: "https://google.com/?p=3",
          healthInfo: "Masks required"
        });
        */
      }
    });
  }, [PubCon.state.publisher]);

  // Fetch non publisher articles
  useEffect (() => {
    if (fetchNonPub && !free) {
      if (results.length > 0) {
        setFetchNonPub(false);
        fetchNonPublisherContent();
      }
    }
  }, [results]);

  // fetch more content queue
  useEffect (() => {
    // Check the fetchMoreContentQueue
    if (fetchMoreContentQueue.getQueueCount() > 0) {
      const paramsUpdate = [{indx: 6, value: nonGreedyResults}, {indx: 5, value: results}];
      fetchMoreContentQueue.queueNext(paramsUpdate);
    }
  }, [nonGreedyResults]);

  useEffect (() => {
    if (usrSpecTag !== '') {
      setUserSpecifiedTag(usrSpecTag);
    }
  }, [usrSpecTag]);

  useEffect (() => {
    if (userSpecifiedTag !== '') {
      isResultsV2
        ? getResultsv2({currentFeed: [], page: 0, 
          sources: [...PubCon.state.publisher.sources, ...PubCon.state.publisher.nonPubSources], 
          targeting: PubCon.state.publisher.username, userId: user, tags: [userSpecifiedTag],
        })
        : searchContentApi([userSpecifiedTag], null, 0, '', null, true, false, undefined, true, null, PubCon.state.publisher.username);
    }
  }, [userSpecifiedTag]);

  useEffect (() => {
    if (shouldRefresh !== '') {
      refresh();
    }
  }, [shouldRefresh]);

    // Fetch non publisher articles
    useEffect (() => {
      if (searchHashUpdate !== '' && (PubCon.state.publisher.template && PubCon.state.publisher.template.header && PubCon.state.publisher.template.header.template1)) {
        setSearchHash(searchHashUpdate);
      }
    }, [searchHashUpdate]);
  

  const refresh = () => {
    setUserSpecifiedTag([]);
    setUserSpecifiedResults([]);
    setNonGreedyResults([]);
    setResults([]);
    setResultsTags([]);
    setFreeSearch(false);
    setCurSearchTxt('');
    setErrorMessage('');
    if (home) {
      setFocus(uuidv4());
    }
    setSearchScreenPage(0);
    setHome(true);
    setSearchHash("hash");
  }

  useEffect (() => {
    if (shouldRefreshUserInfo !== '') {
      setUserSpecifiedTag([]);
      setUserSpecifiedResults([]);
      setResults([]);
      setResultsTags([]);
      setFreeSearch(false);
      setCurSearchTxt('');
      setErrorMessage('');
      fetchRelevantUserContentTags();
    }
  }, [shouldRefreshUserInfo]);

  // Fetch non article content (sponsored, products, events, etc...)
  useEffect (() => {
    if (fetchNonPubDone && fetchNonArt && !free) {
      if (results.length > 0) {
        setFetchNonArt(false);
        fetchNonBaseContent();
      }
    }
  }, [results, fetchNonPubDone]);

  // Fetch Ads
  useEffect (() => {
    if (fetchNonPubDone && fetchNonArtDone && fetchAds && !free) {
      if (results.length > 0) {
        setFetchAds(false);
        fetchAdContent();
      }
    }
  }, [results, fetchNonArtDone]);

  const focusCallbacks = async () => {
    setFocusCall(uuidv4());
    setErrorMessage('');
    const usrDarkModeRes = await MeSearchAPI.get('/api/user');
    setDarkMode(usrDarkModeRes.data.darkMode ? true : false);
    if (usrDarkModeRes.data) {
      let fAccessLvl = 0;
      let found = false;
      if (usrDarkModeRes.data.accessLvl) {
        usrDarkModeRes.data.accessLvl.forEach(accessObj => {
          if (accessObj.account === PubCon.state.publisher.username || accessObj.account === PubCon.state.publisher.owner) {
            found = true;
            fAccessLvl = Math.max(accessObj.perm, fAccessLvl);
          }
        });
      }
      if (fAccessLvl == 0 && found) {
        setPubUpload(false);
      } else {
        setPubUpload(checkSystemCapability(usrDarkModeRes.data, PubCon.state.publisher.username, SystemCapabilities.UPLOAD) && checkSystemCapability(usrDarkModeRes.data, PubCon.state.publisher.owner, SystemCapabilities.UPLOAD));
      }
    }
  }
  
  useEffect (() => {
    setFreeSearch(false);
    let render = true;
    let bodyId;
    let trustedContribRequest;
    let trustedContribApprove;
    let changeCode;
    let rem;
    let publisherSign;
    let navToForgotPass;
    let usrEmail = "";
    let msg;
    let abc;
    let pid;
    let rc;
    let rid;
    let bid;
    let su;
    let addBusiness;
    let cc;
    let ccode;
    let emailConfirm;
    let account;
    let e;
    let priv;

    //navigation.navigate('charts');//TODO testing 

    if (Platform.OS === 'web') {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let loc = window.location.href
      bodyId = params.get('body_id');
      trustedContribRequest = params.get('tcr');
      trustedContribApprove = params.get('tc');
      changeCode = params.get('prc');
      navToForgotPass = params.get('fp');
      usrEmail = params.get('email');
      publisherSign = loc.includes('publisher');
      rem = params.get('rem');
      msg = params.get('msg');
      abc = params.get('abc');
      pid = params.get('pid');
      rc = params.get('rc');
      rid = params.get('rid');
      bid = params.get('bid');
      priv = params.get('priv');
      su = params.get('su');
      addBusiness = params.get('addBusiness');
      cc = params.get('cc');
      ccode = params.get('ccode');
      emailConfirm = params.get('emailConfirm');
      account = params.get('account');
      e = params.get('e');
      if (!bodyId) {
        const bId = determineBodyId(search, loc);
        if (bId !== "") {
          bodyId = bId;
        }
      }
    }
    if (emailConfirm) {
      navigation.navigate('ConfirmEmail', {confirmCode: cc, changeCode: ccode, account: account, email: e});
    }
    if (addBusiness) {
      navigation.navigate('ClaimBusiness', {addBusiness: true});
    }
    if (su) {
      navigation.navigate('Signin/Signup', {screen: 'Signup'});
    }
    if (abc) {
      navigation.navigate('ApproveBusinessClaim', {pid, rc, rid, body_id: bid});
    }
    if (msg) {
      navigation.navigate('UserMessage');
    }
    if (rem && changeCode) {
      render = false;
      navigation.navigate('DidNotResetPassword', {changeCode});
    }
    if (navToForgotPass) {
      render = false;
      navigation.navigate('ForgotPassword', {email: usrEmail});
    }
    if (publisherSign) {
      if (!isGuest) {
        render = false;
        navigation.navigate('Publisher');
      }
      // TODO: add an error message to the user
    }
    if (changeCode && !rem) {
      render = false;
      navigation.navigate('ResetPassword', {changeCode, priv});
    }
    if (bodyId) {
      render = false;
      // Retrieve dark mode boolean and content
      const usrTagsResponseProm = MeSearchAPI.get('/api/user');
      usrTagsResponseProm.then((usrTagsResponse, err) => {
        if (usrTagsResponse) {
          const darkmode = usrTagsResponse.data.darkMode;
          const responseProm = MeSearchAPI.get(
            '/api/content/single', 
            {params: {
              body_id: bodyId,
              pub: PubCon.state.publisher.username
            }
          });
          responseProm.then((response) => {
            if (response) {
              const content = response.data;
              if (PubCon.state.publisher.linkViewSources.includes(content.source) || content.type === ContentTypesEnum.ARTICLELINKVIEW) {
                window.open(content.body[0].link);
              } else {
                navigation.navigate('ResultsShow', { content: content, darkMode: darkmode, frameView: PubCon.state.publisher.frameViewSources.includes(content.source) ? true : false  });
              }
            }
          });
        }
      });
    } else if (trustedContribRequest) {
      render = false;
      navigation.navigate('TrustedContributor');
    } else if (trustedContribApprove) {
      render = false;
      navigation.navigate('ApproveTrustedContributor', {email: trustedContribApprove});
    }
    fetchRelevantUserContentTags();
    startRecording();
    focusCallbacks();
    const unsubscribe = navigation.addListener('focus', async () => {
      focusCallbacks();
    });
    if (Platform.OS === 'web' && !window.location.href.includes('content-page/') && !window.location.href.includes('/bid')) {
      updateGDataLayer(userSpecifiedTag, PubCon.state.publisher.gtmId, PubCon.state.publisher.username);
    }
    if (render) {
      setRenderPage(true);
    }
    return unsubscribe;
  }, []);

  useEffect (() => {
    if (!renderPage && !initRend) {
      setRenderPage(true);
    } else if (initRend) {
      setInitRend(false);
    }
  }, [focusCall]);

  useEffect (() => {
    if (initValFocusEffect) {
      if (initValFocus) {
        if (!renderPage) {
          setRenderPage(true);
        }
        // Refresh ad units on page
        refreshDFPAds();
        if (!free) {
          loadResults(false, true, true);
        } else {
          // Set dark mode async
          const usrResponsePromise = MeSearchAPI.get('/api/user');
          usrResponsePromise.then((usrResponse) => {
            let darkmode = usrResponse.data.darkMode;
            if (typeof darkmode === 'undefined') {
              darkmode = false;
            }
            if (usrResponse.data) {
              let fAccessLvl = 0;
              let found = false;
              if (usrResponse.data.accessLvl) {
                usrResponse.data.accessLvl.forEach(accessObj => {
                  if (accessObj.account === PubCon.state.publisher.username || accessObj.account === PubCon.state.publisher.owner) {
                    found = true;
                    fAccessLvl = Math.max(accessObj.perm, fAccessLvl);
                  }
                });
              }
              if (fAccessLvl == 0 && found) {
                setPubUpload(false);
              } else {
                setPubUpload(checkSystemCapability(userResponse.data, PubCon.state.publisher.username, SystemCapabilities.UPLOAD) && checkSystemCapability(userResponse.data, PubCon.state.publisher.owner, SystemCapabilities.UPLOAD));
              }
            }
            setDarkMode(darkmode);
          });
        }
      } else {
        setInitValFocus(true);
      }
    } else {
      setInitValFocusEffect(true);
    }
  }, [focus]);

  useEffect (() => {
    if (initVal) {
      if (searchScreenPage > 0) {
        loadResults(true);
      } else {
        loadResults(true, false);
      }
      
    } else {
      setInitVal(true);
    }
    if (Platform.OS === 'web' && !window.location.href.includes('content-page/') && !window.location.href.includes('/bid')) {
      updateGDataLayer(userSpecifiedTag, PubCon.state.publisher.gtmId, PubCon.state.publisher.username);
    }
  }, [searchScreenPage, home]);

  useEffect (() => {
    if (initVal) {
      // Refresh ad units on page
      refreshDFPAds();
    }
  }, [free, searchScreenPage]);

  useEffect (() => {
    fetchUserAddress(state);
  }, [state.currentLocation]);

  const navToLogin = (upload, signin=false) => {
    const navToScreen = {parentNav: 'Drawer', screen: {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search'}}}};
    //const navToScreen = { parentNav: 'Home', screen: 'Search', params: {refreshUserInfo: uuidv4()}};
    if (!signin) {
      navigation.navigate('Signin/Signup', {screen: "Signup", params: { upload: upload, termsAccepted: termsAccepted, navToScreen }});
    } else {
      navigation.navigate('Signin/Signup', {screen: "Signin", params: { upload: upload, termsAccepted: termsAccepted, navToScreen }});
    }
  };

  const loadRegularResults = () => {
    setUserSpecifiedTag('');
    getResultsv2({currentFeed: [], page: 0, 
      sources: [...PubCon.state.publisher.sources, ...PubCon.state.publisher.nonPubSources], 
      targeting: PubCon.state.publisher.username, userId: user
    })
  };

  return (
    <>
      {renderPage ? 
      <>
          <View 
            style={{backgroundColor: darkMode ? '#3c3c3d' : 'white',
              width: Dimensions.get('window').width,
              height: Dimensions.get('window').height - (Platform.OS === 'web' ? 70 : 120), // NEED to have this so any vertical flatlist in the container is scrollable
              marginTop: (PubCon.state.publisher.template && PubCon.state.publisher.template.header && Platform.OS === "ios") ? -15 : null
              // Also need to subtract from it to account for the tool bar components above and thier padding
            }}
          >
            {(!results.length > 0 && !resultsTags.length > 0 && numFound !== 0 && !errorMessage && (!resultsv2.length > 0 || free))
            ? <View style={{height: Dimensions.get('window').height, backgroundColor: darkMode ? '#3c3c3d' : 'white'}}>
                <ActivityIndicator size="large" color={darkMode ? 'white' : Platform.OS ==='web' ? 'blue' : Platform.OS === 'android' ? '#005157' : 'default'} style={{ marginTop: 150 }}/>
              </View>
            : <>
              <ResultsPage
                  darkMode={darkMode}
                  results={isResultsV2 && !free ? resultsv2 : results}
                  // Important!! 
                  // The following filter is needed because if an empty string (usually comes from an empty pubTag) is in the array then
                  // it breaks viewable item tracking on mobile for some reason
                  resultsTags={resultsTags.filter(c => c !== "")}
                  numFound={numFound}
                  PubCon={PubCon}
                  free={free}
                  searchScreenPage={searchScreenPage}
                  fetchMoreContent={fetchMoreContent}
                  fetchMoreContentQueue={fetchMoreContentQueue}
                  userSpecifiedTag={userSpecifiedTag}
                  loadRegularResults={loadRegularResults}
                  headlineResults={headlineResults}
                  latestResults={latestResults}
                  userSpecifiedResults={userSpecifiedResults}
                  loading={loading}
                  currentLoadTag={currentLoadTag}
                  curSearchTxt={curSearchTxt}
                  curPageTags={curPageTags}
                  nonGreedyResults={nonGreedyResults}
                  setSearchScreenPage={setSearchScreenPage}
                  setUserSpecifiedTag={setUserSpecifiedTag}
                  totalPageNum={totalPageNum}
                  navToLogin={navToLogin}
                  isGuest={isGuest}
                  isAdmin={isAdmin}
                  pgFocus={focus}
                  refresh={refresh}
                  navigation={navigation}
                  route={route}
                  claimed={PubCon.state.publisher.claimed}
                  setAllTags={setAllTags}
                  setResults={setResults}
                  setResultsTags={setResultsTags}
                  setNonGreedyResults={setNonGreedyResults}
                  setUserSpecifiedResults={setUserSpecifiedResults}
                  setNumFound={setNumFound}
                  setFreeSearch={setFreeSearch}
                  setCurSearchTxt={setCurSearchTxt}
                  fetchRelevantUserContentTags={fetchRelevantUserContentTags}
                  updateUserSearchHistory={updateUserSearchHistory}
                  setErrorMessage={setErrorMessage}
                  errorMessage={errorMessage}
                  termsAccepted={termsAccepted}
                  home={home}
                  setHome={setHome}
                  searchHash={searchHash}
                  setSearchHash={setSearchHash}
                  businessInfo={businessInfo}
                  upload={pubUpload}
                  resultsV2={resultsv2}
                  grid={isResultsV2 && !free ? false : true}
                  isLoadingV2={isLoadingv2}
                />
              </>
              }
              { !termsAccepted
                ?<TermsOfServiceFooter 
                  darkMode={darkMode}
                  setTermsAccepted={setTermsAccepted}
                  setErrorMsg={setErrorMessage}
                />
                : null 
              }
          </View>
          
        </>
      : <View style={{height: Dimensions.get('window').height, backgroundColor: darkMode ? '#3c3c3d' : 'white'}}>
          <ActivityIndicator size={"large"} color={darkMode ? 'white' : Platform.OS ==='web' ? "#005157" : Platform.OS === 'android' ? '#005157' : 'default'} style={{marginTop: 150}}/>
        </View>
      }
    </>
  );
};

export default MeSearchHome;