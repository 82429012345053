import { useEffect, useState } from 'react';
import MeSearchAPI from '../api/MeSearch-API';
import ContentTypeEnum from '../components/utils/contentTypes';
export default () => {

  const [content, setContent] = useState([]);
  const [adContent, setAdContent] = useState([]);
  //const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(false);
  const [adLoading, setAdLoading] = useState(false);
  const [error, setError] = useState('');
  const adTypes = [ContentTypeEnum.ADVERTISEMENT, ContentTypeEnum.ADVERTISEMENTFRAMEVIEW, ContentTypeEnum.ADVERTISEMENTLINKVIEW];
 
  const [res, setRes] = useState(null);

  const getContentApi = async(curTypes, source=null, claimed=false, uploadType="Article") => {

    if (curTypes && adTypes.includes(curTypes[0])) {
      setAdLoading(true);
    } else {
      setLoading(true);
    }

    try {
      const types = curTypes;
      let response;
      if (!claimed) {
        response = await MeSearchAPI.get(
          '/api/content/my-content',
          {params: {
            types: types
          }}
        );
      } else {
        response = await MeSearchAPI.get(
          '/api/content/content-by-source',
          {params: {
            source: source
          }}
        );
      }
      // TODO: New API Route
      '/api/content/content-by-source'
      if (response) {
        const conObjs = response.data;

        if (conObjs.length > 0) {

          if (adTypes.includes(conObjs[0].type)) {
            setAdContent(conObjs);
          } else {
            if (uploadType !== "Article") {
              if (uploadType === "Offer") {
                uploadType = "Offers";
              }
              setContent(
                conObjs.filter(conObj => {
                  return conObj.meTags.includes(uploadType);
                })
              );
            } else {
              if (claimed) {
                setContent(
                  conObjs.filter(conObj => {
                    return !conObj.meTags.includes("Offers");
                  })
                );
              } else {
                setContent(conObjs);
              }
            }
            
          }
        }
      }
    } catch (err) {
      setError(err);
    }
    setAdLoading(false);
    setLoading(false);
  };

  const base64ImageToBlob = (str) => {
    fetch(str)
    .then(res => res.arrayBuffer)
    .then(arrayBuffer => {

      let file = new File([arrayBuffer], 'test');

    })
  };

  const postMediaApi = async(mediaArray) => {
    let cdnMedia = [];
    try {
      const images = mediaArray;
      const response = await MeSearchAPI.post('/api/content/upload-media-base64', {images});
      cdnMedia = response.data.uriPaths ? response.data.uriPaths : [];
    } catch (err) {
      console.log(err)
      setError(err);
    }
    return cdnMedia;
  };

  const postFileApi = async(filesArray) => {
    let cdnFiles = [];
    try {
      const files = filesArray;
      const response = await MeSearchAPI.post('/api/content/upload-files-base64', {files});
      cdnFiles = response.data.uriPaths ? response.data.uriPaths : [];
    } catch (err) {
      console.log(err)
      setError(err);
    }
    return cdnFiles;
  };
  
  return [
    content,
    adContent,
    loading,
    adLoading,
    error,
    postMediaApi,
    setError,
    getContentApi,
    postFileApi
  ];
};

