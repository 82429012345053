import React from 'react';
import ResultDetail from './GridDetail';
import GridDetailRestaurant from '../../core/GridDetailRestaurant';
import GridRow from '../../core/GridRow';
import { Platform } from 'react-native';
import { Context as PubContext } from '../../../context/PubContext';
import MeSearchAPI from '../../../api/MeSearch-API';
import ContentTypeEnum from '../../utils/contentTypes';
import { updateUsr } from '../../../screens/utils/resultClkUtils';
import TagManager from 'react-gtm-module';
import combineImpressions from '../../utils/combineImpressions';
import AdTypesEnum from '../../utils/AdUnitTypes';
import _ from 'lodash';
import SingleFeedResult from '../../core/SingleFeedResult';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

class ResultsList extends React.Component  {
  static contextType = PubContext;

  constructor(props) {
    super(props);
    this.state = {pgFocus: "", impressions: {}, imprSent: {}};
    this.handleOnViewableItemsChanged = this.handleOnViewableItemsChanged.bind(this);
    this.impressionSend = this.impressionSend.bind(this);
    this.resultClick = this.resultClick.bind(this);
  }
 
  async impressionSend(impressions) {
    try {
      const response = await MeSearchAPI.get('/api/user');
      let curImpressions = response.data.history.impressions ? response.data.history.impressions : {};
      curImpressions = _.omit(curImpressions, '_id');
      let impressionsToSend = combineImpressions(curImpressions, impressions);
      // Cap impressions at 1000 (last 1000 elements)
      impressionsToSend = Object.keys(impressionsToSend).slice(Math.max(impressionsToSend.length - 1000, 0)).reduce((result, key) => {
      result[key] = impressionsToSend[key];
      return result;
      }, {});
      this.setState({imprSent: impressionsToSend});
      await MeSearchAPI.patch(
      '/api/user', 
      { payload: { 
          history: {
              searches: response.data.history.searches,
              contentClicks: response.data.history.contentClicks,
              locations: response.data.history.locations,
              accounts: response.data.history.accounts,
              contentShareClicks: response.data.history.contentShareClicks,
              contentShares: response.data.history.contentShares,
              impressions: impressionsToSend
          }
          } 
      });
    } catch (err) {
        console.log(err);
    }
  }

  resultClick(item) {
    if ((this.context.state.publisher.viewSources.includes(item.source) ||
        this.context.state.publisher.frameViewSources.includes(item.source) || 
        item.type === ContentTypeEnum.ARTICLEFRAMEVIEW) && 
        item.type !== ContentTypeEnum.ARTICLELINKVIEW) {
        let frmView = (this.context.state.publisher.frameViewSources.includes(item.source) || item.type === ContentTypeEnum.ARTICLEFRAMEVIEW) ? true : false;
        this.props.navigation.navigate('ResultsShow', { content: item, darkMode: this.props.darkMode, frameView: frmView  });
    } else {
        if (Platform.OS === 'web') {
          let date = new Date();
          const tagManagerArgs = {
              gtmId: this.context.state.publisher.gtmId, //'GTM-59ZPF2B'
              dataLayer: {
              FullSection: 'Content',
              CanonicalUrl: `${item.body[0].link}`,
              Headline: `${item.body[0].title}`,
              Author: `${item.body[0].authors.toString()}`,
              Catagory: `${item.meTags.toString()}`,
              SubCategory: `${item.meTags.toString()}`,
              Description: `${item.body[0].description}`,
              Published: 'Yes',
              Published_short: '',
              Updated: '',
              StoryType: `${item.type}`,
              Day_of_week: `${days[date.getDay()]}`,
              Month: `${months[date.getMonth()]}`,
              Day: `${date.getDay()}`,
              Hour: `${date.getHours()}`,
              Year: `${date.getFullYear()}`, 
              Server: '', 
              Domain: `${this.context.state.publisher.domains[0]}`,
              Testing: 'Yes', 
              User: '', 
              Logged: '',
              BodyId: `${item.body_id}`,
              Publication: `${this.context.state.publisher.username}`
              },
              dataLayerName: 'DataLayer'
          }
          if (this.context.state.publisher.gtmId) {
            TagManager.initialize(tagManagerArgs);
          }
        }
        updateUsr(item, this.context)
        Platform.OS === 'web' ? window.open(item.body[0].link) : this.props.navigation.navigate('ResultsShow', { content: item, darkMode: this.props.darkMode, frameView: true  }); //Linking.openURL(item.body[0].link);
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (newProps.pgFocus !== this.props.pgFocus) {
      this.setState({imprSent: {}});
    }
  }

  async handleOnViewableItemsChanged(viewableItems, results) {
    const timeStamp = new Date().getTime();
    let curImpr = {};
    const viewableBodyIds = viewableItems.filter((vItem) => vItem.isViewable).map(v => v.item.body_id);
    const adsProds = results.filter(r => (r.type === ContentTypeEnum.ADVERTISEMENT || r.type === ContentTypeEnum.ADVERTISEMENTLINKVIEW ||
      r.type === ContentTypeEnum.ADVERTISEMENTFRAMEVIEW || r.type === ContentTypeEnum.PRODUCT || r.type === ContentTypeEnum.PRODUCTLINKVIEW || 
      r.type === ContentTypeEnum.PRODUCTFRAMEVIEW));
    adsProds.forEach(adProd => {
      if (viewableBodyIds.includes(adProd.body_id)) {
        curImpr[`${adProd.body_id}`] = { timestamp: timeStamp, count: 1 };
      }
    });
    this.setState({impressions: curImpr});
  };

  UNSAFE_componentWillMount() {
    if (this.context.state.publisher.dfpAds) {
      let tempGridAd = this.context.state.publisher.adSlots.filter(a => a.type === AdTypesEnum.GRID);
      if (tempGridAd.length > 0){
        this.setState({gridAdUnit: tempGridAd[0]});
      }
    }
  }

  render() {
    return (
      <GridRow
        navigation={this.props.navigation}
        tagBarColor={this.props.tagBarColor}
        horizontal={this.props.horizontal}
        handleOnViewableItemsChanged={this.handleOnViewableItemsChanged}
        impressionSendCallback={this.impressionSend}
        impressions={this.state.impressions}
        imprSent={this.state.imprSent}
        dfpAds={this.context.state.publisher.dfpAds}
        viewable={this.props.viewable}
        title={this.props.title}
        gridDetail={this.props.grid ? ResultDetail : SingleFeedResult}
        restaurantDetail={GridDetailRestaurant}
        context={this.context}
        resultClickCallback={this.resultClick}
        results={this.props.results}
        setHorizontal={this.props.setHorizontal}
        setCurTag={this.props.setCurTag}
        darkMode={this.props.darkMode}
        isAdmin={this.props.isAdmin}
        fetchMoreContentQueue={this.props.fetchMoreContentQueue}
        onReachedEnd={this.props.onReachedEnd}
        searchTxt={this.props.searchTxt}
        freesearchState={this.props.freesearchState}
        userSpecified={this.props.userSpecified}
        userSpecifiedTag={this.props.userSpecifiedTag}
        flatlistKey={"_id"}
        isLoading={this.props.isLoading}
        curLoadTag={this.props.curLoadTag}
        isLoadingV2={this.props.isLoadingV2}
        imageHeader={this.props.imageHeader}
        restaurantInfo={this.props.restaurantInfo ? this.props.restaurantInfo : {}}
        assets={this.props.assets}
        grid={this.props.grid}
      />
    );
  }
};

export default ResultsList;