import React, { useEffect, useContext, useState } from 'react';
import { Text, ActivityIndicator, SafeAreaView } from 'react-native';
import { Context as AuthContext } from '../context/AuthContext';
import { Context as PubContext } from '../context/PubContext';
import { Platform } from 'react-native';
import determineReferrer from './utils/determineReferrer';
import uuidv4 from 'uuid/v4';


const ResolveAuthScreen = ({navigation, route}) => {
  const ref = route.params?.ref ?? '';
  const { tryLocalSignin, state, tryKSignin } = useContext(AuthContext);
  const { addPublisher, addRef, tryLandingPage, tryLocalAccountSignin, errorMsg } = useContext(PubContext);
  const [focus, setFocus] = useState(uuidv4());

  const fetchPub = async () => {
    let referrer = determineReferrer();
    referrer = ref ? ref : referrer;
    /*******************
    * Url Repair Start *
    ********************/
    if (referrer.includes('p=')) {
      const urlComps = referrer.split('?');
      let prefix = urlComps[0];
      let urlSuffix = `${urlComps[1].replace('p\=', '')}`;
      referrer = `${prefix}${urlSuffix}`;
      if (Platform.OS === 'web') {
        let loc = window.location.href;
        let contentPG = false;
        let publisher = false;
        let bodyId = "";
        if (loc.includes('/content-page')) {
          let search = window.location.search;
          let params = new URLSearchParams(search);
          bodyId = params.get('body_id');
          bodyId = bodyId ? bodyId : "";
          loc = loc.replace('content-page/', '');
          contentPG = true;
        }
        if (loc.includes('/publisher')) {
          loc = loc.replace('publisher/', '');
          publisher = true;
        }
        const locUrlComps = loc.split('?');
        let locPrefix = locUrlComps[0];
        let locUrlSuffix = `${locUrlComps[1].replace('p\=', '')}`;
        let locUrlSuffixPrts = locUrlSuffix.split('&');
        if (locUrlSuffixPrts.length > 1) {
          if (locUrlSuffixPrts.length === 2) {
            locUrlSuffix = `${locUrlSuffixPrts[0]}/?${locUrlSuffixPrts[1].replace('?', '')}`;
          } else {
            locUrlSuffixPrts.forEach((prt, index) => {
              if (index === 0) {
                locUrlSuffix = `${locUrlSuffixPrts[0]}/`;
              } else if (index === 1) {
                locUrlSuffix += `?${locUrlSuffixPrts[index].replace('?', '')}`;
              } else {
                locUrlSuffix += `&${locUrlSuffixPrts[index]}`;
              }
            });
          }
          if (contentPG) {
            history.replaceState(null, 'body', `${locPrefix}${locUrlSuffix}${locUrlSuffix.includes('?') ? `&body_id=${bodyId}` : `/?body_id=${bodyId}`}}`);
          } else if (publisher) {
            history.replaceState(null, 'body', `${locPrefix}publisher/${locUrlSuffix}`);
          } else {
            history.replaceState(null, 'body', `${locPrefix}${locUrlSuffix}`);
          }
        } else {
          let newUrl = `${locPrefix}${locUrlSuffix}`;
          if (contentPG) {
            newUrl = `${locPrefix}${locUrlSuffix}${locUrlSuffix.includes('?') ? `&body_id=${bodyId}` : `/?body_id=${bodyId}`}}`;
          } else if (publisher) {
            newUrl = `${locPrefix}publisher/${locUrlSuffix}`;
          }
          history.replaceState(null, 'body', newUrl);
        }
      }
    }
    /*****************
    * Url Repair End *
    ******************/
    if (referrer) {
      if (referrer.includes('kiosk')) {
        await tryLocalSignin({nav: false});
        tryKSignin();
      } else {
        const nav = await tryLocalSignin({referrer, nav: false});
        const landing = await tryLandingPage({referrer});
        if (!landing) {
          await addRef({referrer});
          await addPublisher({referrer});
          await tryLocalAccountSignin();
          if (!nav) {
            navigation.navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search'}}});
          }
        }
      }
    }
    const unsubscribe = navigation.addListener('focus', () => {
      setFocus(uuidv4());
    });
    return unsubscribe;
  };

  useEffect(() => {
    fetchPub();
  }, [focus]);

  return (
    <SafeAreaView style={{flexDirection: 'row', justifyContent: 'center'}}>
      { (state.errorMessage)
        ? state.errorMessage === 'referrer does not link to any publisher' ? <Text>{`url does not resolve to any publisher`}</Text>  : <Text>{`Service Is Currently Down\:(`}</Text> 
        : <ActivityIndicator size="large" style={{ marginTop: 150 }} color="#005157"/> 
      }
    </SafeAreaView>
  );
};

export default ResolveAuthScreen;