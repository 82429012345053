const EnumeratorValidate = (value, ref) => {
  return Object.values(ref).some(val => {
    return val === value;
  });
};

const RadioValidate = (value) => {
  return Object.values(value).some(val => {
    return val;
  });
};

const EmailValidate = (value) => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(value);
};

const UrlValidate = (value) => {
  const hlink = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal|app))(:[0-9]{1,5})?(\/[a-zA-Z0-9_\-\.~]+)*(\/([a-zA-Z0-9_\-\.]*)(\?[a-zA-Z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/;
  return hlink.test(value);
};

const PhoneNumberValidate = (value) => {
  const phoneNumRegex = /^\(?([0-9]{3})\)?[-.●\ ]?([0-9]{3})[-.●]?([0-9]{4})$/;
  const trimmedVal = value.trim();
  return phoneNumRegex.test(trimmedVal);
};

const NumberValidate = (value) => {
  value = Number(value);
  return Number.isFinite(value);
};

const SetArrayElementValidate = (value, ref) => {
  return ref.includes(value);
};

const SetArrayValidate = (value, ref) => {
  return (value.length !== ref.length) 
  ? false
  : ref.every((val, index) => {
    return val === value[index];
  });
};

const DateRangeValidate = (value) => {
  const contructHoursString = (hoursObj) => {
    let openStr = hoursObj.openAM ? `${hoursObj.open}` : `${Number(hoursObj.open) + 12}`;
    let closeStr = hoursObj.closeAM ? `${hoursObj.close}` : `${Number(hoursObj.close) + 12}`;
    if (!openStr || !closeStr) {
      return '';
    }
    return `${openStr}-${closeStr}`;
  };
  const val = contructHoursString(value);
  if (val === '') {
    return true;
  }
  let comps = val.split("-");
  if (comps.length !== 2) {
    return false;
  }
  try {
    let valid = true;
    comps.forEach(comp => {
      const compNum = Number(comp);
      if (!Number.isFinite(compNum)) {
        valid = false;
      } else if (compNum > 24 || compNum < 0) {
        valid = false;
      }
    });
    return valid;
  } catch (err) {
    return false;
  }
};

const Any = (value) => {
  return true;
};

export default {
  EnumeratorValidate,
  EmailValidate,
  UrlValidate,
  PhoneNumberValidate,
  SetArrayElementValidate,
  SetArrayValidate,
  Any,
  NumberValidate,
  RadioValidate,
  DateRangeValidate
};