import React, {useState} from 'react';
import { ScrollView } from 'react-native';
import FormWrapper from '../components/wrappers/MeSearch/FormWrapper';
import BusinessSignupWrapper from '../components/wrappers/MeSearch/BusinessSignupWrapper';
import FormFieldTypesEnum from './utils/FormValidation/formFieldTypesEnum';
import BusinessTypesEnum from './utils/businessTypesEnum';
import SubmitField from './utils/FormValidation/FormValidationSubmitField';
import ValidationTypesEnum from './utils/FormValidation/formValidateTypesEnum';
import MeSearchAPI from '../api/MeSearch-API';


const BusinessSignupScreen = ({ navigation, route }) => {
  // since this is a multi-step form, include all form fields in config below.
  const genBusinessTypesOptions = () => {
    const businessTypeOptions = {};
    Object.values(BusinessTypesEnum).forEach(val => {
      businessTypeOptions[`${val}`] = false;
    });
    return businessTypeOptions;
  };

  const bizSignupForm = {
    firstName: { name: "First Name", value: "", error: "", required: true, placeholder: "", type: FormFieldTypesEnum.TEXTINPUT, step: 1 },
    lastName: { name: "Last Name", value: "", error: "", required: true, placeholder: "", type: FormFieldTypesEnum.TEXTINPUT, step: 1 },
    email: { name: "Email Address", value: "", error: "", required: true, placeholder: "name@example.com", type: FormFieldTypesEnum.TEXTINPUT, step: 1 },
    phone: { name: "Phone", value: "", error: "", required: true, placeholder: "234-567-8900", type: FormFieldTypesEnum.TEXTINPUT, step: 1 },
    // mailingAddress: { name: "Mailing Address", value: "", error: "", required: true, placeholder: "", type: FormFieldTypesEnum.TEXTINPUT, step: 1 },
    businessName: { name: "Business Name", value: "", error: "", required: true, type: FormFieldTypesEnum.VERIFIED, step: 2 },
    businessType: {name: "Business Type", value: genBusinessTypesOptions(), error: "", required: true, type: FormFieldTypesEnum.RADIO, step: 2 },
    businessStreet: {name: "Business Street", value: "", error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT, step: 2},
    businessCity: {name: "Business City", value: "", error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT, step: 2},
    businessState: {name: "Business State", value: "", error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT, step: 2},
    businessZip: {name: "Business Zip", value: "", error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT, step: 2, trim: true},
    businessCountry: {name: "Business Country", value: "", error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT, step: 2},
    businessWebsite: { name: "Business Website", value: "", error: "", required: false, type: FormFieldTypesEnum.TEXTINPUTURL, step: 2 },
    businessPhone: { name: "Business Phone Number", value: "", error: "", required: true, type: FormFieldTypesEnum.TEXTINPUT, step: 2 },
    businessLogo: { name: "Business Logo", value: "", error: "", required: false, type: FormFieldTypesEnum.IMAGEUPLOAD, step: 3 },
    businessProfilePic: { name: "Business Profile Pic", value: "", error: "", required: false, type: FormFieldTypesEnum.IMAGEUPLOAD, step: 3 },
    // businessPhotos: { name: "Business Photos", value: "", error: "", required: false, type: FormFieldTypesEnum.IMAGEPICKER, step: 2 },
    // businessSocialMedia: { name: "Business Social Media", value: "", error: "", required: false, type: FormFieldTypesEnum.SOCIALMEDIA, step: 2 },
  };

  const bizSignupValidation = {
    firstName: { validate: SubmitField(ValidationTypesEnum.ANY) },
    lastName: { validate: SubmitField(ValidationTypesEnum.ANY) },
    email: { validate: SubmitField(ValidationTypesEnum.EMAIL) },
    phone: { validate: SubmitField(ValidationTypesEnum.PHONE) },
    // mailingAddress: { validate: SubmitField(ValidationTypesEnum.ANY) },
    businessName: { validate: SubmitField(ValidationTypesEnum.ANY) },
    businessType: { validate: SubmitField(ValidationTypesEnum.RADIO) },
    businessStreet: { validate: SubmitField(ValidationTypesEnum.ANY) },
    businessCity: { validate: SubmitField(ValidationTypesEnum.ANY) },
    businessState: { validate: SubmitField(ValidationTypesEnum.ANY) },
    businessZip: { validate: SubmitField(ValidationTypesEnum.NUMBER) },
    businessCountry: { validate: SubmitField(ValidationTypesEnum.ANY) },
    businessWebsite: { validate: SubmitField(ValidationTypesEnum.URL) },
    businessPhone: { validate: SubmitField(ValidationTypesEnum.PHONE) },
    businessLogo: { validate: SubmitField(ValidationTypesEnum.URL) },
    businessProfilePic: { validate: SubmitField(ValidationTypesEnum.URL) },
    sources: { validate: SubmitField(ValidationTypesEnum.URL) },
    // businessPhotos: { validate: SubmitField(ValidationTypesEnum.ANY) },
    // businessSocialMedia: { validate: SubmitField(ValidationTypesEnum.ARRAY) },
  };

  const [step, setStep] = useState(1);
  const [multiValueIndex, setMultiValueIndex] = useState(0);

  const methods = {
    pubOwnerLookup: async account => {
      return await MeSearchAPI.get('/api/pub-owner/exists', {params: {account}});
    }
  };

  return (
    <ScrollView>
      <FormWrapper 
        Component={BusinessSignupWrapper}
        values={bizSignupForm}
        validation={bizSignupValidation}
        navigation={navigation}
        route={route}
        setStep={setStep}
        step={step}
        index={multiValueIndex}
        setIndex={setMultiValueIndex}
        methods={methods}
      />
    </ScrollView>
  );
};
    
export default BusinessSignupScreen;