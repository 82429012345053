import React from 'react';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import { Feather } from '@expo/vector-icons';
import { Dimensions, View, Text, StyleSheet, TouchableOpacity } from 'react-native';
//import React Native chart Kit for different kind of Chart
import { BarChart } from 'react-native-chart-kit';

const BarChartDash = ({ barData, title, subtitle,}) => {

    return (
      <View>
        <Text style={{fontWeight:'bold', fontFamily: 'Arial'}}>{title}</Text>
        <Text style={{color:'grey', fontFamily: 'Arial'}}>{subtitle}</Text>
        <BarChart
            data={barData}
            width={((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? Dimensions.get('window').width - 40 : 650}
            height={250}
            withCustomBarColorFromData={true} //need this parameter for it to work
            flatColor={true}
            fromZero={true}
            showValuesOnTopOfBars={true}
            showBarTops={false}
            animation={{
                durationY: 1000,
                easingY: 'EaseOutQuad',
              }}
            chartConfig={{
                backgroundGradientFrom: '#0b0317',
                backgroundGradientTo: '#0b0317',
                data: barData.datasets,
                decimalPlaces: 0,
                color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`, //base color for the bar top label
                // labelColor: () => '#000000', //color for side labels
                barRadius: 3,
                barPercentage: .3,
                style:{
                    fontFamily: 'Bogle-Regular',
                    fontSize: 10,
                },
                propsForLabels: {
                    fontFamily: 'Bogle-Regular',
                    fontSize: 10,

                }
            }}
        />
      </View>
    );
  };
   
  export default BarChartDash;