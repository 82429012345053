import React, { useState } from 'react';
import  { Input, CheckBox } from 'react-native-elements';
import { FlatList, Text, View, SafeAreaView, TextInput, TouchableOpacity, ActivityIndicator, Image, Dimensions, Switch, StyleSheet } from 'react-native';
import { Platform } from 'react-native';
import FormFieldTypesEnum from '../screens/utils/FormValidation/formFieldTypesEnum';
import { Feather } from '@expo/vector-icons';
import { launchImageLibraryAsync, MediaTypeOptions, requestMediaLibraryPermissionsAsync } from 'expo-image-picker';
import { getDocumentAsync } from 'expo-document-picker';
import { readAsStringAsync} from 'expo-file-system';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import useManagedContent from '../hooks/useManagedContent';
import HeaderButton from './core/HeaderButton';
import validateFormField, {validateMultiFormField} from '../screens/utils/FormValidation/validateFormField';
import {Picker} from '@react-native-picker/picker';
import BoolTermsEnum from '../screens/utils/FormValidation/utils/boolTermsEnum';
import DatePicker from './DatePicker';
import { ScrollView } from 'react-native-gesture-handler';


const FormField = ({type, fieldsMeta, fieldKey, navigation, validate, setFieldsMeta, setEditingField, index, navOptions, label, horizontal, methods, userExists}) => {

  const [ 
    content,
    adContent,
    loading,
    adLoading,
    error,
    postMediaApi,
    setError,
    getContentApi,
    postFileApi
  ] = useManagedContent();
  
  const [imgProcessing, setImgProcessing] = useState(false);
  const [fileProcessing, setFileProcessing] = useState(false);
  const [apiProcessing, setApiProcessing] = useState(false);
  const [customError, setCustomError] = useState("");
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showStartDate, setShowStartDate] = useState(`${fieldsMeta[`${fieldKey}`].value}`.match(/\w+(->)/g) && `${fieldsMeta[`${fieldKey}`].value}`.match(/\w+(->)/g).length > 0);
  const [showEndDate, setShowEndDate] = useState(`${fieldsMeta[`${fieldKey}`].value}`.match(/(->)\w+/g) && `${fieldsMeta[`${fieldKey}`].value}`.match(/(->)\w+/g).length > 0);
  const [dateSelection, setDateSelection] = useState({
    Before: `${fieldsMeta[`${fieldKey}`].value}`.match(/(->)\w+/g) && `${fieldsMeta[`${fieldKey}`].value}`.match(/(->)\w+/g).length > 0, 
    After: `${fieldsMeta[`${fieldKey}`].value}`.match(/\w+(->)/g) && `${fieldsMeta[`${fieldKey}`].value}`.match(/\w+(->)/g).length > 0
  });

  const weekDays = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];

  const dcFields = [
    {
      title: 'Title',
    },
    {
      title: 'Subject',
    },
    {
      title: 'Creator (Author)',
    },
    {
      title: 'Date',
    },
    {
      title: 'Publisher',
    },
    {
      title: 'Description',
    },
    {
      title: 'Type',
    },
    {
      title: 'Format',
    },
    {
      title: 'Language',
    },
  ];

  const updateFieldsMeta = (key, value) => {
    let newFieldsMeta = JSON.parse(JSON.stringify(fieldsMeta));
    newFieldsMeta[key] = value;
    setFieldsMeta(newFieldsMeta);
  };

  const handleFieldVerification = async (text) => {
    setApiProcessing(true);

    try {
      // For REFERENCE:
      // userLookup: async userEmail => {
      //   return await MeSearchAPI.get('/api/user/is-account-user', {params: {account, username: userEmail}});
      // },
      let response = null;
      if (methods.userLookup) {
        response = await methods.userLookup(text);
      } else if (methods.pubOwnerLookup) {
        response = await methods.pubOwnerLookup(text);
      } else {
        response = await methods.existingUserLookup(text);
      }

      setCustomError("");
      if (response.data.isAccountUser) {
        setCustomError("This user is already a user on this account.");
        setApiProcessing(false);
        return true;
      }

      if (response.data.exists) {
        setCustomError("This name is already in use. Please try again with a different one.");
        setApiProcessing(false);
        return true;
      }
    } catch (error) {
      if (error.response?.status === 404) {
        await methods.doesUserExist(false);
        await methods.errorResponse(error.response.data.error);
      }
    }
    setApiProcessing(false);
    return false;
  };

  const handleUploadFile = async (fieldKey, fieldObj, updateFieldsMeta) => {
      // receive file, send to API for processing on CDN.
      setFileProcessing(true);
      // TODO: HACK 
      // Expo web doesn't resolve the following promise on cancel
      if (Platform.OS === 'web') {
          setTimeout(() => {
          setFileProcessing(false);
          }, [3000])
      }
      const { uri, size } = await getDocumentAsync({ type: 'application/pdf' });
      if (uri) {
          fieldObj.error = "";
          if (size > 31457280) {
              fieldObj.error = "Files must be below 30 MB";
              updateFieldsMeta(fieldKey, fieldObj);
              setFileProcessing(false);
              return;
          }
          let pickedUri = uri
          if (Platform.OS !== 'web') {
              const content = await readAsStringAsync(uri, {encoding: "base64"});
              pickedUri = `data:application/pdf;base64,${content}`;
          }

          try {
              setFileProcessing(true);
              const result = await postFileApi([pickedUri]);
              if (result.length > 0) {
                  fieldObj.value = result[0];
              } else {
                  fieldObj.error = "Error uploading file";
              }
          } catch (error) {
              console.log(error);
              fieldObj.error = "Error uploading file";
          }
      }
  
      setFileProcessing(false);
      updateFieldsMeta(fieldKey, fieldObj);
  };
  
  const handleUploadImage = async (fieldKey, fieldObj, updateFieldsMeta, size=null) => {
      setImgProcessing(true);
      const image = await pickImage();
      fieldObj.error = "";
      if (image !== "cancelled") {
          if (image && image !== "") {
              try {
                  setImgProcessing(true);
                  const result = await postMediaApi([image]);
                  if (result.length > 0) {
                    if (size) {
                        await Image.getSize(result[0], (width, height) => {
                          if ((size.height/size.width) != (height/width)) {
                            fieldObj.error = `Image must be ${size.width}x${size.height}`;
                          } else {
                            fieldObj.value = result[0];
                          }
                          updateFieldsMeta(fieldKey, fieldObj);
                        });
                    } else {
                      fieldObj.value = result[0];
                      updateFieldsMeta(fieldKey, fieldObj);
                    }
                  } else {
                    fieldObj.error = "Error uploading file";
                    updateFieldsMeta(fieldKey, fieldObj);
                  }
              } catch (error) {
                console.log(error);
                fieldObj.error = "Error uploading file";
                updateFieldsMeta(fieldKey, fieldObj);
              }
          } else {
            fieldObj.error = "Error uploading file";
            updateFieldsMeta(fieldKey, fieldObj);
          }
      }
      setImgProcessing(false);
  };
  
  const pickImage = async () => {
      const request = await requestMediaLibraryPermissionsAsync();
      if (request.granted) {
          try {

              // TODO: HACK 
              // Expo web doesn't resolve the following promise on cancel
              if (Platform.OS === 'web') {
                  setTimeout(() => {
                    setImgProcessing(false);
                  }, [3000])
              }
              
              let result = await launchImageLibraryAsync({
                  mediaTypes: MediaTypeOptions.Images,
                  allowsEditing: true,
                  allowsMultipleSelection : false,
                  aspect: [4, 3],
                  base64: true,
              });
      
              if (!result.cancelled) {
                  let resultUri = result.uri;
                  let downSampled = false;
                  const downSizeAndCompressedImage = await manipulateAsync(
                  result.uri,
                  [],
                  { compress: 0.5, format: SaveFormat.JPEG, base64: true }
                  );
                  result = downSizeAndCompressedImage;
                  if (Platform.OS === 'web') {
                  resultUri = result.uri;
                  downSampled = true;
                  } else {
                      if (result.base64) {
                          resultUri = `data:image/jpeg;base64,${result.base64}`;
                          downSampled = true;
                      }
                  }
                  
                  if (Platform.OS !== 'web' && !downSampled) {
                  let uriParts = result.uri.split('.');
                  let ext = "";
      
                  uriParts.some(uriPart => {
                      if (uriPart.match(/(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|mp4|MP4)/)) {
                      ext = uriPart;
                      return true;
                      }
                      return false;
                  });
                  if (ext === "") {
                      // TODO:
                      // Some error logic
                  }
                  // TODO switch to data:video/mp4, 
                  resultUri = `data:image/${ext};base64,${result.base64}`;
                  }
                  return resultUri;
              } else {
                return "cancelled";
              }
          } catch (E) {
            console.log(E);
            return "";
          }
      } else {
          return "cancelled";
      }
  };

  let render = null;

  const onSubmit = async () => {
    let verified = false;
    if (fieldsMeta[fieldKey].type === FormFieldTypesEnum.VERIFIED) {
      verified = await handleFieldVerification(fieldsMeta[`${fieldKey}`].value);
    }
    // validate, and if good, then clear editingField (allowing user to go back to main form). Otherwise show error.
    if (fieldsMeta[`${fieldKey}`].type === FormFieldTypesEnum.MULTI || fieldsMeta[`${fieldKey}`].type === FormFieldTypesEnum.MULTIURL) {
      validateMultiFormField({ setter: setFieldsMeta, original: fieldsMeta, key: fieldKey, index, validations: validate });
    } else {
      validateFormField({ setter: setFieldsMeta, original: fieldsMeta, key: fieldKey, validations: validate });
    }
    if (!fieldsMeta[`${fieldKey}`].error && !verified) {
      setEditingField('');
    }
  };

  const onCancel = async () => {
    let verified = false;
    if (fieldsMeta[fieldKey].type === FormFieldTypesEnum.VERIFIED) {
      verified = await handleFieldVerification(fieldsMeta[`${fieldKey}`].value);
    }
    if (verified) {
      let newFieldObj = fieldsMeta[`${fieldKey}`];
      newFieldObj.value = "";
      updateFieldsMeta(fieldKey, newFieldObj);
    }
    setEditingField('');
  };

  React.useLayoutEffect(() => {
    if (navOptions || (typeof navOptions === 'undefined')) {
      navigation.setOptions({
        headerTitle: 'Edit ' + fieldsMeta[`${fieldKey}`].name.slice(0, Math.min(fieldsMeta[`${fieldKey}`].name.length, 12)) + `${fieldsMeta[`${fieldKey}`].name.length > 12 ? "..." : ""}`,
        headerTitleAlign:'center',
        headerRight: () => (
          <HeaderButton 
            title="Add"
            onPress={() => onSubmit()}
          />
        ),
        headerLeft: () => (
          <HeaderButton 
            title="Cancel"
            onPress={() => onCancel()}
          />
        ),
      });
    }
  }, [navigation, fieldsMeta, customError, userExists]);

  let ifFieldMeta = (
    <Input 
      value={fieldsMeta[`${fieldKey}`].value}
      // label={label}
      errorMessage={fieldsMeta ? fieldsMeta[`${fieldKey}`].error : errorMessage}
      placeholder={fieldsMeta[`${fieldKey}`].placeholder}
      onSubmitEditing={() => setEditingField('')}
      onChangeText={(text) => {
        if (fieldsMeta[`${fieldKey}`].value && fieldsMeta[`${fieldKey}`].error) {
          const clearError = fieldsMeta[`${fieldKey}`].error = "";
          updateFieldsMeta(fieldKey, clearError);
        }

        let newFieldObj = fieldsMeta[`${fieldKey}`];
        newFieldObj.value = fieldKey.toLowerCase().includes("email") ? text.toLowerCase() : text;
        updateFieldsMeta(fieldKey, newFieldObj);
      }}
    />
  );

  let regularInput = (
    <Input
      label={
        <View style={{flexDirection: 'row'}}>
          <Text style={{color: '#707070', fontWeight: '600', fontSize: 18}}>
            {fieldsMeta[`${fieldKey}`].name}
          </Text>
          {fieldsMeta[`${fieldKey}`].required ?
          <Text style={{color: 'red', paddingLeft: 5, fontWeight: '800', fontSize: 22}}>
            *
          </Text>
          : null}
        </View>
      }
      labelStyle={{color: '#707070'}}
      value={fieldsMeta[`${fieldKey}`].value}
      onChangeText={(text) => {
          if (fieldsMeta[`${fieldKey}`].trim) {
            text=text.trim();
          }
          let newFieldObj = fieldsMeta[`${fieldKey}`];
          newFieldObj.value = fieldKey.toLowerCase().includes("email") ? text.toLowerCase() : text;
          updateFieldsMeta(fieldKey, newFieldObj);
      }}
      autoCorrect={false}
      maxLength={800}
    />
  );

  if (type === FormFieldTypesEnum.TEXTINPUT) {
    render = (fieldsMeta && !label) ? ifFieldMeta : regularInput;
    return render;
  } else if (type === FormFieldTypesEnum.PASSWORD) {
    return (
      <Input
        label={
          <View style={{flexDirection: 'row'}}>
            <Text style={{color: '#707070', fontWeight: '600', fontSize: 18}}>
              {fieldsMeta[`${fieldKey}`].name}
            </Text>
            {fieldsMeta[`${fieldKey}`].required ?
            <Text style={{color: 'red', paddingLeft: 5, fontWeight: '800', fontSize: 22}}>
              *
            </Text>
            : null}
          </View>
        }
        labelStyle={{color: '#707070'}}
        value={fieldsMeta[`${fieldKey}`].value}
        onChangeText={(text) => {
            if (fieldsMeta[`${fieldKey}`].trim) {
              text=text.trim();
            }
            let newFieldObj = fieldsMeta[`${fieldKey}`];
            newFieldObj.value = fieldKey.toLowerCase().includes("email") ? text.toLowerCase() : text;
            updateFieldsMeta(fieldKey, newFieldObj);
        }}
        autoCorrect={false}
        maxLength={800}
        secureTextEntry={true}
        errorMessage={fieldsMeta[`${fieldKey}`] && fieldsMeta[`${fieldKey}`].error ? fieldsMeta[`${fieldKey}`].error: null}
      />
    );
  } else if (type === FormFieldTypesEnum.TEXTINPUTURL) {
      return (
          <Input
              label={
                  <View style={{flexDirection: 'row'}}>
                      <Text style={{color: '#707070', fontWeight: '600', fontSize: 18}}>
                      {fieldsMeta[`${fieldKey}`].name}
                      </Text>
                      {fieldsMeta[`${fieldKey}`].required ?
                      <Text style={{color: 'red', paddingLeft: 5, fontWeight: '800', fontSize: 22}}>
                      *
                      </Text>
                      : null}
                  </View>
              }
              labelStyle={{color: '#707070'}}
              value={fieldsMeta[`${fieldKey}`].value}
              onChangeText={(text) => {
                  if (fieldsMeta[`${fieldKey}`].trim) {
                      text=text.trim();
                  }
                  let newFieldObj = fieldsMeta[`${fieldKey}`];
                  newFieldObj.value = fieldKey.toLowerCase().includes("email") ? text.toLowerCase() : text;
                  updateFieldsMeta(fieldKey, newFieldObj);
              }}
              autoCorrect={false}
              autoCapitalize="none"
              maxLength={800}
              errorMessage={fieldsMeta[`${fieldKey}`] && fieldsMeta[`${fieldKey}`].error ? fieldsMeta[`${fieldKey}`].error: null}
              onSubmitEditing={(e) => {
                  if (e.nativeEvent.text !== "") {
                      if (!e.nativeEvent.text.includes('http')) {
                      let newUrl = `http://${e.nativeEvent.text}`;
                      let newFieldObj = fieldsMeta[`${fieldKey}`];
                      newFieldObj.value = newUrl;
                      updateFieldsMeta(fieldKey, newFieldObj);
                      }
                  }
              }}
              onBlur={(e) => {
                  if (e.nativeEvent.text !== "") {
                      if (!e.nativeEvent.text.includes('http')) {
                      let newUrl = `http://${e.nativeEvent.text}`;
                      let newFieldObj = fieldsMeta[`${fieldKey}`];
                      newFieldObj.value = newUrl;
                      updateFieldsMeta(fieldKey, newFieldObj);
                      }
                  }
              }}
          />
      );
  } else if (type === FormFieldTypesEnum.VERIFIED) {
    return (
      !apiProcessing ? (
        <>
        <Input
          label={
            <View style={{flexDirection: 'row'}}>
              <Text style={{color: '#707070', fontWeight: '600', fontSize: 18}}>
                {fieldsMeta[`${fieldKey}`].name}
              </Text>
              {fieldsMeta[`${fieldKey}`].required ?
              <Text style={{color: 'red', paddingLeft: 5, fontWeight: '800', fontSize: 22}}>
                *
              </Text>
              : null}
            </View>
          }
          errorMessage={customError ? customError : fieldsMeta[`${fieldKey}`] ? `${fieldsMeta[`${fieldKey}`].error}` : null}
          labelStyle={{color: '#707070'}}
          value={fieldsMeta[`${fieldKey}`].value}
          onChangeText={(text) => {
              if (fieldsMeta[`${fieldKey}`].trim) {
                text=text.trim();
              }
              let newFieldObj = fieldsMeta[`${fieldKey}`];
              newFieldObj.value = fieldKey.toLowerCase().includes("email") ? text.toLowerCase() : text;
              updateFieldsMeta(fieldKey, newFieldObj);
          }}
          autoCorrect={false}
          maxLength={800}
        />
        </>
      ) : (
        <ActivityIndicator size="large" style={{marginTop: 5}} color={Platform.OS === 'android' ? "#005157" : null}/>
      )
    );
  } else if (type === FormFieldTypesEnum.RADIO) {
    if (fieldsMeta && !horizontal) {
      return (
        <View>
          <View style={{flexDirection: 'row', paddingLeft: 10}}>
            <Text style={{color: '#707070', fontWeight: '600', fontSize: 18}}>
              {fieldsMeta[`${fieldKey}`].name}
            </Text>
            {fieldsMeta[`${fieldKey}`].required ?
              <Text style={{color: 'red', paddingLeft: 5, fontWeight: '800', fontSize: 22}}>
                *
              </Text>
            : null}
          </View>
          <View style={{flexDirection: 'row'}}>
            <FlatList
              data={Object.keys(fieldsMeta[`${fieldKey}`].value)}
              // horizontal={true}
              style={{ marginLeft:10 }}
              keyExtractor={(item) => item}
              renderItem={(obj) => {
                const radioItem = obj.item;
                return (
                  <CheckBox
                    containerStyle={{flex:1, marginLeft:-10, backgroundColor: null, borderWidth: 0}}
                    title={radioItem}
                    onPress={() => {
                        let newValObj = JSON.parse(JSON.stringify(fieldsMeta[`${fieldKey}`].value));
                        Object.keys(newValObj).forEach(valObj => {
                        if (valObj === radioItem) {
                            newValObj[`${valObj}`] = !newValObj[`${valObj}`];
                            
                        } else {
                            newValObj[`${valObj}`] = false;
                        }
                        });
                        let newFieldObj = fieldsMeta[`${fieldKey}`];
                        newFieldObj.value = newValObj;
                        updateFieldsMeta(fieldKey, newFieldObj);
                    }}
                    //checkedColor={'#007bff'}
                    checked={fieldsMeta[`${fieldKey}`].value[radioItem]}
                    checkedIcon='dot-circle-o'
                    uncheckedIcon='circle-o'
                  />
                );
              }}
            />
          </View>
        </View>
      );
    } else {
      return (
        <View>
          <View style={{flexDirection: 'row', paddingLeft: 10}}>
            <Text style={{color: '#707070', fontWeight: '600', fontSize: 18}}>
              {fieldsMeta[`${fieldKey}`].name}
            </Text>
            {fieldsMeta[`${fieldKey}`].required ?
              <Text style={{color: 'red', paddingLeft: 5, fontWeight: '800', fontSize: 22}}>
                *
              </Text>
            : null}
          </View>
          <View style={{flexDirection: 'row'}}>
            <FlatList
              data={Object.keys(fieldsMeta[`${fieldKey}`].value)}
              horizontal={true}
              style={{ marginLeft:10 }}
              keyExtractor={(item) => item}
              renderItem={(obj) => {
                const radioItem = obj.item;
                return (
                  <CheckBox
                    containerStyle={{flex:1, marginLeft:-10, backgroundColor: null, borderWidth: 0}}
                    title={radioItem}
                    onPress={() => {
                        let newValObj = JSON.parse(JSON.stringify(fieldsMeta[`${fieldKey}`].value));
                        Object.keys(newValObj).forEach(valObj => {
                        if (valObj === radioItem) {
                            newValObj[`${valObj}`] = !newValObj[`${valObj}`];
                            
                        } else {
                            newValObj[`${valObj}`] = false;
                        }
                        });
                        let newFieldObj = fieldsMeta[`${fieldKey}`];
                        newFieldObj.value = newValObj;
                        updateFieldsMeta(fieldKey, newFieldObj);
                    }}
                    //checkedColor={'#007bff'}
                    checked={fieldsMeta[`${fieldKey}`].value[radioItem]}
                    checkedIcon='dot-circle-o'
                    uncheckedIcon='circle-o'
                  />
                );
              }}
            />
            <Feather name="chevron-right" style={{fontSize: 25, paddingLeft: 8, paddingTop: 13}}/>
          </View>
        </View>
      );
    }
  } else if (type === FormFieldTypesEnum.CHECKBOX) {
    if (fieldsMeta && !horizontal) {
      return (
        <View>
          <View style={{flexDirection: 'row', paddingLeft: 10}}>
            <Text style={{color: '#707070', fontWeight: '600', fontSize: 18}}>
              {fieldsMeta[`${fieldKey}`].name}
            </Text>
            {fieldsMeta[`${fieldKey}`].required ?
              <Text style={{color: 'red', paddingLeft: 5, fontWeight: '800', fontSize: 22}}>
                *
              </Text>
            : null}
          </View>
          <View style={{flexDirection: 'row'}}>
            <FlatList
              data={Object.keys(fieldsMeta[`${fieldKey}`].value)}
              // horizontal={true}
              style={{ marginLeft:10 }}
              keyExtractor={(item) => item}
              renderItem={(obj) => {
                const checkItem = obj.item;
                return (
                  <CheckBox
                    containerStyle={{flex:1, marginLeft:-10, backgroundColor: null, borderWidth: 0}}
                    title={checkItem}
                    onPress={() => {
                        let newValObj = JSON.parse(JSON.stringify(fieldsMeta[`${fieldKey}`].value));
                        Object.keys(newValObj).forEach(valObj => {
                          if (valObj === checkItem) {
                            newValObj[`${valObj}`] = !newValObj[`${valObj}`];
                          } 
                        });
                        let newFieldObj = fieldsMeta[`${fieldKey}`];
                        newFieldObj.value = newValObj;
                        updateFieldsMeta(fieldKey, newFieldObj);
                    }}
                    checkedColor={'#007bff'}
                    checked={fieldsMeta[`${fieldKey}`].value[checkItem]}
                  />
                );
              }}
            />
          </View>
        </View>
      );
    } else {
      return (
        <View>
          <View style={{flexDirection: 'row', paddingLeft: 10}}>
            <Text style={{color: '#707070', fontWeight: '600', fontSize: 18}}>
              {fieldsMeta[`${fieldKey}`].name}
            </Text>
            {fieldsMeta[`${fieldKey}`].required ?
              <Text style={{color: 'red', paddingLeft: 5, fontWeight: '800', fontSize: 22}}>
                *
              </Text>
            : null}
          </View>
          <View style={{flexDirection: 'row'}}>
            <FlatList
              data={Object.keys(fieldsMeta[`${fieldKey}`].value)}
              horizontal={true}
              style={{ marginLeft:10 }}
              keyExtractor={(item) => item}
              renderItem={(obj) => {
                const checkItem = obj.item;
                return (
                  <CheckBox
                    containerStyle={{flex:1, marginLeft:-10, backgroundColor: null, borderWidth: 0}}
                    title={checkItem}
                    onPress={() => {
                        let newValObj = JSON.parse(JSON.stringify(fieldsMeta[`${fieldKey}`].value));
                        Object.keys(newValObj).forEach(valObj => {
                          if (valObj === checkItem) {
                            newValObj[`${valObj}`] = !newValObj[`${valObj}`];
                          }
                        });
                        let newFieldObj = fieldsMeta[`${fieldKey}`];
                        newFieldObj.value = newValObj;
                        updateFieldsMeta(fieldKey, newFieldObj);
                    }}
                    checkedColor={'#007bff'}
                    checked={fieldsMeta[`${fieldKey}`].value[checkItem]}
                  />
                );
              }}
            />
            <Feather name="chevron-right" style={{fontSize: 25, paddingLeft: 8, paddingTop: 13}}/>
          </View>
        </View>
      );
    }
  } else if (type === FormFieldTypesEnum.TOGGLE) {
    return (
      <View>
        <View style={{flexDirection: 'row', paddingLeft: 10}}>
          <Text style={{color: '#707070', fontWeight: '600', fontSize: 18}}>
            {fieldsMeta[`${fieldKey}`].name}
          </Text>
          {fieldsMeta[`${fieldKey}`].required ?
            <Text style={{color: 'red', paddingLeft: 5, fontWeight: '800', fontSize: 22}}>
              *
            </Text>
          : null}
        </View>
        <View style={{flexDirection: 'row'}}>
          <FlatList
            data={Object.keys(fieldsMeta[`${fieldKey}`].value)}
            style={{ marginLeft:10 }}
            keyExtractor={(item) => item}
            renderItem={({item}) => {
              return (
                <View style={{ flexDirection: "row", padding: 5, alignItems: 'center', justifyContent: 'space-between' }}>
                  <Text style={{ fontSize: 16 }}>{item}</Text>
                  <Switch
                    trackColor={{true: '#0682d4', false: '#838584'}}
                    value={fieldsMeta[`${fieldKey}`].value[item]}
                    onValueChange={() => {
                      let newValObj = JSON.parse(JSON.stringify(fieldsMeta[`${fieldKey}`].value));
                      Object.keys(newValObj).forEach(valObj => {
                        if (valObj === item) {
                          newValObj[`${valObj}`] = !newValObj[`${valObj}`];
                        }
                      });
                      let newFieldObj = fieldsMeta[`${fieldKey}`];
                      newFieldObj.value = newValObj;
                      updateFieldsMeta(fieldKey, newFieldObj);
                    }}
                  />
                </View>
              );
            }}
          />
        </View>
        {(fieldsMeta[`${fieldKey}`] && fieldsMeta[`${fieldKey}`].error) ? <Text style={{fontSize: 18, color: 'red', paddingBottom: 10, paddingLeft: 10, paddingTop: 10}}>{`${fieldsMeta[`${fieldKey}`].error}`}</Text> : null}
      </View>
    );
  } else if (type === FormFieldTypesEnum.DATERANGE) {
    return (
        <>
          <View style={{flexDirection: 'row', paddingLeft: 10}}>
              <Text style={{color: '#707070', fontWeight: '600', fontSize: 18}}>
              {fieldsMeta[`${fieldKey}`].name}
              </Text>
              {fieldsMeta[`${fieldKey}`].required ?
              <Text style={{color: 'red', paddingLeft: 5, fontWeight: '800', fontSize: 22}}>
              *
              </Text>
              : null}
          </View>
          <View style={{flexDirection: 'row'}}>
              <Input
                  containerStyle={{width: 50, paddingTop: 32}}
                  inputStyle={{width: 30}}
                  value={fieldsMeta[`${fieldKey}`].value.open}
                  onChangeText={(text) => {
                      if (fieldsMeta[`${fieldKey}`].trim) {
                          text=text.trim();
                      }
                      let newFieldObj = fieldsMeta[`${fieldKey}`];
                      newFieldObj.value.open = text;
                      updateFieldsMeta(fieldKey, newFieldObj);
                  }}
                  autoCorrect={false}
                  maxLength={800}
              />
              <View style={{paddingLeft: -5}}>
                  <CheckBox
                      containerStyle={{backgroundColor: null, borderWidth: 0}}
                      title={"AM"}
                      onPress={() => {
                          let newFieldObj = JSON.parse(JSON.stringify(fieldsMeta[`${fieldKey}`]));
                          newFieldObj.value.openAM = true;
                          newFieldObj.value.openPM = false;
                          updateFieldsMeta(fieldKey, newFieldObj);
                      }}
                      checked={fieldsMeta[`${fieldKey}`].value.openAM}
                      checkedIcon='dot-circle-o'
                      uncheckedIcon='circle-o'
                  />
                  <CheckBox
                      containerStyle={{backgroundColor: null, borderWidth: 0}}
                      title={"PM"}
                      onPress={() => {
                          let newFieldObj = JSON.parse(JSON.stringify(fieldsMeta[`${fieldKey}`]));
                          newFieldObj.value.openPM = true;
                          newFieldObj.value.openAM = false;
                          updateFieldsMeta(fieldKey, newFieldObj);
                      }}
                      checked={fieldsMeta[`${fieldKey}`].value.openPM}
                      checkedIcon='dot-circle-o'
                      uncheckedIcon='circle-o'
                  />
              </View>
              <Text style={{paddingTop: 45, fontSize: 18, paddingLeft: 5, paddingRight: 25}}>To</Text>
              <Input
                  containerStyle={{width: 50, paddingTop: 32}}
                  inputStyle={{width: 30}}
                  value={fieldsMeta[`${fieldKey}`].value.close}
                  onChangeText={(text) => {
                      if (fieldsMeta[`${fieldKey}`].trim) {
                          text=text.trim();
                      }
                      let newFieldObj = fieldsMeta[`${fieldKey}`];
                      newFieldObj.value.close = text;
                      updateFieldsMeta(fieldKey, newFieldObj);
                  }}
                  autoCorrect={false}
                  maxLength={800}
              />
              <View style={{paddingLeft: 10}}>
                  <CheckBox
                      containerStyle={{backgroundColor: null, borderWidth: 0}}
                      title={"AM"}
                      onPress={() => {
                          let newFieldObj = JSON.parse(JSON.stringify(fieldsMeta[`${fieldKey}`]));
                          newFieldObj.value.closeAM = true;
                          newFieldObj.value.closePM = false;
                          updateFieldsMeta(fieldKey, newFieldObj);
                      }}
                      checked={fieldsMeta[`${fieldKey}`].value.closeAM}
                      checkedIcon='dot-circle-o'
                      uncheckedIcon='circle-o'
                  />
                  <CheckBox
                      containerStyle={{backgroundColor: null, borderWidth: 0}}
                      title={"PM"}
                      onPress={() => {
                          let newFieldObj = JSON.parse(JSON.stringify(fieldsMeta[`${fieldKey}`]));
                          newFieldObj.value.closePM = true;
                          newFieldObj.value.closeAM = false;
                          updateFieldsMeta(fieldKey, newFieldObj);
                      }}
                      checked={fieldsMeta[`${fieldKey}`].value.closePM}
                      checkedIcon='dot-circle-o'
                      uncheckedIcon='circle-o'
                  />
              </View>
          </View>
      </>
    );

  } else if (type === FormFieldTypesEnum.FILEUPLOAD) {
      return (
          <View style={{flexDirection: 'row'}}>
              <Input
                  containerStyle={{flex: 0.99}}
                  label={
                      <View style={{flexDirection: 'row'}}>
                          <Text style={{color: '#707070', fontWeight: '600', fontSize: 18}}>
                            {fieldsMeta[`${fieldKey}`].name}
                          </Text>
                          {fieldsMeta[`${fieldKey}`].required ?
                          <Text style={{color: 'red', paddingLeft: 5, fontWeight: '800', fontSize: 22}}>
                            *
                          </Text>
                          : null}
                      </View>
                  }
                  labelStyle={{color: '#707070'}}
                  value={fieldsMeta[`${fieldKey}`].value}
                  onChangeText={(text) => {
                      if (fieldsMeta[`${fieldKey}`].trim) {
                          text=text.trim();
                      }
                      let newFieldObj = fieldsMeta[`${fieldKey}`];
                      newFieldObj.value = text;
                      updateFieldsMeta(fieldKey, newFieldObj);
                  }}
                  autoCorrect={false}
                  errorMessage={fieldsMeta[`${fieldKey}`] && fieldsMeta[`${fieldKey}`].error ? fieldsMeta[`${fieldKey}`].error: null}
                  maxLength={800}
                  onSubmitEditing={(e) => {
                      if (e.nativeEvent.text !== "") {
                          if (!e.nativeEvent.text.includes('http')) {
                          let newUrl = `http://${e.nativeEvent.text}`;
                          let newFieldObj = fieldsMeta[`${fieldKey}`];
                          newFieldObj.value = newUrl;
                          updateFieldsMeta(fieldKey, newFieldObj);
                          }
                      }
                  }}
                  onBlur={(e) => {
                      if (e.nativeEvent.text !== "") {
                          if (!e.nativeEvent.text.includes('http')) {
                          let newUrl = `http://${e.nativeEvent.text}`;
                          let newFieldObj = fieldsMeta[`${fieldKey}`];
                          newFieldObj.value = newUrl;
                          updateFieldsMeta(fieldKey, newFieldObj);
                          }
                      }
                  }}
              />
              {!fileProcessing ?
              <TouchableOpacity
                  style={{marginTop: 25}}
                  onPress={() => {
                      let newFieldObj = fieldsMeta[`${fieldKey}`];
                      handleUploadFile(fieldKey, newFieldObj, updateFieldsMeta);
                  }}
              >
                  <Feather 
                    name="upload" 
                    style={{
                      fontSize: 35
                    }}
                  />
              </TouchableOpacity>
              : <ActivityIndicator size="large" style={{ marginTop: 5 }} color={Platform.OS === 'android' ? "#005157" : null}/>}
          </View>
      );
  } else if (type === FormFieldTypesEnum.IMAGEUPLOAD) {
      return (
          <View style={{flexDirection: 'row'}}>
              <Input
                  containerStyle={{flex: 0.99}}
                  label={
                      <View style={{flexDirection: 'row'}}>
                          <Text style={{color: '#707070', fontWeight: '600', fontSize: 18}}>
                            {fieldsMeta[`${fieldKey}`].name}
                          </Text>
                          {fieldsMeta[`${fieldKey}`].required ?
                          <Text style={{color: 'red', paddingLeft: 5, fontWeight: '800', fontSize: 22}}>
                            *
                          </Text>
                          : null}
                      </View>
                  }
                  labelStyle={{color: '#707070'}}
                  value={fieldsMeta[`${fieldKey}`].value}
                  onChangeText={(text) => {
                      if (fieldsMeta[`${fieldKey}`].trim) {
                          text=text.trim();
                      }
                      let newFieldObj = fieldsMeta[`${fieldKey}`];
                      newFieldObj.value = text;
                      updateFieldsMeta(fieldKey, newFieldObj);
                  }}
                  autoCorrect={false}
                  errorMessage={fieldsMeta[`${fieldKey}`] && fieldsMeta[`${fieldKey}`].error ? fieldsMeta[`${fieldKey}`].error: null}
                  maxLength={800}
                  onSubmitEditing={(e) => {
                      if (e.nativeEvent.text !== "") {
                          if (!e.nativeEvent.text.includes('http')) {
                          let newUrl = `http://${e.nativeEvent.text}`;
                          let newFieldObj = fieldsMeta[`${fieldKey}`];
                          newFieldObj.value = newUrl;
                          updateFieldsMeta(fieldKey, newFieldObj);
                          }
                      }
                  }}
                  onBlur={(e) => {
                      if (e.nativeEvent.text !== "") {
                          if (!e.nativeEvent.text.includes('http')) {
                          let newUrl = `http://${e.nativeEvent.text}`;
                          let newFieldObj = fieldsMeta[`${fieldKey}`];
                          newFieldObj.value = newUrl;
                          updateFieldsMeta(fieldKey, newFieldObj);
                          }
                      }
                  }}
              />
              {!imgProcessing ?
              <TouchableOpacity
                  style={{marginTop: 25}}
                  onPress={() => {
                      let newFieldObj = fieldsMeta[`${fieldKey}`];
                      handleUploadImage(fieldKey, newFieldObj, updateFieldsMeta, fieldsMeta[`${fieldKey}`].size);
                  }}
              >
                  <Feather 
                    name="upload" 
                    style={{
                      fontSize: 35
                    }}
                  />
              </TouchableOpacity>
              : <ActivityIndicator size="large" style={{ marginTop: 5 }} color="#005157"/>}
          </View>
      );
  } else if (type === FormFieldTypesEnum.MULTI) {
    return (
      <Input 
        value={fieldsMeta[`${fieldKey}`].value[index].value}
        errorMessage={fieldsMeta ? fieldsMeta[`${fieldKey}`].error : errorMessage}
        placeholder={fieldsMeta[`${fieldKey}`].placeholder}
        onSubmitEditing={() => setEditingField('')}
        onChangeText={(text) => {
          if (fieldsMeta[`${fieldKey}`].value[index].value && fieldsMeta[`${fieldKey}`].error) {
            const clearError = fieldsMeta[`${fieldKey}`].error = "";
            updateFieldsMeta(fieldKey, clearError);
          }

          let newFieldObj = fieldsMeta[`${fieldKey}`];
          newFieldObj.value[index].value = fieldKey.toLowerCase().includes("email") ? text.toLowerCase() : text;
          updateFieldsMeta(fieldKey, newFieldObj);
        }}
      />
    );
  } else if (type === FormFieldTypesEnum.MULTIURL) {
    return (
      <Input 
        value={fieldsMeta[`${fieldKey}`].value[index].value}
        errorMessage={fieldsMeta ? fieldsMeta[`${fieldKey}`].error : errorMessage}
        placeholder={fieldsMeta[`${fieldKey}`].placeholder}
        onChangeText={(text) => {
          if (fieldsMeta[`${fieldKey}`].value[index].value && fieldsMeta[`${fieldKey}`].error) {
            const clearError = fieldsMeta[`${fieldKey}`].error = "";
            updateFieldsMeta(fieldKey, clearError);
          }

          let newFieldObj = fieldsMeta[`${fieldKey}`];
          newFieldObj.value[index].value = fieldKey.toLowerCase().includes("email") ? text.toLowerCase() : text;
          updateFieldsMeta(fieldKey, newFieldObj);
        }}
        onSubmitEditing={(e) => {
          if (e.nativeEvent.text !== "") {
            if (!e.nativeEvent.text.includes('http')) {
              let newUrl = `http://${e.nativeEvent.text}`;
              let newFieldObj = fieldsMeta[`${fieldKey}`];
              newFieldObj.value[index].value = newUrl;
              updateFieldsMeta(fieldKey, newFieldObj);
            }
          }
        }}
        autoCapitalize="none"
        onBlur={(e) => {
          if (e.nativeEvent.text !== "") {
            if (!e.nativeEvent.text.includes('http')) {
              let newUrl = `http://${e.nativeEvent.text}`;
              let newFieldObj = fieldsMeta[`${fieldKey}`];
              newFieldObj.value[index].value = newUrl;
              updateFieldsMeta(fieldKey, newFieldObj);
            }
          }
        }}
      />
    );
  } else if (type === FormFieldTypesEnum.PERIODICITY) {
    return (
      <View style={{marginLeft: 20}}>
        <View style={{flexDirection:"row", paddingBottom:20}}>
          <CheckBox
            containerStyle={{marginLeft:-10, backgroundColor: null, borderWidth: 0}}
            checkedIcon='dot-circle-o'
            uncheckedIcon='circle-o'
            title={"Once"}
            textStyle={{fontSize: 13, fontWeight: "600"}}
            checkedColor='#007bff'
            onPress={() => {
              let newValObj = JSON.parse(JSON.stringify(fieldsMeta[`${fieldKey}`].value.interval));
              Object.keys(newValObj).forEach(valObj => {
                if (valObj === "Once") {
                  newValObj[`${valObj}`] = !newValObj[`${valObj}`];
                } else {
                  newValObj[`${valObj}`] = false;
                }
              });
              let newFieldObj = fieldsMeta[`${fieldKey}`];
              newFieldObj.value.interval = newValObj;
              updateFieldsMeta(fieldKey, newFieldObj);
            }}
            checked={fieldsMeta[`${fieldKey}`].value.interval['Once']}
          /> 
          <CheckBox
            containerStyle={{marginLeft:-10, backgroundColor: null, borderWidth: 0, opacity: 0.5}}
            checkedIcon='dot-circle-o'
            uncheckedIcon='circle-o'
            title={"Daily"}
            textStyle={{fontSize: 13, fontWeight: "600"}}
            checkedColor='#007bff'
            onPress={() => {
              /*let newValObj = JSON.parse(JSON.stringify(fieldsMeta[`${fieldKey}`].value.interval));
              Object.keys(newValObj).forEach(valObj => {
                if (valObj === "Daily") {
                  newValObj[`${valObj}`] = !newValObj[`${valObj}`];
                } else {
                  newValObj[`${valObj}`] = false;
                }
              });
              let newFieldObj = fieldsMeta[`${fieldKey}`];
              newFieldObj.value.interval = newValObj;
              updateFieldsMeta(fieldKey, newFieldObj);*/
            }}
            checked={fieldsMeta[`${fieldKey}`].value.interval['Daily']}
          /> 
          <CheckBox
            containerStyle={{flex:1, marginLeft:-10, backgroundColor: null, borderWidth: 0, opacity: 0.5}}
            checkedIcon='dot-circle-o'
            uncheckedIcon='circle-o'
            title={"Weekly"}
            textStyle={{fontSize: 13, fontWeight: "600"}}
            checkedColor='#007bff'
            onPress={() => {
              /*let newValObj = JSON.parse(JSON.stringify(fieldsMeta[`${fieldKey}`].value.interval));
              Object.keys(newValObj).forEach(valObj => {
                if (valObj === "Weekly") {
                  newValObj[`${valObj}`] = !newValObj[`${valObj}`];
                } else {
                  newValObj[`${valObj}`] = false;
                }
              });
              let newFieldObj = fieldsMeta[`${fieldKey}`];
              newFieldObj.value.interval = newValObj;
              updateFieldsMeta(fieldKey, newFieldObj);*/
            }}
            checked={fieldsMeta[`${fieldKey}`].value.interval['Weekly']}
          />
        </View>
        {fieldsMeta[`${fieldKey}`].value.interval['Daily'] ?
          <>
            <Text style={{fontSize: 18, fontWeight: '600', paddingTop: 3, paddingLeft: 5}}>Repeat Daily</Text>
            <View style={{flexDirection:"row", paddingBottom:20}}>
              {Object.keys(fieldsMeta[`${fieldKey}`].value.dailyInfo).map((i) => {
                return (
                  <CheckBox
                    containerStyle={{marginLeft:-10, backgroundColor: null, borderWidth: 0}}
                    checkedIcon='dot-circle-o'
                    uncheckedIcon='circle-o'
                    title={i}
                    textStyle={{fontSize: 13, fontWeight: "600"}}
                    checkedColor='#007bff'
                    onPress={() => {
                      let newValObj = JSON.parse(JSON.stringify(fieldsMeta[`${fieldKey}`].value.dailyInfo));
                      Object.keys(newValObj).forEach(valObj => {
                        if (valObj === i) {
                          newValObj[`${valObj}`] = !newValObj[`${valObj}`];
                        } else {
                          newValObj[`${valObj}`] = false;
                        }
                      });
                      let newFieldObj = fieldsMeta[`${fieldKey}`];
                      newFieldObj.value.dailyInfo = newValObj;
                      updateFieldsMeta(fieldKey, newFieldObj);
                    }}
                    checked={fieldsMeta[`${fieldKey}`].value.dailyInfo[`${i}`]}
                    key={i}
                  />
                )
              })}
            </View>
          </>
        : null}
        {fieldsMeta[`${fieldKey}`].value.interval['Weekly'] ?
          <>
            <Text style={{fontSize: 18, fontWeight: '600', paddingTop: 3, paddingLeft: 5}}>Repeat Weekly Each</Text>
            <View style={{flexDirection:"row", paddingBottom:20}}>
              <ScrollView
                horizontal={true}
              >
                {Object.keys(fieldsMeta[`${fieldKey}`].value.weeklyInfo).map((i) => {
                  return (
                    <CheckBox
                      containerStyle={{marginLeft:-10, backgroundColor: null, borderWidth: 0}}
                      title={i}
                      textStyle={{fontSize: 13, fontWeight: "600"}}
                      checkedColor='#007bff'
                      onPress={() => {
                        let newValObj = JSON.parse(JSON.stringify(fieldsMeta[`${fieldKey}`].value.weeklyInfo));
                        Object.keys(newValObj).forEach(valObj => {
                          if (valObj === i) {
                            newValObj[`${valObj}`] = !newValObj[`${valObj}`];
                          }
                        });
                        let newFieldObj = fieldsMeta[`${fieldKey}`];
                        newFieldObj.value.weeklyInfo = newValObj;
                        updateFieldsMeta(fieldKey, newFieldObj);
                      }}
                      checked={fieldsMeta[`${fieldKey}`].value.weeklyInfo[`${i}`]}
                      key={i}
                    />
                  )
                })}
              </ScrollView>
            </View>
          </>
        : null}
        {!fieldsMeta[`${fieldKey}`].value.interval['Once'] ?
          <>
            <Text style={{fontSize: 18, fontWeight: '600', paddingTop: 3, paddingLeft: 5}}>Specified Time of Day</Text>
            <View style={{marginLeft: -45}}>
              <DatePicker
                date={fieldsMeta[`${fieldKey}`].value.time}
                mode='time'
                onChange={(event, date) => {
                  let newFieldObj = fieldsMeta[`${fieldKey}`];
                  let strDate = `${date}`;
                  if (date instanceof Date) {
                    strDate = date.toISOString();
                  } else {
                    const dateSeed = new Date().toISOString().split("T")[0] + 'T' + strDate + ':00.000Z';
                    strDate = new Date(dateSeed).toISOString();
                  }
                  newFieldObj.value.time = strDate;
                  updateFieldsMeta(fieldKey, newFieldObj);
                }}
              />
            </View>
            <Text style={{fontSize: 18, fontWeight: '600', paddingTop: 3, paddingLeft: 5}}>Repeat Until</Text>
            <View style={{marginLeft: -45}}>
              <DatePicker
                date={fieldsMeta[`${fieldKey}`].value.date}
                mode='date'
                onChange={(event, date) => {
                  let newFieldObj = fieldsMeta[`${fieldKey}`];
                  let strDate = `${date}`;
                  if (date instanceof Date) {
                    strDate = date.toISOString();
                  }
                  newFieldObj.value = strDate;
                  updateFieldsMeta(fieldKey, newFieldObj);
                }}
              />
            </View>
          </>
        : null}
      </View>
    );
  } else if (type === FormFieldTypesEnum.CALENDARRANGE) {
    return (
      <>
      <Text style={{fontWeight:"bold", fontSize:20, paddingBottom:20, marginLeft: 20}}>Specify the Date(s)</Text>
      {showStartDate ?
        <View>
          <View style={{flexDirection: 'row'}}>
            <CheckBox
              containerStyle={{flex:1, marginLeft: 20, backgroundColor: null, borderWidth: 0}}
              textStyle={{fontSize: 13, fontWeight: "600"}}
              checkedColor='#007bff'
              onPress={() => {
                if (dateSelection['After']) {
                  let newDateSelection = JSON.parse(JSON.stringify(dateSelection));
                  newDateSelection.After = false;
                  setDateSelection(newDateSelection);
                  let newFieldObj = fieldsMeta[`${fieldKey}`];
                  newFieldObj.value = newFieldObj.value.split("->").filter(f => f !== "").length > 1 
                  ? "->" + newFieldObj.value.split("->").filter(f => f !== "")[1] 
                    : newFieldObj.value.match(/(->)\w+/g)
                    ? "->" + newFieldObj.value.split("->").filter(f => f !== "")[0] 
                  : "No Date Range";
                  updateFieldsMeta(fieldKey, newFieldObj);
                  setShowStartDate(false);
                } else {
                  let newDateSelection = JSON.parse(JSON.stringify(dateSelection));
                  newDateSelection.After = true;
                  setDateSelection(newDateSelection);
                  let newFieldObj = fieldsMeta[`${fieldKey}`];
                  newFieldObj.value = newFieldObj.value.split("->").filter(f => f !== "").length > 1 
                  ? `${new Date().toISOString()}` + "->" + newFieldObj.value.split("->").filter(f => f !== "")[1] 
                    : newFieldObj.value.match(/(->)\w+/g)
                    ? `${new Date().toISOString()}` + "->" + newFieldObj.value.split("->").filter(f => f !== "")[0] 
                  : `${new Date().toISOString()}->`;
                  updateFieldsMeta(fieldKey, newFieldObj);
                  setShowStartDate(true);
                }
              }}
              checked={fieldsMeta[`${fieldKey}`].value.match(/\w+(->)/g) && fieldsMeta[`${fieldKey}`].value.match(/\w+(->)/g).length > 0}
            /> 
            <View
              style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: Math.min(Dimensions.get('window').width-20, 500), paddingTop: 15}}
            >
              <Text style={{fontSize: 16, fontWeight: '500', paddingTop: 3, paddingLeft: 5}}>After</Text>
            </View>
          </View>
          <DatePicker
            date={fieldsMeta[`${fieldKey}`].value.split("->")[0]}
            mode='date'
            onChange={(event, date) => {
              let newFieldObj = fieldsMeta[`${fieldKey}`];
              let strDate = `${date}`;
              if (date instanceof Date) {
                strDate = date.toISOString();
              }
              if (newFieldObj.value.match(/(->)\w+/g)) {
                strDate = `${strDate}->${newFieldObj.value.split("->")[1]}`;
              } else {
                strDate = `${strDate}->`;
              }
              newFieldObj.value = strDate;
              updateFieldsMeta(fieldKey, newFieldObj);
            }}
          />
        </View>
      :
      <View style={{flexDirection: 'row'}}>
        <CheckBox
          containerStyle={{flex:1, marginLeft: 20, backgroundColor: null, borderWidth: 0}}
          textStyle={{fontSize: 13, fontWeight: "600"}}
          checkedColor='#007bff'
          onPress={() => {
            if (dateSelection['After']) {
              let newDateSelection = JSON.parse(JSON.stringify(dateSelection));
              newDateSelection.After = false;
              setDateSelection(newDateSelection);
              let newFieldObj = fieldsMeta[`${fieldKey}`];
              newFieldObj.value = newFieldObj.value.split("->").filter(f => f !== "").length > 1 
                ? "->" + newFieldObj.value.split("->").filter(f => f !== "")[1] 
                  : newFieldObj.value.match(/(->)\w+/g)
                  ? "->" + newFieldObj.value.split("->").filter(f => f !== "")[0] 
                : "No Date Range";
              updateFieldsMeta(fieldKey, newFieldObj);
              setShowStartDate(false);
            } else {
              let newDateSelection = JSON.parse(JSON.stringify(dateSelection));
              newDateSelection.After = true;
              setDateSelection(newDateSelection);
              let newFieldObj = fieldsMeta[`${fieldKey}`];
              newFieldObj.value = newFieldObj.value.split("->").filter(f => f !== "").length > 1 
                ? `${new Date().toISOString()}` + "->" + newFieldObj.value.split("->").filter(f => f !== "")[1] 
                  : newFieldObj.value.match(/(->)\w+/g)
                  ? `${new Date().toISOString()}` + "->" + newFieldObj.value.split("->").filter(f => f !== "")[0] 
                : `${new Date().toISOString()}->`;
              updateFieldsMeta(fieldKey, newFieldObj);
              setShowStartDate(true);
            }
          }}
          checked={fieldsMeta[`${fieldKey}`].value.match(/\w+(->)/g) && fieldsMeta[`${fieldKey}`].value.match(/\w+(->)/g).length > 0}
        /> 
        <View
          style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: Math.min(Dimensions.get('window').width-20, 500), paddingTop: 15}}
        >
          <Text style={{fontSize: 16, fontWeight: '500', paddingTop: 3, paddingLeft: 5}}>After</Text>
        </View>
      </View>
      }
      {showEndDate ?
        <View>
          <View style={{flexDirection: 'row'}}>
            <CheckBox
              containerStyle={{flex:1, marginLeft: 20, backgroundColor: null, borderWidth: 0}}
              textStyle={{fontSize: 13, fontWeight: "600"}}
              checkedColor='#007bff'
              onPress={() => {
                if (dateSelection['Before']) {
                  let newDateSelection = JSON.parse(JSON.stringify(dateSelection));
                  newDateSelection.Before = false;
                  setDateSelection(newDateSelection);
                  let newFieldObj = fieldsMeta[`${fieldKey}`];
                  newFieldObj.value = newFieldObj.value.split("->").filter(f => f !== "").length > 1 
                  ? newFieldObj.value.split("->").filter(f => f !== "")[0] + "->"
                    : newFieldObj.value.match(/\w+(->)/g)
                    ? newFieldObj.value.split("->").filter(f => f !== "")[0] + "->"
                  : "No Date Range";
                  updateFieldsMeta(fieldKey, newFieldObj);
                  setShowEndDate(false);
                } else {
                  let newDateSelection = JSON.parse(JSON.stringify(dateSelection));
                  newDateSelection.Before = true;
                  setDateSelection(newDateSelection);
                  let newFieldObj = fieldsMeta[`${fieldKey}`];
                  newFieldObj.value = newFieldObj.value.split("->").filter(f => f !== "").length > 1 
                  ? newFieldObj.value.split("->").filter(f => f !== "")[0] + "->" + `${new Date().toISOString()}`
                    : newFieldObj.value.match(/\w+(->)/g)
                    ?  newFieldObj.value.split("->").filter(f => f !== "")[0] + "->" + `${new Date().toISOString()}`
                  : `->${new Date().toISOString()}`;
                  updateFieldsMeta(fieldKey, newFieldObj);
                  setShowEndDate(true);
                }
              }}
              checked={fieldsMeta[`${fieldKey}`].value.match(/(->)\w+/g) && fieldsMeta[`${fieldKey}`].value.match(/(->)\w+/g).length > 0}
            /> 
            <View
              style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: Math.min(Dimensions.get('window').width-20, 500), paddingTop: 15}}
            >
              <Text style={{fontSize: 16, fontWeight: '500', paddingTop: 3, paddingLeft: 5}}>Before</Text>
            </View>
          </View>
          <DatePicker
            date={fieldsMeta[`${fieldKey}`].value.split("->")[1]}
            mode='date'
            onChange={(event, date) => {
              let newFieldObj = fieldsMeta[`${fieldKey}`];
              let strDate = `${date}`;
              if (date instanceof Date) {
                strDate = date.toISOString();
              }
              if (newFieldObj.value.match(/\w+(->)/g)) {
                strDate = `${newFieldObj.value.split("->")[0]}->${strDate}`;
              } else {
                strDate = `->${strDate}`;
              }
              newFieldObj.value = strDate;
              updateFieldsMeta(fieldKey, newFieldObj);
            }}
          />
        </View>
      :
      <View style={{flexDirection: 'row'}}>
        <CheckBox
          containerStyle={{flex:1, marginLeft: 20, backgroundColor: null, borderWidth: 0}}
          textStyle={{fontSize: 13, fontWeight: "600"}}
          checkedColor='#007bff'
          onPress={() => {
            if (dateSelection['Before']) {
              let newDateSelection = JSON.parse(JSON.stringify(dateSelection));
              newDateSelection.Before = false;
              setDateSelection(newDateSelection);
              let newFieldObj = fieldsMeta[`${fieldKey}`];
              newFieldObj.value = newFieldObj.value.split("->").filter(f => f !== "").length > 1 
              ? newFieldObj.value.split("->").filter(f => f !== "")[0] + "->"
                : newFieldObj.value.match(/\w+(->)/g)
                ? newFieldObj.value.split("->").filter(f => f !== "")[0] + "->"
              : "No Date Range";
              updateFieldsMeta(fieldKey, newFieldObj);
              setShowEndDate(false);
            } else {
              let newDateSelection = JSON.parse(JSON.stringify(dateSelection));
              newDateSelection.Before = true;
              setDateSelection(newDateSelection);
              let newFieldObj = fieldsMeta[`${fieldKey}`];
              newFieldObj.value = newFieldObj.value.split("->").filter(f => f !== "").length > 1 
              ? newFieldObj.value.split("->").filter(f => f !== "")[0] + "->" + `${new Date().toISOString()}`
                : newFieldObj.value.match(/\w+(->)/g)
                ?  newFieldObj.value.split("->").filter(f => f !== "")[0] + "->" + `${new Date().toISOString()}`
              : `->${new Date().toISOString()}`;
              updateFieldsMeta(fieldKey, newFieldObj);
              setShowEndDate(true);
            }
          }}
          checked={fieldsMeta[`${fieldKey}`].value.match(/(->)\w+/g) && fieldsMeta[`${fieldKey}`].value.match(/(->)\w+/g).length > 0}
        /> 
        <View
          style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: Math.min(Dimensions.get('window').width-20, 500), paddingTop: 15}}
        >
          <Text style={{fontSize: 16, fontWeight: '500', paddingTop: 3, paddingLeft: 5}}>Before</Text>
        </View>
      </View>
      }
      </>
    )
  } else if (type === FormFieldTypesEnum.MULTIBOOL) {
    return (
      <SafeAreaView style={{justifyContent: 'center', alignItems: 'center', padding: 10}}>
        <View style={{width: Math.min(Dimensions.get('window').width-20, 500), justifyContent: 'center'}}>
          <View>
          <Text style={{fontWeight:"bold", fontSize:20, paddingBottom:20}}>Specify Boolean Operator</Text>
            <View style={{flexDirection:"row",  alignItems:'flex-start'}}>
              <View style={{flexDirection:"row", paddingBottom:20,}}>
                <CheckBox
                  containerStyle={{flex:1, marginLeft:-10, backgroundColor: null, borderWidth: 0}}
                  checkedIcon='dot-circle-o'
                  uncheckedIcon='circle-o'
                  title={BoolTermsEnum.AND}
                  textStyle={{fontSize: 13, fontWeight: "600"}}
                  checkedColor='#007bff'
                  onPress={() => {
                    let newFieldObj = fieldsMeta[`${fieldKey}`];
                    newFieldObj.value[index].logic = BoolTermsEnum.AND;
                    updateFieldsMeta(fieldKey, newFieldObj);
                  }}
                  checked={fieldsMeta[`${fieldKey}`].value[index].logic === BoolTermsEnum.AND}
                /> 
                <CheckBox
                  containerStyle={{flex:1, marginLeft:-10, backgroundColor: null, borderWidth: 0}}
                  checkedIcon='dot-circle-o'
                  uncheckedIcon='circle-o'
                  title={BoolTermsEnum.OR}
                  textStyle={{fontSize: 13, fontWeight: "600"}}
                  checkedColor='#007bff'
                  onPress={() => {
                    let newFieldObj = fieldsMeta[`${fieldKey}`];
                    newFieldObj.value[index].logic = BoolTermsEnum.OR;
                    updateFieldsMeta(fieldKey, newFieldObj);
                  }}
                  checked={fieldsMeta[`${fieldKey}`].value[index].logic === BoolTermsEnum.OR}
                /> 
                <CheckBox
                  containerStyle={{flex:1, marginLeft:-10, backgroundColor: null, borderWidth: 0}}
                  checkedIcon='dot-circle-o'
                  uncheckedIcon='circle-o'
                  title={BoolTermsEnum.NOT}
                  textStyle={{fontSize: 13, fontWeight: "600"}}
                  checkedColor='#007bff'
                  onPress={() => {
                    let newFieldObj = fieldsMeta[`${fieldKey}`];
                    newFieldObj.value[index].logic = BoolTermsEnum.NOT;
                    updateFieldsMeta(fieldKey, newFieldObj);
                  }}
                  checked={fieldsMeta[`${fieldKey}`].value[index].logic === BoolTermsEnum.NOT}
                />                       
              </View>
            </View>
            <Text style={{fontWeight:"bold", fontSize:20, paddingBottom:20}}>Specify Textual Criteria { showAdvanced ? "(Advanced)" : null }</Text>
            <View style={{flexDirection:"row",  justifyContent:'space-between', alignItems:'center'}}>
              <Text style={{fontWeight:"bold", fontSize:18, paddingBottom:10}}>"Must Have" Text</Text>
              {/*<Text style={{color:"#0984e3", top:-5}}
                onPress={() => { showAdvanced ? setShowAdvanced(false) : setShowAdvanced(true) }}
              >{ showAdvanced ? "Simple" : "Advanced" }</Text>*/}
            </View>
            <View style={styles.textArea}>
            <TextInput
              style={{ flex:1, textAlignVertical: 'top', backgroundColor: '#e5e9ed', padding:10, color: 'black' }}
              value={fieldsMeta[`${fieldKey}`].value[index].value.terms ? fieldsMeta[`${fieldKey}`].value[index].value.terms.join("\n") : ""}
              placeholder={'Enter one or more textual criteria separated by return <CR> or semi-colon (:)\n\nExamples ' + 
                'of Textual Criteria\n\n1. Single Word eg.biden\n2. Phrase eg."president elect biden"\n3. Any Word in Phrase eg. president elect ' + 
                'biden\n4. Proximity eg. president elect /10 First 100 days\n5. Logical AND eg. ' +
                '"presidential" and "vice president"\n6. Logical OR eg. "president" or "vice president"'}
              placeholderTextColor='silver'
              multiline={true}
              onChangeText={(text) => {
                text = text.replace(/\“/g, '\"').replace(/\„/g, '\"').replace(/\”/g, '\"');
                let newTermPrts = text.split("\n");
                let newTerms = [];
                if (fieldsMeta[`${fieldKey}`].trim) {
                  newTermPrts.forEach(nt => {
                    const newTerm = nt.trim();
                    newTerms.push(newTerm);
                  });
                } else {
                  newTerms = newTermPrts;
                }
                let newFieldObj = fieldsMeta[`${fieldKey}`];
                newFieldObj.value[index].value.terms = newTerms;
                updateFieldsMeta(fieldKey, newFieldObj);
              }}
            />
            </View>
            {showAdvanced ?
              <View>
              <View style={{flexDirection:"row",  justifyContent:'space-between', alignItems:'center'}}>
              <Text style={{fontWeight:"bold", fontSize:18, paddingBottom:10, paddingTop:10}}>"Near" Text</Text>
              <View style={{flexDirection:"row", justifyContent: 'flex-end',}}>
                <Text style={{paddingRight:10, marginTop: 10}}>Proximity</Text>
                <TextInput
                  style={{ width: 50, textAlignVertical: 'top', backgroundColor: 'white', padding:10, color: 'black' }}
                  value={(fieldsMeta[`${fieldKey}`].value[index].value.nearTerms && fieldsMeta[`${fieldKey}`].value[index].value.nearTerms.proximity) 
                    ? fieldsMeta[`${fieldKey}`].value[index].value.nearTerms.proximity 
                    : ""
                  }
                  placeholder={'5'}
                  placeholderTextColor='silver'
                  keyboardType={'numeric'}
                  onChangeText={(text) => {
                    let newFieldObj = fieldsMeta[`${fieldKey}`];
                    if (!newFieldObj.value[index].value.nearTerms) {
                      newFieldObj.value[index].value.nearTerms = {};
                    }
                    newFieldObj.value[index].value.nearTerms.proximity = text.trim();
                    updateFieldsMeta(fieldKey, newFieldObj);
                  }}
                />
              </View>
              </View>
              <View style={styles.textAreaShort}>
              <TextInput
                style={{ flex:1, textAlignVertical: 'top', backgroundColor: '#e5e9ed', padding:10, color: 'black' }}
                value={(fieldsMeta[`${fieldKey}`].value[index].value.nearTerms && fieldsMeta[`${fieldKey}`].value[index].value.nearTerms.terms) 
                  ? fieldsMeta[`${fieldKey}`].value[index].value.nearTerms.terms.join("\n") 
                  : ""
                }
                placeholder={'Enter one or more textual criteria separated by return <CR> or semi-colon (:)'}
                placeholderTextColor='silver'
                multiline={true}
                onChangeText={(text) => {
                  text = text.replace(/\“/g, '\"').replace(/\„/g, '\"').replace(/\”/g, '\"');
                  let newTermPrts = text.split("\n");
                  let newTerms = [];
                  if (fieldsMeta[`${fieldKey}`].trim) {
                    newTermPrts.forEach(nt => {
                      const newTerm = nt.trim();
                      newTerms.push(newTerm);
                    });
                  } else {
                    newTerms = newTermPrts;
                  }
                  let newFieldObj = fieldsMeta[`${fieldKey}`];
                  if (!newFieldObj.value[index].value.nearTerms) {
                    newFieldObj.value[index].value.nearTerms = {};
                  }
                  newFieldObj.value[index].value.nearTerms.terms = newTerms;
                  updateFieldsMeta(fieldKey, newFieldObj);
                }}
              />
              </View>
              <View style={{flexDirection:"row",  justifyContent:'space-between', alignItems:'center'}}>
              <Text style={{fontWeight:"bold", fontSize:18, paddingBottom:10, paddingTop:10}}>"Exclude" Text</Text>
              <View style={{flexDirection:"row", justifyContent: 'flex-end',}}>
                <Text style={{paddingRight:10, marginTop: 10}}>Proximity</Text>
                <TextInput
                  style={{ width: 50, textAlignVertical: 'top', backgroundColor: 'white', padding:10, color: 'black' }}
                  value={(fieldsMeta[`${fieldKey}`].value[index].value.excludeTerms && fieldsMeta[`${fieldKey}`].value[index].value.excludeTerms.proximity) 
                    ? fieldsMeta[`${fieldKey}`].value[index].value.excludeTerms.proximity 
                    : ""
                  }
                  placeholder={'5'}
                  placeholderTextColor='silver'
                  keyboardType={'numeric'}
                  onChangeText={(text) => {
                    let newFieldObj = fieldsMeta[`${fieldKey}`];
                    if (!newFieldObj.value[index].value.excludeTerms) {
                      newFieldObj.value[index].value.excludeTerms = {};
                    }
                    newFieldObj.value[index].value.excludeTerms.proximity = text.trim();
                    updateFieldsMeta(fieldKey, newFieldObj);
                  }}
                />
              </View>
              </View>
              <View style={styles.textAreaShort}>
              <TextInput
                style={{ flex:1, textAlignVertical: 'top', backgroundColor: '#e5e9ed', padding:10, color: 'black' }}
                value={(fieldsMeta[`${fieldKey}`].value[index].value.excludeTerms && fieldsMeta[`${fieldKey}`].value[index].value.excludeTerms.terms) 
                  ? fieldsMeta[`${fieldKey}`].value[index].value.excludeTerms.terms.join("\n") 
                  : ""
                }
                placeholder={'Enter one or more textual criteria separated by return <CR> or semi-colon (:)'}
                placeholderTextColor='silver'
                multiline={true}
                onChangeText={(text) => {
                  text = text.replace(/\“/g, '\"').replace(/\„/g, '\"').replace(/\”/g, '\"');
                  let newTermPrts = text.split("\n");
                  let newTerms = [];
                  if (fieldsMeta[`${fieldKey}`].trim) {
                    newTermPrts.forEach(nt => {
                      const newTerm = nt.trim();
                      newTerms.push(newTerm);
                    });
                  } else {
                    newTerms = newTermPrts;
                  }
                  let newFieldObj = fieldsMeta[`${fieldKey}`];
                  if (!newFieldObj.value[index].value.excludeTerms) {
                    newFieldObj.value[index].value.excludeTerms = {};
                  }
                  newFieldObj.value[index].value.excludeTerms.terms = newTerms;
                  updateFieldsMeta(fieldKey, newFieldObj);
                }}
              />
              </View>
            </View>
            :null}
          </View>                      
        </View>
        {/*showTags
        ?
        <>
          <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: Math.min(Dimensions.get('window').width-20, 500), paddingTop: 15}}>
            <Text style={{fontWeight:"bold", fontSize:20, paddingBottom:20}}>Specify Tags</Text>
          </View>
          <TouchableOpacity 
            style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: Math.min(Dimensions.get('window').width-20, 500), paddingTop: 15}}
            onPress={() => setShowTags(!showTags)}
          >
            <Text style={{fontSize: 16, fontWeight: '500', paddingTop: 3}}>Tags</Text>
            <Feather name="chevron-down" size={24} color="#666"/>
          </TouchableOpacity>
          <View style={{width: Math.min(Dimensions.get('window').width-20, 500), justifyContent: 'center'}}>
            <View style={{height: Math.min(Dimensions.get('window').height-10, 550), paddingVertical: 10}}>
            <View style={{ flexDirection: "row", justifyContent:'flex-end', paddingRight:15}}>
              <View style={{ flexDirection: "row", width: 200,}}>
                <Feather name="search" size={24} color="#666" style={{paddingRight:5}} />
                  <TextInput
                    style={{
                      flex: 1,
                      backgroundColor:'white'
                    }}
                    placeholder="search"
                    onChangeText={(txt) => {
                      setSearchTxt(txt);
                    }}
                  />
                <Feather name="chevron-right" size={24} color="#666" />
              </View>
            </View> 
    
            <View style={{ flexDirection: "row", justifyContent:'space-between', paddingTop:20}}>
              <Text style={{color:'#2089dc', fontWeight:'bold'}}>Clear</Text><Text style={{color:'#2089dc', fontWeight:'bold'}}>Select All</Text>
            </View>
            <FlatList
              data={dcFields}
              renderItem={({item, index}) => {
              return (
                <View style={{ flexDirection: "row", alignItems:'center', justifyContent:'space-between'}}>
                  <CheckBox 
                    title={item.title}
                    checked={true}
                    style={{width:'100%'}}
                  />
                </View>
                )
              }}
              keyExtractor={item => item.title}
            />
            <View style={{ flexDirection: "row", justifyContent:'space-between', paddingTop:10}}>
              <Text style={{color:'#2089dc', fontWeight:'bold'}}>More</Text>
            </View>
            </View>
          </View>
        </>
        :
        <>
        <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: Math.min(Dimensions.get('window').width-20, 500), paddingTop: 15}}>
          <Text style={{fontWeight:"bold", fontSize:20, paddingBottom:20}}>Specify Tags</Text>
        </View>
        <TouchableOpacity 
          style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: Math.min(Dimensions.get('window').width-20, 500), paddingTop: 15}}
          onPress={() => setShowTags(!showTags)}
        >
          <Text style={{fontSize: 16, fontWeight: '500', paddingTop: 3}}>Tags</Text>
          <Feather name="chevron-right" size={24} color="#666"/>
        </TouchableOpacity>
        </>
        */}
      </SafeAreaView>
    );
  } else {
    return null;
  }
};

const styles = StyleSheet.create({
  errorMessage: {
    fontSize: 14, 
    color: 'red', 
    paddingBottom: 10, 
    paddingLeft: 10
  },
  headerButtonText: {
    color: '#24627a',
    fontWeight: '700',
    fontSize: 16
  },
  textArea: {
      height: 280,
      marginLeft:10,
      justifyContent: "flex-start",
      borderColor: 'silver', borderStyle:'dashed', borderWidth: 2
  },
  textAreaShort: {
    height: 100,
    marginLeft:10,
    justifyContent: "flex-start",
    borderColor: 'silver', borderStyle:'dashed', borderWidth: 2
  },
});

export default FormField;