import React, { useContext, useState } from 'react';
import { View, StyleSheet, Dimensions, Image } from 'react-native';
import  { Text, Button, Input } from 'react-native-elements';
import NavLink from '../components/NavLink';
import { Context as PubContext } from '../context/PubContext';
import Spacer from '../components/Space';
import CopyrightFooter from '../components/CopyrightFooter';

const PubSigninScreen = () => {
    const { state, clearErrorMessage, signup, errorMsg  } = useContext(PubContext);
    const [err, setErr] = useState(null);
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ sources, setSources] = useState('');

    return (
        <View style={styles.container}>
        <Spacer/>
        <Input 
            label="Username"
            value={username}
            onChangeText={(newUsername) => setUsername(newUsername)}
            autoCapitalize="none"
            autoCorrect={false}
            maxLength={80}
        />
        <Spacer/>
        <Input
            secureTextEntry={true}
            label="Password"
            value={password}
            onChangeText={(newPassword) => setPassword(newPassword)}
            autoCapitalize="none"
            autoCorrect={false}
            maxLength={60}
        />
        <Spacer/>
        <Input 
            label="Sources (Comma Separated)"
            value={sources}
            onChangeText={(newSources) => setSources(newSources)}
            autoCapitalize="none"
            autoCorrect={false}
            maxLength={300}
        />
        <Spacer/>
        {err ? <Text style={styles.errorMessage}>{err}</Text> : null}
        {state.errorMsg ? <Text style={styles.errorMessage}>{state.errorMsg}</Text> : null}
        <Spacer>
            <Button 
            title={"Sign Up"}
            onPress={() => { 
              if (username === '' || password === '') {
                setErr("Must have a username and password")
                return
              }
              let sourcesArr = []
              if (sources !== '') {
                sourcesArr = sources.split(',')
              }
              signup({ username, password, sources: sourcesArr })
            }}
            />
        </Spacer>
        {/*<Spacer>
            <Button 
            title={"Back"}
            onPress={() => navigate('Search')}
            />
        </Spacer>*/}
        <View style={{width: 280}}>
            <NavLink
            text="Already have a Publisher Account? Sign in instead!"
            routeName="PubSignin"
            />
        </View>
        {/* <View style={{flexDirection: 'row', justifyContent: 'center'}}>
            <View style={{height: 150, width: 280, marginBottom: 5, padding: 10}}>
                <Image style={styles.image} source={require('../../assets/web_footer_copyright.png')}/>
            </View>
        </View> */}
        <CopyrightFooter />
        </View>
    );
};

// TODO: DEPRECATED
/*PubSigninScreen.navigationOptions = {
  header: null
};*/

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
    paddingRight: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
    marginBottom: 30
  },
  image: {
    width: null,
    height: null,
    marginTop: 5,
    marginBottom: 5,
    resizeMode: 'contain',
    flex: 1
  },
  errorMessage: {
    fontSize: 18,
    color: 'red',
    marginTop: 15,
    marginLeft: 15
  },
});

export default PubSigninScreen;