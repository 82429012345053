import React from 'react';
import { View } from 'react-native';
import Welcome from '../components/Welcome';

const WelcomeScreen = ({ navigation, route }) => {
  return (
    <View>
      <Welcome 
        navigation={navigation}
        route={route}
      />
    </View>
  );
};

export default WelcomeScreen;