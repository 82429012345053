const CombineImpressions = (imprOne, imprTwo) => {
  Object.keys(imprTwo).forEach(impr => {
    if (!imprOne[`${impr}`]) {
     imprOne[`${impr}`] = { timestamp: imprTwo[`${impr}`].timestamp, count: imprTwo[`${impr}`].count };
     } else {
      imprOne[`${impr}`] = { timestamp: imprTwo[`${impr}`].timestamp, count: (imprTwo[`${impr}`].count + imprOne[`${impr}`].count) };
     };
   });
   return imprOne;
};
  
export default CombineImpressions;