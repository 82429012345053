import SystemCapabilitiesEnum from './SystemCapabilities';

const checkCapabilityGroup = accLvl => {
  switch (accLvl) {
    // Owner
    case 5:
      return [SystemCapabilitiesEnum.ADMIN, SystemCapabilitiesEnum.UPLOAD, SystemCapabilitiesEnum.DOWNLOAD];
    
    // Admin - Pro
    case 4:
      return [SystemCapabilitiesEnum.ADMIN, SystemCapabilitiesEnum.UPLOAD, SystemCapabilitiesEnum.DOWNLOAD, SystemCapabilitiesEnum.API];
    
    // Admin - Basic
    case 3:
      return [SystemCapabilitiesEnum.UPLOAD, SystemCapabilitiesEnum.DOWNLOAD];
    
    // Subscriber - Pro
    case 2:
      return [SystemCapabilitiesEnum.UPLOAD, SystemCapabilitiesEnum.DOWNLOAD];
    
    // Subscriber - Basic
    case 1:
      return [SystemCapabilitiesEnum.UPLOAD, SystemCapabilitiesEnum.DOWNLOAD];
    
    // Guest
    case 0:
      return [SystemCapabilitiesEnum.DOWNLOAD];
    
    default:
      return [];
  }
};

export default checkCapabilityGroup;