import React, { useContext, useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions, Image, FlatList, TouchableOpacity, ScrollView } from 'react-native';
import  { Text, Button, Input } from 'react-native-elements';
import NavLink from '../components/NavLink';
import { Context as PubContext } from '../context/PubContext';
import Spacer from '../components/Space';
import MeSearchAPI from '../api/MeSearch-API';
import CopyrightFooter from '../components/CopyrightFooter';


const PublisherHomeScreen = ({navigation, route}) => {
    const pubOwner = route.params?.pubOwner ?? '';
    const { state, clearErrorMessage, signin, signout  } = useContext(PubContext);
    const [err, setErr] = useState(null);
    const [errAd, setErrAd] = useState(null);
    const [successMsgAd, setSuccessMsgAd] = useState(null);
    const [errRem, setErrRem] = useState(null);
    const [successMsgRem, setSuccessMsgRem] = useState(null);
    const [usernameAd, setUsernameAd] = useState('');
    const [usernameRem, setUsernameRem] = useState('');
    const [publications, setPublications] = useState([]);
    const [landingPages, setLandingPages ] = useState([]);

    const fetchPublications = async () => {
        try {
          let publications = await MeSearchAPI.get(
            '/api/pub-owner/publications', {params: {pubOwner}});
          setPublications(publications.data);
        } catch (err) {
            setErr(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`);
        }
    };

    const fetchLandingPages = async () => {
      try {
        let pubOwnRes = await MeSearchAPI.get(
          '/api/pub-owner', {params: {pubOwner}});
        if (pubOwnRes.data.landingPage) {
          let landingPgs = pubOwnRes.data.landingPage.map(p => p.domains[0]);
          setLandingPages(landingPgs);
        }
      } catch (err) {
          setErr(`${err.response ? err.response.data.error : 'An error occured please try again later'}`);
      }
    };

    useEffect(() => {
      fetchPublications();
      fetchLandingPages();
    }, []);

    return (
        <ScrollView style={styles.container}>
        <View style={{flexDirection: 'row', justifyContent: 'center'}}>
          <Text style={styles.largeHeaderStyle}>{`${pubOwner}`}</Text>
        </View>
        <Spacer/>
        <Text style={styles.headerStyle}>Add an Admin:</Text>
        <Input 
            label="Username"
            value={usernameAd}
            onChangeText={(newUsername) => setUsernameAd(newUsername)}
            autoCapitalize="none"
            autoCorrect={false}
            maxLength={80}
        />
        <Spacer/>
        <Text style={styles.headerStyle}>Remove an Admin:</Text>
        <Input 
            label="Username"
            value={usernameRem}
            onChangeText={(newUsername) => setUsernameRem(newUsername)}
            autoCapitalize="none"
            autoCorrect={false}
            maxLength={80}
        />
        <Spacer/>
        <Text style={styles.headerStyle}>Publications:</Text>
        <FlatList
          style={{height: 120}}
          data={publications}
          keyExtractor={(pub) =>  pub._id}
          renderItem={({ item }) => {
            return (
              <TouchableOpacity
                style={{paddingBottom: 10, width: 105}}
                onPress={() => {
                  navigation.navigate('Publication', { screen: 'PubHome', params: {pub: item.username, publisher: true, pubOwner}});
                }}
              >
               <Text style={styles.pubListItem}>{`${item.username}`}</Text>
              </TouchableOpacity>
            );
          }}
        />
        <Spacer/>
        <Text style={styles.headerStyle}>Landing Pages:</Text>
        <FlatList
          style={{height: landingPages.length > 1 ? 120 : 60}}
          data={landingPages}
          keyExtractor={(lp) =>  lp}
          renderItem={({ item }) => {
            return (
              <TouchableOpacity
                style={{paddingBottom: 10, width: 280}}
                onPress={() => {
                  navigation.navigate('EditLandingPage', {pubOwner, landDomain: item});
                }}
              >
               <Text style={styles.pubListItem}>{`${item}`}</Text>
              </TouchableOpacity>
            );
          }}
        />
        <Spacer/>
        <TouchableOpacity
          style={{paddingBottom: 10, width: 280}}
          onPress={() => {
            navigation.navigate('CreateLandingPage', {pubOwner});
          }}
        >
          <Text style={styles.createPubStyle}>Create A New Landing Page</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{paddingBottom: 10, width: 220}}
          onPress={() => {
            navigation.navigate('CreatePublication', {pubOwner});
          }}
        >
          <Text style={styles.createPubStyle}>Create A New Publication</Text>
        </TouchableOpacity>
        {err ? <Text style={styles.errorMessage}>{err}</Text> : null}
        {errAd ? <Text style={styles.errorMessage}>{errAd}</Text> : null}
        {successMsgAd ? <Text style={styles.successMessage}>{successMsgAd}</Text> : null}
        {errRem ? <Text style={styles.errorMessage}>{errRem}</Text> : null}
        {successMsgRem ? <Text style={styles.successMessage}>{successMsgRem}</Text> : null}
        <Spacer>
            <Button 
            title={"Submit"}
            onPress={async () => {
                setErrAd('');
                setSuccessMsgAd('');
                setErrRem('');
                setSuccessMsgRem('');
                let usrAd = usernameAd;
                let usrRem = usernameRem;
                setUsernameAd('');
                setUsernameRem('');
                if (usrAd) {
                    try {
                    await MeSearchAPI.post(
                        '/api/user/change-access-lvl',
                        { username: usrAd, pub: pubOwner, newLvl: 4, pubOwner });
                        setSuccessMsgAd('Succesfully added user as admin!');
                    } catch (err) {
                        setErrAd(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`);
                    }
                }
                if (usrRem) {
                    try {
                    await MeSearchAPI.post(
                        '/api/user/change-access-lvl', 
                        { username: usrRem, pub: pubOwner, newLvl: 1, pubOwner });
                        setSuccessMsgRem('Succesfully removed user\'s admin status!');
                    } catch (err) {
                        setErrRem(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`);
                    }
                }
            }}
            />
        </Spacer>
        <Spacer>
            <Button 
            title={"Sign Out"}
            onPress={() => signout()}
            />
        </Spacer>
        <View style={{width: 215}}>
        <NavLink
          text="Edit Publisher Account"
          routeName="PublisherEdit"
          params={{pub: pubOwner}}
        />
        </View>
        {/* <View style={{flexDirection: 'row', justifyContent: 'center'}}>
            <View style={{height: 150, width: 280, marginBottom: 5, padding: 10}}>
                <Image style={styles.image} source={require('../../assets/web_footer_copyright.png')}/>
            </View>
        </View> */}
        <CopyrightFooter />
        </ScrollView>
    );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
    paddingRight: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
    marginBottom: 20
  },
  image: {
    width: null,
    height: null,
    marginTop: 5,
    marginBottom: 5,
    resizeMode: 'contain',
    flex: 1
  },
  errorMessage: {
    fontSize: 18,
    color: 'red',
    marginTop: 15,
    marginLeft: 15
  },
  successMessage: {
    fontSize: 18,
    color: 'green',
    marginTop: 15,
    marginLeft: 15
  },
  headerStyle: {
    fontSize: 22,
    fontWeight: '300',
    margin: 10
  },
  largeHeaderStyle: {
    fontSize: 26,
    fontWeight: '300',
    margin: 10
  },
  pubListItem: {
    fontSize: 16,
    fontWeight: '400',
    paddingLeft: 10,
    paddingBottom: 5,
    color: 'gray'

  },
  createPubStyle: {
    fontSize: 18,
    fontWeight: '400',
    paddingLeft: 10,
    paddingBottom: 5,
    color: 'blue'

  },
});

export default PublisherHomeScreen;