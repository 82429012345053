import React, { useContext } from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { Context as PubContext } from '../context/PubContext';
import SettingsScreen from '../screens/SettingsScreen';
import DrawerMenu from '../components/Drawer/DrawerMenu';
import mainFlow from './stackNavigators/mainFlow';
// import loginFlow from './stackNavigators/loginFlow';
import authFlow from './stackNavigators/authFlow';

const Drawer = createDrawerNavigator();

const drawerNavigator = () => {
  const  PubCon = useContext(PubContext);
  return (
    <Drawer.Navigator
      initialRouteName={'Home'}
      drawerPosition={'left'}
      drawerContent={(props) => {
        return PubCon.state.publisher ? <DrawerMenu navigation={props.navigation}/> : null;
      }}
      screenOptions={{
        title: "MeSearch"
      }}
    >
      <Drawer.Screen
        name="Home"
        component={mainFlow}
      />
      <Drawer.Screen
        name="Settings"
        component={SettingsScreen}
      />
      {/* <Drawer.Screen
        name="Signin/Signup"
        component={loginFlow}
      /> */}
      <Drawer.Screen  // TODO depreciate existing loginflow
        name="Signin/Signup"
        component={authFlow}
      />
    </Drawer.Navigator>
  );
};

export default drawerNavigator;