import React, { useContext } from 'react';
import removeSpecialCharacters from '../../../screens/utils/removeSpecialCharacters';
import { Context as PubContext } from '../../../context/PubContext';
import ContentTypeEnum from '../../utils/contentTypes';
import GridDetail from '../../core/GridDetail';


const ResultDetail = ({ result, horizontal }) => {
  const  PubCon = useContext(PubContext);

  const determineTitlePrefix = (item) => {
    if ((PubCon.state.publisher.sources.includes(item.source) && (PubCon.state.publisher.owner !== 'MeSearchAI' || item.source.includes('-UGC'))) || item.meTags.includes("Offers")) {
      return removeSpecialCharacters(item.body[0].title);
    } else {
      return `\"${removeSpecialCharacters(item.body[0].title)}\" ${item.source} Reports`;
    }
  }

  const determineStampImage = (type) => {
    if (type === ContentTypeEnum.ADVERTISEMENT || type === ContentTypeEnum.ADVERTISEMENTFRAMEVIEW || type === ContentTypeEnum.ADVERTISEMENTLINKVIEW) {
      return require('../../../../assets/ad_content.png');
    }
    if (type === ContentTypeEnum.PRODUCT || type === ContentTypeEnum.PRODUCTFRAMEVIEW || type === ContentTypeEnum.PRODUCTLINKVIEW) {
      return require('../../../../assets/product_content.png');
    }
    if (type === ContentTypeEnum.EVENT || type === ContentTypeEnum.EVENTFRAMEVIEW || type === ContentTypeEnum.EVENTLINKVIEW) {
      return require('../../../../assets/events_content.png');
    }
    if (type === ContentTypeEnum.SPONSORED || type === ContentTypeEnum.SPONSOREDFRAMEVIEW || type === ContentTypeEnum.SPONSOREDLINKVIEW) {
      return require('../../../../assets/sponsored_content.png');
    }
  }

  const isPointPark = ((src) => {
    return [
      'PointParkUniversity-UGC',
      'PPUGlobe-UGC',
      'PPUGlobe',
      'Point Park News Service-UGC',
      'Point Park News Service',
      'PointParkNewsService-UGC',
    ].includes(src);
  })(result.source)

  return (
    <GridDetail
      title={determineTitlePrefix(result)} 
      srcStampImg={PubCon.state.publisher.badgeSourceMap[result.source]}
      typeStampImg={determineStampImage(result.type)}
      defaultImage={PubCon.state.publisher.badgeSourceMap[result.source]}
      byline={`By: ${result.body[0].authors.toString()}`}
      userGen={result.userGen ? result.userGen : null}
      media={result.body[0].media.length > 0 ? result.body[0].media : PubCon.state.publisher.badgeSourceMap[result.source]}
      type={result.type}
      horizontal={horizontal}
      isPointPark={isPointPark}
    />
  );
};

export default React.memo(ResultDetail);