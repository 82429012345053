import React, {useState, useEffect, useContext} from 'react';
import { SafeAreaView, View, Text, Image, ActivityIndicator, TouchableOpacity, FlatList, StyleSheet, Dimensions } from 'react-native';
import { HeaderBackButton } from '@react-navigation/stack';
import valErrorExist from '../../../screens/utils/FormValidation/utils/valErrorsExist';
import validateFormField from '../../../screens/utils/FormValidation/validateFormField';
import FormFieldTypesEnum from '../../../screens/utils/FormValidation/formFieldTypesEnum';
import MenuFormItem from '../../core/MenuFormItem';
import HeaderButton from '../../core/HeaderButton';
import KioskAPI from '../../../api/kiosk/MeSearch-API-Kiosk';
import uuidv4 from 'uuid/v4';

const EditCenterWrapper  = ({ fieldsMeta, setFieldsMeta, validate, setEditingField, navigation, route, step, setStep, setIndex, initialValues, create }) =>  {
  
  const groupId = route.params?.groupId ?? null;
  const forumId = route.params?.forumId ?? null;
  const accountId = route.params?.AccountId ?? null;
  const groupName = route.params?.groupName ?? null;
  const callingUserID = route.params?.callingUserID ?? null;

  const mappedData = Object.entries(fieldsMeta);
  const lastStep = mappedData[mappedData.length - 1][1].step;
  const dataSet = mappedData.filter(item => {
    return item[1].step === step;
  });

  const dataSetObj = Object.fromEntries(dataSet);
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldsMetaInStep, setFieldsMetaInStep] = useState(dataSetObj);
  const [refresh, setRefresh] = useState(uuidv4());
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    let VisibilityRestriction = ""
    let SharedRestrictionName = ""
    SharedRestrictionName = Object.keys(fieldsMeta['sharing'].value).find(v => fieldsMeta['sharing'].value[`${v}`]);
    VisibilityRestriction = Object.keys(fieldsMeta['visibility'].value).find(v => fieldsMeta['visibility'].value[`${v}`]);

    let VisibilityRestrictionId=fieldsMeta['centerName'].data.VisibilityRestrictionId
    let SharedRestrictionId=fieldsMeta['centerName'].data.SharedRestrictionId

    if (VisibilityRestriction=="Private") {
      VisibilityRestrictionId = 1
    }
    if (VisibilityRestriction=="Open") {
      VisibilityRestrictionId = 2
    }
    if (VisibilityRestriction=="Restricted") {
      VisibilityRestrictionId = 3
    }

    if (SharedRestrictionName=="Open") {
      SharedRestrictionId = 1
    }
    if (SharedRestrictionName=="Private") {
      SharedRestrictionId = 2
    }
    if (SharedRestrictionName=="Closed") {
      SharedRestrictionId = 3
    }
    
    if (create) {
      setLoading(true);
      try{
        await KioskAPI.put('/api/kiosk/account/FocusCenter', 
          {
            GroupID : 0, 
            GroupName : fieldsMeta['centerName'].value,
            Description : fieldsMeta['centerDescription'].value,
            accountId : accountId, // required
            IncludedMembers : fieldsMeta['centerUsers'].value,
            ExcludedMembers : '',
            VisibilityRestrictionId : VisibilityRestrictionId,
            SharedRestrictionId : SharedRestrictionId,
            CapabilityIDs : '',
            DisclosureIDs : '',
            CallingUserID : '',
            SharedWithGroupIds : '',
          },
          { params: {
            accountId : accountId,
          }
        });
        navigation.goBack()
      } catch (err) {
        setErrorMessage('Failed to Create');
        console.log(err);
      }
      setLoading(false);
    } else {
      setLoading(true);
      try {
        await KioskAPI.put('/api/kiosk/account/FocusCenter', 
        {
          GroupID : fieldsMeta['centerName'].data.Groupid, 
          GroupName : fieldsMeta['centerName'].value,  // changeable value
          Description : fieldsMeta['centerDescription'].value,  // changeable value
          accountId : fieldsMeta['centerName'].data.AccountId, 
          IncludedMembers : fieldsMeta['centerUsers'].value, 
          ExcludedMembers : fieldsMeta['centerName'].data.ExcludedMembers, 
          VisibilityRestrictionId : VisibilityRestrictionId,  // changeable value
          CapabilityIDs : fieldsMeta['centerName'].data.CapabilityIds, 
          DisclosureIDs : fieldsMeta['centerName'].data.DisclosureIds, 
          CallingUserID : fieldsMeta['centerName'].data.CreatedUserID, 
          SharedWithGroupIds : fieldsMeta['centerName'].data.SharedWithGroupIds, 
          SharedRestrictionId : SharedRestrictionId, // changeable value
        },
        { params: {
            accountId : fieldsMeta['centerName'].data.AccountId,
          }
        });
        navigation.goBack();
      } catch (err) {
        setErrorMessage('Failed to Update');
        console.log(err);
      }
      setLoading(false);
    };
  };
      
  const onNext = () => {
    setErrorMessage('');
    Object.keys(fieldsMetaInStep).forEach(fieldsMetaKey => {
      validateFormField({ setter: setFieldsMetaInStep, original: fieldsMeta, key: fieldsMetaKey, validations: validate });
    });

    if (valErrorExist(fieldsMeta, step)) {
      setErrorMessage('There seems to be missing data or errors in the form. Please correct all errors before submitting.');
    } else {
      setStep(step + 1);
    }
  };
  
  const onBack = () => {
    setErrorMessage('');
    if (step === 1) {
      navigation.goBack();
    } else {
      setStep(step - 1);
    }
  };

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: 'Edit/Manage Focus Center',
      headerTitleAlign:'center',
      headerRight: () => {
        if (step === lastStep) {
          return (
            <HeaderButton 
              title="Save"
              onPress={() => onSubmit()}
            />
          );
        } else {
          return (
            <HeaderButton 
              title="Next"
              onPress={() => onNext()}
            />
          );
        }
      },
      headerLeft: () => (
        <HeaderBackButton
          onPress={() => onBack()}
        />
      )
    });

  }, [navigation, step]);

  return (
    <SafeAreaView style={{justifyContent: 'center', alignItems: 'center', padding: 10}}>
      <View style={{width: Math.min(Dimensions.get('window').width-20, 500), justifyContent: 'center'}}>
        {!loading ?
        <>
          { errorMessage ? (
            <Text style={styles.errorMessage}>{errorMessage}</Text>
          ) : null }
          
          <View style={{height: Math.min(Dimensions.get('window').height-100, 500), paddingVertical: 10}}>
            <FlatList 
              data={dataSet}
              keyExtractor={item => item[0]}
              extraData={refresh}
              renderItem={({item, index}) => {
                let render = null;
  
                if (item[1].type === FormFieldTypesEnum.MULTI || item[1].type === FormFieldTypesEnum.MULTIURL) {
                  render = (
                    <MenuFormItem
                      type={item[1].type}
                      itemName={item[1].name}
                      value={item[1]['value']}
                      fieldsMeta={fieldsMeta}
                      setFieldsMeta={setFieldsMeta}
                      fieldKey={item[0]}
                      setEditingField={setEditingField}
                      setIndex={setIndex}
                      setRefresh={setRefresh}
                      logic={false}
                    />
                  );
                } else {
                  render = (
                    <MenuFormItem 
                      value={item[1].value}
                      itemName={item[1].name}
                      errorFlag={item[1].error ? true : false}
                      type={item[1].type}
                      onPress={() => {
                        setEditingField(`${item[0]}`);
                      }}
                    />
                  )
                }
                return (
                  render
                );
              }}
            />
          </View>
        </>
        : <ActivityIndicator size="large" style={{ marginTop: 20 }} color="#005157"/>}
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  errorMessage: {
    fontSize: 18, 
    color: 'red', 
    paddingBottom: 10, 
    paddingLeft: 10,
    paddingTop: 10
  },
  headerButtonText: {
    color: '#24627a',
    fontWeight: '700',
    fontSize: 16
  }
});
  
export default EditCenterWrapper;