import React, { useState } from 'react';
import  { Text, Button, Input } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, ActivityIndicator, ScrollView } from 'react-native';
import Spacer from '../components/Space';
import MeSearchAPI from '../api/MeSearch-API';
import CopyrightFooter from '../components/CopyrightFooter';
import { Paltform } from 'react-native';


const CreatePublisherScreen = ({navigation, route}) => {
  const pubOwner = route.params?.pubOwner ?? '';
  const [errorMessage, setErrorMsg] = useState("");
  const [successMessage, setSuccessMsg] = useState("");
  const [pubTags, setPubTags] = useState("");
  const [username, setUsername] = useState("");
  const [trackingCode, setTrackingCode] = useState("");
  //const [password, setPassword] = useState("");
  const [ugcPubTags, setUGCPubTags] = useState("");
  const [ugcOptPubTags, setUGCOPTPubTags] = useState("");
  const [ugcSource, setUGCSource] = useState("");
  const [assetTopHeaderWeb, setAssetTopHeaderWeb] = useState("");
  const [assetTopHeaderMobile, setAssetTopHeaderMobile] = useState("");
  const [assetTopHeaderWebDarkMode, setAssetTopHeaderWebDarkMode] = useState("");
  const [assetTopHeaderMobileDarkMode, setAssetTopHeaderMobileDarkMode] = useState("");
  const [defaultUserTags, setDefaultUserTags] = useState("");
  //const [headline, setHeadline] = useState(true);
  //const [breaking, setBreaking] = useState(true);
  const [tagBarColor, setTagBarColor] = useState("");
  const [defaultImage, setDefaultImage] = useState("");
  const [mandatoryTag, setMandatoryTag] = useState("");
  const [domains, setDomains] = useState("");
  const [sources, setSources] = useState("");
  const [blacklistTags, setBlacklistTags] = useState("");
  const [tcEmail, setTCEmail] = useState("");
  const [nEmail, setNEmail] = useState("");
  const [pgLoad, setPGLoad ] = useState("");
  const [badgeSourceMap, setBadgeSourceMap ] = useState("");
  const [frameViewSources, setFrameViewSources ] = useState("");
  const [linkViewSources, setLinkViewSources ] = useState("");
  const [viewSources, setViewSources ] = useState("");
  const [nonPubSources, setNonPubSources ] = useState("");
  const [contentAge, setContentAge ] = useState("14");
  const [pubSharing, setPubSharing ] = useState("");

  const getAccessLvl = (user, pub) => {
    let accessLvl = 1;
    if (user.accessLvl) {
      user.accessLvl.forEach(accessObj => {
        if (accessObj.account === pub) {
          accessLvl = Math.max(accessObj.perm, accessLvl);
        }
      });
    }
    return accessLvl;
  };

  return (
    <ScrollView contentContainerStyle={Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall}>
    {!pgLoad ?
    <>
      <Spacer/>
      {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
      {successMessage ? <Text style={styles.successMessage}>{successMessage}</Text> : null}
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>username:</Text>
      <Spacer>
        <Input 
          label="Username"
          value={username}
          onChangeText={(text) => setUsername(text)}
          autoCorrect={false}
          autoCapitalize="none"
          maxLength={80}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>Tracking Code:</Text>
      <Spacer>
        <Input 
          label="Tracking Code"
          value={trackingCode}
          onChangeText={(text) => setTrackingCode(text)}
          autoCorrect={false}
          autoCapitalize="none"
          maxLength={80}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>pubTags:</Text>
      <Spacer>
        <Input 
          label="Publisher Tags (Comma Separated)"
          value={pubTags}
          autoCapitalize="none"
          onChangeText={(text) => setPubTags(text)}
          autoCorrect={false}
          maxLength={50000}
        />
      </Spacer>
      {/*<Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>password:</Text>
      <Spacer>
        <Input 
          label="Password"
          value={password}
          secureTextEntry={true}
          autoCapitalize="none"
          onChangeText={(text) => setPassword(text)}
          autoCorrect={false}
          maxLength={60}
        />
      </Spacer>*/}
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>ugcPubTags:</Text>
      <Spacer>
        <Input 
          label="UGC Publisher Tags (Comma Separated)"
          value={ugcPubTags}
          autoCapitalize="none"
          onChangeText={(text) => setUGCPubTags(text)}
          autoCorrect={false}
          maxLength={50000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>ugcOptPubTags:</Text>
      <Spacer>
        <Input 
          label="UGC Optional Publisher Tags (Comma Separated)"
          value={ugcOptPubTags}
          autoCapitalize="none"
          onChangeText={(text) => setUGCOPTPubTags(text)}
          autoCorrect={false}
          maxLength={50000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>ugcSource:</Text>
      <Spacer>
        <Input 
          label="UGC Source"
          value={ugcSource}
          autoCapitalize="none"
          onChangeText={(text) => setUGCSource(text)}
          autoCorrect={false}
          maxLength={500}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>assetTopHeaderWeb:</Text>
      <Spacer>
        <Input 
          label="Uri"
          value={assetTopHeaderWeb}
          autoCapitalize="none"
          onChangeText={(text) => setAssetTopHeaderWeb(text)}
          autoCorrect={false}
          maxLength={500}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>assetTopHeaderMobile:</Text>
      <Spacer>
        <Input 
          label="Uri"
          value={assetTopHeaderMobile}
          autoCapitalize="none"
          onChangeText={(text) => setAssetTopHeaderMobile(text)}
          autoCorrect={false}
          maxLength={500}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>assetTopHeaderWebDarkMode:</Text>
      <Spacer>
        <Input 
          label="Uri"
          value={assetTopHeaderWebDarkMode}
          autoCapitalize="none"
          onChangeText={(text) => setAssetTopHeaderWebDarkMode(text)}
          autoCorrect={false}
          maxLength={500}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>assetTopHeaderMobileDarkMode:</Text>
      <Spacer>
        <Input 
          label="Uri"
          value={assetTopHeaderMobileDarkMode}
          autoCapitalize="none"
          onChangeText={(text) => setAssetTopHeaderMobileDarkMode(text)}
          autoCorrect={false}
          maxLength={1000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>defaultUserTags:</Text>
      <Spacer>
        <Input 
          label="Default User Tags (Comma Separated)"
          value={defaultUserTags}
          autoCapitalize="none"
          onChangeText={(text) => setDefaultUserTags(text)}
          autoCorrect={false}
          maxLength={1000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>tagBarColor:</Text>
      <Spacer>
        <Input 
          label="Tag Bar Color (Hex Value)"
          value={tagBarColor}
          autoCapitalize="none"
          onChangeText={(text) => setTagBarColor(text)}
          autoCorrect={false}
          maxLength={80}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>defaultImage:</Text>
      <Spacer>
        <Input 
          label="Uri"
          value={defaultImage}
          autoCapitalize="none"
          onChangeText={(text) => setDefaultImage(text)}
          autoCorrect={false}
          maxLength={10000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>mandatoryTag:</Text>
      <Spacer>
        <Input 
          label="Mandatory Tag"
          value={mandatoryTag}
          autoCapitalize="none"
          onChangeText={(text) => setMandatoryTag(text)}
          autoCorrect={false}
          maxLength={80}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>domains:</Text>
      <Spacer>
        <Input 
          label="Uri (Comma Separated)"
          value={domains}
          autoCapitalize="none"
          onChangeText={(text) => setDomains(text)}
          autoCorrect={false}
          maxLength={10000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>sources:</Text>
      <Spacer>
        <Input 
          label="Sources (Comma Separated)"
          value={sources}
          autoCapitalize="none"
          onChangeText={(text) => setSources(text)}
          autoCorrect={false}
          maxLength={100000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>badgeSourceMap:</Text>
      <Spacer>
        <Input 
          label="Source;Uri,Source;Uri,Source;Uri,etc..."
          value={badgeSourceMap}
          autoCapitalize="none"
          onChangeText={(text) => setBadgeSourceMap(text)}
          autoCorrect={false}
          maxLength={200000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>blacklistTags:</Text>
      <Spacer>
        <Input 
          label="Black List Tags (Comma Separated)"
          value={blacklistTags}
          autoCapitalize="none"
          onChangeText={(text) => setBlacklistTags(text)}
          autoCorrect={false}
          maxLength={1000000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>tcEmail:</Text>
      <Spacer>
        <Input 
          label="Email"
          value={tcEmail}
          autoCapitalize="none"
          onChangeText={(text) => setTCEmail(text)}
          autoCorrect={false}
          maxLength={800}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>nEmail:</Text>
      <Spacer>
        <Input 
          label="Emails (Semicolon Separated)"
          value={nEmail}
          autoCapitalize="none"
          onChangeText={(text) => setNEmail(text)}
          autoCorrect={false}
          maxLength={1000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>frameViewSources:</Text>
      <Spacer>
        <Input 
          label="Sources (Comma Separated) - Optional"
          value={frameViewSources}
          autoCapitalize="none"
          onChangeText={(text) => setFrameViewSources(text)}
          autoCorrect={false}
          maxLength={500000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>linkViewSources:</Text>
      <Spacer>
        <Input 
          label="Sources (Comma Separated) - Optional"
          value={linkViewSources}
          autoCapitalize="none"
          onChangeText={(text) => setLinkViewSources(text)}
          autoCorrect={false}
          maxLength={500000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>viewSources:</Text>
      <Spacer>
        <Input 
          label="Sources (Comma Separated) - Optional"
          value={viewSources}
          autoCapitalize="none"
          onChangeText={(text) => setViewSources(text)}
          autoCorrect={false}
          maxLength={500000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>nonPubSources:</Text>
      <Spacer>
        <Input 
          label="Sources (Comma Separated) - Optional"
          value={nonPubSources}
          autoCapitalize="none"
          onChangeText={(text) => setNonPubSources(text)}
          autoCorrect={false}
          maxLength={500000}
        />
      </Spacer>
      <Spacer>
        <Input 
          label="Content Age (Days)"
          value={contentAge}
          autoCapitalize="none"
          onChangeText={(text) => setContentAge(text)}
          autoCorrect={false}
          maxLength={500000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>pubSharing:</Text>
      <Spacer>
        <Input 
          label="pub to share to for certain tags (PubUsername;tag,tag,tag)"
          value={pubSharing}
          autoCapitalize="none"
          onChangeText={(text) => setPubSharing(text)}
          autoCorrect={false}
          maxLength={800}
        />
      </Spacer>
      <Spacer>
        <Button
          title={'Submit'}
          onPress={ async () =>  {
            setSuccessMsg('')
            setErrorMsg('')
            if (username === "" || ugcPubTags === "" || ugcOptPubTags === "" || ugcSource === "" || assetTopHeaderWeb === "" || assetTopHeaderMobile === "" || 
                assetTopHeaderWebDarkMode === "" || assetTopHeaderMobileDarkMode === "" || defaultUserTags === "" || tagBarColor === "" || defaultImage === "" || mandatoryTag === "" ||
                domains === "" || sources === "" || blacklistTags === "" || tcEmail === "" || nEmail === "" || badgeSourceMap === "") {
              setErrorMsg('Missing one or more fields');
              return;
            }
            let frmViewSourcesArr = [];
            let lnkViewSourcesArr = [];
            if (frameViewSources !== "") {
              frmViewSourcesArr = frameViewSources.split(',');
            }
            if (linkViewSources !== "") {
              lnkViewSourcesArr = linkViewSources.split(',');
            }
            let viewSourcesArr = [];
            if (viewSources !== "") {
              viewSourcesArr = viewSources.split(',');
            }
            let nonPubSourcesArr = [];
            if (nonPubSources !== "") {
              nonPubSourcesArr = nonPubSources.split(',');
            }
            let pubTagsArr = [];
            if (pubTags !== "") {
              pubTagsArr = pubTags.split(',');
            }
            let ugcPubTagsArr = ugcPubTags.split(',');
            let ugcOptPubTagsArr = ugcOptPubTags.split(',');
            let defaultUserTagsArr = defaultUserTags.split(',');
            let bSourceMap = badgeSourceMap.split(',');
            let weight = 5;
            let defaultUserTagsArrWeighted = [];
            defaultUserTagsArr.forEach(usrTag => {
              defaultUserTagsArrWeighted.push({conTag: usrTag, value: weight});
              if (weight > 1) {
                weight -= 1;
              }
            });
            let badgeSourceHashMap = {};
            bSourceMap.forEach(bSource => {
              let curBSourceObj = bSource.split(';');
              badgeSourceHashMap[`${curBSourceObj[0]}`] = `${curBSourceObj[1]}`;
            });
            let domainsArr = domains.split(',');
            let sourcesArr = sources.split(',');
            let blacklistTagsArr = blacklistTags.split(',');
            let assetTPHeaderWeb = {link: assetTopHeaderWeb, location: "TopHeader-Web"};
            let assetTPHeaderMob = {link: assetTopHeaderMobile, location: "TopHeader-Mobile"};
            let assetTPHeaderWebDark = {link: assetTopHeaderWebDarkMode, location: "TopHeader-Web-DarkMode"};
            let assetTPHeaderMobDark = {link: assetTopHeaderMobileDarkMode, location: "TopHeader-Mobile-DarkMode"};
            let assetsArr = [assetTPHeaderWeb, assetTPHeaderMob, assetTPHeaderWebDark, assetTPHeaderMobDark];

            let pubSharingArr = [];
            const pubSharingMapComps = pubSharing.split(';');
            if (pubSharingMapComps.length === 2) {
              let pubName = pubSharingMapComps[0];
              const usrResponse = await MeSearchAPI.get('/api/user');
              let pOwner = "";
              try {
                const pResponse = await MeSearchAPI.get('/api/pub', {params: {pub: pubName}});
                pOwner = pResponse.data.owner;
              } catch (err) {
                setErrorMsg('Pub in pubSharing does not exist');
                return;
              }
              if (usrResponse && usrResponse.data) {
                const curAccLvl = Math.max(getAccessLvl(usrResponse.data, pubName), getAccessLvl(usrResponse.data, pOwner));
                if (curAccLvl < 4) {
                  setErrorMsg('You do not have admin access to the pub you are trying to share to');
                  return;
                }
              } else {
                setErrorMsg('You do not have admin access to the pub you are trying to share to');
                return;
              }
              let pubSharingTagArr = pubSharingMapComps[1].split(",");
              pubSharingArr.push({pub: pubName, tags: pubSharingTagArr});
            }
            const payload = {pubTags: pubTagsArr, username, ugcPubTags: ugcPubTagsArr, ugcOptPubTags: ugcOptPubTagsArr, ugcSource,
              assets: assetsArr, defaultUserTags: defaultUserTagsArrWeighted, tagBarColor, defaultImage, mandatoryTag,
              domains: domainsArr, sources: sourcesArr, blacklistTags: blacklistTagsArr, tcEmail, nEmail, badgeSourceMap: badgeSourceHashMap,
              linkViewSources: lnkViewSourcesArr, frameViewSources: frmViewSourcesArr, nonPubSources: nonPubSourcesArr, viewSources: viewSourcesArr, contentAge: Number(contentAge),
              shareTo: pubSharingArr, trackingCode
            };
            setPGLoad(true);
            try {
                await MeSearchAPI.post(
                    '/api/pubSignup', 
                    { payload,
                      pubOwner
                });
                setSuccessMsg("Successfully Created Publisher Account");
                setPGLoad(false);
            } catch (err) {
              setErrorMsg(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`);
              setPGLoad(false);
            }
          }}
        />
      </Spacer>
      {/* <View style={{flexDirection: 'row', justifyContent: 'center'}}>
        <View style={{height: 150, width: 280, marginBottom: 5, padding: 10}}>
            <Image style={styles.image} source={require('../../assets/web_footer_copyright.png')}/>
        </View>
      </View> */}
      <CopyrightFooter />
      </>
    : <ActivityIndicator size="large" style={{ marginTop: 150 }} color="#005157"/> }
    </ScrollView>
  );
};

const styles = StyleSheet.create({
    containerSmall: {
      //flex: 1
    },
    containerLarge: {
      width: Dimensions.get('window').width,
      paddingLeft: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
      paddingRight: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null
    },
    errorMessage: {
      fontSize: 18,
      color: 'red',
      marginTop: 15,
      marginLeft: 15
    },
    successMessage: {
      fontSize: 18,
      color: 'green',
      marginTop: 15,
      marginLeft: 15
    },
    errorAst: {
      fontSize: 24,
      color: 'red',
    },
    image: {
        width: null,
        height: null,
        marginTop: 5,
        marginBottom: 5,
        resizeMode: 'contain',
        flex: 1
    },
});

export default CreatePublisherScreen;