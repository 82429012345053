import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import { ScrollView, View, Dimensions, Linking, Text } from 'react-native';
import { WebView } from 'react-native-webview';
import { Platform } from 'react-native';
import { Context as PubContext } from '../context/PubContext';
import { HeaderBackButton } from '@react-navigation/stack';
import ArticleDisplay from '../components/ArticleDisplay';
import NativeArticleDisplay from '../components/NativeArticleDisplay';
import { updateUsr } from './utils/resultClkUtils';
import TagManager from 'react-gtm-module';
import { isMobile } from 'react-device-detect';
import Header from '../components/core/whitelabel/Header';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const ResultsShowScreen = ({ navigation, route }) => {
  const refUrl = route.params?.refUrl ?? '';
  const content = route.params?.content ?? '';
  const darkMode = route.params?.darkMode ?? '';
  const frameView = route.params?.frameView ?? '';
  const PubCon = useContext(PubContext);
  const pubName = PubCon.state.publisher.username;
  const [isAndroid, setIsAndroid] = useState(false);

  // useLayoutEffect(() => {
  //   navigation.setOptions({
  //     headerLeft: () => {
  //       if (Platform.OS === 'web') {
  //         if (pubName === 'TribExtra-AP') {
  //           return (
  //             <HeaderBackButton
  //               style={{paddingTop: 15}}
  //               onPress={() => Linking.openURL('https://triblive.com/trib-extra/')}
  //             />
  //           );
  //         } else if (pubName === 'Sponsored Sports 2') {
  //           if (refUrl !== "") {
  //             return (
  //               <HeaderBackButton
  //                 style={{paddingTop: 15}}
  //                 onPress={() => Linking.openURL(`${refUrl}`)}
  //               />
  //             );
  //           } else {
  //             return (
  //               <HeaderBackButton
  //                 style={{paddingTop: 15}}
  //                 onPress={() => Linking.openURL('https://triblive.com/sports/sponsored/')}
  //               />
  //             );
  //           }
  //         } `else {
  //           return (
  //             <HeaderBackButton
  //               style={{paddingTop: 15}}
  //               onPress={() => navigation.goBack()}
  //             />
  //           );
  //         }`
  //       }
  //     },
  //   })
  // }, [navigation]);

  useEffect (() => {
    if (Platform.OS === 'web') {
      const urlComps = PubCon.state.ref.split('?');
      let urlSuffix = `${encodeURIComponent(content !== '' ? content.body[0].title.toLowerCase(): 'x')}/bid${content.body_id}`;
      if (PubCon.state.ref.includes('?p')) {
        urlSuffix = `content-page/?${urlComps[1]}&body_id=${content.body_id}`;
      }
      let date = new Date();
      const tagManagerArgs = {
        gtmId: PubCon.state.publisher.gtmId, //'GTM-59ZPF2B'
        dataLayer: {
          FullSection: 'Content',
          CanonicalUrl: `${urlComps[0]}${urlSuffix}`,
          Headline: `${content.body[0].title}`,
          Author: `${content.body[0].authors.toString()}`,
          Catagory: `${content.meTags.toString()}`,
          SubCategory: `${content.meTags.toString()}`,
          Description: `${content.body[0].description}`,
          Published: 'Yes',
          Published_short: '',
          Updated: '',
          StoryType: `${content.type}`,
          Day_of_week: `${days[date.getDay()]}`,
          Month: `${months[date.getMonth()]}`,
          Day: `${date.getDay()}`,
          Hour: `${date.getHours()}`,
          Year: `${date.getFullYear()}`, 
          Server: '', 
          Domain: `${PubCon.state.publisher.domains[0]}`,
          Testing: 'Yes', 
          User: '', 
          Logged: '',
          BodyId: `${content.body_id}`,
          Publication: `${PubCon.state.publisher.username}`
        }
      }
      if (PubCon.state.publisher.gtmId) {
        TagManager.initialize(tagManagerArgs);
      }
      if (!window.location.href.includes('content-page') && !window.location.href.includes('/bid')) {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let pub = params.get('p');
        if (pub) {
          history.pushState(null, 'body', `content-page/?p=${pub}&body_id=${content.body_id}`);
        } else {
          const pubPageMatches = `${PubCon.state.ref}`.match(/[^\/]+/g);
          let pubPage = (pubPageMatches && pubPageMatches.length >= 3) ? pubPageMatches[2] : '/';
          history.replaceState(null, 'body', `/${pubPage}` === '//'
            ? `/${encodeURIComponent(content !== '' ? content.body[0].title.toLowerCase(): 'x')}/bid${content.body_id}`
            : `/${pubPage}/${encodeURIComponent(content !== '' ? content.body[0].title.toLowerCase(): 'x')}/bid${content.body_id}`
          );
        }
      }
      const isAndr = navigator.userAgent.toLowerCase().indexOf('android') > -1;
      setIsAndroid(isAndr);
    }
    updateUsr(content, PubCon);
  }, []);
  
  return (
    <>
      <ScrollView>
          {
            pubName === 'TribExtra-AP' || pubName === 'Sponsored Sports 2' || refUrl !== '' ? (
              <Header 
                pubName={PubCon.state.publisher.whitelabel?.displayName}
                logo={PubCon.state.publisher.whitelabel?.logo}
                menuLinks={PubCon.state.publisher.whitelabel?.headerLinks}
                primaryColor={PubCon.state.publisher.whitelabel?.primaryColor}
                textColor={PubCon.state.publisher.whitelabel?.textColor}
              />
            ) : null
          }
        {!frameView
        ? <ArticleDisplay
          darkMode={darkMode}
          content={content}
          navigation={navigation}
          refUrl={refUrl}
        />
        : <NativeArticleDisplay
            link={content.body[0].link}
          />
        }
      </ScrollView>
    </>
  );
};

export default ResultsShowScreen;