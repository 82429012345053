import React, { useContext, useEffect, useState } from 'react';
import  { Text, Button, Input } from 'react-native-elements';
import { View, StyleSheet, Image, Dimensions,  TouchableOpacity, ScrollView, SafeAreaView, Linking } from 'react-native';
import AuthForm from '../../components/AuthForm';
import NavLink from '../../components/NavLink';
import { Context as AuthContext } from '../../context/AuthContext';
import { Context as PubContext } from '../../context/PubContext';
import { isMobile, isTablet } from 'react-device-detect';
import { Platform } from 'react-native';
import { DrawerActions } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons';
import Spacer from '../Space';
// import { color } from 'react-native-reanimated';

const KioskSignIn = ({navigation}) => {

  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const { state, kSignin, clearKErrorMessage } = useContext(AuthContext);
    
  useEffect (() => {
    const unsubscribe = navigation.addListener('focus', () => {
      clearKErrorMessage();
    });
    return unsubscribe;
  }, []);

  return (
    <ScrollView style={Platform.OS === 'web' ? styles.containerLarge : styles.containerSmall} contentContainerStyle={{justifyContent: 'center'}}>
      <View style={{/*backgroundColor:'black',*/ justifyContent: 'center', alignItems: 'center', paddingBottom: 20, paddingTop: 30, marginRight: 35, marginTop: Platform.OS === 'ios' ? 10 : null}}>
        <Image style={{height:113, width:280, resizeMode: 'contain'}} source={{uri: "https://cdn.mesearch.ai/assets/default/Crivella-Ai-logo_Blu.png"}}/>
      </View>
      <View style={{flex:1, alignItems: 'center', }}>
          <Text style={{color:'#2867B2',fontSize: 24}}>Account Login</Text>
          <View style={{width: (Platform.OS === 'web' && !isMobile) ? '50%' : '90%'}}>

          <Spacer/>
          <Text style={{color:'#2867B2', fontWeight: 'bold'}}>Sign In</Text>
          <Spacer/>
          <Input 
            label=""
            value={username}
            onChangeText={(newUsername) => setUsername(newUsername)}
            placeholder="Email Address"
            autoCapitalize="none"
            autoCorrect={false}
            maxLength={80}
          />
          <Spacer/>
          <Input
            secureTextEntry={true}
            label=""
            placeholder="Password"
            value={password}
            onChangeText={(newPassword) => setPassword(newPassword)}
            autoCapitalize="none"
            autoCorrect={false}
            onSubmitEditing={() => kSignin({ username, password })}
            maxLength={60}
          />
          <Text style={{color:'#2867B2', marginLeft: 10}}>Forgot Password?</Text>
          <Spacer/>
            {state.kerrorMessage ? <Text style={{color: 'red'}}>{state.kerrorMessage}</Text> : null}
            <View style={{justifyContent:'center', alignItems:'center'}}>
            <Button 
                containerStyle={{width:100,}}
                buttonStyle={{backgroundColor:'#2867B2'}}
                title="Sign In"
                onPress={() =>  kSignin({ username, password })}
            />
          <Spacer/>
          <View style={{flexDirection: 'row'}}>
            <Text>Don't have an account yet?  </Text>
            <TouchableOpacity 
              onPress={()=> {
                if (Platform.OS === 'web') {
                  window.open("https://www.knowledgekiosk.com/AccountManagement/BuyNewAccount.aspx?selectedPlanType=2&kioskTypeID=8")
                } else {
                  Linking.openURL("https://www.knowledgekiosk.com/AccountManagement/BuyNewAccount.aspx?selectedPlanType=2&kioskTypeID=8")
                }
              }}
            >
              <Text style={{color:'#3385E5'}}>Sign Up</Text>
            </TouchableOpacity>
          </View>
          <Spacer/>
          <Text>By signing in, you are agreeing to the following:</Text>
              <View style={{flexDirection: 'row'}}>
                <TouchableOpacity 
                        onPress={()=> {
                            if (Platform.OS === 'web') {
                                let nUrl = 'https://682fa7c9-2890-4aa4-895b-aea14a8f1996.filesusr.com/ugd/7a373e_bb8f875508a1441bbcb582b38ed0f514.pdf'
                                window.open(`${nUrl}`)
                            } else {
                                Linking.openURL('https://682fa7c9-2890-4aa4-895b-aea14a8f1996.filesusr.com/ugd/7a373e_bb8f875508a1441bbcb582b38ed0f514.pdf')
                            }
                        }}
                    ><Text style={{color:'#3385E5'}}>Terms of Service</Text>
                    </TouchableOpacity>
                    <Text>, </Text> 
                    <TouchableOpacity 
                        onPress={()=> {
                            if (Platform.OS === 'web') {
                                let nUrl = 'https://682fa7c9-2890-4aa4-895b-aea14a8f1996.filesusr.com/ugd/7a373e_0d7d571b9af04beabbc45879774f18be.pdf'
                                window.open(`${nUrl}`)
                            } else {
                                Linking.openURL('https://682fa7c9-2890-4aa4-895b-aea14a8f1996.filesusr.com/ugd/7a373e_0d7d571b9af04beabbc45879774f18be.pdf')
                            }
                        }}
                    ><Text style={{color:'#3385E5'}}>Terms of Use</Text>
                    </TouchableOpacity>
                    <Text> and </Text> 
                    <TouchableOpacity 
                        onPress={()=> {
                            if (Platform.OS === 'web') {
                                let nUrl = 'https://682fa7c9-2890-4aa4-895b-aea14a8f1996.filesusr.com/ugd/7a373e_4fad1376b0cd4490b69638a18958e85c.pdf'
                                window.open(`${nUrl}`)
                            } else {
                                Linking.openURL('https://682fa7c9-2890-4aa4-895b-aea14a8f1996.filesusr.com/ugd/7a373e_4fad1376b0cd4490b69638a18958e85c.pdf')
                            }
                        }}
                    ><Text style={{color:'#3385E5'}}>Privacy Policy</Text> 
                    </TouchableOpacity>

                </View>
            
            </View>
          <Spacer/>
          <View style={{flexDirection:'row', marginBottom:5}}>
          <TouchableOpacity
              activeOpacity={.8} //The opacity of the button when it is pressed
              style = {styles.roundBttn}
            >
              <Feather name="phone-call" style={{color:'white', }}/>
            </TouchableOpacity>
            <Text style={{color:'#3385E5'}}>412.353.3720</Text>
          </View>
          <View style={{flexDirection:'row', marginBottom:5}}>
          <TouchableOpacity
              activeOpacity={.8} //The opacity of the button when it is pressed
              style = {styles.roundBttn}
            >
              <Feather name="mail" style={{color:'white', }}/>
            </TouchableOpacity>
            <Text style={{color:'#3385E5'}}>info@crivellatech.com</Text>
          </View>
          <View style={{flexDirection:'row', marginBottom:5}}>
              <TouchableOpacity
              activeOpacity={.8} //The opacity of the button when it is pressed
              style = {styles.roundBttn}
            >
              <Feather name="linkedin" style={{color:'white', }}/>
            </TouchableOpacity>
            <Text style={{color:'#3385E5'}}>LinkedIn</Text>
          </View>
          </View>
          <View style={{height: 70, width: null, paddingTop: 25}}>
              <Text>Copyright © Crivella Technologies Limited 2020</Text>
          </View>
        </View>
      </ScrollView>
  );
};

const styles = StyleSheet.create({
  containerSmall: {
    //flex: 1,
    //justifyContent: 'center',
    marginBottom: 5,
  },
  containerLarge: {
    width: Dimensions.get('window').width,
    paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
    paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) : null,
    //justifyContent: 'center',
    marginBottom: 20
  },
  image: {
    width: null,
    height: null,
    resizeMode: 'contain',
    flex: 1
  },
  roundBttn:{
    backgroundColor: '#3385E5',
    justifyContent: 'center',
    alignContent: 'center',
    borderWidth: 3,
    borderColor: '#3385E5',
    borderRadius: 30,
    marginRight:5
    
  },
  
});

export default KioskSignIn;