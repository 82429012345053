import { useEffect, useState, useContext } from 'react';
import MeSearchAPI from '../api/MeSearch-API';

export default () => {
  const [pubs, setPubs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getPubsApi = async () => {
    setLoading(true);
    try {
      let response = await MeSearchAPI.get('/api/user');
      const userObj = response.data;
      let pubsSorted = [];
      if (!userObj.params.pubs || !(userObj.params.pubs.length > 0)) {
        setLoading(false);
        return;
      }
      let mostRecentTS = Math.max(...userObj.params.pubs.map(a => a.lastVisited));
      let mostRecentPubObj = userObj.params.pubs.find(x => x.lastVisited === mostRecentTS);
      userObj.params.pubs.sort((a, b) => b.value - a.value);
      pubsSorted.push(mostRecentPubObj.pubName);
      pubsSorted.push(...(userObj.params.pubs.filter((pObj) => {return pObj.pubName !== mostRecentPubObj.pubName})).map(a => a.pubName));
      setPubs(pubsSorted);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPubsApi();
  }, []);

  return [
    pubs,
    loading,
    error,
  ];

};