import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import KioskSignOn from '../../components/kiosk/kioskSignOn';

const Stack = createStackNavigator();

const kioskLoginFlow = () => {
    return (
        <Stack.Navigator
        initialRouteName="KioskSignin"
        screenOptions={{ gestureEnabled: true }}
        >
        <Stack.Screen
            name="KioskSignin"
            component={KioskSignOn}
            options={{title: "Mesearch", headerShown: false}}
        />
        </Stack.Navigator>
    );
};

export default kioskLoginFlow;