import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { useContext, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import  { Button, Input, CheckBox } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, TextInput, FlatList, ScrollView, SafeAreaView, ImageBackground, TouchableOpacity, Linking, ActivityIndicator, Text } from 'react-native';
import { WebView } from 'react-native-webview';
import isVideo from '../screens/utils/isVideo';
import ImgPick from './imagepicker_DEPRECATED';
import ImagePicker from './ImgPicker';
import * as ImgPicker from 'expo-image-picker';
import Spacer from './Space';
import Logo from '../../assets/sample_Clouds.png';
import truBadge from '../../assets/trusted.png' ;
import comBadge from '../../assets/community.png' ;
import vidBadge from '../../assets/videoSymbolLight.png' ;
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import ArticleDisplay from './ArticleDisplay';
import uidv4 from 'uuid/v4';
import { Context as PubContext } from '../context/PubContext';
import ContentTypeEnum from './utils/contentTypes';
import Cal from '../components/CalendarDropdown';
import FileParserInput from './FileParserInput';
import removeSpecialCharacters from '../screens/utils/removeSpecialCharacters';
import bodyLinkParser from './utils/bodyLinkParser';
import isUri from './utils/isUri';
import MeSearchAPI from '../api/MeSearch-API';
import determineUrl from '../screens/utils/determineUrl';
import { set } from 'react-ga';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, AtomicBlockUtils } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import AsyncStorage from '@react-native-async-storage/async-storage';
import StockPhotoOverlay from './StockPhotoOverlay';
import { Feather } from '@expo/vector-icons';

//import CalInp from '../components/CalendarInput';

// yarn add react-datepicker

const getAccessLvl = (user, pub) => {
    let accessLvl = 1;
    if (user.accessLvl) {
      user.accessLvl.forEach(accessObj => {
        if (accessObj.account === pub) {
          accessLvl = Math.max(accessObj.perm, accessLvl);
        }
      });
    }
    return accessLvl;
};

const UploadForm = ({viewStory, setViewStory,
                    viewModal, setViewModal, 
                    myStory, setMyStory, 
                    myByline, setMyByline,
                    myHeadline, setMyHeadline,
                    myContactInfo, setMyContactInfo,
                    myImage, setMyImage,
                    myImages, setMyImages,
                    updateMyImages, 
                    cardHeadline, setCardHeadline,
                    cardAuthor, setCardAuthor,
                    contentType, setContentType,
                    publish, removePub,
                    pubStatus, setPubStatus,
                    myPhotoInfo, setMyPhotoInfo,
                    myPhotoCredit, setMyPhotoCredit,
                    myPhotoDesc, setMyPhotoDesc,
                    myDisclaimer, setMyDisclaimer,
                    canEdit, setCanEdit, contentObj,
                    optPubTags, setOptPubTags,
                    chkBoxes, setChkBoxes,
                    cbLayouts, setCbLayouts,
                    productPrice, setProductPrice,
                    getContentAccessLvl,
                    body_ID, setBody_ID,
                    darkMode, isGuest,
                    ugcSource, setUgcSource,
                    ugcPubTags, setUgcPubTags,
                    publisherUsername, setPublisherUsername,
                    tagBarColor, setTagBarColor,
                    badgeSourceMap, setBadgeSourceMap,
                    curRef, setCurRef,
                    nEmail, setNEmail,
                    defaultImage, setDefaultImage,
                    optPubTagsArr, setOptPubTagsArr,
                    navigation, claimed, uploadType, businessName, pubConfig,
                    isPointPark,
                    }) => {
    const {state} = useContext(PubContext);
    const styles = StyleSheet.create({
        input:{
            backgroundColor:'#e5e9ed',
            borderColor:'silver',
            borderWidth:2,
            alignItems:'center',
            padding:10,
            height:120,
            marginTop:5,
            marginLeft:5
        },

        blank: {
            width: 250,
            height: 250,
            backgroundColor:'#e5e9ed',
            justifyContent:'center', 
            alignItems:'center',
            marginBottom: 20,
            elevation:5
          },
          
        isflexRowView:{flexDirection:'row', width:'100%', justifyContent:'space-between', padding:20},
        isflexWrapView:{ width:'100%', justifyContent:'space-between', padding:20},
        isflexRowItem:{flex:1, width:'50%', justifyContent:'center', alignItems:'center'},
        isflexWrapItem:{flex:1, justifyContent:'center', alignItems:'center'},
    
        dropZone: {
            height: 50,
            backgroundColor:'white',
            justifyContent:'center', 
            alignItems:'center',
            marginBottom: 20,
            elevation:5
          },

        badgeImage: {
            width: 75,
            height: 20,
            marginTop: 2,
            marginLeft: 5,
            paddingLeft: 5
          },

        textArea: {
            height: 340,
            //minWidth:320,
            marginLeft:10,
            //width: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width)/2) -100: 280,
            justifyContent: "flex-start",
            borderColor: 'silver', borderStyle:'solid', borderWidth: 1
          },

        titleStyle: {
            fontSize: 24,
            fontWeight: 'bold',
            color: 'white',
            paddingLeft: 5,
            paddingBottom:5,
            marginBottom:10,
            paddingTop:5,
            backgroundColor: tagBarColor,
            overflow: 'hidden'
          },

        containerSmall: {
          flex: 1,
          justifyContent: 'center',
          marginBottom: 150
        },

        mobileSmall: {
            flex: 1,
            justifyContent: 'center',
            marginBottom: 150,
            paddingLeft:'5%',
            paddingRight:'5%',
          },

        containerLarge: {
          width: Dimensions.get('window').width,
          backgroundColor:'white',
          paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
          paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
          justifyContent: 'center',
          marginBottom: 150,
          opacity: viewModal ? 0.4 : 1.0
        },

        container: {
            marginLeft: 15,
            marginRight:15,
            justifyContent:'center', 
            flexDirection:'row',
            marginTop:10,
            height: 250,
            width: 250
          },

        image: {
            width: 250,
            height: 180,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            marginBottom: -2,
          },

          name: {
            width: 250,
            height: 42,
            fontWeight: 'bold',
            color: 'white',
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 5,
          },

          textDescStyle: {
            color: 'white',
            fontWeight: '300',
            fontSize: 14,
            paddingBottom: 5,
            paddingLeft: 5,
            paddingRight: 10
          },

          modalStyle:{ width:'45%', minHeight:'75%',
          position:"absolute", top:'10%', left:"50%", marginRight:"-50%", 
          transform: [{ translateX: '-50%', translateY: '-50%' }],
          backgroundColor: darkMode ? '#3c3c3d' : '#eae8e8', borderBottomLeftRadius:20, borderBottomRightRadius:20, 
            },

        modalMobile:{ 
            position:"absolute", top:'10%',
            width:'100%',
            justifyContent: 'center',
            paddingLeft:'5%',
            paddingRight:'5%',
            backgroundColor: darkMode ? '#3c3c3d' : '#eae8e8', 
            borderBottomLeftRadius:20, borderBottomRightRadius:20, 
        },

    });

    const [isAdmin, setIsAdmin] = useState(false);
    const [notValid, setNotValid] = useState(false);
    const [viewPreview, setViewPreview] = useState(false);
    
    const [myCoverage, setMyCoverage] = useState('');
    const [myFrameLink, setMyFrameLink] = useState('');
    const [myStartDate, setMyStartDate] = useState('');
    const [myEndDate, setMyEndDate] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [pubActive, setPubActive] = useState(false)
    const [mediaType, setMediaType] = useState('News');
    const [layoutType, setLayoutType] = useState('Card');
    const [usrId, setUsrId] = useState('');
    const [accLvl, setAccLvl] = useState(null);

    const [tagType, setTagType] = useState('');
    const [imgProcessing, setImgProcessing] = useState(false);
    const [publishing, setPublishing] = useState(false);
    const [headLabel, setHeadLabel] = useState('Headline')
    const [bodyLabel, setBodyLabel] = useState('Body')
    const [headPlaceholder, setHeadPlaceholder] = useState('Enter your headline');
    const [bodyPlaceholder, setBodyPlaceholder] = useState('Enter your story');
    const [customTags, setCustomTags] = useState([]);
    const [photoSelectVisible, setPhotoSelectVisible] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [contentState, setContentState] = useState({});
    const [saved, setSaved] = useState(false);

    const togglePhotoOverlay = () => {
        setPhotoSelectVisible(!photoSelectVisible);
    };

    const unPublish = async () => {
        await removePub();
        clrUpload();
    }

    const setChk = (key) => {
        const newChkBoxes = JSON.parse(JSON.stringify(chkBoxes));
        newChkBoxes[key] = true; // boolean
        Object.keys(newChkBoxes).forEach((chkBox) => {
          if (chkBox !== key) {
              newChkBoxes[chkBox] = false;
          }
        });
        setChkBoxes(newChkBoxes);
        setMediaType(key)
    };

    const setLayout = (key) => {
        const newChkBoxes = JSON.parse(JSON.stringify(cbLayouts));
        newChkBoxes[key] = true; // boolean
        Object.keys(newChkBoxes).forEach((chkBox) => {
          if (chkBox !== key) {
            newChkBoxes[chkBox] = false;
          }
        });
        setCbLayouts(newChkBoxes);
        setLayoutType(key)
    };

    const setTagChk = (key) => {
        const newChkBoxes = JSON.parse(JSON.stringify(optPubTags));
        newChkBoxes[key] = !newChkBoxes[key]; // boolean
        setOptPubTags(newChkBoxes);
        setTagType(key)
    };

    useEffect(() => {
        canPublish();
    },[ myStory,
        myByline, 
        myHeadline, 
        myContactInfo, 
        myImage, 
        myImages,
        cardHeadline, 
        cardAuthor, 
        contentType,
        pubStatus, 
        myPhotoInfo, 
        optPubTags,
        chkBoxes, 
        cbLayouts, 
        productPrice,
     ]);

    useEffect(() => {
        const userResProm = MeSearchAPI.get('/api/user');
        try {
            userResProm.then((userRes) => {
                if (userRes) {
                    const userObj = userRes.data;
                    const accessLvl = getAccessLvl(userObj, publisherUsername);
                    const userID = userObj._id;
                    setUsrId(userID);
                    setAccLvl(accessLvl);
                    const accOwnLvl = getAccessLvl(userObj, state.publisher.owner);
                    if (accOwnLvl >= 3) {
                        setIsAdmin(true);
                    } else {
                        setIsAdmin(false);
                    }
                }
            });
        } catch (error) {
            console.log('Could not retrieve user.')
        }
    }, []);
    

    const preSetFields = async () => {
        
        // Get publisher info for whatever publisher this was posted under
        let srch = '';
        let srchPrts = contentObj.body[0].link.split("?");
        if (srchPrts && srchPrts.length > 0) {
          srch = `?${srchPrts.pop()}`;
        }
        const curUrl = determineUrl(srch, contentObj.body[0].link);
        let pubRes = await MeSearchAPI.get('/api/pub/with-referrer', { params: {referrer: curUrl}});
        if (pubRes.data) {
          let contentPub = pubRes.data;
          let tagChkHash = {};
          contentPub.ugcOptPubTags.forEach(optPubTag => {
            tagChkHash[optPubTag] = contentObj.tags.includes(optPubTag);
          });
          if (uploadType === "Offer") {
            setOptPubTags({});
            setOptPubTagsArr([]);
            setUgcPubTags(["Offers"]);
          } else {
            setOptPubTags(tagChkHash);
            setOptPubTagsArr(contentPub.ugcOptPubTags);
            setUgcPubTags(contentPub.ugcPubTags);
          }
          setUgcSource(contentPub.ugcSource);
          setPublisherUsername(contentPub.username);
          setTagBarColor(contentPub.tagBarColor);
          setBadgeSourceMap(contentPub.badgeSourceMap);
          setCurRef(curUrl);
          setNEmail(contentPub.nEmail);
          setDefaultImage(contentPub.defaultImage);
        }

        setBody_ID(contentObj.body_id);
        setMyHeadline(contentObj.body[0].title);
        setMyByline(contentObj.body[0].authors[0]);
        setMyStory(contentObj.body[0].bodyContent);
        setMyContactInfo(contentObj.userGen ? contentObj.userGen.username : '');
        setMyImages(contentObj.body[0].media);
        setProductPrice(contentObj.price);
        setCardAuthor('By '+ contentObj.body[0].authors[0]);
        setCardHeadline(contentObj.body[0].title);
    
        let mediaMeta = {};
        contentObj.mediaMeta.forEach((img, index) => {
          const desc = contentObj.mediaMeta[index]['caption'] ? contentObj.mediaMeta[index]['caption'] : '';
          const credit = contentObj.mediaMeta[index]['credit'] ? contentObj.mediaMeta[index]['credit'] : '';
          const uri = contentObj.mediaMeta[index]['uri'] ? contentObj.mediaMeta[index]['uri'] : `${index}`;
          mediaMeta[uri] = {};
          mediaMeta[uri]['Credits'] = credit;
          mediaMeta[uri]['Description'] = desc;
        });
        setMyPhotoInfo(mediaMeta);
    }

    useEffect (() => {
        
        if (canEdit) {
            preSetFields();
          } else {
            let initUgcOptPubTags = {};
            pubConfig.ugcOptPubTags.forEach(optTag => {
              initUgcOptPubTags[optTag] = false;
            });
            setOptPubTags(initUgcOptPubTags);
            setOptPubTagsArr(pubConfig.ugcOptPubTags);
            setUgcPubTags(pubConfig.ugcPubTags);
            setUgcSource(pubConfig.ugcSource);
            setPublisherUsername(pubConfig.username);
            setTagBarColor(pubConfig.tagBarColor);
            setBadgeSourceMap(pubConfig.badgeSourceMap);
            setCurRef(state.ref);
            setNEmail(pubConfig.nEmail);
            setDefaultImage(pubConfig.defaultImage);
          }
          if (uploadType === "Offer") {
            setMyByline(businessName);
            setHeadLabel('Offer'), 
            setHeadPlaceholder('ie. 25% of your purchase')
            setBodyLabel('Offer Description'), 
            setBodyPlaceholder('ie. Come dine with us - bring this offer to get 25% off your bill - Alcohol not included')
            setCardHeadline('Offer');
            setUgcPubTags(["Offers"]);
            setOptPubTags({});
            setOptPubTagsArr([]);
            //setUgcPubTags([...pubConfig.ugcPubTags, "Offers"]);
        }
    }, [canEdit]);

    const validateEmail = email => {
        const re = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    

    const cleanText = text => {
        text = text.replace(/[^\s,.'\w]/g, "");
        return text
    };

    const canPublish = () =>{
        if (myHeadline !== '' &&
            (myByline !== '' || contentType !== ContentTypeEnum.ARTICLE) &&
            (myStory !== '' || contentType !== ContentTypeEnum.ARTICLE) 
        ){
            setPubActive(true)
        }
        else{
            setPubActive(false)
        }

    }
    
    const buildContent = () => {
        
        let defaultImg = 'https://cdn.mesearch.ai/assets/media/sample_Camera.png';

        let mediaMeta = [];

        myImages.forEach((img) => {
          const desc = myPhotoInfo[img] ? (myPhotoInfo[img]['Description'] ? myPhotoInfo[img]['Description'] : '') : '';
          const credit = myPhotoInfo[img] ? (myPhotoInfo[img]['Credits'] ? myPhotoInfo[img]['Credits'] : '') : '';
          mediaMeta.push({uri: img, credit: credit, caption: desc});
        });

        if (mediaMeta.length === 0) {
            mediaMeta.push({uri: defaultImg, credit: '', caption: ''});
        }

        const source = ugcSource;
        let ugcTags = Object.keys(optPubTags).filter(optT => optPubTags[`${optT}`]);
        ugcTags.push(...ugcPubTags);

        const usrId = usrId;
        const accessLvl = accessLvl ? accessLvl : 1;
        const timeDate = new Date();
        const timestamp = timeDate.setDate(timeDate.getDate());
        const crtDate = new Date(timestamp).toISOString();
        const desc = myStory.substring(0, 120);
        let link = body_ID;

        if (curRef.includes('?p')) {
          const urlComps = curRef.split('?');
          let prefix = urlComps[0];
          //let urlSuffix = `content-page/?${urlComps[1]}&body_id=${body_ID}`;
          let urlSuffix = `${urlComps[1].replace('p\=', '')}/${encodeURIComponent(myHeadline !== '' ? myHeadline.toLowerCase(): 'x')}/bid${body_ID}`;
          link = `${prefix}${urlSuffix}`;
        } else {
          link = `${curRef}${encodeURIComponent(myHeadline !== '' ? myHeadline.toLowerCase(): 'x')}/bid${body_ID}`;
        }

        const htmlText = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const bodyLinks = bodyLinkParser(myStory);
        const newlineRegex = new RegExp('\n', 'g');
        const bodyStory = myStory.replace(newlineRegex, "    ");
        
        const bodyDat = [{
            link: link,
            description: desc,
            bodyContent: bodyStory,
            media: myImages.length > 0 ? myImages : defaultImg,
            title: myHeadline,
            createDate: crtDate,
            authors:[myByline],
            htmlText,
        }];

        const usrGen = {
            accessLvl: accLvl,
            userId: usrId,
            created: timestamp,
            lastModified: timestamp
        }

        const content = {
            mediaMeta: mediaMeta,
            source_native_id:0,
            type: ContentTypeEnum.ARTICLE,
            source: source,
            body: bodyDat,
            bodyLinks: bodyLinks,
            body_id: body_ID,
            tags: ugcTags
        };

        if (!claimed) {
            content.userGen = usrGen;
        }

        return content;

    }

    const clrUpload = () =>{
        setMyHeadline('');
        setCardHeadline('Headline');
        setCardAuthor('By-line (Author)');
        setMyByline('');
        setMyStory('');
        setMyContactInfo('');
        setMyImages([]);
        setMyPhotoInfo({});
        setViewStory(!viewStory);
        setCanEdit(false);
        setPubActive(false);
        setLayout('Card');
        setChk('News');
        setContentType('Article');
        let initUgcOptPubTags = {};
        pubConfig.ugcOptPubTags.forEach(optTag => {
          initUgcOptPubTags[optTag] = false;
        });
        setOptPubTags(initUgcOptPubTags);
        setOptPubTagsArr(pubConfig.ugcOptPubTags);
        setUgcPubTags(pubConfig.ugcPubTags);
        setUgcSource(pubConfig.ugcSource);
        setPublisherUsername(pubConfig.username);
        setTagBarColor(pubConfig.tagBarColor);
        setBadgeSourceMap(pubConfig.badgeSourceMap);
        setCurRef(state.ref);
        setNEmail(pubConfig.nEmail);
        setDefaultImage(pubConfig.defaultImage);
    }

    const handleFileUpload = (incomingContent) => {
        setMyStory(incomingContent);
        getMoreTags(incomingContent);
    };

    const addText = text => {
        const newEditorState = EditorState.createWithContent(ContentState.createFromText(text));
        setEditorState(newEditorState);
    };

    const storeEditorText = async value => {
        try {
            await AsyncStorage.setItem('content', value);
        } catch (error) {
            console.log('There was an error saving the editor text');
        }
    };

    const onEditorStateChange = editorState => {
        setEditorState(editorState);
        let converted = convertToRaw(editorState.getCurrentContent());
        let htmlContent = draftToHtml(converted);

        // let textString = "";
        // for (let i = 0; i < converted.blocks.length; i++) {
        //     textString += converted.blocks[i].text + "\n";
        // }
        // will need to pass textString for the desc most likely...since it uses a substring of it and saves that. We don't want that to be in HTML anyway.

        setMyStory(htmlContent);
        if (converted.blocks[0].text !== "") {
            setTimeout(() => {
                setSaved(true);
                storeEditorText(converted.blocks[0].text);
            }, 2500);
        }
        setSaved(false);
    };

    // incomplete below. not sure if needed yet.
    // const insertImage = async () => {
    //     try {
    //         let result = await ImgPicker.launchImageLibraryAsync({
    //             mediaTypes: ImgPicker.MediaTypeOptions.Images,
    //             allowsEditing: true,
    //             aspect: [4, 3],
    //             quality: 1,
    //             base64: true,
    //         });
    //         if (!result.cancelled) {
    //             // fill later
    //         }
    //     } catch (error) {
            
    //     }
    // };

    // not sure if we need this yet.
    // const toggleOverlay = () => {
    //     setModalVisible(!modalVisible);
    // };
    
    const getMoreTags = async (tx) => {
        
        let newTags = [];
        let cTags = [];

        if (tx !=''){

            try {

                let response = await MeSearchAPI.post(
                    '/api/content/meTags', 
                    {
                        title: myHeadline!='' ? myHeadline : 'i',
                        body: tx,
                        tags:[]
                    }
                )
              
                if (response.data.meTags.toString()!='' && response.data.meTags.toString()!='General'){
                    response.data.meTags.forEach((value) => {
                        newTags.push(value)
                        cTags.push(value)
                    });
                }

                setCustomTags(cTags)

            } catch (err) {
                console.log(err);
            }
                
        }
        
        // TODO NEED TO LOOP TAG ARRAY
        setOptPubTagsArr([]);
        pubConfig.ugcOptPubTags.forEach((value) => {
            newTags.push(value)
        });
        
        setOptPubTagsArr(newTags)

        let opt = Object.entries(optPubTags);
        cTags.forEach((index)=>{
            opt.push([index,true])
            }
        )
        
        // TODO RESET OPTPUBTAGS WITH NEW TAGS
        setOptPubTags({});
        const obj = Object.fromEntries(opt);
        setOptPubTags(obj);

    }

    return (
<>
    {(!imgProcessing && !publishing) ?
    <View style={Dimensions.get('window').width > 1250 ? null : styles.mobileSmall}>

        <View style={{ backgroundColor: darkMode ? '#3c3c3d' : 'white', borderRadius:5, marginTop:10, paddingLeft:10, paddingRight:10, 
        borderColor:'silver', borderWidth:3
        }}>

        <View style={{flexDirection:'row', justifyContent:'space-between', 
                    backgroundColor: tagBarColor, 
                    marginTop:10, paddingRight:5, paddingTop:5}}>

            <Text style={{color:'white', fontWeight:'bold', paddingLeft:5, marginTop:7 }}>
                {canEdit ? 'Edit' : 'Add'} {uploadType}</Text>
                <View >
                    {contentType !== ContentTypeEnum.ARTICLE 
                        ? <Text style={{fontWeight:'bold', color:'white'}}>Visits: 0       Clicks: 0</Text> 
                        : null
                    }
                </View> 

            {/*<TouchableOpacity 
            style={{width:20, height:20, flexDirection:'row', justifyContent:'center'}}
            onPress={ () => clrUpload() }
            >
            <Text style={{color:'white', fontWeight:'bold', paddingLeft:5, paddingRight:5, borderColor:'white', borderWidth:1}}>X</Text>
            </TouchableOpacity>*/}

        
            <View style={{flexDirection:'row', justifyContent:'flex-end'}}>
                
                <View style={{flexDirection:'row'}}>
                    {canEdit 
                    ? 
                    <Button onPress={()=>{unPublish()}} 
                    title="Unpublish" buttonStyle={{height:30,  backgroundColor:'red'}}
                    />
                    : null
                    } 
                    <Text> </Text>
                    <Button title="Preview" buttonStyle={{height:30, marginBottom:10, borderColor:'white', borderWidth:1, backgroundColor:null }}  onPress={()=>{setViewPreview(!viewPreview)}} />
                    <Text> </Text>
                    <Button onPress={pubActive ? () => publish(setPublishing) : () => {} } 
                    buttonStyle={{height:30, marginBottom:5, borderColor:'white', borderWidth:1, backgroundColor:null, opacity: pubActive ? 1 : 0.5, }}
                    title={canEdit ? 'Update' : 'Publish'} />
                </View>
            </View>
        </View>


        <View style={{ marginTop: 10, }}>

        {contentType !== ContentTypeEnum.ARTICLE
            ?
            <>
            <Text style={{fontWeight:'bold', color:'#86939e', fontSize:16, marginLeft:10}}>Ad Layout Style:</Text>
            <View style={{flexDirection:'row'}}>
                <CheckBox
                        checkedIcon='dot-circle-o'
                        uncheckedIcon='circle-o'
                        containerStyle={{flex:1, backgroundColor:'white', borderColor:'white'}}
                        title='Card Ad'
                        onPress={() => {
                            setLayout('Card');
                        }}
                        checkedColor='#007bff'
                        checked={cbLayouts['Card']}
                />
                <CheckBox
                        checkedIcon='dot-circle-o'
                        uncheckedIcon='circle-o'
                        containerStyle={{flex:1, backgroundColor:'white', borderColor:'white'}}
                        title='Full Ad'
                        onPress={() => {
                            setLayout('Full');
                        }}
                        checkedColor='#007bff'
                        checked={cbLayouts['Full']}
                />
                <CheckBox
                        checkedIcon='dot-circle-o'
                        uncheckedIcon='circle-o'
                        containerStyle={{flex:1, backgroundColor:'white', borderColor:'white'}}
                        title='Pencil'
                        onPress={() => {
                            setLayout('Pencil');
                        }}
                        checkedColor='#007bff'
                        checked={cbLayouts['Pencil']}
                />
            </View>
            </>
            : 
            <View style={{display:'none'}}>
                <Text style={{fontWeight:'bold', color:'#86939e', fontSize:16, marginLeft:10}}>Article Category:</Text>
                <View style={{flexDirection:'row'}}>
                    <CheckBox
                            checkedIcon='dot-circle-o'
                            uncheckedIcon='circle-o'
                            containerStyle={{flex:1, backgroundColor:'white', borderColor:'white'}}
                            title='News'
                            onPress={() => {
                                setChk('News');
                            }}
                            checkedColor='#007bff'
                            checked={chkBoxes['News']}
                    />
                    <CheckBox
                            checkedIcon='dot-circle-o'
                            uncheckedIcon='circle-o'
                            containerStyle={{flex:1, backgroundColor:'white', borderColor:'white'}}
                            title='Editorial'
                            onPress={() => {
                                setChk('Editorial');
                            }}
                            checkedColor='#007bff'
                            checked={chkBoxes['Editorial']}
                    />
                    <CheckBox
                            checkedIcon='dot-circle-o'
                            uncheckedIcon='circle-o'
                            containerStyle={{flex:1, backgroundColor:'white', borderColor:'white'}}
                            title='Opinion'
                            onPress={() => {
                                setChk('Opinion');
                            }}
                            checkedColor='#007bff'
                            checked={chkBoxes['Opinion']}
                    />
                </View>
            </View>
        }

            <View style={{flexDirection: 'row', alignItems: 'center',}}>
                <Text style={{fontSize:16, fontWeight: 'bold', color: darkMode ? 'white' : 'black', marginHorizontal:10, marginBottom: 5,}}>
                    {contentType  !== ContentTypeEnum.ARTICLE ? "Product Information" : 'Article ' + bodyLabel }
                    <Text style={{color:'red', fontWeight:'bold'}}> *</Text>
                    {/* <Text style={{marginLeft:30, marginRight:5}}>Type/Paste your story OR</Text>
                    <Text>upload your (Text) .TXT / (Microsoft Word) .DOCX </Text> */}
                </Text>
                <FileParserInput getFileContent={handleFileUpload} />
            </View>

            {
                Platform.OS === 'web' ? (
                    <View>
                        <Editor 
                            editorState={editorState}
                            contentState={contentState}
                            editorStyle={{ height: 300, border: "1px solid" }}
                            toolbarStyle={{ borderColor: 'black', borderWidth: 1 }}
                            spellCheck
                            toolbarClassName='toolbar-class'
                            wrapperClassName='wrapper-class'
                            editorClassName='editor-class'
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'remove', 'history'],
                                inline: {
                                    options: ['bold', 'italic']
                                },
                                blockType: {
                                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'Blockquote']
                                },
                                fontSize: {
                                    options: [8, 10, 12, 14, 16, 18, 24, 36, 48, 60]
                                },
                                fontFamily: {
                                    options: ['Arial', 'Impact', 'Times New Roman', 'Verdana']
                                },
                                list: {
                                    options: ['unordered', 'ordered']
                                },
                                link: {
                                    options: ['link']
                                },
                            }}
                            stripPastedStyles={true}
                            // toolbarCustomButtons={[<StockPhotoOverlay />]}
                        />
                        { saved ? <div style={{ height: 20 }}><Text>Your text has been saved!</Text></div> : <div style={{ height: 20 }}></div> }
                    </View>
                ) : null
            }

            {/* The following text area is still applicable to mobile applications (.native file) */}
            {/* <View style={styles.textArea}>
                <TextInput
                    style={{ flex:1, textAlignVertical: 'top', backgroundColor: darkMode ? '#666464' : '#fcfcfc', padding:10, color: darkMode ? 'white' : 'black' }}
                    value={myStory}
                    onChangeText={myStory => {setMyStory(myStory); getMoreTags(myStory); }}
                    placeholder={contentType  !== ContentTypeEnum.ARTICLE ? "Additional Information" : uploadType === "Article" ? "Type Your Article or upload MS Word Document or Text file." : bodyPlaceholder}
                    //placeholder={contentType  !== ContentTypeEnum.ARTICLE ? "Additional Information" : 'Type or paste your story'}
                    placeholderTextColor='grey'
                    multiline={true}
                />
            </View> */}

            <Spacer/>

            {/* START HEADLINE */}
            <View>
                <Text style={{fontWeight:'bold', color: darkMode ? 'white' : 'black', fontSize:16, marginLeft:10}}>{contentType  !== ContentTypeEnum.ARTICLE ? "Product Description" : headLabel + " "}<Text style={{color:'red', fontWeight:'bold'}}>*</Text></Text>
                <Input 
                    label=""
                    value={myHeadline}
                    onChangeText={(myHeadline) => {setMyHeadline(myHeadline); setCardHeadline(myHeadline);}}
                    //placeholder={contentType  !== ContentTypeEnum.ARTICLE ? "Description" : 'Enter your headline'}
                    placeholder={headPlaceholder}
                    placeholderTextColor='grey'
                    autoCapitalize="none"
                    autoCorrect={true}
                    maxLength={120}
                    inputStyle={{color: darkMode ? 'white' : 'black'}}
                />
            </View>
            <Spacer/>
            {/* END HEADLINE */}

            {/* START AUTHOR */}
            {contentType  === ContentTypeEnum.ARTICLE
                ?
                <View style={{display: uploadType === "Article" ? 'block' : 'none'}}>
                    <Text style={{fontWeight:'bold', color: darkMode ? 'white' : 'black', fontSize:16, marginLeft:10}}>Author(s) <Text style={{color:'red', fontWeight:'bold'}}>*</Text></Text>
                    <Input 
                        label=""
                        value={myByline}
                        onChangeText={(myByline) => {setMyByline(cleanText(myByline)); setCardAuthor('By '+ cleanText(myByline))}}
                        placeholder='First name Last name'
                        placeholderTextColor='grey'
                        autoCapitalize="none"
                        autoCorrect={true}
                        maxLength={75}
                        inputStyle={{color: darkMode ? 'white' : 'black'}}
                    />

                    <Spacer/>
                </View>
                : setCardAuthor('Buy Now')
            }
            {/* END AUTHOR */}

            <View>
                <Text style={{fontWeight:'bold', color: darkMode ? 'white' : 'black', fontSize:16, marginLeft:10}}>{contentType  !== ContentTypeEnum.ARTICLE ? "Product Description" : "Add Background Photo"}</Text>
                <Spacer/>
            </View>

            {/* START PREVIEW */}
            
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <TouchableOpacity style={styles.container} onPress={()=>{setViewPreview(!viewPreview)}}>

                    <View>

                    { isVideo(myImages[0])
                    ?
                        isUri(myImages[0])
                        ?  <><Image 
                            source={{uri: 'https://cdn.mesearch.ai/assets/media/videoSymbolLightCard.png'}}
                            style={{width: 250, height: 180, backgroundColor:'silver'}}
                            /></>
                        : <>
                            <View>
                            {Platform.OS === 'web'
                                ?<><iframe 
                                width="250" 
                                height={(isMobile) ? "250" : "180"}
                                src={`${myImages[0]}`}
                                frameBorder="0"
                                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen
                                />
                                </>
                                : (myImages.length > 0 
                                    ? isUri(myImages[0]) 
                                        ? <Webview
                                            source={{uri: myImages[0]}}
                                            style={{width: 250, height: 180}}
                                            /> 
                                        : <Image 
                                            source={{uri: 'https://cdn.mesearch.ai/assets/media/videoSymbolLightCard.png'}} 
                                            style={{width: 250, height: 180, backgroundColor:'silver'}}
                                            />
                                    : <Image
                                        source={{uri: 'https://cdn.mesearch.ai/assets/media/sample_Camera.png'}}
                                        style={{width: 250, height: 180}}
                                    />
                                )  
                            }
                            </View>
                        </>
                    :
                        <>
                            <ImageBackground 
                                style={{
                                    width: 250,
                                    height: cbLayouts['Full'] ? 250 : 180,
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4,
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                    marginBottom: cbLayouts['Full'] ? 18 : -2,
                                    opacity: cbLayouts['Pencil'] ? 0.25 : 1.0
                                }}
                                imageStyle={{ borderRadius: 4 }}
                                source={{uri: myImages.length > 0 ? myImages[0] : 'https://cdn.mesearch.ai/assets/media/sample_Camera.png'}}
                                onError={(err) => {
                                    console.log(err)
                                }}
                            >
                                {!claimed && !isPointPark ?
                                <View style={{backgroundColor: 'black', width: 100, height: 30, padding:3 }}>
                                    <Image
                                    style={styles.badgeImage}
                                    imageStyle={{ borderRadius: 4 }}
                                    source={getContentAccessLvl(contentObj) > 1 ? truBadge : comBadge}
                                    />
                                </View> 
                                : null}
                            </ImageBackground>
                        </>
                    }

                    <View style={{height: 70, width: 250, marginBottom:20, backgroundColor: '#000000', display: cbLayouts['Full'] ? 'none' : null, opacity: 0.9}}>
                        <View style={{ height: 50, paddingTop: 1, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <Text numberOfLines={2} style={styles.name} onPress={()=>{setDisplayHeadline(true); }}>{cardHeadline}</Text>
                        </View>
                        <View style={{ flexDirection:'row', justifyContent:'space-between', height: 30, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <View style={{ flexDirection:'row', paddingLeft: 5,}}>
                                {contentType  === ContentTypeEnum.ARTICLE 
                                    ?
                                        <Image
                                        style={{height: 20, width: 35, paddingLeft: 15, paddingBottom: 5}}
                                        imageStyle={{ borderRadius: 4 }}
                                        source= {{uri: badgeSourceMap[ugcSource]}}
                                        onError={(err) => {
                                            console.log(err)
                                        }}
                                        />
                                    :   <Text style={{color:'white', fontWeight:'bold', marginLeft:10}}>Price: ${productPrice}</Text>
                                }
                                <Text numberOfLines={1} style={styles.textDescStyle} >{uploadType === "Article" ? cardAuthor : businessName}</Text>
                            </View>
                            {
                                state.publisher.owner === 'TribTotalMedia' && !claimed ? (
                                    getContentAccessLvl(contentObj) > 1 ? 
                                        <Text style={{color:'white', paddingRight:5, fontSize:10, display:'none'}}>Trusted{"\n"}Contributor</Text> 
                                    : <Text style={{color:'white', paddingRight:5, fontSize:10, display:'none'}}>Community{"\n"}Contributor</Text> 
                                ) : null
                            }
                        </View>
                    </View>

                    {/* image gallery */}
                    <FlatList
                        data={myImages}
                        horizontal={true}
                        style={{maxWidth:250}}
                        keyExtractor={(item) => item }
                        renderItem={(item) => {
                            return (
                                <View style={{paddingLeft:10}}>
                                    <ImageBackground 
                                    style={[styles.image, { width: 50, height: 50, marginBottom:10, backgroundColor:'silver' }]}
                                    imageStyle={{ borderRadius: 4 }}
                                    //source={{uri: `${item.item}`}}
                                    source={{ uri: !isVideo(`${item.item}`) ? `${item.item}` : 'https://cdn.mesearch.ai/assets/media/videoSymbolLight.png' }} 
                                    // style={{ width: 50, height: 50, marginBottom:10, backgroundColor:'silver' }}
                                    onError={(err) => {
                                        console.log(err)
                                    }}
                                    >
                                    </ImageBackground>

                                </View>
                            )
                        }
                        }
                        />
                    {/* end gallery */}

                    </View>    

                </TouchableOpacity>
            </View>
            {/* END PREVIew */}

            {/** START IMAGEPICKER **/}

            <View style={{flex:1, alignItems: 'center', justifyContent: 'center', display:'none'}}>

                <Text>By submitting your content to MeSearch, </Text>
                <Text>you acknowledge and agree to MeSearch's </Text>

                <View style={{flexDirection: 'row'}}>
                    
                    <TouchableOpacity 

                        onPress={()=> {
                            if (Platform.OS === 'web') {
                                let nUrl = 'https://mesearch.us/wp-content/uploads/2020/02/2020-01-01_Crivella-Media-Tech_Collection-and-Use-of-Personal-Information_Policy-only.pdf'
                                window.open(`${nUrl}`)
                            } else {
                                Linking.openURL('https://mesearch.us/wp-content/uploads/2020/02/2020-01-01_Crivella-Media-Tech_Collection-and-Use-of-Personal-Information_Policy-only.pdf')
                            }
                        }}
                    ><Text style={{color:'#3385E5'}}>Privacy Policy</Text> 
                    </TouchableOpacity>
                    <Text> and </Text> 
                    
                    <TouchableOpacity 
                        onPress={()=> {
                            if (Platform.OS === 'web') {
                                let nUrl = 'https://mesearch.us/wp-content/uploads/2020/02/Terms-of-Services.pdf'
                                window.open(`${nUrl}`)
                            } else {
                                Linking.openURL('https://mesearch.us/wp-content/uploads/2020/02/Terms-of-Services.pdf')
                            }
                        }}
                    ><Text style={{color:'#3385E5'}}>Terms of Service and Use.</Text>
                    </TouchableOpacity>
                </View>

            </View>

            <View style={ Dimensions.get('window').width > 1250 ? styles.isflexRowView : styles.isflexWrapView }>
                <View style={Dimensions.get('window').width > 1250 ? styles.isflexRowItem : styles.isflexWrapItem}>
                    <View style={{flexDirection: 'row', alignContent: 'space-between'}}>
                        <ImagePicker 
                            setMyImage={setMyImage}
                            updateMyImages={updateMyImages}
                            setMyImages={setMyImages}
                            myImages={myImages}
                            myPhotoInfo={myPhotoInfo}
                            setMyPhotoInfo={setMyPhotoInfo}
                            myPhotoCredit={myPhotoCredit}
                            setMyPhotoCredit={setMyPhotoCredit}
                            myPhotoDesc={myPhotoDesc}
                            setMyPhotoDesc={setMyPhotoDesc}
                            myDisclaimer={myDisclaimer}
                            setMyDisclaimer={setMyDisclaimer}
                            darkMode={darkMode}
                            setImgProcessing={setImgProcessing}
                            togglePhotoOverlay={togglePhotoOverlay}
                            visible={photoSelectVisible}
                        />
                    </View>
                </View>
            </View>
            {/** END IMAGEPICKER **/}

            {/*
            <View style={{flex: 1, alignItems: 'center', justifyContent: 'center',}}>
                <Button
                    title="Analyze Text"
                    onPress={()=> {
                            if (Platform.OS === 'web') {
                                window.open('https://mesearch.ai/tagging-tool/apisampler.htm')
                            } else {
                                Linking.openURL('https://mesearch.ai/tagging-tool/apisampler.htm')
                            }
                    }}
                    style={{ width:160, paddingTop: 10, display: isAdmin ? "block" : "none"}}
                /> 
            </View>
            <Spacer/> */}

            {/*
            <Input 
                label="Published Contact"
                //labelStyle={{color: 'red'}}
                value={myContactInfo}
                inputStyle={notValid ? {color: 'red', borderColor:'red', borderWidth:3}: {color:'black'}} 
                onChangeText={(myContactInfo) => {
                    setMyContactInfo(myContactInfo);
                    setNotValid(false)
                    if (!validateEmail(myContactInfo)) {
                        setNotValid(true)
                    }
                }
                }
                onBlur={() => {canPublish()}}
                textContentType='emailAddress'
                placeholder='email@address.com'
                placeholderTextColor='grey'
                autoCapitalize="none"
                autoCorrect={false}
                maxLength={80}
            />
            <Spacer/>
            */}

            {optPubTagsArr.length > 0 ?
            <View style={{marginTop:-10}}>
            <Text style={{fontWeight:'bold', color: darkMode ? 'white' : 'black', fontSize:16, marginLeft:10}}>Suggested Tags:</Text>
            <FlatList
                    data={optPubTagsArr}
                    horizontal={false}
                    style={{ //maxWidth: (Platform.OS === 'web') ? '50%' : '100%', 
                    marginLeft:10 }}
                    numColumns={2}
                    keyExtractor={(item) => item }
                    renderItem={(item, index) => {
                        return (

                                <CheckBox
                                        containerStyle={{flex:1, backgroundColor: darkMode ? '#3c3c3d' : 'white', borderColor: darkMode ? '#3c3c3d' : 'white', marginLeft:-10,}}
                                        textStyle={{color: darkMode ? 'white' : 'black'}}
                                        title={item.item}
                                        onPress={() => {
                                            setTagChk(item.item);
                                        }}
                                        checkedColor={darkMode ? 'white' : '#007bff'}
                                        checked={optPubTags[item.item]}
                                />

                        )
                    }
                    }
            />
            </View>
            : null}

            <Spacer/>
            <View style={{ flexDirection:'row', justifyContent:'center', marginTop:10}}>
            {
                state.publisher.owner === 'TribTotalMedia' ? (
                    !claimed ?
                    <TouchableOpacity 
                        style={{flexDirection: 'row'}}
                        onPress={()=> {
                            if (!isGuest) {
                                if (Platform.OS === 'web') {
                                    let nUrl = window.location.href.includes('?') ? `${window.location.href}&tcr=true` : `${window.location.href}?tcr=true`
                                    window.open(`${nUrl}`)
                                } else {
                                    Linking.openURL('https://mesearch.ai/?tcr=true')
                                }
                            }
                        }}
                        >
                        <Text style={{fontSize: 14, fontWeight: 'bold', color: darkMode ? 'white' : '#3385E5', paddingTop: 5}}>Become A  </Text>
                        <Image style={{height: 25, width: 75}} source={require('../../assets/trusted.png')}/>
                    </TouchableOpacity> : null
                ) : null
            }
          </View>
          <Spacer/>
            {contentType  !== ContentTypeEnum.ARTICLE
            ?
            <>
            <Input 
                label="Product Price"
                labelStyle={{fontWeight:'bold', color:'#86939e', fontSize:16,}} 
                value={productPrice}
                inputContainerStyle={{ width:80}}
                onChangeText={(productPrice) => setProductPrice( productPrice.replace(/[^.0-9]/g, ''))}
                placeholder='$0.00'
                placeholderTextColor='grey'
                autoCapitalize="none"
                autoCorrect={false}
                maxLength={40}
            />
            <Spacer/>
            <Text style={{fontWeight:'bold', color:'#86939e', fontSize:16, marginLeft:10}}>Ad Duration:</Text>
            <View style={{flex:1, marginLeft:10, alignItems: 'center', justifyContent: 'center'}}>
                <View>
                    <Text>From:</Text>
                    <Cal/>
                </View>
            <Spacer/>
                <View>
                    <Text>To:</Text>
                    <Cal/>
                </View>
            </View>
            <Spacer/>
            <Input 
                label="Daily Budget"
                value={myCoverage}
                onChangeText={(myCoverage) => setMyCoverage( myCoverage.replace(/[^.0-9]/g, ''))}
                placeholder='$0.00'
                placeholderTextColor='grey'
                autoCapitalize="none"
                autoCorrect={false}
                inputContainerStyle={{ width:80}}
                maxLength={80}
            />
            <Spacer/>
            <Input 
                label="Website"
                value={myFrameLink}
                onChangeText={(myFrameLink) => setMyFrameLink(myFrameLink)}
                placeholder='http://'
                placeholderTextColor='grey'
                autoCapitalize="none"
                autoCorrect={false}
                maxLength={80}
            />
            <Spacer/>
            <Input 
                label="Targeting Package"
                value={myCoverage}
                onChangeText={(myCoverage) => setMyCoverage(myCoverage)}
                placeholder='Targeting'
                placeholderTextColor='grey'
                autoCapitalize="none"
                autoCorrect={false}
                maxLength={80}
            />
{/*
            <View  style={{flex:1, marginLeft:10, alignItems: 'center', justifyContent: 'center'}}>
                <View style={{zoom:'50%', width:'95%'}}>
                    <CalInp/>
                </View>

                <View style={{zoom:'50%', width:'95%'}}>
                    <CalInp/>
                </View>
            </View>
*/}
            </>

            : null
            
            }

        </View>

        </View>
    
    </View>
    : <View style={{alignItems: 'center', justifyContent: 'center'}}>
        <ActivityIndicator size="large" color={darkMode ? 'white' : Platform.OS ==='web' ? "#005157" : Platform.OS === 'android' ? '#005157' : 'default'} style={{ marginTop: 150 }}/>
        <Text style={{fontSize: 14, color: 'grey'}}>{publishing ? "Publishing..." : "Processing image..."}</Text>
      </View> 
    }

        {viewPreview  
            ?
               <>

                <View style={{ width:'100%', height:'100%',
                    position:"absolute", top:1, left:1, backgroundColor:'black', opacity:.7}}>
                </View>


                <View style={(Platform.OS === 'web' && (!isMobile && !isTablet)) ? styles.modalStyle : styles.modalMobile }>
                        
                        <View style={{flexDirection:'row', justifyContent:'space-between', backgroundColor: '#3385E5'}}>
                                <View style={{flexDirection:'row', paddingLeft:10}}>
                                    <Text style={{color:'white', fontWeight:'bold', paddingBottom:5, paddingTop:5}}>Preview  </Text>
                                </View>

                                <Text  onPress={()=>setViewPreview(!viewPreview)}
                                        style={{color:'white', height:25, 
                                        paddingLeft:5, paddingRight:5, 
                                        marginRight:5, marginTop:5, marginBottom:5,
                                        borderColor:'white', borderWidth:1,
                                        alignItems: 'center', justifyContent: 'center'}}>X</Text>
                        </View>

                        {/* start preview */}
                        {(usrId !== '' && accLvl) ?
                        <ArticleDisplay
                            darkMode={darkMode}
                            content={buildContent()}
                            preview={true}
                            claimed={claimed}
                        />
                        : null}
                        {/* end preview */}

                    </View>                       
               </> 
           :                 
               null
           }
    </>
    )

}

export default UploadForm;