import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  TouchableOpacity,
  TextInput,
  FlatList,
  ScrollView,
  ActivityIndicator,
  ImageBackground
} from "react-native";
import { Platform } from 'react-native';
import { Feather, MaterialCommunityIcons, Ionicons } from "@expo/vector-icons";
import truBadge from '../../../../assets/trusted.png' ;
import comBadge from '../../../../assets/community.png' ;
import AccountHomeUserProfile from "../../core/AccountHomeUserProfile";
import GridRow from "../../core/GridRow";
import AccountHomeBtnList from "../../core/AccountHomeBtnList";
import AccountHomeCustomBtn from "../../core/AccountHomeCustomBtn";
import useAccount from '../../../hooks/useAccount';
import isVideo from '../../../screens/utils/isVideo';
import { WebView } from 'react-native-webview';
import { Context as PubContext } from '../../../context/PubContext';
import { Context as AuthContext } from '../../../context/AuthContext';
import useManagedContent from '../../../hooks/useManagedContent';
import { isMobile, isTablet } from 'react-device-detect';
import predicateSearch from '../../../screens/utils/predicateSearch';
import imagePick from '../../utils/imagePick';
import MeSearchAPI from '../../../api/MeSearch-API';
import alertToConfirm from '../../utils/alertToConfirm';


const UserHomeWrapper = ({ navigation, route, name, imageUri, hasAccounts, horizontal, setHorizontal, darkMode, upload }) => {
  const { state } = useContext(PubContext);
  const { signoutAndSigninToGuest } = useContext(AuthContext);
  const [activeBtnTitle, setActiveBtnTitle] = useState("");
  const [content, adContent, loading, adLoading, error, postMediaApi, setError, getContentApi] = useManagedContent();
  const [searchTxt, setSearchTxt] = useState("");
  const [base64Img, setbase64Img] = useState(null);
  const [imgProcessing, setImgProcessing] = useState(false);
  const tmpBtnList = [
    {
      title: "Articles",
    },
    {
      title: "Ads",
    },
  ];

  const btnClickHandler = (btnTitle) => {
    setActiveBtnTitle(btnTitle);
  };

  useEffect(() => {
    setActiveBtnTitle(tmpBtnList[0].title);
  }, []);

  useEffect(() => {
    setSearchTxt("");
    if (activeBtnTitle === 'Articles') {
        getContentApi();
    }
  }, [activeBtnTitle]);

  const getContentAccessLvl = (content) => {
    return (content.userGen && content.userGen.accessLvl) ? content.userGen.accessLvl : 1;
  }

  const setNewImage = async () => {
    setImgProcessing(true);
    // TODO: HACK 
    // Expo web doesn't resolve the following promise on cancel
    if (Platform.OS === 'web') {
      setTimeout(() => {
        setImgProcessing(false);
      }, [8000])
    }
    let resultUri = await imagePick();
    if (resultUri !== "") {
      try {
        await MeSearchAPI.post('/api/content/upload-display-pic-base64', {image: resultUri});
        setbase64Img(resultUri);
        setImgProcessing(false);
      } catch (err) {
        console.log(err);
        setImgProcessing(false);
      }
    } else {
      setImgProcessing(false);
    }
  }

  return (
    <View>
      <View>
        <View style={{ alignItems: "center" }}>
          <View>
            <View style={{alignItems: "center", justifyContent: "center"}}>
              {!imgProcessing ?
                <>
                  <Image
                    style={{
                      height: (Platform.OS === 'web' && !isMobile) ? 250 : 124,
                      width: (Platform.OS === 'web' && !isMobile) ? 250 : 124,
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 20
                    }}
                    source={{ uri: base64Img ? base64Img : imageUri ? imageUri : "https://cdn.mesearch.ai/assets/stockPhotos/60.jpg" }}
                  />
                  <TouchableOpacity
                    style={{
                      backgroundColor: "rgba(0,0,0,0.6)",
                      position: "absolute",
                      zIndex: 1,
                      width: (Platform.OS === 'web' && !isMobile) ? 250 : 124,
                      height: "30%",
                      bottom: 0,
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottomLeftRadius: 20,
                      borderBottomRightRadius: 20,
                    }}
                    onPress={setNewImage}
                  >
                    <Text style={{ color: "#fff" }}>Edit</Text>
                  </TouchableOpacity>
                </>
              : 
                <View style={{alignItems: 'center', justifyContent: 'center'}}>
                  <ActivityIndicator size="large" color={darkMode ? 'white' : Platform.OS ==='web' ? "#005157" : Platform.OS === 'android' ? '#005157' : 'default'}/>
                  <Text style={{fontSize: 14, color: 'grey'}}>Processing image...</Text>
                </View> 
              }
            </View>
            <View style={{ alignItems: "center" }}>
              <Text style={[{ fontWeight: "bold" }, darkMode ? {color: 'white'} : null]}>{name}</Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          <View style={{ alignItems: "center", marginHorizontal: 15 }}>
            <Text style={[{ fontWeight: "bold" }, darkMode ? {color: 'white'} : null]}>{content.length}</Text>
            <Text style={darkMode ? {color: 'white'} : null}>{content.length === 1 ? 'Article' : 'Articles'}</Text>
          </View>
          <View style={{ alignItems: "center", marginHorizontal: 15 }}>
            <Text style={[{ fontWeight: "bold" }, darkMode ? {color: 'white'} : null]}>{adContent.length}</Text>
            <Text style={darkMode ? {color: 'white'} : null}>{content.length === 1 ? 'Ad' : 'Ads'}</Text>
          </View>
        </View>

        <View
          style={{ alignItems: "center", marginTop: 10, paddingHorizontal: 15 }}
        >
          <View style={{ flexDirection: "row", paddingVertical: 10 }}>
            <TouchableOpacity
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginHorizontal: 10,
                paddingHorizontal: 5,
                paddingVertical: 5,
                borderColor: darkMode ? "white": "#666",
                borderWidth: 1,
                borderRadius: 5
              }}
              onPress={() => setHorizontal(!horizontal)}
            >
              <Feather name={horizontal ? "list" : "grid"} size={24} color={darkMode ? "white" : "black"} />
              <Text style={[{ paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>{horizontal ? "List" : "Grid"}</Text>
            </TouchableOpacity>
            {hasAccounts ?
                <TouchableOpacity
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        marginHorizontal: 10,
                        paddingHorizontal: 5,
                        borderColor: darkMode ? "white" :"#666",
                        borderWidth: 1,
                        borderRadius: 5
                    }}
                    onPress={() => {
                        navigation.navigate('Drawer', {screen: 'Account', params: {screen: 'Passport', params: {darkMode}}});
                    }}
                >
                <Ionicons name="git-network-outline" size={24} color={darkMode ? "white" : "black"} />
                <View style={{ alignItems: "center", justifyContent: "center" }}>
                    <Text style={[{ lineHeight: 12, paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>My</Text>
                    <Text style={[{ lineHeight: 12, paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>
                    Network
                    </Text>
                </View>
                </TouchableOpacity>
            : null}
            {/*
            <TouchableOpacity
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginHorizontal: 10,
                paddingHorizontal: 5,
                borderColor: "#666",
                borderWidth: 1,
                borderRadius: 5
              }}
            >
              <Feather name="heart" size={24} color="#8B0000" />
            </TouchableOpacity>
            */}
            {/*<TouchableOpacity
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginHorizontal: 10,
                paddingHorizontal: 5,
                borderColor: darkMode ? "white" : "#666",
                borderWidth: 1,
                borderColor: "#c9c9c9",
                borderRadius: 5
              }}
              onPress={() => {
                //navigation.navigate('AccountSettings', {darkMode});
              }}
            >
              <View style={{opacity: 0.35}}>
                <Feather name="settings" size={24} color={darkMode ? "white" : "black"} />
              </View>
            </TouchableOpacity>*/}
            <TouchableOpacity
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginHorizontal: 10,
                paddingHorizontal: 5,
                borderColor: darkMode ? "white" : "#666",
                borderWidth: 1,
                borderRadius: 5
              }}
              onPress={() => {
                  if (Platform.OS === 'web') {
                    alertToConfirm('web', "Are you sure you want to logout of your personal membership?", async () => {
                      await signoutAndSigninToGuest({navToLogin: true});
                    })();
                  } else {
                    alertToConfirm('mobile', "Are you sure you want to logout of your personal membership?", async () => {
                      await signoutAndSigninToGuest({navToLogin: true});
                    })();
                  }
                }
              }
            >
              <View>
                <Feather name="log-out" size={24} color={darkMode ? "white" : "black"} />
              </View>
              <View style={{ alignItems: "center", justifyContent: "center" }}>
                <Text style={[{ lineHeight: 12, paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>Log</Text>
                <Text style={[{ lineHeight: 12, paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>
                  Out
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>

        <View>
          <AccountHomeBtnList
            list={tmpBtnList}
            onPress={btnClickHandler}
            activeBtnTitle={activeBtnTitle}
          />
        </View>

        <View style={{ paddingHorizontal: 5, marginTop: 10}}>
          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            {activeBtnTitle && activeBtnTitle === 'Articles' && (state.publisher.ugcPermitted || (typeof state.publisher.ugcPermitted === 'undefined')) && upload ?
              <TouchableOpacity
                style={{ flexDirection: "row", alignItems: "center", padding: 10 }}
                onPress={() => {
                  navigation.navigate('UploadContent', {displayForm: true, darkMode, isGuest: false});
                }}
              >
                <Feather name="plus-circle" size={24} color={darkMode ? "white" : "#0984e3"} />
                <Text style={[{ paddingLeft: 5 }, darkMode ? {color: 'white'} : null]}>Article</Text>
              </TouchableOpacity>
            : null}
            <View
              style={{ flexDirection: "row", justifyContent: "flex-end" }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  borderWidth: 1,
                  borderColor: darkMode ? "white" : "#666",
                  width: 200,
                  height: 30
                }}
              >
                <Feather name="search" size={24} color={darkMode ? "white" : "#666"} />
                <TextInput
                  style={{
                    flex: 1,
                  }}
                  placeholder="search"
                  value={searchTxt}
                  onChangeText={(txt) => {
                    setSearchTxt(txt);
                  }}
                />
                <Feather name="chevron-right" size={24} color={darkMode ? "white" : "#666"} />
              </View>
            </View>
          </View>
        </View>

        <View style={{paddingTop : 5}}>
            {activeBtnTitle === 'Articles' ?
                <FlatList
                    data={predicateSearch(
                        content, 
                        searchTxt, 
                        {source: 1, body_id: 1, body: 1, tags: 1, targeting: 1, meTags: 1, type: 1, 
                          source_native_id: 1, userGen: 1, contentDate: 1, mediaMeta: 1, bodyLinks: 1, status: 1, remDate: 1, price: 1, quantity: 1}
                    )}
                    horizontal={horizontal}
                    showsHorizontalScrollIndicator={(Platform.OS === 'web' && (!isMobile && !isTablet)) ? true : false}
                    ListHeaderComponent={
                        () => {
                        return (
                            <View style={{justifyContent: 'center', alignItems: 'center'}}>
                            {loading 
                            ? <ActivityIndicator size="large" color={Platform.OS === 'android' ? "#005157" : null}/>
                            : null}
                            </View>
                        );
                        }
                    }
                    keyExtractor={(item) => item._id }
                    renderItem={(item) => {
                        return (
                            <TouchableOpacity style={styles.container} 
                              onPress={()=> {
                                navigation.navigate('UploadContent', {curContent: item.item, darkMode, displayForm: true});
                              }}
                            >
                                { isVideo(item.item.body[0].media[0])
                                ?
                                <>
                                    <View>
                                    {Platform.OS === 'web'
                                        ?<iframe 
                                        width="250" 
                                        height="180"
                                        src={`${item.item.body[0].media[0]}`}
                                        frameBorder="0"
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
                                        allowFullScreen
                                        />
                                        : <WebView source={{uri: item.item.body[0].media.length > 0 ? item.item.body[0].media[0] : 'https://cdn.mesearch.ai/assets/media/sample_Clouds.png'}} style={{width: 250, height: 180}}/>}
                                    </View>
                                </>
                                :
                                    <ImageBackground 
                                        style={styles.image}
                                        imageStyle={{ borderRadius: 4 }}
                                        source={{uri: item.item.body[0].media.length > 0 ? item.item.body[0].media[0] : 'https://cdn.mesearch.ai/assets/media/sample_Clouds.png'}}
                                        onError={(err) => {
                                            console.log(err)
                                    }}
                                    >
                                    <View style={{backgroundColor: 'black', width: 100, height: 30, padding:3, borderRadius: 4 }}>
                                        <Image
                                        style={styles.badgeImage}
                                        imageStyle={{ borderRadius: 4 }}
                                        source={getContentAccessLvl(item.item) > 1 ? truBadge : comBadge}
                                        />             
                                    </View>
                                    </ImageBackground>
                                }

                                <View style={{height: 70, width: 250, marginBottom:20, backgroundColor: '#000000', opacity: 0.9}}>
                                    <View style={{ height: 50, paddingTop: 1, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                                        <Text numberOfLines={2} style={styles.name} >{item.item.body[0].title}</Text>
                                    </View>
                                    <View 
                                      style={{ 
                                          flexDirection:'row', 
                                          justifyContent:'space-between', 
                                          height: 30, 
                                          width: 250, 
                                          marginTop: -4, 
                                          backgroundColor: '#000000', 
                                          opacity: 0.9, 
                                          borderBottomLeftRadius: 4, 
                                          borderBottomRightRadius: 4
                                        }}
                                    >
                                        <View style={{ flexDirection:'row', paddingLeft: 5,}}>
                                            <Image
                                            style={{height: 20, width: 35, paddingLeft: 15, paddingBottom: 5}}
                                            imageStyle={{ borderRadius: 4 }}
                                            source= {{uri: state.publisher.badgeSourceMap[item.item.source]}}
                                            onError={(err) => {
                                                console.log(err)
                                            }}
                                            />
                                            <Text numberOfLines={1} style={styles.textDescStyle} >By {item.item.body[0].authors[0]}</Text>
                                        </View>
                                        {getContentAccessLvl(item.item) > 1 ? <Text style={{color:'white', paddingRight:5, fontSize:10, display:'none' }}>Trusted{"\n"}Contributor</Text> : <Text style={{color:'white', paddingRight:5, fontSize:10, display:'none'}}>Community{"\n"}Contributor</Text> }
                                    </View>
                                </View>
                            </TouchableOpacity>
                            )
                        }
                        }
                    />
                : null}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
    badgeImage: {
        width: 75,
        height: 20,
        marginTop: 2,
        marginLeft: 5,
        paddingLeft: 5,
        backgroundColor: 'black'
    },
    container: {
        marginLeft: 15,
        
        justifyContent:'center', 
        flexDirection:'column',
    },
    image: {
        width: 250,
        height: 180,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        marginBottom: -2,
        },

    textDescStyle: {
        color: 'white',
        fontWeight: '300',
        fontSize: 14,
        paddingBottom: 5,
        paddingLeft: 5,
        paddingRight: 10
    },
    name: {
        width: 250,
        height: 42,
        fontWeight: 'bold',
        color: 'white',
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
    },
  });

export default UserHomeWrapper;