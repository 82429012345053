import React, { useContext, useState, useEffect } from 'react';
import  { Text, Button, Input, CheckBox } from 'react-native-elements';
import {Picker, StyleSheet, Image, FlatList, TextInput, Dimensions, View, ScrollView, SafeAreaView, ImageBackground, TouchableOpacity } from 'react-native';
import useKioskResults from '../../hooks/kiosk/useKioskResults';

const codingPanel = ({ navigation, setViewCoding, viewCoding, accountID, ke_id }) => {

   const [codingData, setCodingDat] = useState([]);
   const [  accounts, accountGrpDetails,
            accountGrpSets, accountCodingFrm, 
            accountCodingData, loading, error, 
            getAccounts, getAccountGrpDetails, 
            getGroupSets, getCodingFrm, 
            getCodingData, setCodingData, putCodingData, 
            saveCodingSuccess, setSaveCodingSuccess,
            getPortfolioItems, getPdfBase64, portfolioItems, pdfBase64,
            accountCodingHistory, getCodingHistory,
            guid, setGuid, getSearch,searchResults] = useKioskResults();

   const setValue = (key, val) => {
      const newChkBoxes = JSON.parse(JSON.stringify(codingData));
      newChkBoxes.forEach((el,index) => {
        if (el.name === key) {
            if (el.type ==='checkbox'){
               if (el.logic==='true'){
                  newChkBoxes.forEach((obj,i) => {
                     if(obj.context===el.context)
                        newChkBoxes[i].value = false
                  })
               }
               newChkBoxes[index].value = !newChkBoxes[index].value;
            }
            else{
               newChkBoxes[index].value = val;  
            }
 
        }
      });
      
      setCodingDat(newChkBoxes);
  };

   useEffect(() => {
     getCodingFrm(accountID);
     getCodingData(ke_id);
     getCodingHistory(ke_id);
     getSearch('purple', 4);
   }, []);

   useEffect(() => {
      // console.log('useeffect acctdata')
      // console.log(accountCodingData)
      // console.log('useeffect accountCodingFrm')
      // console.log(accountCodingFrm)

      if (Object.keys(accountCodingFrm).length > 0) {
         if (typeof accountCodingData === 'object' && Object.keys(accountCodingData).length > 0){

            Object.entries(accountCodingData).forEach((impr) => {
               // console.log(impr)
               let key = impr[0]
               let val = impr[1]

               codingData.forEach((el,index) => {
                  if (el.name === key) {
                     el.value = val
                  }
                  });

               });
         }
      }

   }, [accountCodingData, accountCodingFrm]);

   useEffect(() => {

        let coding = []
        if (accountCodingFrm && accountCodingFrm.doc && accountCodingFrm.doc.form && accountCodingFrm.doc.form.header) {
         accountCodingFrm.doc.form.header.forEach((item)=>{
               
               let section = item.caption
               let logic = item.OneClickCoding

               if (item.input.length){
                     item.input.map(row => {
                        if (row.type ==='checkbox')         
                           coding.push({context:section, type:`${row.type}`, logic:logic, label:`${row.caption}`, name:`${row.name}`, value:false})
                        else
                           coding.push({context:section, type:`${row.type}`, logic:'null', label:`${row.caption}`, name:`${row.name}`, value:''})  
                        })
               }
               else {
                  if (item.input.type ==='checkbox')         
                     coding.push({context:section, type:`${item.input.type}`, logic:logic, label:`${item.input.caption}`, name:`${item.input.name}`, value:false})
                  else
                     coding.push({context:section, type:`${item.input.type}`, logic:'null', label:`${item.input.caption}`, name:`${item.input.name}`, value:''}) 
               }
            }
         )
      }
      // set coding array
      
      if (coding.length > 0 ){
         setCodingDat(coding)  
      }

   }, [accountCodingFrm]); 

   const saveCoding = () =>{
      let newAccountCodingData =JSON.parse(JSON.stringify(accountCodingData));
      codingData.forEach(obj => {
        newAccountCodingData[`${obj.name}`] = obj.value;
      });
      putCodingData( ke_id, newAccountCodingData)

   }

   const codingHistory = () =>{
      alert('history'+ JSON.stringify(accountCodingHistory))
   }

   let hdr = ""
   let lasthdr = ""

    return (

      <View style={{padding:10, }}>
         <>
         {(saveCodingSuccess !== null) 
         ? saveCodingSuccess ? <Text>Save Successful</Text> : <Text>Error Saving</Text>
         : null}
         </>
         <View style={{flexDirection:'row', justifyContent:'space-between', backgroundColor: '#3385E5'}}>
            <Text style={{fontWeight:'bold', color:'white', padding:10}}>Coding</Text>

            <View style={{flexDirection:'row', justifyContent:'space-between',}}>
               <Text style={{fontWeight:'bold', color:'white', padding:10}} onPress={ () => codingHistory() }>History</Text>
               <Text style={{fontWeight:'bold', color:'white', padding:10}} onPress={ () => saveCoding() }>Save</Text>
               <Text  onPress={()=>setViewCoding(!viewCoding)}
               style={{color:'white', height:25, 
               paddingLeft:5, paddingRight:5, 
               marginRight:5, marginTop:10, marginBottom:5,
               //borderColor:'white', borderWidth:1, 
               alignItems: 'center', justifyContent: 'center'}}>X</Text>
            </View>
         </View>

         <FlatList
            data={codingData}
            horizontal={false}
            keyExtractor={(item) => item.name }
            renderItem={({item}) => {
               
               if (hdr!=item.context){
                  hdr=item.context
               }
               else 
                  lasthdr = hdr
                  
               return (
                     <View style={{flex: 1, }}>

                           <View style={{flexDirection:'column', flex: 1,}}>
                              <View style={{flexDirection:'row'}}>
                                 {lasthdr!=item.context
                                    ? <View style={{flex:1, borderTopColor: 'gray', borderTopWidth: 1,}}>
                                       <Text style={{ fontSize: 15, fontWeight: "bold",  color: '#2089dc'}}>{item.context}</Text>
                                       </View>
                                    : null
                                 }                               
                              </View>    

                           <View style={{flexDirection:'column', flex: 1,}}>
                              <View style={{flexDirection:'row'}}>

                              {item.type==='checkbox'
                                    ?
                                       <CheckBox
                                       containerStyle={{flex:1, marginLeft:-10}}
                                       textStyle={{color: 'black'}}
                                       title={item.label}
                                       onPress={() => {
                                          setValue(item.name, item.value);
                                       }}
                                       checked={item.value}
                                    />
                                    :
                                       <TextInput 
                                       placeholder={item.label}
                                       placeholderTextColor='grey'
                                       autoCapitalize="none"
                                       autoCorrect={true}
                                       value={item.value}
                                       onChangeText={(value) => {
                                          setValue(item.name, value)
                                       }}
                                       style={{flex:1, justifyContent: "flex-start", height:140, borderColor:'silver', borderWidth:3}}
                                       multiline={true}/>
                              }

                              </View>

                           </View>                    
                        </View>

                     </View>
               )
            }
            }
         />
         <View style={{flexDirection:'row', justifyContent:'space-between',}}>
            <Text style={{fontSize:10}}>Acct:{accountID}</Text>
            <Text style={{fontSize:10}}>Ke_id:{ke_id}</Text>
         </View>

      </View>

        )
  };

  // Styles
const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    containerSmall: {
      flex: 1,
      justifyContent: 'center',
      paddingBottom: 50,
      //backgroundColor: darkMode ? '#3c3c3d' : 'white'
    },
    containerLarge: {
      width: Dimensions.get('window').width,
      //backgroundColor: darkMode ? '#3c3c3d' : 'white',
      paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
      paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
      justifyContent: 'center',
      paddingBottom: 150,
      marginTop:20,
      //opacity: viewModal ? 0.4 : 1.0
    },    
  });

  export default codingPanel;