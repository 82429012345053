import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import TrustedContributorScreen from '../screens/TrustedContributorScreen';
import ApproveTrustedContributorScreen from '../screens/ApproveTrustedContributorScreen';
import LandingPageScreen from '../screens/PublisherLandingPage';
import ResolveAuthScreen from '../screens/ResolveAuthScreen';
import DidNotResetPasswordScreen from '../screens/DidNotResetPasswordScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import UserMessageScreen from '../screens/UserMessageScreen';
//import DrawerNavigator from './drawerNavigator';
import pubFlow from './stackNavigators/pubFlow';
import pubOwnerFlow from './stackNavigators/pubOwnerFlow';
import { setNavigator } from '../navigationRef';
import kioskFlow from './stackNavigators/kioskFlow';
import kioskLoginFlow from './stackNavigators/kioskLoginFlow';
import ClaimBusinessScreen from '../screens/ClaimBusinessScreen';
import ApproveBusinessClaimScreen from '../screens/ApproveBusinessClaimScreen';
import ConfirmEmailScreen from '../screens/ConfirmEmailScreen';
import ConfirmEmailHoldingScreen from '../screens/ConfirmEmailHoldingScreen';
import TabNavigator from './tabNavigator';
import accountFlow from './stackNavigators/accountFlow';
import PubChartScreen from '../screens/PubChartsScreen';
import DashboardScreen from '../screens/Dashboard';
import PubTagsScreen from '../screens/PubTagsStatScreen';

const Stack = createStackNavigator();

class mainNavigator extends React.Component {
    
    render() {

        return (
            <NavigationContainer ref={(navigator) => {setNavigator(navigator)}}>
            <Stack.Navigator
              initialRouteName={'ResolveAuth'}
              screenOptions={{
                title: "MeSearch",
              }}
            >
                <Stack.Screen
                  name="ResolveAuth"
                  options={{headerShown: false}}
                >
                  { ({navigation, route}) => <ResolveAuthScreen navigation={navigation} route={route}/> }
                </Stack.Screen>
                <Stack.Screen
                  name="ResetPassword"
                  component={ResetPasswordScreen}
                  options={{headerShown: false}}
                />
                <Stack.Screen
                  name="ForgotPassword"
                  component={ForgotPasswordScreen}
                  options={{headerShown: false}}
                />
                <Stack.Screen
                  name="TrustedContributor"
                  component={TrustedContributorScreen}
                  options={{headerShown: false}}
                />
                <Stack.Screen
                  name="ApproveTrustedContributor"
                  component={ApproveTrustedContributorScreen}
                  options={{headerShown: false}}
                />
                <Stack.Screen
                  name="DidNotResetPassword"
                  component={DidNotResetPasswordScreen}
                  options={{headerShown: false}}
                />
                <Stack.Screen
                  name="LandingPage"
                  component={LandingPageScreen}
                  options={{headerShown: false}}
                />
                <Stack.Screen
                  name="UserMessage"
                  component={UserMessageScreen}
                  options={{headerShown: false}}
                />
                <Stack.Screen
                  name="ClaimBusiness"
                  component={ClaimBusinessScreen}
                  options={{headerShown: false}}
                />
                <Stack.Screen
                  name="ApproveBusinessClaim"
                  component={ApproveBusinessClaimScreen}
                  options={{headerShown: false}}
                />
                <Stack.Screen
                  name="Publisher"
                  component={pubOwnerFlow}
                  options={{headerShown: false}}
                />
                <Stack.Screen
                  name="Publication"
                  component={pubFlow}
                  options={{headerShown: false}}
                />
                <Stack.Screen
                  name="ConfirmEmail"
                  component={ConfirmEmailScreen}
                  options={{headerShown: false}}
                />
                <Stack.Screen
                  name="ConfirmEmailHolding"
                  component={ConfirmEmailHoldingScreen}
                  options={{headerShown: false}}
                />
                <Stack.Screen
                  name="Drawer"
                  component={TabNavigator}
                  options={{headerShown: false}}
                />
                {/*
                <Stack.Screen
                  name="Drawer"
                  component={DrawerNavigator}
                  options={{headerShown: false}}
                />
                */}
                <Stack.Screen
                  name="kioskLoginFlow"
                  component={kioskLoginFlow}
                  options={{headerShown: false}}
                />
                <Stack.Screen
                  name="kioskFlow"
                  component={kioskFlow}
                  options={{headerShown: false}}
                />
                <Stack.Screen
                  name="charts"
                  component={PubChartScreen}
                  options={{headerShown: true,
                  headerTitle:"Account"
                  }}
                />
                <Stack.Screen
                  name="analytics"
                  component={DashboardScreen}
                  options={{headerShown: true,
                  headerTitle:"Account"
                  }}
                />
                <Stack.Screen
                  name="tags"
                  component={PubTagsScreen}
                  options={{headerShown: true,
                  headerTitle:"Account"
                  }}
                />
            </Stack.Navigator>
            </NavigationContainer>
        );
    }
};

export default mainNavigator;