import React, { useRef, useState, useEffect } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  TouchableOpacity,
  TextInput,
  FlatList,
  Platform,
} from "react-native";
import { Feather, MaterialCommunityIcons, Ionicons } from "@expo/vector-icons";
import MeSearchAPI from '../../api/MeSearch-API';
import { isMobile, isTablet } from "react-device-detect";
import alertToConfirm from '../utils/alertToConfirm';

const AccountHomeUserProfile = ({ profiles, title, email, myAccLvl, pubOwner, setAdmins, admins, adminCapability, horizontal, darkMode, setSuccessMsg, setErrorMsg, kiosk, removeFunc }) => {
  let flatListRef = useRef(0);
  const [currOffset, setCurrOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [imgErrors, setImgErrors] = useState({});
  const scroll = () => {
    flatListRef.current.scrollToOffset({
      offset: currOffset,
      animated: true,
    });
  };

  useEffect(() => {
    scroll();
  }, [currOffset]);

  return (
    <View style={styles.container}>
      {profiles.length > 0 ? <Text style={[styles.title, darkMode ? {color: 'white'} : null]}>{title}</Text> : null}
      <View style={styles.inner}>
        {count > 0 && Platform.OS === "web" ? (
          <TouchableOpacity
            style={styles.arrow}
            onPress={() => {
              if (count > 0) {
                setCurrOffset(currOffset - 250);
                setCount(count - 1);
              }
            }}
          >
            <Feather name="chevron-left" size={24} color="#fff" />
          </TouchableOpacity>
        ) : null}
        <FlatList
          horizontal={horizontal}
          ref={flatListRef}
          showsHorizontalScrollIndicator={false}
          data={profiles}
          keyExtractor={(item) => item.username}
          renderItem={({ item }) => {
            return (
              <View
                style={[
                  styles.profile,
                  item.accessLvl === 5
                    ? { backgroundColor: "#27ae60" }
                    : item.accessLvl === 4
                    ? { backgroundColor: "#8B0000" }
                    : item.accessLvl === 3
                    ? { backgroundColor: "#3498db" }
                    : { backgroundColor: "#7f8c8d" },
                    { width: 250 },
                ]}
              >
                <View style={styles.upper}>
                  <Image 
                    style={styles.img}
                    source={{ uri: (imgErrors[`${item.username}`] && kiosk && Platform.OS !== 'web') ? `https://cdn.mesearch.ai/assets/stockPhotos/alpha/${item.username.substring(0, 1).toLowerCase()}.jpg` : item.displayPicUri ? item.displayPicUri :  "https://cdn.mesearch.ai/assets/stockPhotos/60.jpg" }} 
                    defaultSource={Platform.OS === 'web' ? {uri: `https://cdn.mesearch.ai/assets/stockPhotos/alpha/${item.username.substring(0, 1).toLowerCase()}.jpg`} : null}
                    onError={() => {
                      if (Platform.OS !== 'web') {
                        let newImgErrors = JSON.parse(JSON.stringify(imgErrors));
                        newImgErrors[`${item.username}`] = true;
                        setImgErrors(newImgErrors);
                      }
                    }} 
                  />
                  <View style={styles.userInfo}>
                    <Text style={styles.userName}>
                      {item.displayName ? 
                        <>
                          {
                            item.displayName.length >= 20
                              ? item.displayName.substring(0, 17) + "..."
                              : item.displayName
                          }
                        </>
                      : 
                      <>
                        {
                          item.username.length >= 20
                            ? item.username.substring(0, 17) + "..."
                            : item.username
                        }
                      </>
                      }
                    
                    </Text>
                    <Text style={styles.userTitle}>{`${item.accessLvl < 4 ? 'Basic Admin' : item.accessLvl >= 5 ? 'Owner' : 'Pro Admin'}`}</Text>
                  </View>
                </View>
                <View style={styles.lower}>
                  <View style={styles.contentWrapper}>
                    <View style={styles.content}>
                      <Text style={styles.text}>{`Restricted Access: ${!kiosk ? item.accessLvl < 4 ? 'Yes' : 'No' : item.IsRestrictedDisclosure === 'Yes' ? 'Yes' : 'No'}`}</Text>
                      <Text style={styles.text}>
                      {`Restricted Capabilities: ${!kiosk ? (item.systemCapabilities && item.systemCapabilities.some(c => !c.value)) ? 'Yes' : 'No' : item.IsRestrictedCapabilies === 'Yes' ? 'Yes' : 'No'}`}
                      </Text>
                    </View>
                    <View style={styles.content}>
                      <Text style={styles.text}>{email}</Text>
                    </View>
                  </View>
                  {(myAccLvl >= 4 && myAccLvl >= item.accessLvl && adminCapability && item.accessLvl != 5) ?
                    <View style={{flexDirection: 'row'}}>
                      {/*<TouchableOpacity 
                        style={styles.btn}
                        onPress={async () => {
                          console.log("Edit User");
                        }}
                      >
                        <Text style={styles.btnText}>Edit</Text>
                    </TouchableOpacity>*/}
                    {Platform.OS === 'web' ?
                      <TouchableOpacity 
                        style={styles.btn}
                        onPress={async () => {
                          alertToConfirm('web', "Are you sure you want to remove this User?", async () => {
                            try {
                              !kiosk 
                              ? await MeSearchAPI.delete(
                                '/api/user/remove-from-account', 
                                { params : {username: item.username, account: pubOwner } })
                              : await removeFunc();
                              setAdmins(admins.filter(a => a.username !== item.username));
                              setSuccessMsg("Succesfully removed user");
                            } catch (err) {
                              setErrorMsg("Failed to remove user");
                              console.log(err);
                            }
                          })();
                    
                        }}
                      >
                        <Text style={styles.btnText}>Remove</Text>
                      </TouchableOpacity>
                    : 
                      <TouchableOpacity 
                        style={styles.btn}
                        onPress={async () => {
                          alertToConfirm('mobile', "Are you sure you want to remove this User?", async () => {
                            try {
                              !kiosk 
                              ? await MeSearchAPI.delete(
                                '/api/user/remove-from-account', 
                                { params : {username: item.username, account: pubOwner } })
                              : await removeFunc();
                              setAdmins(admins.filter(a => a.username !== item.username));
                              setSuccessMsg("Succesfully removed user");
                            } catch (err) {
                              setErrorMsg("Failed to remove user");
                              console.log(err);
                            }
                          })();
                    
                        }}
                      >
                        <Text style={styles.btnText}>Remove</Text>
                      </TouchableOpacity>
                    }
                  </View>
                  : null}
                </View>
              </View>
            );
          }}
        />
        {profiles.length > 3 &&
        (Platform.OS === "web" && (!isMobile && !isTablet)) &&
        count <= Math.ceil(profiles.length / 3) &&
        horizontal
         ? (
          <TouchableOpacity
            style={styles.arrow}
            onPress={() => {
              if (count <= Math.ceil(profiles.length / 3)) {
                setCurrOffset(currOffset + 250);
                setCount(count + 1);
              }
            }}
          >
            <Feather name="chevron-right" size={24} color="#fff" />
          </TouchableOpacity>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    //flex:1,
    //backgroundColor:'#d63031',
    //marginHorizontal:10,
    // justifyContent:'center',
    // alignItems:'center',
    //paddingVertical:5,
  },
  inner: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    paddingHorizontal: 5,
    // borderColor:'red',
    // borderWidth:1,
  },
  title: {
    paddingVertical: 5,
    marginHorizontal: 5,
  },
  profile: {
    marginHorizontal: 5,
    marginVertical: 5,
    height: (Platform.OS === 'web' && !isMobile) ? 180 : 200
  },
  upper: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderBottomColor: "#fff",
    borderBottomWidth: 1,
  },
  img: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
  },
  userInfo: {
    paddingLeft: 5,
  },
  userName: {
    color: "#fff",
    fontWeight: "600",
    fontSize: 15,
  },
  userTitle: {
    color: "#fff",
    fontSize: 10,
  },
  lower: {
    //flexDirection: "row",
    //alignItems: "center",
  },
  contentWrapper: {
    paddingHorizontal: 5,
    paddingVertical: 10,
  },
  content: {
    paddingBottom: 10,
  },
  text: {
    color: "#fff",
    fontSize: 10,
  },
  btn: {
    borderColor: "#fff",
    borderWidth: 1,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 10,
    width: 85
  },
  btnText: {
    color: "#fff",
    textAlign: "center",
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  arrow: {
    backgroundColor: "#666",
    height: "94%",
    justifyContent: "center",
  },
});

export default AccountHomeUserProfile;
