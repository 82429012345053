import React, { createRef, useState, useContext } from 'react';
import { View, Text, StyleSheet, FlatList, TouchableOpacity } from 'react-native';
import PublicationDetail from './PublicationDetail';
import { Feather } from '@expo/vector-icons';
import { isMobile, isTablet } from 'react-device-detect';
import { Platform } from 'react-native';
import MeSearchAPI from '../api/MeSearch-API';
import { Context as PubContext } from '../context/PubContext';
import { Context as AuthContext } from '../context/AuthContext';

const PublicationsList = ({ publications, tagBarColor, tagTitle, navigation }) => {
  const [offset, setOffset] = useState(0);
  const [prevOffset, setPrevOffset] = useState(0);
  const { addPublisher, addRef, errorMsg } = useContext(PubContext);
  const { tryLocalSignin } = useContext(AuthContext);

  if (!publications.length) {
    return null;
  }
  const flatlist = createRef();
  const styles = StyleSheet.create({
    titleStyle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'white',
        paddingLeft: 5,
        marginLeft: 15,
        marginBottom: 5,
        backgroundColor: tagBarColor,
        borderRadius: 4,
        overflow: 'hidden'
    },
    container: {
      flexDirection: 'row',
      marginTop: 10,
      marginBottom: 10,
    },
    containerTwo: {
      marginTop: 10,
      marginBottom: 10
    }
  });

  function handleScroll(event) {
    setPrevOffset(offset);
    setOffset(event.nativeEvent.contentOffset.x);
  }

  function updateUserPubs(userPubs, pub) {
    if (!userPubs) {
        userPubs = [];
    }
    const timeStamp = new Date().getTime();
    let usrPubIndex = userPubs.findIndex(u => u.pubName == pub);
    if (usrPubIndex > -1) {
        userPubs[usrPubIndex].value += 1;
        userPubs[usrPubIndex].lastVisited = timeStamp;
    } else {
        userPubs.push({pubName: pub, value: 1, lastVisited: timeStamp});
    }
    return userPubs;
  };

  async function updateUserParams(pub) {
    const response = await MeSearchAPI.get('/api/user');
    const updatedPubs = updateUserPubs(response.data.params.pubs, pub);
    await MeSearchAPI.patch(
        '/api/user', 
        { payload: { 
            params: {
                contentTags: response.data.params.contentTags,
                currentLocation: response.data.params.currentLocation,
                homeLocation: response.data.params.homeLocation,
                profileTags: response.data.params.profileTags,
                pubs: updatedPubs
            }
        } 
    });
  }
  
  if (Platform.OS === 'web' && (!isMobile && !isTablet)) {
    // TODO: Make the following two render returns components and import
    return (
      <>
        <View style={{marginBottom: 10}}>
            <Text style={styles.titleStyle}>{tagTitle}</Text>
        </View>
        <View style={styles.container}>
            {offset > 50
            ?<View style={{flexDirection: 'column', backgroundColor: '#ededf2'}}>
            <TouchableOpacity style={{flex: 1}}
            onPress={() => {
                (offset - 500) < 0 ? setOffset(0) : setOffset(offset - 500);
                flatlist.current.scrollToOffset({offset: offset-500, animated: true});
            }}
            >
                <Feather name="arrow-left" style={{fontSize: 35, color: '#a6abad', width: 35, marginTop: 80}}/>
            </TouchableOpacity>
            </View>
            : null}
            <View style={{flex: 1}}>
            <FlatList
            onScroll={handleScroll}
            ref={flatlist}
            showsHorizontalScrollIndicator={false}
            horizontal={true}
            data={publications}
            keyExtractor={(pub) => pub.pubName}
            renderItem={({ item }) => {
                return (
                    <TouchableOpacity
                        onPress={function () {
                          updateUserParams(item.pubName);
                          window.open(item.uri);
                        }}
                        >
                        <PublicationDetail publication={item}/>
                    </TouchableOpacity>
                );
            }}
            />
            </View>
            <View style={{flexDirection: 'column'}}>
                <TouchableOpacity style={{flex: 1, backgroundColor: '#ededf2'}}
                onPress={() => {
                    // TODO: set an upper limit for offset
                    setOffset(offset + 500);
                    flatlist.current.scrollToOffset({offset: offset+500, animated: true});
                }}
                >
                <Feather name="arrow-right" style={{fontSize: 35, color: '#a6abad', width: 35, marginTop: 80}}/>
                </TouchableOpacity>
            </View>
        </View>
      </>
    );
  } else {
    return (
      <View style={styles.containerTwo}>
         <Text style={styles.titleStyle}>{tagTitle}</Text>
        <View style={{flexDirection: 'row'}}>
            <FlatList
            onScroll={handleScroll}
            ref={flatlist}
            showsHorizontalScrollIndicator={false}
            horizontal={true}
            data={publications}
            keyExtractor={(pub) => pub.pubName}
            renderItem={({ item }) => {
                return (
                    <TouchableOpacity
                        onPress={async () => {
                            updateUserParams(item.pubName);
                            if (Platform.OS === 'web') {
                              window.open(item.uri);
                            } else {
                              await tryLocalSignin({referrer: item.uri, nav: false});
                              await addPublisher({referrer: item.uri});
                              await addRef({referrer: item.uri});
                              navigation.navigate('Drawer', {screen: 'Home', params: {screen: 'Home', params: {screen: 'Search'}}});
                            }
                        }}
                        >
                        <PublicationDetail publication={item}/>
                    </TouchableOpacity>
                );
            }}
            />
        </View>
      </View>
    );
  }
};

export default PublicationsList;