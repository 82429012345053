import React, { useState, useEffect } from 'react';
import  { Text, Button, Input } from 'react-native-elements';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, ActivityIndicator, ScrollView } from 'react-native';
import Spacer from '../components/Space';
import MeSearchAPI from '../api/MeSearch-API';
import CopyrightFooter from '../components/CopyrightFooter';
import { Platform } from 'react-native';


const EditPublisherScreen = ({navigation, route}) => {
  const pub = route.params?.pub ?? '';
  const publisher = route.params?.publisher ?? '';
  const [errorMessage, setErrorMsg] = useState("");
  const [successMessage, setSuccessMsg] = useState("");
  const [pubTags, setPubTags] = useState("");
  const [oldPub, setOldPub] = useState(null);
  const [trackingCode, setTrackingCode] = useState("");
  //const [username, setUsername] = useState("");
  //const [password, setPassword] = useState(null);
  const [ugcPubTags, setUGCPubTags] = useState("");
  const [ugcOptPubTags, setUGCOPTPubTags] = useState("");
  const [ugcSource, setUGCSource] = useState("");
  const [assetTopHeaderWeb, setAssetTopHeaderWeb] = useState("");
  const [assetTopHeaderMobile, setAssetTopHeaderMobile] = useState("");
  const [assetTopHeaderWebDarkMode, setAssetTopHeaderWebDarkMode] = useState("");
  const [assetTopHeaderMobileDarkMode, setAssetTopHeaderMobileDarkMode] = useState("");
  const [defaultUserTags, setDefaultUserTags] = useState("");
  //const [headline, setHeadline] = useState(true);
  //const [breaking, setBreaking] = useState(true);
  const [tagBarColor, setTagBarColor] = useState("");
  const [defaultImage, setDefaultImage] = useState("");
  const [mandatoryTag, setMandatoryTag] = useState("");
  const [domains, setDomains] = useState("");
  const [sources, setSources] = useState("");
  const [blacklistTags, setBlacklistTags] = useState("");
  const [tcEmail, setTCEmail] = useState("");
  const [nEmail, setNEmail] = useState("");
  const [ pgLoad, setPGLoad ] = useState("");
  const [badgeSourceMap, setBadgeSourceMap ] = useState("");
  const [frameViewSources, setFrameViewSources ] = useState("");
  const [linkViewSources, setLinkViewSources ] = useState("");
  const [viewSources, setViewSources ] = useState("");
  const [nonPubSources, setNonPubSources ] = useState("");
  const [contentAge, setContentAge ] = useState("14");
  const [pubSharing, setPubSharing ] = useState("");

  useEffect (() => {
    try {
        const responseProm = MeSearchAPI.get('/api/pub',  
          {params: {
            pub: pub
          }
        });
        responseProm.then(response => {
            setOldPub(response.data);
            if (response.data) {
                setPubTags(response.data.pubTags.toString());
                setTrackingCode(response.data.trackingCode);
                //setUsername(response.data.username);
                setUGCPubTags(response.data.ugcPubTags.toString());
                setUGCOPTPubTags(response.data.ugcOptPubTags.toString());
                setUGCSource(response.data.ugcSource);
                setAssetTopHeaderWeb(response.data.assets[0].link);
                setAssetTopHeaderMobile(response.data.assets[1].link);
                setAssetTopHeaderWebDarkMode(response.data.assets[2].link);
                setAssetTopHeaderMobileDarkMode(response.data.assets[3].link);
                setTagBarColor(response.data.tagBarColor);
                setDefaultImage(response.data.defaultImage);
                setMandatoryTag(response.data.mandatoryTag);
                setDomains(response.data.domains.toString());
                setSources(response.data.sources.toString());
                setBlacklistTags(response.data.blacklistTags.toString());
                setTCEmail(response.data.tcEmail);
                setNEmail(response.data.nEmail);
                setFrameViewSources(response.data.frameViewSources.toString());
                setLinkViewSources(response.data.linkViewSources.toString());
                setViewSources(response.data.viewSources.toString());
                setNonPubSources(response.data.nonPubSources.toString());
                setContentAge(response.data.contentAgeRange ? `${response.data.contentAgeRange}` : "14");
                let defUsrTags = [];
                response.data.defaultUserTags.forEach(defaultUsrTag => {
                  defUsrTags.push(defaultUsrTag.conTag);
                });
                let bSourceMapArr = [];
                setDefaultUserTags(defUsrTags.toString());
                Object.keys(response.data.badgeSourceMap).forEach(key => {
                    bSourceMapArr.push(`${key};${response.data.badgeSourceMap[key]}`);
                });
                setBadgeSourceMap(bSourceMapArr.toString());
                let pSharingArr = [];
                if (response.data.shareTo && response.data.shareTo.length > 0) {
                  response.data.shareTo.forEach(shareObj => {
                    pSharingArr.push(`${shareObj.pub};${shareObj.tags.toString()}`);
                  });
                }
                setPubSharing(pSharingArr.toString());
            }
        });
    } catch (err) {
        setErrorMsg(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`);
    }
  }, []);

  return (
    <ScrollView contentContainerStyle={Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall}>
    {!pgLoad ?
    <>
      <Spacer/>
      {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
      {successMessage ? <Text style={styles.successMessage}>{successMessage}</Text> : null}
      {/*<Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>username:</Text>
      <Spacer>
        <Input 
          label="Username"
          value={username}
          onChangeText={(text) => setUsername(text.trim())}
          autoCorrect={false}
          autoCapitalize="none"
          maxLength={80}
        />
      </Spacer>*/}
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>Tracking Code:</Text>
      <Spacer>
        <Input 
          label="Tracking Code"
          value={trackingCode}
          autoCapitalize="none"
          onChangeText={(text) => setTrackingCode(text)}
          autoCorrect={false}
          maxLength={50000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>pubTags:</Text>
      <Spacer>
        <Input 
          label="Publisher Tags (Comma Separated)"
          value={pubTags}
          autoCapitalize="none"
          onChangeText={(text) => setPubTags(text)}
          autoCorrect={false}
          maxLength={50000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>ugcPubTags:</Text>
      <Spacer>
        <Input 
          label="UGC Publisher Tags (Comma Separated)"
          value={ugcPubTags}
          autoCapitalize="none"
          onChangeText={(text) => setUGCPubTags(text)}
          autoCorrect={false}
          maxLength={50000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>ugcOptPubTags:</Text>
      <Spacer>
        <Input 
          label="UGC Optional Publisher Tags (Comma Separated)"
          value={ugcOptPubTags}
          autoCapitalize="none"
          onChangeText={(text) => setUGCOPTPubTags(text)}
          autoCorrect={false}
          maxLength={50000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>ugcSource:</Text>
      <Spacer>
        <Input 
          label="UGC Source"
          value={ugcSource}
          autoCapitalize="none"
          onChangeText={(text) => setUGCSource(text)}
          autoCorrect={false}
          maxLength={500}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>assetTopHeaderWeb:</Text>
      <Spacer>
        <Input 
          label="Uri"
          value={assetTopHeaderWeb}
          autoCapitalize="none"
          onChangeText={(text) => setAssetTopHeaderWeb(text)}
          autoCorrect={false}
          maxLength={500}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>assetTopHeaderMobile:</Text>
      <Spacer>
        <Input 
          label="Uri"
          value={assetTopHeaderMobile}
          autoCapitalize="none"
          onChangeText={(text) => setAssetTopHeaderMobile(text)}
          autoCorrect={false}
          maxLength={500}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>assetTopHeaderWebDarkMode:</Text>
      <Spacer>
        <Input 
          label="Uri"
          value={assetTopHeaderWebDarkMode}
          autoCapitalize="none"
          onChangeText={(text) => setAssetTopHeaderWebDarkMode(text)}
          autoCorrect={false}
          maxLength={500}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>assetTopHeaderMobileDarkMode:</Text>
      <Spacer>
        <Input 
          label="Uri"
          value={assetTopHeaderMobileDarkMode}
          autoCapitalize="none"
          onChangeText={(text) => setAssetTopHeaderMobileDarkMode(text)}
          autoCorrect={false}
          maxLength={500}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>defaultUserTags:</Text>
      <Spacer>
        <Input 
          label="Default User Tags (Comma Separated)"
          value={defaultUserTags}
          autoCapitalize="none"
          onChangeText={(text) => setDefaultUserTags(text)}
          autoCorrect={false}
          maxLength={5000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>tagBarColor:</Text>
      <Spacer>
        <Input 
          label="Tag Bar Color (Hex Value)"
          value={tagBarColor}
          autoCapitalize="none"
          onChangeText={(text) => setTagBarColor(text)}
          autoCorrect={false}
          maxLength={80}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>defaultImage:</Text>
      <Spacer>
        <Input 
          label="Uri"
          value={defaultImage}
          autoCapitalize="none"
          onChangeText={(text) => setDefaultImage(text)}
          autoCorrect={false}
          maxLength={1000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>mandatoryTag:</Text>
      <Spacer>
        <Input 
          label="Mandatory Tag"
          value={mandatoryTag}
          autoCapitalize="none"
          onChangeText={(text) => setMandatoryTag(text)}
          autoCorrect={false}
          maxLength={80}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>domains:</Text>
      <Spacer>
        <Input 
          label="Uri (Comma Separated)"
          value={domains}
          autoCapitalize="none"
          onChangeText={(text) => setDomains(text)}
          autoCorrect={false}
          maxLength={1000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>sources:</Text>
      <Spacer>
          <Input 
          label="Sources (Comma Separated)"
          value={sources}
          autoCapitalize="none"
          onChangeText={(text) => setSources(text)}
          autoCorrect={false}
          maxLength={2000000}
          />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>badgeSourceMap:</Text>
      <Spacer>
        <Input 
          label="Source;Uri,Source;Uri,Source;Uri,etc..."
          value={badgeSourceMap}
          autoCapitalize="none"
          onChangeText={(text) => setBadgeSourceMap(text)}
          autoCorrect={false}
          maxLength={4000000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>blacklistTags:</Text>
      <Spacer>
        <Input 
          label="Black List Tags (Comma Separated)"
          value={blacklistTags}
          autoCapitalize="none"
          onChangeText={(text) => setBlacklistTags(text)}
          autoCorrect={false}
          maxLength={500000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>tcEmail:</Text>
      <Spacer>
        <Input 
          label="Email"
          value={tcEmail}
          autoCapitalize="none"
          onChangeText={(text) => setTCEmail(text)}
          autoCorrect={false}
          maxLength={80}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>nEmail:</Text>
      <Spacer>
        <Input 
          label="Emails (Semicolon Separated)"
          value={nEmail}
          autoCapitalize="none"
          onChangeText={(text) => setNEmail(text)}
          autoCorrect={false}
          maxLength={500}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>frameViewSources:</Text>
      <Spacer>
        <Input 
          label="Sources (Comma Separated) - Optional"
          value={frameViewSources}
          autoCapitalize="none"
          onChangeText={(text) => setFrameViewSources(text)}
          autoCorrect={false}
          maxLength={5000000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>linkViewSources:</Text>
      <Spacer>
        <Input 
          label="Sources (Comma Separated) - Optional"
          value={linkViewSources}
          autoCapitalize="none"
          onChangeText={(text) => setLinkViewSources(text)}
          autoCorrect={false}
          maxLength={5000000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>viewSources:</Text>
      <Spacer>
        <Input 
          label="Sources (Comma Separated) - Optional"
          value={viewSources}
          autoCapitalize="none"
          onChangeText={(text) => setViewSources(text)}
          autoCorrect={false}
          maxLength={5000000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>nonPubSources:</Text>
      <Spacer>
        <Input 
          label="Sources (Comma Separated) - Optional"
          value={nonPubSources}
          autoCapitalize="none"
          onChangeText={(text) => setNonPubSources(text)}
          autoCorrect={false}
          maxLength={5000000}
        />
      </Spacer>
      <Spacer>
        <Input 
          label="Content Age (Days)"
          value={contentAge}
          autoCapitalize="none"
          onChangeText={(text) => setContentAge(text)}
          autoCorrect={false}
          maxLength={500000}
        />
      </Spacer>
      <Text style={{marginBottom: 10, marginTop: 20, fontSize: 20, fontWeight: 'bold', color: 'grey', marginTop: 10, paddingLeft: 22}}>pubSharing:</Text>
      <Spacer>
        <Input 
          label="pub to share to for certain tags (PubUsername;tag,tag,tag)"
          value={pubSharing}
          autoCapitalize="none"
          onChangeText={(text) => setPubSharing(text)}
          autoCorrect={false}
          maxLength={800}
        />
      </Spacer>
      <Spacer>
        <Button
          title={'Submit'}
          onPress={ async () =>  {
            if (ugcPubTags === "" || ugcOptPubTags === "" || ugcSource === "" || assetTopHeaderWeb === "" || assetTopHeaderMobile === "" || 
            assetTopHeaderWebDarkMode === "" || assetTopHeaderMobileDarkMode === "" || defaultUserTags === "" || tagBarColor === "" || defaultImage === "" || mandatoryTag === "" ||
            domains === "" || blacklistTags === "" || tcEmail === "" || nEmail === "" || badgeSourceMap === "" ) {
              setErrorMsg('Missing one or more fields');
              return;
            }
            setPGLoad(true)

            let frmViewSourcesArr = [];
            let lnkViewSourcesArr = [];
            if (frameViewSources !== "") {
              frmViewSourcesArr = frameViewSources.split(',');
            }
            if (linkViewSources !== "") {
              lnkViewSourcesArr = linkViewSources.split(',');
            }
            let viewSourcesArr = [];
            if (viewSources !== "") {
              viewSourcesArr = viewSources.split(',');
            }
            let nonPubSourcesArr = [];
            if (nonPubSources !== "") {
              nonPubSourcesArr = nonPubSources.split(',');
            }
            let pubTagsArr = [];
            if (pubTags !== "") {
              pubTagsArr = pubTags.split(',');
            }
            let ugcPubTagsArr = ugcPubTags.split(',');
            let ugcOptPubTagsArr = ugcOptPubTags.split(',');
            let defaultUserTagsArr = defaultUserTags.split(',');
            let bSourceMap = badgeSourceMap.split(',');
            let weight = 5;
            let defaultUserTagsArrWeighted = [];
            defaultUserTagsArr.forEach(usrTag => {
              defaultUserTagsArrWeighted.push({conTag: usrTag, value: weight});
              if (weight > 1) {
                weight -= 1;
              }
            });
            let domainsArr = domains.split(',');
            let sourcesArr = sources.split(',');
            let blacklistTagsArr = blacklistTags.split(',');
            let badgeSourceHashMap = {};
            bSourceMap.forEach(bSource => {
              let curBSourceObj = bSource.split(';');
              badgeSourceHashMap[`${curBSourceObj[0]}`] = `${curBSourceObj[1]}`;
            });
            let assetTPHeaderWeb = {link: assetTopHeaderWeb, location: "TopHeader-Web"};
            let assetTPHeaderMob = {link: assetTopHeaderMobile, location: "TopHeader-Mobile"};
            let assetTPHeaderWebDark = {link: assetTopHeaderWebDarkMode, location: "TopHeader-Web-DarkMode"};
            let assetTPHeaderMobDark = {link: assetTopHeaderMobileDarkMode, location: "TopHeader-Mobile-DarkMode"};
            let assetsArr = [assetTPHeaderWeb, assetTPHeaderMob, assetTPHeaderWebDark, assetTPHeaderMobDark];

            let pubSharingArr = [];
            if (pubSharing !== "") {
              const pubSharingMapComps = pubSharing.split(';');
              if (pubSharingMapComps.length === 2) {
                let pubName = pubSharingMapComps[0];
                let pubSharingTagArr = pubSharingMapComps[1].split(",");
                pubSharingArr.push({pub: pubName, tags: pubSharingTagArr});
              }
            }
            
            let payload = {assets: assetsArr};
            
            if (oldPub.pubTags.toString() !== pubTags) {
              payload["pubTags"] = pubTagsArr;
            } 
            if (oldPub.ugcPubTags.toString() !== ugcPubTags) {
              payload["ugcPubTags"] = ugcPubTagsArr;
            }
            if (oldPub.ugcOptPubTags.toString() !== ugcOptPubTags) {
              payload["ugcOptPubTags"] = ugcOptPubTagsArr;
            }
            if (oldPub.ugcSource !== ugcSource) {
              payload["ugcSource"] = ugcSource;
            }
            if (oldPub.defaultUserTags.toString() !== defaultUserTags) {
              payload["defaultUserTags"] = defaultUserTagsArrWeighted;
            } 
            if (oldPub.tagBarColor !== tagBarColor) {
              payload["tagBarColor"] = tagBarColor;
            } 
            if (oldPub.defaultImage !== defaultImage) {
              payload["defaultImage"] = defaultImage;
            } 
            if (oldPub.mandatoryTag !== mandatoryTag) {
              payload["mandatoryTag"] = mandatoryTag;
            } 
            if (oldPub.domains.toString() !== domains) {
              payload["domains"] = domainsArr;
            } 
            if (oldPub.sources.toString() !== sources) {
              payload["sources"] = sourcesArr;
            } 
            if (oldPub.blacklistTags.toString() !== blacklistTags) {
              payload["blacklistTags"] = blacklistTagsArr;
            } 
            if (oldPub.tcEmail !== tcEmail) {
              payload["tcEmail"] = tcEmail;
            } 
            if (oldPub.nEmail !== nEmail) {
              payload["nEmail"] = nEmail;
            }
            if (`${oldPub.contentAgeRange}` != contentAge) {
              payload["contentAgeRange"] = Number(contentAge);
            }
            if (oldPub.frameViewSources.toString() !== frameViewSources) {
              payload["frameViewSources"] = frmViewSourcesArr;
            } 
            if (oldPub.linkViewSources.toString() !== linkViewSources) {
              payload["linkViewSources"] = lnkViewSourcesArr;
            } 
            if (oldPub.viewSources.toString() !== viewSources) {
              payload["viewSources"] = viewSourcesArr;
            }
            if (oldPub.nonPubSources.toString() !== nonPubSources) {
              payload["nonPubSources"] = nonPubSourcesArr;
            } 
            payload["badgeSourceMap"] = badgeSourceHashMap;
            payload["shareTo"] = pubSharingArr
            try {
                if (publisher) {
                    await MeSearchAPI.patch(
                        '/api/pub', 
                        { payload,
                          pub: pub
                    });
                } else {
                    await MeSearchAPI.patch(
                        '/api/pub', 
                        { payload,
                          pub: pub
                    });
                }
                setSuccessMsg("Successfully Updated Publication");
            } catch (err) {
              setErrorMsg(`${err.response.data.error ? err.response.data.error : 'An error occured please try again later'}`);
            }
            setPGLoad(false);
          }}
        />
      </Spacer>
      {/* <View style={{flexDirection: 'row', justifyContent: 'center'}}>
        <View style={{height: 150, width: 280, marginBottom: 5, padding: 10}}>
            <Image style={styles.image} source={require('../../assets/web_footer_copyright.png')}/>
        </View>
      </View> */}
      <CopyrightFooter />
      </>
    : <ActivityIndicator size="large" style={{ marginTop: 150 }} color="#005157"/> }
    </ScrollView>
  );
};

const styles = StyleSheet.create({
    containerSmall: {
      //flex: 1
    },
    containerLarge: {
      width: Dimensions.get('window').width,
      paddingLeft: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null,
      paddingRight: Dimensions.get('window').width > 1050 ? ((Dimensions.get('window').width - 1050)/2) : null
    },
    errorMessage: {
      fontSize: 18,
      color: 'red',
      marginTop: 15,
      marginLeft: 15
    },
    successMessage: {
      fontSize: 18,
      color: 'green',
      marginTop: 15,
      marginLeft: 15
    },
    errorAst: {
      fontSize: 24,
      color: 'red',
    },
    image: {
        width: null,
        height: null,
        marginTop: 5,
        marginBottom: 5,
        resizeMode: 'contain',
        flex: 1
    },
});

export default EditPublisherScreen;