import React from 'react';
import { Text, TouchableOpacity, StyleSheet } from 'react-native';
import Spacer from './Space';
import { useNavigation } from '@react-navigation/native';

const NavLink = ({ text, routeName, params }) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity onPress={() => params ? navigation.navigate(routeName, params) : navigation.navigate(routeName)}>
        <Spacer>
        <Text style={styles.link}>{text}</Text>
        </Spacer>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  link: {
    color: 'blue'
  }
});

export default NavLink;