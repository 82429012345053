import React, { createRef, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, TouchableOpacity, ActivityIndicator, ImageBackground, Dimensions } from 'react-native';
import { Feather, FontAwesome5 } from '@expo/vector-icons';
import { isMobile, isTablet } from 'react-device-detect';
import { Platform } from 'react-native';
import AdUnit from '../AdUnit';
import AdTypesEnum from '../utils/AdUnitTypes';


class GridRow extends React.Component  {

  constructor(props) {
    super(props);
    this.state = {initLoad: true, page: 5, offset: 0, maxOffset: 0, gridAdUnit:{adUnit:"",slotID:""}, pageSingleFeed: 0};
    this.flatlist = createRef();
    this.defaultTitle = "Headlines";
    this.internalHandleOnViewableItemsChanged = this.internalHandleOnViewableItemsChanged.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this._ImgHead = this._ImgHead.bind(this);
    this.styles = StyleSheet.create({
        titleStyle: {
            fontSize: 32,
            fontWeight: '400',
            color: 'black',
            paddingLeft: 5,
            marginBottom: 5,
            // backgroundColor: props.tagBarColor,
            borderRadius: 4,
            overflow: 'hidden',
            width: props.horizontal ? null : Math.min(Dimensions.get('window').width-30, 500)
        },
        titleHeadlineStyle: {
          fontSize: 24,
          fontWeight: 'bold',
          color: 'white',
          paddingLeft: 5,
          marginLeft: 15,
          marginBottom: 5,
          backgroundColor: props.tagBarColor,
          borderRadius: 4,
          overflow: 'hidden'
      },
        container: {
          flexDirection: props.horizontal ? 'row' : 'column',
          marginTop: props.horizontal ? 10 : null,
          marginBottom: props.horizontal ? 10 : null,
        },
        containerTwo: {
          marginTop: props.horizontal ? 10 : null,
          marginBottom: props.horizontal ? 10 : null,
          paddingTop: !props.horizontal ? 10 : null,
          paddingBottom: !props.horizontal ? 10 : null,
          flex: 1,
        },
        imageHeader: {
          width: (Platform.OS === 'web' && !isMobile) ? 750 : 300,
          height: (Platform.OS === 'web' && !isMobile) ? 200 : 90,
          marginLeft: 10,
          marginRight: 10,
          marginRight: (Platform.OS === 'web' && !isMobile) ? 120 : 40
        },
      });
      this.VIEWABILITY_CONFIG = {
        minimumViewTime: 300,
        viewAreaCoveragePercentThreshold: 70,
        waitForInteraction: false,
      };
  };

  internalHandleOnViewableItemsChanged({viewableItems}) {
    this.props.handleOnViewableItemsChanged(viewableItems, this.props.results);
  }

  async componentDidUpdate() {
    if (Object.keys(this.props.impressions).length > 0 && Object.keys(this.props.impressions).filter((bid) => Object.keys(this.props.imprSent).indexOf(bid) == -1).length > 0 && this.props.viewable) {
      this.props.impressionSendCallback(this.props.impressions);
    }
  }

  handleScroll(event) {
    this.setState({offset: this.props.horizontal ? event.nativeEvent.contentOffset.x : event.nativeEvent.contentOffset.y});
  };

  UNSAFE_componentWillMount() {
    if (this.props.dfpAds) {
      let tempGridAd = this.props.context.state.publisher.adSlots.filter(a => a.type === AdTypesEnum.GRID);
      if (tempGridAd.length > 0) {
        this.setState({gridAdUnit: tempGridAd[0]});
      }
    }
    if (Platform.OS === 'ios') {
      this.props.onReachedEnd ? this.props.onReachedEnd(this.props.title, this.props.results.length, this.props.searchTxt, this.props.freesearchState, this.props.userSpecified) : () => {};
    }
  }
  
  renderResultDetail({item, index}, props) {
    const GridDetail = props.gridDetail;
    const RestaurantDetail = props.restaurantDetail;
    return (
        <>
          {
          (Platform.OS === 'web' && props.title === 'Latest' && index === 2 && props.dfpAds) ?

              //<View style={{paddingLeft: 10,}}>
                <View style={{paddingLeft: 10, justifyContent: 'center', alignItems: 'center'}}>
                  <AdUnit
                    dfpNetworkID={props.context.state.publisher.dfpNetworkID} 
                    adPlatform={props.context.state.publisher.adPlatform} 
                    adUnit={this.state.gridAdUnit["adUnit"]}
                    slotID={this.state.gridAdUnit["slotID"]}
                    sizes={[[250, 250]]}
                    cstmSctn="Homepage"
                  ></AdUnit>
                </View>
              //   {/* TODO ADD ADMIN LOGIC & ONPRESS HERE */}

              //   <View style={{marginTop:-30, left:10, width:250, height:30, paddingRight: 10, justifyContent: 'center', alignItems: 'flex-end', backgroundColor:'black'}}>
              //      <Feather name="bar-chart-2" 
              //       //onPress={() => props.navigation.navigate('analytics', {pubName: props.context.state.publisher.username, body_id: item.body_id, title: item.body[0].title})} 
              //       style={{color:'white', fontSize:20}}/>
              //   </View>
              // </View>
 
          :null}
          {((props.title === "Latest" && (!item.meTags || !item.meTags.includes("Headline"))) || props.title !== "Latest")
          ?<View
            style={{justifyContent: 'center', alignItems: 'center'}}
           >
            <TouchableOpacity
              onPress={() => props.resultClickCallback(item)}
            >
              {!item.claimed ?
              <ImageBackground style={{paddingLeft: 10,}}>
                {/* to GridDetail wrapper */}
                <GridDetail 
                  result={item} 
                  horizontal={props.horizontal}
                  darkMode={props.darkMode}
                  index={index}
                />
              {/* TODO ADD ADMIN LOGIC & ONPRESS HERE */}
              {this.props.isAdmin ?
                <View style={{marginTop:-30, left:10, width:250, height:30, paddingRight: 10, justifyContent: 'center', alignItems: 'flex-end',}}>
                    <Feather name="bar-chart-2"  
                    onPress={() => props.navigation.navigate('analytics', {reportName: props.context.state.publisher.username, body_id: item.body_id, title: item.body[0].title})} 
                    style={{color:'white', fontSize:20}}/>
                </View>
                : null}

              </ImageBackground>
              :
              <RestaurantDetail
                result={item}
                horizontal={props.horizontal} 
                darkMode={props.darkMode}
                tagBarColor={props.tagBarColor}
              />
              }
            </TouchableOpacity>
          </View>
          : null}
        </>
    );
  };

  fetchPubAsset = (assetLocation) => {
    let image = null;
    this.props.assets.forEach(asset => {
        if (asset.location === assetLocation) {
        image = asset.link;
        }
    });
    return image;
  };

  _ImgHead = () => {
    if (this.props.imageHeader) {
      const ImgHeader = this.props.imageHeader
      return (
          <ImgHeader
          fetchPubAsset={this.fetchPubAsset}
          darkMode={this.props.darkMode}
          tagBarColor={this.props.tagBarColor}
          restaurantInfo={this.props.restaurantInfo ? this.props.restaurantInfo : {}}
        />
      );
    } else {
      return (null);
    }
  };

  render() {
    //const ImgHeader = this.props.imageHeader;
    if (!this.props.results.length) {
      return null;
    }
    if (Platform.OS === 'web' && (!isMobile && !isTablet)) {
        // TODO: Make the following two render returns components and import
        return (
        <>
        {this.props.horizontal && this.props.grid ?
          // this is where row's headline/tag gets printed
          <View style={[{marginBottom: 7, marginLeft: 10}, this.props.horizontal ? null : {justifyContent: 'center', alignItems: 'center'}]}>
            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
              <Text style={(this.props.title !== 'Headlines' || isMobile) ? [this.styles.titleStyle, {marginLeft: this.props.horizontal ? 15: null, marginRight: this.props.horizontal ? 15 : null}] : this.styles.titleHeadlineStyle}>{this.props.title ? this.props.title : defaultTitle}</Text>
              {/* {!this.props.freesearchState ?
              <TouchableOpacity
                onPress={() => {
                  this.props.setHorizontal(!this.props.horizontal);
                  this.props.setCurTag(this.props.title);
                }}
                style={{width: 36}}
              >
                <View
                  style={{flexDirection: 'row', width: 30}}
                >
                  <Feather name="smartphone" style={{fontSize: 18, color: this.props.darkMode ? 'white' : 'black', width: 18, paddingTop: 5, paddingLeft: 5}}/>
                  <Feather name="refresh-cw" style={{fontSize: 18, color: this.props.darkMode ? 'white' : 'black', width: 18, paddingTop: 5, paddingLeft: 5}}/>
                </View>
              </TouchableOpacity>
              : null} */}
            </View>
          </View>
        : null}
        <View style={[this.styles.container, this.props.horizontal ? null : {justifyContent: 'center', alignItems: 'center'}]}>
            {this.state.offset > 275 && this.props.horizontal
            ?<View
                style={{flexDirection: 'column', marginLeft: 10}}
              >
            <TouchableOpacity style={{flex: 1, alignItems: 'center', justifyContent: 'center', height: '100%'}}
            onPress={() => {
                (this.state.offset - 500) < 0 ? this.setState({offset: 0}) : this.setState({offset: this.state.offset-500});
                this.flatlist.current.scrollToOffset({offset: this.state.offset-500, animated: true});
            }}
            >
              <View style={{marginHorizontal: 4}}>
                <FontAwesome5 name="arrow-alt-circle-left" size={35} color="black" />
              </View>
            </TouchableOpacity>
            </View>
            : null}
            <View 
              style={[{justifyContent: 'center', alignItems: 'center'}, !this.props.horizontal ? {height: Dimensions.get('window').height, width: Dimensions.get('window').width} : null]}
            >
              {/* this renders the actual row of results */}
            <FlatList
              ListHeaderComponent={!this.props.horizontal && this.props.grid ? () => {
                return (
                  <>
                    <View style={[{marginBottom: 10}, this.props.horizontal ? null : {justifyContent: 'center', alignItems: 'center'}]}>
                      <Text style={(this.props.title !== 'Headlines' || isMobile) ? [this.styles.titleStyle, {marginLeft: this.props.horizontal ? 15: null}] : this.styles.titleHeadlineStyle}>{this.props.title ? this.props.title : defaultTitle}</Text>
                      {!this.props.freesearchState ?
                      <TouchableOpacity
                        onPress={() => {
                          this.props.setHorizontal(!this.props.horizontal);
                          this.props.setCurTag(this.props.title);
                        }}
                        style={{width: 36}}
                      >
                        <View
                          style={{flexDirection: 'row', width: 36}}
                        >
                          <Feather name="smartphone" style={{fontSize: 18, color: this.props.darkMode ? 'white' : 'black', width: 18, paddingTop: 5, paddingLeft: 18}}/>
                          <Feather name="refresh-cw" style={{fontSize: 18, color: this.props.darkMode ? 'white' : 'black', width: 18, paddingTop: 5, paddingLeft: 18}}/>
                        </View>
                      </TouchableOpacity>
                      : null}
                    </View>
                  </>
                )
              } : this._ImgHead}
              style={{
                width: !this.props.horizontal ? 
                  Dimensions.get('window').width : 
                  Math.min((Platform.OS === 'web' && !isMobile) ? 
                    (this.state.offset > 275 && this.props.horizontal) ? 
                      Dimensions.get('window').width-85 : 
                      Dimensions.get('window').width-50 : 
                      Dimensions.get('window').width, 1210),
              }}
              onScroll={(event) => this.handleScroll(event)}
              onEndReachedThreshold={this.props.grid ? 0.2 : 20}
              onEndReached={() => {
                  if (((this.state.offset >= 250 && (this.state.offset > this.state.maxOffset)) || this.state.initLoad ) && (this.props.title !== "Headlines" && this.props.title !== "Latest" && this.props.grid)) {
                    this.setState({initLoad: false, page:  this.props.results.length, pageSingleFeed: this.state.pageSingleFeed+1, maxOffset: this.state.offset});
                    if (!this.props.fetchMoreContentQueue.isQueueEnded()) {
                      if (this.props.onReachedEnd && this.props.fetchMoreContentQueue) {
                        let params = [this.props.title, this.props.results.length, this.props.searchTxt, this.props.freesearchState, this.props.userSpecified];
                        this.props.fetchMoreContentQueue.addToQueue(this.props.onReachedEnd, params, this.props.title);
                        this.props.fetchMoreContentQueue.startQueue();
                      }
                    } else {
                      this.props.onReachedEnd ? this.props.onReachedEnd(this.props.title, this.props.results.length, this.props.searchTxt, this.props.freesearchState, this.props.userSpecified) : () => {}
                    }
                  } else if (!this.props.grid && (this.state.initLoad || this.state.pageSingleFeed >= 1)) {
                    let curPg = this.state.pageSingleFeed+1;
                    this.setState({initLoad: false, page:  this.props.results.length, pageSingleFeed: this.state.pageSingleFeed+1, maxOffset: this.state.offset});
                    this.props.onReachedEnd ? this.props.onReachedEnd(this.props.userSpecifiedTag ? this.props.userSpecifiedTag : this.props.title, curPg, this.props.searchTxt, this.props.freesearchState, this.props.userSpecified) : () => {}
                  }
              }}
              ref={this.flatlist}
              showsHorizontalScrollIndicator={false}
              horizontal={this.props.horizontal}
              data={this.props.results}
              keyExtractor={(result) => result[`${this.props.flatlistKey}`]}
              onViewableItemsChanged={this.internalHandleOnViewableItemsChanged}
              viewabilityConfig={this.VIEWABILITY_CONFIG}
              renderItem={(item) => this.renderResultDetail(item, this.props)}
            />
            </View>
            {(this.props.isLoading && this.props.curLoadTag === this.props.title) || this.props.isLoadingV2
            ?<View style={{flexDirection: 'column'}}>
            <ActivityIndicator size="large" color={this.props.darkMode ? 'white' : Platform.OS ==='web' ? "#005157" : Platform.OS === 'android' ? '#005157' : 'default'} style={{ marginTop: 75 }}/> 
            </View>
            : this.props.horizontal
            ?<View style={{flexDirection: 'column'}}>
            <TouchableOpacity style={{flex: 1, alignItems: 'center', justifyContent: 'center', height: '100%'}}
            onPress={() => {
                // TODO: set an upper limit for offset
                this.setState({offset: this.state.offset+500});
                this.flatlist.current.scrollToOffset({offset: this.state.offset+500, animated: true});
            }}
            >
              <View style={{marginHorizontal: 4}}>
                <FontAwesome5 name="arrow-alt-circle-right" size={35} color="black" />
              </View>
                {/* <Feather name="arrow-right" style={{fontSize: 35, color: '#a6abad', width: 35, marginTop: 80}}/> */}
            </TouchableOpacity>
            </View>
            : null
            }
        </View>
        </>
        );
    } else {
        return (
        <View style={this.styles.containerTwo}>  
            {this.props.horizontal && this.props.grid ?
            <View style={[/*{marginBottom: 10},*/ this.props.horizontal ? null : {justifyContent: 'center', alignItems: 'center'}]}>
              <Text style={([this.styles.titleStyle, {marginLeft: this.props.horizontal ? 15: null}])}>{this.props.title ? this.props.title : defaultTitle}</Text>
              {!this.props.freesearchState ?
              <TouchableOpacity
                onPress={() => {
                  this.props.setHorizontal(!this.props.horizontal);
                  this.props.setCurTag(this.props.title);
                }}
                style={{width: 36}}
              >
                <View
                  style={{flexDirection: 'row', width: 36}}
                >
                  <Feather name="smartphone" style={{fontSize: 18, color: this.props.darkMode ? 'white' : 'black', paddingTop: 5, paddingLeft: 18}}/>
                  <Feather name="refresh-cw" style={{fontSize: 18, color: this.props.darkMode ? 'white' : 'black', paddingTop: 5}}/>
                </View>
              </TouchableOpacity>
              : null}
            </View>
            : null}
            <View 
            style={{
              flexDirection: this.props.horizontal ? 'row' : 'column', 
              height: this.props.horizontal ? null : Dimensions.get('window').height,
              paddingTop: Platform.OS === 'web' ? null : !this.props.horizontal && this.props.grid ? 50 : null
            }}
            >
            <FlatList
              ListHeaderComponent={!this.props.horizontal && this.props.grid ? () => {
                return (
                  <>
                    <View style={[{marginBottom: 10}, this.props.horizontal ? null : {justifyContent: 'center', alignItems: 'center'}]}>
                      <Text style={([this.styles.titleStyle, {marginLeft: this.props.horizontal ? 15: null}])}>{this.props.title ? this.props.title : defaultTitle}</Text>
                      {!this.props.freesearchState ?
                      <TouchableOpacity
                        onPress={() => {
                          this.props.setHorizontal(!this.props.horizontal);
                          this.props.setCurTag(this.props.title);
                        }}
                        style={{width: 36}}
                      >
                        <View
                          style={{flexDirection: 'row', width: 36}}
                        >
                          <Feather name="smartphone" style={{fontSize: 18, color: this.props.darkMode ? 'white' : 'black', paddingTop: 5, paddingLeft: 18}}/>
                          <Feather name="refresh-cw" style={{fontSize: 18, color: this.props.darkMode ? 'white' : 'black', paddingTop: 5}}/>
                        </View>
                      </TouchableOpacity>
                      : null}
                    </View>
                  </>
                );
              } : this._ImgHead}
              onScroll={(event) => this.handleScroll(event)}
              onEndReachedThreshold={this.props.grid ? 0.2 : 20}
              onEndReached={() => {
                if (((this.state.offset >= 250 && (this.state.offset > this.state.maxOffset)) || this.state.initLoad ) && (this.props.title !== "Headlines" && this.props.title !== "Latest") && this.props.grid) {
                    this.setState({initLoad: false, page: this.props.results.length, pageSingleFeed: this.state.pageSingleFeed+1, maxOffset: this.state.offset});
                    if (!this.props.fetchMoreContentQueue.isQueueEnded()) {
                      if (this.props.onReachedEnd && this.props.fetchMoreContentQueue) {
                        let params = [this.props.title, this.props.results.length, this.props.searchTxt, this.props.freesearchState, this.props.userSpecified];
                        this.props.fetchMoreContentQueue.addToQueue(this.props.onReachedEnd, params, this.props.title);
                        this.props.fetchMoreContentQueue.startQueue();
                      }
                    } else {
                      this.props.onReachedEnd ? this.props.onReachedEnd(this.props.title, this.props.results.length, this.props.searchTxt, this.props.freesearchState, this.props.userSpecified) : () => {}
                    }
                } else if (!this.props.grid) {
                  let curPg = this.state.pageSingleFeed+1;
                  this.setState({initLoad: false, page:  this.props.results.length, pageSingleFeed: this.state.pageSingleFeed+1, maxOffset: this.state.offset});
                  this.props.onReachedEnd ? this.props.onReachedEnd(this.props.userSpecifiedTag ? this.props.userSpecifiedTag : this.props.title, curPg, this.props.searchTxt, this.props.freesearchState, this.props.userSpecified) : () => {}
                }
              }}
              ref={this.flatlist}
              showsHorizontalScrollIndicator={false}
              horizontal={this.props.horizontal}
              data={this.props.results}
              keyExtractor={(result) => result[`${this.props.flatlistKey}`]}
              onViewableItemsChanged={this.internalHandleOnViewableItemsChanged}
              viewabilityConfig={this.VIEWABILITY_CONFIG}
              renderItem={(item, index) => this.renderResultDetail(item, this.props, this.props.context, index)}
            />
            {(this.props.isLoading && this.props.curLoadTag === this.props.title) || this.props.isLoadingV2
                ?<View style={{flexDirection: 'column'}}>
                <ActivityIndicator size="large" color={this.props.darkMode ? 'white' : Platform.OS ==='web' ? "#005157" : Platform.OS === 'android' ? '#005157' : 'default'} style={{ marginTop: 75 }}/>
                </View>
            : null}
            </View>
        </View>
        );
    }
  };
};

export default GridRow;