import { useEffect, useState } from 'react';
// import { acc } from 'react-native-reanimated';
import MeSearchAPIKiosk from '../../api/kiosk/MeSearch-API-Kiosk';


export default () => {
  const [admins, setAdmins] = useState([]);
  const [adminsLoading, setAdminsLoading] = useState(false);
  const [adminsError, setAdminsError] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [guid, setGuid] = useState('');
  const [accountGrpDetails, setAccGrpDetails] = useState([]);
  const [accountGrpSets, setAcctGrpSets] = useState([]);
  const [accountSets, setAcctSets] = useState([]);
  const [accountCodingFrm, setCodingFrm] = useState({});
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [accountForumIds, setAcctForumIds] = useState([]);
  const [pdfBase64, setPdfBase64] = useState(null);
  const [accountCodingData, setCodingData] = useState();
  const [accountCodingHistory, setCodingHistory] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [saveCodingSuccess, setSaveCodingSuccess] = useState(null);
  const [searchHistoryId, setSearchHistoryId] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [lastSearchTxt, setLastSearchTxt] = useState("");
  const [lastSearchForumIds, setLastSearchForumIds] = useState([]);
  const [lastSearchPortfolioIds, setLastSearchPortfolioIds] = useState([]);
  const [numFound, setNumFound] = useState(0);
  
  const getAccounts = async () => {
    setLoading(true);
    try {
      let response = await MeSearchAPIKiosk.get('/api/kiosk/accounts');

      if (!response.data) {
        setLoading(false);
        return;
      }
      let accnts = response.data;
      accnts.sort((a, b) => a.OwnersAccountTitle < b.OwnersAccountTitle ? -1 : a.OwnersAccountTitle > b.OwnersAccountTitle ? 1 : 0);
      setAccounts(accnts);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const getAccountGroupDetails = async (accountId) => {
    setLoading(true);
    try {
      let response = await MeSearchAPIKiosk.get('/api/kiosk/account/group-details', 
        {
          params: {
            accountId: accountId
          }
        }
      );
      if (!response.data) {
        setLoading(false);
        return;
      }
      let accntGrpDetails = response.data;
      accntGrpDetails.sort((a, b) => a.GroupName < b.GroupName ? -1 : a.GroupName > b.GroupName ? 1 : 0);
      setAccGrpDetails(accntGrpDetails);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };
  
  const getGroupSets = async (forumId) => {
    setLoading(true);
    try {
      let response = await MeSearchAPIKiosk.get('/api/kiosk/account/docs-by-forum-id', {params: {forumId}});
      if (!response.data) {
        setLoading(false);
        return;
      }
      let accntGrpSets = response.data;
      accntGrpSets.sort((a, b) => a.PortfolioName < b.PortfolioName ? -1 : a.PortfolioName > b.PortfolioName ? 1 : 0);
      setAcctGrpSets(accntGrpSets);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const getPortfolioItems = async (forumId, accountId, portfolioId, page, curItems) => {
    const newItems = curItems && curItems.length > 0 ? [...curItems] : [];
    setLoading(true);
    try {
      let response = await MeSearchAPIKiosk.get('/api/kiosk/account/forums/portfolio', {params: {forumId, accountId, portfolioId, page}});
      if (!response.data) {
        setLoading(false);
        return;
      }
      let portfolioItems = response.data;
      newItems.push(...portfolioItems)
      setPortfolioItems(newItems);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getPdfBase64 = async (pdf_link, ke_id) => {
    
    setLoading(true);
    try {
      let response = await MeSearchAPIKiosk.get('/api/kiosk/account/forums/portfolio/pdf-base64', {params: {pdf_link, ke_id}});
      if (!response.data) {
        setLoading(false);
        return;
      }
      let base64 = response.data;
      setPdfBase64(base64.base64);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const getCodingFrm = async (accountId) => {
    setLoading(true);
    try {
      let response = await MeSearchAPIKiosk.get('/api/kiosk/account/forums/portfolio/pdf/coding-template', {params: {accountId}});
      if (!response.data) {
        setLoading(false);
        return;
      }
      let accntCoding = response.data;
      setCodingFrm(accntCoding);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const getCodingData = async (ke_id) => {
    setLoading(true);

    try { // TODO THIS NEEDS 
      let response = await MeSearchAPIKiosk.get('/api/kiosk/account/forums/portfolio/pdf/coding', {params: {ke_id}});

      if (!response.data) {
        setLoading(false);
        return;
      }
      let accntCoding = response.data;
      setCodingData(accntCoding);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const getCodingHistory = async (ke_id) => {
    setLoading(true);
    try { // TODO THIS NEEDS 
      let response = await MeSearchAPIKiosk.get('/api/kiosk/account/forums/portfolio/pdf/coding-history', {params: {ke_id}});
      if (!response.data) {
        setLoading(false);
        return;
      }
      let historyCoding = response.data;
      setCodingHistory(historyCoding);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };
  
  const putCodingData = async (ke_id, codingDoc) => {
    setLoading(true);
    try {
      let response = await MeSearchAPIKiosk.put('/api/kiosk/account/forums/portfolio/pdf/coding', {ke_id, codingDoc});
      if (!response.data) {
        setLoading(false);
        return;
      }
      setLoading(false);
      setSaveCodingSuccess(true);
    } catch (err) {
      setError(err);
      setLoading(false);
      setSaveCodingSuccess(false);
    }
  };

  const getSearch = async (searchText, acct, ForumIds=[], PortfolioIds=[], curItems=[], page=0, boolSearch={}) => {
    const newItems = curItems && curItems.length > 0 ? [...curItems] : [];
    setSearchHistoryId("");
    setLastSearchTxt(searchText);
    setLoading(true);
    try {
      ForumIds = ForumIds.length > 0 ? ForumIds : accountForumIds;
      setLastSearchForumIds(ForumIds);
      setLastSearchPortfolioIds(PortfolioIds);
      let body = {searchTxt: searchText, accountId: acct, ForumIds, PortfolioIds, page};
      if (Object.keys(boolSearch).length > 0) {
        body['boolSearch'] = boolSearch;
      }
      let response = await MeSearchAPIKiosk.post('/api/kiosk/search', body, {params: {accountId: acct}})
      if (!response.data) {
        setLoading(false);
        return;
      }
      let searchRes = response.data.docs;
      newItems.push(...searchRes)
      setPortfolioItems(newItems);
      setNumFound(response.data.numFound);
      setSearchHistoryId(response.data.historyId);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    //getAccounts();
  }, []);

  const getAdminsApi = async (accId) => {
    setAdmins([]);
    setAdminsLoading(true);
    try {
      const response = await MeSearchAPIKiosk.get('/api/kiosk/account/users', {params: {accountId: accId}});
      const adminsObj = response.data;
      setAdmins(adminsObj ? adminsObj : []);
      setAdminsLoading(false);
    } catch (err) {
      setAdminsError(err);
      setAdminsLoading(false);
    }
  };

  const getAccountSets = async (accountId, forumIds) => {
    setLoading(true);
    setAcctForumIds(forumIds);
    try {
      let response = await MeSearchAPIKiosk.get('/api/kiosk/account/docs-by-account-id', {params: {accountId: accountId, forumIds: forumIds}});
      if (!response.data) {
        setLoading(false);
        return;
      }
      setLoading(false);
      setAcctSets(response.data);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const addDocsToSet = async (ke_ids, portfolioId, forumId) => {
    setLoading(true);
    try {
      await MeSearchAPIKiosk.post('/api/kiosk/account/add-to-set', {ke_ids, portfolioId, forumId});
      setLoading(false);
      return "Success";
    } catch (err) {
      setError(err);
      setLoading(false);
      return "Failure";
    }
  };

  const removeDocFromSet = async (ke_id, portfolioId, forumId) => {
    setLoading(true);
    try {
      await MeSearchAPIKiosk.delete('/api/kiosk/account/remove-from-set', {params: {ke_id, portfolioId, forumId}});
      setLoading(false);
      return "Success";
    } catch (err) {
      setError(err);
      setLoading(false);
      return "Failure";
    }
  };

  const removeSet = async (accountId, portfolioId, forumId) => {
    setLoading(true);
    try {
      await MeSearchAPIKiosk.delete('/api/kiosk/account/remove-set', {params: {accountId: accountId, ForumId: forumId, PortfolioId: portfolioId}});
      setLoading(false);
      return "Success";
    } catch (err) {
      setError(err);
      setLoading(false);
      return "Failure";
    }
  };

  const createSetFromSearchResults = async (searchSetName, searchDesc, forumId, accountId, boolSearch) => {
    setLoading(true);
    try {
      if (boolSearch) {
        await MeSearchAPIKiosk.post('/api/kiosk/account/search/create-set', 
        {searchSetName, searchSetDescription: searchDesc, createForumId: forumId, 
        ForumIds: lastSearchForumIds, PortfolioIds: lastSearchPortfolioIds, boolSearch},
        {params: {accountId: accountId}}
      );
      } else {
        await MeSearchAPIKiosk.post('/api/kiosk/account/search/create-set', 
        {searchSetName, searchSetDescription: searchDesc, createForumId: forumId, 
        searchTxt: lastSearchTxt, ForumIds: lastSearchForumIds, PortfolioIds: lastSearchPortfolioIds},
        {params: {accountId: accountId}}
      );
      }
      setLoading(false);
      return "Success";
    } catch (err) {
      setError(err);
      setLoading(false);
      return "Failure";
    }
  };

  return [
    accounts,
    accountGrpDetails,
    accountGrpSets,
    accountCodingFrm,
    accountCodingData,
    loading,
    error,
    getAccounts,
    getAccountGroupDetails,
    getGroupSets,
    getCodingFrm,
    getCodingData,
    setCodingData,
    putCodingData,
    saveCodingSuccess,
    setSaveCodingSuccess,
    getPortfolioItems,
    getPdfBase64,
    portfolioItems,
    pdfBase64,
    accountCodingHistory,
    getCodingHistory,
    guid, setGuid,
    getSearch,
    searchResults,
    getAdminsApi,
    admins,
    adminsLoading,
    adminsError,
    setAdmins,
    setAccGrpDetails,
    setPortfolioItems,
    numFound,
    addDocsToSet,
    setAcctSets,
    accountSets,
    getAccountSets,
    setAcctForumIds,
    removeDocFromSet,
    createSetFromSearchResults,
    removeSet,
    setAcctGrpSets,
    accountForumIds
  ];

};