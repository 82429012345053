import FormFieldTypesEnum from './formFieldTypesEnum';

const validateFormField = ({setter, original, key, validations}) => {
  original[`${key}`].error = '';
  setNewObj(original, setter);
    if (original[`${key}`].value !== "") {
        if (!validations[`${key}`].validate(original[`${key}`].value)) {
          if (original[`${key}`].type === FormFieldTypesEnum.RADIO) {
            if (original[`${key}`].required) {
              original[`${key}`].error = "Must select one";
            }
          } else {
            original[`${key}`].error = `${original[`${key}`].name} was not formatted correctly`;
          }
          setNewObj(original, setter);
        };
      } else if (original[`${key}`].required) {
        original[`${key}`].error = `${original[`${key}`].name} is a required field`;
        setNewObj(original, setter);
    };
}

const setNewObj = (original, setter) => {
  let newObj = JSON.parse(JSON.stringify(original));
  setter(newObj);
}

export const validateMultiFormField = ({setter, original, key, index, validations}) => {
  original[`${key}`].error = '';
  setNewObj(original, setter);
  if (original[`${key}`].value[index].value !== "") {
    if (!validations[`${key}`].validate(original[`${key}`].value[index].value)) {
      original[`${key}`].error = `${original[`${key}`].name} was not formatted correctly`;
      setNewObj(original, setter);
    };
  } else if (original[`${key}`].required) {
    original[`${key}`].error = `${original[`${key}`].name} is a required field`;
    setNewObj(original, setter);
  };
};

export default validateFormField;