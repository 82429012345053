import determineUrl from './determineUrl';
import { Platform } from 'react-native';


const determineReferrer = () => {
    let referrer;
    if (Platform.OS === 'web') {
      let search = window.location.search
      let href = window.location.href;
      referrer = determineUrl(search, href);
    } else {
      referrer = "http://localhost:19006/mesearch";
    }
    //referrer = "http://localhost:19006/kiosk";
    return referrer;
};

export default determineReferrer;