import React, { useEffect, useState, useContext } from 'react';
import { View, Text, StyleSheet, Dimensions, ScrollView, TouchableOpacity, ActivityIndicator, Image } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import  { Button } from 'react-native-elements';
import MeSearchAPI from '../api/MeSearch-API';
import MeSearchAPIPub from '../api/MeSearch-API-Pub';
import AccountHomeWrapper from './wrappers/MeSearch/AccountHomeWrapper';
import UserHomeWrapper from './wrappers/MeSearch/UserHomeWrapper';
import { Ionicons } from "@expo/vector-icons";
import SystemCapabilities from './utils/SystemCapabilities';
import checkSystemCapability from './utils/checkSystemCapability';
import { Platform } from 'react-native';
import { Context as PubContext } from '../context/PubContext';


const MeSearchAccountHome = ({navigation, route}) => {
    const isConfirmed = route.params?.isConfirmed ?? false;
    const myMembership = route.params?.myMembership ?? false;
    const refreshAdmins = route.params?.refreshAdmins ?? false;
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
    const [userAccount, setUserAccount] = useState(null);
    const [myAccLvl, setMyAccLvl] = useState(null);
    const [adminCapability, setAdminCapability] = useState(false);
    const [uploadCapability, setUploadCapability] = useState(false);
    const [pubOwnerAccount, setPubOwnerAccount] = useState(null);
    const [localErrorMsg, setLocalErrorMsg] = useState('');
    const [localSuccessMsg, setLocalSuccessMsg] = useState('');
    const [isGuest, setIsGuest] = useState(false);
    const [pubUpload, setPubUpload] = useState(false);
    const [hasAccounts, setHasAccounts] = useState(false);
    const [horizontal, setHorizontal] = useState(true);
    const [darkMode, setDarkMode] = useState(false);
    const [landingPages, setLandingPages] = useState([]);
    const [publicationConfig, setPublicationConfig] = useState({});
    const { state } = useContext(PubContext);

    const checkForAccounts = (user) => {
      if (user.accessLvl && user.accessLvl.length > 0) {
        return user.accessLvl.some(a => {
          if (a.perm >= 3) {
            setHasAccounts(true);
            return true;
          }
          return false;
        });
      } else {
        setHasAccounts(false);
      }
    };

    const getAccessLvl = (user, pub) => {
        let accessLvl = 1;
        if (user.accessLvl) {
          user.accessLvl.forEach(accessObj => {
            if (accessObj.account === pub) {
              accessLvl = Math.max(accessObj.perm, accessLvl);
            }
          });
        }
        return accessLvl;
    };

    const getCurrentPubAccLvl = (user) => {
      let fAccessLvl = 0;
      let found = false;
      if (user.accessLvl) {
        user.accessLvl.forEach(accessObj => {
          if (accessObj.account === state.publisher.username || accessObj.account === state.publisher.owner) {
            found = true;
            fAccessLvl = Math.max(accessObj.perm, fAccessLvl);
          }
        });
      }
      if (fAccessLvl == 0 && found) {
        setPubUpload(false);
      } else {
        setPubUpload(checkSystemCapability(user, state.publisher.username, SystemCapabilities.UPLOAD) && checkSystemCapability(user, state.publisher.owner, SystemCapabilities.UPLOAD));
      }
    };

    const setPubConfig = async (pubOwnAcc) => {
      if (pubOwnAcc && pubOwnAcc.username !== state.publisher.owner) {
        try {
          let publications = await MeSearchAPI.get('/api/pub-owner/publications/from-claimed-owner', {params: {pubOwnerId: pubOwnAcc._id, pubOwner: pubOwnAcc.username}});
          if (publications.data && publications.data.length > 0) {
            setPublicationConfig(publications.data[0]);
          } else {
            let publicationsByOwner = await MeSearchAPI.get(
              '/api/pub-owner/publications', {params: {pubOwner: pubOwnAcc.username}});
              if (publicationsByOwner.data && publicationsByOwner.data.length > 0) {
                setPublicationConfig(publicationsByOwner.data[0]);
              } else {
                setPublicationConfig({});
              }
          }
        } catch (err) {
          setPublicationConfig({});
          console.log(err);
        }
      } else {
        setPublicationConfig({});
      }
    };

    useEffect(() => {
      setPubConfig(pubOwnerAccount);
    }, [pubOwnerAccount]);

  const getAccountInfo = async () => {
        // Fetch current pub token
        const pubToken = await AsyncStorage.getItem('pubtoken');
        try {
            const usrResponse = await MeSearchAPI.get('/api/user');
            getCurrentPubAccLvl(usrResponse.data);
            if (usrResponse.data.darkMode) {
                setDarkMode(true);
            }
            if (usrResponse.data.guest) {
                setIsGuest(true);
                setHasAccounts(false);
            } else {
                checkForAccounts(usrResponse.data);
                setIsGuest(false);
                if (!pubToken) {
                    setUserAccount(usrResponse.data);
                } else {
                    const pubOwnerResponse = await MeSearchAPIPub.get('/api/pub-owner');
                    setPubOwnerAccount(pubOwnerResponse.data);
                    setIsEmailConfirmed((pubOwnerResponse.data.isConfirmed || (typeof pubOwnerResponse.data.isConfirmed === 'undefined')));
                    if (pubOwnerResponse.data.landingPage && pubOwnerResponse.data.landingPage.length > 0) {
                        setLandingPages(pubOwnerResponse.data.landingPage);
                    } else {
                        setLandingPages([]);
                    }
                    if (usrResponse.data && pubOwnerResponse.data) {
                        let myAccLevel = getAccessLvl(usrResponse.data, pubOwnerResponse.data.username);
                        setMyAccLvl(myAccLevel);
                        setAdminCapability(checkSystemCapability(usrResponse.data, pubOwnerResponse.data.username, SystemCapabilities.ADMIN));
                        setUploadCapability(checkSystemCapability(usrResponse.data, pubOwnerResponse.data.username, SystemCapabilities.UPLOAD));
                    }
                }
            }
        } catch (err) {
            setLocalErrorMsg('Something went wrong');
        }
    }

    useEffect(() => {
      getAccountInfo();
      const unsubscribe = navigation.addListener('focus', () => {
        getAccountInfo();
      });
      return unsubscribe;
    }, []);

    return (
        <ScrollView contentContainerStyle={[{alignItems: 'center', justifyContent: 'center', paddingTop: 50}, darkMode ? {backgroundColor: '#3c3c3d'} : null]}>
          {isGuest ?
            <View style={{justifyContent: 'center', alignItems: 'center', flex: 1}}>
              <Image 
                style={{width: 260, height: 140, resizeMode: 'contain', }} 
                source={(state.publisher.assets && state.publisher.assets.length > 1) ? {uri: state.publisher.assets[1].link} : require('../../assets/mesearch_logo_small.png')} 
              />
              <Button 
                style={{marginTop: 20, width: 200}}
                title={'Log In'}
                onPress={() => navigation.navigate('Signin/Signup') }
              />
              <Text style={{fontSize: 18, fontWeight: '400', marginTop: 20}}>Or</Text>
              <Button 
                style={{marginTop: 20, width: 200}}
                title={'Create An Account'}
                onPress={() => navigation.navigate('Signin/Signup', {screen: 'SignupChoice'}) }
              />
            </View>
          : <View style={{width: Math.min(Dimensions.get('window').width-30, 800), paddingBottom: 20}}>
                {(pubOwnerAccount && !myMembership) ? 
                    <>
                        {(isConfirmed || isEmailConfirmed) ?
                            <View>
                                <AccountHomeWrapper
                                  refreshAdmins={refreshAdmins}
                                  darkMode={darkMode}
                                  uploadCapability={uploadCapability}
                                  horizontal={horizontal}
                                  setHorizontal={setHorizontal}
                                  hasAccounts={hasAccounts}
                                  imageUri={pubOwnerAccount.displayPicUri}
                                  route={route}
                                  navigation={navigation}
                                  name={pubOwnerAccount.displayName ? pubOwnerAccount.displayName : pubOwnerAccount.username}
                                  type={pubOwnerAccount.type ? pubOwnerAccount.type : "News Publisher"}
                                  pubOwner={pubOwnerAccount}
                                  myAccLvl={myAccLvl ? myAccLvl : 3}
                                  adminCapability={adminCapability}
                                  landingPages={landingPages}
                                  setLandingPages={setLandingPages}
                                  publicationConfig={publicationConfig}
                                />
                            </View>
                        : <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: Dimensions.get('window').height*0.30}}>
                            <Text style={{fontSize: 18, color: 'red'}}>Authorized Business Representative Needs to Confirm Their Email</Text>
                            <TouchableOpacity
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginHorizontal: 10,
                                    paddingHorizontal: 5,
                                    borderColor: darkMode ? 'white' : "#666",
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    marginTop: 20,
                                    width: 200
                                }}
                                onPress={() => {
                                    navigation.navigate('Drawer', {screen: 'Account', params: {screen: 'Passport', params: {}}});
                                }}
                                >
                                <Ionicons name="git-network-outline" size={24} color={darkMode ? 'white' : "black"}/>
                                <View style={{ alignItems: "center", justifyContent: "center" }}>
                                    <Text style={[{ lineHeight: 12, paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>My</Text>
                                    <Text style={[{ lineHeight: 12, paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>
                                    Network
                                    </Text>
                                </View>
                            </TouchableOpacity>
                          </View>
                        }
                    </>
              
                : <>
                    {userAccount ?
                      <>
                      <View>
                        <UserHomeWrapper
                          upload={pubUpload}
                          darkMode={darkMode}
                          horizontal={horizontal}
                          setHorizontal={setHorizontal}
                          hasAccounts={hasAccounts}
                          imageUri={userAccount.displayPicUri}
                          navigation={navigation}
                          route={route}
                          name={userAccount.displayName ? userAccount.displayName : userAccount.username}
                        />
                      </View>
                      </>
                    : <ActivityIndicator size="large" color={darkMode ? 'white' : Platform.OS ==='web' ? "#005157" : Platform.OS === 'android' ? '#005157' : 'default'} style={{ marginTop: 150 }}/> }
                  </>
                }
                {localErrorMsg ? <Text style={styles.errorMessage}>{localErrorMsg}</Text> : null}
                {localSuccessMsg ? <Text style={styles.successMessage}>{localSuccessMsg}</Text> : null}
            </View>
          }
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    errorMessage: {
      fontSize: 18,
      color: 'red',
      marginTop: 15
    },
    successMessage: {
      fontSize: 18,
      color: 'green',
      marginTop: 15
    }
});

export default MeSearchAccountHome;