import React from 'react';
import { View, Text, Image } from 'react-native';

const CopyrightFooter = () => {
  const year = new Date().getFullYear();
  return (
    <View style={{height: 70, width: 'auto', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingRight: 15}}>
      <Image style={styles.image} source={require('../../assets/mesearch_logo_small.png')}/>
      <Text style={{ paddingLeft: 5, fontSize: 12}}>Copyright © {year} Mesearch Media Technologies Ltd.</Text>
    </View>
  );
};

const styles = {
  image: {
    width: 70,
    height: 70,
    resizeMode: 'contain',
  },
}

export default CopyrightFooter;