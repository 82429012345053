import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import { View, Text, StyleSheet, FlatList, Dimensions } from 'react-native';
//import Dash from 'react-native-dash';

const StepIndicator = ({ step, currentStepMeta, lastStep }) => {
  let render = null;

  if (step === lastStep) {
    render = (
      <>
        <View style={currentStepMeta.done ? styles.circleFilled : styles.circleGrey}>
          <Text style={styles.text}>{step}</Text>
        </View>
      </>
    );
  } else {
    render = (
      <>
        <View style={currentStepMeta.done ? styles.circleFilled : styles.circleGrey}>
          <Text style={styles.text}>{step}</Text>
        </View>
        <View>
          {/*<Dash dashColor={currentStepMeta.done ? 'rgb(58, 142, 250)' : 'grey'} style={{width: 60, height: 1, marginHorizontal: 5, alignContent: 'center'}} />*/}
        </View>
      </>
    );
  }
  return render;
};

const StepProgress = ({ step, numberOfSteps }) => {
  const [stepMeta, setStepMeta] = useState([]);
  const [, setRerender] = useState({});
  // const [indicators, setIndicators] = useState([]);

  useEffect(() => {
    let initialMeta = [];
    let i = 0;
    while (i < numberOfSteps) {
      if (i === 0) {
        initialMeta.push({
          done: true,
        });
        i++;
      }

      if (i + 1 === numberOfSteps) {
        initialMeta.push({
          done: false,
        });
        break
      }

      initialMeta.push({
        done: false,
      });
      i++;
    }
    setStepMeta(initialMeta);
  }, [])

  useEffect(() => {
    // as step changes, update any objects inside of stepMeta to reflect progress.
    // by default: if step > 1 && step !== numberOfSteps, then toggle indicator and line to true
    if (step > 1) {
      // when going backwards in steps...
      if (stepMeta[step].done === true) {
        let clone = stepMeta;
        clone[step] = {done: false};
        setStepMeta(clone);
        setRerender({});
      } else {
        let clone = stepMeta;
        clone[step - 1] = {done: true};
        setStepMeta(clone);
        setRerender({});
      }
    }

  }, [step, stepMeta])

  let indicators = stepMeta.map((meta, index, array) => {
    return (
      <StepIndicator key={index} step={index + 1} currentStepMeta={meta} lastStep={array.length} />
    );
  });
  
  return (
    <View style={{flexDirection: 'row', width: Math.min(Dimensions.get('window').width-20, 500), height: 50, alignItems: 'center', justifyContent: 'center'}}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {indicators}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  circleFilled: {
    width: 35,
    height: 35,
    padding: 10,
    backgroundColor: 'rgb(58, 142, 250)',
    borderColor: 'rgb(58, 142, 250)',
    borderRadius: 100
  },
  circleGrey: {
    width: 35,
    height: 35,
    padding: 10,
    backgroundColor: 'grey',
    borderColor: 'grey',
    borderRadius: 100
  },
  solidLine: {
    paddingHorizontal: 10,
    alignItems: 'center',
    height: 1,
    borderWidth: 80,
  },
  text: {
    color: 'white',
    textAlign: 'center',
  }
});

export default StepProgress;