/*function distinctMerge(...arrays) {

  let combinedArray = [];
   
  arrays.forEach(array => {
    combinedArray = [...combinedArray, ...array]
  });
  const distinctArray = combinedArray.filter((element, index) => combinedArray.indexOf(element) === index);
  
  return distinctArray;
};*/
function distinctMerge(pubArray, userArray, twoWeekArray) {
  const usrArrayInTwoWeeks = userArray.filter(item => {
    return twoWeekArray.includes(item);
  });
  const usrTwoWeekArray = pubArray.concat(usrArrayInTwoWeeks.concat(twoWeekArray));
  const distinctArray = usrTwoWeekArray.filter((element, index) => usrTwoWeekArray.indexOf(element) === index);
  return distinctArray;
};

export default distinctMerge;