import React from "react";
import { Text, TouchableOpacity, View, Linking, FlatList } from "react-native";
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import { Feather } from '@expo/vector-icons';
import { DrawerActions } from '@react-navigation/native';
import Spacer from '../Space';
import { navigate } from '../../navigationRef';
import { menuDataMain } from './menuData';
import uuidv4 from 'uuid/v4';

const DrawerItem = ({ navigation, icon, name, screenName, index, uri, data, menuType, setMenuData, setMenuType, endIndex }) => {
  
    switch (menuType) {
      case 'Main':
        return (
            <>
            {(index === 0)
                ? <Spacer>
                    <View style={{flexDirection: 'row', justifyContent: 'center', flex: 1 }}>
                        <Text style={{fontSize: 30, fontWeight: '400', color: 'white', paddingRight: ((Platform.OS === 'web' && (isMobile || isTablet)) || Platform.OS !== 'web') ? 80 : 118}}>Menu</Text>
                    </View>
                  </Spacer>
                : null}
            <TouchableOpacity
                style={{flexDirection: 'row'}}
                onPress={() => {
                    if (name === "Close") {
                        navigation.dispatch(DrawerActions.closeDrawer());
                    } else if (uri) {
                        if (Platform.OS === 'web') {
                          window.open(uri, "_self");
                        } else {
                          if (name === "Neighborhood News Network") {
                            navigate('ResolveAuth', { ref: uri });
                            //navigation.navigate('ResolveAuth', { ref: uri });
                          } else if ("Dine Local") {
                            navigate('ResolveAuth', { ref: uri });
                          } else {
                            Linking.openURL(uri);
                          }
                        }
                    } else if (data && data.content) {
                        setMenuType('Catagories');
                        setMenuData(data.content);
                    } else if (name === "Feed") {
                      navigation.navigate(`${screenName}`, {screen: 'Search', params: { isStatusBarHidden: false } });
                    } else if (name === "Sign In/Sign Up")  {
                      navigation.navigate(`${screenName}`, {screen: 'Signin', params: { isStatusBarHidden: false } });
                    } else {
                      navigation.navigate(`${screenName}`, { isStatusBarHidden: false });
                    }
                }}
            >
                {icon ? <Feather name={icon} style={{fontSize: 30, width: 40, marginLeft: 10, marginTop: 5, color: 'white'}}/> : null}
                <Text style={{ fontSize: 15, fontWeight: "300", margin: 10, color: 'white'}}>{name}</Text>
            </TouchableOpacity>
          </>
        );
      case 'Catagories':
            data = [data];
            return (
                <>
                  <FlatList
                    data={data}
                    keyExtractor={(item) => item.name ? item.name : item}
                    renderItem={({ item }) => (
                        <TouchableOpacity
                            onPress={() => {
                                if (item.content && item.content.length > 0) {
                                  setMenuData(item.content);
                                } else {
                                  navigation.navigate('Home', {screen: 'Search', params: { usrSpecTag: item.name ? item.name : item, searchHashUpdate: `${uuidv4()}` } });
                                }
                            }}
                        >
                        <View style={{justifyContent: 'space-between', flexDirection: 'row', borderBottomColor: 'gray', borderBottomWidth: 1}}>
                          <Text style={{ fontSize: 16, fontWeight: "400", color: 'white', paddingBottom: 15, padding: 5}}>{item.name ? item.name : item}</Text>
                          {(item.content && item.content.length > 0) ? <Feather name={'chevron-right'} style={{fontSize: 30, width: 40, marginLeft: 10, color: 'white'}}/> : null}
                        </View>
                        </TouchableOpacity>
                    )}
                  />
                  {(index === endIndex)
                    ? <TouchableOpacity
                        style={{flexDirection: 'row'}}
                        onPress={() => {
                          setMenuType('Main');
                          setMenuData(menuDataMain);
                        }}
                      >
                        <Feather name={'arrow-left'} style={{fontSize: 30, width: 40, marginTop: 5, color: 'white'}}/>
                        <Text style={{ fontSize: 18, fontWeight: "400", marginTop: 10, color: 'white'}}>Back To Main Menu</Text>
                      </TouchableOpacity>
                    : null}
                </>
            );
    default: 
      break;
  };

};

export default DrawerItem;
