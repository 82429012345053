
function isVideo(uri) {
  
  const mp4Regex = new RegExp("\.mp4$", "g");
  const mp4Base64Regex = new RegExp("\/mp4\;base64", "g");

  const movRegex = new RegExp("\.mov$", "g");
  const movBase64Regex = new RegExp("\/mov\;base64", "g");

  const qtRegex = new RegExp("\.qt$", "g");
  const qtBase64Regex = new RegExp("\/qt\;base64", "g");

  const m4vRegex = new RegExp("\.m4v$", "g");
  const m4vBase64Regex = new RegExp("\/m4v\;base64", "g");

  const AVCHDRegex = new RegExp("\.avchd$", "g");
  const AVCHDBase64Regex = new RegExp("\/avchd\;base64", "g");

  const SwfRegex = new RegExp("\.swf$", "g");
  const SwfBase64Regex = new RegExp("\/swf\;base64", "g");

  const FlvRegex = new RegExp("\.flv$", "g");
  const FlvBase64Regex = new RegExp("\/flv\;base64", "g");

  const WmvRegex = new RegExp("\.wmv$", "g");
  const WmvBase64Regex = new RegExp("\/wmv\;base64", "g");

  const AVIRegex = new RegExp("\.avi$", "g");
  const AVIBase64Regex = new RegExp("\/avi\;base64", "g");

  const M4PRegex = new RegExp("\.m4p$", "g");
  const M4PBase64Regex = new RegExp("\/m4p\;base64", "g");

  const OGGRegex = new RegExp("\.ogg$", "g");
  const OGGBase64Regex = new RegExp("\/ogg\;base64", "g");

  const MPGRegex = new RegExp("\.mpg$", "g");
  const MPGBase64Regex = new RegExp("\/mpg\;base64", "g");

  const MP2Regex = new RegExp("\.mp2$", "g");
  const MP2Base64Regex = new RegExp("\/mp2\;base64", "g");

  const MPEGRegex = new RegExp("\.mpeg$", "g");
  const MPEGBase64Regex = new RegExp("\/mpeg\;base64", "g");

  const MPERegex = new RegExp("\.mpe$", "g");
  const MPEBase64Regex = new RegExp("\/mpe\;base64", "g");

  const MPVRegex = new RegExp("\.mpv$", "g");
  const MPVBase64Regex = new RegExp("\/mpv\;base64", "g");

  const WEBMRegex = new RegExp("\.webm$", "g");
  const youtubeRegex = new RegExp("youtube\.com", "g");

  const videoRegexs = [mp4Regex, mp4Base64Regex, movRegex, movBase64Regex, youtubeRegex, qtRegex, qtBase64Regex, m4vRegex, m4vBase64Regex, AVCHDRegex, AVCHDBase64Regex, SwfRegex, SwfBase64Regex, 
    FlvRegex, FlvBase64Regex, WmvRegex, WmvBase64Regex, AVIRegex, AVIBase64Regex, M4PRegex, M4PBase64Regex, OGGRegex, OGGBase64Regex, MPGRegex, MPGBase64Regex, 
    MPERegex, MPEGBase64Regex, MPVRegex, MPVBase64Regex, WEBMRegex, MP2Regex, MPEGRegex];
  return videoRegexs.some(vRegex => vRegex.test(uri));
};

export default isVideo;