import React from 'react';
import { View } from 'react-native';
import AdUnit from '../AdUnit';

const FetchAdUnitStory = ({index,dfpNetworkID,adPlatform,sizes,inStory1,inStory2,inStory3}) => {
    let InStoryAdUnit = null;
    switch(index){
        case 2:
            if(inStory1["adUnit"] != ""){
                InStoryAdUnit = (
                    <View style={{paddingLeft: 15, paddingBottom: 5}}>
                        <AdUnit
                            dfpNetworkID={dfpNetworkID} 
                            adPlatform={adPlatform} 
                            adUnit={inStory1["adUnit"]}
                            slotID={inStory1["slotID"]}
                            sizes={sizes}
                        ></AdUnit>  
                    </View>
                )
            }
            break;
        case 5:
            if(inStory2["adUnit"] != ""){
                InStoryAdUnit = (
                <View style={{paddingLeft: 15, paddingBottom: 5}}>
                    <AdUnit
                        dfpNetworkID={dfpNetworkID} 
                        adPlatform={adPlatform} 
                        adUnit={inStory2["adUnit"]}
                        slotID={inStory2["slotID"]}
                        sizes={sizes}
                    ></AdUnit>  
                </View>
                )
            }
            break;
        case 10:
            if(inStory3["adUnit"] != ""){
                InStoryAdUnit = (
                    <View style={{paddingLeft: 15, paddingBottom: 5}}>
                        <AdUnit
                            dfpNetworkID={dfpNetworkID} 
                            adPlatform={adPlatform} 
                            adUnit={inStory3["adUnit"]}
                            slotID={inStory3["slotID"]}
                            sizes={sizes}
                        ></AdUnit>  
                    </View>
                )
            }
            break;
        default:
            InStoryAdUnit = (null)
            break;
    }
    return InStoryAdUnit;
}

export default FetchAdUnitStory;