import React, { useContext, useState, useEffect } from 'react';
import  { Text, Button,  CheckBox } from 'react-native-elements';
import {StyleSheet, TextInput, Image, FlatList, Dimensions, View, ScrollView, SafeAreaView, ImageBackground, TouchableOpacity } from 'react-native';
import Logo from '../../../assets/Crivella-Ai-logo.png';
import Spacer from '../Space';
import { Feather, FontAwesome, Foundation } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { navigate } from '../../navigationRef';
import uuidv4 from 'uuid/v4';
import { HeaderBackButton } from '@react-navigation/stack';
import { Platform } from 'react-native';
import KioskGrid from './kioskGrid'
//import { Picker } from '@react-native-community/picker';
import useKioskResults from '../../hooks/kiosk/useKioskResults';
import { Context as AuthContext } from '../../context/AuthContext';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import BoolSearchBar from './boolSearch'
import ProfileInfo from './profileInfo'
import DetailPanel from './detailPanel'
import UploadPanel from './uploadPanel'

import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
  } from 'react-native-chart-kit'
  //import ChartTips from './src/ChartWithTooltip'
import PDFReader from 'rn-pdf-reader-js'
import profileInfo from './profileInfo';

//import { isMobile, isTablet } from 'react-device-detect';
//import Pdf from 'react-native-pdf';

// yarn add rn-pdf-reader-js
// yarn add react-native-chart-kit
// expo install react-native-svg
// yarn add react-native-final-tree-view
// expo install @react-native-community/picker

// yarn add react-device-detect
// yarn add react-native-webview
// yarn add react-native-view-pdf
// yarn add react-native-pdf rn-fetch-blob @react-native-community/progress-bar-android @react-native-community/progress-view
// yarn add @react-native-community/progress-view

// const BoolDat = [{name:'AND'},{name:'OR'},{name:'NOT'}]
// const DcDat = [
//   {name:'All Field'},
//   {name:'Text'},
//   {name:'Title'},
//   {name:'Creator'},
//   {name:'Identifier'},
//   {name:'Subject'},
//   {name:'Description'},
//   {name:'Publisher'},
//   {name:'Contributor'},
//   {name:'Source'},
//   {name:'Audience'},
//   {name:'Relation'},
//   {name:'Date'},
//   {name:'Type'},
//   {name:'Coverage'},
//   {name:'Format'},
//   {name:'Language'},
//   {name:'Rights Holder'},
//   {name:'Provenance'},
// ]

  // iterate through DATA and add `checked` property to each of DATA's objects.
  
  // const checkAll = () => {
  //   DATA.forEach(object => {
  //     object.checked = true;
  //   });
  // }

const KioskMainScreen = ({ navigation }) => {

    const { kSignout } = useContext(AuthContext);
    const [err, setErr] = useState(null);
    const [ShowSearch, setShowSearch] = useState(false);
    const [Accountid, setAccountid] = useState(0);
    const [selectedAcctName, setAcctName] = useState('');
    const [Forumid, setForumid] = useState(0);
    const [selectedAcctGrp, setAcctGrp] = useState('');
    const [Portfolioid, setPortfolioid] = useState('');
    const [selectedSetName, setSetName] = useState('');
    
    const [viewForums, setViewForums] = useState(false);
    const [viewSets, setViewSets] = useState(false);
    const [viewResults, setResults] = useState(false);
    const [viewRS, setViewRS] = useState([]);
    const [viewStatus, setViewStatus] = useState('center');
    const [viewProfile, setViewProfile] = useState(false);
    const [viewUpload, setViewUpload] = useState(false);

    const [checkedItems, setCheckedItems] = useState([]);
    const [showPanel, setShowPanel] = useState(false);
    const [rend, setRend] = useState("");
    const [ accounts, accountGrpDetails,
            accountGrpSets, accountCodingFrm, 
            accountCodingData, loading, error, 
            getAccounts, getAccountGrpDetails, 
            getGroupSets, getCodingFrm, 
            getCodingData, setCodingData, putCodingData, 
            saveCodingSuccess, setSaveCodingSuccess,
            getPortfolioItems, getPdfBase64, portfolioItems, pdfBase64,
            accountCodingHistory, getCodingHistory,
            guid, setGuid,
            getSearch,searchResults] = useKioskResults();

    const getAccnts = () => {
      // /api/kiosk/accounts
      getAccounts();
    };

    const getGroups = (acct) => {
      // /api/kiosk/account/group-details
      getAccountGrpDetails(acct);
    };

    const getSets = (Forumid) => {
      // /api/kiosk/account/docs-by-forum-id
      getGroupSets(Forumid);
    };

    const getItems = () => {
      // /api/kiosk/account/forums/portfolio
      getPortfolioItems(Forumid, Accountid, Portfolioid);
    };

    useEffect(() => {
      if (Accountid!=0)
      getGroups(Accountid);
    }, [Accountid]);

    useEffect(() => {
      if (Forumid!=0)
      getGroupSets(Forumid)
    }, [Forumid]);

    useEffect(() => {
      if (Portfolioid !="")
        getItems()
    }, [Portfolioid]);

    // const addBool = () => {
    //   let newBoolItems = JSON.parse(JSON.stringify(boolItems)); 
    //   newBoolItems.push({id:`${boolItems.length+1}`, logic:'AND', dc:'ALL', term:''});
    //   setBoolItems(newBoolItems)
    // };

    // const remBool = (index) => {
    //   let newBoolItems = JSON.parse(JSON.stringify(boolItems)); 
    //   if (newBoolItems.length === 1) {
    //     newBoolItems = [];
    //   } else {
    //     newBoolItems.splice(index,1);
    //   }

    //   setBoolItems(newBoolItems)
    // };

    // const updateTerm = (id, term) => {
    //   let newBoolItems = JSON.parse(JSON.stringify(boolItems)); 
    //   let logic = newBoolItems[id].logic;
    //   let dc = newBoolItems[id].dc;
    //   newBoolItems.splice(id,1,{id: `${id}`,logic,dc,term});
    //   setBoolItems(newBoolItems)
    // };

    // const updateLogic = (id, logic) => {
    //   let newBoolItems = JSON.parse(JSON.stringify(boolItems)); 
    //   let dc = newBoolItems[id].dc;
    //   let term = newBoolItems[id].term;
    //   newBoolItems.splice(id,1,{id: `${id}`,logic,dc,term});
    //   setBoolItems(newBoolItems)
    // };

    // const updateDC = (id, dc) => {
    //   let newBoolItems = JSON.parse(JSON.stringify(boolItems)); 
    //   let logic = newBoolItems[id].logic;
    //   let term = newBoolItems[id].term;
    //   newBoolItems.splice(id,1,{id: `${id}`,logic,dc,term});
    //   setBoolItems(newBoolItems)
    // };

    const LeftItem = () => {
      return (
        <View style={[styles.leftItem, {paddingTop:50, alignItems:'center'}]}>
          <DetailPanel/>
        </View>
      );
    };

    const RightItem = () => {
      return (
        <View style={styles.rightItem}>
          <TouchableOpacity style={styles.deleteButtonStyle}>
            <Text style={styles.textButtonStyle}>Home</Text>
          </TouchableOpacity>
        </View>
      );
    };

    // Render use JSX syntax
    return (
      <>
      {/* <SafeAreaView style={Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall}> */}

      <View style={Dimensions.get('window').width > 1250 ? styles.containerLarge : styles.containerSmall}>

        {/* {showPanel
        ?
        <View style={{padding:10, width: Platform.OS === 'web' ? '30%' : '50%', backgroundColor: '#3385E5',}}>
          <View style={{flexDirection:'row', justifyContent:'space-between', borderBottomColor: 'white', borderBottomWidth: 1,}}>
              <Text style={{fontWeight:'bold', color:'white', padding:10}}>Settings</Text>
              <TouchableOpacity style={{paddingTop:10}} onPress={()=>setShowPanel(!showPanel)}>
              <Feather name="x" style={{fontSize: 20, color: 'white'}} />
            </TouchableOpacity>
          </View>
          <View style={{flexDirection:'row', alignContent:'space-between', marginTop:10}}>
            <Text style={{color:'white'}}>Current Account:</Text>
          </View>
          <Picker
              selectedValue={Accountid}
              onValueChange={item => {setViewForums(false); setViewSets(false); setAcctName(item.OwnersAccountTitle); setAccountid(item); setViewForums(true)}}
              defaultIndex={1}
              placeholder="Select an item"
              containerStyle={{ borderColor:'grey', borderWidth:3}}
              pickerStyleType={{backgroundColor: '#fafafa'}}
              style={{ fontSize:10, width: '90%', height:30, backgroundColor:'white'}}
              mode="dropdown">
              <Picker.Item key={'unselectable'} label="" value={-1}/>  
              { accounts.map((item)=>(
                <Picker.Item label={item.OwnersAccountTitle} value={item.AccountId}/>)
              )}
          </Picker>
          {viewForums
            ?
              <>
                  <Text style={{color:'white'}}>Current Focus Room:</Text>
                  <View style={{flexDirection:'row', alignContent:'space-between'}}>
                      <Picker
                          selectedValue={Forumid}
                          onValueChange={item => {setForumid(item); setAcctGrp(item.GroupName); setViewSets(true)}}
                          defaultIndex={0}
                          placeholder="Select an item"
                          containerStyle={{ borderColor:'grey', borderWidth:3}}
                          style={{ fontSize:10, width: '90%', height:30, backgroundColor:'white'}}
                          mode="dropdown">
                          <Picker.Item key={'unselectable'} label="" value={-1}/>   
                          { accountGrpDetails.map((item)=>(
                          <Picker.Item label={item.GroupName} value={item.Forumid}/>)
                          )}
                      </Picker>
                      <View style={{flexDirection:'row', justifyContent:'flex-end', paddingRight:2, paddingTop:2,}}>
                              <Feather name="x" style={{
                                fontSize: 20,
                                color: 'white'
                              }} onPress={()=> { 
                                setViewForums(false); setViewSets(false)
                              }}/>
                      </View>
                    </View>
              </>
          : null
          }
          {viewSets
            ?
              <>   
                    <Text style={{color:'white'}}>Current Sets:</Text>
                    <View style={{flexDirection:'row', alignContent:'space-between'}}>
                    <Picker
                        selectedValue={Portfolioid}
                        onValueChange={item => {setPortfolioid(item); setSetName(item.PortfolioName);}}
                        defaultIndex={0}
                        placeholder="Select an item"
                        containerStyle={{ borderColor:'grey', borderWidth:3}}
                        style={{ fontSize:10, width: '90%', height:30, backgroundColor:'white',}}
                        mode="dropdown">
                          <Picker.Item key={'unselectable'} label="" value={-1}/> 
                        { accountGrpSets.map((item)=>(
                          <Picker.Item label={item.PortfolioName} value={item.Portfolioid}/>)
                        )}
                    </Picker> 
                    <Feather name="x" style={{
                                fontSize: 20,
                                color: 'white'
                              }} onPress={()=> { 
                                setViewSets(false)
                              }}/>
                    </View>  
                    
                    </>
          : null
          }
        </View>
        :
        <View style={{flexDirection:'row', justifyContent:'center', backgroundColor:'#2089dc', paddingTop:15, paddingLeft:5, paddingRight:5}}>
          <TouchableOpacity onPress={()=> {getAccnts(); setShowPanel(!showPanel); } }>
            <Feather name="settings" style={{fontSize: 20, color: 'white'}} />
          </TouchableOpacity>
        </View>
        } */}

        <ScrollView style={{backgroundColor: Platform.OS === 'web' ? 'black':'black'}}>

      <LinearGradient
          // Button Linear Gradient
          colors={['black', 'black', 'black']}
          style={{ padding: 15, alignItems: 'center', }}>

      <View style={{paddingLeft:20}}>

        {Platform.OS === 'web' //Accountid != 0 && Platform.OS === 'web'
        ?
          <BoolSearchBar
            Accountid={Accountid}
          />
          :null
        }

    </View>
    
      </LinearGradient>

      {!viewResults
          ?
          <>
          <View style={{flexDirection:'row', justifyContent:'space-between', width:'95%', height:40, marginTop:20, paddingLeft:30}}>
          {/* <TouchableOpacity style={{marginTop: 18, paddingLeft: 10, paddingRight: 0}} onPress={() => setResults(!viewResults)}>
            <Feather name="menu" style={{
              fontSize: 35,
              color: 'white'
            }}/>
          </TouchableOpacity> */}
           <Image style={{width:120, height: 90, top:-60, resizeMode:'contain'}} source={Logo} />
           <Image style={{width:120, height: 90, top:-60, resizeMode:'contain'}} source={{uri:'https://cdn.mesearch.ai/assets/default/kk_reg.png'}} />             

          {/* <TouchableOpacity style={{marginTop: 18, paddingLeft: 10, paddingRight: 0}} onPress={() => { kSignout(); }}>
            <Feather name="log-out" style={{
              fontSize: 35,
              color: 'white'
            }}/>
          </TouchableOpacity> */}
        </View>

        <View style={{alignItems:'center', top:-20}}>
          <Text style={{color:'white',}}>{selectedAcctGrp}</Text>
        </View>

              <KioskGrid
                navigation={navigation}
                activeAcct={Accountid}
                Portfolioid={Portfolioid}
                Forumid={Forumid}
                accounts={accounts}
                accountGrpDetails={accountGrpDetails}
                accountGrpSets={accountGrpSets}
                portfolioItems={portfolioItems}
                setAccountid={setAccountid}
                setForumid={setForumid}
                setPortfolioid={setPortfolioid}
                getPdfBase64={getPdfBase64}
                pdfBase64={pdfBase64}
                setResults={setResults}
                viewResults={viewResults}
                selectedAcctName={selectedAcctName}
                setAcctName={setAcctName} 
                selectedAcctGrp={selectedAcctGrp} 
                setAcctGrp={setAcctGrp}
                selectedSetName={selectedSetName} 
                setSetName={setSetName}
                viewRS={viewRS}
                setViewRS={setViewRS}
              />
              </>
          : 

          <View style={{ alignItems: Platform.OS === 'web' ? 'center': null}}>
            {/* <View style={{alignItems:'center', top:-20}}>
              <Text style={{color:'silver', fontSize:20}}>Details | <Text style={{textDecorationLine:'underline', color:'#3192D4'}}>For You</Text> | Home</Text>
            </View> */}
            {Platform.OS === 'web' 
            ?
            <View style={styles.titleStyle}>
                <View style={{ flexDirection:'row', width:245, justifyContent:'space-between'}}>
                    <Text style={{fontSize: 18, paddingBottom:5, color: '#2867B2',}}>{viewRS} </Text>
                    <Text style={{fontSize: 14, color:'white'}}>{selectedAcctGrp}</Text>
                    <Feather name="refresh-cw" style={{fontSize: 20, color: 'white', width: 35}} onPress={() => setResults(!viewResults)}/> 
                </View>                 
            </View>          
            : null
            }

          {viewRS === 'Accounts:' ?     
          <FlatList
            data={accounts}
            horizontal={!viewResults}
            keyExtractor={item => item.AccountId }
            maxToRenderPerBatch={3}
            nitialNumToRender={3}
            snapToAlignment={"end"}
            snapToEnd={true}
            snapToInterval={Dimensions.get('window').height}
            decelerationRate={"normal"}
            pagingEnabled={true}
            renderItem={({item, index}) => {
                if (index > 3) {
                  return
                }
                return (
                  <Swipeable
                  onSwipeableLeftOpen={() => {console.log('Swiped Left Accounts')}}
                  onSwipeableRightOpen={() => {setResults(!viewResults), setViewStatus('center')}}

                  onSwipeableLeftWillOpen= {() => {setViewStatus('left')}}
                  onSwipeableRightWillOpen= {() => {setViewStatus('right')}}
                  onSwipeableWillClose= {() => {setViewStatus('center')}}

                  onSwipeableClose={() => {setViewStatus('center')}}
                  
                  renderLeftActions={(progress, dragx) => <LeftItem />}
                  renderRightActions={(progress, dragx) => <RightItem />}>

                    <View style={{ paddingRight:10}}> 
                        <LinearGradient
                        colors={['black', '#ffffff00', 'black']} // transparent
                        style={{ justifyContent:'center', alignItems: 'center', borderRadius: 5, 
                        width: Platform.OS === 'web' ? 250 : '100%', 
                        height: Dimensions.get('window').height, marginBottom:20 }}
                        >
                            {/* <Image
                            //source={{uri: "https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/cdn1/AccountImage_"+ `${item.AccountId}` + ".png"}}
                            source={{uri: "https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/cdn1/AccountImage_"+ `${item.AccountId}` + ".png"}}
                            defaultSource='https://cdn.mesearch.ai/assets/default/Crivella-Ai-logo_Blu.png'
                            //defaultSource='https://cdn.mesearch.ai/assets/mesearch/logo_sm.png'
                            style={{flex:1, width: Platform.OS === 'web' ? 250 : '100%', maxHeight: Dimensions.get('window').height,}}
                            resizeMode="contain"
                            /> */}
                            
                            {Platform.OS === 'web' 
                            ?
                            <View style={{ flexDirection:'row', justifyContent:'space-between', alignItems: 'center', width:'100%', height:30, paddingLeft:15, backgroundColor:'red'}}>
                            <Feather name="arrow-left-circle" onPress={() => {alert()}} style={{fontSize: 20, color: 'white', width: 35}}/>
                            <Feather name="arrow-right-circle" style={{fontSize: 20, color: 'white', width: 35}}/>
                            </View>
                            :null
                            }

                            <ImageBackground 
                                style={{flex:1, width: Platform.OS === 'web' ? 250 : '100%', maxHeight: '100%', alignItems: 'center'}}
                                imageStyle={{ borderRadius: 4 }}
                                resizeMode= {Platform.OS === 'web' ? "contain": "contain"}
                                source={{uri: "https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/cdn1/AccountImage_"+ `${item.AccountId}` + ".png"}}
                                //source={{uri: "https://cdn.mesearch.ai/assets/default/samplePortrait.jpeg"}}
                                //defaultSource='https://cdn.mesearch.ai/assets/default/samplePortrait.jpeg'
                            >
                            <View style={{ flexDirection:'column', justifyContent:'space-between', alignItems: 'flex-end', width:'100%', height:30, paddingLeft:15, top:200, paddingRight:10 }}>                             
                              <View style={{flexDirection:'column', alignItems:'center'}}>
                                <Foundation name="heart" onPress={() => {alert('Like')}} style={{paddingLeft:5, fontSize: 44, color: 'red', width: 44, }}/>
                                <Text style={{fontSize:10, color:'white'}}>32</Text>
                              </View>
                              <View style={{flexDirection:'column', alignItems:'center'}}>
                                <FontAwesome name="commenting" onPress={() => {alert('Comment')}} style={{fontSize: 44, color: 'white', width: 44, }}/>
                                <Text style={{fontSize:10, color:'white'}}>4</Text>
                              </View>
                              <View style={{flexDirection:'column', alignItems:'center'}}>
                                <FontAwesome name="share" onPress={() => {alert('Share')}} style={{fontSize: 44, color: 'white', width: 44, }}/>
                                <Text style={{fontSize:10, color:'white'}}>5</Text>
                              </View>
                            </View>
                            </ImageBackground>
                        </LinearGradient>  

                        <View style={{position: 'absolute', left: 0, right: 0, bottom: 60, height: 70, width: Platform.OS === 'web' ? 250 : '100%',}}>
                            <View style={{ height: 50, paddingTop: 1, width: Platform.OS === 'web' ? 250 : '100%', }}>
                                <Text numberOfLines={2} style={styles.name}>{item.OwnersAccountTitle}</Text>
                            </View>
                            <View style={{ flexDirection:'row', justifyContent:'space-between', height: 30, width: Platform.OS === 'web' ? 250 : '100%',}}>
                                <View style={{ flexDirection:'row', paddingLeft: 5,}}>
                                <Text numberOfLines={1} style={styles.textDescStyle, {fontSize:8}}>{item.AccountId} / {item.KioskID}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                
                  </Swipeable>)
                }
            }
            />
            :null} 

          {viewRS === 'Focus Rooms:' ?    
          <FlatList
            data={accountGrpDetails}
            horizontal={!viewResults}
            keyExtractor={(item) => item.id }
            renderItem={(item) => {
                return (
                  <Swipeable
                  onSwipeableLeftOpen={() => console.log('Swiped Left Groups')}
                  onSwipeableRightOpen={() => console.log('Swiped right Groups')}
                  renderLeftActions={(progress, dragx) => <LeftItem />}
                  renderRightActions={(progress, dragx) => <RightItem />}
                  >

                <View style={{ paddingRight:10}}> 
                    <TouchableOpacity onPress={() => {setForumid(item.item.Forumid); setAcctGrp(item.item.GroupName)}}>
                        <LinearGradient
                        colors={['black', 'black', 'black']} /// red
                        style={{ justifyContent:'center', alignItems: 'center', borderRadius: 5, width: Platform.OS === 'web' ? 250 : '100%', height: Dimensions.get('window').height-120, }}>
                        <Image
                        source={{uri: "https://www.knowledgekiosk.com/baselinekiosk/html/kksknowledgekiosk/cdn1/GroupImage_" + `${item.item.Groupid}` +'_'+ `${item.item.AccountId}` + ".png"}}
                        //defaultSource='https://cdn.mesearch.ai/assets/default/Crivella-Ai-logo_Blu.png'
                        defaultSource='https://cdn.mesearch.ai/assets/mesearch/logo_sm.png'
                        style={{flex:1, width: Platform.OS === 'web' ? 250 : '100%', maxHeight: 180}}
                        resizeMode= {Platform.OS === 'web' ? "contain": "cover"}
                        />   
                        </LinearGradient>
                    </TouchableOpacity>

                    <View style={{height: 70, width: Platform.OS === 'web' ? 250 : '100%', marginBottom:20, backgroundColor: '#000000', opacity: 0.9}}>
                        <View style={{ height: 50, paddingTop: 1, width: Platform.OS === 'web' ? 250 : '100%', marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <Text numberOfLines={2} style={styles.name}  >{item.item.GroupName}</Text>
                        </View>
                        <View style={{ flexDirection:'row', justifyContent:'space-between', height: 30, width: Platform.OS === 'web' ? 250 : '100%', marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <View style={{ flexDirection:'row', paddingLeft: 5,}}>
                            <Text numberOfLines={1} style={styles.textDescStyle}>{item.item.Groupid}_{item.item.AccountId}</Text>
                            </View>
                            <View style={{ flexDirection:'row'}}>
                            <Feather name="share-2" style={{fontSize: 20, color: 'white', width: 35}}/>
                            <Feather name="activity" style={{fontSize: 20, color: 'white', width: 35}}/>
                            <Feather name="settings" style={{fontSize: 20, color: 'white', width: 35}} onPress={() => {setForumid(item.item.Forumid); setAcctGrp(item.item.GroupName)} }/>
                            </View>
                        </View>
                    </View>
                </View>
                </Swipeable>
                )
            }
            }
        />
        :null} 

        {viewRS === 'Sets:' ?   
        <FlatList
            data={accountGrpSets}
            horizontal={!viewResults}
            keyExtractor={(item) => item.id }
            renderItem={(item) => {
                return (
                  <Swipeable
                  onSwipeableLeftOpen={() => console.log('Swiped Left Sets')}
                  onSwipeableRightOpen={() => console.log('Swiped right Sets')}
                  renderLeftActions={(progress, dragx) => <LeftItem />}
                  renderRightActions={(progress, dragx) => <RightItem />}
                  >
                <View style={{ paddingRight:10}}> 
                    <TouchableOpacity onPress={() => {setPortfolioid(item.item.Portfolioid); setSetName(item.item.PortfolioName)}}>
                        <LinearGradient
                        colors={['black', 'black', 'black']} // Green
                        style={{ justifyContent:'center', alignItems: 'center', borderRadius: 5, width: Platform.OS === 'web' ? 250 : '100%', height: Dimensions.get('window').height-120, }}>
                            <Image
                              source={{uri: 'https://cdn.mesearch.ai/assets/default/Crivella-Ai-logo_Blu.png'}}
                              defaultSource='https://cdn.mesearch.ai/assets/default/Crivella-Ai-logo_Blu.png'
                              //defaultSource='https://cdn.mesearch.ai/assets/mesearch/logo_sm.png'
                              style={{flex:1, width: Platform.OS === 'web' ? 250 : '100%', maxHeight: 180}}
                              resizeMode="contain"
                              />   
                        </LinearGradient>  
                    </TouchableOpacity>

                    <View style={{height: 70, width: Platform.OS === 'web' ? 250 : '100%', marginBottom:20, backgroundColor: '#000000', opacity: 0.9}}>
                        <View style={{ height: 50, paddingTop: 1, width: Platform.OS === 'web' ? 250 : '100%', marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <Text numberOfLines={2} style={styles.name}  >{item.item.PortfolioName}</Text>
                        </View>
                        <View style={{ flexDirection:'row', justifyContent:'space-between', height: 30, width: Platform.OS === 'web' ? 250 : '100%', marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <View style={{ flexDirection:'row', paddingLeft: 5,}}>
                            <Text numberOfLines={1} style={styles.textDescStyle}></Text>
                            </View>
                            <View style={{ flexDirection:'row'}}>
                            <Feather name="share-2" style={{fontSize: 20, color: 'white', width: 35}}/>
                            <Feather name="activity" style={{fontSize: 20, color: 'white', width: 35}}/>
                            <Feather name="settings" style={{fontSize: 20, color: 'white', width: 35}} onPress={() => {setPortfolioid(item.item.Portfolioid); setSetName(item.item.PortfolioName) }}/>
                            </View>
                        </View>
                    </View>
                </View>
                </Swipeable>
                )
            }
            }
        />
        :null} 

        {viewRS === 'Documents:' ?   
        <FlatList
            data={portfolioItems}
            horizontal={false}
            keyExtractor={(item) => item.ke_id }
            renderItem={(item) => {
                return (
                  <Swipeable
                  onSwipeableLeftOpen={() => console.log('Swiped Left Sets')}
                  onSwipeableRightOpen={() => console.log('Swiped right Sets')}
                  renderLeftActions={(progress, dragx) => <LeftItem />}
                  renderRightActions={(progress, dragx) => <RightItem />}
                  >
                <View style={{ paddingRight:10}}> 
                    <TouchableOpacity onPress={() => navigation.navigate('PdfViewer', {Accountid: activeAcct, ke_id : item.item.ke_id, uri : item.item.pdf_link})}>
                        <LinearGradient
                        colors={['black', 'black', 'black']} // Purple
                        style={{ justifyContent:'center', alignItems: 'center', borderRadius: 5, width: Platform.OS === 'web' ? 250 : '100%', height: 380, }}>
                        {Platform.OS === 'web'
                                ?
                                <TouchableOpacity onPress={() => navigation.navigate('PdfViewer', {Accountid: activeAcct, ke_id : ke_id})}>
                                <iframe 
                                    width="100%" 
                                    height= "500"
                                    src={'https://mobile.knowledgekiosk.com/cm_pp.pdf'}
                                    //src={`${pdfBase64}`}
                                    scrolling="auto"
                                    frameBorder="1"
                                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen
                                /> 
                                </TouchableOpacity>
                            :
                            
                            <View style={{width: 250, height: 380}}>
                            <TouchableOpacity  onPress={() => navigation.navigate('PdfViewer', {Accountid: activeAcct, ke_id : ke_id})}>
                            <PDFReader
                                source={{
                                uri: 'https://mobile.knowledgekiosk.com/cm_pp.pdf',
                            }}
                            /> 
                            </TouchableOpacity>
                            </View>
                            
                        }

                        </LinearGradient>  
                    </TouchableOpacity>

                    <View style={{height: 70, width: Platform.OS === 'web' ? 250 : '100%', marginBottom:20, backgroundColor: '#000000', opacity: 0.9}}>
                        <View style={{ height: 50, paddingTop: 1, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <Text numberOfLines={2} style={styles.name}  >{item.item.title}</Text>
                        </View>
                        <View style={{ flexDirection:'row', justifyContent:'space-between', height: 30, width: 250, marginTop: -4, backgroundColor: '#000000', opacity: 0.9}}>
                            <View style={{ flexDirection:'row', paddingLeft: 5,}}>
                            <Text numberOfLines={1} style={styles.textDescStyle}>Ke_id: {item.item.ke_id}</Text>
                            </View>
                            <View style={{ flexDirection:'row'}}>
                            <Feather name="activity" style={{fontSize: 20, color: 'white', width: 35}}/>
                            <Feather name="settings" style={{fontSize: 20, color: 'white', width: 35}} onPress={() => navigation.navigate('PdfViewer', {Accountid: activeAcct, ke_id : item.item.ke_id, uri : item.item.pdf_link})}/>
                            </View>
                        </View>
                    </View>
                </View>
                </Swipeable>
                )
            }
            }
        />
        :null} 

          </View>
      }

          </ScrollView>

          {Platform.OS !== 'web' && viewResults
            ?
            <View style={{position: 'absolute', left: 0, right: 0, top: 20,}}>
              <View style={{flexDirection:'row', justifyContent:'space-evenly', paddingTop:5, paddingLeft:50, paddingRight:50}}>
                <Text style={{color: viewStatus==='left' ? 'white' :'#e8eaec', textDecorationLine: viewStatus==='left' ? 'underline':null, fontWeight:'bold', fontSize:20,
                  textShadowColor: 'rgba(0, 0, 0, 0.75)', textShadowOffset: {width: -1, height: 1},  textShadowRadius: 10}}>Details</Text>
                <Text> | </Text>
                <Text style={{color: viewStatus==='center' ? 'white' :'#e8eaec', textDecorationLine: viewStatus==='center' ? 'underline':null, fontWeight:'bold', fontSize:20,
                  textShadowColor: 'rgba(0, 0, 0, 0.75)', textShadowOffset: {width: -1, height: 1},  textShadowRadius: 10}}>For You</Text>
                <Text> | </Text>
                <Text style={{color: viewStatus==='right' ? 'white' :'#e8eaec', textDecorationLine: viewStatus==='right' ? 'underline':null, fontWeight:'bold', fontSize:20,
                  textShadowColor: 'rgba(0, 0, 0, 0.75)', textShadowOffset: {width: -1, height: 1},  textShadowRadius: 10}}>Home</Text>
              </View>
            </View>
            :null}

          {Platform.OS !== 'web' 
          ?
          <View style={{position: 'absolute', left: 0, right: 0, bottom: 0, }}>

          <View style={{alignItems:'center', backgroundColor:'silver',}}>
              {Platform.OS !== 'web' && viewUpload
                ?
                  <UploadPanel/>
                :null
              }
          </View>

          <View style={{alignItems:'center', backgroundColor:'red',}}>
              {Platform.OS !== 'web' && viewProfile
                ?
                <ProfileInfo/>
                :null
              }
          </View>

          <View style={{alignItems:'center', backgroundColor:'black', paddingTop:5}}>
              {Platform.OS !== 'web' && ShowSearch
                ?
                <BoolSearchBar
                  Accountid={Accountid}
                  setShowSearch={setShowSearch}
                  ShowSearch={ShowSearch}
                />
                :null
              }
          </View>

            <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop:5, paddingLeft:10, paddingRight:10, height:50, backgroundColor:'black'}}>
              <View style={{flexDirection:'column',}}>
              <Feather name="home" style={{fontSize: 30, color: 'white', width: 35}} onPress={() => {setResults(false), setShowSearch(false), setViewUpload(false),setViewProfile(false)}}/>
              <Text style={{fontSize:10, color:'white'}}>Home</Text>
              </View>
              <View style={{flexDirection:'column',}}>
              <Feather name="search" style={{fontSize: 30, color: 'white', width: 35}} onPress={() => {setShowSearch(!ShowSearch), setViewUpload(false),setViewProfile(false)}}/>
              <Text style={{fontSize:10, color:'white'}}>Discover</Text>
              </View>
              
              <Feather name="plus-square" style={{paddingLeft:2, fontSize: 40, color: 'white', width: 45, backgroundColor:'#3385e5'}}  onPress={() => {navigation.navigate('UploadPanel')}}/>
              
              <View style={{flexDirection:'column',alignItems:'center'}}>
              <Feather name="user" style={{fontSize: 30, color: 'white', width: 35}} onPress={() => {navigation.navigate('ProfileInfo')}}/> 
              <Text style={{fontSize:10, color:'white'}}>Me</Text>
              </View>
              <View style={{flexDirection:'column',}}>
              <Feather name="log-out" style={{fontSize: 30, color: 'white', width: 35}}/>
              <Text style={{fontSize:10, color:'white'}} onPress={() => { kSignout(); }}>Logout</Text>
              </View>


            </View> 

          </View>
          
          :null
          } 
        

    {/* </SafeAreaView> */}
    </View>
</>
    );
};

// Styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerSmall: {
    flex: 1,
    flexDirection:'row',
    justifyContent: 'center',
    paddingBottom: 50,
    //backgroundColor: darkMode ? '#3c3c3d' : 'white'
  },
  containerLarge: {
    flexDirection:'row',
    width: Dimensions.get('window').width,
    //backgroundColor: darkMode ? '#3c3c3d' : 'white',
    paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
    paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
    justifyContent: 'center',
    paddingBottom: 150,
    marginTop:20,
    //opacity: viewModal ? 0.4 : 1.0
  },
  textDescStyle: {
    color: 'white',
    fontWeight: '300',
    fontSize: 14,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 10
  },
  name: {
    width: 250,
    height: 42,
    fontWeight: 'bold',
    color: 'white',
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
  },
  titleStyle: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
    paddingLeft: 5,
    paddingBottom:5,
    marginBottom:10,
    paddingTop:5,
    backgroundColor: 'black',
    overflow: 'hidden'
  },
  blank: {
    width: 250,
    height: 250,
    color:'white',
    justifyContent:'center', 
    alignItems:'center',
    elevation:5
  },
  pdf: {
    flex:1,
    width:Dimensions.get('window').width,
    height:Dimensions.get('window').height,
  },

  leftItem: {
    flex:1,
    backgroundColor: '#3385E5',
    //justifyContent: 'center',
  },
  leftItemText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#fff',
  },
  rightItem: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'red',
   // justifyContent: 'center',
  },
  deleteButtonStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: 'black',
  },
  textButtonStyle: {
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
  },
  archiveButtonStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: '#3e64ff',
  },
  archiveTextButtonStyle:{
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
  },
});

KioskMainScreen.navigationOptions = ({
  headerBackground: null,
  title: "Kiosk Portal",
  headerLeft: <HeaderBackButton title="Back" onPress={() => {
    navigate('Settings')
  }}/>,
  headerStyle: {
   height: 40
  }
});

export default KioskMainScreen;