// TODO: HACK to fix tag,tag,tag,tag from rendering
function fixTagRunon(tags) {
  let newTags = [];
  tags.forEach(tag => {
    const tagsCommaSep = tag.split(',');
    newTags.push(tagsCommaSep[0]);
  });
  return newTags;
}

function fixConTagRunon(conTags) {
  conTags.forEach(conTag => {
    if (conTag.conTag) {
      conTag.conTag = conTag.conTag.split(',')[0];
    }
  });
  return conTags;
}

export { fixTagRunon, fixConTagRunon };