import React, { useContext, useState, useEffect } from 'react';
import  { Text, Button, Input, CheckBox } from 'react-native-elements';
import {Picker, TextInput, StyleSheet, Image, FlatList, Dimensions, View, ScrollView, SafeAreaView, ImageBackground, TouchableOpacity } from 'react-native';
import Logo from '../../../assets/Crivella-Ai-logo.png';
import Spacer from '../Space';
import { Feather } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { navigate } from '../../navigationRef';
import { Platform } from 'react-native';
import PDFReader from 'rn-pdf-reader-js';

const BoolDat = [{name:'AND'},{name:'OR'},{name:'NOT'}]
const DcDat = [
  {name:'All Field'},
  {name:'Text'},
  {name:'Title'},
  {name:'Creator'},
  {name:'Identifier'},
  {name:'Subject'},
  {name:'Description'},
  {name:'Publisher'},
  {name:'Contributor'},
  {name:'Source'},
  {name:'Audience'},
  {name:'Relation'},
  {name:'Date'},
  {name:'Type'},
  {name:'Coverage'},
  {name:'Format'},
  {name:'Language'},
  {name:'Rights Holder'},
  {name:'Provenance'},
]

    const boolSearch = ({ navigation, Accountid, setShowSearch, ShowSearch, activeAcct, Portfolioid, Forumid, viewRS, setViewRS
        }) => {

    const [SearchTerm, setSearchTerm] = useState('');
    const [BasicTerm, setBasicTerm] = useState('');
    const [boolItems, setBoolItems] = useState([]);
    const [showDC, setShowDC] = useState(false);
    const [showFull, setShowFull] = useState(false);

    const addBool = () => {
      let newBoolItems = JSON.parse(JSON.stringify(boolItems)); 
      newBoolItems.push({id:`${boolItems.length+1}`, logic:'AND', dc:'ALL', term:''});
      setBoolItems(newBoolItems)
    };
      
    const remBool = (index) => {
      let newBoolItems = JSON.parse(JSON.stringify(boolItems)); 
      if (newBoolItems.length === 1) {
        newBoolItems = [];
      } else {
        newBoolItems.splice(index,1);
      }

      setBoolItems(newBoolItems)
    };

    const updateTerm = (id, term) => {
      let newBoolItems = JSON.parse(JSON.stringify(boolItems)); 
      let logic = newBoolItems[id].logic;
      let dc = newBoolItems[id].dc;
      newBoolItems.splice(id,1,{id: `${id}`,logic,dc,term});
      setBoolItems(newBoolItems)
    };

    const updateLogic = (id, logic) => {
      let newBoolItems = JSON.parse(JSON.stringify(boolItems)); 
      let dc = newBoolItems[id].dc;
      let term = newBoolItems[id].term;
      newBoolItems.splice(id,1,{id: `${id}`,logic,dc,term});
      setBoolItems(newBoolItems)
    };

    const updateDC = (id, dc) => {
      let newBoolItems = JSON.parse(JSON.stringify(boolItems)); 
      let logic = newBoolItems[id].logic;
      let term = newBoolItems[id].term;
      newBoolItems.splice(id,1,{id: `${id}`,logic,dc,term});
      setBoolItems(newBoolItems)
    };
        
    // Render use JSX syntax
    return (
        <View style={{paddingLeft:5, paddingRight:5, backgroundColor:showFull ? '#3385E5': 'black' , paddingTop:showFull ? 70:null , height: showFull? Dimensions.get('window').height:null}}>
          {/* {Accountid != 0 
          ?  */}
          {showFull
          ?
          <View style={{justifyContent:'center', alignItems:'center',paddingBottom:10, paddingTop:10}}>
          <Text style={{fontWeight:'bold', color:'white'}}>Advanced Search</Text>
          </View>
          :null}
          
          <FlatList
            data={boolItems}
            keyExtractor={item => item.id.toString() }
            horizontal={false}
            style={{flexGrow:0, }}
            showsVerticalScrollIndicator={false}
            ListHeaderComponent = { () => { 
              return (
                  <View style={{flexDirection:'row', justifyContent:'flex-start', paddingTop:5}}>
 
                      <TouchableOpacity style={{width:55, justifyContent:'center', paddingLeft:10, paddingRight:5}} onPress={() => {addBool(),setShowFull(true)}}>
                        <Text style={{color:'white', fontSize:30}}>+</Text>
                      </TouchableOpacity>

                      <TouchableOpacity style={{justifyContent:'center', alignItems:'center', marginLeft:-10, width:30, backgroundColor:'silver'}} onPress={() => alert('searching')}>
                        <Feather name="search" style={{
                          fontSize: 20,
                          color: 'black',
                        }}/>
                      </TouchableOpacity>

                      <TextInput
                        label=""
                        autoCapitalize="none"
                        autoCorrect={false}
                        multiline={false}
                        style={{ backgroundColor:'#ececec', paddingLeft:5, width: Platform.OS === 'web' ? 280: 280, height:40, fontSize:14,}}
                        value={BasicTerm}
                        onChangeText={(value) => {setBasicTerm(value)}}
                        onSubmitEditing={(value) => {setBasicTerm(value)}}
                        placeholder='Search'
                        placeholderTextColor='black'
                        autoCapitalize="none"
                      />
                      <TouchableOpacity style={{justifyContent:'center', alignItems:'center',  width:30, backgroundColor:'silver'}}>
                          <Feather name="x-circle"
                              onPress={() =>setShowSearch(!ShowSearch)}
                              style={{
                                paddingLeft:5,
                                fontSize: 24,
                                color: 'black',
                          }}/>
                      </TouchableOpacity>
                  </View>
              )}}
            renderItem={({item, index}) => {
                return (
                  <View style={{flexDirection:'row', justifyContent:'flex-start', paddingTop:5}}>

                    <View style={{backgroundColor:'white', marginRight:5,}}>
                      <Picker 
                        selectedValue={item.logic}
                        onValueChange={(value) => {
                          {updateLogic(index, value)};
                        }}
                        itemStyle={{padding:0, fontSize: 5}} //ios
                        style={{width:100, height:30, padding:0, fontSize:5 }}
                        containerStyle={{padding:0}}
                        >
                        { BoolDat.map((item)=>(
                          <Picker.Item style={{fontSize:5}} label={item.name} value={item.name}/>)
                        )}
                      </Picker>
                    </View>

                        {showDC
                        ?
                        <View style={{backgroundColor:'white', marginRight:5,}}>
                          <Picker 
                            selectedValue={item.dc}
                            onValueChange={(value) => {{updateDC(index, value)};}}
                            containerStyle={{width: 80, height:30,}}
                            style={{ width:90, height:30, fontSize:12, }}
                            itemStyle={{padding:0, fontSize: 5}} //ios
                          >
                            { DcDat.map((item)=>(
                              <Picker.Item style={{fontSize:5}} label={item.name} value={item.name}/>)
                            )}
                          </Picker> 
                          </View>
                        :
                        <View style={{backgroundColor:'#e4e4e4'}}>
                        <TouchableOpacity style={{flex:1, justifyContent:'center', alignItems:'center', width:30}} onPress={() => setShowDC(!showDC)}>
                          <Feather name="more-vertical" style={{
                            color: 'black',
                            fontSize: 20,
                          }}/>
                        </TouchableOpacity>
                        </View>
                        }

                      <TextInput
                        label=""
                        autoCapitalize="none"
                        autoCorrect={false}
                        multiline={false}
                        style={{fontSize:14, backgroundColor:'white', paddingLeft:5, width: Platform.OS === 'web' ? showDC ? 215 : 290 : showDC ? 155 : 220, height:30,  }}
                        value={item.term}
                        onChangeText={(SearchTerm) => {updateTerm(index, SearchTerm)}}
                        placeholder='Search'
                        placeholderTextColor='grey'
                        autoCapitalize="none"
                      />

                      <TouchableOpacity style={{justifyContent:'center', alignItems:'center'}} onPress={() => {remBool(index); setShowDC(!showDC)}}>
                      <Feather name="x" 
                      style={{
                        fontSize: 24,
                        color: 'white',
                        }}/>
                      </TouchableOpacity>

                  </View>
                )
            }
            }
          />
          {/* : null
          } */}
        </View>
    )
  };

  // Styles
const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    containerSmall: {
      flex: 1,
      justifyContent: 'center',
      paddingBottom: 50,
      //backgroundColor: darkMode ? '#3c3c3d' : 'white'
    },
    containerLarge: {
      width: Dimensions.get('window').width,
      //backgroundColor: darkMode ? '#3c3c3d' : 'white',
      paddingLeft: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
      paddingRight: Dimensions.get('window').width > 1250 ? ((Dimensions.get('window').width - 1250)/2) +150 : null,
      justifyContent: 'center',
      paddingBottom: 150,
      marginTop:20,
      //opacity: viewModal ? 0.4 : 1.0
    },
    textDescStyle: {
      color: 'white',
      fontWeight: '300',
      fontSize: 14,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 10,
    },
    name: {
      width: 250,
      height: 42,
      fontWeight: 'bold',
      color: 'white',
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
    },
    titleStyle: {
      fontSize: 24,
      fontWeight: 'bold',
      color: 'white',
      paddingLeft: 5,
      paddingBottom:5,
      marginBottom:10,
      paddingTop:5,
      backgroundColor: 'black',
      overflow: 'hidden'
    },
    blank: {
      width: 250,
      height: 250,
      color:'white',
      justifyContent:'center', 
      alignItems:'center',
      elevation:5
    },
    pdf: {
      flex:1,
      width:Dimensions.get('window').width,
      height:Dimensions.get('window').height,
    }
  });

  export default boolSearch;