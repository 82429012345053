import React, {useState, useEffect, useContext} from 'react';
import { View, Text, ScrollView, ActivityIndicator } from 'react-native';
import FormWrapper from '../components/wrappers/MeSearch/FormWrapper';
import FormFieldTypesEnum from './utils/FormValidation/formFieldTypesEnum';
import SubmitField from './utils/FormValidation/FormValidationSubmitField';
import ValidationTypesEnum from './utils/FormValidation/formValidateTypesEnum';
import EditCenterWrapper from '../components/wrappers/kiosk/EditCenterWrapper';
import KioskAPI from '../api/kiosk/MeSearch-API-Kiosk';
import { Platform } from 'react-native';

const ManageFocusCenterScreen = ({navigation, route}) => {

  
  const [step, setStep] = useState(1);
  const [multiValueIndex, setMultiValueIndex] = useState(0);
  const groupName = route.params?.groupName ?? '';
  const groupId = route.params?.groupId ?? null;
  const accountId = route.params?.AccountId ?? null;
  const createCenter = route.params?.create ?? null;
  const forumId = route.params?.forumId ?? null;

  const [centerDesc, setCenterDesc] = useState("");
  const [centerUsers, setCenterUsers] = useState("");

  const [centerValues, setCenterValues] = useState({
    centerName: { name: "Center Name", value: groupName, data:{}, error: "", required: true, placeholder: "", type: FormFieldTypesEnum.TEXTINPUT, step: 1 }, // NOTE STEP
    centerDescription: { name: "Description", value: centerDesc, error: "", required: true, placeholder: "", type: FormFieldTypesEnum.TEXTINPUT, step: 1 },
    visibility: { name: "Visibility Restrictions", value: createCenter ? {"Open": false, "Restricted": false,"Private": true,} : {}, error: "", required: true, placeholder: "name@example.com", type: FormFieldTypesEnum.RADIO, step: 1 },
    sharing: { name: "Sharing Restrictions", value:  createCenter ? {"Open": false, "Closed": false,"Private": true,} : {}, error: "", required: true, placeholder: "234-567-8900", type: FormFieldTypesEnum.RADIO, step: 1 },
    centerUsers: { name: "Included Users", value: centerUsers, error: "", required: true, placeholder: "", type: FormFieldTypesEnum.TEXTINPUT, step: 1 },
  });

  useEffect(() => {
    if (groupId) {

      const fetchForumData = async () => {
        let result;
        let response;
        
        try {
          response = await KioskAPI.get('/api/kiosk/account/FocusCenter', {params: {groupId:groupId, accountId:accountId,}});
          result = response.data;
          
          if (Object.keys(result).length > 0){
            
            let desc=result.Description
            let visible = {}
            let share = {}
            let includeUsers=result.IncludedMembers

            setCenterDesc(desc);
            setCenterUsers(includeUsers);

          ///////////////////////////////////////////////////////////
            // "Open": false,
            // "Restricted": false,
            // "Private": false,

            if (result.VisibilityRestriction=="Open") {
              visible={               
                "Open": true,
                "Restricted": false,
                "Private": false,}
            }
            if (result.VisibilityRestriction=="Restricted") {
              visible={               
                "Open": false,
                "Restricted": true,
                "Private": false,}
            }
            if (result.VisibilityRestriction=="Private") {
              visible={               
                "Open": false,
                "Restricted": false,
                "Private": true,}
            }

            // //////////////////////////////////
            // "Open": false,
            // "Private": false,
            // "Closed": false,
            
            if (result.SharedRestrictionName=="Open") {
              share={               
                "Open": true,
                "Closed": false,
                "Private": false,}
            }
            if (result.SharedRestrictionName=="Closed") {
              share={               
                "Open": false,
                "Closed": true,
                "Private": false,}
            }
            if (result.SharedRestrictionName=="Private") {
              share={               
                "Open": false,
                "Closed": false,
                "Private": true,}
            }

            setCenterValues({
              centerName: { name: "Center Name", value: groupName, data: result, error: "", required: true, placeholder: "", type: FormFieldTypesEnum.TEXTINPUT, step: 1 },
              centerDescription: { name: "Description", value: desc, error: "", required: true, placeholder: "", type: FormFieldTypesEnum.TEXTINPUT, step: 1 },
              visibility: { name: "Visibility Restrictions", value: visible, error: "", required: true, placeholder: "name@example.com", type: FormFieldTypesEnum.RADIO, step: 1 },
              sharing: { name: "Sharing Restrictions", value: share, error: "", required: true, placeholder: "234-567-8900", type: FormFieldTypesEnum.RADIO, step: 1 },
              centerUsers: { name: "Included Users", value: includeUsers, error: "", required: true, placeholder: "", type: FormFieldTypesEnum.TEXTINPUT, step: 1 },
              });

          }
        } catch (error) {
          console.log('there was an error retrieving data')
        }
      }

      fetchForumData();
    }
    // result would save into setInitialValues(result)
  }, [groupId]);

  const editCenterValidation = {
    centerName: { validate: SubmitField(ValidationTypesEnum.ANY) },
    centerDescription: { validate: SubmitField(ValidationTypesEnum.ANY) },
    visibility: { validate: SubmitField(ValidationTypesEnum.RADIO) },
    sharing: { validate: SubmitField(ValidationTypesEnum.RADIO) },
    centerUsers: { validate: SubmitField(ValidationTypesEnum.ANY) },
  };
  
    return (

        <ScrollView>
        {((Object.keys(centerValues.visibility.value).length > 0 && Object.keys(centerValues.sharing.value).length > 0) || !groupId) ?
        <FormWrapper 
          Component={EditCenterWrapper}
          values={centerValues}
          validation={editCenterValidation}
          navigation={navigation}
          route={route}
          setStep={setStep}
          step={step}
          index={multiValueIndex}
          setIndex={setMultiValueIndex}
          create={!groupId ? true : false}
        />
        :<ActivityIndicator size="large" style={{ marginTop: 100 }} color={Platform.OS === 'android' ? "#005157" : null}/>}
      </ScrollView>

    );
};
export default ManageFocusCenterScreen;