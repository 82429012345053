import React, { useState } from 'react';
import { View, Text, StyleSheet, Linking, TouchableOpacity, Image, Dimensions } from 'react-native';
import { Platform } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';
import CopyrightFooter from './CopyrightFooter';


const SearchFooter = ({ navToLogin, darkMode, isGuest, claimed }) => {
    return (
      <View style={{height: 70, width: Dimensions.get('window').width, marginBottom: 5, marginTop: 35, justifyContent: 'center', paddingRight: Dimensions.get('window').width > 1250 ? (Dimensions.get('window').width - 1250) : null}}>
        <View style={{flexDirection: (Platform.OS === 'web' && !isMobile) ? 'row' : null, justifyContent: 'center'}}>
          <View style={{height: (Platform.OS === 'web' && !isMobile) ? 20 : 60}}>
            {!(Platform.OS === 'web' && !isMobile) ?
                <View style={{flexDirection: 'row', justifyContent: 'center', paddingTop: 15}}>
                  <View style={{width: 200, paddingLeft: 5, justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                    {!claimed ?
                    <TouchableOpacity 
                      style={{flexDirection: 'row'}}
                      onPress={()=> {
                        if (!isGuest) {
                          if (Platform.OS === 'web') {
                            let nUrl = window.location.href.includes('?') ? `${window.location.href}&tcr=true` : `${window.location.href}?tcr=true`
                            window.open(`${nUrl}`)
                          } else {
                            Linking.openURL('https://mesearch.ai/?tcr=true')
                          }
                        } else {
                          navToLogin(true, true)
                        }
                      }}
                    >
                      <Text style={{fontSize: 12, fontWeight: '400', color: '#0E6938', paddingTop: 5}}>Become A  </Text>
                      <Image style={{height: 25, width: 75}} source={require('../../assets/trusted.png')}/>
                    </TouchableOpacity>
                    : null}
                  </View>
                </View>
                : null}
            <View style={{height: 15, flexDirection:  'row', justifyContent: 'center', marginTop: 2}}>
              <Text style={{fontSize: 12, color: darkMode ? 'white' : 'black'}}>{'Contact Us: '}</Text>
              <Text style={{fontSize: 12, color: darkMode ? 'white' : 'blue'}} onPress={ () => Platform.OS === 'web' ? window.open('mailto:support@crivellamedia.com') : Linking.openURL('mailto:support@crivellamedia.com') }>{'support@crivellamedia.com'}</Text>
              {!claimed ?
              <>{(Platform.OS === 'web' && !isMobile) ? <Text> | </Text> : null}</>
              : null}
              {(Platform.OS === 'web' && !isMobile) ?
                <View style={{flex: 1, paddingLeft: 5, justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row'}}>
                {!claimed ?
                <TouchableOpacity 
                  style={{flexDirection: 'row'}}
                  onPress={()=> {
                    if (!isGuest) {
                      if (Platform.OS === 'web') {
                        let nUrl = window.location.href.includes('?') ? `${window.location.href}&tcr=true` : `${window.location.href}?tcr=true`
                        window.open(`${nUrl}`)
                      } else {
                        Linking.openURL('https://mesearch.ai/?tcr=true')
                      }
                    } else {
                      navToLogin(true, true)
                    }
                  }}
                >
                  <Text style={{fontSize: 12, fontWeight: '400', color: '#0E6938', paddingTop: 2}}>Become A  </Text>
                  <Image style={{height: 25, width: 75}} source={require('../../assets/trusted.png')}/>
                </TouchableOpacity>
                : null}
              </View>
              : null}
            </View>
          </View>
        </View>
        <CopyrightFooter />
          {/* <View style={{height: 30, width: 300}}>
            <Image style={styles.image} source={darkMode ? require('../../assets/dark_mode_footer.png') : require('../../assets/web_footer_copyright.png')}/>
          </View> */}
      </View>
    );
  };
  
  const styles = StyleSheet.create({
      container: {
        marginLeft: 15,
      },
      image: {
        width: null,
        height: null,
        resizeMode: 'contain',
        flex: 1
      },
  });
  
  export default SearchFooter;