import React, { useState, useEffect } from 'react';
import { StyleSheet, Image, Dimensions, View, SafeAreaView, Text } from 'react-native';
import Spacer from '../components/Space';
import MeSearchAPI from '../api/MeSearch-API';


const DidNotResetPasswordScreen = ({ navigation, route }) => {
    const changeCode = route.params?.changeCode ?? '';
    const [successMessage, setSuccessMsg] = useState("");
    const [errorMessage, setErrorMsg] = useState("");

    const removeChangCodeAndNotify = async () => {
        // TODO: hook this screen up to App.js and navigate to it from search screen
        // Make api call her to notify support and remove change code from account
        try {
            await MeSearchAPI.post(
                '/api/user/remove-changeCode', 
                { changeCode: changeCode
            });
            setSuccessMsg("Successfully Notified Our Team");
        } catch (err) {
          setErrorMsg('An error occured please try again later');
        }
    }

    useEffect (() => {
      removeChangCodeAndNotify();
    }, []);

    return (
      <SafeAreaView style={{justifyContent: 'center', alignItems: 'center', flex: 1}}>
        <View style={{width: Math.min(Dimensions.get('window').width-80, 500), justifyContent: 'center', alignItems: 'center'}}>
          <Spacer>
          <View style={{flex: 0.5, marginTop: 60, justifyContent: 'center', alignItems: 'center'}}>
            <Image style={styles.image} source={require('../../assets/logo-mesearch.png')}/>
          </View>
          </Spacer>
          {successMessage !== "" ? <Text style={styles.successMessage}>{`${successMessage}`}</Text> : null}
          {errorMessage !== "" ? <Text style={styles.errorMessage}>{`${errorMessage}`}</Text> : null}
        </View>
      </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    errorMessage: {
      fontSize: 16,
      color: 'red',
      marginTop: 15,
      marginLeft: 15
    },
    successMessage: {
      fontSize: 18,
      color: 'green',
      marginTop: 15,
      marginLeft: 15
    },
    image: {
      width: 200,
      height: 80,
      marginTop: 5,
    }
});

export default DidNotResetPasswordScreen;