import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import SearchHomeScreen from '../../screens/SearchHomeScreen';


const Stack = createStackNavigator();

const searchFlow = () => {
    return (
        <Stack.Navigator
          initialRouteName="SearchHome"
          screenOptions={{ gestureEnabled: true }}
        >
        <Stack.Screen
            name="SearchHome"
            component={SearchHomeScreen}
            options={{title: "Search Home", headerShown: true}}
        />
        </Stack.Navigator>
    );
};

export default searchFlow;