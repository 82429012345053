import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

const instance = axios.create({
  //baseURL: 'http://192.168.0.43:8080/'
  //baseURL: 'http://10.152.104.222:8080/' //staging
  baseURL: 'https://mesearch.ai/',
  //baseURL: "http://10.142.22.19:8080/"
  //baseURL: "http://10.142.22.228:8080/"
  //baseURL: 'http://172.17.104.6:8080/' // DB
  //baseURL: 'http://172.17.104.5:8080/' //WA
});

instance.interceptors.request.use(
async (config) => {
    const ktoken = await AsyncStorage.getItem('ktoken');
    if (ktoken) {
    config.headers.Authorization = `Bearer ${ktoken}`;
    }
    return config;
}, 
(err) => {
    return Promise.reject(err);
}
);

// singleton instance
export default instance;