import React, { useState, useEffect, useContext, useCallback } from 'react';
import { View, Text, TouchableOpacity, Dimensions, ActivityIndicator, SafeAreaView } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MeSearchHome from '../components/MeSearchHome';
import KioskHome from '../components/kiosk/KioskHome';
import { Context as PubContext } from '../context/PubContext';
import { Platform } from 'react-native';


const SearchScreen = ({navigation, route}) => {
  const [kiosk, setKiosk] = useState(false);
  const [fetchComplete, setFetchComplete] = useState(false);
  const { state } = useContext(PubContext);

  const checkToken = async () => {
    const ktoken = await AsyncStorage.getItem('ktoken');
    if (ktoken) {
      if (!state || !state.publisher || !state.publisher.username) {
        setKiosk(true);
      }
    }
    setFetchComplete(true);
  };
  
  useEffect(() => {
    checkToken();
  }, []);

  return (
    <>
    {fetchComplete 
    ? <>{kiosk 
        ? <KioskHome navigation={navigation} route={route}/>
        : <MeSearchHome navigation={navigation} route={route}/>
      }</>
    : <View style={{height: Dimensions.get('window').height}}>
        <ActivityIndicator size={"large"} style={{marginTop: 150}} color="#005157"/>
      </View>
    }
    </>
  );
};

export default SearchScreen;