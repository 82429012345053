import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  TouchableOpacity,
  TextInput,
  FlatList,
  ScrollView,
  ActivityIndicator,
  ImageBackground
} from "react-native";
import { Platform } from 'react-native';
import { Feather, MaterialCommunityIcons, Ionicons } from "@expo/vector-icons";
import AccountHomeUserProfile from "../../core/AccountHomeUserProfile";
import AccountHomePublications from "../../core/AccountHomePublications";
import GridRow from "../../core/GridRow";
import AccountHomeBtnList from "../../core/AccountHomeBtnList";
import AccountHomeCustomBtn from "../../core/AccountHomeCustomBtn";
import useAccount from '../../../hooks/useAccount';
import MeSearchAPIPub from '../../../api/MeSearch-API-Pub';
import MeSearchAPI from '../../../api/MeSearch-API';
import adBadge from '../../../../assets/ad_content.png' ;
import { isMobile, isTablet } from 'react-device-detect';
import isVideo from '../../../screens/utils/isVideo';
import { WebView } from 'react-native-webview';
import { Context as PubContext } from '../../../context/PubContext';
import { Context as AuthContext } from '../../../context/AuthContext';
import ContentRow from '../../core/ContentRow';
import AccountHomeLandingPages from '../../core/AccountHomeLandingPages';
import predicateSearch from '../../../screens/utils/predicateSearch';
import imagePick from '../../utils/imagePick';
import BusinessTypesEnum from '../../../screens/utils/businessTypesEnum';
import useManagedContent from '../../../hooks/useManagedContent';
import alertToConfirm from '../../utils/alertToConfirm';
import ContentTypeEnum from '../../utils/contentTypes';
import TagManager from 'react-gtm-module';
import { updateUsr } from '../../../screens/utils/resultClkUtils';


const AccountHomeWrapper = ({ navigation, route, name, type, pubOwner, myAccLvl, imageUri, adminCapability, hasAccounts, horizontal,
  setHorizontal, landingPages, uploadCapability, darkMode, setLandingPages, publicationConfig, refreshAdmins }) => {
  //const pubOwner = route.params?.pubOwner ?? '';
  const PubCon = useContext(PubContext);
  const { signoutAndSigninToGuest } = useContext(AuthContext);
  const [activeBtnTitle, setActiveBtnTitle] = useState("");
  const [admins, adminsLoading, adminsError, setPubOwner, setAdmins, getAdsApi, ads, 
    adsLoading, getAdminsApi, articles, getArticles, articlesLoading, unPubArticles, nonPubArticlesLoading ] = useAccount();
  const [content, adContent, loading, adLoading, error, postMediaApi, setError, getContentApi ] = useManagedContent();
  const [publications, setPublications] = useState([]);
  const [lPgSuccessMsg, setLPgSuccessMsg] = useState("");
  const [pubErrorMsg, setPubErrorMsg] = useState("");
  const [pubSuccessMsg, setPubSuccessMsg] = useState("");
  const [usrErrorMsg, setUsrErrorMsg] = useState("");
  const [usrSuccessMsg, setUsrSuccessMsg] = useState("");
  const [lPgErrorMsg, setLPgErrorMsg] = useState("");
  const [searchTxt, setSearchTxt] = useState("");
  const [base64Img, setbase64Img] = useState(null);
  const [imgProcessing, setImgProcessing] = useState(false);
  const [tmpBtnList, setTmpBtnList] = useState([{title: "Users"}]); 
  const [articlePage, setArticlePage] = useState(0);
  const [nonPubArticlePage, setNonPubArticlePage] = useState(0);
  const BusinessTypes = [
    BusinessTypesEnum.BAKERY, 
    BusinessTypesEnum.BAR, 
    BusinessTypesEnum.CAFE, 
    BusinessTypesEnum.LODGING, 
    BusinessTypesEnum.Museum, 
    BusinessTypesEnum.RESTAURANT,
    BusinessTypesEnum.RETAILER,
    BusinessTypesEnum.OTHER
  ];

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const resultClick = async (item) => {
    if ((PubCon.state.publisher.viewSources.includes(item.source) ||
      PubCon.state.publisher.frameViewSources.includes(item.source) || 
      item.type === ContentTypeEnum.ARTICLEFRAMEVIEW) && 
      item.type !== ContentTypeEnum.ARTICLELINKVIEW) {
      let frmView = (PubCon.state.publisher.frameViewSources.includes(item.source) || item.type === ContentTypeEnum.ARTICLEFRAMEVIEW) ? true : false;
      navigation.navigate('ResultsShow', {content: item, darkMode: darkMode, frameView: frmView});
    } else {
        if (Platform.OS === 'web') {
          let date = new Date();
          const tagManagerArgs = {
            gtmId: PubCon.state.publisher.gtmId, //'GTM-59ZPF2B'
            dataLayer: {
            FullSection: 'Content',
            CanonicalUrl: `${item.body[0].link}`,
            Headline: `${item.body[0].title}`,
            Author: `${item.body[0].authors.toString()}`,
            Catagory: `${item.meTags.toString()}`,
            SubCategory: `${item.meTags.toString()}`,
            Description: `${item.body[0].description}`,
            Published: 'Yes',
            Published_short: '',
            Updated: '',
            StoryType: `${item.type}`,
            Day_of_week: `${days[date.getDay()]}`,
            Month: `${months[date.getMonth()]}`,
            Day: `${date.getDay()}`,
            Hour: `${date.getHours()}`,
            Year: `${date.getFullYear()}`, 
            Server: '', 
            Domain: `${PubCon.state.publisher.domains[0]}`,
            Testing: 'Yes', 
            User: '', 
            Logged: '',
            BodyId: `${item.body_id}`,
            Publication: `${PubCon.state.publisher.username}`
            },
            dataLayerName: 'DataLayer'
          }
          if (PubCon.state.publisher.gtmId) {
            TagManager.initialize(tagManagerArgs);
          }
      }
      updateUsr(item, PubCon);
      Platform.OS === 'web' ? window.open(item.body[0].link) : navigation.navigate('ResultsShow', {content: item, darkMode: darkMode, frameView: true});
    }
  }

  useEffect(() => {
    if (pubOwner) {
      setPubOwner(pubOwner.username);
    }
  }, [pubOwner]);

  useEffect(() => {
    if (refreshAdmins) {
      getAdminsApi();
    }
  }, [refreshAdmins]);

  const fetchPublications = async () => {
    try {
      let publications = await MeSearchAPIPub.get(
        '/api/pub-owner/publications-lite', {params: {pubOwner: pubOwner.username}});
      setPublications(publications.data);
    } catch (err) {
      setPublications([]);
      console.log(err);
    }
  };

  useEffect(() => {
    setSearchTxt("");
    setLPgErrorMsg("");
    setLPgSuccessMsg("");
    setPubSuccessMsg("");
    setPubErrorMsg("");
    setUsrSuccessMsg("");
    setUsrErrorMsg("");
    if (activeBtnTitle === 'Pubs' || activeBtnTitle === 'Businesses') {
      fetchPublications();
    } else if (activeBtnTitle === 'Ads') {
      getAdsApi();
    } else if (activeBtnTitle === 'Users') {
      getAdminsApi();
    } else if (activeBtnTitle === 'Offers') {
      getContentApi(["Article"], `${pubOwner.username}-UGC`, true, "Offer");
    } else if (activeBtnTitle === 'News') {
      getContentApi(["Article"], `${pubOwner.username}-UGC`, true, "Article");
    } else if (activeBtnTitle === 'Articles') {
      setArticlePage(0);
      setNonPubArticlePage(0);
      getArticles(0, articles, pubOwner.username, false);
      getArticles(0, unPubArticles, pubOwner.username, true);
    }
  }, [activeBtnTitle])

  useEffect(() => {
    const newTmpBtnList = (BusinessTypes.includes(pubOwner.type) && (pubOwner.username !== PubCon.state.publisher.owner) && Object.keys(publicationConfig).length === 0) ? 
      [
        {
          title: "Users",
        },
        {
          title: "Ads",
        },
      ]
      : BusinessTypes.includes(pubOwner.type) ?
      [
        {
          title: "Users",
        },
        /*{
          title: "Businesses",
        },*/
        {
          title: "Offers",
        },
        {
          title: "News",
        },
        {
          title: "Ads",
        },
        {
          title: "Landing Pages",
        },
      ]
      : [
          {
            title: "Users",
          },
          {
            title: "Pubs",
          },
          {
            title: "Ads",
          },
          {
            title: "Articles",
          },
          {
            title: "Landing Pages",
          },
      ];
      setTmpBtnList(newTmpBtnList);
  }, [pubOwner.username, publicationConfig]);

  const btnClickHandler = (btnTitle) => {
    setActiveBtnTitle(btnTitle);
  };

  useEffect(() => {
    setActiveBtnTitle(tmpBtnList[0].title);
  }, []);

  const setNewImage = async () => {
    setImgProcessing(true);
    // TODO: HACK 
    // Expo web doesn't resolve the following promise on cancel
    if (Platform.OS === 'web') {
      setTimeout(() => {
        setImgProcessing(false);
      }, [8000]);
    }
    let resultUri = await imagePick();
    if (resultUri !== "") {
      try {
        await MeSearchAPI.post('/api/content/upload-display-pic-base64', {image: resultUri, pubOwner: pubOwner.username});
        setbase64Img(resultUri);
        setImgProcessing(false);
      } catch (err) {
        console.log(err);
        setImgProcessing(false);
      }
    } else {
      setImgProcessing(false);
    }
  }

  return (
    <View>
      <View>
        <View style={{ alignItems: "center" }}>
          <View>
            <View style={{alignItems: "center", justifyContent: "center"}}>
              {!imgProcessing ?
                <>
                  <Image
                    style={{
                      height: (Platform.OS === 'web' && !isMobile) ? 250 : 124,
                      width: (Platform.OS === 'web' && !isMobile) ? 250 : 124,
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 20
                    }}
                    source={{ uri: base64Img ? base64Img : imageUri ? imageUri : pubOwner.tilePicUrl ? pubOwner.tilePicUrl : "https://cdn.mesearch.ai/assets/stockPhotos/60.jpg" }}
                  />
                  {(adminCapability && myAccLvl >= 4) ?
                  <TouchableOpacity
                    style={{
                      backgroundColor: "rgba(0,0,0,0.6)",
                      position: "absolute",
                      zIndex: 1,
                      width: (Platform.OS === 'web' && !isMobile) ? 250 : 124,
                      height: "30%",
                      bottom: 0,
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottomLeftRadius: 20,
                      borderBottomRightRadius: 20,
                    }}
                    onPress={setNewImage}
                  >
                    <Text style={{ color: "#fff" }}>Edit</Text>
                  </TouchableOpacity>
                  : null}
                </>
              : 
                <View style={{alignItems: 'center', justifyContent: 'center'}}>
                  <ActivityIndicator size="large" color={darkMode ? 'white' : Platform.OS ==='web' ? "#005157" : Platform.OS === 'android' ? '#005157' : 'default'}/>
                  <Text style={{fontSize: 14, color: 'grey'}}>Processing image...</Text>
                </View> 
              }
            </View>
            <View style={{ alignItems: "center" }}>
              <Text style={[{ fontWeight: "bold" }, darkMode ? {color: 'white'} : null]}>{name}</Text>
              <Text style={darkMode ? {color: 'white'} : null}>{type}</Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          {(pubOwner.type === "News Publisher" || !pubOwner.type) ?
            <View style={{ alignItems: "center", marginHorizontal: 15 }}>
              <Text style={[{ fontWeight: "bold" }, darkMode ? {color: 'white'} : null]}>{admins.length}</Text>
              <Text style={[darkMode ? {color: 'white'} : null]}>{`${admins.length === 1 ? 'Admin' : 'Admins'}`}</Text>
            </View>
          : null}
          {(pubOwner.type === "News Publisher" || !pubOwner.type) ?
            <View style={{ alignItems: "center", marginHorizontal: 15 }}>
              <Text style={[{ fontWeight: "bold" }, darkMode ? {color: 'white'} : null]}>{publications.length}</Text>
              <Text style={[darkMode ? {color: 'white'} : null]}>{`${publications.length === 1 ? 'Publication' : 'Publications'}`}</Text>
            </View>
          : null}
          {(pubOwner.type === "News Publisher" || !pubOwner.type) ?
            <View style={{ alignItems: "center", marginHorizontal: 15 }}>
              <Text style={[{ fontWeight: "bold" }, darkMode ? {color: 'white'} : null]}>{ads.length}</Text>
              <Text style={[darkMode ? {color: 'white'} : null]}>{`${ads.length === 1 ? 'Ad' : 'Ads'}`}</Text>
            </View>
          : null}
          {(pubOwner.type && BusinessTypes.includes(pubOwner.type)) ?
            <View style={{ alignItems: "center", marginHorizontal: 15 }}>
              <Text style={[{ fontWeight: "bold" }, darkMode ? {color: 'white'} : null]}>{content.filter(c => c.meTags.includes("Offers")).length}</Text>
              <Text style={[darkMode ? {color: 'white'} : null]}>{`${content.filter(c => c.meTags.includes("Offers")).length === 1 ? 'Offer' : 'Offers'}`}</Text>
            </View>
          : null}
          {(pubOwner.type && BusinessTypes.includes(pubOwner.type)) ?
            <View style={{ alignItems: "center", marginHorizontal: 15 }}>
              <Text style={[{ fontWeight: "bold" }, darkMode ? {color: 'white'} : null]}>{content.filter(c => !c.meTags.includes("Offers")).length}</Text>
              <Text style={[darkMode ? {color: 'white'} : null]}>{`${content.filter(c => !c.meTags.includes("Offers")).length === 1 ? 'Article' : 'Articles'}`}</Text>
            </View>
          : null}
        </View>

        <View
          style={{ alignItems: "center", marginTop: 10, paddingHorizontal: 13 }}
        >
          <View style={{ flexDirection: "row", paddingVertical: 10 }}>
            <TouchableOpacity
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginHorizontal: 10,
                paddingHorizontal: 5,
                paddingVertical: 5,
                borderColor: darkMode ? "white" : "#666",
                borderWidth: 1,
                borderRadius: 5
              }}
              onPress={() => setHorizontal(!horizontal)}
            >
              <Feather name={horizontal ? "list" : "grid"} size={24} color={darkMode ? "white" : "black"} />
              <Text style={[{ paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>{horizontal ? "List" : "Grid"}</Text>
            </TouchableOpacity>
            {hasAccounts ?
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginHorizontal: 10,
                    paddingHorizontal: 5,
                    borderColor: darkMode ? "white" : "#666",
                    borderWidth: 1,
                    borderRadius: 5
                  }}
                  onPress={() => {
                    navigation.navigate('Drawer', {screen: 'Account', params: {screen: 'Passport', params: {darkMode}}});
                  }}
                >
                  <Ionicons name="git-network-outline" size={24} color={darkMode ? "white" : "black"} />
                  <View style={{ alignItems: "center", justifyContent: "center" }}>
                    <Text style={[{ lineHeight: 12, paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>My</Text>
                    <Text style={[{ lineHeight: 12, paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>
                      Network
                    </Text>
                  </View>
                </TouchableOpacity>
            : null}
            {/*
            <TouchableOpacity
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginHorizontal: 10,
                paddingHorizontal: 5,
                borderColor: "#666",
                borderWidth: 1,
                borderRadius: 5
              }}
            >
              <Feather name="heart" size={24} color="#8B0000" />
            </TouchableOpacity>
            */}
            {/*adminCapability && myAccLvl >= 4 ?
            <TouchableOpacity
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginHorizontal: 10,
                paddingHorizontal: 5,
                //borderColor: darkMode ? "white" : "#666",
                borderWidth: 1,
                borderColor: "#c9c9c9",
                borderRadius: 5
              }}
              onPress={() => {
                //navigation.navigate('AccountSettings', {darkMode});
              }}
            >
              <View style={{opacity: 0.35}}>
                <Feather name="settings" size={24} color={darkMode ? "white" : "black"} />
              </View>
            </TouchableOpacity>
            : null*/}
            <TouchableOpacity
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginHorizontal: 10,
                paddingHorizontal: 5,
                borderColor: darkMode ? "white" : "#666",
                borderWidth: 1,
                borderRadius: 5
              }}
              onPress={() => {
                  if (Platform.OS === 'web') {
                    alertToConfirm('web', "Are you sure you want to logout of your personal membership?", async () => {
                      await signoutAndSigninToGuest({navToLogin: true});
                    })();
                  } else {
                    alertToConfirm('mobile', "Are you sure you want to logout of your personal membership?", async () => {
                      await signoutAndSigninToGuest({navToLogin: true});
                    })();
                  }
                }
              }
            >
              <View>
                <Feather name="log-out" size={24} color={darkMode ? "white" : "black"} />
              </View>
              <View style={{ alignItems: "center", justifyContent: "center" }}>
                <Text style={[{ lineHeight: 12, paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>Log</Text>
                <Text style={[{ lineHeight: 12, paddingHorizontal: 5 }, darkMode ? {color: 'white'} : null]}>
                  Out
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>

        <View>
          <AccountHomeBtnList
            darkMode={darkMode}
            list={tmpBtnList}
            onPress={btnClickHandler}
            activeBtnTitle={activeBtnTitle}
          />
        </View>

        <View style={{ paddingHorizontal: 5, marginTop: 10 }}>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            {(activeBtnTitle === 'Users' && (adminCapability && myAccLvl >= 4)) ?
              <TouchableOpacity
                onPress={() => {navigation.navigate('AccountUser', {pubOwner: pubOwner.username})}}
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <Feather name="plus-circle" size={24} color={darkMode ? "white" : "#0984e3"} />
                <Text style={[{ paddingLeft: 5 }, darkMode ? {color: 'white'} : null]}>User</Text>
                <TouchableOpacity
                       style={{
                        borderColor: "silver",
                        borderWidth: 1,
                        paddingHorizontal: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        marginHorizontal: 5,
                        width: 35}}
                        onPress={() => navigation.navigate('analytics', {reportName: pubOwner.username})}
                      >
                        <Feather name="bar-chart-2" size={24} color="silver" />
                      </TouchableOpacity>
              </TouchableOpacity>
            : null}
            {(activeBtnTitle === 'Pubs' || activeBtnTitle === 'Businesses') && (adminCapability && myAccLvl >= 4) ?
              <TouchableOpacity
                style={{ flexDirection: "row", alignItems: "center" }}
                onPress={() => navigation.navigate('Publisher', {screen: 'CreatePublication', params: {pubOwner: pubOwner.username}})}
              >
                <Feather name="plus-circle" size={24} color={darkMode ? "white" : "#0984e3"} />
                <Text style={[{ paddingLeft: 5 }, darkMode ? {color: 'white'} : null]}>{activeBtnTitle === 'Pubs' ? "Publication" : "Business"}</Text>
                <TouchableOpacity
                       style={{
                        borderColor: "silver",
                        borderWidth: 1,
                        paddingHorizontal: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        marginHorizontal: 5,
                        width: 35}}
                        onPress={() => navigation.navigate('analytics', {reportName: pubOwner.username})}
                      >
                        <Feather name="bar-chart-2" size={24} color="silver" />
                      </TouchableOpacity>
              </TouchableOpacity>
            : null}

            {activeBtnTitle === 'Ads' && uploadCapability ?
              // <TouchableOpacity
              //   style={{ flexDirection: "row", alignItems: "center" }}
              // >
              //   <Feather name="plus-circle" size={24} color={darkMode ? "white" : "#0984e3"} />
              //   <Text style={[{ paddingLeft: 5 }, darkMode ? {color: 'white'} : null]}>Advertisement</Text>
              // </TouchableOpacity>

              adminCapability && myAccLvl >= 4 ?
                <TouchableOpacity
                style={{
                borderColor: "silver",
                borderWidth: 1,
                paddingHorizontal: 5,
                justifyContent: "center",
                alignItems: "center",
                marginHorizontal: 5,
                width: 35}}
                onPress={() => navigation.navigate('analytics', {reportName: pubOwner.username})}
              >
                <Feather name="bar-chart-2" size={24} color="silver" />
              </TouchableOpacity>
              : null

            : null}

            {activeBtnTitle === 'Articles' && uploadCapability ?
            
              adminCapability && myAccLvl >= 4 ?
              <TouchableOpacity
              style={{
               borderColor: "silver",
               borderWidth: 1,
               paddingHorizontal: 5,
               justifyContent: "center",
               alignItems: "center",
               marginHorizontal: 5,
               width: 35}}
               onPress={() => navigation.navigate('analytics', {reportName: pubOwner.username})}
             >
               <Feather name="bar-chart-2" size={24} color="silver" />
             </TouchableOpacity>
             : null

            : null}

            {activeBtnTitle === 'Landing Pages' && (adminCapability && myAccLvl >= 4) ?
              <TouchableOpacity
                style={{ flexDirection: "row", alignItems: "center" }}
                onPress={() => navigation.navigate('CreateLandingPage', {pubOwner: pubOwner.username})}
              >
                <Feather name="plus-circle" size={24} color={darkMode ? "white" : "#0984e3"} />
                <Text style={[{ paddingLeft: 5 }, darkMode ? {color: 'white'} : null]}>Landing Page</Text>
              </TouchableOpacity>
            : null}

            {activeBtnTitle === 'News' && uploadCapability ?
              <TouchableOpacity
                style={{ flexDirection: "row", alignItems: "center", padding: 10 }}
                onPress={() => {
                  navigation.navigate('UploadContent', {displayForm: true, darkMode, isGuest: false, claimed: true, 
                    businessName: pubOwner.displayName ? pubOwner.displayName : pubOwner.username, pubConfig: publicationConfig
                  });
                }}
              >
                <Feather name="plus-circle" size={24} color={darkMode ? "white" : "#0984e3"} />
                <Text style={[{ paddingLeft: 5 }, darkMode ? {color: 'white'} : null]}>News</Text>
              </TouchableOpacity>
            : null}

            {activeBtnTitle === 'Offers' && uploadCapability ?
              <TouchableOpacity
                style={{ flexDirection: "row", alignItems: "center", padding: 10 }}
                onPress={() => {
                  navigation.navigate('UploadContent', {displayForm: true, darkMode, isGuest: false, claimed: true, uploadType: "Offer", 
                    businessName: pubOwner.displayName ? pubOwner.displayName : pubOwner.username, pubConfig: publicationConfig
                  });
                }}
              >
                <Feather name="plus-circle" size={24} color={darkMode ? "white" : "#0984e3"} />
                <Text style={[{ paddingLeft: 5 }, darkMode ? {color: 'white'} : null]}>Offer</Text>
              </TouchableOpacity>
            : null}

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 1,
                borderColor: darkMode ? "white" : "#666",
                width: 225,
                height: 30
              }}
            >
              <Feather name="search" size={24} color={darkMode ? "white" : "#666"} />
              <TextInput
                style={{
                  flex: 1,
                }}
                placeholder="search"
                value={searchTxt}
                onChangeText={(txt) => {
                  setSearchTxt(txt);
                }}
              />
              <Feather name="chevron-right" size={24} color={darkMode ? "white" : "#666"} />
            </View>
          </View>
        </View>

        <View>
          {activeBtnTitle && activeBtnTitle === "Users" ? (
            <View>
              {usrSuccessMsg !== "" ? <Text style={{ fontSize: 16, color: 'green', paddingTop: 10, marginLeft: 12, paddingBottom: 10}}>{usrSuccessMsg}</Text> : null}
              {usrErrorMsg !== "" ? <Text style={{ fontSize: 16, color: 'red', paddingTop: 10, marginLeft: 12, paddingBottom: 10}}>{usrErrorMsg}</Text> : null}
              <AccountHomeUserProfile
                setErrorMsg={setUsrErrorMsg}
                setSuccessMsg={setUsrSuccessMsg}
                darkMode={darkMode}
                horizontal={horizontal}
                admins={admins}
                setAdmins={setAdmins}
                pubOwner={pubOwner.username}
                myAccLvl={myAccLvl}
                adminCapability={adminCapability}
                profiles={predicateSearch(admins.filter(a => a.accessLvl >= 4), searchTxt, {username: 1, displayName: 1, accessLvl: 1, firstName: 1, lastName: 1, phoneNum: 1})}
                title="Administrators - Pro"
              />
              <AccountHomeUserProfile
                setErrorMsg={setUsrErrorMsg}
                setSuccessMsg={setUsrSuccessMsg}
                darkMode={darkMode}
                horizontal={horizontal}
                admins={admins}
                setAdmins={setAdmins}
                pubOwner={pubOwner.username}
                myAccLvl={myAccLvl}
                adminCapability={adminCapability}
                profiles={predicateSearch(admins.filter(a => a.accessLvl < 4), searchTxt, {username: 1, displayName: 1, accessLvl: 1, firstName: 1, lastName: 1, phoneNum: 1})}
                title="Administrators - Basic"
              />
            </View>
          ) : (activeBtnTitle && (activeBtnTitle === "Pubs" || activeBtnTitle === "Businesses")) ? (
                <View>
                  {pubSuccessMsg !== "" ? <Text style={{ fontSize: 16, color: 'green', paddingTop: 10, marginLeft: 12, paddingBottom: 10}}>{pubSuccessMsg}</Text> : null}
                  {pubErrorMsg !== "" ? <Text style={{ fontSize: 16, color: 'red', paddingTop: 10, marginLeft: 12, paddingBottom: 10}}>{pubErrorMsg}</Text> : null}
                  <AccountHomePublications
                    darkMode={darkMode}
                    horizontal={horizontal}
                    publications={predicateSearch(publications, searchTxt, {username: 1, domains: 1, owner: 1, sources: 1, tcEmail: 1, defaultImage: 1, pubTags: 1})}
                    setPublications={setPublications}
                    setErrMsg={setPubErrorMsg}
                    setSuccessMsg={setPubSuccessMsg}
                    title={activeBtnTitle === "Pubs" ? "Publications" : "Businesses"}
                    pubOwner={pubOwner.username}
                    myAccLvl={myAccLvl}
                    adminCapability={adminCapability}
                    navigation={navigation}
                  />
                </View>
              )
          : <View style={{paddingTop : 5,}}>
              {activeBtnTitle && activeBtnTitle === 'Ads' ? (
                  <View>
                    { predicateSearch(
                          ads.filter(a => {
                            return (!a.status || (a.status !== 2 && a.status !== 3 && a.status !== 0));
                          }), searchTxt, {source: 1, body_id: 1, body: 1, tags: 1, targeting: 1, meTags: 1, type: 1, 
                            source_native_id: 1, userGen: 1, contentDate: 1, mediaMeta: 1, bodyLinks: 1, status: 1, remDate: 1, price: 1, quantity: 1}
                        ).length > 0 ? <Text style={[{marginBottom: 5, marginLeft: 12}, darkMode ? {color: 'white'} : null]}>Active Campaigns</Text> : null}
                    <ContentRow
                      horizontal={horizontal}
                      navigation={navigation}
                      data={
                        predicateSearch(
                          ads.filter(a => {
                            return (!a.status || (a.status !== 2 && a.status !== 3 && a.status !== 0));
                          }), searchTxt, {source: 1, body_id: 1, body: 1, tags: 1, targeting: 1, meTags: 1, type: 1, 
                            source_native_id: 1, userGen: 1, contentDate: 1, mediaMeta: 1, bodyLinks: 1, status: 1, remDate: 1, price: 1, quantity: 1}
                        )
                      }
                      isLoading={adsLoading}
                      myAccLvl={myAccLvl}
                      adminCapability={adminCapability}
                      onPress={(item) => {
                        if (uploadCapability) {
                          navigation.navigate(
                            'ManageCampaign', 
                            {
                              body_id: item.body_id, 
                              activate: true
                            }
                          )
                        }
                      }}
                    />
                    {predicateSearch(
                      ads.filter(a => {
                        return (a.status && (a.status === 2 || a.status === 3 || a.status === 0));
                      }), searchTxt, {source: 1, body_id: 1, body: 1, tags: 1, targeting: 1, meTags: 1, type: 1, 
                      source_native_id: 1, userGen: 1, contentDate: 1, mediaMeta: 1, bodyLinks: 1, status: 1, remDate: 1, price: 1, quantity: 1}
                    ).length > 0 ? <Text style={[{marginBottom: 5, marginLeft: 12}, darkMode ? {color: 'white'} : null]}>Non Active Campaigns</Text>: null}
                    <ContentRow
                      horizontal={horizontal}
                      navigation={navigation}
                      data={
                        predicateSearch(
                          ads.filter(a => {
                            return (a.status && (a.status === 2 || a.status === 3 || a.status === 0));
                          }), searchTxt, {source: 1, body_id: 1, body: 1, tags: 1, targeting: 1, meTags: 1, type: 1, 
                          source_native_id: 1, userGen: 1, contentDate: 1, mediaMeta: 1, bodyLinks: 1, status: 1, remDate: 1, price: 1, quantity: 1}
                        )
                      }
                      isLoading={adsLoading}
                      myAccLvl={myAccLvl}
                      adminCapability={adminCapability}
                      onPress={(item) => {
                        if (uploadCapability) {
                          navigation.navigate(
                            'ManageCampaign', 
                            {
                              body_id: item.body_id, 
                              activate: false
                            }
                          )
                        }
                      }}
                    />
                  </View>
              ) : (activeBtnTitle && activeBtnTitle === 'Articles') ? (
                <View style={{width: Math.min(Dimensions.get('window').width-20, 790)}}>
                  {predicateSearch(
                      articles, searchTxt, {source: 1, body_id: 1, body: 1, tags: 1, targeting: 1, meTags: 1, type: 1, 
                        source_native_id: 1, userGen: 1, contentDate: 1, mediaMeta: 1, bodyLinks: 1, status: 1, remDate: 1, price: 1, quantity: 1}
                    ).length > 0 ? <Text style={[{marginBottom: 5, marginLeft: 12}, darkMode ? {color: 'white'} : null]}>Published Articles</Text> : null}
                  <ContentRow
                    horizontal={horizontal}
                    data={
                      predicateSearch(
                        articles, searchTxt, {source: 1, body_id: 1, body: 1, tags: 1, targeting: 1, meTags: 1, type: 1, 
                          source_native_id: 1, userGen: 1, contentDate: 1, mediaMeta: 1, bodyLinks: 1, status: 1, remDate: 1, price: 1, quantity: 1}
                      )
                    }
                    isLoading={articlesLoading}
                    myAccLvl={myAccLvl}
                    adminCapability={adminCapability}
                    onPress={(item) => {
                      resultClick(item);
                    }}
                    onReachedEnd={(pg) => {
                      getArticles(pg, articles, pubOwner.username, false);
                    }}
                    setPage={setArticlePage}
                    page={articlePage}
                  />
                  {predicateSearch(
                    unPubArticles, searchTxt, {source: 1, body_id: 1, body: 1, tags: 1, targeting: 1, meTags: 1, type: 1, 
                    source_native_id: 1, userGen: 1, contentDate: 1, mediaMeta: 1, bodyLinks: 1, status: 1, remDate: 1, price: 1, quantity: 1}
                  ).length > 0 ? <Text style={[{marginBottom: 5, marginLeft: 12}, darkMode ? {color: 'white'} : null]}>Non Published Articles</Text>: null}
                  <ContentRow
                    horizontal={horizontal}
                    data={
                      predicateSearch(
                        unPubArticles, searchTxt, {source: 1, body_id: 1, body: 1, tags: 1, targeting: 1, meTags: 1, type: 1, 
                        source_native_id: 1, userGen: 1, contentDate: 1, mediaMeta: 1, bodyLinks: 1, status: 1, remDate: 1, price: 1, quantity: 1}
                      )
                    }
                    isLoading={nonPubArticlesLoading}
                    myAccLvl={myAccLvl}
                    adminCapability={adminCapability}
                    onPress={(item) => {
                      if (uploadCapability) {
                        navigation.navigate(
                          'PublishArticle', 
                          {
                            body_id: item.body_id, 
                            activate: false
                          }
                        )
                      }
                    }}
                    onReachedEnd={(pg) => {
                      getArticles(pg, unPubArticles, pubOwner.username, true);
                    }}
                    setPage={setNonPubArticlePage}
                    page={nonPubArticlePage}
                  />
                </View>
              ) : <>
                  {activeBtnTitle && activeBtnTitle === 'Landing Pages' ? (
                    <View>
                      {lPgSuccessMsg !== "" ? <Text style={{ fontSize: 16, color: 'green', paddingTop: 10, marginLeft: 12, paddingBottom: 10}}>{lPgSuccessMsg}</Text> : null}
                      {lPgErrorMsg !== "" ? <Text style={{ fontSize: 16, color: 'red', paddingTop: 10, marginLeft: 12, paddingBottom: 10}}>{lPgErrorMsg}</Text> : null}
                      <AccountHomeLandingPages
                        darkMode={darkMode}
                        horizontal={horizontal}
                        landingPages={predicateSearch(landingPages, searchTxt, {domains: 1, publications: 1, headerImage: 1, tags: 1})}
                        title={"Landing Pages"}
                        pubOwner={pubOwner.username}
                        myAccLvl={myAccLvl}
                        adminCapability={adminCapability}
                        navigation={navigation}
                        setLandingPages={setLandingPages}
                        setLPgSuccessMsg={setLPgSuccessMsg}
                        setLPgErrorMsg={setLPgErrorMsg}
                      />
                    </View>
                  ) : null}
              </>
              }
                <>
                  {activeBtnTitle && activeBtnTitle === 'Offers' ? (
                    <View>
                      <ContentRow
                        horizontal={horizontal}
                        data={
                          predicateSearch(
                            content.filter(a => {
                              return a.meTags.includes("Offers");
                            }), searchTxt, {source: 1, body_id: 1, body: 1, tags: 1, targeting: 1, meTags: 1, type: 1, 
                              source_native_id: 1, userGen: 1, contentDate: 1, mediaMeta: 1, bodyLinks: 1, status: 1, remDate: 1, price: 1, quantity: 1}
                          )
                        }
                        isLoading={loading}
                        myAccLvl={myAccLvl}
                        adminCapability={adminCapability}
                        onPress={(item) => {
                          if (uploadCapability) {
                            navigation.navigate('UploadContent', {curContent: item, darkMode, isGuest: false, claimed: true, uploadType: "Offer", 
                            businessName: pubOwner.displayName ? pubOwner.displayName : pubOwner.username
                          });
                          }
                        }}
                      />
                    </View>
                  ) : <>
                        {activeBtnTitle && activeBtnTitle === 'News' ? (
                          <View>
                            <ContentRow
                              horizontal={horizontal}
                              data={
                                predicateSearch(
                                  content.filter(a => {
                                    return !a.meTags.includes("Offers");
                                  }), searchTxt, {source: 1, body_id: 1, body: 1, tags: 1, targeting: 1, meTags: 1, type: 1, 
                                    source_native_id: 1, userGen: 1, contentDate: 1, mediaMeta: 1, bodyLinks: 1, status: 1, remDate: 1, price: 1, quantity: 1}
                                )
                              }
                              isLoading={loading}
                              myAccLvl={myAccLvl}
                              adminCapability={adminCapability}
                              onPress={(item) => {
                                if (uploadCapability) {
                                  navigation.navigate('UploadContent', {curContent: item, darkMode, isGuest: false, claimed: true, 
                                    businessName: pubOwner.displayName ? pubOwner.displayName : pubOwner.username
                                  });
                                }
                              }}
                            />
                            </View>
                        ) : null}
                      </>
                  }
                </>
            </View>
          }
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  badgeImage: {
      width: 75,
      height: 20,
      marginTop: 2,
      marginLeft: 5,
      paddingLeft: 5,
      backgroundColor: 'black'
  },
  container: {
      marginLeft: 15,
      
      justifyContent:'center', 
      flexDirection:'column',
  },
  image: {
      width: 250,
      height: 180,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      marginBottom: -2,
      },

  textDescStyle: {
      color: 'white',
      fontWeight: '300',
      fontSize: 14,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 10
  },
  name: {
      width: 250,
      height: 42,
      fontWeight: 'bold',
      color: 'white',
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
  },
});

export default AccountHomeWrapper;
