import React, { useRef, useState, useEffect } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  TouchableOpacity,
  TextInput,
  FlatList,
  Platform,
} from "react-native";
import { Feather, MaterialCommunityIcons, Ionicons } from "@expo/vector-icons";
import MeSearchAPI from '../../api/MeSearch-API';
import { isMobile, isTablet } from "react-device-detect";
import alertToConfirm from '../utils/alertToConfirm';

const AccountHomeUserPublications = ({ publications, title, myAccLvl, pubOwner, adminCapability, setPublications, horizontal, darkMode, setSuccessMsg, setErrMsg, navigation }) => {
  let flatListRef = useRef(0);
  const [currOffset, setCurrOffset] = useState(0);
  const [count, setCount] = useState(0);

  const scroll = () => {
    flatListRef.current.scrollToOffset({
      offset: currOffset,
      animated: true,
    });
  };

  useEffect(() => {
    scroll();
  }, [currOffset]);

  return (
    <View style={styles.container}>
      {publications.length > 0 ? <Text style={[styles.title, darkMode ? {color: 'white'} : null]}>{title}</Text> : null}
      <View style={styles.inner}>
        {count > 0 && Platform.OS === "web" ? (
          <TouchableOpacity
            style={styles.arrow}
            onPress={() => {
              if (count > 0) {
                setCurrOffset(currOffset - 250);
                setCount(count - 1);
              }
            }}
          >
            <Feather name="chevron-left" size={24} color="#fff" />
          </TouchableOpacity>
        ) : null}
        <FlatList
          horizontal={horizontal}
          ref={flatListRef}
          showsHorizontalScrollIndicator={false}
          data={publications}
          keyExtractor={(item) => item.username}
          renderItem={({ item }) => {
            return (
              <View
                style={[
                  styles.profile,
                  item.tagBarColor
                    ? { backgroundColor: `${item.tagBarColor}` }
                    : { backgroundColor: "#3498db" },
                    { width: 250 },
                ]}
              >
                <View style={styles.upper}>
                  <Image style={styles.img} source={{ uri: item.defaultImage ? item.defaultImage : "https://cdn.mesearch.ai/assets/stockPhotos/60.jpg" }} />
                  <View style={styles.userInfo}>
                    <Text style={styles.userName}>
                        {
                          item.username.length >= 20
                            ? item.username.substring(0, 17) + "..."
                            : item.username
                        }
                    </Text>
                  </View>
                </View>
                <View>
                    <View style={styles.lower}>
                        <View style={styles.contentWrapper}>
                            <View style={styles.content}>
                            <Text style={styles.text}>{`domain: ${(item.domains && item.domains.length > 0) ? `${item.domains[0]}` : 'None'}`}</Text>
                            <Text style={styles.text}>{`Status: ${item.status ? `${item.status}` : 'Open'}`}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                      {(adminCapability && myAccLvl >= 4) ?
                          <View style={{flexDirection:'row'}}>
                              <TouchableOpacity 
                                  style={styles.btn}
                                  onPress={async () => {
                                    navigation.navigate('Publication', {screen: 'PubEdit', params: {pub: item.username}});
                                  }}
                              >
                                  <Text style={styles.btnText}>Edit</Text>
                              </TouchableOpacity>
                              <TouchableOpacity 
                                  style={styles.btn}
                                  onPress={async () => {
                                    alertToConfirm('web', "Are you sure you want to remove this publication?", async () => {
                                      setSuccessMsg("");
                                      setErrMsg("");
                                      try {
                                        await MeSearchAPI.delete('/api/pub', {params: {pub: item.username}});
                                        setSuccessMsg("Successfully deleted");
                                        setPublications(publications.filter(p => p.username !== item.username));
                                      } catch (err) {
                                        setErrMsg("Failed to delete");
                                      }
                                    })();
                                  }}
                              >
                                  <Text style={styles.btnText}>Remove</Text>
                              </TouchableOpacity>
                            </View>
                      : null}
                      <TouchableOpacity
                       style={{
                        borderColor: "#fff",
                        borderWidth: 1,
                        paddingHorizontal: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        marginHorizontal: 5,
                        width: 35}}
                        onPress={() => navigation.navigate('charts', {pubName: item.username})}
                      >
                        <Feather name="bar-chart-2" size={24} color="#fff" />
                      </TouchableOpacity>
                      <TouchableOpacity
                       style={{
                        borderColor: "#fff",
                        borderWidth: 1,
                        paddingHorizontal: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        marginHorizontal: 5,
                        width: 35}}
                        onPress={() => navigation.navigate('tags', {pubName: item.username})}
                      >
                        <Feather name="tag" size={18} color="#fff" />
                      </TouchableOpacity>
                    </View>

                    </View>
              </View>
            );
          }}
        />
        {publications.length > 3 &&
        (Platform.OS === "web" && (!isMobile && !isTablet)) &&
        count <= Math.ceil(publications.length / 3) &&
        horizontal
        ? (
          <TouchableOpacity
            style={styles.arrow}
            onPress={() => {
              if (count <= Math.ceil(publications.length / 3)) {
                setCurrOffset(currOffset + 250);
                setCount(count + 1);
              }
            }}
          >
            <Feather name="chevron-right" size={24} color="#fff" />
          </TouchableOpacity>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    //flex:1,
    //backgroundColor:'#d63031',
    //marginHorizontal:10,
    // justifyContent:'center',
    // alignItems:'center',
    //paddingVertical:5,
  },
  inner: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    paddingHorizontal: 5,
    // borderColor:'red',
    // borderWidth:1,
  },
  title: {
    paddingVertical: 5,
    marginHorizontal: 5,
  },
  profile: {
    marginHorizontal: 5,
    marginVertical: 5,
    height: (Platform.OS === 'web' && !isMobile) ? 180 : 200
  },
  upper: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderBottomColor: "#fff",
    borderBottomWidth: 1,
  },
  img: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
  },
  userInfo: {
    paddingLeft: 5,
  },
  userName: {
    color: "#fff",
    fontWeight: "600",
    fontSize: 15,
  },
  userTitle: {
    color: "#fff",
    fontSize: 10,
  },
  lower: {
    flexDirection: "row",
    alignItems: "center",
  },
  contentWrapper: {
    paddingHorizontal: 5,
    paddingVertical: 10,
  },
  content: {
    paddingBottom: 10,
  },
  text: {
    color: "#fff",
    fontSize: 10,
  },
  btn: {
    borderColor: "#fff",
    borderWidth: 1,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 5,
    width: 70
  },
  btnText: {
    color: "#fff",
    textAlign: "center",
    paddingHorizontal: 10,
    paddingVertical: 5,
    fontSize:10
  },
  arrow: {
    backgroundColor: "#666",
    height: "95%",
    justifyContent: "center",
  },
});

export default AccountHomeUserPublications;
