import React from 'react';
import { View, Text } from 'react-native';
const ManageSetScreen = ({navigation, route}) => {
    const portfolioId = route.params?.portfolioId ?? null;
    return (
        <View style={{alignItems: 'center', justifyContent: 'center', paddingTop: 50}}>
          <Text>Manage Set</Text>
          <Text>TODO: if portfolioId is null then assume the user is trying to add a new set and not manage an existing one</Text>
        </View>
    );
};
export default ManageSetScreen;